import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '@app/core/_Services';


@Component({
  selector: 'app-select-shipment-to-create',
  templateUrl: './select-shipment-to-create.component.html',
  styleUrls: ['./select-shipment-to-create.component.scss']
})
export class SelectShipmentToCreateComponent implements OnInit {

  @Input() booking: any;
  @Input() databundle: any;
  @Input() isExfac = false;
  @Output() btnsubmit_event = new EventEmitter();

  selectedBooking = [];
  isSelectedAll = false;
  constructor( private _noti: NotificationService) { }

  ngOnInit(): void {
    // console.log(this.booking);

    this.booking.forEach(booking => {
      var sumWeight = 0;
      booking.customer.forEach(cus=>{
        cus.customerOrder.forEach(order=>{
          var orderWeight = 0;
          order.items.forEach(item=>{
            orderWeight+=item.weight;
            sumWeight+=item.weight;
          });
          order.sumWeight = orderWeight;
        })
      })
      booking.sumWeight = sumWeight;
    });
    
  }

  onselect(item){

    if(this.selectedBooking.length > 0){
    if(this.selectedBooking.find(e=>e.truckBookingNo === item.truckBookingNo)){
      var index = this.selectedBooking.findIndex(e=>e.truckBookingNo === item.truckBookingNo);
      this.selectedBooking.splice(index, 1);
    }else{
      this.selectedBooking.push(item);
    }}
    else{
      this.selectedBooking.push(item);

    }
    console.log(this.selectedBooking)

  }

  onCreate(){
    this._noti
    .swalConfirm(
      'ยืนยันสร้าง booking',''
    )
    .then((result) => {
      if (result) {
        this.btnsubmit_event.emit(this.selectedBooking);
        setTimeout(() => {
          this.selectedBooking = [];
        });
      }
    });
   
  }

  isSelected(item){
    return this.selectedBooking.indexOf(item) > -1;
  }

  selectAll(isSelectedAll){
    console.log(isSelectedAll)
    this.selectedBooking = [];
    if(isSelectedAll){
      this.selectedBooking.push(...this.booking);

    }
    this.isSelectedAll = isSelectedAll;
    
  }

  shipmentData(item){
    // console.log("shipmentData",item)
    var shipments = this.databundle.items;
    var shipment = shipments.filter(l => l.name === item.truckBookingNo);

  return shipment[0]
  }

  findEndLoc(shipment){
    // console.log("findEndLoc",shipment)
    // var length = shipment.locations.length;
    // var end =  shipment.locations[length - 1];
    // var endLoc = end;
    var rowActions = shipment.row[0].rowItem;
    var Drop = rowActions.filter(action => action.name === 'DISCHARGE');  
    return Drop;
  }

   printDestination(d){
    const loc = d.orders[0].destination;
    var result = loc.name ;
    return result;
   }


   

   

}
