import { Injectable } from '@angular/core';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { HttpService } from '@app/core/_Services';
import { Appmodule, MenuListDto } from '@app/modules/notification/model/PermissSetting';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoficationserviceService {

  constructor(private _apiService: HttpService) { }

  destroy$: Subject<boolean> = new Subject<boolean>();

  getNoficationInApp(UserId: number): Observable<any> {
    return this._apiService.get(`/Notification/InApp/${UserId}`);
  }

  getNoficationInAppByfontend(UserId: number,token :string,NotiTypeId :number,pagesize:number, page:number = 1): Observable<any> {
    return this._apiService.GetToNoti(`/v1.0/NotiDB/Get/InAppWebV2/ByUserID/${UserId}?NotiTypeId=${NotiTypeId}&SortBy=CreateDate&SortOrder=-1&pageSize=${pagesize}&page=${page}`,token);
  }

  getCountNoficationInApp(UserId: number,token :string,NotiTypeId :number): Observable<any> {
    return this._apiService.GetToNoti(`/v1.0/NotiDB/Get/InAppWebV2/ByUserID/${UserId}?NotiTypeId=${NotiTypeId}&MessageStatus=1`,token);
  }

  UpdateReadNoficationInApp(token :string,data:any): Observable<any> {
    console.log(data.notiapp.id)
    const JSobj =  JSON.stringify(data);
    console.log(JSobj)

    return this._apiService.ReadNoti(`/v1.0/NotiDB/UpdateV2/InAppV2MessageStatus/${data.notiapp.id}/${2}`,token,JSobj);
  }


  UpdateNoficationInApp(data:any): Observable<any> {
    return this._apiService.patch(`/Notification/UpdateInapp`,data);
  }

  GetMenu(userid:number): Observable<TMSAPIResponse> {
    return this._apiService.get(`/Menu/from-user/${userid}`);
  }

  GetPermiss(roId:number): Observable<TMSAPIResponse> {
    return this._apiService.get(`/SettingPermission/Permiss/${roId}`);
  }

  GetSettingMenu(roId:number): Observable<TMSAPIResponse> {
    return this._apiService.get(`/SettingPermission/Menu/${roId}`);
  }

  UpdatePermiss(data:Array<Appmodule>,roId:number,Userid:string): Observable<TMSAPIResponse> {
    return this._apiService.post(`/SettingPermission/UpdateSetting/${roId}/${Userid}`,data);
  }

  UpdateMenu(data:Array<MenuListDto>,roId:number,Userid:string): Observable<TMSAPIResponse> {
    return this._apiService.post(`/SettingPermission/UpdateMenu/${roId}/${Userid}`,data);
  }

  GetClientRole(ClientId:number): Observable<TMSAPIResponse> {
    return this._apiService.get(`/SettingPermission/ClientRole/${ClientId}`);
  }

  GetAction(): Observable<TMSAPIResponse> {
    return this._apiService.get(`/SettingPermission/GetAction`);
  }

 //360 noti
  getNofication360InAppBubble(token :string): Observable<any> {
    return this._apiService.GetToNoti(`/v1.0/NotiDB360TST/get-bubble`,token);
  }

  getNofication360InAppByfontend(UserId: number,token :string,NotiTypeId :number,pagesize: number, page: number = 1): Observable<any> {
    return this._apiService.GetToNoti(`/v1.0/NotiDB360TST/Get/InAppWebV2/ByUserID/${UserId}?NotiTypeId=${NotiTypeId}&pageSize=${pagesize}&page=${page}`,token);
  }
  countUnreadNofication360InAppByfontend(UserId: number,token :string,NotiTypeId :number,dataTake :number): Observable<any> {
    return this._apiService.GetToNoti(`/v1.0/NotiDB360TST/Get/InAppWebV2/ByUserID/${UserId}?MessageStatus=1`,token);
  }
  UpdateNoficationInApp360(token :string,data:any): Observable<any> {
    console.log(data.notiapp.id)
    const JSobj =  JSON.stringify(data);
    console.log(JSobj)

    return this._apiService.ReadNoti(`/v1.0/NotiDB360TST/UpdateV2/InAppV2MessageStatus/${data.notiapp.id}/${2}`,token,JSobj);
  }

}
