import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouteDiagramComponent } from './pages/route-diagram/route-diagram.component';
import { MaterialsModule } from '../materials/materials.module';
import {RouteDiagramRoutingModule} from "@app/modules/route-diagram/route-diagram.routing.module";
import {BookingServiceService} from "@app/modules/booking/service/booking-service.service";

@NgModule({
  declarations: [RouteDiagramComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    MaterialsModule,
    RouteDiagramRoutingModule,
  ],
  providers: [BookingServiceService]
})
export class RouteDiagramModule { }
