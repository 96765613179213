<div class="modal fade" id="editlocation" tabindex="0" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{typeadd?"เพิ่มที่อยู่":"แก้ไขข้อมูลที่อยู่"}}
                </h4>
                <a class="Close"><i class="fal fa-times" data-dismiss="modal"></i></a>
            </div>
            <form [formGroup]="locationDetail" (ngSubmit)="onSubmit()">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label" for="txtcompany">ชื่อบริษัท<span
                                    class="text-danger">*</span></label>
                            <input type="text" id="txtcompany" name="txtcompany" class="form-control"
                                placeholder="ชื่อบริษัท" formControlName="titleName">
                        </div>
                        <div class="col-md-4">
                            <label class="form-label" for="txttaxID">เลขประจำตัวผู้เสียภาษี<span
                                    class="text-danger">*</span></label>
                            <input type="text" id="txttaxID" name="txttaxID" class="form-control"
                                placeholder="เลขประจำตัวผู้เสียภาษี" formControlName="TaxID">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtcomlocation">ที่อยู่<span
                                    class="text-danger">*</span></label>
                            <textarea type="text" id="txtcomlocation" name="txtcomlocation" class="form-control"
                                rows="3" placeholder="ที่อยู่" formControlName="address"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">จังหวัด</label>
                            <ng-select placeholder="จังหวัด" formControlName="provinceId" (change)="onchangeProvince()">
                                <ng-option *ngFor="let item of province?.data" [value]="item.id">{{item.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="this.locationDetail.value.provinceId != null" class="col-md-3">
                            <label class="form-label">อำเภอ/เขต</label>
                            <ng-select placeholder="อำเภอ/เขต" formControlName="districtId" (change)="onchangeDistrict()">
                                <ng-option *ngFor="let item of District?.data" [value]="item.id">{{item.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="this.locationDetail.value.districtId != null"  class="col-md-3">
                            <label class="form-label">ตำบล/แขวง</label>
                            <ng-select placeholder="ตำบล/แขวง" formControlName="subdistrictId">
                                <ng-option *ngFor="let item of SubDistrict?.data" [value]="item.id">{{item.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label" for="txtzipcode">รหัสไปรษณีย์<span
                                    class="text-danger">*</span></label>
                            <input type="text" id="txtzipcode" name="txtzipcode" class="form-control"
                                placeholder="รหัสไปรษณีย์" formControlName="zipcode">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <h5>ผู้ติดต่อหลัก</h5>
                </div>
                <div class="form-group">
                    <div class="row"> 
                        <div class="col-md-4">
                            <label class="form-label" for="txtfirstname">ชื่อ<span class="text-danger">*</span></label>
                            <input type="text" id="txtfirstname" name="txtfirstname" class="form-control"
                                placeholder="ชื่อ" formControlName="firstName">
                        </div>
                        <div class="col-md-4">
                            <label class="form-label" for="txtlastname">นามสกุล<span
                                    class="text-danger">*</span></label>
                            <input type="text" id="txtlastname" name="txtlastname" class="form-control"
                                placeholder="นามสกุล" formControlName="lastName">
                        </div>
                        <div class="col-md-4">
                            <label class="form-label" for="txtcomtel">เบอร์โทรศัพท์<span
                                    class="text-danger">*</span></label>
                            <input type="text" id="txtcomtel" name="txtcomtel" class="form-control" mask="000-000-0009"
                                placeholder="เบอร์โทรศัพท์" formControlName="phoneNo">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="Toplant" formControlName="billing">
                                <label class="custom-control-label" for="Toplant">กำหนดเป็นที่อยู่ในการออกเอกสาร</label>
                            </div>
                        </div>
                    </div>  
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="Toplant2" formControlName="shipping">
                                <label class="custom-control-label" for="Toplant2">กำหนดเป็นที่อยู่จัดส่งเอกสาร</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>
                <button type="button" type="submit" class="btn btn-success">บันทึกข้อมูล</button>
            </div>
        </form>
        </div>
    </div>
</div>