import { Component, Input, OnInit } from '@angular/core';
import { DriverDetailResponse, UserStatementMonth } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
declare function openModal(modelID): any;

@Component({
  selector: 'app-modal-payment-history',
  templateUrl: './modal-payment-history.component.html',
  styleUrls: ['./modal-payment-history.component.scss']
})
export class ModalPaymentHistoryComponent implements OnInit {

  @Input() driver_id: number;

  monthSelected = 1
  month: any[] = []

  userStatementMonths: UserStatementMonth[] = []
  userStatementMonth = new UserStatementMonth()
  userStatementDates: any;

  longThaiMonth = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

  constructor(private driverDetailService: DriverDetailService) { }

  ngOnInit(): void {
    this.getUserStatement()
  }

  onOpenModal(){
    openModal("#modalPaymentHistory");
  }

  async getUserStatement() {
    await this.driverDetailService
      .getUserStatementMonthsByUserId(this.driver_id)
      .subscribe((res) => {
        if (res.isSuccess) {
          if (res.data) {
            this.userStatementMonth = res.data[0]

            console.log("getUserStatatement", res.data);
            this.userStatementMonths = res.data
            console.log("this.userStatementMonth", this.userStatementMonths);

            this.month = []
            res.data.map(c => {
              this.month.push({
                id: `${c.year}-${c.month}`,
                name: this.longThaiMonth[c.month - 1] + ' ' + (c.year + 543),
              });
            })

            this.monthSelected = this.month[0].id

            this.getUserStatementByMonth();
          }
        }
      });
  }

  onselectedMonth() {
    console.log(this.monthSelected);
    
    this.userStatementMonth = this.userStatementMonths.find(
      (c) => {
        let select = `${c.year}-${c.month}`
        return select == this.monthSelected.toString()}
    );
    this.getUserStatementByMonth();
  }

  async getUserStatementByMonth() {
    let select = this.monthSelected.toString().split("-");
    let year = +select[0]
    let month = +select[1]
    console.log('year',year);
    console.log('month',month);

    await this.driverDetailService.getUserStatementByUserId(this.driver_id, year,month)
      .subscribe(res => {
        if (res.isSuccess) {
          console.log("onselectedMonth", res);
          this.userStatementDates = res.data
        }
      })
  }

}
