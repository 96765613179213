<div id="driver-gen-pdf" class="w-100">
    <div class="row">
        <!-- <div class="col-12 text-center">
            <h4>ประวัติคนขับรถ</h4>
        </div> -->
        <div class="col-12 text-center">
            <!-- <h4 class="fw-500">{{driverDetail.firstName}} {{driverDetail.lastName}} - หจก.พัฒนาขนส่ง</h4> -->
            <app-header-file [title]="title" [header]="header"></app-header-file>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('driverdetail')}">
        <span class="col-12 mt-2 mb-2">
            <div class="border border-left-0 border-right-0 border-top-0"></div>
        </span>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('driverdetail')}">
        <div class="col-6">
            <div class="row text-center">
                <div class="col-6">
                    <img src="{{driverDetail.fleetDriver.photoURL}}" class="h-auto w-75 rounded-corners-gradient-borders">
                </div>
                <div class="col-6">
                    <!-- <img src="{{driverDetail.QRCodePhotoURL}}" class="h-auto w-75"> -->
                    <qrcode [qrdata]="driverDetail.fleetDriver.driverURL" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                </div>
                <div class="col-12">
                    <label class="mt-3">{{driverDetail.fleetDriver.driverURL}}</label>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <div class="help-block text-muted">ชื่อ - นามสกุล
                    </div>
                    <div>
                        <label>{{driverDetail.fleetDriver.firstName}} {{driverDetail.fleetDriver.lastName}}</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="help-block text-muted">เบอร์โทร (ใช้เป็น Username ในการเข้าสู่ระบบ)
                    </div>
                    <div>
                        <label>{{driverDetail.fleetDriver.mobileNo}}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="help-block text-muted">เลขบัตรประชาชน</div>
                    <div>
                        <label>{{driverDetail.fleetDriver.idcard || '-'}}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="help-block text-muted">
                        เดือน/ปีเกิด
                    </div>
                    <div>
                      <label *ngIf="driverDetail.fleetDriver.dateOfBirth">{{driverDetail.fleetDriver.dateOfBirth|thaidate:'short'}} ({{driverDetail.fleetDriver.yearOld}} ปี)</label>
                      <label *ngIf="!driverDetail.fleetDriver.dateOfBirth">-</label>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('training-history')}">
        <span class="col-12">
            <div class="border border-left-0 border-right-0 border-top-0 mt-2 mb-2"></div>
        </span>
    </div>

    <!-- <div class="row">
        <div class="col-12">
            <h5>ประสิทธิภาพ</h5>
        </div>
    </div> -->

    <!-- <div class="row">
        <div class="col-4">
            <div class="help-block text-muted">ประจำเดือน</div>
            <div>
                <label>พฤษภาคม 2021</label>
            </div>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">ระยะทางรวม (กิโลเมตร)</div>
            <div>
                <label>{{driverDetail.distance_sum|number:'1.2'}} กม.</label>
            </div>
        </div>
    </div> -->

    <!-- <div class="row">
        <div class="col-4">
            <div class="help-block text-muted">รายได้รวม</div>
            <div>
                <label>{{performancePerMonth.income_per_month|number:'1.2'}}</label>
            </div>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">รายได้ต่อวัน</div>
            <div>
                <label>{{performancePerMonth.income_per_day|number:'1.2'}}</label>
            </div>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">รายได้ต่อกิโล</div>
            <div>
                <label>{{performancePerMonth.income_per_km|number:'1.2'}}</label>
            </div>
        </div>
    </div> -->

    <!-- <div class="row">
        <div class="col-4">
            <div class="help-block text-muted">เที่ยวรวม</div>
            <div>
                <label>{{performancePerMonth.roundTrip_per_month|number:'1.0'}} เที่ยว</label>
            </div>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">เที่ยวต่อวัน</div>
            <div>
                <label>{{performancePerMonth.roundTrip_per_day|number:'1.0'}} เที่ยว</label>
            </div>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">กิโลเมตร/เที่ยว</div>
            <div>
                <label>{{performancePerMonth.roundTrip_per_km|number:'1.0'}} เที่ยว</label>
            </div>
        </div>
    </div> -->

    <!-- <div class="row">
        <span class="col-12">
            <div class="border border-left-0 border-right-0 border-top-0 mt-2 mb-2"></div>
        </span>
    </div> -->

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('training-history')}">
        <div class="col-12">
            <h5>ประวัติการอบรม</h5>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('training-history')}">
        <div class="col-12">
            <div class="row">
                <div class="help-block text-muted col-4">วันที่</div>
                <div class="help-block text-muted col-8">หัวข้อการอบรม</div>
            </div>
        </div>

        <div class="col-12">
            <div class="row" *ngFor="let userTraining of driverDetail.userTrainings">
                <label class="col-4">{{userTraining.date|thaidate:"short"}}</label>
                <label class="col-8">{{userTraining.title}}</label>
            </div>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('salary')}">
        <span class="col-12">
            <div class="border border-left-0 border-right-0 border-top-0 mt-2 mb-2"></div>
        </span>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('salary')}">
        <div class="col-12">
            <h5>เงินเดือน / ค่าเที่ยว</h5>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('salary')}">
        <div class="col-4">
            <div class="help-block text-muted">อัตราเงินเดือน</div>
                    <label *ngIf="driverDetail.userSalaries.length > 0">{{driverDetail.userSalaries[0].salary|number:'1.0'}} บาท/เดือน</label>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">อัตราค่าเที่ยว</div>
                    <label *ngIf="driverDetail.userSalaries.length > 0">{{driverDetail.userSalaries[0].tripWage|number:'1.0'}} บาท/เที่ยว</label>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">Valid since</div>
            <label *ngIf="driverDetail.userSalaries.length > 0">{{driverDetail.userSalaries[0].startDate|thaidate:"short"}}</label>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('salary')}">
        <div class="col-12">
            <div class="row">
                <div class="help-block text-muted col-4">ประวัติปรับค่าจ้าง</div>
                <div class="help-block text-muted col-4">เงินเดือน</div>
                <div class="help-block text-muted col-4">ค่าเที่ยว</div>
            </div>
        </div>

        <div class="col-12">
            <div class="row" *ngFor="let userSalary of driverDetail.userSalaries">
                <label class="col-4">{{userSalary.startDate|thaidate:"short"}}</label>
                <label class="col-4">{{userSalary.salary|number:'1.0'}} บาท</label>
                <label class="col-4">{{userSalary.tripWage|number:'1.0'}} บาท</label>
            </div>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('bankaccount')}">
        <span class="col-12">
            <div class="border border-left-0 border-right-0 border-top-0 mt-2 mb-2"></div>
        </span>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('bankaccount')}">
        <div class="col-12">
            <h5>บัญชี / วอลเล็ตเงินเดือน</h5>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('bankaccount')}">
        <div class="col-4">
            <div class="help-block text-muted">สถานะบัญชี</div>
            <label>ตั้งเป็นบัญชีหลัก</label>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('bankaccount')}">
        <div class="col-4">
            <div class="help-block text-muted">ธนาคาร</div>
            <label>{{driverDetail.userBookbank.bankName}}</label>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">เลขบัญชี</div>
            <label>{{driverDetail.userBookbank.accountNo}}</label>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">ชื่อบัญชี</div>
            <label>{{driverDetail.userBookbank.accountName}}</label>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('payment')}">
        <span class="col-12">
            <div class="border border-left-0 border-right-0 border-top-0 mt-2 mb-2"></div>
        </span>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('payment')}">
        <div class="col-12">
            <h5>ประวัติจ่ายเงิน / เบิกเงิน</h5>
        </div>
    </div>

    <div class="row" [ngClass]="{'d-none' : !topicList.includes('payment')}">
        <div class="col-4">
            <div class="help-block text-muted">สรุปประจำเดือน</div>
            <label>พฤษภาคม</label>
        </div>
        <div class="col-4">
            <div class="help-block text-muted">ค่าจ้างทั้งหมด</div>
            <span *ngIf="driverDetail.userStatementMonth.monthAmount">{{driverDetail.userStatementMonth.monthAmount|number:'1.0'}}
                บาท</span>
            <span *ngIf="!driverDetail.userStatementMonth.monthAmount">-</span>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-4">
                    <div class="help-block text-muted">Order No.</div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">หัวข้อการชำระ</div>
                </div>
                <div class="col-4 text-right">
                    <div class="help-block text-muted">ยอดเงิน</div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div *ngFor="let userStatementDate of driverDetail.userStatementDates"
                class="row">

                <div class="col-12">
                    <label>{{userStatementDate.statementDate|thaidate:'short'}}</label>
                </div>

                <div class="col-12">
                    <div *ngFor="let userStatementDto of userStatementDate.userStatementDtos" class="row">
                        <div class="col-4">
                            <label>{{userStatementDto.statementNo}}</label>
                        </div>
                        <div class="col-4">
                            <label>{{userStatementDto.m_StatementType_Name}}</label>
                        </div>
                        <div class="col-4 text-right">
                            <label>
                                <span *ngIf="userStatementDto.amount >= 0">+</span>
                                <span *ngIf="userStatementDto.amount < 0"></span>
                                {{userStatementDto.amount|number:'1.2'}} บาท
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
