import { DateTime } from "aws-sdk/clients/devicefarm";

export class DriverList {
  firstName: string;
  lastName: string;
  mobileNo: string;
  driverLicenceStatus: boolean;
  driverStatus: boolean;
  deliveryStatus: string;
  fleetCode: string;
  profileImgURL: string;
  photo: string;
  user_ID: number;
  user_Name: string;
  driverLicenceStatusColor: string;
  isEnabled: boolean;
  shipmentStatusID: number;
  shipmentStatusName: string;
  companyFleetNo: number;
  isNotAvariable: boolean;
  avariableText: string;
  dateOfBirth : DateTime
  isHasMobileApp: boolean;
  tst360UserServiceId: number;
  authorizationLevelId: number;
}

export class ImportDriverExcelRequest {
  CliendId: number;
  UserId: number;
  CompanyId: number;
  ServiceTypeId: number | null;
  ExcelData: IDriverImportModel[] = [];
}

export interface IDriverImportModel {
  rowIndex: number;
  firstName: string;
  lastName: string;
  birthDate: string;
  mobileNo: string;
  idCard: string;
}


export const DriverImportHeaders = [
  'firstName',
  'lastName',
  'birthDate',
  'mobileNo',
  'idCard',
];


export interface IncorrectList {
  column: string;
  description: string;
  incorrectType: string;
}


export interface ReturnValidateDriverExcel {
  rowIndex: number;
  isCorrect: boolean;
  incorrectList: IncorrectList[];
  record: IDriverImportModel;
}

export interface driverManagementFilter {
  search:string
  driverLicenseStatus:number
  driverStatus:boolean
  shipmentStatus:number
}
