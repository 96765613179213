import { NgModule } from '@angular/core';

import { AppLayoutComponent } from './app.layout.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { SlidemenuComponent } from './slidemenu/slidemenu.component';
import { LayoutSettingComponent } from './layout-setting/layout-setting.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    // FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AppLayoutComponent,
  ],
  declarations: [
    AppLayoutComponent,
    NavbarComponent,
    SlidemenuComponent,
    LayoutSettingComponent,
    FooterComponent
  ],
  providers: [],
})
export class AppLayoutModule { }
