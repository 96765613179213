import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService } from '@app/core/_Services';
import { AddFleetXDriverListRequest } from '@app/modules/truck-license-management/models/fleet-x-truck-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DriverDetailService } from '../../services/driver-detail.service';
import { FleetXDriverService } from '../../services/fleet-x-driver.service';
declare function closeModal(modalID): any;
@Component({
  selector: 'app-modal-add-fleet-driver',
  templateUrl: './modal-add-fleet-driver.component.html',
  styleUrls: ['./modal-add-fleet-driver.component.scss']
})
export class ModalAddFleetDriverComponent implements OnInit {

  drivers = [];
  @Input() selectedDriver = [];
  @Input() selectedDriverIds = [];
  @Input() currentFleet: number;
  driverList : any
  user: User;
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  @Output() Refresh = new EventEmitter();


  constructor(
    private authen: AuthenticationService,
    private driverDetailService: DriverDetailService,
    private fleetXDriver: FleetXDriverService,
    private noti: NotificationService,
  ) { }
  ngOnInit(): void {
    this.authen.currentUser.subscribe((x) => {
      this.user = x;

      this.getDriver();
    });

  }

  ngOnChanges(): void {
    this.selectedDriverIds = [];
    this.selectedDriver = [];
  }

  // getDriver() {
  //   this.driverDetailService.getDriverFleet(0).then((res) => {

  //     this.driverList = res;
  //     console.log(this.driverList);
  //   });

  //   this.driverDetailService.driverAllOnChange.pipe(takeUntil(this.destroy$)).subscribe(res => {
  //     console.log('driverListOnChange', res);
  //     this.driverList = res;
  //   })

  // }

  async getDriver() {
    await this.driverDetailService.getDriverFleet(0)
      this.driverDetailService.driverAllOnChange.pipe(takeUntil(this.destroy$)).subscribe(res => {
        console.log('driverListOnChange', res);
        this.driverList = res;
      })
  }

  onSelectDriver(event: any) {
    console.log(event)
    console.log(this.selectedDriver)
    this.selectedDriver = event
  }

  onRemoveDriver(item) {
    this.selectedDriver = this.selectedDriver.filter(obj => obj !== item);
    this.selectedDriverIds = this.selectedDriverIds.filter(obj => obj !== item.user_ID);
  }

  onSaveFleetDriver() {

    const data = {
      updateBy: this.user.tstS360_UserID,
      companyId: this.user.tstS360_CompanyID,
      driverUserID: this.selectedDriverIds,
      fleetNo: this.currentFleet,
    } as AddFleetXDriverListRequest;
    console.log(data)

    this.fleetXDriver.AddFleetXDriverList(data).subscribe(x => {
    this.getDriver();
      closeModal("#addFleetDriver");
      this.noti.toastsuccess('สำเร็จ', 'เพิ่มคนขับเข้า FLEET สำเร็จ');
      this.Refresh.emit();
      this.selectedDriver = [];
      this.selectedDriverIds = [];
    });
  }

  closed(){
    closeModal("#addFleetDriver");
  }



}
