<div class="modal fade" id="changepassword" tabindex="0" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    เปลี่ยนรหัสผ่าน
                </h4>
                <a class="Close"><i class="fal fa-times" data-dismiss="modal"></i></a>
            </div>
            <form [formGroup]="Passwordform" (ngSubmit)="onSubmit()">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtpasswordcurrent">รหัสผ่านเดิม<span
                                    class="text-danger">*</span></label>
                            <input type="password" id="txtpasswordcurrent" name="txtpasswordcurrent" class="form-control" formControlName="passwordcurrent"
                                placeholder="รหัสผ่านเดิม">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label" for="txtnewpassword">รหัสผ่านใหม่<span
                                    class="text-danger">*</span></label>
                            <input type="password" id="txtnewpassword" name="txtnewpassword" class="form-control" formControlName="txtnewpassword"
                                placeholder="รหัสผ่านใหม่" minlength="8">
                        </div>
                        <div class="col-md-6">
                            <label class="form-label" for="txtconpassword">ยืนยันรหัสผ่านใหม่<span
                                    class="text-danger">*</span></label>
                            <input type="password" id="txtconpassword" name="txtconpassword" class="form-control" formControlName="txtconpassword"
                                placeholder="ยืนยันรหัสผ่านใหม่" minlength="8">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="text-dark m-0">
                                <i *ngIf="this.Passwordform.value.txtnewpassword?.length > 7" class="fas fa-check-circle text-success"></i>
                                8 ตัวอักขระหรือมากกว่า
                            </h6>                                   
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="text-dark m-0">
                                <i *ngIf="this.Passwordform.value.txtnewpassword == this.Passwordform.value.txtconpassword && this.Passwordform.value.txtnewpassword != '' && this.Passwordform.value.txtnewpassword != null" class="fas fa-check-circle text-success"></i>
                               รหัสยืนยันถูกต้อง
                            </h6>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="text-dark m-0">
                                <i class="fas fa-check-circle text-success"></i>
                                ตัวอักษรพิพพ์ใหญ่และพิมพ์เล็ก
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="text-dark m-0">
                                <i class="fas fa-check-circle text-success"></i>
                                อย่างน้อยหนึ่งหมายเลข
                            </h6>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>
                <button type="button" class="btn btn-success" type="submit" [disabled]="!Passwordform.valid">บันทึกข้อมูล</button>
            </div>
            </form>
        </div>
    </div>
</div>