import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdsServicesService {

  constructor() { }

  getAds(){
  var  ads = [
      "https://file.pub.360truck.co/tst-notifications/Advertising/AdsCoupon.jpg",
      "https://file.pub.360truck.co/tst-notifications/Advertising/AdsTippaya.jpg",
      "https://file.pub.360truck.co/tst-notifications/Advertising/AdsBridgeStone.jpg"
    ];
 return ads;
  }
}
