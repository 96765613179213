import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {  Subject } from 'rxjs';
import { DriverDetailService } from '../../services/driver-detail.service';

@Component({
  selector: 'app-modal-driver-print',
  templateUrl: './modal-driver-print.component.html',
  styleUrls: ['./modal-driver-print.component.scss']
})
export class ModalDriverPrintComponent implements OnInit {

  @Output() selectedDataPrintEvent = new EventEmitter<any>();

  destroy: Subject<boolean> = new Subject<boolean>();
  selectDriverPrintList: any[] = [];

  modalReferenace!: NgbModalRef;
  @ViewChild('modaldriverprint') modaldriverprint!: ModalDriverPrintComponent;
  @ViewChild('htmlData') htmlData: ElementRef;

  constructor(
    private modalService: NgbModal,
    private driverDetailService: DriverDetailService) { 
    }

  ngOnInit(): void {
    this.driverDetailService.selectDriverPrintListOnChange.subscribe(res => {
      this.selectDriverPrintList = res.map(e => {
        return {
          ...e, selected: false
        }
      })
    });
    this.selectedDataPrintEvent.emit(this.selectDriverPrintList);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  open(size?: 'sm' | 'md' | string): void {
    this.ngOnInit();
    if (size) {
      this.modalReferenace = this.modalService.open(this.modaldriverprint, { size: size, centered: true, backdrop: 'static' });
    } else {
      this.modalReferenace = this.modalService.open(this.modaldriverprint, { size: 'sm', centered: true, backdrop: 'static' });

    }
  }

  close(bool: boolean): void {
    this.modalReferenace.close(bool);
  }

  selectChange(event, id: string): void {
    switch (id) {
      case 'all': {
        this.selectDriverPrintList = this.selectDriverPrintList.map(e => {
          return {
            ...e,
            selected: event
          }
        })
        break;
      }
      default: {
        const index = this.selectDriverPrintList.findIndex(e => e.id == id)
        this.selectDriverPrintList[index].selected = event;
        break;
      }
    }
    const index = this.selectDriverPrintList.findIndex(e => e.id == 'all');
    if (this.selectDriverPrintList.filter(e => e.id != 'all').length == this.selectDriverPrintList.filter(e => e.id != 'all' && e.selected).length) {
      this.selectDriverPrintList[index].selected = true;
    } else {
      this.selectDriverPrintList[index].selected = false;
    }
    this.selectedDataPrintEvent.emit(this.selectDriverPrintList);
  }

  getPdf(elementId: string): void {
    this.modalReferenace.close(true);
  }
}

