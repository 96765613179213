import { Component, Input, OnInit } from '@angular/core';
import { AdsServicesService } from '@app/modules/master/service/ads-services.service';

@Component({
  selector: 'app-ads-banner-carousel',
  templateUrl: './ads-banner-carousel.component.html',
  styleUrls: ['./ads-banner-carousel.component.scss']
})
export class AdsBannerCarouselComponent implements OnInit {

  @Input() maxheight: number = 300;
  constructor(
    private adsService : AdsServicesService
  ) { }
    AdsUrlList = [];
  ngOnInit(): void {
    this.getAds()
  }

  getAds(){
    this.AdsUrlList =  this.adsService.getAds();
    //console.log(this.AdsUrlList);
    
  }
}
