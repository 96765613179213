import { Component, OnInit } from '@angular/core';
import { VERSION } from '../../../../environments/version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version:string;
  // ถ้า error ให้ใช้คำสั่ง "npm install"
  constructor() {
    this.version = VERSION.semverString;
  }

  ngOnInit(): void {
  }

}
