<table class="table table-bordered table-hover table-striped w-100 table-sm" id="dataIncorrectTable">
  <thead>
    <tr>
      <th *ngFor="let col of dataImportIncorrectListColumns; let i = index;" [ngClass]="col.class" [ngStyle]="{'width': col.width}">{{col.header}}</th>
    </tr>
  </thead>
  <tbody *ngIf="dataIncorrectList?.length > 0">
    <ng-container #tbody *ngFor="let item of dataIncorrectList">
      <tr>
        <td class="text-center"><span class="h5">{{item.rowIndex | number}}</span></td>
        <td>
          <div *ngFor="let element of item.incorrectList">
            <span class="h5">{{element.column ? element.column + ' : ': ''}}</span>
            <span class="h5 text-danger" *ngIf="element.incorrectType == 'required' || element.incorrectType == 'Required'">{{element.description}}</span>
            <span class="h5 text-blue" *ngIf="element.incorrectType == 'duplicate' || element.incorrectType == 'IdentityDuplicate'
            || element.incorrectType == 'Duplicate'">{{element.description}}</span>
            <span class="h5 text-warning " *ngIf="element.incorrectType == 'PatternNotMath' ">{{element.description}}</span>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
