import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpService } from ".";
import { UserDocVerifyStatusApiPath } from "../_Constants/apiConstants";

@Injectable({
    providedIn: 'root'
})

export class UserDocVerifyStatusService {

    constructor(private _apiService: HttpService,) {

    }

    getDocVerifyStatus(): Observable<any> {
        return this._apiService.get(
            `${UserDocVerifyStatusApiPath.UserDocVerifyStatus}`
        )
    }
}