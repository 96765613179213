<div class="col-xl-12" *ngIf="permission90200.canRead">
    <div id="panel-1" class="panel">
        <div class="panel-container show">
            <div class="panel-content">
                <div class="row">
                    <table class="table table-striped table-sm w-100">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Team ที่ต้องการได้รับการแจ้งเตือน</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of linelist?.data">
                                <td class="text-right">
                                    <h6>{{item.groupNo}}</h6>
                                </td>
                                <td>
                                    <ng-select [(ngModel)]="item.teamIds" placeholder="เลือกทีม" [multiple]="true">
                                        <ng-option *ngFor="let item of teamclient" [value]="item.teamId">{{item.teamName}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-5 d-flex justify-content-center">
                    <div class="col-md-3 text-center">
                        <button type="button" class="btn btn-success btn-block" (click)="OnSave()" [disabled]="linelist == null" *ngIf="permission90200.canEdit">
                            บันทึก</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12"  *ngIf="!permission90200.canRead">
 Access Denied
</div>