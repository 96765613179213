import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name:'UPPERCASE'})
export class UppercasePipe implements PipeTransform {
    transform(value:string):string{
        return value.toUpperCase();
    }
}

@Pipe({name: 'Filename'})
export class FileNamePipe implements PipeTransform {
  transform(value: string): string {
    var filename = value.replace(/^.*[\\\/]/, '');
    return filename;
  }
}

@Pipe({name: 'permission'})
export class PermissionPipe implements PipeTransform {
  transform(value: number[],action:number): boolean {
    if (!value){
      return false;
    }
    const check = value.findIndex(f=>f === action);
    return check > -1;
  }
}

@Pipe({name: 'image'})
export class ImagePipe implements PipeTransform {
  transform(items: any[]): any {
    
    return items.filter(item => (item.resourceUri.includes(".jpg") || item.resourceUri.includes(".png") || item.resourceUri.includes(".JPG") || item.resourceUri.includes(".PNG")) && item.isPhoto == false);
  }
}

@Pipe({name: 'nonimage'})
export class NonImagePipe implements PipeTransform {
  transform(items: any[]): any {
    
    return items.filter(item => (item.resourceUri.includes(".jpg") == false && item.resourceUri.includes(".png")==false && item.resourceUri.includes(".JPG")==false && item.resourceUri.includes(".PNG")==false) && item.isPhoto == false);
  }
}
