<app-breadcrumb Title="Dasboard"></app-breadcrumb>
<div class="row">
    <div class="col-md-4 mt-2">
      <div class="form-group row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label" for="example-datetime-local-input">จากวันที่</label>
            <app-datepicker [id]="'searchStartDate'" [(ngModel)]="search.startDateFrom" [valid]="true"> </app-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mt-2">
      <div class="form-group row">
        <div class="col-12">
            <div class="form-group">
              <label class="form-label" for="example-datetime-local-input">ถึงวันที่</label>
              <app-datepicker [id]="'searchEndDate'" [(ngModel)]="search.startDateTo" [valid]="true"> </app-datepicker>
            </div>
        </div>
      </div>
    </div>
  <div class="col-md-4 mt-2">
    <div class="form-group row">&nbsp;</div>
    <div class="form-group row">
      <div class="col-12">
        <div class="form-group">
          <input type="button" class="mt-3" [id]="'submitSearchDate'" (click)="onSearchDateChange()" value="ค้นหา" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="card">

            <div class="card-header">
                Supply Chain Visibility Dasboard
            </div>
            <div class="card-body">
                <div #drawflow style="height: 70vh"></div>
            </div>
        </div>
    </div>
</div>
