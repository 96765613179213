import { ShipmentTrackingStatusDetailModel } from "@app/core/_Models/BookingModel/ShipmentTrackingStatusModel";
import { TruckBookingModel } from "@app/core/_Models/BookingModel/TruckBookingModel";
import { DropdownModel } from "@app/core/_Models/MasterModel/DropdownModel";
import { ProvinceModel } from "@app/core/_Models/MasterModel/ProvinceModel";

export class ShipmentModel {
}
export interface ShipmentBasicDto {
  shipmentId: number;
  truckBookingId: number | null;
  clientCarrierId: number | null;
  carrierCompanyTruckId: number | null;
  carrierCompanyTailId: number | null;
  carrierDriverId: number | null;
  shipmentNo: string;
  shipmentNoDisplay: string;
  shipmentStatusId: number;
  shipmentStatusName: string;
  productNameDisplay: string;
  betweenLocationCount: number;
  tmsCarrierCompanyName: string;

  originCode: string;
  originLoc: string;
  originProvince: string;
  originProvinceId: number | null;

  descCode: string;
  descLoc: string;
  descProvince: string;
  descProvinceId: number | null;

  truckTypeNamesDisplay: string;
  truckLicense: string;
  truckLicenseProvince: string;
  truckLicenseTail: string;
  truckLicenseTailProvince: string;
  truckTypeName: string;
  truckTypePhoto: string;
  driverFirstname: string;
  driverLastname: string;
  driverMobileNo: string;
  weightTons: number;
  price: number;
  createBy: string;
  assignmentName: string;
  truckBooking: TruckBookingModel;
  firstLocation: TruckBookingLocationModel;
  lastLocation: TruckBookingLocationModel;
  shipmentItems: ShipmentItemDto[];
  starredRouteId: number | null;
  arPrice: number | null;
  apPrice: number | null;
  followupStatusId: number | null;
  followupComment: string;
  truckTypeId: number | null;
  haveTail: boolean | undefined;
  ownerId: number;
  ownerName: string;
  ownerImage: string;

  currentStatus: string;
  currentDate: string | null;
  nextETADate: string | null;
  lastETADate: string | null;
  shipmentStatusDetails: ShipmentTrackingStatusDetailModel[];

  is360: boolean;
  isTmsBasic: boolean;
  isBidPrice: boolean;
  isTmsEnterprise: boolean;
  truckBookingCarrierAssignmentId: number | null;
  noOfTruck: number;

  quota: number;
  quotaUsed:number;
  orderRef : string;
  refShipmentId: number | null;
  refTruckBookingId: number | null;
  refShipmentOriginalClientLogoImage: string | null;
  refShipmentNo: string | null;
  fuelSummary: ShipmentFuelSummaryDto;
}

export interface ShipmentResponse {
  shipments: ShipmentBasicDto[];
  total: number;
}

export interface ShipmentCountResponse {
  total: number;
  current: number;
}

export interface ShipmentTotalDto {
  draft: number;
  booking: number;
  pending: number;
  ongoing: number;
  followCase: number;
  history: number;
  total: number;
  waitConfirm: number;
  waitConfirmPrice: number;
}
export interface TruckBookingLocationModel {
  id: number;
  truckbookingId: number;
  truckBooking: TruckBookingModel;
  clientLocationId: number | null;
  // clientLocation: ClientLocationModel;
  provinceId: number;
  province: ProvinceModel;
  districtId: number | null;
  // district: DistrictModel;
  subDistrictId: number | null;
  // subDistrict: SubDistrictModel;
  shortAddress: string;
  fullAddress: string;
  latitude: number;
  longitude: number;
  placeId: string;
  needLabor: boolean;
  numberOfLabor: number | null;
  contactName: string;
  contactPhone: string;
  dateFrom: string;
  dateTo: string;
  sequence: number;
  distanceFromPrvLoc: number;
  hrsFromPrvLoc: string;
  travellingTimeEstMethodId: number;
  // travellingTimeEstMethod: TravellingTimeEstMethodModel;
  // shipmentLocationTracking: ShipmentLocationTrackingModel[];
  // shipmentPlaning: ShipmentPlaningModel[];
  // truckBookingLocationRemarks: TruckBookingLocationRemarkModel[];
}
export interface ShipmentItemDto {
  iD: number;
  truckBookingProductId: number;
  truckBookingProduct: TruckBookingProductDto;
  shipmentId: number;
  orderItemId: number;
  loadingTypeId: number | null;
  weight_Tons: number;
  packaging_Volume: number;
  handling_Volume: number;
  originLocationId: number | null;
  destLocationId: number | null;
  x_Expense: number | null;
}
export interface TruckBookingProductDto {
  truckBookingId: number;
  productGroupId: number;
  // productGroup: ProductGroupModel;
  clientProductId: number;
  handlingUnitId: number;
  // handlingUnit: HandlingUnitModel;
  weightUnitId: number;
  // weightUnit: WeightUnitModel;
  orderItemId: number;
  productNumber: string;
  productName: string;
  handlingVolume: number;
  weight: number;
  productValue: number;
  packagingVolume: number;
  packagingUnitId: number | null;
  x_OrderId: number;
}

export const DropdownActionList = [
  { value: 1, text: 'ดูรายละเอียด' },
  { value: 2, text: 'สร้างงานใหม่อีกครั้ง' },
  { value: 3, text: 'แก้ไขรถ/คนขับ' },
  { value: 4, text: 'ยกเลิกใช้รถ' },
  //{ value: 9, text: 'ยกเลิกรับงาน' },
] as DropdownModel[];

export const DropdownManageActionList = [
  { value: 1, text: 'สร้างงานใหม่อีกครั้ง'},
  { value: 3, text: 'พิมพ์เอกสาร Invoice'},
  { value: 2, text: 'ยกเลิกงาน'},
  { value: 5, text: 'แจ้งเตือนผู้ขนส่งกรณีมีการเปลี่ยนแปลงข้อมูล'},
] as DropdownModel[];

export class AddTruckDriverRequest {
  // is360Work: boolean | undefined;
  driverUserId: number | undefined;
  carrierCompanyTruckId: number | undefined;
  carrierCompanyTailId: number | undefined;
  truckbookingCarrierassignmentId: number | undefined;
  clientCarrierId: number | undefined;
  truckTypeId: number;
  shipmentId: number;
  shipmentStatusId: number;
  truckLicense: string;
  tailLicense: string;
  actionBy: number;
  isOwnCarrier:boolean
}

export interface ShipmentTruckModel {
  shipmentId: number;
  truckBookingId: number | null;
  companyTruckId: number | null;
  isAvariable: boolean;
}

export interface ShipmentTailModel {
  shipmentId: number;
  truckBookingId: number | null;
  companyTailId: number | null;
  isAvariable: boolean;
}

export interface ShipmentDriverModel {
  isHasMobileApp: boolean;
  shipmentIds: number[];
  truckBookingIds: number[];
  truckBookings: ShipmentDriverIdModel[];
  clientId: number;
  tst360CompanyId: number;
  userId: number;
  photo: string;
  fullname: string;
  mobileNo: string;
  isAvariable: boolean;
}

export interface ShipmentDriverIdModel {
  shipmentId: number;
  truckBookingId: number | null;
  truckBookingNo: string;
  tst360UserId: number | null;
}

export interface ShipmentTruckIddModel {
  shipmentId: number;
  truckBookingId: number | null;
  companyTruckId: number | null;
}

export interface ShipmentFuelSummaryDto {
  shipmentId: number;
  estPrice: number;
  budgetAmount: number;
  budgetBalance: number;
  expenditure: number;
  sumCouponActualAmount: number;
}

export interface ShipmentSettingModel {
  shipmentId: number;
  truckBookingId: number;
  shipmentStatusTemplate: number;
  confirmedType: number;
  isTruckPlus: boolean;
  isShowDOHistory: boolean;
  isConfirmDispatch: boolean;
  showDOHistoryText: string;
}