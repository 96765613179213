<div class="row" *ngIf="!onlyLoc">
    <div class="col-12">
        <div class="card">

            <!-- <div class="card-header">
                <div class="row p-0 m-0" >
                    <div class="col">
                        <h4>{{data.truckBookingNo}} : {{truckTypeName}}</h4>
                    </div>
                    <div class="col-auto">
         
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" name="{{data.truckBookingNo}}" id="{{data.truckBookingNo}}"
                            [checked]="isSelect"
                            (change)="onselect(!isSelect)"/>
                            <label class="custom-control-label" for="{{data.truckBookingNo}}">นำไปสร้างShipment</label>
                        </div>
                       
                    </div>
                </div>
            </div> -->

            <div class="card-header">
                <h4>{{data.truckBookingNo}} : {{truckTypeName}}</h4>
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" name="{{data.truckBookingNo}}"
                        id="{{data.truckBookingNo}}" [checked]="isSelect" (change)="onselect(!isSelect)" />
                    <label class="custom-control-label" for="{{data.truckBookingNo}}">นำไปสร้างShipment</label>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2 ">
                        <i class="fas fa-route"></i> Route :
                    </div>
                    <div class="col route">

                        <span *ngFor="let ac of Load">
                            <i class="fas fa-warehouse"></i>
                            {{findLocation(ac,true,false)}} &nbsp;
                        </span>
                        <span *ngFor="let ac of Drop;let i = index">
                            <i class="fas fa-paper-plane"></i> {{findLocation(ac,false,false)}} &nbsp;
                        </span>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-2">
                        <i class="fas fa-boxes"></i> Order Number :
                    </div>
                    <div class="col">
                        <span *ngFor="let customer of data.customer; let i = index">
                            <span *ngFor="let order of customer.customerOrder; let j = index">
                                <span for="" *ngIf="i+j > 0"> <br></span>
                                <span> {{order.ref_no_1}} น้ำหนัก {{findOrderWeight(order) | number: '1.2-2'}} กก.
                                </span>
                            </span>
                        </span>
                        <span>
                            รวม {{sumWeight | number: '1.2-2'}} กก.
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <i class="fas fa-user-hard-hat"></i> Action :
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-12 load" *ngFor="let ac of Load">
                                <div class="row">
                                    <div class="col-auto">
                                        <i class="fas fa-truck-loading"></i> Load {{findLocation(ac,true)}}

                                    </div>
                                    <div class="col-auto">
                                      
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">เวลาจองคิว</span>
                                            </div>
                                            <input type="time" class="form-control" [(ngModel)]="timeForm"
                                                (change)="timeFromChange(ac,$event)">
                                            <div class="input-group-append input-group-prepend">
                                                <span class="input-group-text">ถึง</span>
                                            </div>
                                            <input type="time" class="form-control" [(ngModel)]="timeTo"
                                                (change)="timeToChange(ac,$event)">
                                        </div>
                                    </div>
                                </div>

                                <div *ngFor="let order of ac.orders">
                                    <div class="row">
                                        <div class="col-md-auto">
                                            <div> Orde Number : {{order.order_number}}</div>
                                        </div>
                                        <div class="col">
                                            <div *ngFor="let item of order.items">
                                                {{item.item_number}} : {{item.material.name}} : จำนวน {{item.qty |
                                                number:'1.2-2'}} : {{item.weight | number:'1.2-2'}} กก.
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 drop" *ngFor="let ac of Drop">
                                <div class="row">
                                    <div class="col-auto">
                                        <i class="fas fa-truck-loading"></i> Drop {{findLocation(ac,false)}}

                                    </div>


                                    <div class="col-auto">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">เวลาส่งสินค้า</span>
                                            </div>
                                            <input type="time" class="form-control" [(ngModel)]="timeForm"
                                                (change)="timeFromChange(ac,$event)">
                                            <div class="input-group-append input-group-prepend">
                                                <span class="input-group-text">ถึง</span>
                                            </div>
                                            <input type="time" class="form-control" [(ngModel)]="timeTo"
                                                (change)="timeToChange(ac,$event)">
                                        </div>
                                    </div>
                                </div>
                                <span class="badge badge-info badge-pill" *ngIf="findLocRemark(ac) != ''">
                                    {{findLocRemark(ac)}}
                                </span>
                                <div *ngFor="let order of findLocItem(ac)">
                                    <div class="row">
                                        <div class="col-md-auto">
                                            <div>Orde Number : {{order.order_number}}</div>
                                        </div>
                                        <div class="col">
                                            <div *ngFor="let item of order.items">
                                                {{item.item_number}} : {{item.material.name}} : {{item.weight | number:
                                                '1.2-2'}} กก.
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        Start :
                    </div>
                    <div class="col-auto">

                        {{startLoc.dateTimeFrom.toString().slice(0, -1) | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                    <div class="col-auto">
                        To
                    </div>
                    <div class="col-auto">

                        {{startLoc.dateTimeTo.toString().slice(0, -1) | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        End :
                    </div>
                    <div class="col-auto">
                        {{endLoc.dateTimeFrom.toString().slice(0, -1) | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                    <div class="col-auto">
                        To
                    </div>
                    <div class="col-auto">
                        {{endLoc.dateTimeTo.toString().slice(0, -1) | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-2">
                                SAP :
                            </div>
                            <div class="col-auto">
                                Shiping Condition : {{data?.sap_cost?.shipment_condition}}
                            </div>
                            <div class="col-auto">
                                Shiping Cost : {{data?.sap_cost?.costing | number: '1.2-2'}} บาท
                            </div>

                            <div class="col-auto">
                                Drop Cost : {{data?.sap_cost?.drop_costing | number: '1.2-2'}} บาท
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row" *ngIf="isExfac">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-2">
                                Truck License :
                            </div>
                            <div class="col-auto">
                                <input type="text" class="form-control" [(ngModel)]="data.truck_license">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row p-2" *ngIf="onlyLoc">
    <div class="col-12">
        <div class="row">
            <div class="col-12 load" *ngFor="let ac of Load">
                <div class="row">
                    <div class="col-auto">
                        <i class="fas fa-truck-loading"></i> Load {{findLocation(ac,true)}}

                    </div>
                    <div class="col-auto">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">เวลาจองคิว</span>
                            </div>
                            <input type="time" class="form-control" [(ngModel)]="timeForm"
                                (change)="timeFromChange(ac,$event)">
                            <div class="input-group-append input-group-prepend">
                                <span class="input-group-text">ถึง</span>
                            </div>
                            <input type="time" class="form-control" [(ngModel)]="timeTo"
                                (change)="timeToChange(ac,$event)">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 drop p-2" *ngFor="let ac of Drop">
                <div class="row">
                    <div class="col-auto">
                        <i class="fas fa-truck-loading"></i> Drop {{findLocation(ac,false)}}
                        <span *ngIf="ac.province"> ( {{ac.province}} ) </span>
                    </div>


                    <!-- <div class="col-auto">
                        <div class="input-group">
                            <input type="time" class="form-control" [(ngModel)]="timeForm"
                                (change)="timeFromChange(ac,$event)">
                            <div class="input-group-append input-group-prepend">
                                <span class="input-group-text">ถึง</span>
                            </div>
                            <input type="time" class="form-control" [(ngModel)]="timeTo"
                                (change)="timeToChange(ac,$event)">
                        </div>
                    </div> -->
                </div>
                <span class="badge badge-info badge-pill" *ngIf="findLocRemark(ac) != ''">
                    {{findLocRemark(ac)}}
                </span>

                <div class="row">
                    <div class="col-12">

                        <div *ngFor="let order of findLocItem(ac)">
                            <div class="row">
                                <div class="col-md-auto">
                                    <div>Orde Number : {{order.order_number}}</div>
                                </div>
                                <!-- <div class="col">
                                    <div *ngFor="let item of order.items">
                                        {{item.item_number}} : {{item.material.name}} : {{item.weight | number:
                                        '1.2-2'}} กก.
                                    </div>
                                </div> -->
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
        <div class="row" *ngIf="isExfac">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-2">
                        Truck License :
                    </div>
                    <div class="col-auto">
                        <input type="text" class="form-control" [(ngModel)]="data.truck_license">
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>