import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Customernote } from 'src/app/modules/master/model/CustomerNote';
import { FormControl, FormGroup } from '@angular/forms'
import { RemarkComponentsModel } from 'src/app/core/_Models/RemarkComponentsModel';
import { bool } from 'aws-sdk/clients/signer';
@Component({
  selector: 'app-remark',
  templateUrl: './remark.component.html',
  styleUrls: ['./remark.component.scss']
})
export class RemarkComponent implements OnInit, OnChanges {

  @Input() Customernote: Customernote = new Customernote();
  @Input() index: number = 0;
  @Input() subIndex: number = 0;
  @Input() name: string = "name"
  @Input() dataEdit: FormGroup;
  @Input() TypeAction: number = 1;
  @Input() disabled: boolean = false;
  @Output() indexRemark: EventEmitter<number> = new EventEmitter();
  @Output() indexAndSub : EventEmitter<RemarkIndex>  = new EventEmitter();
  @Output() CustomerRemark: EventEmitter<Customernote> = new EventEmitter();
  @Output() remark: EventEmitter<RemarkComponentsModel> = new EventEmitter();
  remarknote = new FormControl('');
  constructor() { }
  ngOnChanges() {
    // console.log(this.dataEdit);
    if (this.dataEdit != null) {
      this.remarknote.setValue(this.dataEdit.value.remark);
      this.Item.text = this.dataEdit.value.remark;
      if( this.dataEdit.value.sendto != null)
      {
        this.dataEdit.value.sendto.forEach(e => {
          this.Item.sentTo.push(e);
        });

      }

      //this.checksentTo();
      this. check();
    }
  }

  Item: RemarkComponentsModel = new RemarkComponentsModel();
  to1: bool = false;
  to2: bool = false;
  to3: bool = false;
  ngOnInit(): void {
    this.addRemark();
  }

  addRemark() {
    this.CustomerRemark.emit(this.Customernote)
  }

  removeCustomerRemark(index) {
    console.log(index);
    this.indexRemark.emit(index)
    var rm = new RemarkIndex()
    rm.index = index;
    rm.subindex = this.subIndex;
    this.indexAndSub.emit(rm);
  }

  onSearchChange(searchValue: string): void {
    //  this.Customernote.remark = searchValue;
  }

  textChange(event) {
    this.Item.text = event;
    this.remark.emit(this.Item)
  }

  check()
  {
    var sendto1 = this.Item.sentTo.indexOf(3);
    if(sendto1 != -1)
    {
      this.to1 = true;
    }
    else
    {
      this.to1=false;
    }

    var sendto2 = this.Item.sentTo.indexOf(2);
    if(sendto2 != -1)
    {
      this.to2 = true;
    }
    else
    {
      this.to2=false;
    }

    var sendto3 = this.Item.sentTo.indexOf(5);
    if(sendto3 != -1)
    {
      this.to3 = true;
    }
    else
    {
      this.to3=false;
    }
  }
  Toplant() {
    let value = this.Item.sentTo.findIndex(a => a === 3);
    if (value != -1) {
      this.Item.sentTo.splice(value,1);
    } else {
      this.Item.sentTo.push(3);
    }
    console.log(this.Item)

    this.remark.emit(this.Item)
  }

  Tosales() {
    let value = this.Item.sentTo.findIndex(a => a === 2);
    if (value != -1) {
      this.Item.sentTo.splice(value,1);
    } else {
      this.Item.sentTo.push(2);
    }
    console.log(this.Item)

    this.remark.emit(this.Item)
  }

  Todriver() {
    let value = this.Item.sentTo.findIndex(a => a === 5);
    if (value != -1) {
      this.Item.sentTo.splice(value,1);
    } else {
      this.Item.sentTo.push(5);
    }
    console.log(this.Item)
    this.remark.emit(this.Item)
  }

  async checksentTo() {
    if (this.dataEdit.value.sendto != null) {
      this.Item.sentTo = await this.dataEdit.value.sendto;

      var send1 = this.Item.sentTo.indexOf(3);
      var send2 = this.Item.sentTo.indexOf(2);
      var send3 = this.Item.sentTo.indexOf(5);

      if (send1 != -1) {
        this.to1 = true;
      }
      if (send2 != -1) {
        this.to2 = true;
      }
      if (send3 != -1) {
        this.to3 = true;
      }
    }
  }
}

class RemarkIndex{
  index:number;
  subindex:number;
}
