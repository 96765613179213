import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamdatabaseComponent } from './pages/teamdatabase/teamdatabase.component';
import { TeaminternalComponent } from './components/teaminternal/teaminternal.component';
import { TeamexternalComponent } from './components/teamexternal/teamexternal.component';
import { TeammemberComponent } from './pages/teammember/teammember.component';
import { MaterialsModule } from '../materials/materials.module';
import { ModalAddteammemberComponent } from './components/modal-addteammember/modal-addteammember.component';
import { AllUserComponent } from './components/all-user/all-user.component';
import { ModalAdduserComponent } from './components/modal-adduser/modal-adduser.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModaladdteamComponent } from './components/modaladdteam/modaladdteam.component';
import { ModalEditUserComponent } from './components/modal-edit-user/modal-edit-user.component';
import { TeamService } from '@app/modules/team/services/team.service';
import { ClientUserService } from '@app/modules/team/services/client-user.service';
import { TeamRoutingModule } from '@app/modules/team/team-routing.module';
import {ScopeFilterPipe} from "@shared/Pipes/ScopeFilter.pipe";
import { SharedModule } from '@app/shared/shared.module';
import { DriverManagementModule } from '../driver-management/driver-management.module';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [
    TeamdatabaseComponent,
    TeaminternalComponent,
    TeamexternalComponent,
    TeammemberComponent,
    ModalAddteammemberComponent,
    AllUserComponent,
    ModalAdduserComponent,
    ModaladdteamComponent,
    //SearchFilterPipe,
    ScopeFilterPipe
  ],
  imports: [
    TeamRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialsModule,
    NgxDropzoneModule,
    NgSelectModule,
    SharedModule,
    DriverManagementModule,
    NgxMaskModule
  ],
  exports:[
    ModalEditUserComponent
  ],
  providers: [TeamService, ClientUserService],
})
export class TeamModule {}
