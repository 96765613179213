import {ClientModule, UserPermission} from "@app/core/_Models/UserModel/UserPermission";
import { ResgisterPackageModel } from "@app/core/_SignUp/models/signup.model";

export class User {
    id: number = 1;
    ClientID:number;
    access_token?: string;
    tstS360_CompanyID:number;
    tstS360_UserID:number;
    phoneNumber:string;
    email:string;
    fullName:string;
    firstName:string;
    lastName:string;
    photoURL:string;
    signature: string = new Date().getMonth()+"_360truckTms";
    team:UserTeam[];
    department:UserDepartment;
    client : UserClient;
    appPackageSubscriptionIdList: number[];
    registerPackage: ResgisterPackageModel;
    companyTypeId: number;
 ///   Model
    Role : UserRole;
    Menu : UserMenu;
    Permission : UserPermission;

    clientModules: ClientModule[] = [];
  }

  export class UserMenu {
    Name : string;
    Link : string;
  }
  export class UserTeam {
    teamNumber : string;
    teamName : string;
    teamStatus : string;
    clientID : number;
    deletedOn : string;
    deletedBy : string;
    modifiedBy : string;
    createdBy : string;
    valid : boolean;
    id : number;
    createdOn : string;
    modifiedOn : string;
  }
  export class UserDepartment {
    departmentName:string;
    deletedOn:string;
    deletedBy:string;
    modifiedBy:string;
    createdBy:string;
    valid:string;
    id:number;
    createdOn:string;
    modifiedOn:string;
  }
  export class UserClient {
    id : number;
    clientName : string;
    tsT360_CompanyID : string;
    isTruckPlus : boolean
  }

  export class UserRole {
    roleName:string;
    departmentID:number;
    roleID : number;
    id : number;
  }

export enum UserServiceTypeEnum {
  Carrier = 3,
  Shipper = 2,
}
