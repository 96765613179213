import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingSettingComponent } from './page/booking-setting/booking-setting.component';
import { SettingComponent } from './page/setting/setting.component';
import { MaterialsModule } from '../materials/materials.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProductSettingComponent } from './page/product-setting/product-setting.component';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import {ServicesettingService} from "@app/modules/setting/service/servicesetting.service";
import { SharedModule } from '@app/shared/shared.module';
import { LoadLimitSettingComponent } from './page/load-limit-setting/load-limit-setting.component';

@NgModule({
  declarations: [
    BookingSettingComponent,
    SettingComponent,
    ProductSettingComponent,
    LoadLimitSettingComponent
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,

  ],
  providers: [ServicesettingService]
})
export class SettingModule { }
