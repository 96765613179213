<ng-container *ngIf="isShownConfirmButton()">
  <div class="row mt-3">
    <div class="col-md-6 col-auto d-flex align-items-center">
      <div class="d-flex align-items-center"
          *ngIf="isShownComfirmBy()">
        ยืนยันราคาโดย :
        {{ shipmentConfirmPrice?.confirmPriceBy }}
        {{ shipmentConfirmPrice?.confirmPriceDate | thaidate: "datetime" }}
      </div>
      <div class="d-flex align-items-center"
          *ngIf="isShownCancelBy()">
        ยกเลิกยืนยันราคาโดย :
        {{ shipmentConfirmPrice?.cancelConfirmPriceBy }}
        {{ shipmentConfirmPrice?.cancelConfirmPriceDate | thaidate: "datetime" }}
      </div>
    </div>
    <div class="col-md-6 col-auto d-flex justify-content-end">
      <button class="btn btn-sm btn-dark"
          *ngIf="!isAllowToCancelConfirmPrice()"
          (click)="onConfirmPrice()"
          [disabled]="isDisabledConfirmButton()">
        {{ confirmButtonName }}
      </button>
      <button class="btn btn-sm btn-outline-dark"
          *ngIf="isAllowToCancelConfirmPrice()"
          (click)="onCancelConfirmPrice()"
          [disabled]="shipmentStatusId === 40">
        ยกเลิกยืนยันราคา
      </button>
    </div>
  </div>
</ng-container>
<app-modal-confirm #modalConfirm></app-modal-confirm>
<app-modal-success #modalSuccess></app-modal-success>