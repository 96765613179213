<div class="page-wrapper">
  <div class="page-inner bg-brand-gradient">
    <div class="page-content-wrapper bg-transparent m-0">
      <!-- BEGIN Page Header -->
      <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
        <div class="d-flex align-items-center justify-content-center container p-0">
          <div
            class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9 border-0">
            <a href="javascript:void(0)" class="page-logo-link press-scale-down d-flex align-items-center">
              <img src="assets/img/360techx/360techx_logo_horizontal_nobg_white 2.png" alt="SmartAdmin WebApp"
                aria-roledescription="logo">
              <span class="page-logo-text mr-1"></span>
            </a>
          </div>
        </div>
      </div>

      <main id="js-page-content" role="main" class="page-content" >

        <div class="flex-1"
        style="background: url(assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
        <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
          <div class="row">
            <div class="col col-md-6 col-lg-7 hidden-sm-down">
              <h2 class="fs-xxl fw-500 mt-4 text-white">
                360TECHX TMS Platform
                <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60">
                  Streamlining Supply Chain with 360TMS
                </small>
              </h2>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
              <h1 class="text-white fw-300 mb-3 d-sm-block d-md-none">
                กรุณาเข้าสู่ระบบ
              </h1>
              <div class="card p-4 rounded-plus bg-faded">
                <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
                  <div class="form-group">
                    <label class="form-label" for="username">Username</label>
                    <input type="text" id="username"
                      class="form-control form-control-lg {{loginForm.controls.username.invalid?'is-invalid':'is-valid'}}"
                      placeholder="กรอกผู้ใช้งาน" formControlName="username" required>
                    <div *ngIf="loginForm.controls.username.invalid" class="invalid-feedback">
                      <div *ngIf="loginForm.controls.username.errors.required">
                        กรุณากรอกผู้ใช้งาน
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="password">Password</label>
                    <div class="input-group">
                      <input type="password" id="password"
                        class="form-control form-control-lg {{loginForm.controls.password.invalid?'is-invalid':'is-valid'}}"
                        placeholder="รหัสผ่าน" formControlName="password" required>
                      <div class="input-group-append">
                        <span class="input-group-text border-left-0">
                          <a class="text-info" routerLink="/forgetpassword">ลืมรหัสผ่าน</a>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="loginForm.controls.password.invalid" class="invalid-feedback">
                      <div *ngIf="loginForm.controls.password.errors.required">
                        กรุณากรอกรหัสผ่าน
                      </div>
                      <div *ngIf="loginForm.controls.password.errors.minlength">
                        กรุณากรอกรหัสผ่าน
                      </div>
                    </div>
                  </div>
                  <div class="form-group text-left">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="rememberme" formControlName="remember">
                      <label class="custom-control-label" for="rememberme">จดจำการเข้าใช้งาน</label>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col-12 pl-lg-1 my-2">
                      <button *ngIf="!onLoging" type="submit" [disabled]="!loginForm.valid"
                        class="btn btn-success btn-block btn-lg">
                        เข้าสู่ระบบ
                      </button>
                      <button *ngIf="onLoging" class="btn btn-success btn-block btn-lg" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        กำลังเข้าสู่ระบบ...
                      </button>
                    </div>
                    <p style="color:red">{{error}}</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


      </main>
    </div>

  </div>
</div>
