export class DriverDetailResponse {
  fleetDriver: FleetDriverResponse = new FleetDriverResponse();
  userTrainings: UserTrainingResponse[] = []
  userSalaries: UserSalaryResponse[] = [];
  userBookbank: UserBookbankResponse = new UserBookbankResponse();
  userStatementMonth = new UserStatementMonth();
  userStatementDates: any
}

export class FleetDriverResponse {
  companyFleetNo: number|undefined
  dateOfBirth: Date|undefined
  email: string|undefined
  firstName: string|undefined
  fleetID: number|undefined
  fleetName: string|undefined
  idcard: string|undefined
  lastName: string|undefined
  mobileNo: string|undefined
  photoURL: string|undefined
  userDriverLicenceUrl: string|undefined
  userID: number|undefined
  userIdCardUrl: string|undefined
  yearOld: number|undefined
  shipmentStatusName: string|undefined
  driverURL: string|undefined
}

export class UserTrainingResponse {
  id: number | undefined
  user_ID: number | undefined
  date: string | undefined
  title: string | undefined
  detail: string | undefined
  result: number | undefined
  createdBy: string | undefined
  trainingStatus: number | undefined
  userTrainingFiles: UserTrainingFileResponse[] = []
}

export class UserTrainingFileResponse {
  id: number | undefined
  userTraining_ID: number | undefined
  filePath: string | undefined
}

export class UserTrainingForm {
  id: number | undefined
  user_ID: number | undefined
  date: string | undefined
  title: string | undefined
  detail: string | undefined
  result: number | undefined
  createdBy: number | undefined
  userTrainingFiles: UserTrainingFileResponse[] = []
  trainingDoc: any[] = [];
  tst360_CompanyID: number;
}

export class UserSalaryResponse {
  createdBy: number | undefined
  createdOn: Date | undefined
  createdName: string | undefined
  endDate: Date | undefined
  id: number | undefined
  modifiedBy: number | undefined
  modifiedName: string | undefined
  salary: number | undefined
  salaryUnit_ID: string | undefined
  startDate: string | undefined
  tripWage: number | undefined
  tripWageUnit_ID: string | undefined
  user_ID: string | undefined
}

export class UserSalaryForm {
  createdBy: number | undefined
  endDate: Date | undefined
  id: number | undefined
  modifiedBy: number | undefined
  modifiedName: string | undefined
  salary: number | undefined
  salaryUnit_ID: number | undefined
  startDate: Date | undefined
  tripWage: number | undefined
  tripWageUnit_ID: number | undefined
  user_ID: number | undefined
  tst360_CompanyID: number;
}

export class UserBookbankResponse {
  id: number | undefined
  user_ID: number | undefined
  bankName_ID: number | undefined
  bankName: string | undefined
  accountNo: string | undefined
  accountName: string | undefined
  bankBranch: string | undefined
  filePath: string | undefined
  defaultFlag: boolean | undefined
}

export class UserBookbankForm {
  id: number | undefined
  user_ID: number | undefined
  bankName_ID: number | undefined
  accountNo: string | undefined
  accountName: string | undefined
  bankBranch: string | undefined
  filePath: string | undefined
  defaultFlag: boolean | undefined
  bookbankDoc: File[] = [];
  tst360_CompanyID: number;
}

export class UserChangePassword {
  userPoolEnum: number | undefined
  appPackageID: number | undefined
  userID: number | undefined
  username: string | undefined
  newPassword: string | undefined
  confirmPassword: string | undefined
  resetTypeEnum: number | undefined
  oldPassword: string | undefined
  resetToken: string | undefined
  ownerId: number | undefined
  ownerPassword: string | undefined
}

export class UserChangeEmail {
  userPoolEnum: number | undefined
  appPackageID: number | undefined
  companyID: number | undefined
  identityUserId: string | undefined
  newEmail: string | undefined
}

export class UserChangePhoneNumber {
  userPoolEnum: number | undefined;
  appPackageID: number | undefined;
  identityUserId: string | undefined;
  phoneNumber: string | undefined;
  newPhoneNumber: string | undefined;
}

export class UserVerifyOTP {
  userPoolEnum: number | undefined;
  appPackageID: number | undefined;
  identityUserId: string | undefined;
  phoneNumber: string | undefined;
  otpCode: string | undefined;
  tokenType: number | undefined;
}

export class UserConfirmChangePhoneNumber {
  userPoolEnum: number | undefined;
  appPackageID: number | undefined;
  identityUserId: string | undefined;
  phoneNumber: string | undefined;
  token: string | undefined;
}

export class UserStatementMonth {
  month: number | undefined
  year: number | undefined
  monthAmount: number | undefined
  tripWageAmount: number | undefined
  id: {month,year}
}

export interface SaveChangeFleetRequest {
  updateBy: number;
  companyId: number;
  user_ID: number;
  fleetNo: number;
}
