import {Component, OnDestroy, OnInit} from '@angular/core';
import {TMSAPIResponse} from '@app/core/_Models/TMSAPIResponse';
import {User} from '@app/core/_Models/UserModel/User';
import {AuthenticationService, NotificationService} from '@app/core/_Services';
import {TeamService} from '@app/modules/team/services/team.service';
import {NoficationserviceService} from '../../service/noficationservice.service';
import {takeUntil} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import { Operation, PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { ServicesettingService } from '@app/modules/setting/service/servicesetting.service';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';

@Component({
  selector: 'app-linegroup',
  templateUrl: './linegroup.component.html',
  styleUrls: ['./linegroup.component.scss']
})
export class LinegroupComponent implements OnInit, OnDestroy {

  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  permission90200 : PermissionGroup= {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  constructor(private teamservice: TeamService, private notiservice: NoficationserviceService, private authService: AuthenticationService,
              private _noti: NotificationService,private sv : ServicesettingService) {
                this.authService.currentUser.subscribe((x) => {this.user = x;
                  var p: PermissionModule = this.authService.UserPermissionModule(90000);
                  if (p) {
                    console.log('permission', p);
            
                    this.permission90200 = this.authService.FindPermissionAction(
                      90200,
                      p.operations
                    );
            
                  }
                    });
  }

  teamclient: TMSAPIResponse;
  linelist: any;
  user: User;

  ngOnInit(): void {
    

    this.getteam();
    this.getsetting();
    //this.permission90200 = this.sv.GetPermission(90000,90200);

  }

   getteam() {
     this.teamservice.GetTeamListWithClient().subscribe((res: TMSAPIResponse) => {
  
      if (!res.isSuccess) {
        this._noti.toasterror("ไม่สามารถดึงข้อมูลได้", res.message);

      } else {
        this.teamclient = res.data;
      }
    });
  }

  linegroup = [
    {id: 1, groupline: "Line Group 1", TeamIds: []},
    {id: 2, groupline: "Line Group 2", TeamIds: []},
    {id: 3, groupline: "Line Group 3", TeamIds: []},
    {id: 4, groupline: "Line Group 4", TeamIds: []},
    {id: 5, groupline: "Line Group 4", TeamIds: []}
  ];

  async getsetting() {
    this.linelist = await this.notiservice.GetGroupLine(this.user.ClientID);
    console.log(this.linelist);
  }

  async OnSave() {
    console.log("teamclient", this.linelist);
    this.notiservice.updateGroupline(this.user.ClientID, this.user.id.toString(), this.linelist?.data).subscribe
    (
      (data) => 
      {
        let result = data;
        if(result?.isSuccess)
        {
          this._noti.toastsuccess("บันทึกข้อมูลเรียบร้อย", "");
        }else
        {
          this._noti.toasterror("บันทึกข้อมูลไม่สำเร็จ", "");
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
