<ng-template #modalsuccess let-modal>
  <div class="modal-header bg-success text-center text-white">
    <div class="col-12 p-0">
      <span *ngIf="title_icon === 'fa-check-circle'" class="fal fa-check-circle fa-9x"></span>
      <span *ngIf="title_icon === 'fa-user-circle'" class="fal fa-user-circle fa-9x"></span>
      <span *ngIf="title_icon === 'fa-truck-circle'" class="fa-stack fa-6x">
        <i class="fal fa-circle fa-stack-2x"></i>
        <i class="fal fa-truck fa-stack-1x"></i>
      </span>
      <span *ngIf="title_icon === 'fa-box-open'" class="fa-stack fa-6x">
        <i class="fal fa-circle fa-stack-2x"></i>
        <i class="fal fa-box-open fa-stack-1x"></i>
      </span>
      <button type="button" class="close pt-0 pl-0 pb-0 pr-1" style="color:white !important" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div *ngIf="title_text" class="mt-3">
        <label class="font-weight-bold mb-0" style="white-space: pre-wrap" [ngClass]="title_class">{{title_text}}</label>
      </div>
    </div>
  </div>
  <div class="modal-body pb-0">
    <div *ngIf="body_title" class="row mb-2">
      <div class="col-12 text-center">
        <label class="font-weight-bold mb-0" style="white-space: pre-wrap" [ngClass]="body_title_class">{{body_title}}</label>
      </div>
    </div>
    <div *ngIf="body_text" class="row">
      <div class="col-12 text-center">
        <label class="mb-0" style="white-space: pre-wrap" [ngClass]="body_text_class">{{body_text}}</label>
      </div>
    </div>
    <!-- <div *ngIf="body_textclipboard" class="row mt-2">
      <div class="col-auto mb-2">
        <label class="mb-0" style="white-space: pre-wrap" [ngClass]="body_textclipboard_class">{{body_textclipboard}}</label>
      </div>
      <div class="col-auto">
        <button id="btnclipboard" type="button" class="btn btn-success waves-effect waves-themed font-weight-bold" ngxClipboard [cbContent]="btnclipboard_content" (cbOnSuccess)="btnclipboard_onsuccess($event)">
          <span [ngClass]="btnclipboard_icon" btnclipboard_icon class="mr-1"></span>
          {{btnclipboard_text}}
        </button>
      </div>
    </div> -->
  </div>
  <div class="modal-footer p-2 pt-4">
  <!-- <div class="modal-footer justify-content-between"> -->
   
    <button *ngIf="!btnother_ishide" id="btnother" type="button" class="btn btn-outline-success waves-effect waves-themed font-weight-bold" [ngStyle]="{'width': !btnsubmit_ishide ? '47%' : '100%'}" (click)="btnother_click()">{{btnother_text}}</button>
    <button *ngIf="!btnsubmit_ishide" id="btnsubmit" type="button" class="btn btn-success waves-effect waves-themed font-weight-bold" [ngStyle]="{'width': !btnother_ishide ? '47%' : '100%'}" (click)="btnsubmit_click()">{{btnsubmit_text}}</button>
   
  </div>
</ng-template>
