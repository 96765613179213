export class BookingModel{
  id:number;
  booking_no:string;
  do:string;
  factory:string;
  delivered_date:Date;
  delivered_time:string;
  customer_name:string;
  destination_name:string;
  destination_detail:string;
  destination_location:string;
  deliver_status:deliver_status;
}

export enum deliver_status{
  success = 1,
  late = 2
}
