<div class="modal fade" id="Editcompany" tabindex="0" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    แก้ไขข้อมูลบริษัท
                </h4>
                <a class="Close"><i class="fal fa-times" data-dismiss="modal"></i></a>
            </div>
            <form [formGroup]="companyform" (ngSubmit)="onSubmit()">
                <div class="modal-body">
                    <div class="col-12">
                        <div class="d-flex flex-column align-items-center justify-content-center p-4">
                          <img *ngIf="!profileImageUri" src="https://sermpanya.com/sponline/assets/webimg/img_avatar.png"
                               class=" profile-file-input shadow-2 img-thumbnail" alt="">
                          <img *ngIf="profileImageUri" [src]="profileImageUri"
                               class=" profile-file-input shadow-2 img-thumbnail" alt="" style="
                               max-width: 300px;
                               height: auto;
                           ">
                          <input type="file" class="profile-file-input"
                                 id="profileImg" accept="image/*"
                                 (change)="handleFileInput($event)">
                          <label for="profileImg" style="color: #b5b5b5" class="mt-3"><i class="fas fa-upload"></i>&nbsp;
                            เปลี่ยนรูปภาพ</label>
            
                        </div>
                      </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label">ประเภทบริษัท</label>
                                <ng-select placeholder="สถานะ" formControlName="TypeCompany">
                                    <ng-option *ngFor="let item of typecom.__zone_symbol__value?.data" [value]="item.id">{{item.nameTH}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label" for="txttitlename">ชื่อบริษัท</label>
                                <input type="text" id="txttitlename" name="txttitlename" class="form-control"
                                    placeholder="ชื่อบริษัท" formControlName="NameTH">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label" for="txtname">ชื่อบริษัท (ภาษาอังกฤษ)</label>
                                <input type="text" id="txtname" name="txtname" class="form-control"
                                    placeholder="ชื่อบริษัท (ภาษาอังกฤษ)" formControlName="NameEN">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label" for="txtlastname">ประเภทสินค้าหลัก</label>
                                <ng-select  placeholder="ประเภทสินค้า" [multiple]="true" formControlName="TypeProduct">
                                    <ng-option *ngFor="let item of typeproduct.__zone_symbol__value?.data" [value]="item.id">{{item.name}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label" for="txtemail">Email</label>
                                <input type="email" id="txtemail" name="txtemail" class="form-control"
                                    placeholder="นามสกุล" formControlName="Email">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label" for="txttel">เบอร์โทรศัพท์</label>
                                <input type="text" id="txttel" name="txttel" class="form-control" mask="000-000-0009"
                                    placeholder="เบอร์โทรศัพท์" formControlName="Phone">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>
                    <button type="button" class="btn btn-success" type="submit" [disabled]="IsSaveing"><span *ngIf="IsSaveing" class="spinner-grow spinner-grow-sm"></span>บันทึกข้อมูล</button>
                </div>
            </form>
        </div>
    </div>
</div>