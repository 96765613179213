<ng-template #modalsalaryratedetail let-modal>
    <div class="modal-header">
        <h4 class="modal-title fw-500">
            อัตราค่าจ้าง (เงินเดือน / ค่าเที่ยว)
        </h4>
        <div>
            <button *ngIf="isEdit" class="btn btn-sm-custom btn-outline-success waves-effect waves-themed mr-1" [ngClass]="[salaryRateForm.invalid?'not-allowed':'']" [disabled]="salaryRateForm.invalid" (click)="onSubmit()">
                <i class="fal fa-save mr-1"></i><span class="fs-xs">บันทึก</span>
            </button>
            <button class="btn btn-sm-custom btn-outline-warning waves-effect waves-themed mr-1" aria-hidden="true"
                [ngClass]="{'active':isEdit,'':!isEdit}" (click)="onEdit()">
                <i *ngIf="!isEdit" class="fal fa-edit"></i>
                <div *ngIf="isEdit" class="spinner-border mr-2 text-success" role="status"
                    style="width: 1rem;height: 1rem;vertical-align: middle;">
                    <span class="sr-only">Loading...</span>
                </div>
                <span class="fs-xs">แก้ไขข้อมูล</span>
            </button>
            <button type="button" class="btn btn-sm-custom btn-outline-danger waves-effect waves-themed"
                aria-hidden="true" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <i class="fal fa-times"></i>
            </button>
        </div>

    </div>
    <span class="row">
        <span class="col-12 height-0">
            <div class="border border-left-0 border-right-0 border-top-0 m-3"></div>
        </span>
    </span>

    <div class="modal-body" style="height: 600px;">
        <form [formGroup]="salaryRateForm">
            <div class="row">
                <div class="col-6">
                    <div class="help-block text-muted">เงินเดือนปัจจุบัน</div>
                    <label *ngIf="!isEdit && userSalaries.length > 0">{{userSalaries[0].salary|number:'1.0'}}
                        บาท/เดือน</label>
                    <div *ngIf="isEdit" class="row">
                        <div class="col-6">
                            <input type="number" class="form-control text-right" formControlName="salary">
                        </div>
                        <div class="col-6">
                            <ng-select style="width: calc(100%)" [items]="salaryType" bindLabel="name" bindValue="id"
                            formControlName="salaryUnit_ID" clearable>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="help-block text-muted">ค่าเที่ยวปัจจุบัน</div>
                    <label *ngIf="!isEdit && userSalaries.length > 0">{{userSalaries[0].tripWage|number:'1.0'}}
                        บาท/เที่ยว</label>
                    <div *ngIf="isEdit" class="row">
                        <div class="col-6">
                            <input type="number" class="form-control text-right" formControlName="tripWage">
                        </div>
                        <div class="col-6">
                            <ng-select style="width: calc(100%)" [items]="roundTripType" bindLabel="name" bindValue="id"
                            formControlName="tripWageUnit_ID" clearable>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="help-block text-muted">มีผลตั้งแต่ (เดือน/ปีพ.ศ.)</div>
                    <label
                        *ngIf="!isEdit && userSalaries.length > 0">{{userSalaries[0].startDate|thaidate:"short"}}</label>
                    <div *ngIf="isEdit" class="row">

                        <div class="col-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <input ngbDatepicker #startDate="ngbDatepicker" formControlName="startDate"
                                        class="form-control" placeholder="ระบุวันที่มีผล" (click)="startDate.toggle()" [(ngModal)]="minDate" [minDate]="endDate_min" required>
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-secondary" (click)="startDate.toggle()" type="button">
                                            <span class="fal fa-calendar-alt fa-lg"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row" [ngClass]="{'mt-3':isEdit}">
            <span class="col-12">
                <div class="col-12 border border-left-0 border-right-0 border-top-0"></div>
            </span>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <label class="fw-500">ประวัติการเปลี่ยนแปลงอัตราค่าจ้าง</label>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="help-block text-muted col-4">ประวัติปรับค่าจ้าง</div>
                    <div class="help-block text-muted col-4">เงินเดือน/ค่าเที่ยว</div>
                    <div class="help-block text-muted col-4">โดย</div>
                </div>
            </div>

            <div class="col-12">
                <div class="row" *ngFor="let userSalary of userSalaries|slice:1:10">
                    <label class="col-4">{{userSalary.startDate|thaidate:"short"}}</label>
                    <label class="col-4">{{userSalary.salary|number:'1.0'}}/{{userSalary.tripWage|number:'1.0'}}
                        บาท</label>
                    <label class="col-4">{{userSalary.createdName}}</label>
                </div>
            </div>
        </div>


    </div>


</ng-template>

<app-modalsuccess #modalsuccess (btnother_event)="success_other($event)" [btnsubmit_ishide]=true></app-modalsuccess>