import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-mission',
  templateUrl: './alert-mission.component.html',
  styleUrls: ['./alert-mission.component.scss']
})
export class AlertMissionComponent implements OnInit {

  constructor(private router: Router,) { }
  @Input() text: string = "คุณสามารถจัดการรถบรรทุก และคนขับของคุณได้ โดย ";
  // @Input() icon: string = "";
  @Output() redirect = new EventEmitter();
  ngOnInit(): void {
  }

goTo(){
  console.log("GO TO ")
  this.redirect.emit()
  // this.router.navigate(['/truck-management/truck-license-list'],{ queryParams: { tab:'license' } });
}
}
