<div class="row p-2">
    <div class="ng-gallery-container">
        <div class="col-4 pl-1 pr-1" *ngFor="let item of imageItems; let i = index" [lightbox]="i"
            [gallery]="galleryId">
            <img [src]="item.data.thumb">
        </div>
        <div class="img-more-maintenance-techx-basic col-4 text-center w-50 m-0"
            (click)="openInFullScreen(imgMoreCount)" *ngIf="imageItems?.length > 3">
            <span>+ {{imageItems?.length - imgMoreCount}} รูปภาพ</span>
        </div>
    </div>
</div>