import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { AnalyticsServiceService } from '@app/core/_Services/analytics-service.service';
import { AddClientUserModel } from '@app/modules/team/models/ClientUser/AddClientUserModel';
import { ClientUserAllListModel } from '@app/modules/team/models/ClientUser/ClientUserAllListModel';
import { ClientUserService } from '@app/modules/team/services/client-user.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/_Models/UserModel/User';
import {
  AuthenticationService,
  NotificationService,
  PageloadingService,
} from 'src/app/core/_Services';
import { GetUserService } from '../../services/get-user.service';
declare function openModal(ModalID): any;
declare function closeModal(modalID): any;
declare function TabSelect(tabID): any;
declare function Click(ID): any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('users') users: ElementRef<HTMLAnchorElement>;
  @ViewChild('company') company: ElementRef<HTMLAnchorElement>;
  constructor(
    // private pageload: PageloadingService,
    private usersersive: GetUserService,
    private auth: AuthenticationService,
    private _ClientUserService: ClientUserService,
    private route: ActivatedRoute,
    private router: Router,
    private _noti: NotificationService,
    private detech: ChangeDetectorRef,
    private anlaytic: AnalyticsServiceService,

  ) {
    // pageload.hide();
  }
  user: User;
  profile: any;
  profileImage: string;
  userList: ClientUserAllListModel[];
  userEditData: AddClientUserModel;
  isEdit: boolean = false;
  tabSelected: number;
  destroy$: Subject<boolean> = new Subject<boolean>();
  tabUrl = ['tab_user', 'tab_company'];
  tabUrl2 = ['user', 'company'];
  isViewUser: boolean = false;
  behaviorTitle = new BehaviorSubject<string>('tab_user');
  title: string;
  permission: PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  ngOnInit(): void {
    //this.onGetQueryParam();
    this.user = this.auth.currentUserValue;
    this.GetProfile();
    this.anlaytic.PushUserInfo2(this.user);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((data) => {
      if (data) {
        if (data && data.t) {
          this.behaviorTitle.next(data.t);
        } else {
          this.router.navigate(['/profile'], {
            queryParams: { t: 'tab_user' },
          });
        }
      }
    });

    this.behaviorTitle.subscribe((title) => {
      this.title = title;
      this.detech.detectChanges();
      if (this.title) {
        switch (this.title) {
          case 'tab_user': {
            this.users.nativeElement.click();
            break;
          }
          case 'tab_company': {
            this.company.nativeElement.click();
            break;
          }
          default: {
            this.router.navigate(['/profile'], {
              queryParams: { t: 'tab_user' },
            });
            break;
          }
        }
        this.detech.detectChanges();
      }
    });

    this.auth.currentUser.subscribe((user) => {
      const p: PermissionModule = this.auth.UserPermissionModule(80000);
      if (p) {
        console.log('permission', p);
        this.permission = this.auth.FindPermissionAction(80100, p.operations);
        this.detech.detectChanges();
      }
      if (this.permission.canRead) {
      }
    });
  }

  async GetProfile() {
    this.profile = await this.usersersive.GetUser(this.user.id);
    if (this.profile) {
      console.log('GetProfile', this.profile);
      const date = new Date();
      this.profileImage =
        this.profile.data.photoURL + `?time=${date.getTime()}`;
      this.auth.photoAsync.next(
        this.profile.data.photoURL + `?time=${date.getTime()}`
      );
    }
  }

  async AfterFormSubmit(event: any) {
    if (event) {
      closeModal('#EditUser');
      await this.GetProfile();
    }
  }

  async getClientUserList() {
    const dept = await this._ClientUserService
      .GetClientUserAllList()
      .subscribe((res: TMSAPIResponse) => {
        if (res.isSuccess) {
          this.userList = res.data;
        }
      });
  }

  GetEditUser() {
    //this.pageload.show();
    this._ClientUserService
      .GetClientUserById(this.user.tstS360_UserID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: TMSAPIResponse) => {
          //this.pageload.hide();
          if (!res.isSuccess) {
            this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
          } else {
            if (res.isSuccess) {
              this.isEdit = true;
              this.isViewUser = false;
              openModal('#EditUser');
              const userData = res.data;
              console.log('userData : ', userData);
              console.log(
                'userData.userRoleDto.isActive : ',
                userData.userRoleDto.isActive
              );
              this.userEditData = new AddClientUserModel();
              this.userEditData.clientID = userData.clientID;
              this.userEditData.tsT360_CompanyID = userData.tsT360_CompanyID;
              this.userEditData.tsT360_UserID = userData.tsT360_UserID;
              this.userEditData.username = userData.username;
              this.userEditData.phoneNumber = userData.phoneNumber;
              this.userEditData.email = userData.email;
              this.userEditData.firstName = userData.firstName;
              this.userEditData.lastName = userData.lastName;
              this.userEditData.photoURL = userData.photoURL;
              this.userEditData.idCard = userData.idCard;
              this.userEditData.taxId = userData.taxId;
              this.userEditData.roleUser.isActive = userData.userRoleDto.isActive;

              this.userEditData.tsT360_DefaultUserServiceType = 2;
              this.userEditData.departmentId = userData.departmentDto.id;

              this.userEditData.roleId = userData.userRoleDto.roleID;
              this.userEditData.teamIds.push(userData.teamDto.id);

              this.userEditData.teamUser = userData.teamUserDto;
              this.userEditData.roleUser = userData.userRoleDto;

              console.log('map team id: ', userData.teamUserDto);
              if (userData.teamUserDto) {
                this.userEditData.teamIds = userData.teamUserDto.map((m) => m.teamID);
              }

              this.userEditData.tstUserId = userData.identityUserDto?.tstUserId;
              console.log('userEditData : ', this.userEditData);
            }
          }
        },
        (err) => {
          //this.pageload.hide();
        }
      );
  }

  onGetQueryParam() {
    const qtyUrl = this.route.snapshot.queryParams['t'];
    console.log(qtyUrl);
    if (qtyUrl) {
      this.tabSelected = this.tabUrl.indexOf(qtyUrl);
      console.log(this.tabSelected);
      TabSelect(this.tabUrl[this.tabSelected]);
      Click(this.tabUrl2[this.tabSelected]);
    } else {
      TabSelect(this.tabUrl[0]);
      this.tabSelected = 0;
      Click(this.tabUrl2[0]);
    }
  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  get getTimeStamp() {
    return new Date().getTime();
  }
}
