import { Component, OnInit,OnDestroy,AfterContentInit } from '@angular/core';
import {  takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpService, PageloadingService } from 'src/app/core/_Services';
declare function LightGallery(galleryID):any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit,OnDestroy,AfterContentInit {

  test : boolean = true;
  CountNumber = 5000.55;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _apiService: HttpService) {

    // pageload.hide();

   }

  ngOnInit(): void {
    this._apiService.get("/products").pipe(takeUntil(this.destroy$)).subscribe((data: any[])=>{
      console.log(data);
    })
  }
 ngAfterContentInit(): void {
   //Called after ngOnInit when the component's or directive's content has been initialized.
   //Add 'implements AfterContentInit' to the class.
  // LightGallery('js-lightgallery');
 }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
