import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/_Authentication/auth.guard';
import { ForgetPasswordComponent } from './core/_Authentication/forget-password/forget-password.component';
import { LoginComponent } from './core/_Authentication/login/login.component';
import { ResetPasswordComponent } from './core/_Authentication/reset-password/reset-password.component';
import { NotfoundComponent } from './core/_Template/ErrorPages/notfound/notfound.component';
import { InviteCarrierComponent } from './modules/carrier/pages/invite-carrier/invite-carrier.component';
import { MaterialsComponent } from './modules/materials/materials.component';
import { NotitmsComponent } from './modules/notification/pages/notitms/notitms.component';
import { PermistmsComponent } from './modules/notification/pages/permistms/permistms.component';
import { RouteDiagramComponent } from './modules/route-diagram/pages/route-diagram/route-diagram.component';
import { SettingComponent } from './modules/setting/page/setting/setting.component';
import { ProfileComponent } from './modules/usermanage/pages/profile/profile.component';
import { HomeComponent } from './shared/Components/home/home.component';
import { LogoutComponent } from "@app/core/_Authentication/logout/logout.component";
import { ShipmentExpenseModel } from './core/_Models/BookingModel/BillReceiptDetailesModel';
import { ShipmetnAssignCalendarComponent } from './modules/shipmentAssignCalendar/shipmetn-assign-calendar/shipmetn-assign-calendar.component';
import { CookieLoginComponent } from './core/_Authentication/cookie-login/cookie-login.component';
import { SignupPackagesComponent } from './core/_SignUp/pages/signup-packages/signup-packages.component';
import { SchedulerComponent } from './modules/scheduler/scheduler/scheduler.component';
import { SchedulerSheraComponent } from './modules/scheduler/pages/scheduler-shera/scheduler-shera.component';
import { SchedulerSheraGuard } from './core/_Authentication/scheduler-shera.guard';
import { LoginGuard } from './core/_Authentication/login.guard';
import { TripStatusComponent } from './modules/tms-dashboard/pages/trip-status/trip-status.component';
import { TripSummaryComponent } from './modules/tms-dashboard/pages/trip-summary/trip-summary.component';
import { TripHistoryReportComponent } from './modules/tms-dashboard/pages/trip-history-report/trip-history-report.component';
import { AppLayoutComponent } from './core/_Template/app.layout.component';
import { AdminModule } from '@app/modules/admin/admin.module';
import { CallbackComponent } from './core/_Authentication/callback/callback.component';

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      { path: 'mat', component: MaterialsComponent },
      { path: '', redirectTo: '/booking', pathMatch: 'full' },
      {
        path: 'booking',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/booking/booking.module').then(
            (e) => e.BookingModule
          ),
        runGuardsAndResolvers: 'always',
        data: {
          titlePage: 'การจองรถ',
          breadcrumb: [
            { label: 'หลัก', url: '' },
            { label: 'การจองรถ', url: 'booking' },
          ]
        },
      },
      { path: 'forgetpassword', component: ForgetPasswordComponent },
      { path: 'resetpassword', component: ResetPasswordComponent },
      { path: 'invite/:carrierID', component: InviteCarrierComponent },

      {
        path: 'master',
        canActivate: [AuthGuard],
        data: {
          titlePage: 'ข้อมูลหลักที่ใช้ในการขนส่ง',
          breadcrumb: [
            { label: 'ข้อมูลหลักที่ใช้ในการขนส่ง', url: '' },
          ]
        },
        loadChildren: () =>
          import('@app/modules/master/master.module').then(
            (e) => e.MasterModule
          ),
      },
      {
        path: 'routes',
        loadChildren: () =>
          import('./modules/route/route.module').then((t) => t.RouteModule),
      },
      {
        path: 'team',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/team/team.module').then(
            (e) => e.TeamModule
          ),
      },
      {
        path: 'carriers',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/carrier/carrier.module').then(
            (e) => e.CarrierModule
          ),
      },
      {
        path: 'driver',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/driver-management/driver-management.module').then(
            (e) => e.DriverManagementModule
          ),
      },
      {
        path: 'public',
        loadChildren: () =>
          import('@app/modules/public/public.module').then(
            (e) => e.PublicModule
          ),
      },
      {
        path: 'truck-management',
        canActivate: [AuthGuard],
        data: {
          titlePage: 'ฟลีทรถ และคนขับ',
          breadcrumb: [
            { label: 'จัดการฟลีทรถ และคนขับ', url: '' },
          ]
        },
        loadChildren: () =>
          import('@app/modules/truck-management/truck-management.module').then(
            (e) => e.TruckManagementModule
          ),
      },
      {
        path: 'import-orders',
        canActivate: [AuthGuard],
        data: {
          titlePage: 'นำเข้าออเดอร์ของ LA',
          breadcrumb: [
            { label: 'นำเข้าออเดอร์ของ LA', url: '' },
          ]
        },
        loadChildren: () =>
          import('@app/modules/import-orders/import-orders.module').then(
            (e) => e.ImPortOrdersModule
          ),
      },
      { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
      { path: 'setting', canActivate: [AuthGuard], component: SettingComponent },
      { path: 'noti', canActivate: [AuthGuard], component: NotitmsComponent },
      { path: 'permis', canActivate: [AuthGuard], component: PermistmsComponent },
      { path: 'scheduler', canActivate: [AuthGuard], component: SchedulerComponent },
      { path: 'scheduler-shera', canActivate: [SchedulerSheraGuard], component: SchedulerSheraComponent },
      { path: 'shipmentAssignCalendar', canActivate: [AuthGuard], component: ShipmetnAssignCalendarComponent },
      {
        path: 'route-diagram', canActivate: [AuthGuard],
        loadChildren: () => import('@app/modules/route-diagram/route-diagram.routing.module')
          .then((e) => e.RouteDiagramRoutingModule)
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/tms-dashboard/tms-dashboard.module').then(
            (e) => e.TmsDashboardModule
          ),
      },
      {
        path: 'trip-history-report',
        canActivate: [AuthGuard], component: TripHistoryReportComponent
      },
      {
        path: 'basic-booking',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/techx-basic-booking/techx-basic-booking.module').then(
            (e) => e.TechxBasicBookingModule
          ),
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'booking-eh',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/enterprise/eh/booking/booking-eh.module').then(
            (e) => e.BookingEhModule
          ),
        runGuardsAndResolvers: 'always',
        data: {
          titlePage: 'สร้างการจองใหม่',
          breadcrumb: [
            { label: 'หลัก', url: '' },
            { label: 'การจองรถ', url: 'booking-eh/create' },
          ]
        },
      },
      {
        path: 'subscription',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/subscription/subscription.module').then(
            (e) => e.SubscriptionModule
          ),
      },
    ]
  },
  { path: 'login', canActivate: [LoginGuard], component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'cookie-login', component: CookieLoginComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'signup',
    loadChildren: () =>
      import('@app/core/_SignUp/sign-up.module').then(
        (e) => e.SignUpModule
      ),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('@app/modules/packages/packages.module').then(
        (e) => e.PackagesModule
      ),
  },
  {
    path: 'checkout',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/modules/checkout/checkout.module').then(
        (e) => e.CheckoutModule
      ),
  },
  {
    path: 'admin', canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/admin/admin.module')
      .then((e) => e.AdminModule)
  },
  {
    path: 'callback', component: CallbackComponent ,
  },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
