<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="addFleetDriver" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
                <div class="modal-header">
                        <h5 class="modal-title">เพิ่มคนขับเข้า FLEET</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body" >
                <div class="container-fluid" style="min-height:300px">
                   <div class="row">
                    <div class="col-12">
                        <ng-select placeholder="เลือกคนขับ" [multiple]="true" [items]="driverList"
                        bindLabel="fullName" [(ngModel)]="selectedDriverIds"
                        bindValue="user_ID" (change)="onSelectDriver($event)" >
                            <ng-template ng-label-tmp let-item="item">

                                <img height="20" width="20" [src]="item.photo"  class=" profile-file-input rounded-circle shadow-2 " />
                                <span>{{item.user_Name}}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index"  >
                                <div class="row">
                                    <div class="col-md-auto col-12 text-center">
                                        <img [src]="item.photo" height="20" width="20"
                                        class=" profile-file-input rounded-circle shadow-2 " alt="">
                                    </div>
                                    <div class="col-md-4 pt-1">
                                        {{item.user_Name}}
                                    </div>
                                    <div class="col-md-3 pt-1">
                                        {{item.mobileNo}}
                                    </div>
                                    <div class="col pt-1">
                                        {{item.status}}
                                    </div>
                                    <div class="col pt-1">
                                        <span *ngIf="item.companyFleetNo != 0">  F {{item.companyFleetNo}}  </span>
                                        <span *ngIf="item.companyFleetNo == 0">  -  </span>
                                    </div>
                               </div>
                            </ng-template>
                        </ng-select>
                    </div>
                   </div>
                   <div class="row mt-2 pb-1 border-bottom " *ngFor="let item of selectedDriver">
                        <div class="col-md-auto col-12 text-center">
                            <img [src]="item.photo" height="50" width="50"
                            class=" profile-file-input rounded-circle shadow-2 " alt="">
                        </div>
                        <div class="col-md-4 pt-3">
                            {{item.user_Name}}
                        </div>
                        <div class="col-md-3 pt-3">
                            {{item.mobileNo}}
                        </div>
                        <div class="col pt-3">
                            {{item.status}}
                        </div>
                        <div class="col pt-3">
                            <span *ngIf="item.companyFleetNo != 0">  F {{item.companyFleetNo}}  → F{{currentFleet}}  </span>
                            <span *ngIf="item.companyFleetNo == 0">  -   → F{{currentFleet}} </span>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="btn btn-danger" (click)="onRemoveDriver(item)" > <i class="fas fa-trash"></i></button>
                        </div>
                   </div>

                </div>
                <div class="p-2">
                    <div class="row pb-2 mt-2">
                        <div class="col-md-6">
                            <button class="btn btn-outline-secondary btn-block" (click)="closed()">ปิดหน้านี้</button>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-success btn-block" (click)="onSaveFleetDriver()">บันทึก</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<script>
    $('#exampleModal').on('show.bs.modal', event => {
        var button = $(event.relatedTarget);
        var modal = $(this);
        // Use above variables to manipulate the DOM

    });
</script>
