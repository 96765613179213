<div class="modal fade" id="EditUser" tabindex="0" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!IsView">
        <h4 class="modal-title">
          {{IsEdit ? "แก้ไขข้อมูลผู้ใช้" : "เพิ่ม ผู้ใช้งาน"}}
        </h4>
        <a class="Close"><i class="fal fa-times" data-dismiss="modal"></i></a>
      </div>


      <form [formGroup]="userform" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="col-12">
            <div class="d-flex flex-column align-items-center justify-content-center p-4">
              <img [src]="profileImageUri"
                   class=" profile-file-input rounded-circle shadow-2 img-thumbnail" alt="" (error)="onImgErrorProfile($event)">
              <input type="file" class="profile-file-input"
                     id="CompanyImg" accept="image/*"
                     (change)="handleFileInput($event)" *ngIf="!IsView" #uploadFile>
              <label for="CompanyImg" style="color: #b5b5b5" class="mt-3" *ngIf="!IsView"><i class="fas fa-upload"></i>&nbsp;
                เปลี่ยนรูปภาพ
              </label>
            </div>
          </div>
          <!--                <div class="form-group">-->
          <!--                    <div class="row">-->
          <!--                        <div class="col-md-12">-->
          <!--                            <label class="form-label" for="txttitlename">คำนำหน้า</label>-->
          <!--                            <input type="text" id="txttitlename" name="txttitlename" class="form-control" formControlName="nametitle"-->
          <!--                                placeholder="คำนำหน้า">-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                </div>-->
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Role</label>
                <ng-select placeholder="สิทธิ์" formControlName="roleId" [readonly]="IsView || !(permission70100.actionDto | permission : 3)"
                  [items]="roleList" bindLabel="roleName" bindValue="id"
                (change)="setAuthlevelByClientRole($event)">
                  <!-- <ng-option *ngFor="let item of roleList" [value]="item.id">{{item.roleName}}</ng-option> -->
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label" for="txtname">ชื่อ</label>
                <input type="text" id="txtname" name="txtname" class="form-control" formControlName="firstname"
                       placeholder="ชื่อ" [readonly]="IsView">
                <div *ngIf="userform.controls.firstname.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.firstname.errors.required">
                    กรุณากรอกชื่อ
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label" for="txtlastname">นามสกุล</label>
                <input type="text" id="txtlastname" name="txtlastname" class="form-control" formControlName="lastname"
                       placeholder="นามสกุล" [readonly]="IsView">
                <div *ngIf="userform.controls.lastname.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.lastname.errors.required">
                    กรุณากรอกนามสกุล
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label" for="txtemail">Email</label>
                <input type="email" id="txtemail" name="txtemail"
                       class="form-control {{userform.controls.email.invalid?'is-invalid':'is-valid'}}"
                       formControlName="email"
                       placeholder="email" (change)="checkUsedEmail()" [readonly]="IsView">
                <div *ngIf="userform.controls.email.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.email.errors.canUse">
                    {{userform.controls.email.errors.canUse}}
                  </div>
                </div>

                <div *ngIf="userform.controls.email.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.email.errors.required">
                    กรุณากรอกอีเมล
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label" for="txttel">เบอร์โทรศัพท์</label>
                <input type="tel" id="txttel" name="txttel"  maxlength="10"
                       class="form-control {{userform.controls.mobileNumber.invalid?'is-invalid':'is-valid'}}"
                       formControlName="mobileNumber" oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                       placeholder="เบอร์โทรศัพท์" (change)="checkUsedMobileNo()" [readonly]="IsView">

                <div *ngIf="userform.controls.mobileNumber.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.mobileNumber.errors.canUse">
                    {{userform.controls.mobileNumber.errors.canUse}}
                  </div>
                </div>

                <div *ngIf="userform.controls.mobileNumber.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.mobileNumber.errors.required">
                    กรุณากรอกเบอร์โทรศัพท์
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">สถานะ User</label>
                <ng-select [readonly]="IsView || !(permission70100.actionDto | permission : 3)" placeholder="สถานะ" formControlName="status" [disabled]="">
                  <ng-option *ngFor="let item of Active" [value]="item.id">{{item.name}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div *ngIf="IsEdit == false" class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="IsusernamePhone" [checked]="IsusernamePhone" [readOnly]="!(permission70100.actionDto | permission : 3)" (change)="checkUsernameChnage($event)">
                        <label class="custom-control-label" for="IsusernamePhone">ใช้เบอร์มือถือและอีเมลเป็น Username</label>
                    </div>
                </div>
            </div>
        </div>
          <div *ngIf="IsusernamePhone != true && IsEdit == false" class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label" for="txtusername">Username</label>
                <input type="text" id="txtusername" name="txtusername" class="form-control" formControlName="username"
                       placeholder="Username" [readonly]="IsEdit">
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="clientModule_70400?.pkg_id > 10">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Department</label>
                <ng-select placeholder="Department" (change)="getRoleByDepartment()" formControlName="departmentId"
                           [readonly]="IsView || !(permission70100.actionDto | permission : 3) ">
                  <ng-option *ngFor="let item of departmentList" [value]="item.id">{{item.departmentName}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Role</label>
                <ng-select placeholder="สิทธิ์" formControlName="roleId" [readonly]="IsView || !(permission70100.actionDto | permission : 3)"
                  [items]="roleList" bindLabel="roleName" bindValue="id"
                (change)="setAuthlevelByClientRole($event)">
                  <!-- <ng-option *ngFor="let item of roleList" [value]="item.id">{{item.roleName}}</ng-option> -->
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="clientModule_70200?.pkg_id > 10">
            <div class="row">
              <div class="col-md-12"  >
                <label class="form-label">Team</label>
                <ng-select placeholder="ทีม" formControlName="teamIds" [readonly]="IsView" [multiple]="true">
                  <ng-option *ngFor="let item of teamList" [value]="item.teamId">{{item.teamNumber}}
                    : {{item.teamName}}</ng-option>
                </ng-select>


              </div>
            </div>
          </div>
          <div *ngIf="IsEdit == false" class="panel-tag">
            บัญชีผู้ใช้ที่เพิ่มใหม่จะได้รับแจ้ง Username และ Password(ที่สร้างโดยระบบ)สำหรับการเข้าใช้งานครั้งแรก ผ่านทางอีเมล์ที่ระบุไว้
        </div>
        </div>
        <!--            <div class="modal-footer">-->
        <!--                <button type="reset" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>-->
        <!--                <button type="submit" class="btn btn-success">บันทึกข้อมูล</button>-->
        <!--            </div>-->
        <div class="modal-footer">
          <button type="reset" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>
          <button *ngIf="!onSaving && !IsView" type="submit" [disabled]="!userform.valid"
                  class="btn {{IsEdit?'btn-warning':'btn-success'}}">
            {{IsEdit?"บันทึกข้อมูล":"เพิ่ม User"}}
          </button>
          <button *ngIf="onSaving" class="btn {{IsEdit?'btn-warning':'btn-success'}}" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            กำลังบันทึกข้อมูล...
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
