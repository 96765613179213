<div class="form-group" [ngClass]="{'remove-top': removeTop}">
  <label class="form-label" for="example-datetime-local-input">{{title}} <span class="txt-red" *ngIf="isRequire">*</span>
  </label>
  <div class="input-group">
    <div class="input-group-append">
      <ng-container *ngIf="secondpicker == false">
        <span class="input-group-text fs-xl">
          <i class="fas fa-calendar"></i>
        </span>
      </ng-container>
      <ng-container *ngIf="secondpicker == true">
        <span class="input-group-text pt-2">
          ถึง
        </span>
      </ng-container>
    </div>
    <!-- <pre>{{value}}</pre> -->
    <input type="text" autocomplete="off" [id]="id" [ngClass]="{'invalid': !valid}" [class]="'form-control date-picker ' + class"
      [disabled]="disabled"
      data-provide="datepicker" [value]="textValue" placeholder="เลือกวันที่" #date data-toggle="tooltip" data-placement="top" [title]="textValue ? textValue : ''" />
  </div>
</div>