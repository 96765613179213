<button type="button"
    class="btn btn-success"
    (click)="onOpenModal()"
    [disabled]="disabled">เพิ่มสมาชิกในทีม</button>
<div class="modal fade" tabindex="-1" id="addMemeberModal" role="dialog" aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title ">
                    เพิ่มสมาชิกเข้าทีม {{TeamName}}
                </h4>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <input type="text" #searchUTxt (keyup)="search()" id="js-filter-contacts" name="filter-contacts" class="form-control shadow-inset-2 " placeholder="ค้นหา">
                    </div>
                </div>
                <div class="row mt-3" id="js-contacts">
                  <div class="col-12 text-center" *ngIf="loadingUserdata">
                    <div class="frame-wrap" >
                      <div class="p-3" style="border:0;height: 450px;">
                        <div class="d-flex justify-content-center">
                          <strong style="margin-top: 25%;">กำลังโหลดข้อมูล...</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                    <div class="col-12" *ngFor="let user of teamUser|searchFilter:{'fullname':searchTxt,'departmentName':searchTxt,'email':searchTxt,'mobileNo':searchTxt};let i = index;">
                        <div id="c_{{i+1}}" class="card border shadow-0 mb-2 shadow-sm-hover" >
                            <div class="card-body text-left border-faded border-top-0 border-left-0 border-right-0 rounded-top">
                                <div class="d-flex flex-row align-items-center">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" [(ngModel)]="user.isSelected" class="custom-control-input" id="ToDriver{{user.tst360UserId}}">
                                        <label class="custom-control-label" for="ToDriver{{user.tst360UserId}}"></label>
                                    </div>
                                    <span class="mr-3">
                                        <span class="rounded-circle profile-image d-block "
                                            style="background-image:url({{user.photo}}); background-size: cover;"></span>
                                    </span>
                                    <div class="info-card-text flex-1">
                                        <a href="javascript:void(0);" class="fs-xl text-truncate text-truncate-lg text-info">
                                          {{user.fullname}}
                                        </a>

                                        <span class="text-truncate text-truncate-xl">{{user.departmentName}}</span>
                                      <div class="p-1">
                                        <a href="tel:{{user.mobileNo}}" class="mt-1 fs-sm fw-400 text-dark">
                                          <i class="fas fa-mobile-alt text-muted mr-2"></i>{{user.mobileNo}}</a>
                                        <a href="mailto:{{user.email}}" class="mt-1 d-block fs-sm fw-400 text-dark">
                                          <i class="fas fa-envelope-o text-muted mr-2"></i> {{user.email}}
                                        </a>
                                      </div>
                                    </div>
<!--                                    <button class="js-expand-btn btn btn-sm btn-default" data-toggle="collapse"   aria-expanded="false">-->
<!--                                        <span class="collapsed-hidden" >+</span>-->
<!--                                        <span class="collapsed-reveal" >-</span>-->
<!--                                    </button>-->
                                </div>
                            </div>
                            <div class="card-body p-0 collapse ">
                                <div class="p-3">
                                    <a href="tel:+13174562564" class="mt-1 d-block fs-sm fw-400 text-dark">
                                        <i class="fas fa-mobile-alt text-muted mr-2"></i>{{user.mobileNo}}</a>
                                    <a href="mailto:oliver.kopyov@smartadminwebapp.com" class="mt-1 d-block fs-sm fw-400 text-dark">
                                        <i class="fas fa-mouse-pointer text-muted mr-2"></i> {{user.email}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">ยกเลิก</button>
                <button type="button" class="btn btn-success" (click)="onSubmit()">เพิ่มสมาชิก</button>
            </div>
        </div>
    </div>
</div>
