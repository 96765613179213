<ng-template #modalConfirm let-modal>
  <div class="modal-header text-center">
    <div class="dev-comfirm-icon">
      <span class="comfirm-icon"><i [ngClass]="modalConfig?.iconClassName" class="fa-5x pt-2"></i></span>
    </div>
  </div>
  <div class="modal-body p-1">
    <div class="container-fluid text-center mt-5 mb-2">
      <div class="row">
        <div class="col-12">
          <span class="h4" [innerHtml]="modalConfig?.title"></span>
        </div>
        <div class="col-12">
          <span [innerHtml]="modalConfig?.description"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="p-2">
    <div class="row pb-2 mt-2">
      <div [ngClass]="'col-' + (12 / modalConfig?.buttonList.length)" *ngFor="let item of modalConfig?.buttonList">
        <button class="btn waves-effect waves-themed  btn-block" [ngClass]="item.class" (click)="modalSubmit(item.comfirm)">{{item.text}}</button>
      </div>
      <!-- <div class="col-md-6">
        <button class="btn btn-outline-secondary btn-block" data-dismiss="modal" (click)="modalSubmit(false)">{{modalConfig?.buttonCancelText}}</button>
      </div> -->
    </div>
  </div>
</ng-template>

<!-- Modal -->
<!-- <div class="modal fade" id="{{elementId}}" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">

    </div>
  </div>
</div> -->
