import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AppContants } from '@app/core/_Constants/appContants';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-contact-center',
  templateUrl: './modal-contact-center.component.html',
  styleUrls: ['./modal-contact-center.component.scss']
})
export class ModalContactCenterComponent implements OnInit {
  @Input() body_title?: 'ต้องการแก้ไขจำนวน fleet รถ?' | string;
  @Input() body_description?: 'เพื่อป้องกันการเปลี่ยนแปลงข้อมูลโดยไม่ตั้งใจ หากต้องการแก้ไขจำนวนคัน หรือ ลบประเภทรถ โปรดติดต่อเจ้าหน้าที่ 360TRUCK ครับ' | string;
  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() button_text: string = 'ติดต่อเจ้าหน้าที่';
  @Input() iconClass: string = 'fal fa-phone-alt';
  @Input() isCall: boolean = false;
  @Input() phoneNumber: string = AppContants.Insurance_360Contact_No;

  modalReference!: NgbModalRef;
  @Output() btnModalSubmitEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("modalContactCenter", { static: true }) modalContactCenter!: TemplateRef<any>;
  constructor(
    private modalService: NgbModal
  ) { }


  ngOnInit() {
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string): NgbModalRef {
    // console.log('isCall', this.isCall);
    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalContactCenter, { size: size, centered: true, backdrop: 'static' });
    }
    else {
      this.modalReference = this.modalService.open(this.modalContactCenter, { centered: true });
    }
    return this.modalReference;
  }

  onModalSubmit(event: boolean): void {
    this.modalReference.close();
  }

}
