<ng-template #modalContactCenter let-modal>
  <div class="modal-header text-center pb-0">
    <div class="col-12 p-0">
      <!-- <img class="img-contact-center" src="/assets/img/main/contactcenter.png" alt=""> -->
      <span class="fal fa-info-circle fa-5x text-success"></span>
      <button type="button" class="close pt-1 pl-0 pb-0 pr-1" aria-label="Close" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
    </button>
      <!-- <button type="button" class="close pt-0 pl-0 pb-0 pr-1"  aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
  </div>
  <div class="modal-body pb-0">
    <div class="row pb-2">
      <div class="col-12 text-center">
        <h3 class="font-weight-bold">
          {{body_title}}
        </h3>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-12 text-center">
        <span class="text-secondary">{{body_description}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer flex-column p-2">
    <div class="d-flex justify-content-around w-100">
      <div class="col-6 p-1">
        <button type="button" class="btn btn-sm btn-outline-primary waves-effect waves-themed btn-block"
        (click)="modal.dismiss('Cross click')">ปิดหน้านี้</button>
      </div>
      <div class="col-6 p-1" *ngIf="isCall">
        <a href="tel:{{phoneNumber}}" class="btn btn-sm btn-primary waves-effect waves-themed btn-block"
        ><i class="{{iconClass}}"></i> {{button_text}}</a>
      </div>
      <div class="col-6 p-1" *ngIf="!isCall">
        <button type="button" class="btn btn-sm btn-primary waves-effect waves-themed btn-block"
        (click)="onModalSubmit(true)">{{button_text}}</button>
      </div>
    </div>
  </div>
</ng-template>
