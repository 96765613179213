<div class="row justify-content-between">
    <div class="col-12 col-md-auto">
        <div class="row">
            <div class="col-12 col-md-auto mt-2">
                <label class="form-label pr-3" for="">หมายเหตุ</label>
            </div>
            <div class="col-auto mt-2">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox"
                        class="custom-control-input"
                        id="Toplant{{name}}{{index}}{{subIndex}}"
                        [checked]="to1"
                        [disabled]="disabled"
                        (change)="Toplant()">
                    <label class="custom-control-label" for="Toplant{{name}}{{index}}{{subIndex}}">ส่งถึงโรงงาน</label>
                </div>
            </div>
            <div class="col-auto mt-2">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox"
                        class="custom-control-input"
                        id="Tosales{{name}}{{index}}{{subIndex}}"
                        [checked]="to2"
                        [disabled]="disabled"
                        (change)="Tosales()">
                    <label class="custom-control-label" for="Tosales{{name}}{{index}}{{subIndex}}">ส่งถึงทีม Sales</label>
                </div>
            </div>
            <div class="col-auto mt-2">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox"
                        class="custom-control-input"
                        id="ToDriver{{name}}{{index}}{{subIndex}}"
                        [disabled]="disabled"
                        [checked]="to3"
                        (change)="Todriver()">
                    <label class="custom-control-label" for="ToDriver{{name}}{{index}}{{subIndex}}">ส่งถึงคนขับ</label>
                </div>
            </div>

        </div>
    </div>

</div>
<div class="input-group">
    <textarea class="form-control"
        id=""
        rows="1"
        (input)="onSearchChange($event.target.value)"
        (change)="textChange($event.target.value)"
        [formControl]="remarknote"
        [readonly]="TypeAction === 3 || disabled">
    </textarea>
    <div *ngIf="!disabled"
        class="input-group-prepend">
        <div *ngIf="TypeAction !== 3" class="input-group-text delete-icon " (click)="removeCustomerRemark(index)">
            <i class="fas fa-trash"></i>
        </div>
    </div>
</div>