<app-dynamic-breadcrumb></app-dynamic-breadcrumb>
<!-- <app-breadcrumb Title="ผู้ใช้และทีม"></app-breadcrumb> -->
<div class="row">
    <div class="col-xl-12">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" *ngIf="clientModuleBooking?.pkg_id > 20"><a id="internal" class="nav-link active" data-toggle="tab" href="#tab_INTERNAL" role="tab" (click)="onchangeTab('team')"><b>รายการทีม</b></a></li>
            <li class="nav-item"><a id="external" class="nav-link" data-toggle="tab" href="#tab_EXTERNAL" role="tab" (click)="onchangeTab('user')"><b>รายการผู้ใช้</b></a></li>
        </ul>
        <div class="tab-content p-3">
            <div class="tab-pane fade show active" id="tab_INTERNAL" role="tabpanel">
                <app-teaminternal *ngIf="!isTabUserList"></app-teaminternal>
            </div>
            <div class="tab-pane fade" id="tab_EXTERNAL" role="tabpanel">
                <app-all-user [onChangeTab]="isTabUserList" *ngIf="isTabUserList" [notBasic]="clientModuleBooking?.pkg_id > 20"></app-all-user>
            </div>
        </div>
    </div>
</div>
