export class ClientLocationAddItem{
  id: number;
  createdOn: string;
  modifiedOn: string;
  valid: boolean;
  deletedOn: string;
  deletedBy: string;
  modifiedBy: string;
  createdBy: string;
  locationNumber: string;
  locationShortAddress: string;
  locationFullAddress: string;
  lat: number;
  lng: number;
  placeId:string;
  provinceID: number;
  districtID: number;
  subdistrictID:number;
  LocationTypeId : number;
  ContactName : string;
  ContactPhone : string;
  ClientID:number;
  CustomerId:any;



}
export class ClientLocationAddModel
{
  clientLocation:ClientLocationAddItem[];
}
