import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { BannerApiPath, FleetXDriverAPIPath } from '../_Constants/apiConstants';
import { TMSAPIResponse } from '../_Models/TMSAPIResponse';
import { SaveChangeFleetRequest } from '@app/modules/truck-license-management/models/fleet-x-truck-model';
import {
  BannerRequestDto,
  BannerResponseDto,
} from '@app/modules/banner/BannerDto';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  public _BASE_MS_API = environment.apiMicrosoft365Url;

  destroy$: Subject<boolean> = new Subject<boolean>();
  bannerOnChange: BehaviorSubject<BannerResponseDto>;
  constructor(private apiService: HttpService) {
    this.bannerOnChange = new BehaviorSubject(new BannerResponseDto());
  }

  getBanner(dataReq: BannerRequestDto): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(BannerApiPath.GetBanner, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.bannerOnChange.next(res.data);
          resolve(res);
        });
    });
  }
}
