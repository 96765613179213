import { Injectable } from '@angular/core';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService, NotificationService } from 'src/app/core/_Services';
import { Address, Addresslist } from '../models/Address';
import { CompanyModel, UpdateClientUserDto } from '../models/Company';

@Injectable()
export class GetUserService {

  constructor(private _apiService: HttpService) { }

  destroy$: Subject<boolean> = new Subject<boolean>();

  async GetUser(userid: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/ClientUser/${userid}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetCompany(comId: number, clientID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/ClientCompany/${comId}/${clientID}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetTypeCompany(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/ClientCompany/TypeCompany`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetProductgroup(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/ClientCompany/ProductGroup`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async updateCompany(Id: number, name: string, cuslocation: CompanyModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .patch(`/ClientCompany/${Id}/${name}`, cuslocation)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetProvince(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/AppMaster/province`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetDistrict(Id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/AppMaster/district-by-province/${Id}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetSubDistrict(Id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/AppMaster/subDistrict-by-district/${Id}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async updateAddress(Id: number, name: string, data: Address): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .patch(`/ClientCompany/UpdateAddress/${Id}/${name}`, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async AddAddress(data: Address): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/ClientCompany/AddAddress`, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  ChangePassword(Id: number, password: string, oldpass: string): Observable<any> {
    return this._apiService.patch(`/Users/Changepassword`, {
      UserID: Id,
      NewPassword: password,
      OldPassword: oldpass
    });
  }
  Uploadlogo(data: any): Observable<any> {
    return this._apiService.fileupload(`/Client/AddImage`, data);
  }
}
