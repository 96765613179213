<!-- Button trigger modal -->
<button type="button" class="btn btn-success waves-effect waves-themed btn-block" data-toggle="modal" data-target="#add-fleet-driver">
    เพิ่มคนขับใหม่
</button>

<!-- Modal -->
<div class="modal fade" id="add-fleet-driver" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><i class="fas fa-user"></i>เพิ่มผู้ใช้งาน (คนขับ)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div *ngIf="currentStep===1">

                        <div class="row">
                            <div class="col-12 text-center">
                                <img [src]="'assets/img/demo/avatars/avatar-m.png'"
                                    class=" profile-file-input rounded-circle shadow-2 img-thumbnail" alt="">
                                <br>
                                <input type="file" class="profile-file-input" id="profileImg" accept="image/*">
                                <label for="profileImg" style="color: #b5b5b5" class="mt-3"><i
                                        class="fas fa-upload"></i>&nbsp;
                                    เปลี่ยนรูปภาพ</label>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6 col-12">
                                <div class="form-group">
                                    <label class="form-label" for="firstName">ชื่อจริง <span
                                            class="txt-red">*</span></label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" id="firstName" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="form-group">
                                    <label class="form-label" for="firstName">นามสกุล <span
                                            class="txt-red">*</span></label>
                                    <div class="input-group">
                                        <input class="form-control" type="text" id="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">

                                <div class="form-group">
                                    <label class="form-label" for="firstName">วัน/เดือน/ปีเกิด <span
                                            class="txt-red">*</span></label>
                                    <div class="input-group">
                                        <input class="form-control" type="date" id="" />
                                    </div>
                                </div>

                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="firstName">Department <span
                                            class="txt-red">*</span></label>
                                    <ng-select [items]="department" bindLabel="name" bindValue="id" [(ngModel)]="p"
                                        >
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="firstName">Role <span
                                            class="txt-red">*</span></label>
                                    <ng-select [items]="permission" bindLabel="name" bindValue="id" [(ngModel)]="p"
                                        [disabled]="true">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="firstName">Team <span
                                            class="txt-red">*</span></label>
                                    <ng-select [items]="team" bindLabel="name" bindValue="id" [(ngModel)]="p"
                                    >
                                    </ng-select>
                                </div>
                            </div>


                            <div class="col-md-6 col-12">
                                <div class="form-group">
                                    <label class="form-label" for="firstName">เบอร์โทรศัพท์ <span
                                            class="txt-red">*</span></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-phone"></i></span>
                                        </div>
                                        <input type="text" class="form-control" name="" id="" mask="000-000-0009"
                                            placeholder="โปรดกรอกเบอร์โทรศัพท์ 10 หลัก" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="form-group">
                                    <label class="form-label" for="firstName">เลขบัตรประชาชน <span
                                            class="txt-red">*</span></label>

                                    <div class="input-group">
                                        <input class="form-control" type="text" id="" mask="0-0000-00000-00-0"
                                            placeholder="เลขบัตรประชาชน 13 หลัก" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <label class="form-label" for="firstName">อัปโหลดสำเนาบัตรประชาชน</label>
                                <div class="custom-dropzone dropzone" ngx-dropzone>
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fal fa-cloud-upload text-muted mb-3"></i> <br>
                                            <span class="text-uppercase">Drop files here or click to upload.</span>
                                            <br>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true">
                                        <ngx-dropzone-label> </ngx-dropzone-label>
                                    </ngx-dropzone-image-preview>
                                    <ngx-dropzone-preview [removable]="true">
                                        <ngx-dropzone-label></ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <label class="form-label" for="firstName">อัปโหลดสำเนาใบขับขี่</label>
                                <div class="custom-dropzone dropzone" ngx-dropzone>
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fal fa-cloud-upload text-muted mb-3"></i> <br>
                                            <span class="text-uppercase">Drop files here or click to upload.</span>
                                            <br>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true">
                                        <ngx-dropzone-label> </ngx-dropzone-label>
                                    </ngx-dropzone-image-preview>
                                    <ngx-dropzone-preview [removable]="true">
                                        <ngx-dropzone-label></ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </div>
                            </div>
                        </div>
                        <div class="p-2">
                            <div class="row pb-2 mt-2">
                                <div class="col-md-6">
                                    <button class="btn btn-outline-secondary btn-block">ปิดหน้านี้</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-success btn-block" (click)="save()">บันทึกและไปต่อ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentStep===2">
                        <div class="row">
                            <div class="col-12">
                                <label class="form-label" for="firstName">คุณต้องการให้คนขับคนนี้เข้า FLEET ไหน</label>

                            </div>
                            <div class="col-12" *ngFor="let fleet of fleetList">
                                <div class="custom-control custom-radio">
                                    <!-- <input type="radio" id="labor{{j}}" value="1" name="labor{{j}}" (change)="laborchange()"  [checked]="!nolabor" class="custom-control-input"> -->
                                    <input type="radio" id="labor{{ fleet }}" value="true" name="Fleet" class="custom-control-input" />

                                    <label class="custom-control-label" for="labor{{ fleet }}">FLEET {{fleet}}</label>
                                  </div>
                            </div>
                           
                        </div>
                        <div class="p-2">
                            <div class="row pb-2 mt-2">
                                <div class="col-md-6">
                                    <button class="btn btn-outline-secondary btn-block">ปิดหน้านี้</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-success btn-block" (click)="fleetSave()">บันทึก</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>