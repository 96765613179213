import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientModule } from '@app/core/_Models/UserModel/UserPermission';
import { AppContants } from 'src/app/core/_Constants/appContants';
import { AuthenticationService, PageloadingService } from 'src/app/core/_Services';
@Component({
  selector: 'app-teamdatabase',
  templateUrl: './teamdatabase.component.html',
  styleUrls: ['./teamdatabase.component.scss'],
})
export class TeamdatabaseComponent implements OnInit, AfterViewInit {
  isTabUserList: boolean = false;
  clientModuleBooking: ClientModule;


  constructor(
    // private pageload: PageloadingService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
  ) {
    AppContants.beadcrumb = [];
    AppContants.beadcrumb = [{ name: 'ผู้ใช้และทีม', url: '/team' }];
  }

  ngOnInit(): void {
    //this.pageload.hide();
    this.authService.currentUser.subscribe((user) => {
      console.log(user)
      this.clientModuleBooking = user.clientModules.find(e => e.amo_id == 40111)

    });
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((param) => {
      if (param.t === 'user') {
        this.isTabUserList = true;
        document.getElementById('external').click();
      } else if (param.t === 'team' || !param.t) {
        this.isTabUserList = false;
        document.getElementById('internal').click();
      }
    });
  }

  onchangeTab(tab: string) {
    if (tab === 'user') {
      this.router.navigate(['/team'], { queryParams: { t: 'user' } });
    } else {
      this.router.navigate(['/team'], { queryParams: { t: 'team' } });
    }
  }
}
