import { Component, OnInit } from '@angular/core';
import GeocoderResult = google.maps.GeocoderResult;

import { ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LocationModel } from '@app/shared/Components/ModalGoogleMap/modalgooglemap/googlemap/Model/LoactionModel';
@Component({
  selector: 'app-map-directions',
  templateUrl: './map-directions.component.html',
  styleUrls: ['./map-directions.component.scss']
})
export class MapDirectionsComponent implements OnInit {

  @ViewChild('mapView') public mapViewElementRef: ElementRef;

  @Input() routes;
  @Input() driverLocs;
  @Input() istracking = true;
  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.initMapDirect();
      console.log(this.routes)
      console.log(this.driverLocs)

    }, 100);
  }


  async initMapDirect() {
    var center = new google.maps.LatLng(13.687985230843804, 100.37217327882891)
    var directionsService = new google.maps.DirectionsService();
    var directionsRenderer = new google.maps.DirectionsRenderer();

    var mapOptions = {
      zoom: 7,
      center: center
    }
    var map = new google.maps.Map(document.getElementById('map'), mapOptions);
    directionsRenderer.setMap(map);
    var requestRoute;
    if (this.routes.length == 2) {


      var origin = this.routes.filter(x => x.sequence == 1);
      console.log(origin)
      origin = origin[0];
      console.log(origin.lat)
      var destination = this.routes.filter(x => x.sequence == 2);
      destination = destination[0];


      requestRoute = {
        origin: new google.maps.LatLng(origin.lat, origin.lng),
        destination: new google.maps.LatLng(destination.lat, destination.lng),
        travelMode: google.maps.TravelMode.DRIVING
      }

      console.log(origin, destination)
    } else if (this.routes.length > 2 && this.routes.length < 26) {
      const waypts: google.maps.DirectionsWaypoint[] = [];
      var origin = this.routes.filter(x => x.sequence == 1);
      origin = origin[0];

      const maxsequence = Math.max(...this.routes.map(o => o.sequence), 0);
      console.log(maxsequence);
      var destination = this.routes.filter(x => x.sequence == maxsequence);
      destination = destination[0];

      var waypoint = this.routes.filter(x => x.sequence != maxsequence && x.sequence != 1);

      waypoint.forEach(way => {
        waypts.push({
          location: new google.maps.LatLng(way.lat, way.lng),
          stopover: true,
        });
      });


      requestRoute = {
        origin: new google.maps.LatLng(origin.lat, origin.lng),
        destination: new google.maps.LatLng(destination.lat, destination.lng),
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      }
    }
    console.log(this.driverLocs)


    setTimeout(async() => {
      var request = requestRoute;
      directionsService.route(request, function (result, status) {
        if (status == 'OK') {
          directionsRenderer.setDirections(result);
      }
      });
}, 100);

  await this.createMarker(map);
  }

  async createMarker(map){
    const iconBase = "assets/img/mapmarker/"
    var locs = [];
    this.driverLocs.forEach(item => {
      var loc = { position: new google.maps.LatLng(item.latitude, item.longtitude) }
      locs.push(loc)
    });
   

    console.log(locs);
    for (let i = 0; i < locs.length; i++) {
      const marker = new google.maps.Marker({
        position: locs[i].position,
        icon: iconBase + "truck3.png",
        map: map
      });
    }
  }

}
