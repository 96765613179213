<div class="text-center">
    <div class="row">
        <div class="avatar avatar-xl " style="margin-left: calc(50% - 50px);">
            <img #imgprofile class="rounded-circle" src="{{profileImage}}" alt="" (error)="defaultImageProfileUrl">
            <!-- <img #imgprofile class="rounded-circle" alt="" > -->
        </div>
    </div>

    <div *ngIf="canChange">
        <button type="button" class="btn btn-sm btn-success waves-effect waves-themed mt-3" *ngIf="permission130100.canEdit || fleetDriver.userID===currentUser?.id"
            (click)="openLg(uploadProfile)">แก้ไขรูปโปรไฟล์</button>
        <!-- <button type="button" class="btn btn-sm btn-primary waves-effect waves-themed mt-3"
            ><i class="far fa-edit mr-2"></i>เลือกรูปโปรไฟล์</button> -->
    </div>
</div>

<ng-template #uploadProfile let-modal>
    <div class="modal-header">
        <h4 class="modal-title">โปรดเลือกรูปภาพโปรไฟล์</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"   (click)="modal.close('Save click')">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div *ngFor="let image of images; let index = index;" key="index" class=" select item text-center p-1 avatar" style="width:100px;height:100px">
          <img class="" [ngClass]="{'selected' : selected == image  }" style="width:100px;height:100px" class="rounded-circle "
               [src]="image" (click)="selectedImg(image)" />
        </div>
        <div class="text-center  avatar avatar-xl m-1" *ngIf="lineImage!=undefined" style="width:100px;height:100px">
          <img #imgline class="rounded-circle " [ngClass]="{'selected' : selected == lineImage  }" style="width:100%"
               [src]="lineImage" (error)="imgline.src = defaultImageProfileUrl" (click)="selectedImg(lineImage)" />
          <br> <span> จากไลน์</span>
        </div>
        <div class=" select  text-center avatar avatar-xl m-1" *ngIf="fleetDriver?.photoURL!=undefined" style="width:100px;height:100px">
          <img #imgprofile [ngClass]="{'selected' : selected == uploadImage  }" style="width:100%" class="rounded-circle"
               [src]="uploadImage" (error)="imgprofile.src = defaultImageProfileUrl" (click)="selectedImg(uploadImage!)" />
          <br> <span> รูปของคุณ</span>
        </div>
        <!-- <div class="text-center image-upload" >
      <label for="file-input">
          <img class="rounded-circle img-fluid select " [ngClass]="{'selected' : selected == defaultImageProfileUrl  }"
              [src]="defaultImageProfileUrl" style="cursor: pointer;height:150px" (click)="selectedImg(defaultImageProfileUrl!)" />
          <br> <span> อัพโหลดรูป</span>
      </label>

      <input id="file-input" type="file" (change)="onFileUpload($event)" accept="image/*">
  </div> -->
        <div style="width:150px" class="ml-5">
          <app-tst-fileupload (FilesData)="onFileUpload($event)" [showPreview]="false"></app-tst-fileupload>
        </div>
      </div>
    </div>
    <div class="card-footer">
        <div class="form-row">
            <div class="col-4 col-md-6">
                <button type="button" class="btn btn-sm btn-outline-primary waves-effect waves-themed btn-block"
                    (click)="modal.close('Save click')">ปิดหน้านี้</button>
            </div>

            <div class="col-8 col-md-6">
                <button type="submit"
                    class="btn btn-sm btn-primary waves-effect waves-themed btn-block" (click)="onSaveImageProfile()" [disabled]="file?.length <= 0 && !selected">บันทึกข้อมูล</button>
            </div>
        </div>
    </div>
</ng-template>
