import { Component, OnInit } from '@angular/core';
import { PageloadingService } from 'src/app/core/_Services';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  constructor(  ) { }

  ngOnInit(): void {
    // this.pageload.hide();
  }

}
