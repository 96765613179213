<div class="d-flex align-items-center flex-1 text-muted">
<!--    <span class="hidden-md-down fw-700">2020 © SmartAdmin by&nbsp;<a href='https://www.gotbootstrap.com' class='text-primary fw-500' title='gotbootstrap.com' target='_blank'>gotbootstrap.com</a></span>-->
</div>
<div>
    <ul class="list-table m-0">
<!--        <li><a href="intel_introduction.html" class="text-secondary fw-700">About</a></li>-->
<!--        <li class="pl-3"><a href="info_app_licensing.html" class="text-secondary fw-700">License</a></li>-->
<!--        <li class="pl-3"><a href="info_app_docs.html" class="text-secondary fw-700">Documentation</a></li>-->
<!--        <li class="pl-3 fs-xl"><a href="https://wrapbootstrap.com/user/MyOrange" class="text-secondary" target="_blank"><i class="fal fa-question-circle" aria-hidden="true"></i></a></li>-->
        <li class="pl-3">UI Version: {{this.version}}</li>
    </ul>
</div>
