import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IBreadcrumb } from './breadcrumb-model';

@Injectable({
  providedIn: 'root'
})
export class DynamicBreadcrumbService {
  public breadcrumbLabels: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public newBreadcrumb: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<IBreadcrumb[]>([]);
  public titlePage: BehaviorSubject<string> = new BehaviorSubject<string>("");
  constructor(private router: ActivatedRoute) {

  }

  updateBreadcrumbLabels(labels: any) {
    this.breadcrumbLabels.next(labels);
  }

  updateBreadcrumb(newBreadcrumb: IBreadcrumb[]) {
    this.newBreadcrumb.next(newBreadcrumb);
  }

  updateTitlePage(label: string) {
    this.titlePage.next(label);
  }
}
