import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepModel } from '../models/step-model';

// const STEPS = [
//   { stepIndex: 1, isComplete: false, text: 'qq' },
//   { stepIndex: 2, isComplete: false, text: 'ww' },
// ];

@Injectable({
  providedIn: 'root'
})
export class StepsService {

  steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>([]);
  currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);

  constructor() {
    this.currentStep$.next(this.steps$.value[0]);
  }

  setCurrentStep(step: StepModel): void {
    this.currentStep$.next(step);
  }

  getCurrentStep(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  setSteps(steps: StepModel[]): void {
    this.steps$ = new BehaviorSubject<StepModel[]>(steps);
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$?.value?.stepIndex;

    if (index < this.steps$?.value?.length) {
      this.currentStep$.next(this.steps$?.value[index]);
    }
  }

  isLastStep(): boolean {
    return this.currentStep$?.value?.stepIndex === this.steps$?.value?.length;
  }
}
