export class CarrierProposeData {
  carrierProposeId: number | undefined;
  shipperBookingId: number | undefined;
  shipperBookingNo: string | undefined;
  bookingExpenseDtos: BookingExpense[] | undefined;
  totalTargetPrice: number | undefined;
  proposeTruckTypes: ProposeTruckType[] | undefined;
  unitPriceId: number | undefined;
  unitPriceName: string | undefined;
  deliveryPricePerUnitPrice: number | undefined;
  deliveryTextDisplay: string | undefined;
  deliveryLaborTextDisplay: string | undefined;
  needLabor: boolean | undefined;
  laborPricePerUnitPrice: number | undefined;
  is360Work: boolean | undefined;
  requestCategoryID: number | undefined;
  isBidPrice: boolean | undefined;
  assignmentId: number[] | undefined;
  userId: number | undefined;
  carrierCompanyId: number | undefined;
  totalTargetPerUnitPrice: number | undefined;
  carrierProposeEquipmentDtos: CarrierProposeEquipmentDto[] = [];
  workCheckDtos: CarrierProposeWorkCheckList[] = [];
  isCompanyTruckType: boolean | undefined;
  slot :string | undefined;
  truckBooking: any;
}

export class BookingExpense {
  bookingExpenseId: number | undefined;
  expenseTypeId: number | undefined;
  expenseTypeName: string | undefined;
  subExpenseTypeName: string | undefined;
  quantity: number | undefined;
  pricePerUnit: number | undefined;
  valid: boolean | undefined;
  pricePerUnitRequest: number | undefined;
}

export class ProposeTruckType {
  truckTypeId: number | undefined;
  truckTypeName: string | undefined;
  noOfTruck: number | undefined;
  noOfTruckRequest: number | undefined;
}

export class CarrierProposeEquipmentDto {
  proposeEquipmentId: number | undefined;
  carrierProposeId: number | undefined;
  equipmentId: number | undefined;
  equipmentName: string | undefined;
  isChecked: boolean | undefined;
  note?: string | undefined;
}
export class CarrierProposeWorkCheckList {
  workName: string | undefined;
  isChecked: boolean | undefined;
}


export class CarrierProposeRequest {
  CompanyId: number | undefined;
  CarrierProposeId: number | undefined;
  ShipperBookingId: number | undefined;
  //JobSearch = true
  Is360Work: boolean | undefined;
}


export class CauseOfRejectionData {
  id: number | undefined;
  value: string | undefined;
  isSpecify: boolean;
  text: string | undefined;
  checked: boolean
}

export class CarrierProposeRejectRequest {
  Is360Work!: boolean;
  CarrierCompanyId!: number;
  ShipperBookingId!: number;
  CarrierAssignmentIds: number[] = [];
  RejectionReasonId!: number;
  RejectionReasonText!: string;
}
