<ng-template #modaltraininghistoryform let-modal>
    <div class="modal-header">
        <h5 class="modal-title"><i class="fal fa-star color-success-100 fw-900 mr-1"></i>{{modalTitle}}</h5>
        <button type="button" class="btn btn-sm-custom btn-outline-danger waves-effect waves-themed" aria-hidden="true"
            data-dismiss="modal" aria-label="Close" (click)="closed()">
            <i class="fal fa-times"></i>
        </button>
    </div>

    <form [formGroup]="trainingForm">
        <div class="modal-body">
            <div class="row mt-2">

                <div *ngIf="action == 'SUCCESS'" class="col-12">
                    <div class="row">
                        <div class="help-block text-muted col-4">วันที่</div>
                        <div class="help-block text-muted col-8">หัวข้อการอบรม</div>
                    </div>
                </div>

                <div *ngIf="action == 'SUCCESS'" class="col-12">
                    <div class="row">
                        <label class="col-4">{{userTraining?.date|thaidate:'short'}}</label>
                        <label class="col-8">{{userTraining?.title}}</label>
                    </div>
                </div>

                <div *ngIf="action != 'SUCCESS'" class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-label">วันที่<span class="txt-red">*</span></label>

                        <div class="input-group">
                            <input ngbDatepicker #date="ngbDatepicker" required formControlName="date"
                                class="form-control" placeholder="ระบุวันที่อบรม" (click)="date.toggle()" required>
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-secondary" (click)="date.toggle()" type="button">
                                    <span class="fal fa-calendar-alt fa-lg"></span>
                                </button>
                            </div>
                        </div>

                        <!-- <div class="input-group">
                                    <input class="form-control" formControlName="date" type="date" placeholder="ระบุวันที่อบรม"/>
                                </div> -->

                    </div>
                </div>

                <div *ngIf="action != 'SUCCESS'" class="col-12">
                    <div class="form-group">
                        <label class="form-label">หัวข้อการอบรม<span class="txt-red">*</span></label>

                        <div class="input-group">
                            <input class="form-control" formControlName="title" type="text"
                                placeholder="ระบุหัวข้อการอบรม" [readonly]="action == 'SUCCESS'"/>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label class="form-label">รายละเอียดเพิ่มเติม<span class="txt-red">*</span></label>

                        <div class="input-group">
                            <textarea class="form-control" formControlName="detail"
                                placeholder="กรอกรายละเอียดเพิ่มเติม" rows='5' maxlength="500"></textarea>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <label class="form-label" for="firstName">อัพโหลดรูปประวัติการอบรม</label>
                    <!-- <div class="custom-dropzone dropzone" ngx-dropzone>
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fal fa-cloud-upload text-muted mb-3"></i> <br>
                                        <span class="text-uppercase">Drop files here or click to upload.</span>
                                        <br>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true">
                                    <ngx-dropzone-label> </ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                                <ngx-dropzone-preview [removable]="true">
                                    <ngx-dropzone-label></ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </div> -->

                    <div class="row">
                        <div *ngFor="let image of trainingImage" class="col-6 text-center mb-3">
                            <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                [imageSrc]="image.ImageUrl" [extension]="image.ImageExtension!" [removable]="true"
                                (removed)="ngxdropzoneimage_old_delete(image)">
                            </ngx-custom-dropzone-image-preview>

                        </div>
                        <div class="col-6 text-center mb-3" *ngFor="let f of trainingNewImage">
                            <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f"
                                [removable]="true" (removed)="ngxdropzoneimage_remove(f)">
                            </ngx-custom-dropzone-image-preview>
                            <ngx-dropzone-label>{{f.name}}</ngx-dropzone-label>
                        </div>
                        <div *ngIf="this.trainingNewImage.length + this.trainingImage.length < 20" class="col-12 col-lg-12 text-center">
                            <div id="ngxdropzoneimage-insurecar" class="custom-ngx-dropzone" ngx-dropzone
                                [accept]="'image/jpg, image/jpeg, image/png, .pdf'" [maxFileSize]="10*(1024*1024)"
                                [multiple]="true" (change)="ngxdropzoneimage_change($event)">
                                <ngx-dropzone-label>
                                    กดเพื่อเลือกรูปภาพ,หรือวางไฟล์ที่นี่

                                    <br />
                                    <small>(ขนาดไฟล์สูงสุด 10 MB)</small>
                                </ngx-dropzone-label>
                            </div>
                            <span class="text-danger"
                                *ngIf="this.trainingNewImage.length + this.trainingImage.length > 20">คุณสามารถอัปโหลดรูปภาพได้สูงสุด
                                20 รูป</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="p-2">
                <div class="row pb-2 mt-2">
                    <div class="col-md-6">
                        <button class="btn btn-outline-secondary btn-block"
                            (click)="modal.dismiss('Cross click')">ปิดหน้านี้</button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-success btn-block" (click)="onSubmit()">บันทึก</button>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="card-footer">
            <div class="row">
                <div class="col-6">
                    <button class="btn btn-outline-secondary btn-block"
                        (click)="closed()">ปิดหน้านี้</button>
                </div>
                <div class="col-6">
                    <button [disabled]="trainingForm.invalid" class="btn btn-success btn-block" (click)="onSubmit()">บันทึก</button>
                </div>
            </div>


        </div>
    </form>
</ng-template>

<app-modalsuccess #modalsuccess (btnother_event)="success_other($event)" [btnsubmit_ishide]=true></app-modalsuccess>
<app-modalsuccess #modalsuccess_complete (btnother_event)="success_complete($event)" (btnsubmit_event)="submit_complete()"></app-modalsuccess>

<app-modal-confirm #modalConfirm></app-modal-confirm>