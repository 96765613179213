import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { AuthenticationService } from '@app/core/_Services';
import { clientFleetDto } from '../../models/TruckFleet';
import { TruckManagementService } from '../../service/truck-management.service';
declare function openModal(modalID): any;
declare function closeModal(modalID): any;
@Component({
  selector: 'app-fleet-dropdown',
  templateUrl: './fleet-dropdown.component.html',
  styleUrls: ['./fleet-dropdown.component.scss']
})
export class FleetDropdownComponent implements OnInit {

  clientFleets : clientFleetDto[];
 
  currentFleetItem : clientFleetDto;
  @Input()  currentFleet = 0;
  @Input()  ShowEditFleet : boolean = true;
  @Input()  ShowAddFleet : boolean = true;
  @Output() onFleetChange :EventEmitter<any> = new EventEmitter();
  @Output() returnFleetDetail : EventEmitter<clientFleetDto> = new EventEmitter();
  @Output() EditFleet :EventEmitter<any> = new EventEmitter();
  param : Params;

  permission120100  : PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  permission130100  : PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  
  constructor(private truckmenagementService : TruckManagementService, 
    private activedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private authService: AuthenticationService,) { }


  ngOnInit(): void {
    this.authService.currentUser.subscribe((x) => {

      var p: PermissionModule = this.authService.UserPermissionModule(120000);
      if (p) {
        console.log('permission', p);
        this.permission120100 = this.authService.FindPermissionAction(
          120100,
          p.operations
        );
      }
      
      var p2: PermissionModule = this.authService.UserPermissionModule(130000);
      if (p2) {
        this.permission130100 = this.authService.FindPermissionAction(
          130100,
          p2.operations
        );
        console.log('permission130100',   this.permission130100 );
      }

    });
    this.getFleetList();
  }

  ngOnChage(){
    this.getFleetList();

  }

  getFleetList(){
    this.truckmenagementService.getClientFleet().subscribe(result => {
      console.log(result);
      this.clientFleets = result.data;

      this.getQueryParam();
    });
  }

  getQueryParam(){
    this.route.queryParams.subscribe((param) => {
      this.param = param;
      console.log('param', param);
      this.onGetQueryParam(this.param);
    });

 
  }
   onGetQueryParam(param: Params) {
     var fleetNo = this.activedRoute.snapshot.queryParamMap.get('fleet_No');
    
    if (fleetNo == null) {
      this.onFleetChange.emit(0);
      return;
    }
    
    this.currentFleet =  parseInt(fleetNo);
   // console.log("CurrentFleet",this.currentFleet)
    this.onFleetChange.emit(  this.currentFleet);
    this.currentFleetItem = this.clientFleets.find(item=>item.companyFleetNo == this.currentFleet);
    console.log("CurrentFleet",this.currentFleetItem)
    this.returnFleetDetail.emit(this.currentFleetItem);
  }


  fleetChange(any){
    console.log(any);
    this.currentFleetItem = any;
    this.returnFleetDetail.emit(this.currentFleetItem);
    this.onFleetChange.emit(  this.currentFleetItem.companyFleetNo);
  }

  editFleetModal(){
    this.EditFleet.emit();
    // openModal("#addFleetModal")
  }

  addUser() {
    // this.isEdit = false;
    // this.isViewUser = false;
    openModal('#EditUser');
  }

  checkPage():boolean{
    let check = false
    return check
  }

}
