
<ul class="custom-bread-crumb breadcrumb breadcrumb-seperator-1 mb-0" [ngStyle]="{'background-color': bgColor}">
  <span *ngFor="let item of breadcrumb; let i = index">
      <li [ngStyle]="{'font-size': fontSize}">
          <a *ngIf="item?.url" [href]="item?.url">{{ item.label }}</a>
          <span *ngIf="!item?.url" [ngStyle]="{'color': lastLinkColor}">{{ item.label }}</span>
          <span class="line" *ngIf="breadcrumb.length !== i+1">{{symbol}}</span>
      </li>
  </span>
</ul>
<div class="subheader sub-header-scale  pt-0 mt-1 mb-2">
  <div class="row  justify-content-between" style="width: 100%;">
  
    <div class="col">
      <h4 class="pb-0 mb-0">
        <i class='subheader-icon fal fa-window'></i> {{titlePage}}
    </h4>
    </div>
    <div class="col-auto" *ngIf="IntroAction" style="cursor: pointer">
      <span (click)="clickIntro()"><i class="fas fa-comment-dots"></i> เรียนรู้การใช้งาน
      </span>

  </div>
  </div>

  </div>


