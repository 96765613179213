import { enableProdMode } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { platformBrowser } from '@angular/platform-browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import localeEn from '@angular/common/locales/en';

// registerLocaleData(localeEn); // th-TH

if (environment.production) {
  enableProdMode();
  console.log = () => { }; // disable console on production
}

platformBrowser()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((error) => console.log(error));
