import { Component, HostListener, Input, OnInit } from '@angular/core';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService } from '@app/core/_Services';
import { BannerService } from '@app/core/_Services/banner.service';
import {
  BannerRequestDto,
  BannerResponseDto,
} from '@app/modules/banner/BannerDto';
import { ResizedEvent } from 'angular-resize-event';
import * as moment from 'moment';
import { now } from 'moment';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;

  req: BannerRequestDto = new BannerRequestDto();
  banner: BannerResponseDto = new BannerResponseDto();
  currentUser: User;

  constructor(private bannerService: BannerService,private authenticationService: AuthenticationService) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  // ngOnInit() {

  //  // this.bannerService.getBanner(this.req);
  //  // this.onSubscibe();
  // }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((x) =>{
      this.currentUser = x;
    });

    this.onResize();

    var now = new Date();
    this.req.height = this.screenHeight;
    this.req.width = this.screenWidth;
    this.req.registerPackageId =  this.currentUser.registerPackage.id;
    this.req.requestDate = moment(now).format("YYYY-MM-DD");
    this.bannerService.getBanner(this.req);
    this.onSubscibe();
  }

  onSubscibe(): void {
    this.bannerService.bannerOnChange.subscribe((res) => {
      this.banner = res;
      console.log('this.banner', this.banner);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    console.log(
      `Screen height: ${this.screenHeight}, Screen width: ${this.screenWidth}`
    );
  }
}
