import { Component, OnInit,OnDestroy, AfterViewInit } from '@angular/core';
import { MasterReport, subReport, isseting } from '../../model/NotiReportmodel';
import { Subject } from 'rxjs';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { AuthenticationService } from '@app/core/_Services';
import { User } from '@app/core/_Models/UserModel/User';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
declare function initDataTable(tableID): any;
@Component({
  selector: 'app-setnotification',
  templateUrl: './setnotification.component.html',
  styleUrls: ['./setnotification.component.scss']
})
export class SetnotificationComponent implements OnInit, OnDestroy, AfterViewInit {
  user: User;
  destroy$: Subject<boolean> = new Subject<boolean>();
  permission90200 : PermissionGroup= {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };
  constructor(private authService: AuthenticationService) {
    this.authService.currentUser.subscribe((x) => {this.user = x;
      var p: PermissionModule = this.authService.UserPermissionModule(90000);
      if (p) {
        console.log('permission', p);

        this.permission90200 = this.authService.FindPermissionAction(
          90200,
          p.operations
        );

      }
        });
   }

  ngOnDestroy(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  
  private tableWidget: any;

  async ngAfterViewInit() {
    this.tableWidget = await initDataTable("Clienttbcustomerlist");
  }

  status = [
    { id: 1, name: 'ไม่แจ้ง' },
    { id: 2, name: 'แจ้งเฉพาะงานตัวเอง' },
    { id: 3, name: 'แจ้งเฉพาะงานของทีมตัวเอง' },
    { id: 4, name: 'แจ้งงานทั้งหมด' },
  ];
  week = [
    { id: 1, name: 'จันทร์' },
    { id: 2, name: 'อังคาร' },
    { id: 3, name: 'พุธ' },
    { id: 4, name: 'พฤหัส' },
    { id: 4, name: 'ศุกร์' },
    { id: 4, name: 'เสาร์' },
    { id: 4, name: 'อาทิตย์' },
  ];
  linegroup = [
    { id: 1, groupline: "Line Group 1", name: 'Team A' },
    { id: 2, groupline: "Line Group 2", name: 'Team B' },
    { id: 3, groupline: "Line Group 3", name: 'Team C' },
    { id: 4, groupline: "Line Group 4", name: 'Team A, Team B, Team C' },
  ];

  masterReport: Array<MasterReport> = new Array<MasterReport>();

  ngOnInit(): void {
    this.Createsub();
  }

  LocationChange(event) {

  }

  async Createsub() {
    let m1 = new MasterReport();
    m1.name = "แจ้งเตือน 1 ข้อความ สรุปการจองรายวัน";
    let subReport1 = new Array<subReport>();

    let seting1 = new isseting();
    let s1 = new subReport();
    s1.name = "จำนวนBookingที่Pending/รอเริ่มขนส่งวันนี้และวันอื่นๆ/งานกำลังขนส่ง/ขนเสร็จสิ้น";
    seting1.IsPlant = 1;
    s1.Isset = seting1;

    let seting2 = new isseting();
    let s2 = new subReport();
    s2.name = "จำนวนBooking และ shipment ที่ส่งontime / ไม่on-time / ค้างส่งข้ามวัน จากเมื่อวานนี้";
    seting2.IsSupply = 2;
    s2.Isset = seting2;

    let seting3 = new isseting();
    let s3 = new subReport();
    s3.name = "จำนวนBooking และ shipment ที่ส่งontime / ไม่on-time / ค้างส่งข้ามวัน จากเมื่อวานนี้";
    seting3.Isgroup1 = 4;
    s3.Isset = seting3;

    let seting4 = new isseting();
    let s4 = new subReport();
    s4.name = "จำนวนBookingที่รอส่งคืนเอกสาร";
    seting4.Isgroup2 = 4;
    s4.Isset = seting4;

    let seting5 = new isseting();
    let s5 = new subReport();
    s5.name = "จำนวนBookingที่มีเคสรอแก้ไข";
    seting5.Isgroup2 = 4;
    s5.Isset = seting5;

    let seting6 = new isseting();
    let s6 = new subReport();
    s6.name = "จำนวนBookingที่รอรับวางบิล";
    seting6.Isgroup4 = 4;
    s6.Isset = seting6;

    let seting7 = new isseting();
    let s7 = new subReport();
    s7.name = "จำนวนBookingที่รอส่งคืนเอกสาร";
    seting7.IsAccount = 4;
    s7.Isset = seting7;

    subReport1.push(s1);
    subReport1.push(s2);
    subReport1.push(s3);
    subReport1.push(s4);
    subReport1.push(s5);
    subReport1.push(s6);
    subReport1.push(s7);

    m1.sub = subReport1;
    m1.isTime = true;
    this.masterReport.push(m1)

    let m2 = new MasterReport();
    m2.name = "แจ้งเตือน 1 ข้อความ สรุปรถเข้ารับสินค้ารายวัน";
    let subReport21 = new Array<subReport>();

    let seting21 = new isseting();
    let s21 = new subReport();
    s21.name = "จำนวนBookingที่ขนส่งวันนี้ ตามแต่ละ Location ต้นทาง";
    seting21.IsPlant = 1;
    s21.Isset = seting21;

    subReport21.push(s21);
    m2.sub = subReport21;
    m2.isTime = true;
    m2.islocation = true;
    this.masterReport.push(m2)
  }

}
