<aside class="page-sidebar">
  <div class="page-logo">
    <a href="#" class="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal"
      data-target="#modal-shortcut">
      <img src="{{tmsLogo}}" (error)="onImgError($event)"
        alt="SmartAdmin WebApp" aria-roledescription="logo">
      <span class="page-logo-text mr-1"></span>
      <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
      <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
    </a>
  </div>
  <!-- BEGIN PRIMARY NAVIGATION -->
  <nav id="js-primary-nav" class="primary-nav" role="navigation" id="slide-intro"
    [ngClass]="clientModule?.pkg_id == 30 ? '' : 'bg-techx-basic-color'">
    <div class="nav-filter">
      <div class="position-relative">
        <input type="text" id="nav_filter_input" placeholder="Filter menu" class="form-control" tabindex="0">
        <a href="#" onclick="return false;" class="btn-primary btn-search-close js-waves-off" data-action="toggle"
          data-class="list-filter-active" data-target=".page-sidebar">
          <i class="fal fa-chevron-up"></i>
        </a>
      </div>
    </div>
    <div class="info-card" id="info-profile">
      <img src="{{photoImage}}" (error)="onImgErrorProfile($event)" class="profile-image rounded-circle">
      <!-- <div class="click-zoom">
        <label>
          <input type="checkbox">
          <img class="profile-image rounded-circle" src="{{photoImage}}" (error)="onImgErrorProfile($event)">
        </label>
      </div> -->
      <!-- <img *ngIf="!auth.photoAsync | async" src="https://sermpanya.com/sponline/assets/webimg/img_avatar.png" (error)="onImgErrorProfile($event)" class="profile-image rounded-circle" >    -->
      <div class="info-card-text">
        <small class="d-inline-block text-truncate text-truncate-sm font-weight-normal">{{currentUser?.Role?.roleName}}</small>

          <br><span class="text-truncate text-truncate-sm d-inline-block font-weight-bold">
            {{currentUser?.fullName}}
          </span>
          <!-- <ng-container *ngIf="currentUser?.companyTypeId != 3"> -->
            <br><small class="d-inline-block text-truncate text-truncate-sm font-weight-normal">{{currentUser?.client?.clientName }}</small>
          <!-- </ng-container> -->
      </div>
      <img src="assets/img/card-backgrounds/road.jpg" (error)="onImgError($event)" class="cover" alt="cover">
      <a href="#" onclick="return false;" class="pull-trigger-btn" data-action="toggle" data-class="list-filter-active"
        data-target=".page-sidebar" data-focus="nav_filter_input">
        <i class="fal fa-angle-down"></i>
      </a>
    </div>
    <ng-container *ngIf="!clientModule || clientModule?.pkg_id < 30; else menuEnterpire">
      <ul id="js-nav-menu" class="nav-menu mb-0 pb-2" [ngClass]="{'bg-techx-basic-color': m.platformId > 1, 'bg-360truck-v3':m.platformId == 1}" *ngFor="let m of basicMenus;let x = index"  id="welcome-{{x}}" >
        <li class="nav-title mt-0 pt-4 text-write pr-1">{{m.menuName}}</li>
        <li *ngFor="let child of m.childrens" #rla="routerLinkActive" routerLinkActive class="text-write"
          id="navMenu{{child.menuId}}"
          [routerLinkActiveOptions]="{exact: true}"
          [ngClass]="currentMenuId == child.menuId ? child.childrens.length > 0 ?'active open':'active' : ''">


          <!--          <a title="{{sub.menuName}}" routerLink="{{sub.menuLink}}"  [queryParams]="sub.queryParams" routerLinkActive="active"-->
          <a class="text-write pr-1" title="{{child.menuName}}" (click)="MenuCrossHandler($event,child)" routerLinkActive="active"
            *ngIf="child.childrens.length ===0"
            [queryParams]="parseQueryParamToJson(child.jsonQueryParams)"
            routerLinkActive #rlaChy="routerLinkActive" [ngClass]="rlaChy.isActive ?'active' : ''">
            <i [ngClass]="child.menuIcon"></i>
            <span class="nav-link-text">{{child.menuName}} </span> <span *ngIf="isNewmenu(child.menuId)" class="badge-danger badge-pill" style="font-size: 10px;"> ใหม่ </span>
          </a>

          <a class="text-write pr-1" title="{{child.menuName}}" *ngIf="child.childrens.length > 0"
            [ngClass]="currentMenuId == child?.menuId && child.platformId > 1 ?'active' : ''" (click)="MenuCrossHandler($event, child)">
            <i [ngClass]="child.menuIcon"></i>
            <span class="nav-link-text">{{child.menuName}} </span> <span *ngIf="isNewmenu(child.menuId)" class="badge-danger badge-pill" style="font-size: 10px;"> ใหม่ </span>
            <!-- <b class="collapse-sign"><em class="fal fa-angle-up"></em></b> -->
            <b class="collapse-sign">
              <em class="fal mr-2 {{currentMenuId == child?.menuId ?' fa-angle-up':' fa-angle-down'}}"></em></b>
          </a>
          <ul *ngFor="let _child of child.childrens;let y = index" [ngStyle]="{'display':currentMenuId == child?.menuId? 'block':'none'}" class="text-write" >

            <!--            <li routerLink="{{child.menuLink}}" [queryParams]="child.queryParams" routerLinkActive="active">-->
            <!--              <a routerLink="{{child.menuLink}}" title="{{child.menuName}}"  [queryParams]="child.queryParams" routerLinkActive="active"> -->
            <li (click)="onMenuRedirect(_child)" routerLinkActive="active"
              class="{{currentUrl === _child?.menuLink + (_child.queryParams ? _child.queryParams : '') ? 'active': ''}}">
              <a class="text-write pr-1"  title="{{_child.menuName}}" routerLinkActive="active">
                <span class="nav-link-text">{{_child.menuName}}</span>
              </a>
            </li>

          </ul>
        </li>
      </ul>
    </ng-container>
    <ng-template #menuEnterpire>
      <ul *ngFor="let m of menu" class="nav-menu" [ngClass]="{'bg-360truck-v3':m.platformId == 1}">

        <li class="nav-title text-write">{{m.menuName}}</li>


        <li *ngFor="let sub of m.subMenuDto" id="navMenu{{sub.menuId}}" (click)="navTitleClick('navMenu'+sub.menuId)">
          <!--          <a title="{{sub.menuName}}" routerLink="{{sub.menuLink}}"  [queryParams]="sub.queryParams" routerLinkActive="active"-->
          <a class="text-write" title="{{sub.menuName}}" (click)="onRedirect(sub.menuLink)" routerLinkActive="active"
            *ngIf="sub.childMenuDto.length ===0 && sub.platformId == 2">
            <i [ngClass]="sub.menuIcon"></i>
            <span class="nav-link-text">{{sub.menuName}}</span>
          </a>
          <a class="text-write" title="{{sub.menuName}}" (click)="enterPriseMenuCrossHandler(sub.platformId, sub.menuLink, sub.queryParams)" routerLinkActive="active"
          *ngIf="sub.childMenuDto.length ===0 && sub.platformId == 1">
            <i [ngClass]="sub.menuIcon"></i>
            <span class="nav-link-text">{{sub.menuName}}</span>
          </a>

          <a class="text-write" title="{{sub.menuName}}" *ngIf="sub.childMenuDto.length > 0">
            <i [ngClass]="sub.menuIcon"></i>
            <span class="nav-link-text">{{sub.menuName}}</span>
            <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>
          </a>
          <ul *ngFor="let child of sub.childMenuDto">

            <!--            <li routerLink="{{child.menuLink}}" [queryParams]="child.queryParams" routerLinkActive="active">-->
            <!--              <a routerLink="{{child.menuLink}}" title="{{child.menuName}}"  [queryParams]="child.queryParams" routerLinkActive="active"> -->
            <li (click)="onRedirect(child.menuLink)" routerLinkActive="active" *ngIf="child.platformId == 2">
              <a class="text-write" (click)="onRedirect(child.menuLink)" title="{{child.menuName}}" routerLinkActive="active">
                <span class="nav-link-text">{{child.menuName}}</span>
              </a>
            </li>
            <li routerLinkActive="active" *ngIf="child.platformId == 1">
              <a class="text-write" (click)="enterPriseMenuCrossHandler(child.platformId, child.menuLink, child.queryParams)" title="{{child.menuName}}" routerLinkActive="active">
                <span class="nav-link-text">{{child.menuName}}</span>
              </a>
            </li>
            <!--            <li routerLink="/booking" [queryParams]="{t:'tab-pending',st:'response'}" routerLinkActive="active">-->
            <!--              <a routerLink="/booking?t=tab-pending&st=response" title="รอยืนยัน" routerLinkActive="active">-->
            <!--                <span class="nav-link-text">รอยืนยัน</span>-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li routerLink="/booking" [queryParams]="{t:'tab-pending',st:'request'}" routerLinkActive="active">-->
            <!--              <a routerLink="/booking?t=tab-pending&st=all" title="ขอราคา" routerLinkActive="active">-->
            <!--                <span class="nav-link-text">ขอราคา</span>-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li routerLink="/booking" [queryParams]="{t:'tab-pending',st:'all'}" routerLinkActive="active">-->
            <!--              <a routerLink="/booking?t=tab-pending&st=all" title="ทั้งหมด" routerLinkActive="active">-->
            <!--                <span class="nav-link-text">ทั้งหมด</span>-->
            <!--              </a>-->
            <!--            </li>-->
          </ul>
        </li>
      </ul>
    </ng-template>


    <!--        <li id="naveMenuWaitForProgress" (click)="navTitleClick('naveMenuWaitForProgress')">-->
    <!--          <a title="รอดำเนินการ">-->
    <!--            <i class="fas fa-compass"></i>-->
    <!--            <span class="nav-link-text">ระหว่างขนส่ง</span>-->
    <!--            <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--          </a>-->
    <!--          <ul>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-ongoing',st:'wait-for-start'}" routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-ongoing&st=wait-for-start" title="ทั้งหมด" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">รอเริ่มขนส่ง</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-ongoing',st:'on-the-way'}" routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-ongoing&st=on-the-way" title="รอจ่ายงาน" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">กำลังขนส่ง</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-ongoing',st:'all'}" routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-ongoing&st=all" title="รอยืนยัน" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">ทั้งหมด</span>-->
    <!--              </a>-->
    <!--            </li>-->

    <!--          </ul>-->
    <!--        </li>-->
    <!--        <li id="navMenuDelivered" (click)="navTitleClick('navMenuDelivered')">-->
    <!--          <a title="ส่งสำเร็จ">-->
    <!--            <i class="fas fa-clipboard-check"></i>-->
    <!--            <span class="nav-link-text">ส่งเสร็จแล้ว</span>-->
    <!--            <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--          </a>-->
    <!--          <ul>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-delivered',st:'wait-for-verify'}"-->
    <!--                routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-delivered&st=wait-for-verify" title="รอตรวจสอบ" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">รอตรวจสอบ</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-delivered',st:'follow-case'}" routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-delivered&st=follow-case" title="รอติดตาม" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">รอติดตาม</span>-->
    <!--              </a>-->
    <!--            </li>-->

    <!--          </ul>-->
    <!--        </li>-->
    <!--        <li id="navMenuPayment" (click)="navTitleClick('navMenuPayment')">-->
    <!--          <a title="รอรับวางบิล">-->
    <!--            <i class="fas fa-file-invoice-dollar"></i>-->
    <!--            <span class="nav-link-text">รับวางบิล/ทำจ่าย</span>-->
    <!--            <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--          </a>-->
    <!--          <ul>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-pay',st:'wait-for-bill'}" routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-pay&st=wait-for-bill" title="รอรับวางบิล" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">รอรับวางบิล</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-pay',st:'wait-for-pay'}" routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-pay&st=wait-for-pay" title="รอชำระเงิน" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">รออนุมัติ/รอทำจ่าย</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--            <li routerLink="/booking" [queryParams]="{t:'tab-pay',st:'paid'}" routerLinkActive="active">-->
    <!--              <a routerLink="/booking?t=tab-pay&st=paid" title="ชำระเงินแล้ว" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">ทำจ่ายแล้ว</span>-->
    <!--              </a>-->
    <!--            </li>-->

    <!--          </ul>-->
    <!--        </li>-->

    <!--        <li class="nav-title">รถขนส่งและเส้นทาง</li>-->
    <!--        <li id="navMenuHome" (click)="navTitleClick('navMenuHome')">-->
    <!--          <a title="รถขนส่ง">-->
    <!--            <i class="fas fa-truck"></i>-->
    <!--            <span class="nav-link-text">รถขนส่ง</span>-->
    <!--            <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--          </a>-->
    <!--          <ul>-->
    <!--            <li routerLink="/carriers" routerLinkActive="active">-->
    <!--              <a routerLink="/carriers" title="รายชื่อผู้ขนส่ง" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">รายชื่อผู้ขนส่ง</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--          </ul>-->


    <!--        </li>-->
    <!--        <li id="navMenuMyRoute" (click)="navTitleClick('navMenuMyRoute')">-->
    <!--          <a title="เส้นทางของฉัน">-->
    <!--            <i class="fas fa-route"></i>-->
    <!--            <span class="nav-link-text">เส้นทางของฉัน</span>-->
    <!--            <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--          </a>-->
    <!--          <ul>-->
    <!--            <li routerLink="/routes/spot-route" routerLinkActive="active">-->
    <!--              <a routerLink="/routes/spot-route" title="routes" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">Spot Routes</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--            <li routerLink="/routes/bit-route" routerLinkActive="active">-->
    <!--              <a routerLink="/routes/bit-route" title="routes" routerLinkActive="active">-->
    <!--                <span class="nav-link-text">Bid Routes</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--        </li>-->


    <!--      <li class="nav-title">ข้อมูลหลักที่ใช้ในการขนส่ง</li>-->
    <!--      <li id="navMenurouteData" (click)="navTitleClick('navMenurouteData')">-->
    <!--        <a title="รายการลูกค้า" routerLink="/master" routerLinkActive="active">-->
    <!--          <i class="fas fa-user-tag"></i>-->
    <!--          <span class="nav-link-text">รายการลูกค้า</span>-->
    <!--        </a>-->
    <!--        <a title="รายการสถานที่" routerLink="/master" routerLinkActive="active">-->
    <!--          <i class="fas fa-warehouse"></i>-->
    <!--          <span class="nav-link-text">รายการสถานที่</span>-->
    <!--        </a>-->
    <!--        <a title="รายการสินค้า" routerLink="/master" routerLinkActive="active">-->
    <!--          <i class="fas fa-box-open"></i>-->
    <!--          <span class="nav-link-text">รายการสินค้า</span>-->
    <!--        </a>-->
    <!--      </li>-->

    <!--      <li class="nav-title">ตั้งค่า</li>-->
    <!--      <li id="navMenuCompany" (click)="navTitleClick('navMenuCompany')">-->
    <!--        <a title="ข้อมูลของคุณ">-->
    <!--          <i class="fas fa-user"></i>-->
    <!--          <span class="nav-link-text">ข้อมูลของคุณ</span>-->
    <!--          <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--        </a>-->
    <!--        <ul>-->
    <!--          <li routerLink="/profile" routerLinkActive="active">-->
    <!--            <a routerLink="/profile" title="ข้อมูลส่วนตัว" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">ข้อมูลส่วนตัว</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--          <li routerLink="/profile" routerLinkActive="active">-->
    <!--            <a routerLink="/profile" title="ข้อมูลบริษัท" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">ข้อมูลบริษัท</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <!--      <li id="navMenuUser" (click)="navTitleClick('navMenuUser')">-->
    <!--        <a title="ผู้ใช้และทีม">-->
    <!--          <i class="fas fa-users"></i>-->
    <!--          <span class="nav-link-text">ผู้ใช้และทีม</span>-->
    <!--          <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--        </a>-->
    <!--        <ul>-->
    <!--          <li routerLink="/team" routerLinkActive="active">-->
    <!--            <a routerLink="/team" title="รายการผู้ใช้" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">รายการผู้ใช้</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--          <li routerLink="/team" routerLinkActive="active">-->
    <!--            <a routerLink="/team" title="รายการทีม" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">รายการทีม</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </li>-->

    <!--      <li id="navMenuSetting" (click)="navTitleClick('navMenuSetting')">-->
    <!--        <a title="การตั้งค่า">-->
    <!--          <i class="fas fa-cog"></i>-->
    <!--          <span class="nav-link-text">การตั้งค่า</span>-->
    <!--          <b class="collapse-sign"><em class="fal fa-angle-up"></em></b>-->
    <!--        </a>-->
    <!--        <ul>-->
    <!--          <li routerLink="/noti" routerLinkActive="active">-->
    <!--            <a routerLink="/noti" title="แจ้งเตือนงานขนส่ง" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">การแจ้งเตือน</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--          <li routerLink="/permis" routerLinkActive="active">-->
    <!--            <a routerLink="/permis" title="ตั้งค่าสิทธ์การใช้งาน" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">ตั้งค่าสิทธ์การใช้งาน</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--          <li routerLink="/setting" routerLinkActive="active">-->
    <!--            <a routerLink="/setting" title="ตั้งค่าการจอง" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">ตั้งค่าการจอง</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--          <li routerLink="/setting" routerLinkActive="active">-->
    <!--            <a routerLink="/setting" title="ตั้งค่าสินค้า" routerLinkActive="active">-->
    <!--              <span class="nav-link-text">ตั้งค่าสินค้า</span>-->
    <!--            </a>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <div class="filter-message js-filter-message bg-success-600"></div>
  </nav>
  <!-- END PRIMARY NAVIGATION -->
  <!-- NAV FOOTER -->
  <div class="nav-footer shadow-top bg-gradient-color">
    <a href="#" onclick="return false;" data-action="toggle" data-class="nav-function-minify" class="hidden-md-down">
      <i class="ni ni-chevron-right"></i>
      <i class="ni ni-chevron-right"></i>
    </a>
    <!--    <ul class="list-table m-auto nav-footer-buttons">-->
    <!--      <li>-->
    <!--        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Chat logs">-->
    <!--          <i class="fal fa-comments"></i>-->
    <!--        </a>-->
    <!--      </li>-->
    <!--      <li>-->
    <!--        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Support Chat">-->
    <!--          <i class="fal fa-life-ring"></i>-->
    <!--        </a>-->
    <!--      </li>-->
    <!--      <li>-->
    <!--        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Make a call">-->
    <!--          <i class="fal fa-phone"></i>-->
    <!--        </a>-->
    <!--      </li>-->
    <!--    </ul>-->
  </div>
  <!-- END NAV FOOTER -->
</aside>
