import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { BookingFilterModel } from '@app/modules/booking/models/BookingFilterModel';
import { FireBaseNotiModel } from '@app/core/_Models/FireBaseNotiModel';
import { HttpService } from '@app/core/_Services/http.service';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { DeviceDetectorService } from 'ngx-device-detector';
import { log } from 'util';
import { User } from '@app/core/_Models/UserModel/User';
@Injectable()
export class MessagingService {
  private currentMessageSubject: BehaviorSubject<FireBaseNotiModel>;
  public currentMessage: Observable<FireBaseNotiModel>;

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private notifcation: NotificationService,
    private _http: HttpService,
    private deviceService: DeviceDetectorService
  ) {
    this.currentMessageSubject = new BehaviorSubject<FireBaseNotiModel>(
      new FireBaseNotiModel()
    );
    this.currentMessage = this.currentMessageSubject.asObservable();
    // this.angularFireMessaging.messaging.subscribe((_messaging) => {
    //   console.log('_messaging : ',_messaging);
    //   _messaging.onMessage=_messaging.onMessage.bind(_messaging);
    //  // _messaging.onTokenRefresh=_messaging.onTokenRefresh.bind(_messaging);
    // //  _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging);
    // }
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  public get GetFireBaseMessage(): any {
    return this.currentMessageSubject.value;
  }

  updateToken(userId, token) {
    this.angularFireAuth.authState.pipe(take(1)).subscribe((aa) => {
      //  console.log('angularFireAuth',aa);
      const data = {};
      data[userId] = token;
      this.angularFireDB.object('fcmTokens/').update(data);

      //เช็ควันหมดอายุ ถ้าหมดแล้วขอใหม่ ถ้ามีแล้ว
    });
  }

  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        const deviceInfo = this.deviceService.getDeviceInfo();
        let dataPost = {
          tst360UserId: userId,
          token: token,
          deviceName: deviceInfo.browser + '-' + deviceInfo.browser_version,
        };
        console.log('requestToken', dataPost);
        this._http
          .post('/Users/addFirebaseToken', { tokenDto: dataPost })
          .subscribe((res: TMSAPIResponse) => {});
        this.updateToken(userId, token);
      },
      (err) => {
        console.info('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage(clientId) {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      console.log('new message received. ', payload);
      // this.notifcation.toastsuccess(payload?.notification.title,payload?.notification.body);
      if (payload) {
        if (!payload.client_id || payload.client_id == clientId) {
          let res = new FireBaseNotiModel();
          res.body = payload?.notification?.body;
          res.click_action = payload?.notification?.click_action;
          this.currentMessageSubject.next(res);
        }
      }
    });
  }
}
