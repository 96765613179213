import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[numberWithoutDecimal]',
})
export class NumberWithoutDecimalDirective {
  constructor(private el: ElementRef, private ngModel: NgModel) {}

  ngOnInit() {
    this.ngModel.valueChanges.subscribe((value) => {
      if (typeof value === 'number') {
        this.processValue(value);
      }
    });
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const parseValue = parseFloat(value);
    this.processValue(parseValue);
  }

  processValue(value: number): void {
    if (isNaN(value)) {
      return this.updateInput(0);
    }
    return this.updateInput(value);
  }

  updateInput(newValue: number): void {
    this.el.nativeElement.value = newValue;
    this.ngModel.update.emit(newValue);
  }
}
