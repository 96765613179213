import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/_Services';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';
import { forkJoin } from 'rxjs';
import { ServicesettingService } from '../../service/servicesetting.service';

@Component({
  selector: 'app-load-limit-setting',
  templateUrl: './load-limit-setting.component.html',
  styleUrls: ['./load-limit-setting.component.scss']
})
export class LoadLimitSettingComponent implements OnInit {

  user
  dataCompanylocation=[];
  filterClientLocation;
  constructor(private serviceSetting: ServicesettingService,
    private authService: AuthenticationService,
    private customerservice: CustomerserviceService,) {
   
     }
  times = [];
  loadLimits=[]

  ngOnInit(): void {

    this.generateTimes(60);
    this.authService.currentUser.subscribe(a=>{
      this.user = a;
      this.getLoadlimits();
      console.log("getLoadlimits user",this.user)
      this.GetCompanyLocationList();
    })
  
  }

  LocChange(){
    this.getLoadlimits(this.filterClientLocation);
  }


  async getLoadlimits(clientLocId=0){
    var locid = clientLocId;
    var shipmentsPlan = await this.serviceSetting.getLoadLimit(this.user.ClientID,clientLocId).then(
      async (res) => {
        var loadlimitresult = await res.json();
        //console.log("getLoadlimits" ,loadlimitresult);
        this.loadLimits = loadlimitresult;

        this.times.forEach(t=>{
          var result = this.loadLimits.filter(a=>a.slot == t.hours);
          //console.log("getLoadlimits" ,result);
          if(result.length == 0){
            let loadlimit ={
              slot:t.hours,
              size:0,
              location_id:this.filterClientLocation,
              client_id:this.user.ClientID,
              time:t.time
            }
            this.loadLimits.push(loadlimit)
          }else{
            result[0].time = t.time;
          }
        });

        this.loadLimits.sort((n1,n2) => n1.slot - n2.slot);
        // this.loadLimits.forEach(item => {
        //  var  minutes = 0;
        //  if(this.times.length > 0){
         
        //   if(result.length == 0){
        //     let loadlimit ={
        //       slot 
        //     }
        //   }
        //  }
        //   item.time =  `${item.slot.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        // });
      }
    );
  }

  async GetCompanyLocationList() {
    const services = [
      this.customerservice.GetCompanyLocationList(this.user.ClientID),
      this.customerservice.GetLocationList(this.user.ClientID)
    ];
  var temp = []
    forkJoin([...services]).subscribe((result)=>{
      var r1 = result[0];
      var r2 = result[1];


      r1.map(item=>{
              var loc = {
                id:item.id,
                name: item.locationNumber+' '+item.locationShortAddress
              }
             temp.push(loc);
            })
    
            r2.map(item=>{
              var loc = {
                id:item.id,
                name: item.locationNumber+' '+item.locationShortAddress
              }
             temp.push(loc);
            })
    

       console.log("temp",temp)
            this.dataCompanylocation = temp;
    })
   
    //  this.customerservice
    //   .GetCompanyLocationList(this.user.ClientID)
    //   .subscribe((data) => {

    //     data.map(item=>{
    //       var loc = {
    //         id:item.id,
    //         name: item.locationNumber+' '+item.locationShortAddress
    //       }
    //      temp.push(loc);
    //     })
    
    //     //this.reInitcomDatatable();
    //     console.log("GetCompanyLocationList",this.dataCompanylocation)

      
    //   });

    //   this.customerservice.GetLocationList(this.user.ClientID).subscribe((res)=>{
    //     res.map(item=>{
    //       var loc = {
    //         id:item.id,
    //         name: item.locationNumber+' '+item.locationShortAddress
    //       }
    //       this.dataCompanylocation.push(loc);
    //     })
    //   })
    //   this.dataCompanylocation = temp;
    //   console.log("GetCompanyLocationList",this.dataCompanylocation)
  }
  onLimitChange(load, event) {
    console.log("value",event.target.value);
   load.size = Number(event.target.value);
    console.log("load",load);
   var req = [];
   req.push(load)
   this.serviceSetting.saveLoadlimit(req).subscribe(data =>{
    console.log("data",data);

    this.getLoadlimits(this.filterClientLocation);
   })
  }

  generateTimes(timeFrame: number) {
    this.times = [];
    for (let i = 0; i < 1440; i += timeFrame) {
      let hours = Math.floor(i / 60);
      let minutes = i % 60;
      let time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      let item = {
        hours: hours,
        time: time
      }
      this.times.push(item);
    }
  }

}
