<ng-template #modalchangeemail let-modal>
    <form [formGroup]="changeEmailForm" (submit)="btnsubmit_click()">
        <div class="modal-header bg-warning text-center text-white">
            <div class="col-12 p-0">
                <span class="fal fa-question-circle fa-9x"></span>
                <button type="button" class="close pt-0 pl-0 pb-0 pr-1" style="color:white !important" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal-body pb-0">
            <div *ngIf="body_title" class="row mb-2">
                <div class="col-12 text-center">
                    <h3 class="font-weight-bold mb-0" style="white-space: pre-wrap">
                        {{body_title}}
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <span class="h5">อีเมลเดิม</span>
                    <input type="text" class="form-control" formControlName="oldemail" placeholder="ยังไม่ได้ระบุ" >
                </div>

                <div class="form-group col-12">
                    <span class="h5">อีเมลใหม่</span>
                    <input type="email" class="form-control" formControlName="email" placeholder="กรอกอีเมล์" (keyup)="checkUsedEmail($event)"  required>
                    <!-- <div *ngIf="isUsedEmail === true">
                        <span class="text-success">อีเมล์นี้สามารถใช้งานได้</span>
                    </div> -->
                    <div *ngIf="isUsedEmail === false">
                        <span class="text-danger">{{emailMessageError}}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer justify-content-between">
            <button id="btnother" type="button" class="btn btn-outline-secondary waves-effect waves-themed font-weight-bold"
                style="width:37% !important" (click)="modal.dismiss('Cross click')">ยกเลิก</button>
            <button id="btnsubmit" type="button" class="btn btn-warning waves-effect waves-themed font-weight-bold"
                style="width:58% !important" type="submit" [disabled]="!this.changeEmailForm?.valid">ยืนยันเปลี่ยนอีเมลใหม่</button>
        </div>
    </form>

</ng-template>

<app-modalsuccess #modalsuccess (btnother_event)="success_other($event)" [btnsubmit_ishide]=true></app-modalsuccess>
