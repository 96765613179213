export class  AddClientUserModel {
  firstName:string;
  lastName:string;
  photoURL:string;
  idCard:string;
  taxId:string;
  birthDate:string;
  clientID:number;
  tsT360_CompanyID:number;
  tsT360_UserID:number;
  username:string;
  phoneNumber:string;
  email:string;
  teamIds:number[] = [];
  roleId:number;
  departmentId : number;
  teamUser: Array<teamUserDto> = [];
  roleUser:userRoleDto = new userRoleDto();
  userIDCard:userIDCardDto[] = []
  userDriverLicence:userDriverLicenceDto[] = []
  password:string;
  tsT360_DefaultUserServiceType:number;
  tstUserId:string;
  isActive:boolean;
  emailLink:string;
  authorizeID:number;
  recievePasswordChanel:number = 1;
}

export class userRoleDto {
  id:number;
  valid:boolean;
  userID:number;
  roleID:number;
  roleName: string;
  isActive:boolean;
  authorizeLevelID:number;
}

export class teamUserDto {
  id:number;
  valid:boolean;
  userID:number;
  teamID:number;
}

export class userIDCardDto {
  id:number;
  user_ID:number;
  fileURL:string;
}

export class userDriverLicenceDto {
  id:number;
  user_ID:number;
  fileURL:string;
}