import { Injectable } from '@angular/core';
import { TailApiPath } from '@app/core/_Constants/apiConstants';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { HttpService } from '@app/core/_Services';
import { ShipmentTailModel } from '@app/modules/booking/models/2022/shipment-model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyTailDto, TailTypeDto } from '../models/company-tail-model';

@Injectable({
  providedIn: 'root'
})
export class CompanyTailService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  companyTailListOnChange: BehaviorSubject<CompanyTailDto[]>;
  tailTypeListOnChange: BehaviorSubject<TailTypeDto[]>;
  tailTypeItemOnChange: BehaviorSubject<TailTypeDto>;
  shipmentTailListOnChange: BehaviorSubject<ShipmentTailModel[]>;
  constructor(private apiService: HttpService) {
    this.companyTailListOnChange = new BehaviorSubject(new Array<CompanyTailDto>());
    this.tailTypeListOnChange = new BehaviorSubject(new Array<TailTypeDto>());
    this.tailTypeItemOnChange = new BehaviorSubject(null);
    this.shipmentTailListOnChange = new BehaviorSubject(new Array<ShipmentTailModel>());
  }

  getCompanyTailList(dataReq: any): Promise<CompanyTailDto[]> {
    return new Promise((resolve, reject) => {
      this.apiService.post(TailApiPath.GetCompanyTruckList, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // console.log('getCompanyTruckLis', res);
          this.companyTailListOnChange.next(res.data);
          resolve(res.data);
        });
    })
  }

  getTailTypeByTruckTypeId(truckTypeId: number): Promise<TailTypeDto[]> {
    return new Promise((resolve, reject) => {
      this.apiService.get(`${TailApiPath.GetTailTypeList}?truckTypeId=${truckTypeId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // console.log('getCompanyTruckLis', res);
          this.tailTypeItemOnChange.next(res.data);
          resolve(res.data);
        });
    })
  }

  getTailTypeList(): Promise<TailTypeDto[]> {
    return new Promise((resolve, reject) => {
      this.apiService.get(TailApiPath.GetTailTypeList)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // console.log('getCompanyTruckLis', res);
          this.tailTypeListOnChange.next(res.data);
          resolve(res.data);
        });
    })
  }

  getCompanyTailWithShipment(dataReq: any): Promise<ShipmentTailModel[]> {
    return new Promise((resolve, reject) => {
      this.apiService.post(TailApiPath.GetCompanyTailWithShipment, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // console.log('getCompanyTailWithShipment', res);
          this.shipmentTailListOnChange.next(res.data);
          resolve(res.data);
        });
    })
  }

  saveAndUpdateCompanyTail(dataReq: any): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService.post(TailApiPath.CompanyTail, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          console.log('saveAndUpdateCompanyTail', res);
          resolve(res);
        });
    })
  }
  deleteCompanyTail(dataReq: any): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService.patch(TailApiPath.DeleteCompanyTail, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          resolve(res);
        });
    })
  }
}
