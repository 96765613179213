<div class="row" *ngIf="permission90100.canRead">
    <div class="col-12">
        <h5><i class="fas fa-cogs"></i> ตั้งค่าข้อมูลสำหรับการสร้างบุ๊คกิ้ง</h5>
    </div>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        1. ประเภทรถ
                    </div>
                    <div class="col-md-2" *ngFor="let type of trucktypeDataSetting?.data">
                        <div class="row mt-1 pl-2">
                            <div class="col-12">
                                {{type.name}}
                            </div>
                            <div class="col-12" *ngFor="let truck of type.truckTypeList">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="truck{{truck.id}}"
                                        [(ngModel)]="truck.isSelect">
                                    <label class="custom-control-label" for="truck{{truck.id}}">{{truck.name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12 mb-2">
                        2. เลข Reference ที่เปิดใช้งาน (สูงสุด 3 ประเภท)
                    </div>
                    <div class="col-12 pl-3">
                        <div class="row mt-1">
                            <div class="col-md-2 col-12">
                                2.1 Reference ที่ 1
                            </div>
                            <div class="col-md-auto col-8 ">
                                <input type="text" class="form-control" [(ngModel)]="SettingRef?.data.referenceNO1"
                                    [readonly]="SettingRef?.data.isRef1 == null || SettingRef?.data.isRef1 == false">
                            </div>
                            <div class="col-md-auto col-4 mt-2">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="Ref1"
                                        [(ngModel)]="SettingRef?.data.isRef1" [checked]="SettingRef?.data.isRef1">
                                    <label class="custom-control-label" for="Ref1">ใช้งาน</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pl-3">
                        <div class="row  mt-1">
                            <div class="col-md-2 col-12">
                                2.2 Reference ที่ 2
                            </div>
                            <div class="col-md-auto col-8 ">
                                <input type="text" class="form-control" [(ngModel)]="SettingRef?.data.referenceNO2"
                                    [readonly]="SettingRef?.data.isRef2 == null || SettingRef?.data.isRef2 == false">
                            </div>
                            <div class="col-md-auto col-4 mt-2">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="Ref2"
                                        [(ngModel)]="SettingRef?.data.isRef2" [checked]="SettingRef?.data.isRef2">
                                    <label class="custom-control-label" for="Ref2">ใช้งาน</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pl-3">
                        <div class="row  mt-1">
                            <div class="col-md-2 col-12">
                                2.3 Reference ที่ 3
                            </div>
                            <div class="col-md-auto col-8 ">
                                <input type="text" class="form-control" [(ngModel)]="SettingRef?.data.referenceNO3"
                                    [readonly]="SettingRef?.data.isRef3 == null || SettingRef?.data.isRef3 == false">
                            </div>
                            <div class="col-md-auto col-4 mt-2">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="Ref3"
                                        [(ngModel)]="SettingRef?.data.isRef3" value="FALSE">
                                    <label class="custom-control-label" for="Ref3">ใช้งาน</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form [formGroup]="bookingSetting">
                <div class="row">
                    <div class="col-12 mb-2">
                        3. การแก้ไข Booking
                    </div>
                    <div class="col-auto pl-5">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="sameTeamEdit"  formControlName="requireSameTeamToEdit">
                            <label class="custom-control-label" for="sameTeamEdit">ผู้ใช้งานต้องอยู่ในทีมเดียวกันกับ Booking จึงจะแก้ไขได้</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row pl-3">
                            <div class="col-auto mt-2">
                                <span>ทีมมีสิทธิพิเศษสามารถแก้ไขได้ทุก Booking</span>
                            </div>
                            <div class="col-auto">
                                <ng-select formControlName="globalEditPermisTeamId" style="width: 200px;" [multiple]="true">
                                    <ng-option *ngFor="let item of teamList" [value]="item.teamId">{{item.teamName}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-2">
                        4. ค่าเริ่มต้น
                    </div>
                    <div class="col-12">
                        <div class="row pl-3 mb-2">
                            <div class="col-auto mt-2">
                                <span>ตั้งค่าเริ่มต้นสำหรับผู้ดูแล</span>
                            </div>
                            <div class="col-auto">
                                <ng-select formControlName="defaultOwnerId" style="width: 300px;">
                                    <ng-option *ngFor="let item of UserList" [value]="item.tst360UserId">{{item.fullname}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                      
                    </div>
                     <div class="col-12 pl-5">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="isAutoInput2ndLocFromCustomer"  formControlName="isAutoInput2ndLocFromCustomer">
                            <label class="custom-control-label" for="isAutoInput2ndLocFromCustomer">ให้ระบบเลือกจุดที่ 2 จากลูกค้าที่เลือกโดยอัตโนมัติ</label>
                        </div>
                    </div>
                    <div class="col-12 pl-5">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="isAutoRedirectAfterCreateBooking"  formControlName="isAutoRedirectAfterCreateBooking">
                            <label class="custom-control-label" for="isAutoRedirectAfterCreateBooking">ให้ระบบพาไปหน้ารายการ หลังจากสร้าง Booking สำเร็จ</label>
                        </div>
                    </div>
                </div>
                </form>
                <hr *ngIf="IsShow">
                <div *ngIf="IsShow" class="row">
                    <div class="col-12 mb-2">
                        4. ประเภทการขึ้นลงสินค้า
                    </div>
                    <div class="col-auto" *ngFor="let load of loadingType?.data">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="load{{load.id}}"
                                [(ngModel)]="load.isCheck">
                            <label class="custom-control-label" for="load{{load.id}}">{{load.loadingTypeName}}</label>
                        </div>
                    </div>
                </div>
                <hr *ngIf="IsShow">
                <div *ngIf="IsShow" class="row">
                    <div class="col-12 mb-2">
                        5. ประเภทการคิดราคาค่าขนส่ง
                    </div>
                    <!-- <div class="col-md-auto col-6" *ngFor="let cal of CalculationType">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="cal{{cal.id}}" (change)="CalChange(cal)">
                                    <label class="custom-control-label" for="cal{{cal.id}}">{{cal.name}}</label>
                                </div>

                    </div> -->
                    <div class="col-4 col-md-auto mt-2  mt-md-0 text-right"> เลือกค่าเริ่มต้น
                    </div>
                    <div class="col-8 col-md-3 mt-1  mt-md-0">
                        <ng-select [(ngModel)]="unitPriceSetting?.data.shippingPriceUnitId">
                            <ng-option *ngFor="let item of priceunit?.data" [value]="item.id">{{item.priceUnitName}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <hr *ngIf="IsShow">
                <div *ngIf="IsShow" class="row">
                    <div class="col-12 mb-2">
                       6. ประเภทการคิดราคาแรงงาน
                    </div>
                    <!-- <div class="col-md-auto col-6" *ngFor="let labor of LaborType">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="labor{{labor.id}}" (change)="LaborChange(labor)">
                                    <label class="custom-control-label" for="labor{{labor.id}}">{{labor.name}}</label>
                                </div>

                    </div> -->
                    <div class="col-4 col-md-auto mt-2 mt-md-0 text-right"> เลือกค่าเริ่มต้น </div>
                    <div class="col-8 col-md-3 mt-1  mt-md-0">
                        <ng-select [(ngModel)]="unitPriceSetting?.data.laborPriceUnitId">
                            <ng-option *ngFor="let item of priceunit?.data" [value]="item.id">{{item.priceUnitName}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row justify-content-center mt-3">
                    <div class="col-auto">
                        <button *ngIf="permission90100.canEdit" class="btn btn-success" (click)="Onsave()"
                            [disabled]="OnSaveing"><span *ngIf="OnSaveing" class="spinner-grow spinner-grow-sm"></span>
                            บันทึกการเปลี่ยนแปลง </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="row" *ngIf="!permission90100.canRead">
    <div class="col-12">
        <h5><i class="fas fa-cogs"></i> Access Denied</h5>
    </div>
</div>