import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { BoookingShipmentModel } from '../../model/bookingshipmentModel';

@Component({
  selector: 'app-booking-shipment-item',
  templateUrl: './booking-shipment-item.component.html',
  styleUrls: ['./booking-shipment-item.component.scss']
})
export class BookingShipmentItemComponent implements OnInit {

  @Input() data : BoookingShipmentModel;
  @Input() shipmentData;
  @Input() isExfac = false;
  @Input() onlyLoc = false;

  @Output() selected_event = new EventEmitter();
  startLoc ;
  endLoc ;
  Load;
  Drop;
  totalWeight = 0 ;
  rowActions;
  truckTypeName = '';
  timeForm
  timeTo

  isSelect = false;

  sumWeight = 0;
  constructor() { }

  ngOnInit(): void {
    console.log('booking data',this.data);
    console.log(this.shipmentData);
    this.rowActions = this.shipmentData.row[0].rowItem;
    this.findStartAndEndLoc()

    this.truckTypeName = this.data.trucks[0].truckTypeName;
    this.data.isExfac = this.isExfac;

    this.data.customer.forEach(cus => {
      cus.customerOrder.forEach(order => {
        order.items.forEach(item=>{
          console.log(item);
          this.sumWeight += item.weight;
        })
      });
    });
  }

  findStartAndEndLoc(){
    var start= this.data.locations[0];
    this.startLoc = start;
   
    var length = this.data.locations.length;
    var end = this.data.locations[length - 1];
    this.endLoc = end;


    this.Load = this.rowActions.filter(action => action.name === 'LOAD');
    this.Drop = this.rowActions.filter(action => action.name === 'DISCHARGE');
    
    const uniques = [...new Set(this.Drop.map(item => item.location_id))]; // [ 'A', 'B']
   

     var temp = [];
     for (const unique of uniques) {
      var maxOrder = Math.max(...this.Drop.map(item => item.orders.length));

      var loc = this.Drop.find(item => item.location_id === unique && item.orders.length == maxOrder);
      console.log(" locloc ",loc)
      var province = this.data.locations.filter(a=>a.locationID == loc.location_id);
      console.log(" province ",province)
      if(province.length > 0) {
        loc.province = province[0].province;
      }
      loc.startHH = 8;
      loc.endHH = 16;

      temp.push(loc); 

     }
     this.Drop = temp;
     console.log(" this.Drop", this.Drop)
  
    
  }
  findLocation(item, isLoad,withTime?) {  
    // console.log(item)
    if(item.startHH >= 24){
      item.startHH -= 24;
    }
    if(item.endHH >= 24){
      item.endHH -= 24;
    }
    const hourString1 = item.startHH.toString().padStart(2, "0") + ":00";
    const hourString2 = item.endHH.toString().padStart(2, "0") + ":00";
    const loc = isLoad ? item.orders[0].origin : item.orders[0].destination;
    var result = loc.name;


    this.timeForm = hourString1;
    this.timeTo = isLoad ? hourString1 : hourString2;

    
    return withTime ? result + " Time " + hourString1 + " To " + hourString2 : result;
  }

  findLocRemark(item){
    // console.log("findLocRemark",item);

    var loc = this.data.locations.filter(loc => loc.locationID === item.location_id);
    var remark = ''
    if(loc.length > 0){

      loc[0].remarks.forEach(r => {
        remark = remark + ' ' + r.remark;
      });
      return remark;
    }

  }

  findOrderWeight(order){
    var w = 0;
    order.items.forEach(items => {
      w += items.totalWeight; 
    });
    return w;
  }

  findLocItem(action){
   // console.log("findLocItem", action);
    var orders = [];
    var loc_id = action.location_id;
   // console.log(loc_id);
    action.orders.forEach(order => {
      if(order.destination_location_id == loc_id){
        orders.push(order);
      }
    });
    //console.log("orders",orders);
    return orders;
  }

  onselect(select: boolean){
    this.isSelect = select;
    console.log("onselect",this.data);
    this.selected_event.emit(this.data);
  }

  timeFromChange(loc,event){
    console.log("timeFromChange",loc)
    console.log("event",event.target.value);

   let locations = this.data.locations.filter(a=>a.locationID == loc.location_id );
   
   for (let item of locations){
    // var date2 = moment(item.date).format('YYYY-MM-DD');
    //   console.log(date2)
    var Date = item.date + 'T' + event.target.value+':00.000Z';
     console.log(Date);
    item.dateTimeFrom = Date;
    var index = this.data.locations.indexOf(item);
    console.log('index',index);
    if(index !== -1){
      this.data.locations[index] = item;
    }
   }
  //  
  
  console.log("this.data",this.data);

  }

  timeToChange(loc,event){
    console.log("timeToChange",loc)
    console.log("event",event.target.value);
    let locations = this.data.locations.filter(a=>a.locationID == loc.location_id );
   
    for (let item of locations){
     // var date2 = moment(item.date).format('YYYY-MM-DD');
     //   console.log(date2)
     var Date = item.date + 'T' + event.target.value+':00.000Z';
      console.log(Date);
     item.dateTimeTo = Date;
     var index = this.data.locations.indexOf(item);
     console.log('index',index);
     if(index !== -1){
       this.data.locations[index] = item;
     }
    }
    console.log("this.data",this.data);
  }


}
