import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService } from '@app/core/_Services';
import { AddFleetCompanyTruckDto, CompanyTruckDto } from '@app/modules/truck-license-management/models/company-truck-model';
import { AddFleetXTruckListRequest } from '@app/modules/truck-license-management/models/fleet-x-truck-model';
import { CompanyTruckService } from '@app/modules/truck-license-management/services/company-truck.service';
import { FleetXTruckService } from '@app/modules/truck-license-management/services/fleet-x-truck.service';
import { LocationData } from '@app/shared/Components/ModalGoogleMap/modalgooglemap/googlemap/Model/LocationDataModel';
import { clientFleetDto, CompanyTruckTypeDtoWithPhoto, TruckAddToFleetDto } from '../../models/TruckFleet';
import { TruckManagementService } from '../../service/truck-management.service';
declare function closeModal(modalID): any;

@Component({
  selector: 'app-modal-add-fleet',
  templateUrl: './modal-add-fleet.component.html',
  styleUrls: ['./modal-add-fleet.component.scss']
})
export class ModalAddFleetComponent implements OnInit {
  fleetForm: FormGroup;
  fleetItem: clientFleetDto;
  comTruckTypeList: CompanyTruckTypeDtoWithPhoto[];

  DataLocation: LocationData = new LocationData();
@Input() fleetDetails : clientFleetDto;
@Input() action : number = 0; //0 new fleet //1 edit fleet detail //2add trucks too fleet
@Input() fleetCount : number = 0; //
@Output() Refresh = new EventEmitter();
@Output() ModalFleetClose = new EventEmitter();
  constructor(
    private authen: AuthenticationService,
    private fb: FormBuilder,
    private truckManagementService: TruckManagementService,
    private comTruckService: CompanyTruckService,
    private fleetXTruckService : FleetXTruckService,
    private noti: NotificationService,
  ) {


  }
  companyTruckList: AddFleetCompanyTruckDto[];
  currentStep: number = 1;
  truckList = [];
  truckTypeList = [];
  trucks = [];



  colorList = [
    { colorCode: "#8206FD" },
    { colorCode: "#00D1FF" },
    { colorCode: "#0500FF" },
    { colorCode: "#D5DF32" },
    { colorCode: "#32FF98" },
    { colorCode: "#FFE600" },
    { colorCode: "#FF9900" },
    { colorCode: "#FF0000" },
  ]

  selectedTruckType = [];
  selectedCompanyTruckIds = [];



  user: User;
  ngOnInit(): void {
    this.authen.currentUser.subscribe((x) => {
      this.user = x;


      this.fleetForm = this.fb.group({
        Client_ID: [this.user.ClientID, Validators.required],
        Company360_ID: [this.user.tstS360_CompanyID, Validators.required],
        CompanyFleetNo: [0],
        FleetName: ['', Validators.required],
        FleetColor: [null,Validators.required],
        FullAddress: ['', Validators.required],
        Province_ID: [Validators.required],
        Latitude: [0, Validators.required],
        Longitude: [0, Validators.required]
      })

      this.getCompanyTruckTypeList();
    });


  }

  ngOnChanges() {
  console.log("onchange",this.fleetDetails);
  console.log("action",this.action)
if(this.action == 2){
  this.currentStep =2;
}else if (this.action == 1){
  this.currentStep =1;
}

    this.fleetForm?.patchValue({
      FleetName:this.fleetDetails?.fleetName,
      CompanyFleetNo:this.fleetDetails?.companyFleetNo,
      FullAddress: this.fleetDetails?.fullAddress,
      Province_ID: this.fleetDetails?.province_ID,
      Latitude: this.fleetDetails?.latitude,
      Longitude: this.fleetDetails?.longitude,
      FleetColor: this.fleetDetails?.fleetColor
    })

    this.DataLocation.lat = this.fleetDetails?.latitude;
    this.DataLocation.lng = this.fleetDetails?.longitude;
    this.DataLocation.shortAddress = this.fleetDetails?.fullAddress;
    this.DataLocation.fullAddress = this.fleetDetails?.fullAddress;
    this.DataLocation.provinceId = parseInt(this.fleetDetails?.province_ID.toString());
    this.DataLocation.placeId = "noplaceid";
    // console.log("DataLocation",this.DataLocation)
    // console.log("CompanyFleetNo",this.fleetForm.get('CompanyFleetNo').value)
  }

  colorSelected(colorCode) {
    this.fleetForm.patchValue({
      FleetColor: colorCode
    })
  }

  LocationChange(event: any): void {
    this.fleetForm.patchValue({
      FullAddress: event.fullAddress,
      Province_ID: event.provinceId,
      Latitude: event.lat,
      Longitude: event.lng,
    })
    console.log(this.fleetForm)

  }

  isFormValid(){

    return this.fleetForm.valid;
  }

  onSelectTruck(companyTruck: AddFleetCompanyTruckDto, event: CompanyTruckDto[]) {


    companyTruck.selectedTruckList = event;
    var companyTruckIds = [];

    companyTruck.selectedTruckList.forEach(element => {
      companyTruckIds.push(element.id);
      if (!this.selectedCompanyTruckIds.includes(element.id)) {
        this.selectedCompanyTruckIds.push(element.id)
      }

    });
    companyTruck.selectedCompanyTruckIds = companyTruckIds;

   var currentIndex =  this.companyTruckList.findIndex(item => item.companyTruckType_ID == companyTruck.companyTruckType_ID);

    //  console.log("currentIndex",currentIndex)

    this.companyTruckList[currentIndex].selectedCompanyTruckIds = companyTruckIds;

  //  console.log("companyTruckList",this.companyTruckList[currentIndex])

  }

  onRemove(companyTruck: AddFleetCompanyTruckDto, event: CompanyTruckDto) {
    companyTruck.selectedTruckList = companyTruck.selectedTruckList.filter(obj => obj !== event);
    companyTruck.selectedCompanyTruckIds = companyTruck.selectedCompanyTruckIds.filter(obj => obj !== event.id);;

    this.selectedCompanyTruckIds = this.selectedCompanyTruckIds.filter(obj => obj !== event.id);;
  }

  nextStep() {
    if (this.currentStep == 1) {
      console.log(this.fleetForm.value)
      if (this.fleetForm.valid) {
        this.truckManagementService.postClientFleet(this.fleetForm.value).subscribe((response) => {
          console.log("fleet response", response)
          this.fleetItem = response.data;
          this.fleetForm.patchValue({
            CompanyFleetNo: this.fleetItem.companyFleetNo
          })
          this.currentStep++;
        })
      } else {

      }
    } else if (this.currentStep == 2 && this.selectedTruckType.length > 0) {
      console.log("GettruckList")
      this.getCompanyTruckList()
      this.currentStep++;
    } else if (this.currentStep == 3) {
      this.onSaveTruckLicense();
    }

  }


  backStep() {
    this.currentStep--;
  }

  getCompanyTruckTypeList() {
    this.truckManagementService.GetCompanyTruckTypeList().subscribe((response) => {
      this.comTruckTypeList = response.data;

    })
  }

  getCompanyTruckList(): void {



    this.comTruckService.getCompanyTruckTypeListByCompanyTruckID(this.selectedTruckType).subscribe(res => {

      this.companyTruckList = res.data;
      console.log(this.companyTruckList)
    });



  }

  onTruckTypeSelected(truckType: CompanyTruckTypeDtoWithPhoto) {
    if (this.selectedTruckType.includes(truckType)) {
      this.selectedTruckType = this.selectedTruckType.filter(obj => obj !== truckType);
    } else {
      this.selectedTruckType.push(truckType);
    }

    console.log(this.selectedTruckType)
  }

  isSelected(truckType) {

    return this.selectedTruckType.includes(truckType);
  }

  onSaveTruckLicense() {
    this.selectedCompanyTruckIds = [];
    this.companyTruckList.forEach((comtruck) => {
    if(comtruck.selectedCompanyTruckIds?.length >0){
      comtruck.selectedCompanyTruckIds.forEach((id) => {
        this.selectedCompanyTruckIds.push(id)
      })
    }
    })
    // console.log(this.comTruckTypeList);

    const data = {
      updateBy: this.user.tstS360_UserID,
      companyId: this.user.tstS360_CompanyID,
      companyTruckId: this.selectedCompanyTruckIds,
      fleetNo: this.fleetForm.get('CompanyFleetNo').value,
    } as AddFleetXTruckListRequest;

   this.fleetXTruckService.AddFleetXTruckList(data).subscribe((response)=>{
     console.log(response);
     if(response.isSuccess){
      closeModal("#addFleetModal");
      this.noti.toastsuccess('สำเร็จ', 'เพิ่มรถเข้า FLEET สำเร็จ');
      this.Refresh.emit();
      this.currentStep = 1
      this.fleetForm = this.fb.group({
        Client_ID: [this.user.ClientID, Validators.required],
        Company360_ID: [this.user.tstS360_CompanyID, Validators.required],
        CompanyFleetNo: [0],
        FleetName: ['', Validators.required],
        FleetColor: [null,Validators.required],
        FullAddress: ['', Validators.required],
        Province_ID: [Validators.required],
        Latitude: [0, Validators.required],
        Longitude: [0, Validators.required]
      })
      this.selectedTruckType = [];
      this.selectedCompanyTruckIds = [];
      this.DataLocation = new LocationData();
     }else{
      this.noti.toasterror('เพิ่มรถเข้า FLEET ล้มเหลว', response.message);
     }
   })
  }

  saveFleetDetail(){
    if (this.currentStep == 1) {
      console.log(this.fleetForm.value)
      if (this.fleetForm.valid) {
        this.truckManagementService.postClientFleet(this.fleetForm.value).subscribe((response) => {
          console.log("fleet response", response)
          this.fleetItem = response.data;
          this.fleetForm.patchValue({
            CompanyFleetNo: this.fleetItem.companyFleetNo
          })
          this.Refresh.emit();
          this.noti.toastsuccess('สำเร็จ', 'แก้ไข FLEET สำเร็จ');
          closeModal("#addFleetModal");

        })
      }
    }
  }
  closeFleetModal(){
    this.fleetForm = this.fb.group({
      Client_ID: [this.user.ClientID, Validators.required],
      Company360_ID: [this.user.tstS360_CompanyID, Validators.required],
      CompanyFleetNo: [0],
      FleetName: ['', Validators.required],
      FleetColor: [null,Validators.required],
      FullAddress: ['', Validators.required],
      Province_ID: [Validators.required],
      Latitude: [0, Validators.required],
      Longitude: [0, Validators.required]
    })

    this.DataLocation = new LocationData();
    this.ModalFleetClose.emit();
    closeModal("#addFleetModal");


  }
}



