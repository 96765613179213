<!-- Modal -->

<div class="modal fade" id="addFleetModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" ><i class="fas fa-home"></i>
                    <span *ngIf="action===0"> เพิ่มใหม่ FLEET {{fleetCount+1}} </span>
                    <span *ngIf="action===1"> แก้ไข FLEET </span>
                    <span *ngIf="action===2"> เพิ่มรถเข้า FLEET </span>
                    </h5>
                <button type="button" class="close" (click)="closeFleetModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-1" [ngClass]="{'min-height-350':currentStep===3,'min-height-550':currentStep===1}">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="row justify-content-center">
                                <div class="col-md-10">
                                    <div class="stepper-wrapper">
                                        <div class="stepper-item " [ngClass]="currentStep===1?'active':'completed'">
                                            <div class="step-counter">1</div>
                                            <div class="step-name">ตั้งชื่อ</div>
                                        </div>
                                        <!-- <div class="stepper-item " [ngClass]="{'active':currentStep===2,'completed':currentStep>2}">
                                            <div class="step-counter">2</div>
                                            <div class="step-name">เลือกประเภทรถ</div>
                                        </div> -->
                                        <div class="stepper-item " [ngClass]="currentStep<=2?'active':''">
                                            <div class="step-counter">2</div>
                                            <div class="step-name">เพิ่มรถเข้า FLEET</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentStep===1">

                        <hr>
                        <form  [formGroup]="fleetForm">

                      
                        <div class="row mb-1">
                            <div class="col-12">
                                <label for="" class="form-label">ชื่อ FLEET <span class="txt-red">*</span></label>
                                <input class="form-control" type="text" formControlName="FleetName"/>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-12">
                                <app-googlemap MapModal="LocationFleet" FavoriteShow="true" IsMaster="true" [Data]="DataLocation"
                                    InputText="ปักหมุดสถานที่ FLEET" (LocData)="LocationChange($event)"></app-googlemap>
               
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-12">
                                <label for="" class="form-label">สีประจำ FLEET <span class="txt-red">*</span></label>
                                <br>
                                <div >
                                    <label class="fleet-color" *ngFor="let item of colorList;let i = index"
                                    [ngClass]="{'selected-color': fleetForm.get('FleetColor').value == item?.colorCode}"
                                    [ngStyle]="{'background-color': item?.colorCode}"  (click)="colorSelected(item?.colorCode)"></label>

                                </div>
                              


                            </div>
                        </div>
                    </form>
                    </div>
                    <div *ngIf="currentStep===2">
                        <div class="row">
                            <div class="col-12">
                                <label for="" class="form-label">ประเภทรถบรรทุกที่ต้องการเลือกเข้า FLEET <span
                                        class="txt-red">*</span></label>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-4 mb-1" *ngFor="let truckType of comTruckTypeList ;let i = index">
                                        <div class="card truck-card selected" [ngClass]="{'selected':isSelected(truckType)}" (click)="onTruckTypeSelected(truckType)">
                                            <div class="card-body text-center" >
                                                    <div class="truckCheck" *ngIf="isSelected(truckType)">
                                                        <i class="fas fa-check-square"></i>
                                                    </div>
                                                    <div class="container text-center" >
                                                        <img class="vertical-center" src="assets/img/360techx/images/truckicon/{{truckType.photo}}"/> 
                                                    </div>
                                                  <br>
                                                    {{truckType.name}} 

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentStep===3">
                        <div class="row" *ngFor="let companyTruck of companyTruckList;let i = index">
                            <div class="col-12">
                                <div class="row mt-1 mb-2">
                                    <div class="col-md-auto col-6 ">
                                        <img class="img-truck"
                                            src="assets/img/360techx/images/truckicon/{{companyTruck.photo}}"
                                            alt="Card image cap">

                                    </div>
                                    <div class="col pt-3">
                                        <h4>{{companyTruck.truckType_Name}}</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ng-select placeholder="เลือกทะเบียน" [multiple]="true" [items]="companyTruck.truckList"
                                            bindLabel="fullName" bindValue="id" (change)="onSelectTruck(companyTruck,$event)" 
                                            [(ngModel)]="companyTruck.selectedCompanyTruckIds">

                                            <ng-template ng-label-tmp let-item="item">
                                                <span>(หัว){{item.headLicenseId}}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                                <div class="row" >
                                                    <div class="col" >
                                                        <span>(หัว){{item.headLicenseId}}     </span>
                                                        <span *ngIf="item.tailLicenseId"> (หาง){{item.tailLicenseId}} </span>
                                                    </div>
                                                    <div class="col-auto">
                                                        <span *ngIf="item.companyFleet_No"> F{{item.companyFleet_No}} </span>
                                                        <span *ngIf="!item.companyFleet_No"> - </span>
                                                    </div>

                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="row justify-content-between mt-1  border-bottom"
                                    *ngFor="let item of companyTruck.selectedTruckList">
                                    <div class="col-md-auto col pt-2">

                                        <span>(หัว){{item.headLicenseId}}     </span>
                                        <span *ngIf="item.tailLicenseId"> (หาง){{item.tailLicenseId}} </span>

                                    </div>
                                    <div class="col-auto">
                                         <span *ngIf="item.companyFleet_No">  F{{item.companyFleet_No}} → F{{fleetForm.get('CompanyFleetNo').value}} </span>
                                         <span *ngIf="!item.companyFleet_No"> - → F{{fleetForm.get('CompanyFleetNo').value}} </span>
                                    </div>
                                    <div class="col-auto">
                                        <button type="button" class="btn btn-danger" (click)="onRemove(companyTruck,item)"> <i
                                                class="fas fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>

        

                    </div>
                </div>
            </div>
            <div *ngIf="action===0">

            <div class="p-2" *ngIf="currentStep < 3">
                <div class="row pb-2 mt-2">
                    <div class="col-md-6">
                        <button class="btn btn-outline-secondary btn-block" *ngIf="currentStep ===1"
                        (click)="closeFleetModal()">
                            ปิดหน้านี้
                        </button>
                        <button class="btn btn-outline-secondary btn-block" *ngIf="currentStep ===2"
                            (click)="backStep()">
                            ย้อนกลับ
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-success btn-block" (click)="nextStep()" [disabled]="!isFormValid()">ไปต่อ</button>
                    </div>
                </div>
            </div>
            <div class="p-2" *ngIf="currentStep === 3">
                <div class="row pb-2 mt-2">
                    <div class="col-md-6">
                        <button class="btn btn-outline-secondary btn-block" (click)="backStep()">ย้อนกลับ</button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-success btn-block" (click)="nextStep()">บันทึก</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="action===1">
            <div class="row p-2 mt-2">
                <div class="col-md-6">
                    <button class="btn btn-outline-secondary btn-block" (click)="closeFleetModal()">ปิด</button>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-success btn-block" (click)="saveFleetDetail()">บันทึก</button>
                </div>
            </div>
        </div>
        <div *ngIf="action===2">
            <div class="p-2" *ngIf="currentStep === 2">
                <div class="row pb-2 mt-2">
                    <div class="col-md-6">
                        <button class="btn btn-outline-secondary btn-block"  (click)="closeFleetModal()">ปิด</button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-success btn-block" (click)="nextStep()">ไปต่อ</button>
                    </div>
                </div>
            </div>
            <div class="p-2" *ngIf="currentStep === 3">
                <div class="row pb-2 mt-2">
                    <div class="col-md-6">
                        <button class="btn btn-outline-secondary btn-block" (click)="backStep()">ย้อนกลับ</button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-success btn-block" (click)="nextStep()">บันทึก</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>