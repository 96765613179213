import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageloadingComponent } from './core/_Template/pageloading/pageloading.component';
import { JwtInterceptor } from './core/_Authentication/jwt.interceptor';
import { ErrorInterceptor } from './core/_Authentication/error.interceptor';
import { AuthenticationService } from './core/_Services';
import { TempleteModule } from './core/_Template/templete.module';
import { UsermanageModule } from './modules/usermanage/usermanage.module';
import { AccountingModule } from './modules/accounting/accounting.module';
import { LayoutSettingComponent } from './core/_Template/layout-setting/layout-setting.component';
import { HomeComponent } from './shared/Components/home/home.component';
import { NavbarComponent } from './core/_Template/navbar/navbar.component';
import { SlidemenuComponent } from './core/_Template/slidemenu/slidemenu.component';
import { LoginComponent } from './core/_Authentication/login/login.component';
import { SettingModule } from './modules/setting/setting.module';
// import { ThaiDatePipe } from './shared/Pipes/thaidate.pipe';
import { NotificationModule } from './modules/notification/notification.module';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './core/_Services/messaging.service';
import { RouteDiagramModule } from './modules/route-diagram/route-diagram.module';
import { ForgetPasswordComponent } from './core/_Authentication/forget-password/forget-password.component';
import { ResetPasswordComponent } from './core/_Authentication/reset-password/reset-password.component';
import { ToastNoAnimationModule, ToastrModule } from "ngx-toastr";
// import {ThaiDatePipe} from "@shared/Pipes/thaidate.pipe";
// import {FileNamePipe} from "@shared/Pipes/AppPipe";
import { CookieModule } from "ngx-cookie";
import { LogoutComponent } from './core/_Authentication/logout/logout.component';
import { ShipmetnAssignCalendarComponent } from './modules/shipmentAssignCalendar/shipmetn-assign-calendar/shipmetn-assign-calendar.component';
import { ShipmentAssignCalendarModuleModule } from './modules/shipmentAssignCalendar/shipment-assign-calendar-module/shipment-assign-calendar-module.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafeHtmlPipe } from './shared/Pipes/safeHtml.pipe';
import { SchedulerModule } from './modules/scheduler/scheduler.module';
import { LoaderInterceptor } from './core/_Template/loader.Interceptor';
import { AppLayoutModule } from './core/_Template/app.layout.module';
import { NotfoundComponent } from './core/_Template/ErrorPages/notfound/notfound.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { ElasticService } from './core/_Services/_logs/elastic.service';
import {FacebookModule} from 'ngx-facebook';

@NgModule({
  declarations: [
    AppComponent,
    PageloadingComponent,
    // LayoutSettingComponent,
    HomeComponent,
    // NavbarComponent,
    // SlidemenuComponent,
    // HomeComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent,
    NotfoundComponent,
    SafeHtmlPipe
  ],
  imports: [
    AppLayoutModule,
    SchedulerModule,
    ShipmentAssignCalendarModuleModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    // TempleteModule,
    UsermanageModule,
    AccountingModule,
    //MaterialsModule,
    HttpClientModule,
    SettingModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    NotificationModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    RouteDiagramModule,
    ToastNoAnimationModule.forRoot(),
    CookieModule.forRoot(),
    BrowserAnimationsModule,
    NgbCollapseModule,
    ApmModule,
    FacebookModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    AuthenticationService,
    MessagingService,
    ApmService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private elastic: ElasticService) {

    if (window.location.hostname !== "localhost") {
      const apm = this.elastic.init();
    }
    //   defineLordIconElement(lottie.loadAnimation);
  }
}













