<ng-template #modalImportFile let-modal>
  <div class="modal-header" style="border-bottom: 1px solid #ccc;">
    <div class="modal-title w-100">
      <button type="button" class="close pt-1 pl-0 pb-0 pr-1" aria-label="Close" data-dismiss="modal"
        (click)="close(false)">
        <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
      </button>
      <div>
        <span class="h4"> <i class="fas text-success" [ngClass]="dataModal?.iconHeader"></i> {{dataModal?.title}}</span>
      </div>
    </div>
  </div>
  <div class="modal-body p-3">
    <div class="row" *ngIf="showExample">
      <div class="col-12">
          <span class="h5">คุณสามารถดาวน์โหลดไฟล์ต้นแบบไฟล์ Excel ได้ที่นี่</span> &nbsp;
          <a [href]="dataModal?.fileTemplatePath" [download]="fileTemplateName" class="btn btn-sm btn-success waves-effect waves-themed">ดาวน์โหลดไฟล์ต้นแบบ</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
          <span class="text-secondary h5">{{dataModal?.description}}</span>
      </div>

    </div>
    <div class="row" *ngIf="showFuelWallet">
      <div class="col-12">
        <span class="badge badge-pill secondary-100">วงเงินน้ำมันที่สามารถจัดการได้ <b>{{ fuelBalance | number: '1.2'}}</b> บาท</span> <br>
        <span class="text-danger fs-sm">หมายเหตุ : วงเงินนี้อาจมีการเปลี่ยนแปลงได้ หลังจากมีการปรับลด-เพิ่มวงเงินใหม่ และนำเข้าไฟล์สำเร็จ</span>
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-between">
          <label class="text-secondary h6">หากวงเงินน้ำมันในบัญชีของคุณมียอดไม่เพียงพอ สามารถเติมวงเงินได้ที่ <a class="text-info" target="_blank"
              href="{{webBase360}}/fuelwallet/topup">หน้าเติมเงินซื้อน้ำมัน</a></label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
          <span class="h5">{{textDefault}}</span>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-12 text-center mb-3" *ngIf="fileImport">
        <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="fileImport" [removable]="true" [extension]="dataModal?.acceptType"
          (removed)="ngxdropzonefile_remove(fileImport)"></ngx-custom-dropzone-image-preview>
        <ngx-dropzone-label>
          <span>{{fileImport?.name}} </span><br>
          ({{ (fileImport?.size / 1024 )/1024 | number}} MB)
        </ngx-dropzone-label>
      </div>
      <div class="col-12 text-center" *ngIf="!fileImport">
        <div id="ngxdropzonefile" class="custom-ngx-dropzone" ngx-dropzone
          [accept]="dataModal?.acceptType" [maxFileSize]="dataModal?.maxDataFileSize" [multiple]="false"
          (change)="ngxdropzonefile_change($event)">
          <ngx-dropzone-label>
            <i class="fal fa-4x" [ngClass]="dataModal?.iconUpload"></i><br>
            {{dataModal?.placeholder}}
            <br />
            <small>(ขนาดไฟล์สูงสุด {{(dataModal?.maxDataFileSize/ 1024) / 1024}} MB)</small>
          </ngx-dropzone-label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="fileImport">
      <div class="col-12">
        <span class="h5">กรุณากดปุ่ม "ประมวลผลข้อมูล" เพื่อตรวจสอบรายการข้อมูลก่อนที่จะนำเข้า</span>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="p-2">
    <div class="row m-1 text-center">
      <div class="col-6">
        <button type="button" class="btn btn-outline-success waves-effect waves-themed btn-block"
          (click)="close(false)">ปิดหน้านี้</button>
      </div>
      <div class="col-6">
        <button type="button" class="btn btn-success waves-effect waves-themed btn-block"
          (click)="close(true)" [disabled]="!this.fileImport">ประมวลผลข้อมูล</button>
      </div>
    </div>
  </div>
  <!-- Footer -->
</ng-template>

