import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationAPIPath, BankNameAPIPath, DasboardAPIPath, FleetXDriverAPIPath, UserApiPath, UserBookbankAPIPath, UserSalaryAPIPath, UserStatementAPIPath, UserTrainingAPIPath } from "@app/core/_Constants/apiConstants";
import { TMSAPIResponse } from "@app/core/_Models/TMSAPIResponse";
import { LoginDto } from "@app/core/_Models/UserModel/Login";
import { User } from "@app/core/_Models/UserModel/User";
import { AuthenticationService, HttpService } from "@app/core/_Services";
import { resolve } from "dns";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DriverDetailResponse, FleetDriverResponse, UserBookbankForm, UserChangeEmail, UserChangePassword, UserChangePhoneNumber, UserConfirmChangePhoneNumber, UserSalaryForm, UserTrainingForm, UserVerifyOTP } from "../models/driver-detail-response.model";
import { DriverPrintTopicModel } from "../models/driver-print-topic.model";
import { DriverList } from "../models/driver.model";

@Injectable({
  providedIn: 'root'
})

export class DriverDetailService {
  destroy$: Subject<boolean> = new Subject<boolean>();

  driverListOnChange: BehaviorSubject<DriverList[]>;
  driverAllOnChange: BehaviorSubject<DriverList[]>;

  currentUser: User
  selectedTopicForPrintOnChange: BehaviorSubject<DriverPrintTopicModel[]>
  selectDriverPrintListOnChange: BehaviorSubject<any[]>;

  constructor(
    private _apiService: HttpService,
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => {
        (this.currentUser = x)
        console.log(x);
      }
    );

    this.selectDriverPrintListOnChange = new BehaviorSubject(selectDriverPrintList);
    this.selectedTopicForPrintOnChange = new BehaviorSubject(new Array<DriverPrintTopicModel>());

    this.driverListOnChange = new BehaviorSubject(new Array<DriverList>());
    this.driverAllOnChange = new BehaviorSubject(new Array<DriverList>());
  }

  //#region FleetDriver
  getFleetDriverByUserId(userId: number): Observable<any> {
    return this._apiService.get(
      `${FleetXDriverAPIPath.FleetDriver}/driver/${userId}/${this.currentUser.tstS360_CompanyID}`
    );
  }
  //#endregion FleetDriver

  //#region UserTraining
  getUserTrainingByUserId(userId: number): Observable<any> {
    return this._apiService.get(
      `${UserTrainingAPIPath.UserTraining}/users/${userId}/${this.currentUser.tstS360_CompanyID}`
    );
  }

  addUserTraining(userTraining: UserTrainingForm): Observable<any> {
    const formData = new FormData();

    console.log('userTraining', userTraining);

    formData.append('UserTrainingForm.user_ID', `${userTraining.user_ID}`)
    formData.append('UserTrainingForm.date', `${userTraining.date}`)
    formData.append('UserTrainingForm.title', `${userTraining.title}`)
    formData.append('UserTrainingForm.detail', `${userTraining.detail}`)
    formData.append('UserTrainingForm.result', `${userTraining.result}`)
    formData.append('UserTrainingForm.createdBy', `${userTraining.createdBy}`)
    formData.append('UserTrainingForm.tst360_CompanyID', `${userTraining.tst360_CompanyID}`)

    userTraining.trainingDoc.map((e: File, index: number) => {
      formData.append(`UserTrainingForm.trainingDoc`, e)
    })

    console.log('formData', formData.get);

    return this._apiService.post(
      `${UserTrainingAPIPath.UserTraining}`, formData
    )
  }

  updateUserTraining(userTraining: UserTrainingForm): Observable<any> {

    const formData = new FormData();

    console.log('userTraining', userTraining);

    formData.append('UserTrainingForm.ID', `${userTraining.id}`)
    formData.append('UserTrainingForm.user_ID', `${userTraining.user_ID}`)
    formData.append('UserTrainingForm.date', `${userTraining.date}`)
    formData.append('UserTrainingForm.title', `${userTraining.title}`)
    formData.append('UserTrainingForm.detail', `${userTraining.detail}`)
    formData.append('UserTrainingForm.result', `${userTraining.result}`)
    formData.append('UserTrainingForm.createdBy', `${userTraining.createdBy}`)
    formData.append('UserTrainingForm.tst360_CompanyID', `${userTraining.tst360_CompanyID}`)

    userTraining.trainingDoc.map((e: File, index: number) => {
      formData.append(`UserTrainingForm.trainingDoc`, e)
    })

    console.log('formData', formData.get);

    return this._apiService.patch(
      `${UserTrainingAPIPath.UserTraining}/${userTraining.id}`, formData
    )
  }

  updateUserTrainingStatus(userTraining: UserTrainingForm, userTrainingStatus: number): Observable<any> {
    return this._apiService.patch(
      `${UserTrainingAPIPath.UserTraining}/${userTraining.id}/training-status/${userTrainingStatus}`, {}
    )
  }

  deleteUserTrainingFile(id: number) {
    return this._apiService.delete(`${UserTrainingAPIPath.UserTrainingFile}/${id}`);
  }

  //#endregion UserTraining

  //#region UserSalary
  getUserSalaryByUserId(userId: number): Observable<any> {
    return this._apiService.get(
      `${UserSalaryAPIPath.UserSalary}/users/${userId}/${this.currentUser.tstS360_CompanyID}`
    );
  }

  addUserSalary(userSalaryForm: UserSalaryForm): Observable<any> {
    return this._apiService.post(
      `${UserSalaryAPIPath.UserSalary}`, { UserSalaryForm: userSalaryForm }
    )
  }
  //#endregion UserSalary

  //#region UserStatement
  getUserStatementMonthsByUserId(userId: number): Observable<any> {
    return this._apiService.get(
      `${UserStatementAPIPath.UserStatement}/users/${userId}/${this.currentUser.tstS360_CompanyID}`
    );
  }

  getUserStatementByUserId(userId: number, year: number, month: number): Observable<any> {
    return this._apiService.get(
      `${UserStatementAPIPath.UserStatement}/users/${userId}/${this.currentUser.tstS360_CompanyID}/year/${year}/month/${month}`
    );
  }
  //#endregion UserStatement

  //#region UserBookbank
  getUserBookbankByUserId(userId: number): Observable<any> {
    return this._apiService.get(`${UserBookbankAPIPath.UserBookbank}/users/${userId}/${this.currentUser.tstS360_CompanyID}`)
  }

  createUserBookbank(userBookbank: UserBookbankForm): Observable<any> {
    const formData = new FormData()

    console.log('userBookbank', userBookbank);

    formData.append('UserBookbankForm.User_ID', `${userBookbank.user_ID}`)
    formData.append('UserBookbankForm.BankName_ID', `${userBookbank.bankName_ID}`)
    formData.append('UserBookbankForm.AccountNo', `${userBookbank.accountNo}`)
    formData.append('UserBookbankForm.AccountName', `${userBookbank.accountName}`)
    if(userBookbank.bankBranch)
      formData.append('UserBookbankForm.BankBranch', `${userBookbank.bankBranch}`)
    formData.append('UserBookbankForm.DefaultFlag', `${userBookbank.defaultFlag}`)
    formData.append('UserBookbankForm.tst360_CompanyID', `${userBookbank.tst360_CompanyID}`)

    userBookbank.bookbankDoc.map((e: File, index: number) => {
      formData.append(`UserBookbankForm.BookbankDoc`, e)
    })

    return this._apiService.post(`${UserBookbankAPIPath.UserBookbank}`, formData)
  }

  updateUserBookbank(userBookbank: UserBookbankForm): Observable<any> {
    const formData = new FormData()

    console.log('userBookbank', userBookbank);

    formData.append('UserBookbankForm.ID', `${userBookbank.id}`)
    formData.append('UserBookbankForm.User_ID', `${userBookbank.user_ID}`)
    formData.append('UserBookbankForm.BankName_ID', `${userBookbank.bankName_ID}`)
    formData.append('UserBookbankForm.AccountNo', `${userBookbank.accountNo}`)
    formData.append('UserBookbankForm.AccountName', `${userBookbank.accountName}`)
    if(userBookbank.bankBranch)
      formData.append('UserBookbankForm.BankBranch', `${userBookbank.bankBranch}`)
    formData.append('UserBookbankForm.DefaultFlag', `${userBookbank.defaultFlag}`)
    formData.append('UserBookbankForm.FilePath', `${userBookbank.filePath}`)
    formData.append('UserBookbankForm.tst360_CompanyID', `${userBookbank.tst360_CompanyID}`)

    userBookbank.bookbankDoc.map((e: File, index: number) => {
      formData.append(`UserBookbankForm.BookbankDoc`, e)
    })

    return this._apiService.patch(`${UserBookbankAPIPath.UserBookbank}/${userBookbank.id}`, formData)
  }
  //#endregion UserBookbank

  //#region BankName
  getBankName(): Observable<any> {
    return this._apiService.get(`${BankNameAPIPath.BankName}`)
  }
  //#endregion BankName

  //#region DriverFleet
  getDriverFleet(fleetNo: number, searchFilter?: any): Promise<DriverList[]> {
    var filters = {
      client_ID: this.currentUser.ClientID,
      fleet_No: fleetNo,
      searchFilter: {
        textSearch: searchFilter?.textSearch,
        driverLicenceStatusId: searchFilter?.driverLicenceStatusId,
        isEnabled: searchFilter?.isEnabled,
        shipmentStatusId: searchFilter?.shipmentStatusId,
      }
    }



    return new Promise((resolve, reject) => {
      this._apiService.post(FleetXDriverAPIPath.GetFleetDriver, { filter: filters })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          this.driverListOnChange.next(res.data)
          resolve(res.data)
          // console.log("getDriverFleet", res.data);

          if (fleetNo == 0) {
            this.driverAllOnChange.next(res.data)
            resolve(res.data)
          }
        });
    })
  }
  //#endregion DriverFleet

  // confirmChangePassword(userId:number,userchangePassword: UserChangePassword): Observable<any> {
  //   return this._apiService.post(
  //     `${environment.apiUrlV3}${UserApiPath.ResetPassword}`,
  //     { userId: userId,resetpasswordDto: userchangePassword }
  //   );
  // }

  getUserIdentityId(userId: number, trueIfNotFound: boolean = false): Observable<any> {
    return this.httpClient.get(`${environment.IdentityAPIUrl_V3}${UserApiPath.UserIdentityId}/${userId}?trueifnotfound=${trueIfNotFound}`);
  }

  changeEmailWithNoti(userChangeEmail: UserChangeEmail): any {
    return this.httpClient.post(
      `${environment.IdentityAPIUrl_V2}${UserApiPath.ChangeEmailWithNoti}`, userChangeEmail
    )
  }

  checkCanUseEmail(email: string): any {
    return this.httpClient.get(
      `${environment.IdentityAPIUrl_V3}${UserApiPath.CanUseThisEmail}?email=${email}&userpool=1`
    );
  }

  verifyOTP(userVerifyOTP: UserVerifyOTP): any {
    return this.httpClient.post(
      `${environment.IdentityAPIUrl_V3}${UserApiPath.VerifyOTP}`, userVerifyOTP
    )
  }

  checkCanUseMobileNo(mobileNo: string): any {
    return this.httpClient.get(
      `${environment.IdentityAPIUrl_V3}${UserApiPath.CanUseThisMobileNo}?phoneNumber=${mobileNo}&userpool=1`
    );
  }

  changePhoneNumber(userChangePhoneNumber: UserChangePhoneNumber): any {
    return this.httpClient.post(
      `${environment.IdentityAPIUrl_V2}${UserApiPath.ChangePhoneNumber}`, userChangePhoneNumber
    )
  }

  confirmChangePhoneNumber(userConfirmChangePhoneNumber: UserConfirmChangePhoneNumber): any {
    return this.httpClient.post(
      `${environment.IdentityAPIUrl_V2}${UserApiPath.ConfirmChangePhoneNumber}`, userConfirmChangePhoneNumber
    )
  }

  login(username: string, password: string, remember = false) {
    let identityUser = new LoginDto();
    identityUser.AppPackage = 3;
    identityUser.Pool = 1;
    identityUser.Username = username;
    identityUser.Password = password;
    identityUser.Remember = remember;
    return this.httpClient
      .post<any>(
        `${environment.IdentityAPIUrl_V3}/auth/login/tms`,
        identityUser
      );
  }

  checkpassword(user360Id: number, oldPassword: string) {
    return this._apiService.post(`${environment.IdentityAPIUrl_V2}${UserApiPath.PasswordCheck}`, { Uid: user360Id, UserPool: 1, Pwd: oldPassword })
  }

  checkOldPassword(password: string) {
    return this._apiService
      .postIdenv3(`${AuthenticationAPIPath.CheckPassword}`, { password: password });
  }

  confirmChangePassword(userId: number, userchangePassword: UserChangePassword): Observable<any> {

    if (userchangePassword.resetTypeEnum == 4) { // Owner change password
      userchangePassword.ownerId = userId
      userchangePassword.ownerPassword = userchangePassword.oldPassword
    }

    return this.httpClient.post<any>(
      `${environment.apiUrl}${UserApiPath.ChangePassword}`,
      { userId: userId, changePasswordDto: userchangePassword }, { withCredentials: true }
    );
  }

  updateUserIsEnabledByUserId(userItem: any) {
    return this._apiService.patchWithoutException(`${UserApiPath.Users}/${userItem.tst360UserId ?? userItem.user_ID}/${userItem.tst360UserServiceId}/${userItem.clientId ?? this.currentUser.ClientID}/IsEnabled/${userItem.isEnabled}`, {});
  }

  deleteUserIdentity(userId: number, userServiceId:number): any {
    const headers = { 'TST-UserPool': '1', 'TST-AppPackage': '1' };
    return this.httpClient.delete(
      `${environment.IdentityAPIUrl_V3}${UserApiPath.Remove}/?tst360UserID=${userId}&tst360UserServiceID=${userServiceId}`, { headers }
    );
  }

  getDriverLocationByDriverId(driverId: number,shipmentId:number): Observable<any> {
    return this._apiService.get(
      `${DasboardAPIPath.getDriverLocation}/${driverId}/${shipmentId}`
    );
  }

  getDriverLocationByBookingId(bookingId: number): Observable<any> {
    return this._apiService.get(
      `${DasboardAPIPath.getDriverLocationByBookkingId}/${bookingId}`
    );
  }

}

export const selectDriverPrintList: DriverPrintTopicModel[] = [
  { id: 'all', text: 'เลือกทั้งหมด', selected: false },
  { id: 'driverdetail', text: 'ข้อมูลพื้นฐานคนขับ + QR', selected: false },
  // { id: 'tel', text: 'ประสิทธิภาพเดือนปัจจุบัน', selected: false },
  { id: 'training-history', text: 'ประวัตการอบรม', selected: false },
  { id: 'salary', text: 'เงินเดือน / ค่าเที่ยว', selected: false },
  { id: 'bankaccount', text: 'บัญชี / วอลเล็ตเงินเดือน', selected: false },
  { id: 'payment', text: 'ประวัติจ่ายเงิน / เบิกงาน', selected: false },
]