export class Address
{
    public address :string;
    public companyId :number;
    public districtId :string;
    public firstName :string;
    public id :number;
    public lastName :string;
    public phoneNo :string;
    public provinceId :number;
    public subdistrictId :number;
    public taxId :string;
    public titleName :string;
    public zipcode :string;
    public billing :boolean;
    public shipping :boolean;
}

export class Addresslist
{
    public address :Address[];
}