import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// declare function LightGallery(galleryID:any, firstRowsNumber:any): any;
declare var LightGallery: any;
@Component({
  selector: 'app-tst-image-list',
  templateUrl: './tst-image-list.component.html',
  styleUrls: ['./tst-image-list.component.scss'],
})
export class TstImageListComponent implements OnInit,OnDestroy {
  @Input() Images: any[] = [];
  @Input() Limit: number = 4;
  @Input() galleryID: any;
  public isSeeMore: boolean = false;
  ImagesViews: any[] = [];
  galleryIDsession: any[] = [];
  public responsiveOptions: any[] = [];
  imageGallery: any;
  //galleryID: any;
  moreImg: any;

  displayBasic: boolean = false;
  constructor(private http: HttpClient) {}
  async ngAfterViewInit() {
    await setTimeout(async () => {
       LightGallery(this.galleryID, true).then((session:any)=>{
        this.galleryIDsession.forEach(f=>{
          let jsonSrssion: any = '';
          jsonSrssion = sessionStorage.getItem(this.galleryID);
          const obj = JSON.parse(jsonSrssion);
          if (obj?.gallery === this.galleryID) {
            this.moreImg = obj?.value;
          }
      });
       });
    }, 100);



  }
  async ngOnInit() {
   // const galleryRunID = Math.floor(Math.random() * (10000 - 1000) + 1000);
   // this.galleryID = 'FileAttaLightGallery' + galleryRunID;
    this.galleryIDsession.push(this.galleryID);
    this.responsiveOptions.push({ breakpoint: '1024px', numVisible: 5 });
    this.responsiveOptions.push({ breakpoint: '768px', numVisible: 3 });
    this.responsiveOptions.push({ breakpoint: '560px', numVisible: 2 });
    this.ImagesViews = this.Images;

    // this.isSeeMore = this.Images.length > this.Limit;

    // if (this.Images.length > 4) {
    //   this.ImagesViews = this.Images.filter(
    //     (item, index) => index < this.Limit
    //   );
    // } else {
    //   this.ImagesViews = this.Images;
    // }

    // const getImages = new Promise((resolve, reject) => {
    //   let gelley = new GellerImage();
    //   this.Images.forEach((item) => {
    //     let gItem = new GelleryImageItem();
    //     gItem.previewImageSrc = item;
    //     gItem.thumbnailImageSrc = item;
    //     gelley.data.push(gItem);
    //   });
    //   resolve(gelley.data);
    // });

   // getImages.then((images) => (this.imageGallery = images));

  }

  viewGellery() {
    this.displayBasic = true;

    $("#"+this.galleryID+" a:first-child > img").trigger("click");
  }
  ngOnDestroy(): void {
    this.galleryIDsession.forEach(f=>{
        console.log('galleryIDsession :'+f);
        sessionStorage.removeItem(f);
    });
    //alert('ngOnDestroy');


  }
}
class GelleryImageItem {
  previewImageSrc: string | undefined;
  thumbnailImageSrc: string | undefined;
}
class GellerImage {
  data: GelleryImageItem[] = [];
}
