<div class="row" *ngIf="permission90300.canRead">
    <div class="col-xl-12">
        <div class="panel-content">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item"><a id="menu-tab" class="nav-link active" data-toggle="tab" href="#view"
                        (click)="clickTab('menu')" role="tab"><b>สิทธ์การเข้าถึงเมนู</b></a></li>
                <li class="nav-item"><a id="access-tab" class="nav-link" data-toggle="tab" href="#permid"
                        (click)="clickTab('access')" role="tab"><b>สิทธ์การใช้งาน</b></a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane fade show active" id="view" role="tabpanel">
                    <div class="col-xl-12 p-0">
                        <div class="panel-container show">
                            <div class="panel-content mt-3">
                                <div class="form-group">
                                    <form [formGroup]="SelectMenu">
                                        <div class="col-md-3">
                                            <ng-select placeholder="กลุ่ม USER" [clearable]="false"
                                                formControlName="RoleId" (change)="OnClientMenuChange()">
                                                <ng-option *ngFor="let item of ClientRole" [value]="item.id">
                                                    {{item.role.roleName}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </form>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4">

                                        </div>
                                        <div class="col-md-8">
                                            <div class="row text-center">
                                                <div class="col-md-4 border-bottom">
                                                    <h6>สิทธ์การเข้าถึงเมนู</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let items of MenuListWeb;let i = index;" class="form-group">  
                                    <div class="row mb-2">
                                        <div class="col-md-1">

                                        </div>
                                        <div class="col-md-3">
                                            <h4>{{items.menuName}}</h4>
                                        </div>
                                    </div>
                                    <div *ngFor="let item of items.subMenuDto" class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-5">

                                                    </div>
                                                    <div class="col-md-7">
                                                        <h5 class="m-0"><i [ngClass]="item.menuIcon"></i> {{item.menuName}}</h5>
                                                    </div>                                                  
                                                </div>
                                            </div>
                                            <div *ngFor="let child of item.childMenuDto" class="form-group">
                                                <div class="row">  
                                                    <div class="col-md-6">

                                                    </div>                                                
                                                    <div class="col-md-6">
                                                        <label class="m-0">{{child.menuName}}</label>
                                                    </div>                                              
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-4 text-center">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{item.menuName+'_'+item.menuId}}"
                                                                [(ngModel)]="item.isCheck">
                                                            <label class="custom-control-label"
                                                                for="{{item.menuName+'_'+item.menuId}}"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngFor="let child of item.childMenuDto" class="row">
                                                <div class="col-md-4 text-center">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{child.menuName+'_'+item.menuId}}"
                                                                [(ngModel)]="child.isCheck">
                                                            <label class="custom-control-label"
                                                                for="{{child.menuName+'_'+item.menuId}}"></label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- <div class="col-md-9">
                                                    <div class="row">
                                                        <div *ngIf="item.IsSupply != null" class="col-md-2 text-center">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="selectIsSup{{i}}" [checked]="item.IsSupply">
                                                                <label class="custom-control-label"
                                                                    for="selectIsSup{{i}}"></label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.IsSales != null" class="col-md-2 text-center">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="selectIsSales{{i}}" [checked]="item.IsSales">
                                                                <label class="custom-control-label"
                                                                    for="selectIsSales{{i}}"></label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.IsPlant != null" class="col-md-2 text-center">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="selectIsPlant{{i}}" [checked]="item.IsPlant">
                                                                <label class="custom-control-label"
                                                                    for="selectIsPlant{{i}}"></label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.IsAccounting != null"
                                                            class="col-md-2 text-center">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="selectIsAccounting{{i}}"
                                                                    [checked]="item.IsAccounting">
                                                                <label class="custom-control-label"
                                                                    for="selectIsAccounting{{i}}"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5 d-flex justify-content-center">
                                    <div class="col-md-3 text-center">
                                        <button type="button" class="btn btn-success btn-block" *ngIf="permission90300.canEdit" (click)="OnSubmitMenuModule()" [disabled]="IsSaveing">
                                            <span *ngIf="IsSaveing"
                                                class="spinner-grow spinner-grow-sm"></span>บันทึก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="tab-pane fade" id="permid" role="tabpanel">
                    <div class="col-xl-12 p-0">
                        <div class="panel-container show">
                            <div class="panel-content mt-3">
                                <div class="form-group">
                                    <form [formGroup]="SelectRole">
                                        <div class="col-md-3">
                                            <ng-select placeholder="กลุ่ม USER" [clearable]="false"
                                                formControlName="RoleId" (change)="OnClientRoleChange()">
                                                <ng-option *ngFor="let item of ClientRole" [value]="item.id">
                                                    {{item.role.roleName}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                    </form>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-3">

                                        </div>
                                        <div class="col-md-9">
                                            <div class="row text-center">
                                                <div *ngFor="let item of Action" class="col-md-1 border-bottom">
                                                    {{item.name}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let items of AppPermiss" class="form-group">
                                    <div class="row">
                                        <div class="col-md-3 text-right">
                                            <h5>{{items.name}}</h5>
                                        </div>
                                    </div>
                                    <div *ngFor="let sub of items.appModuleOperations; let i = index;"
                                        class="text-right">
                                        <div class="row mb-2">
                                            <div class="col-md-3">
                                                <label>{{sub.name}}</label>
                                            </div>
                                            <div class="col-md-9">
                                                <div class="row">
                                                    <div *ngFor="let Actlist of sub.actionListDtos"
                                                        class="col-md-1 text-center">
                                                        <div *ngIf="Actlist.isAction"
                                                            class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="selectIsRead{{Actlist.permissions.actionId_FK+'_'+Actlist.permissions.appModuleOperationId_FK}}"
                                                                [(ngModel)]="Actlist.isCheck">
                                                            <label class="custom-control-label"
                                                                for="selectIsRead{{Actlist.permissions.actionId_FK+'_'+Actlist.permissions.appModuleOperationId_FK}}"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5 d-flex justify-content-center">
                                    <div class="col-md-3 text-center">
                                        <button type="button" class="btn btn-success btn-block"
                                            (click)="OnSubmitAppModule()" [disabled]="IsSaveing" *ngIf="permission90300.canEdit">
                                            <span *ngIf="IsSaveing"
                                                class="spinner-grow spinner-grow-sm"></span>บันทึก</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="!permission90300.canRead">
    <div class="col-xl-12">
    Access Denied
    </div>
</div>
