export class BannerRequestDto {
  registerPackageId: string | undefined;
  requestDate: string | undefined;
  width: number | undefined;
  height: number | undefined;
}

export class BannerResponseDto {
  bannerTypeId: string | undefined;
  bannerTypeName: string | undefined;
  imageUrl: string | undefined;
  actionUrl: string | undefined;
}
