import { Injectable } from '@angular/core';
import { map, catchError, retry } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpHeaders,
  HttpBackend,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { PageloadingService } from './pageloading.service';

declare let bootbox: any;

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public _BASE_API = environment.apiUrl;
  public _BASE_NOTI = environment.apiNoti;
  public _BASE_360NOTI = environment.api360Noti;
  public _BASE_DOCGEN = environment.apiDocgen;
  public _BASE_MATCHING = environment.apiMatching;
  public _BASE_Identity = environment.IdentityAPIUrl_V3;
  public _BASE_360V3 = environment.api360V3;
  public _BASE_PARTNER_SERVICE = environment.partnerSeriveApi;
  public _BASE_MS_API = environment.apiMicrosoft365Url;
  public _BASE_FUEL_API = environment.fuelServiceUrl;
  public _BASE_DASHBORD = environment.dashboardUrl;
  public _BASE_ORDER = environment.ordersUrl;
  public _BASE_INTERFACE = environment.interfaceUrl;
  // public _BASE_360BRAIN = environment.brain360;

  constructor(public httpClient: HttpClient, public handler: HttpBackend) { }



  //360Brain Scheduler
  // getPlan(): Observable<any> {
  //   let headers = new HttpHeaders();


  // }
  // Call API ......
  get(url: string, params?: HttpParams): Observable<any> {
    return this.httpClient.get(this._BASE_API + url, {withCredentials: true, params: params}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      retry(2),
      catchError(this.handleError)
    );
  }

  getWithoutHandleError(url: string): Observable<any> {

    return this.httpClient.get(this._BASE_API + url, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      retry(2)
    );
  }

  getMathing(url: string, token: string): Observable<any> {
    const headers = this.SetHttpHeader(token);
    console.log('header', headers);
    return this.httpClient.get(this._BASE_MATCHING + url, {
      headers: headers, withCredentials: true,
    });
  }

  post(url: string, data: any): Observable<any> {
    return this.httpClient.post<any>(this._BASE_API + url, data, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      catchError(this.handleError)
    );
  }

  postMS365(url: string, data: any): Observable<any> {
    return this.httpClient.post<any>(this._BASE_MS_API + url, data, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      catchError(this.handleError)
    );
  }

  post360v3(url: string, data: any): Observable<any> {
    return this.httpClient.post<any>(this._BASE_360V3 + url, data, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      catchError(this.handleError)
    );
  }
  postWithBlobType(url: string, data: any): Observable<any> {
    return this.httpClient.post<any>(this._BASE_API + url, data, {withCredentials: true});
    //return this.httpClient.post<any>(this._BASE_API + url, data);
  }

  postWithoutException(url: string, data: any): Observable<any> {
    return this.httpClient.post<any>(this._BASE_API + url, data, {withCredentials: true});
    //return this.httpClient.post<any>(this._BASE_API + url, data);
  }

  // Call API ......
  getWithHeader(url: string, header: any): Observable<any> {
    return this.httpClient.get(url, { headers: header, withCredentials: true }).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      retry(2),
      catchError(this.handleError)
    );
  }
  postWithHeader(fullUrl: string, data: any, header: any): Observable<any> {
    return this.httpClient
      .post<any>(fullUrl, data, { headers: header, withCredentials: true })
      .pipe(
        map((res) => {
          console.log('ggggggggggggggggggg : ', res);
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  postToNoti(url: string, data: any, token): Observable<any> {
    const headers = this.SetHttpHeaderNoti(token);
    return this.httpClient
      .post<any>(this._BASE_NOTI + url, data, { headers: headers, withCredentials: true })
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  postToPartnerService(url: string, data: any): Observable<any> {
    const headers = this.SetHttpPartnerService();
    return this.httpClient
      .get<any>(this._BASE_PARTNER_SERVICE + url, { headers: headers, withCredentials: true })
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  gettToPartnerService(url: string): Observable<any> {
    const headers = this.SetHttpPartnerService();
    return this.httpClient
      .get<any>(this._BASE_PARTNER_SERVICE + url, { headers: headers, withCredentials: true })
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  GetToNoti(url: string, token): Observable<any> {
    const headers = this.SetHttpHeaderNoti(token);
    console.log(this._BASE_NOTI + url)
    return this.httpClient.get(this._BASE_NOTI + url,  { headers: headers, withCredentials: true });
  }

  ReadNoti(url: string, token,JSobj): Observable<any> {
    const headers = this.SetHttpHeaderNoti(token);
    console.log(this._BASE_NOTI + url)
    //  return this.httpClient.put("https://localhost:9990/api" + url,  { headers: headers, withCredentials: true });

    return this.httpClient.put(this._BASE_NOTI + url,JSobj,  { headers: headers, withCredentials: true });
  }

  GetTo360Noti(url: string, token): Observable<any> {
    const headers = this.SetHttpHeaderNoti(token);
    console.log(this._BASE_360NOTI + url)
    return this.httpClient.get(this._BASE_360NOTI + url, { headers: headers });
  }

  postToMatching(url: string, data: any, token): Observable<any> {
    const headers = this.SetHttpHeader(token);
    return this.httpClient
      .post<any>(this._BASE_MATCHING + url, data, { headers: headers, withCredentials: true })
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  // postToNoti(url: string,itemId:number,bookId:number, data: any): Observable<any> {
  //   const headers = this.SetHttpHeaderNoti();
  //   return this.httpClient.post<any>(this._BASE_MATCHING + url+ `/${itemId}/${bookId}`, data,{headers:headers}).pipe(
  //     map((res) => {
  //       if (!res) {
  //         throw new Error('Value expected!');
  //       }
  //       return res;
  //     }),
  //     catchError(this.handleError)
  //   );
  // }

  postIdenv3(url: string, data: any): Observable<any> {
    return this.httpClient.post<any>(this._BASE_Identity + url, data,{withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      // catchError(this.handleError)
    );
  }

  postToFuel(url: string, data: any, clientId: number, token = ""): Observable<any> {
    let headers = new HttpHeaders({
      "Techx-client": `${clientId}`,
      Authorization: token,
    });
    return this.httpClient
      .post<any>(this._BASE_FUEL_API + url, data, { headers: headers, withCredentials: true })
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getToFuel(url: string, clientId: number, token = ""): Observable<any> {
    let headers = new HttpHeaders({
      "Techx-client": `${clientId}`,
      Authorization: token,
    });
    console.log(this._BASE_FUEL_API + url)
    return this.httpClient.get(this._BASE_FUEL_API + url,  { headers: headers, withCredentials: true });
  }

  getToDashboard(url: string, clientId: number, token = ""): Observable<any> {
    let headers = new HttpHeaders({
      "Techx-client": `${clientId}`,
      Authorization: token,
    });
    console.log(this._BASE_DASHBORD + url)
    return this.httpClient.get(this._BASE_DASHBORD + url,  { headers: headers, withCredentials: true });
  }


  postToDashboard(url: string, data: any, clientId: number, token = ""): Observable<any> {
    let headers = new HttpHeaders({
      "Techx-client": `${clientId}`,
      Authorization: token,
    });
    return this.httpClient
      .post<any>(this._BASE_DASHBORD + url, data, { headers: headers, withCredentials: true })
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  // putUpdateNoti(token: string, id: string,model:any): Observable<any> {
  //   const headers = this.SetHttpHeaderNoti(token);
  //   return this.httpClient.put<any>(this._BASE_MATCHING +`/v1.0/NotiDB/Update/InAppWebV2/${id}`,model,headers).pipe(
  //     map((res) => {
  //       if (!res) {
  //         throw new Error('Value expected!');
  //       }
  //       return res;
  //     }),
  //     catchError(this.handleError)
  //   );
  // }

  SetHttpHeader(token: string): any {
    let headers = new HttpHeaders({
      svc: 'matching-svc',
      //Authorization: token,
    });
    return headers;
  }

  SetHttpHeaderNoti(token: string): any {
    let headers = new HttpHeaders({
      svc: 'noti-svc',
      //Authorization: token,
    });
    return headers;
  }

  SetHttpHeaderDocgen(token: string): any {
    let headers = new HttpHeaders({
      svc: 'docgen-svc',
      //Authorization: token,
    });
    return headers;
  }

  SetHttpPartnerService(token: string = ""): any {
    let headers = new HttpHeaders({

    });
    return headers;
  }

  put(url: string, data: any): Observable<any> {
    return this.httpClient.put(this._BASE_API + url, data, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      catchError(this.handleError)
    );
  }

  patch(url: string, data: any): Observable<any> {
    return this.httpClient.patch(this._BASE_API + url, data, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      catchError(this.handleError)
    );
  }

  patchWithoutException(url: string, data: any): Observable<any> {
    return this.httpClient.patch(this._BASE_API + url, data, {withCredentials: true});
  }

  delete(url: string): any {
    return this.httpClient.delete(this._BASE_API + url, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      catchError(this.handleError)
    );
  }

  fileupload(url, data): Observable<any> {
    console.log('fileupload data : ' + this._BASE_API + url);
    return this.httpClient.post<any>(this._BASE_API + url, data, {withCredentials: true}).pipe(
      map((res) => {
        if (res === undefined || res === null) {
          throw new Error('Value expected!');
        }
        return res;
      }),
      catchError(this.handleError)
    );
    // return this.httpClient
    //   .post<any>(this._BASE_API + url, data, {
    //     reportProgress: true,
    //     observe: 'events',
    //   })
    //   .pipe(
    //     map((event) => {
    //       console.log('asdsdsd => ',event);
    //       switch (event.type) {
    //         case HttpEventType.UploadProgress:
    //           const progress = Math.round((100 * event.loaded) / event.total);
    //           return {status: 'progress', message: progress};
    //         case HttpEventType.Response:
    //           return event.body;
    //         default:
    //           return `Unhandled event: ${event.type}`;
    //       }
    //     })
    //   );
  }

  postToOrders(url: string, data: any, downloadFile: boolean = false): Observable<any> {
    const options = {
      withCredentials: true,
      responseType: downloadFile ? 'blob' as 'json' : 'json' // 'blob' is used for binary data (e.g., files)
    };

    //const headers = this.SetHttpHeader(token);
    return this.httpClient
      .post<any>(this._BASE_ORDER + url, data, options)
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  //downloadFile ถ้าเป็นการโหลดออกมา ให้เป็น true
  GetToOrders(url: string, downloadFile: boolean = false): Observable<any> {
    const options = {
      withCredentials: true,
      responseType: downloadFile ? 'blob' as 'json' : 'json' // 'blob' is used for binary data (e.g., files)
    };

    //const headers = this.SetHttpHeader(token);
    return this.httpClient
      .get<any>(this._BASE_ORDER + url, options)
      .pipe(
        map((res) => {
          
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  postToInterface(url: string, data: any, downloadFile: boolean = false): Observable<any> {
    const options = {
      withCredentials: true,
      responseType: downloadFile ? 'blob' as 'json' : 'json' // 'blob' is used for binary data (e.g., files)
    };

    //const headers = this.SetHttpHeader(token);
    return this.httpClient
      .post<any>(this._BASE_INTERFACE + url, data, options)
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }

  GetToInterface(url: string, downloadFile: boolean = false): Observable<any> {
    const options = {
      withCredentials: true,
      responseType: downloadFile ? 'blob' as 'json' : 'json' // 'blob' is used for binary data (e.g., files)
    };

    //const headers = this.SetHttpHeader(token);
    return this.httpClient
      .get<any>(this._BASE_INTERFACE + url, options)
      .pipe(
        map((res) => {
          if (res === undefined || res === null) {
            throw new Error('Value expected!');
          }
          return res;
        }),
        catchError(this.handleError)
      );
  }


  private handleError(error: HttpErrorResponse): any {

    let errorMessage = 'Unknown error!';
    let errorStatusText = 'Unknown error!';
    let errorStatus = 0;
    let errorUrl:any;
    // let bootbox: any;
    // debugger;
    console.log('HttpErrorResponse : ', error);
      // this.pageLoad.hide();
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = error.message;
      errorStatus = error.status;
      errorStatusText = error.statusText;
    }
    errorUrl = error?.url;
    console.log('errorStatus : ',errorStatus);
    // window.alert(errorMessage);
    if(errorStatus == 401 || errorStatus == undefined){
      console.warn('handleError : ' + errorMessage);
      return EMPTY;
    }
    if(error?.error?.errors){
      errorMessage =JSON.stringify(error?.error?.errors);
    }

    if (typeof bootbox !== 'undefined') {
      bootbox.confirm({
        title:
          "<i class='fal fa-exclamation-circle text-danger mr-2'></i> พบข้อผิดพลาด !",
        message: '<span>ระบบไม่สามารถทำงานต่อได้ กรุณา โหลดหน้าใหม่.. </span>',
        centerVertical: true,
        swapButtonOrder: true,
        buttons: {
          confirm: {
            label: 'Reload',
            className: 'btn-danger shadow-0',
          },
          cancel: {
            label: 'Close',
            className: 'btn-default',
          },
        },
        className: 'modal-alert',
        closeButton: false,
        callback: (result) => {
          if (result === true) {
            window.location.reload();
          }
        },
      });
    }
    console.warn('handleError : '+errorMessage);
    return EMPTY;
  }
}
