import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-google-map-polygon',
  templateUrl: './google-map-polygon.component.html',
  styleUrls: ['./google-map-polygon.component.scss'],
})
export class GoogleMapPolygonComponent implements OnInit {
  constructor(private cdr: ChangeDetectorRef) {}

  map: google.maps.Map;
  currentPolygon: google.maps.Polygon | null = null; // Store the currently drawn polygon
  predefinedPolygon: google.maps.Polygon | null = null; // Store the predefined polygon

  @Input() locationData: any;
  @Input() selectLocation: any;
  
  @Output() obj_polygon: EventEmitter<any> = new EventEmitter();
  locSelectMapPoint = { lat: 13.736717, lng: 100.523186 };
  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.selectLocation) {
      this.locSelectMapPoint = {
        lat: this.selectLocation?.lat, lng:this.selectLocation?.lng
      }
    }
    setTimeout(() => {
      this.initMap();
    });
  }

  initMap(): void {

    var centerMap = { lat: 13.736717, lng: 100.523186 };
   
    if (this.locationData?.polygonList && this.locationData.polygonList.length) {
      centerMap = {lat: this.locationData?.polygonList[0].lat, lng: this.locationData?.polygonList[0].lng};
    }
    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center: centerMap, // Center map based on your coordinates
        zoom: 11,
        
      }
    );

    // Drawing Manager
    const drawingManager = new google.maps.drawing.DrawingManager({
      
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
    });

    drawingManager.setMap(this.map);

    const marker = new google.maps.Marker({
      position: this.locSelectMapPoint,
      map: this.map,
      title: "Uluru (Ayers Rock)",
    });

    // Event listener for completed polygon drawing
    google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const polygon = event.overlay as google.maps.Polygon;

          if (this.currentPolygon) {
            this.currentPolygon.setMap(null);
          }

          this.logPolygonCoordinates(polygon);

          // Add right-click event to remove the polygon
          google.maps.event.addListener(polygon, 'rightclick', () => {
            this.removePolygon(polygon);
          });
        }
        //anchor: marker
      },
     
    );

    if (this.locationData?.polygonList && this.locationData.polygonList.length) {
      this.addPredefinedPolygon(this.map, this.locationData?.polygonList);
    }
  }

  // Method to add a predefined polygon from fetched coordinates
  addPredefinedPolygon(
    map: google.maps.Map,
    coordinates: { lat: number; lng: number }[]
  ): void {
    if (this.predefinedPolygon) {
      this.predefinedPolygon.setMap(null); // Remove previous polygon if exists
    }

    this.predefinedPolygon = new google.maps.Polygon({
      paths: coordinates,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    });

    // Set the polygon on the map
    this.predefinedPolygon.setMap(map);

    this.logPolygonCoordinates(this.predefinedPolygon);
  }

  // Method to remove a polygon
  removePolygon(polygon: google.maps.Polygon): void {
    polygon.setMap(null); // Removes the polygon from the map
    if (this.currentPolygon === polygon) {
      this.currentPolygon = null; // Reset the current polygon
    }
  }

  logPolygonCoordinates(polygon: google.maps.Polygon): void {
    // Store the new drawn polygon
    this.currentPolygon = polygon;

    const path = polygon.getPath();
    const coordinates: { lat: number; lng: number }[] = [];

    path.forEach((point: google.maps.LatLng) => {
      coordinates.push({
        lat: point.lat(),
        lng: point.lng(),
      });
    });

    this.obj_polygon.emit(coordinates);
  }
}
