<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="javascript:void(0);">SmartAdmin</a></li>
    <li class="breadcrumb-item">UI Components</li>
    <li class="breadcrumb-item active">Buttons</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="subheader">
    <h1 class="subheader-title">
        <i class='subheader-icon fal fa-window'></i> Buttons {{CountNumber | number}}
        <small>
                        Use custom button styles for actions in forms, dialogs, and more. Includes support for a handful of contextual variations, sizes, states, and more.
                    </small>
    </h1>
</div>
<div class="row">
    <div class="col-xl-12">
        <h3>360TRUCK</h3>
        <img src="https://file-dev.360truck.co/logo.jpg" />
        <h3>360TECHX</h3>
        <img src="https://file.tms-dev.360techx.co/logo.jpg" />
        <div id="panel-1" class="panel">
            <div class="panel-hdr">
                <h2>
                    Advanced <span class="fw-300"><i>Example</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div id="js-lightgallery">
                        <a class="" href="assets/img/demo/gallery/1.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/1.jpg" alt="image 0001">
                        </a>
                        <a class="" href="assets/img/demo/gallery/2.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/2.jpg" alt="image">
                        </a>
                        <a class="" href="assets/img/demo/gallery/3.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/3.jpg" alt="image">
                        </a>

                        <a class="bg-black" href="assets/img/demo/gallery/4.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/4.jpg" alt="image" style="opacity: 0.5;">
                            <div class="more-img">
                                +50
                            </div>
                        </a>
                        <a href="assets/img/demo/gallery/5.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/5.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/6.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/6.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/7.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/7.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/8.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/8.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/9.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/9.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/10.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/10.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/11.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/11.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/12.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/12.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/13.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/13.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/14.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/14.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/15.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/15.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/16.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/16.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/17.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/17.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/18.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/18.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/19.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/19.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/20.jpg" data-sub-html="">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/20.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/21.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/21.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/22.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/22.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/23.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/23.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/24.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/24.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/25.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/25.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/26.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/26.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/27.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/27.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/28.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/28.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/29.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/29.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/30.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/30.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/31.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/31.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/32.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/32.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/33.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/33.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/34.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/34.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/35.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/35.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/36.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/36.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/37.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/37.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/38.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/38.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/39.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/39.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/40.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/40.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/41.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/41.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/42.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/42.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/43.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/43.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/44.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/44.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/45.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/45.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/46.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/46.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/47.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/47.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/48.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/48.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/49.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/49.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/50.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/50.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/51.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/51.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/52.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/52.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/53.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/53.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/54.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/54.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/55.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/55.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/56.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/56.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/57.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/57.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/58.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/58.jpg" alt="image">
                        </a>
                        <a href="assets/img/demo/gallery/59.jpg">
                            <img class="img-responsive" src="assets/img/demo/gallery/thumb/59.jpg" alt="image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">

    <div class="col-md-12 col-xl-6">
        <!--Button colors-->
        <div id="panel-1" class="panel">
            <div class="panel-hdr">
                <h2>
                    Button <span class="fw-300"><i>Colors</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        SmartAdmin WebApp includes nine predefined button styles, each serving its own semantic purpose.
                    </div>
                    <h5 class="frame-heading">
                        {{"Default" | uppercase}}
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-primary">Primary</button>
                            <button type="button" class="btn btn-secondary">Secondary</button>
                            <button type="button" class="btn btn-success">Success</button>
                            <button type="button" class="btn btn-info">Info</button>
                            <button type="button" class="btn btn-warning">Warning</button>
                            <button type="button" class="btn btn-danger">Danger</button>
                            <button type="button" class="btn btn-dark">Dark</button>
                            <button type="button" class="btn btn-light">Light</button>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Rounded
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-primary btn-pills">Primary</button>
                            <button type="button" class="btn btn-secondary btn-pills">Secondary</button>
                            <button type="button" class="btn btn-success btn-pills">Success</button>
                            <button type="button" class="btn btn-info btn-pills">Info</button>
                            <button type="button" class="btn btn-warning btn-pills">Warning</button>
                            <button type="button" class="btn btn-danger btn-pills">Danger</button>
                            <button type="button" class="btn btn-dark btn-pills">Dark</button>
                            <button type="button" class="btn btn-light btn-pills">Light</button>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Unique style
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-default">Default</button>
                            <button type="button" class="btn btn-default btn-pills">Default</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Block-->
        <div id="panel-2" class="panel">
            <div class="panel-hdr">
                <h2>
                    Block <span class="fw-300"><i>Buttons</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Create block level buttons—those that span the full width of a parent—by adding <code>.btn-block</code>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="frame-wrap text-center mb-0">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="demo-v-spacing">
                                            <button type="button" class="btn btn-primary btn-lg btn-block">Block
                                                            <span>large</span>
                                                        </button>
                                            <button type="button" class="btn btn-primary btn-block">Block
                                                            <span>default</span>
                                                        </button>
                                            <button type="button" class="btn btn-primary btn-sm btn-block">Block
                                                            <span>small</span>
                                                        </button>
                                            <button type="button" class="btn btn-primary btn-xs btn-block">Block
                                                            <span>extra small</span>
                                                        </button>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="demo-v-spacing mt-3 mt-xl-0">
                                            <button type="button" class="btn btn-success btn-pills btn-lg btn-block">Block
                                                            <span>large</span>
                                                        </button>
                                            <button type="button" class="btn btn-success btn-pills btn-block">Block
                                                            <span>default</span>
                                                        </button>
                                            <button type="button" class="btn btn-success btn-pills btn-sm btn-block">Block
                                                            <span>small</span>
                                                        </button>
                                            <button type="button" class="btn btn-success btn-pills btn-xs btn-block">Block
                                                            <span>extra small</span>
                                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Sizes-->
        <div id="panel-3" class="panel">
            <div class="panel-hdr">
                <h2>
                    Option <span class="fw-300"><i>Sizes</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Alter button sizes by using the classes <code>.btn-lg</code>, <code>.btn-sm</code>, <code>.btn-xs</code>
                    </div>
                    <h5 class="frame-heading">
                        Largest size
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-lg btn-primary">Primary</button>
                            <button type="button" class="btn btn-lg btn-secondary">Secondary</button>
                            <button type="button" class="btn btn-lg btn-default">Default</button>
                            <button type="button" class="btn btn-lg btn-success">Success</button>
                            <button type="button" class="btn btn-lg btn-warning">Warning</button>
                            <button type="button" class="btn btn-lg btn-info">Info</button>
                            <button type="button" class="btn btn-lg btn-danger">Danger</button>
                            <button type="button" class="btn btn-lg btn-dark">Dark</button>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Default size
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-primary">Primary</button>
                            <button type="button" class="btn btn-secondary">Secondary</button>
                            <button type="button" class="btn btn-default">Default</button>
                            <button type="button" class="btn btn-success">Success</button>
                            <button type="button" class="btn btn-warning">Warning</button>
                            <button type="button" class="btn btn-info">Info</button>
                            <button type="button" class="btn btn-danger">Danger</button>
                            <button type="button" class="btn btn-dark">Dark</button>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Small size
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-sm btn-primary">Primary</button>
                            <button type="button" class="btn btn-sm btn-secondary">Secondary</button>
                            <button type="button" class="btn btn-sm btn-default">Default</button>
                            <button type="button" class="btn btn-sm btn-success">Success</button>
                            <button type="button" class="btn btn-sm btn-warning">Warning</button>
                            <button type="button" class="btn btn-sm btn-info">Info</button>
                            <button type="button" class="btn btn-sm btn-danger">Danger</button>
                            <button type="button" class="btn btn-sm btn-dark">Dark</button>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Smallest size
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-xs btn-primary">Primary</button>
                            <button type="button" class="btn btn-xs btn-secondary">Secondary</button>
                            <button type="button" class="btn btn-xs btn-default">Default</button>
                            <button type="button" class="btn btn-xs btn-success">Success</button>
                            <button type="button" class="btn btn-xs btn-warning">Warning</button>
                            <button type="button" class="btn btn-xs btn-info">Info</button>
                            <button type="button" class="btn btn-xs btn-danger">Danger</button>
                            <button type="button" class="btn btn-xs btn-dark">Dark</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--With icons-->
        <div id="panel-4" class="panel">
            <div class="panel-hdr">
                <h2>
                    Button <span class="fw-300"><i>with Icons</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        We use the icon tags to insert icons into buttons
                    </div>
                    <div class="demo">
                        <button type="button" class="btn btn-lg btn-primary">
                                        <span class="fal fa-print mr-1"></span>
                                        Print
                                    </button>
                        <button type="button" class="btn btn-lg btn-secondary">
                                        <span class="fal fa-volume-mute mr-1"></span>
                                        Mute
                                    </button>
                        <button type="button" class="btn btn-lg btn-default">
                                        <span class="fal fa-check mr-1"></span>
                                        Submit
                                    </button>
                        <button type="button" class="btn btn-lg btn-success">
                                        <span class="fal fa-download mr-1"></span>
                                        Download
                                    </button>
                        <button type="button" class="btn btn-lg btn-warning">
                                        <span class="fal fa-exclamation-triangle mr-1"></span>
                                        Scan Device
                                    </button>
                        <button type="button" class="btn btn-lg btn-info">
                                        <span class="fal fa-bug mr-1"></span>
                                        Report Bug
                                    </button>
                        <button type="button" class="btn btn-lg btn-danger">
                                        <span class="fal fa-times mr-1"></span>
                                        Delete
                                    </button>
                        <button type="button" class="btn btn-lg btn-dark">
                                        <span class="fal  fa-eject mr-1"></span>
                                        Eject
                                    </button>
                    </div>
                </div>
            </div>
        </div>
        <!--Icon buttons-->
        <div id="panel-5" class="panel">
            <div class="panel-hdr">
                <h2>
                    Icons <span class="fw-300"><i>example</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Convert button to an icon button by adding the class <code>.btn-icon</code>
                    </div>
                    <h5 class="frame-heading">
                        Various sizes
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-lg btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-lg btn-icon">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-lg btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-lg btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-lg btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-lg btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-lg btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-lg btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-icon">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-sm btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-sm btn-icon">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-sm btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-sm btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-sm btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-xs btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-xs btn-icon">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-xs btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-xs btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-xs btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-xs btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-xs btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-xs btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Rounded
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-lg btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-lg btn-icon rounded-circle">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-lg btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-lg btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-lg btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-lg btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-lg btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-lg btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-icon rounded-circle">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-sm btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-sm btn-icon rounded-circle">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-sm btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-sm btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-sm btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                        <div class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-xs btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-xs btn-icon rounded-circle">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-default btn-xs btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-xs btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-warning btn-xs btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-info btn-xs btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-danger btn-xs btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-dark btn-xs btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Hover dot effect
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-primary btn-lg btn-icon rounded-circle hover-effect-dot">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-secondary btn-lg btn-icon rounded-circle hover-effect-dot">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-success btn-lg btn-icon hover-effect-dot">
                                <i class="fal fa-download"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-xl-6">
        <!--Button colors (outline)-->
        <div id="panel-6" class="panel">
            <div class="panel-hdr">
                <h2>
                    Button <span class="fw-300"><i>Outlines</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Replace the default modifier classes with<code>.btn-outline-*</code>
                    </div>
                    <h5 class="frame-heading">
                        Outline
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-outline-primary">Primary</button>
                            <button type="button" class="btn btn-outline-secondary">Secondary</button>
                            <button type="button" class="btn btn-outline-success">Success</button>
                            <button type="button" class="btn btn-outline-info">Info</button>
                            <button type="button" class="btn btn-outline-warning">Warning</button>
                            <button type="button" class="btn btn-outline-danger">Danger</button>
                            <button type="button" class="btn btn-outline-dark">Dark</button>
                            <button type="button" class="btn btn-outline-light">Light</button>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Rounded outline
                    </h5>
                    <div class="frame-wrap">
                        <div class="demo">
                            <button type="button" class="btn btn-outline-primary btn-pills">Primary</button>
                            <button type="button" class="btn btn-outline-secondary btn-pills">Secondary</button>
                            <button type="button" class="btn btn-outline-success btn-pills">Success</button>
                            <button type="button" class="btn btn-outline-info btn-pills">Info</button>
                            <button type="button" class="btn btn-outline-warning btn-pills">Warning</button>
                            <button type="button" class="btn btn-outline-danger btn-pills">Danger</button>
                            <button type="button" class="btn btn-outline-dark btn-pills">Dark</button>
                            <button type="button" class="btn btn-outline-light btn-pills">Light</button>
                        </div>
                    </div>
                    <h5 class="frame-heading">
                        Unique style
                    </h5>
                    <div class="frame-wrap mb-0">
                        <div class="demo">
                            <button type="button" class="btn btn-outline-default">Default</button>
                            <button type="button" class="btn btn-outline-default btn-pills">Default</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Block (outline)-->
        <div id="panel-7" class="panel">
            <div class="panel-hdr">
                <h2>
                    Block <span class="fw-300"><i>Outlines</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Block buttons inherits outline button properties <code>.btn-outline-*</code>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="frame-wrap text-center mb-0">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="demo-v-spacing">
                                            <button type="button" class="btn btn-outline-primary btn-lg btn-block">Block
                                                            <span>large</span>
                                                        </button>
                                            <button type="button" class="btn btn-outline-primary btn-block">Block
                                                            <span>default</span>
                                                        </button>
                                            <button type="button" class="btn btn-outline-primary btn-sm btn-block">Block
                                                            <span>small</span>
                                                        </button>
                                            <button type="button" class="btn btn-outline-primary btn-xs btn-block">Block
                                                            <span>extra small</span>
                                                        </button>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="demo-v-spacing mt-3 mt-xl-0">
                                            <button type="button" class="btn btn-outline-success btn-pills btn-lg btn-block">Block
                                                            <span>large</span>
                                                        </button>
                                            <button type="button" class="btn btn-outline-success btn-pills btn-block">Block
                                                            <span>default</span>
                                                        </button>
                                            <button type="button" class="btn btn-outline-success btn-pills btn-sm btn-block">Block
                                                            <span>small</span>
                                                        </button>
                                            <button type="button" class="btn btn-outline-success btn-pills btn-xs btn-block">Block
                                                            <span>extra small</span>
                                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Sizes (outline)-->
        <div id="panel-8" class="panel">
            <div class="panel-hdr">
                <h2>
                    Option <span class="fw-300"><i>Sizes</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Alter button sizes by using the classes <code>.btn-lg</code>, <code>.btn-sm</code>, <code>.btn-xs</code>
                    </div>
                    <h5 class="frame-heading">
                        Largest size
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <button type="button" class="btn btn-lg btn-outline-primary">Primary</button>
                            <button type="button" class="btn btn-lg btn-outline-secondary">Secondary</button>
                            <button type="button" class="btn btn-lg btn-outline-default">Default</button>
                            <button type="button" class="btn btn-lg btn-outline-success">Success</button>
                            <button type="button" class="btn btn-lg btn-outline-warning">Warning</button>
                            <button type="button" class="btn btn-lg btn-outline-info">Info</button>
                            <button type="button" class="btn btn-lg btn-outline-danger">Danger</button>
                            <button type="button" class="btn btn-lg btn-outline-dark">Dark</button>
                        </p>
                    </div>
                    <h5 class="frame-heading">
                        Default size
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <button type="button" class="btn btn-outline-primary">Primary</button>
                            <button type="button" class="btn btn-outline-secondary">Secondary</button>
                            <button type="button" class="btn btn-outline-default">Default</button>
                            <button type="button" class="btn btn-outline-success">Success</button>
                            <button type="button" class="btn btn-outline-warning">Warning</button>
                            <button type="button" class="btn btn-outline-info">Info</button>
                            <button type="button" class="btn btn-outline-danger">Danger</button>
                            <button type="button" class="btn btn-outline-dark">Dark</button>
                        </p>
                    </div>
                    <h5 class="frame-heading">
                        Small size
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <button type="button" class="btn btn-sm btn-outline-primary">Primary</button>
                            <button type="button" class="btn btn-sm btn-outline-secondary">Secondary</button>
                            <button type="button" class="btn btn-sm btn-outline-default">Default</button>
                            <button type="button" class="btn btn-sm btn-outline-success">Success</button>
                            <button type="button" class="btn btn-sm btn-outline-warning">Warning</button>
                            <button type="button" class="btn btn-sm btn-outline-info">Info</button>
                            <button type="button" class="btn btn-sm btn-outline-danger">Danger</button>
                            <button type="button" class="btn btn-sm btn-outline-dark">Dark</button>
                        </p>
                    </div>
                    <h5 class="frame-heading">
                        Smallest size
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <button type="button" class="btn btn-xs btn-outline-primary">Primary</button>
                            <button type="button" class="btn btn-xs btn-outline-secondary">Secondary</button>
                            <button type="button" class="btn btn-xs btn-outline-default">Default</button>
                            <button type="button" class="btn btn-xs btn-outline-success">Success</button>
                            <button type="button" class="btn btn-xs btn-outline-warning">Warning</button>
                            <button type="button" class="btn btn-xs btn-outline-info">Info</button>
                            <button type="button" class="btn btn-xs btn-outline-danger">Danger</button>
                            <button type="button" class="btn btn-xs btn-outline-dark">Dark</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--With icons (outline)-->
        <div id="panel-9" class="panel">
            <div class="panel-hdr">
                <h2>
                    Button <span class="fw-300"><i>with Icons</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        We use the icon tags to insert icons into buttons
                    </div>
                    <div class="demo">
                        <button type="button" class="btn btn-lg btn-outline-primary">
                                        <span class="fal fa-print mr-1"></span>
                                        Print
                                    </button>
                        <button type="button" class="btn btn-lg btn-outline-secondary">
                                        <span class="fal fa-volume-mute mr-1"></span>
                                        Mute
                                    </button>
                        <button type="button" class="btn btn-lg btn-outline-default">
                                        <span class="fal fa-check mr-1"></span>
                                        Submit
                                    </button>
                        <button type="button" class="btn btn-lg btn-outline-success">
                                        <span class="fal fa-download mr-1"></span>
                                        Download
                                    </button>
                        <button type="button" class="btn btn-lg btn-outline-warning">
                                        <span class="fal fa-exclamation-triangle mr-1"></span>
                                        Scan Device
                                    </button>
                        <button type="button" class="btn btn-lg btn-outline-info">
                                        <span class="fal fa-bug mr-1"></span>
                                        Report Bug
                                    </button>
                        <button type="button" class="btn btn-lg btn-outline-danger">
                                        <span class="fal fa-times mr-1"></span>
                                        Delete
                                    </button>
                        <button type="button" class="btn btn-lg btn-outline-dark">
                                        <span class="fal  fa-eject mr-1"></span>
                                        Eject
                                    </button>
                    </div>
                </div>
            </div>
        </div>
        <!--Icon buttons (outline)-->
        <div id="panel-10" class="panel">
            <div class="panel-hdr">
                <h2>
                    Icon <span class="fw-300"><i>Outlined</i></span>
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Convert button to an icon button by adding the class <code>.btn-icon</code>
                    </div>
                    <h5 class="frame-heading">
                        Various sizes
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-lg btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-lg btn-icon">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-lg btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-lg btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-lg btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-lg btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-lg btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-lg btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-icon">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-sm btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-sm btn-icon">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-sm btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-sm btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-sm btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-sm btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-sm btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-sm btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-xs btn-icon">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-xs btn-icon">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-xs btn-icon">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-xs btn-icon">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-xs btn-icon">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-xs btn-icon">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-xs btn-icon">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-xs btn-icon">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                    </div>
                    <h5 class="frame-heading">
                        Rounded outline
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-lg btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-lg btn-icon rounded-circle">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-lg btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-lg btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-lg btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-lg btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-lg btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-lg btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-icon rounded-circle">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-sm btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-sm btn-icon rounded-circle">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-sm btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-sm btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-sm btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-sm btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-sm btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-sm btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-xs btn-icon rounded-circle">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-xs btn-icon rounded-circle">
                                <i class="fal fa-volume-mute"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-default btn-xs btn-icon rounded-circle">
                                <i class="fal fa-check"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-xs btn-icon rounded-circle">
                                <i class="fal fa-download"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-warning btn-xs btn-icon rounded-circle">
                                <i class="fal fa-exclamation-triangle"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-info btn-xs btn-icon rounded-circle">
                                <i class="fal fa-bug"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-danger btn-xs btn-icon rounded-circle">
                                <i class="fal fa-times"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-dark btn-xs btn-icon rounded-circle">
                                <i class="fal fa-eject"></i>
                            </a>
                        </p>
                    </div>
                    <h5 class="frame-heading">
                        Hover dot effect
                    </h5>
                    <div class="frame-wrap">
                        <p class="demo">
                            <a href="javascript:void(0);" class="btn btn-outline-primary btn-lg btn-icon rounded-circle hover-effect-dot">
                                <i class="fal fa-print"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-secondary btn-lg btn-icon rounded-circle hover-effect-dot">
                                <i class="fal fa-volume-mute fs-md"></i>
                            </a>
                            <a href="javascript:void(0);" class="btn btn-outline-success btn-lg btn-icon hover-effect-dot">
                                <i class="fal fa-download"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>