import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingAPIPath } from '@app/core/_Constants/apiConstants';
import { HttpService } from '@app/core/_Services';
import { observable, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

    constructor(private http: HttpClient,
    private _apiService: HttpService,
      
      ) {
   }


  
  // getShipmentData(): Observable<any>{
    
  //  return this._apiService.getPlan();
  // }



  getPlan(): Promise<any> {
    var cin = environment.endpoint_planner+"/planning/planner/plan"
    var brain = "https://tms.360brain.co/planning/planner/plan?id=1"
    return fetch(cin, {
      method: 'GET',
     
    });
  }
  getPlanFilter(filter): Observable<any>{
    return this._apiService.postWithoutException(
     "/Scheduler/getPlanFilter",
     filter
    );
  }

  getSlot(client_id,bookingNo): Observable<any>{
    let req = {
      client_id:client_id,
      booking_no:bookingNo
    }

    return this._apiService.postWithoutException(
      "/Scheduler/getSlots",
      req
     );
  }

  getQueue(shipmentId): Promise<any> {
    const queue = environment.queueEndpoint + "/queue/shipment/getShipmentRegisterStatus?shipmentId=" + shipmentId;
    return fetch(queue, {
      method: 'GET',
    }).then(response => response.json());
  }



  saveShipments(shipments): Observable<any> {
    console.log(shipments);
    var request = {
      shipments:shipments
    }
    return this._apiService.postWithoutException(
      "/Scheduler/saveShipments",
      request
     );
  }

   AddPlan(order): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  '',
        Authorization: null,
      })
    };
    // console.log(this.http.post("https://tms.360brain.co/planning/",order))

   return this.http.post("https://tms.360brain.co/planning/planner/addOrder",order,httpOptions)

  }

  AddPlan3(order): Observable<any> {

      var ss = JSON.stringify(order);
      console.log('booking', ss);
      return this._apiService.postWithoutException(
       "/Scheduler/AddOrder",
       order
      );
    }

  
    PinTask(order): Observable<any> {

      var ss = JSON.stringify(order);
      console.log('booking', ss);
      return this._apiService.postWithoutException(
       "/Scheduler/pinTask",
       order
      );
    }


  async AddPlan2(order): Promise<any> {

    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', '*/*');
  
    headers.append('Access-Control-Allow-Origin', 'No');
    headers.append('Access-Control-Allow-Credentials', 'true');
  
    
     console.log(order);
     console.log(JSON.stringify(order));
 
     const res = await fetch("https://tms.360brain.co/planning/planner/addOrder", {
     
        // Adding method type
        method: 'POST',
        headers: headers,
        mode: 'no-cors',
        body: JSON.stringify(order),
        // credentials: 'include',
    })
    return res;
  }


  async getShipmentPlan(client_id): Promise<any> {

    var cin = environment.endpoint_planner+"/planning/planner/getShipmentPlan?clientId=" + client_id;
    var brain = "https://tms.360brain.co/planning/planner/plan?id=1"


    return fetch(cin, {
      method: 'GET',
     
    });
  }
  
  async getShipmentByPlan(planId): Promise<any> {
    var date = new Date();
    var dateLocal = date.toLocaleString();
    var dateStrign = dateLocal.slice(0,10);
    console.log(dateStrign);
    var cin = environment.endpoint_planner+"/planning/planner/getPlanByPlanId?id=" + planId +"&date=" + dateStrign;
    var brain = "https://tms.360brain.co/planning/planner/plan?id=1"

    return fetch(cin, {
      method: 'GET',
     
    });
  }
   
 
}
