import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-danger',
  templateUrl: './modal-danger.component.html',
  styleUrls: ['./modal-danger.component.scss']
})
export class ModalDangerComponent implements OnInit {


  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() body_title: string = '';
  @Input() body_text: string = '';
  @Input() buttonSubmitText: string = 'ตกลง';
  @Output() modalReference!: NgbModalRef;
  @Output() btnModalDangerEvent = new EventEmitter<any>();

  @ViewChild("modalDanger", { static: true }) modalDanger!: TemplateRef<any>;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string) {
    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalDanger, { size: size, centered: true });
    }
    else {
      this.modalReference = this.modalService.open(this.modalDanger, { centered: true });
    }
  }

  btnsubmit_click() {
    this.modalReference.close();
    this.btnModalDangerEvent.emit();
  }


}
