import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TstImageItemModel } from '@app/core/_Models/TstImageItemModel';
import { ModalConfirmComponent } from '@app/shared/Components/modal-confirm/modal-confirm.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserBookbankForm, UserBookbankResponse } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';
import { AuthenticationService } from '@app/core/_Services';

@Component({
  selector: 'app-modal-account-form',
  templateUrl: './modal-account-form.component.html',
  styleUrls: ['./modal-account-form.component.scss']
})
export class ModalAccountFormComponent implements OnInit, OnChanges {

  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() modalTitle: string;
  @Input() driver_id: number
  @Input() action = "CREATE"
  @Input() trainingImage: TstImageItemModel[] = [];

  @Output() modalReference!: NgbModalRef;
  @Input() userBookbank: UserBookbankResponse = new UserBookbankResponse();
  @Output() btnsubmit_event = new EventEmitter<any>();
  @Output() btnother_click = new EventEmitter<UserBookbankResponse>();

  banks: any;
  selectedBank: number | undefined;

  accountForm: FormGroup;

  trainingNewImage: File[] = [];
  deleteImageItem: TstImageItemModel = null;

  @ViewChild("modalaccountform", { static: true }) modalaccountform!: ModalAccountFormComponent;
  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;
  @ViewChild("modalConfirm", { static: true }) modalConfirm!: ModalConfirmComponent;

  constructor(private modalService: NgbModal, private driverDetailService: DriverDetailService, private fb: FormBuilder, private changeRef: ChangeDetectorRef,
    private authen: AuthenticationService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // this.changeRef.detectChanges();
    this.formInit();
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string) {

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalaccountform, {
        size: size,
        centered: true,
        backdrop: 'static'
      });
    } else {
      this.modalReference = this.modalService.open(this.modalaccountform, {
        centered: true,
        backdrop: 'static'
      });
    }

    this.trainingImage = []
    this.trainingNewImage = []
    this.getBankName();
    this.formInit();
  }

  async getBankName() {
    await this.driverDetailService
      .getBankName()
      .subscribe((res) => {
        console.log("getBankName", res.data);
        this.banks = res.data
      });
  }

  formInit() {

    this.accountForm = this.fb.group({
      bankName_ID: new FormControl(this.userBookbank?.bankName_ID, [Validators.required]),
      accountNo: new FormControl(this.userBookbank?.accountNo, [Validators.required]),
      accountName: new FormControl(this.userBookbank?.accountName, [Validators.required]),
      bankBranch: new FormControl(this.userBookbank?.bankBranch, [Validators.required]),
      defaultFlag: new FormControl(true, [Validators.required])
    });

    // expireDate: new FormControl(null, [Validators.required])

    this.selectedBank = this.userBookbank?.bankName_ID;

    const image =
      {
        ImageId: this.userBookbank?.id,
        ImageUrl: this.userBookbank?.filePath,
        ImageIsNew: false,
        ImageExtension: `.${(this.userBookbank?.filePath?.split('.'))?.splice(-1)?.pop()}`  //

      } as TstImageItemModel;


    this.trainingImage.push(image)
  }

  onSubmit() {
    console.log("this.userBookbank", this.userBookbank);

    let userBookbankForm = new UserBookbankForm()
    switch (this.action) {
      case 'CREATE':
        userBookbankForm = this.accountForm.value
        userBookbankForm.id = this.userBookbank.id
        userBookbankForm.user_ID = this.driver_id
        userBookbankForm.tst360_CompanyID = this.authen.currentUserValue.tstS360_CompanyID

        if (this.trainingNewImage.length > 1) {
          userBookbankForm.bookbankDoc = []
        }
        else {
          userBookbankForm.bookbankDoc = this.trainingNewImage
        }

        console.log("userBookbankForm", userBookbankForm);

        this.driverDetailService.createUserBookbank(userBookbankForm)
          .subscribe((res: any) => {
            if (res.isSuccess) {
              console.log("res", res);
              this.openmodalsuccess();
              this.modalReference.close();
            }
          });
        break;

      case 'EDIT':
        userBookbankForm = new UserBookbankForm()
        userBookbankForm = this.accountForm.value
        userBookbankForm.user_ID = this.driver_id
        userBookbankForm.id = this.userBookbank.id
        userBookbankForm.tst360_CompanyID = this.authen.currentUserValue.tstS360_CompanyID

        if (this.trainingNewImage.length > 1) {
          userBookbankForm.bookbankDoc = []
        }
        else {
          userBookbankForm.bookbankDoc = this.trainingNewImage
        }

        console.log("userBookbankForm", userBookbankForm);

        this.driverDetailService.updateUserBookbank(userBookbankForm)
          .subscribe((res: any) => {
            if (res.isSuccess) {
              console.log("res", res);
              this.openmodalsuccess();
              this.modalReference.close();
            }
          });
        break;

      default:
        break;
    }
  }

  openmodalsuccess() {
    this.modalsuccess.modalsize = undefined;
    this.modalsuccess.body_title = 'แก้ไขเรียบร้อย';
    this.modalsuccess.btnother_text = 'ปิด';
    this.modalsuccess.openmodal(this.modalsuccess.modalsize);
  }

  success_other(value: any) {
    console.log("value", value);
    this.modalsuccess.modalReference.close();
    this.modalReference.close();
    this.btnother_click.emit();
  }

  ngxdropzoneimage_change(event: any) {
    // debugger;
    // console.log('ngxdropzoneimage_change', event);
    this.trainingNewImage.push(...event.addedFiles);
  }

  ngxdropzoneimage_remove(f: File) {
    this.trainingNewImage.splice(this.trainingNewImage.indexOf(f), 1);
  }

  ngxdropzoneimage_old_delete(item: TstImageItemModel) {
    this.deleteImageItem = item;
    const modalConfirmConfig = {
      title: 'ลบข้อมูล',
      description: 'คุณต้องการจะลบออกจากระบบใช่ หรือ ไม่',
      iconClassName: 'fas fa-times text-danger',
      buttonList: [
        { text: 'ใช่ ฉันต้องการลบ', class: 'btn-danger', comfirm: true },
        { text: 'ยกเลิก', class: 'btn-outline-secondary', comfirm: false },
      ],
    };
    this.modalConfirm.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirm.open('sm');
    modalRef.closed.subscribe((comfirm: boolean) => {
      if (comfirm) {
        this.trainingImage.splice(this.trainingImage.indexOf(item), 1);

        // this.deleteTrainingFile(item.ImageId);

      }
      this.deleteImageItem = null;
    })
  }
}
