import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserChangePassword } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';

@Component({
  selector: 'app-modalchangepassword',
  templateUrl: './modalchangepassword.component.html',
  styleUrls: ['./modalchangepassword.component.scss']
})
export class ModalchangepasswordComponent implements OnInit {
  @ViewChild('modalchangepassword', { static: true }) modalchangepassword!:ModalchangepasswordComponent
  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;

  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() body_title: string = '';
  @Input() body_text: string = '';

  @Output() modalReference!: NgbModalRef;
  @Output() btnsubmit_event = new EventEmitter<any>();

  checkPasswordForm = new FormGroup({
    password: new FormControl(null),
  });

  changePasswordForm = new FormGroup({
    newPassword: new FormControl('',[Validators.required,Validators.minLength(8)]),
    confirmPassword: new FormControl('',[Validators.required,Validators.minLength(8)]),
  });

  isOldPassword: boolean = false;
  passwordMessageError: string | undefined;
  passwordMessageError2: string = "รหัสไม่ตรงกัน"
  userItem:any;
  currentUser: User;

  @ViewChild(ModalsuccessComponent)
  modalsuccessComponent!: ModalsuccessComponent;

  constructor(private modalService: NgbModal,
    private userService: DriverDetailService,
    // private pageload: PageloadingService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    })
  }

  ngOnInit(): void {
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string) {

    this.isOldPassword = false;
    this.passwordMessageError = undefined;
    this.checkPasswordForm.controls.password.setValue(undefined)
    this.changePasswordForm.controls.newPassword.setValue(undefined)
    this.changePasswordForm.controls.confirmPassword.setValue(undefined)

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(
        this.modalchangepassword,
        { size: size, centered: true }
      );
    } else {
      this.modalReference = this.modalService.open(
        this.modalchangepassword,
        { centered: true }
      );
    }
  }

  btnsubmit_click() {
  }

  // async checkpassword(){
  //   this.isOldPassword = false
  //   await this.userService
  //   .checkpassword(this.userItem.user_ID
  //     ,this.checkPasswordForm.value.password
  //   ).subscribe((res:any)=>{

  //     console.log("before send api",res);

  //     if(res.isSuccess){
  //       console.log("LOGIN SUCCESS",res);
  //       this.passwordMessageError = ''
  //       this.isOldPassword = true
  //     }
  //     else{
  //       console.log("LOGIN UNSUCCESS",res);

  //       this.passwordMessageError = 'รหัสผ่านเดิมไม่ถูกต้อง'
  //       this.isOldPassword = false
  //     }
  //   },
  //   error => {
  //     console.log('error',error);

  //     this.passwordMessageError = 'รหัสผ่านเดิมไม่ถูกต้อง'
  //     this.isOldPassword = false
  //   } )
  // }

  async checkpassword(){
    this.isOldPassword = false
    //this.pageload.show()
    await this.userService
    .checkOldPassword(this.checkPasswordForm.value.password).subscribe((res:any)=>{
      if(res.isSuccess)
      // success api response not content
      // if check password pass api response 200 only
      //else response 400 only go to error section
      this.passwordMessageError = ''
      this.isOldPassword = true
      //this.pageload.hide()
    },
    error => {
      this.passwordMessageError = 'รหัสผ่านเดิมไม่ถูกต้อง'
      this.isOldPassword = false
      //this.pageload.hide()
    } )
  }

  async changepassword(){

    const newpassword = this.changePasswordForm.value.newPassword;
    const confirmPassword = this.changePasswordForm.value.confirmPassword;
    if (newpassword !== confirmPassword) {
      this.changePasswordForm.controls.confirmPassword.setErrors({
        notMatching: true,
      });
      return;
    }
      console.log(this.userItem)
      let userchangePassword = new UserChangePassword()

      userchangePassword.userPoolEnum = 1
      userchangePassword.appPackageID = 1
      userchangePassword.userID = this.userItem.tst360UserId ?  this.userItem.tst360UserId :this.userItem.user_ID;
      userchangePassword.username = this.userItem.mobileNo
      userchangePassword.newPassword = this.changePasswordForm.value.newPassword
      userchangePassword.confirmPassword = this.changePasswordForm.value.confirmPassword
      userchangePassword.resetTypeEnum = 4
      userchangePassword.oldPassword = this.checkPasswordForm.value.password

      await this.userService
      .confirmChangePassword(this.currentUser.tstS360_UserID!,userchangePassword).subscribe((res:any)=>{
        if(res.isSuccess){
          console.log("change success",res);
          this.openmodalsuccess();
        }
        else{
          console.log("change false",res);
        }
      },error=>{
        console.log('error',error.data);

        this.passwordMessageError = error
      })
  }

  openmodalsuccess() {
    this.modalsuccessComponent.modalsize = undefined;
    this.modalsuccessComponent.body_title = 'เปลี่ยนรหัสผ่านเรียบร้อย';
    this.modalsuccessComponent.btnother_text = 'ปิด';
    this.modalsuccessComponent.openmodal(this.modalsuccessComponent.modalsize);
  }

  success_other(value: any) {
    console.log("test");
    this.modalsuccessComponent.modalReference.close();
    this.modalReference.close();
    this.btnsubmit_event.emit();
  }
}
