import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[numberWithTwoDecimal]',
})
export class NumberWithTwoDecimalDirective {
  constructor(private el: ElementRef, private ngModel: NgModel) {}

  ngOnInit() {
    this.ngModel.valueChanges.subscribe((value) => {
      if (typeof value === 'number') {
        this.processValue(value);
      }
    });
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const parseValue = parseFloat(value);
    this.processValue(parseValue);
  }

  processValue(value: number): void {
    if (isNaN(value)) {
      return this.updateInput(0);
    }
    if (value.toString().includes('.')) {
      const splitValue = value.toString().split('.');
      if (splitValue[1].length > 2) {
        const newValue = splitValue[0] + '.' + splitValue[1].substring(0, 2);

        this.updateInput(parseFloat(newValue));
      }
    }
  }

  updateInput(newValue: number): void {
    this.el.nativeElement.value = newValue;
    this.ngModel.update.emit(newValue);
  }
}
