import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserChangeEmail } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';

@Component({
  selector: 'app-modalchangeemail',
  templateUrl: './modalchangeemail.component.html',
  styleUrls: ['./modalchangeemail.component.scss'],
})
export class ModalchangeemailComponent implements OnInit {
  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() body_title: string = '';
  @Input() userItem: any;

  @Output() modalReference!: NgbModalRef;
  @Output() onCallGetDriver = new EventEmitter<any>();
  @Output() newEmail: string | undefined;

  changeEmailForm = new FormGroup({
    oldemail: new FormControl({ value: null, disabled: true }),
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  isUsedEmail: boolean = false;
  emailMessageError: string | undefined;
  email: string | undefined;
  // identityUserId: string | undefined;

  @ViewChild('modalchangeemail', { static: true }) modalchangeemail!: ModalchangeemailComponent
  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;


  constructor(
    private modalService: NgbModal,
    private userService: DriverDetailService,
  ) { }

  ngOnInit(): void { }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string): NgbModalRef {
    this.isUsedEmail = false
    this.emailMessageError = undefined
    this.email = undefined

    this.changeEmailForm.controls.email.setValue(undefined);
    this.changeEmailForm.controls.oldemail.setValue(this.userItem.email);

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalchangeemail, {
        size: size,
        centered: true,
      });
    } else {
      this.modalReference = this.modalService.open(this.modalchangeemail, {
        centered: true,
      });
    }
    return this.modalReference;
  }

  async btnsubmit_click() {

    if (this.changeEmailForm.valid) {
      if (this.email != '') {
        let email = this.email;
        await this.userService.checkCanUseEmail(email!).subscribe(async (res: any) => {
          if (res.isSuccess) {
            this.isUsedEmail = res.isSuccess;
            console.log('res success', res);
            await this.changeEmail();
          } else {
            this.isUsedEmail = res.isSuccess;

            if (res.message.indexOf('Invalid Email format') != -1) {
              this.emailMessageError = 'กรอกอีเมลไม่ถูกต้อง';
            } else if (res.message.indexOf('already exists') != -1) {
              this.emailMessageError = 'อีเมลนี้ได้ถูกใช้งานไปแล้ว';
            }
            console.log('res failed', res);
          }
          console.log('checkUsedMobileNo else res', res);
        });
      } else {
        this.emailMessageError = '';
      }


    }

  }

  checkUsedEmail() {
    console.log('this.changeEmailForm.valid',this.changeEmailForm.valid);

    this.isUsedEmail = this.changeEmailForm.valid
    this.email = this.changeEmailForm.value.email;
    this.emailMessageError = !this.isUsedEmail ? 'กรอกอีเมลไม่ถูกต้อง' : '';

  }

  async changeEmail() {
    let userChangeEmail = new UserChangeEmail();
    await this.userService
    .getUserIdentityId(this.userItem.user_ID!)
    .subscribe((res: any) => {
      console.log('getUserIdentityId', res);
      if (res.isSuccess) {

        userChangeEmail.identityUserId = res.data;
        userChangeEmail.appPackageID = 1;
        userChangeEmail.userPoolEnum = 1;
        userChangeEmail.newEmail = this.email;
        console.log('userChangeEmail res', userChangeEmail);
        this.userService
          .changeEmailWithNoti(userChangeEmail)
          .subscribe((res: any) => {
            console.log('changeEmailWithNoti', res);

            if (res.isSuccess) {
              this.newEmail = userChangeEmail.newEmail
              this.modalReference.close(true)
              this.onCallGetDriver.emit();
              this.openmodalsuccess();
            }
          });
      }
    });
  }

  openmodalsuccess() {
    this.modalsuccess.modalsize = undefined;
    this.modalsuccess.body_title = 'แก้ไขอีเมลเรียบร้อย';
    this.modalsuccess.body_text = `คุณได้เปลี่ยนอีเมลจาก ${this.userItem.email} เป็น ${this.newEmail} เรียบร้อยแล้ว`;
    this.modalsuccess.btnother_text = 'ปิด';
    this.modalsuccess.openmodal(this.modalsuccess.modalsize);
  }

  success_other(value: any) {
    console.log("test");
    this.modalsuccess.modalReference.close();
    this.modalReference.close();
  }
}
