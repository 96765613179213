export class TruckBookingModel {
    clientId:number ;
    bookingNo:string;
    truckBookingNo:string;
    truckBookingId: number;
    truckBookingStatusId: number;
    ownner:Owner = new Owner();
    customer:Customer[] = new Array();
    client:Client[] = new Array();
    locations:BookingLocation[] = new Array();
    trucks:Truck = new Truck();
    createdBy:number;
    targetPay:TargetPrice;
    targetReceive:TargetPrice;
    truckTypeIds: number[] = new Array();
    documentReturn:DocumentReturn;
    refTruckbookingOrigin:RefTruckbookingOrigin;
}
export class Customer {
    customerID: number;
    customerName:string;
    customerNumber:string;
    salesID: number;
    customerOrder:CustomerOrder[] = new Array();
    customerRemark:Remarks[] = new  Array();
}
export class CustomerOrder {
    orderID:number;
    customerId:number;
    ref_no_1:string;
    ref_no_2:string;
    ref_no_3:string;
    items:Item[] = new Array();
}

export class Item {
    amountPerHandling:number;
    clientProductID:number;
    handlingUnitID:number;
    handlingVolume:number;
    itemID:number;
    itemNo: string;
    packagingUnitID:number;
    packagingVolume:number;
    productGroupID:number;
    productID:number;
    productNumber: string;
    productName: null
    productValue:number;
    totalWeight:number;
    weight:number;
    weightPerHandling:number;
    weightPerPackage:number;
    weightUnitID:number;
    handlingPrincipleID:number;
    handlingUnitName:string;
    packagingUnitName:string;
    weightUnitName:string;
    productGroupName:string;
}

export class Owner {
    inChargeID:number;
    inChargeName:string;
    createdBy:number;
}
export class Client{
    customerID:number;
    customerName:string;
    salesID:number;
    salesName:string;
    customerRemark: Remarks[] = new Array();
}
export class BookingLocation {
    clientLocationId:number;
    locationID:number;
    sequence:number;
    shortAddress:string;
    longAddress:string;
    latitude:number;
    longitude:number;
    placeID:string;
    provinceID:number;
    districtID:number;
    needLabor:boolean;
    laborNumber:number;
    contactName:string;
    phoneNumber:string;
    date:Date;
    timeType:number;
    timespan:string;
    timeForm:string;
    timeTo:string;
    dateTimeFrom:Date;
    dateTimeTo:Date;
    isHasTime:boolean;
    actions:Action[] = new Array();
    remarks:Remarks[] = new Array();
    distance:number;
    isDisabled?:boolean;
}

export class Truck{
    truckType:number;
    quantity:number;
}
export class Action{
    actionID
    actionType
    productIDList:[];
    productIdListStr: [];
}
export class Remarks{
    remarkId:number;
    remark:string;
    sendto:[];
}
export class TargetPrice{
    price :number;
    priceUnit :number;
    laborPrice :number;
    laborPriceUnit :number;
    fuelPrice :number;
    fuelPriceUnit :number;
}

export class DocumentReturn{
    docReturnAddress:string;
    docReturnPhoneNumber: string;
    docReturnRecipientName: string;
    isDocReturnRequired : boolean;
}
export interface RefTruckbookingOrigin {
    clientId: number;
  
  }