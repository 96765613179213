export class UpdateClientUserDto {
    public CompanyModel: CompanyModel;
}
export class CompanyModel {
    public CompanyNameTH: string;
    public CompanyNameEN: string;
    public typeNameCompany: string;
    public typeCompanyID: number;
    public typeNameProduct: string;
    public typeProductID: [];
    public Email: string;
    public phone: string;
    public Id: number;
}