import { Component, OnInit } from '@angular/core';
import { CryptoJsService } from "@app/core/_Services/crypto-js.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService, NotificationService, PageloadingService } from '@app/core/_Services';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  tokenValue: any;
  constructor(
    private cryto: CryptoJsService,
    private route: ActivatedRoute,
    private _apiService: HttpService,
    private router: Router,
    private _noti: NotificationService,

  ) {
  }
  UserString: string;
  TokenString: string;
  PasswordGroup = new FormGroup({
    Passwordnew: new FormControl(null, Validators.required),
    Passwordconfirm: new FormControl(null, Validators.required),
  });
  onsaveing:boolean =false;


  resetPasswordForm = new FormGroup({
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  otpResetData: any | undefined;
  tstuserToken: any | undefined;
  tstuser: any | undefined;
  tstuserEmail: any | undefined;
  isFormsubmit = false;
  isTokenExpiry = false;


  ngOnInit(): void {
    // this.route.queryParams.subscribe(qtyUrl => {
    //   if (qtyUrl) {
    //     const user = qtyUrl['tstuser'];
    //     const token = qtyUrl['token'];
    //     this.UserString = user;
    //     this.TokenString = token;
    //     if(this.TokenString == null || this.TokenString == "")
    //     {
    //       this._noti.toasterror('', "ไม่สามารถทำรายการได้");
    //       this.router.navigate(['/login']);
    //     }
    //     // this.tokenValue = this.cryto.decrypt(token);
    //   }
    // });

    const otp = sessionStorage.getItem('rpwotp');
    if (otp) {
      this.otpResetData = JSON.parse(otp);
    }
    this.route.queryParams.subscribe((params) => {

      const emailQueryParam: string = params['email'];
      if (emailQueryParam) {
        this.tstuserEmail = decodeURIComponent(emailQueryParam);
      }
      const tstuserTokenQueryParam: string = params['token'];
      if (tstuserTokenQueryParam) {
        this.tstuserToken = tstuserTokenQueryParam;
        this.otpResetData = undefined;
      }
      const expQueryParam: number = params['exp'];
      if (expQueryParam) {
       const exp =  this.calculateTokenExp(expQueryParam);
       if(exp <= 0){
        this.isTokenExpiry = true;
       }
      }
      const tstuserQueryParam: string = params['tstuser'];
      if (tstuserQueryParam) {
        this.tstuser = tstuserQueryParam;
        this.otpResetData = undefined;
      }
    });
  }
  ngAfterViewInit() {
    $('body').removeAttr('class');
  }
  onSubmit() {
    this.onsaveing =true;
     if(this.PasswordGroup.value.Passwordnew != this.PasswordGroup.value.Passwordconfirm)
     {
      this._noti.toasterror('', "รหัสผ่านยืนยันไม่ถูกต้อง");
      this.onsaveing =false;
      return;
     }


    let resetValue = {
      Passwordnew: this.PasswordGroup.value.Passwordnew,
      Passwordconfirm: this.PasswordGroup.value.Passwordconfirm,
      UserString: this.UserString,
      TokenString: this.TokenString
    }


    this.ResetpasswordAPI(resetValue).subscribe
    (
      (data)=> {
        if(data?.isSuccess)
        {
          this._noti.toastsuccess('', "เปลี่ยนรหัสผ่านสำเร็จ");
          this.router.navigate(['/login']);
          this.onsaveing =false;
        }else
        {
          this._noti.toasterror('', data?.message);
          this.onsaveing =false;
        }
      }
    );
  }

  ResetpasswordAPI(data: any): Observable<any> {
    return this._apiService.post(`/Users/Resetpassword`, data);
  }


  onReset() {
    const newpassword = this.resetPasswordForm.value.newPassword;
    const confirmPassword = this.resetPasswordForm.value.confirmPassword;
    if (newpassword !== confirmPassword) {
      this.resetPasswordForm.controls.confirmPassword.setErrors({
        notMatching: true,
      });
      return;
    }

    let ResetPasswordFormModel: any;
    if (this.otpResetData) {
      ResetPasswordFormModel = {
        userId: 0,
        tstUser:null,
        resetpasswordDto: {
          userPoolEnum: 1,
          appPackageID: 1,
          userID: 0,
          username: this.otpResetData?.key,
          newPassword: newpassword,
          confirmPassword: confirmPassword,
          resetTypeEnum: 2,
          oldPassword: null,
          resetToken: this.otpResetData?.resetToken,
        },
      };
    } else if (this.tstuserToken) {
      ResetPasswordFormModel = {
        userId: 0,
        tstUser: this.tstuser,
        resetpasswordDto: {
          userPoolEnum: 1,
          appPackageID: 1,
          userID: 0,
          username: this.tstuserEmail,
          newPassword: newpassword,
          confirmPassword: confirmPassword,
          resetTypeEnum: 2,
          oldPassword: null,
          resetToken: this.tstuserToken
        },
      };
    }

    this._apiService
      .post360v3('/User/ResetPassword', ResetPasswordFormModel)
      .subscribe((res: any) => {
        this.isFormsubmit = false;

        if (res?.isSuccess) {
          this._noti.toastsuccess(
            'เปลี่ยนรหัสผ่านเรียบร้อย',
            'กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
          );
          this.router.navigate(['/']);
        } else {
          this._noti.toasterror(
            'ไม่สามารถเปลี่ยนรหัสผ่านได้',
            'กรุณาลองใหม่อีกครั้ง'
          );
        }
      });
  }

  calculateTokenExp(ticks:any):number{
    //ticks are in nanotime; convert to microtime
    const ticksToMicrotime = ticks / 10000;

    //ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
    const epochMicrotimeDiff = 2208988800000;
    //new date is ticks, converted to microtime, minus difference from epoch microtime
    const tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);
    //UTC Date
    const yyyy = new Date().getFullYear()
    const mm = new Date().getMonth()+1;
    const dd = new Date().getDate();
    const hh = tickDate.getUTCHours();
    const min = tickDate.getUTCMinutes();
    const expdate  = new Date(yyyy,mm,dd,hh,min);
    // now calculate
    const now = new Date();
    const now_yyyy = new Date().getFullYear();
    const now_mm = new Date().getMonth()+1;
    const now_dd = new Date().getDate();
    const now_hh = now.getHours();
    const now_min = now.getMinutes();
    const now_cal = new  Date(now_yyyy,now_mm,now_dd,now_hh,now_min);

    const diffNow =  moment(now_cal);
    const diifExp = moment(expdate);
    const datedifferance =  diifExp.diff(diffNow,'minute');

    return datedifferance;


  }

}
