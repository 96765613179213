<ng-template #modalCopyClipboard let-modal>
  <div class="modal-header">
    <div class="modal-title w-100">
      <button type="button" class="close pt-1 pl-0 pb-0 pr-1" aria-label="Close" data-dismiss="modal"
        (click)="close(false)">
        <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
      </button>
    </div>
  </div>
  <div class="modal-body p-3" id="for-copy" [innerHTML]="elememt.innerHTML">
  </div>
  <div class="p-2">
    <div class="row m-1 text-center">
      <div class="col-6 pl-0 pr-1">
        <button type="button" class="btn btn-outline-secondary waves-effect waves-themed btn-block" data-dismiss="modal"
          (click)="close(false)">ปิดหน้านี้</button>
      </div>
      <div class="col-6 pl-1 pr-0">
        <button type="button" class="btn btn-success waves-effect waves-themed btn-block"
          (click)="copy()">{{buttonText}}</button>
      </div>
    </div>
  </div>
</ng-template>
