import { Injectable } from '@angular/core';
import { UserEventApiPath } from '../_Constants/apiConstants';
import { User } from '../_Models/UserModel/User';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserEventService {
  user : User;
  constructor(   private _apiService: HttpService,
    private authenticationService: AuthenticationService,) {
      this.authenticationService.currentUser.subscribe(a=>{
        this.user = a;
      })
     }

  SaveUserAction(action){
    var request = {
      addItem : {
        UserID: this.user.id,
        Action : action
      }
    }

    this._apiService.postWithoutException(
      UserEventApiPath.AddUserEvent  , request
    ).subscribe((res)=>{

      console.log("res",res);
    }
    );

  }
}
