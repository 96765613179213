import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-timeframe-detail',
  templateUrl: './modal-timeframe-detail.component.html',
  styleUrls: ['./modal-timeframe-detail.component.scss']
})
export class ModalTimeframeDetailComponent implements OnInit {

  constructor() { }
  @Input()data;
  ngOnInit(): void {
    console.log('Modal Timeframe Detail',this.data);
  }

}
