<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <label *ngIf="Data.placeId" class="form-label after-map-select-input" for="txtnamelocation1"
          (click)="openmapWithValue()"><i class="fas fa-map-marked-alt"></i> เลือกสถานที่ </label>
        <!-- <input *ngIf="Data.placeid" type="text" id="txtnamelocation" name="txtnamelocation" class="form-control" placeholder="กรอกชื่อสถานที่" [(ngModel)]="Data.shortaddress" (change)="shortAddressChange()"> -->
        <!-- <input *ngIf="!Data.placeid" type="text" id="txtnamelocation" name="txtnamelocation" class="form-control" placeholder="คลิกเพื่อกำหนดในแผนที่" (click)="openmap()"> -->
        <!-- After Confirm location -->
        <div class="row" *ngIf="Data.placeId">
          <div class="col-12 pl-0 text-dark">
            <h5> <b> {{Data.shortAddress}} </b> </h5>
            <small id="mapHelp1" class="form-text text-muted mx-1" *ngIf="Data.fullAddress">
              {{Data.fullAddress}}</small>
          </div>
        </div>
        <div class="row" *ngIf="Data.contactName && Data.placeId">
          <div class="col-md-auto col-6">
            <i class="fas fa-user-circle txt-green"></i> &nbsp; <span>{{Data.contactName}}</span>
          </div>
          <div class="col-md-auto col-6">
            <i class="fas fa-phone-square-alt  txt-green"></i> &nbsp;  <span>{{Data.contactPhone | mask:'000 000 0000'}}</span>
          </div>
        </div>
        <div class="row" *ngIf="LocRemarks.length > 0 && Data.placeId">
            <div class="col-12" *ngFor="let remark of LocRemarks">
              <div class="row">
                <div  class=" col-12 ">
                  <div class="card">
                    <div class="card-body remark" style="background-color :#e5cb758c">
                      <div class="row">
                        <div class="col-auto">
                          <i class="fas fa-info-circle txt-orange" style="font-size:25px"></i>
                        </div>
                        <div class="col">
                          <span>{{remark.remark}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- <div class="input-group" *ngIf="Data.placeId">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fal fa-map-marker-alt"></i></span>
          </div>
          <input type="search" id="txtnamelocation1" name="txtnamelocation" class="form-control"
            placeholder="กรอกชื่อสถานที่" [(ngModel)]="Data.shortAddress" (change)="shortAddressChange()">
        </div> -->


        <!-- Before Confirm location -->
        <div class="input-group" *ngIf="!Data.placeId">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fal fa-map-marker-alt"></i></span>
          </div>
          <input type="text" id="txtnamelocation2" name="txtnamelocation" [ngClass]="{'invalid': !valid}"
            class="form-control" placeholder="เลือกสถานที่" (click)="openmap()">
        </div>
        <!-- <small id="mapHelp1" class="form-text text-muted mx-1" *ngIf="Data.fullAddress">ที่อยู่จากแผนที่:
          {{Data.fullAddress}}</small>
        <small id="mapHelp2" class="form-text text-muted mx-1" *ngIf="Data.lat">พิกัดแผนที่ : {{Data.lat}},
          {{Data.lng}}</small> -->
      </div>
    </div>

  </div>
</div>

<div class="modal fade" id="{{MapModal}}" tabindex="1" role="dialog" aria-hidden="true" data-keyboard="false" 
  data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">เลือกสถานที่</h5>
        <button type="button" class="close" (click)="closemap()">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="panel-container show p-2">
          <div class="panel-content" [ngClass]="{ 'd-none': currentStep != 2 }">

            <div class="form-group mb-2">
              <label class="form-label">ค้นหาสถานที่</label>
              <input type="search" #searchinput id="mapaddress" class="form-control"
                placeholder="ค้นหาจากชื่อหรือรหัสสถานที่" (keydown)="searchWithTextFn($event)">
            </div>
            <div class="form-group mb-2" >
              <label class="form-label">ชื่อสถานที่</label>
              <input type="text" id="mapaddressShort" [(ngModel)]="tmpLocationData.shortAddress" class="form-control"
                placeholder="ตั้งชื่อสถานที่">
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="">ผู้ติดต่อ</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input type="text" class="form-control" name="" id="" [(ngModel)]="tmpLocationData.contactName" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="">เบอร์โทร</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" name="" id="" mask="000-000-0009"
                      [(ngModel)]="tmpLocationData.contactPhone" placeholder="โปรดกรอกเบอร์โทรศัพท์ 10 หลัก" [ngClass]="{
                        invalid:false
                      }" />
                  </div>
                </div>
              </div>
              
              <div class="col-12" *ngFor="
              let remark of LocRemarks;
              let locindex = index
            ">
            <div class="form-group">
              <div class="row justify-content-between">
                <div class="col-12 col-md-auto">
                    <div class="row">
                        <div class="col-12 col-md-auto mt-2">
                            <label class="form-label pr-3" for="">หมายเหตุ</label>
                        </div>
                        <div class="col-auto mt-2" *ngIf="isNotBasic">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="Toplant{{sequence}}{{locindex}}" [checked]="to1" (change)="Toplant(remark)">
                                <label class="custom-control-label" for="Toplant{{sequence}}{{locindex}}">ส่งถึงโรงงาน</label>
                            </div>
                        </div>
                        <div class="col-auto mt-2" *ngIf="isNotBasic">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="Tosales{{sequence}}{{locindex}}" [checked]="to2" (change)="Tosales(remark)">
                                <label class="custom-control-label" for="Tosales{{sequence}}{{locindex}}">ส่งถึงทีม Sales</label>
                            </div>
                        </div>
                        <div class="col-auto mt-2" *ngIf="isNotBasic">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="ToDriver{{sequence}}{{locindex}}" [checked]="to3" (change)="Todriver(remark)">
                                <label class="custom-control-label" for="ToDriver{{sequence}}{{locindex}}">ส่งถึงคนขับ</label>
                            </div>
                        </div>
            
                    </div>
                </div>
            
            </div>
            <div class="input-group">
                <textarea class="form-control" id="" rows="1" [(ngModel)]="remark.remark"></textarea>
                <div class="input-group-prepend">
                    <div class="input-group-text delete-icon " (click)="removeLocRemark(locindex)">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="col-12" *ngIf="canAddRemark()">
            <span class="pl-3">
              <u>
                <a (click)="addLocRemark()"><i class="fas fa-plus-circle"></i> เพิ่มเติม</a></u>
            </span>
          </div>
         
            </div>
            <div class="custom-control custom-checkbox" *ngIf="FavoriteShow || isMasterViewMap">
              <input type="checkbox" class="custom-control-input" id="Se{{MapModal}}" [(ngModel)]="isCustomer">
              <label class="custom-control-label" for="Se{{MapModal}}"> สถานที่ของลูกค้า</label>
              <ng-select *ngIf="isCustomer" [(ngModel)]="customerId">
                <ng-option *ngFor="let u of customerList" [value]="u.id">{{u.customerName}}
                </ng-option>
              </ng-select>
            </div>
            <div class="collapse mb-3" id="collapseIsFavLocation" *ngIf="FavoriteShow || isMasterViewMap">
              <div class=" border rounded">
                <div class="input-group p-2 mb-0">
                  <input type="text" class="form-control  shadow-inset-2 " id="js-list-msg-filter"
                    placeholder="ค้นหา สถานที่ ที่บันทึก">
                  <div class="input-group-append">
                    <div class="input-group-text ">
                      <i class="fal fa-search fs-xl"></i>
                    </div>
                  </div>
                </div>
                <ul id="js-list-msg" class="list-group px-2 pb-2">
                  <div class="d-flex justify-content-center" *ngIf="loadingLocation">
                    <div class="spinner-grow text-success mt-3 mb-3 ml-3 mr-3" role="status"></div>
                  </div>
                  <li class="list-group-item" [ngClass]="{'backdrop': loadingLocation}"
                    *ngFor="let item of LocationList;"
                    [ngClass]="item.clientLocationId === tmpLocationData.clientLocationId?' list-group-active':''"
                    (click)="onMasterSelected(item)">
                    <a>
                      <h5>{{item.shortAddress}}</h5>
                      <small>{{item.fullAddress}}</small>
                    </a>
                  </li>
                  <div class="row justify-content-center ml-0 mr-0 mt-3"
                    *ngIf="!loadingLocation && (!LocationList || LocationList.length <= 0)">
                    <h5>ไม่พบผลการค้นหา</h5>
                  </div>
                </ul>
                <div class="filter-message js-filter-message mt-0 fs-sm"></div>
              </div>
            </div>
            <div class="form-group">
              <div class="frame-wrap" *ngIf="isLoadMap">
                <div class="p-3" style="border:0;height: 450px;">
                  <div class="d-flex justify-content-center">
                    <strong style="margin-top: 25%;">กำลังโหลด แผนที่...</strong>
                  </div>
                </div>
              </div>
              <div #mapView style="border:0;height: 376px;"></div>
              <!-- <iframe src="https://maps.google.com/maps?q={{lat}},{{lng}}&z=15&output=embed" class="w-100" frameborder="0" style="border:0;height: 450px;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
            </div>
          </div>


          <div class="panel-content" *ngIf="currentStep===1">
            <div class="form-group mb-2">
              <label class="form-label">ค้นหาสถานที่</label>
              <input type="search" id="searchmasterddress" [(ngModel)]="searchText" class="form-control"
                placeholder="ค้นหาจากชื่อหรือรหัสสถานที่" (keyup)="updateKeyText()">
            </div>
            <ul class="list-group px-2 pb-2">
              <div class="d-flex justify-content-center" *ngIf="loadingLocation">
                <div class="spinner-grow text-success mt-3 mb-3 ml-3 mr-3" role="status"></div>
              </div>
              <li class="list-group-item" [ngClass]="{'backdrop': loadingLocation}"
                *ngFor="let item of LocationList;let i = index"
                [ngClass]="item.clientLocationId === tmpLocationData.clientLocationId?' list-group-active':''">
                <div class="row">
                  <div class="col-auto">
                    <i class="fas fa-map-marker-alt" style="font-size:25px"></i>
                  </div>
                  <div class="col">
                    <a>
                      <h5>{{item.locationNumber +' '+item.shortAddress}}</h5>
                      <small>{{item.fullAddress}}</small>
                    </a>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-success" type="button" (click)="onMasterSelected(item)">เลือกสถานที่</button>
                  </div>
                </div>

              </li>
              <div class="row justify-content-center ml-0 mr-0 mt-3"
                *ngIf="!loadingLocation && (!LocationList || LocationList.length <= 0)">
                <h5>ไม่พบผลการค้นหา</h5>
              </div>
            </ul>
            <div class="row">
              <div class="col-12 text-center mt-2">
                <button class="btn btn-success" type="button" (click)="MasterViewMap()">
                  ค้นหาจาก Google Map <i class="fas fa-map-marked-alt"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="panel-content" *ngIf="IsMaster && !isMasterViewMap">

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row w-100 ml-0 mr-0">
          <div class="col-6">
            <ng-container *ngIf="currentStep==2">
              <button class="btn btn-secondary"  type="button" (click)="MasterViewMap()">เลือกจากสถานที่บันทึกไว้</button>
              <!-- data-toggle="collapse" data-target="#collapseIsFavLocation" aria-expanded="false" aria-controls="collapseIsFavLocation" -->
            </ng-container>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-success float-right" (click)="onSubmit()">บันทึก</button>
            <button type="button" class="btn btn-primary float-right mr-3" (click)="closemap()">ยกเลิก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>