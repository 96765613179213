import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-modal-copy-clipboard',
  templateUrl: './modal-copy-clipboard.component.html',
  styleUrls: ['./modal-copy-clipboard.component.scss']
})
export class ModalCopyClipboardComponent implements OnInit {
  @Input() elememt: HTMLElement;
  @Input() buttonText: string = 'คักลอกข้อความ';
  modalReference!: NgbModalRef;
  @ViewChild('modalCopyClipboard') modalCopyClipboard!: ModalCopyClipboardComponent
  constructor(private modalService: NgbModal, private clipboardService: ClipboardService) { }

  ngOnInit() {
  }

  open(size?: 'sm' | 'md' | 'lg' | string): void {
    this.ngOnInit();
    if (size) {
      this.modalReference = this.modalService.open(this.modalCopyClipboard, { size: size, centered: true, backdrop: 'static' })
    } else {
      this.modalReference = this.modalService.open(this.modalCopyClipboard, { size: 'md', centered: true, backdrop: 'static' })
    }
  }

  close(bool): void {
    this.modalReference.close(bool);
  }

  copy(): void {
    const element = document.getElementById('for-copy');
    this.clipboardService.copyFromContent(element.innerText);
  }

}
