import { Injectable } from '@angular/core';
import { CompanyTruckDto } from '@app/modules/truck-license-management/models/company-truck-model';
import { BehaviorSubject } from 'rxjs';
import { MaintenancePhoto, MaintenanceTypeModel, TruckMaintenanceModel } from '../models/truck-maintenance-model';
import { TruckPrintTopicModel, TruckShareTopicModel } from '../models/truck-print-topic-model';
import { CompanyTruckType, FleetAPIPath, FleetXDriverAPIPath, FleetXTruckAPIPath, TruckLocationsTrackingAPIPath } from '@app/core/_Constants/apiConstants';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, HttpService } from '@app/core/_Services';
import { Observable } from 'rxjs';
import { AddCompanyTruckByTruckTypeIdCommand, DriverFleetItem, SaveAndUpdateCompanyTruckTypeCommand, TruckFleet, TruckFleetItem, TruckTypeDto, TruckTypeGroupDto } from '../models/TruckFleet';

@Injectable({
  providedIn: 'root'
})

export class TruckManagementService {

  selectedTopicForPrintOnChange: BehaviorSubject<TruckPrintTopicModel[]>
  comTruckItemOnChange: BehaviorSubject<CompanyTruckDto>;
  selectTruckShareListOnChange: BehaviorSubject<any[]>;
  selectTruckPrintListOnChange: BehaviorSubject<any[]>;
  currentUser: User;

  constructor(    private _apiService: HttpService,
    private authenticationService: AuthenticationService) {

      this.authenticationService.currentUser.subscribe(
        (x) =>{
       (this.currentUser = x)
        console.log(x);
      }
      );
      this.selectTruckShareListOnChange = new BehaviorSubject(selectTruckShareList);
      this.selectTruckPrintListOnChange = new BehaviorSubject(selectTruckPrintList);
      this.selectedTopicForPrintOnChange = new BehaviorSubject(new Array<TruckPrintTopicModel>());
      this.comTruckItemOnChange = new BehaviorSubject(null);
    }

  getCompanyFleet() {

    return this.GetFleetOverAll().subscribe((result) => {
      console.log(result.data);
      var jsonString = JSON.stringify(result.data);
      console.log(jsonString);
    });
  }

  GetFleetOverAll(): Observable<any> {
    var test = {client_id:this.currentUser.ClientID}
    console.log(test);
    return this._apiService.post(
      FleetAPIPath.GetFleetOverAll,{client_id:this.currentUser.ClientID}
    );
  }

  GetCompanyTruckTypeList(): Observable<any>{
    return this._apiService.get(
      CompanyTruckType.GetCompanyTruckType+'/'+this.currentUser.ClientID);
  }

  GetTruckTrackingLocation(fleetNo:number): Observable<any> {
    var filters = {
      client_ID : this.currentUser.ClientID,
      fleet_No : fleetNo
    }
    return this._apiService.post(
      TruckLocationsTrackingAPIPath.GetTruckTrackingLocation,{filter:filters}
    );
  }

  genTruckFleet() {
    const fleetlist = new Array<TruckFleet>();
    for (let fleet = 0; fleet < 3; fleet++) {
      const all = new TruckFleet();
      if (fleet == 0) {
        all.fleetName = "ทั้งหมด";
        all.fleetColor = "black";
        all.fleetTruckSum = 60;
        all.fleetDriverSum = 60;

      } else {
        all.fleetName = "Fleet " + fleet + " ทีมงานสายลุย";
        all.fleetColor = "red";
        all.fleetTruckSum = 20;
        all.fleetDriverSum = 20;


      }
      all.fleetId = fleet;

      for (let i = 0; i < 3; i++) {
        const truckItem = new TruckFleetItem();
        truckItem.truckTypeName = "10 ล้อ พื้นเรียบ";
        truckItem.truckTypeImage = "assets/img/360techx/images/truckicon//33_10w_flatbed.png";
        truckItem.truckTypeId = 33;
        truckItem.trucTypeCount = 10;

        all.truckList.push(truckItem);
      }

      for (var i = 0; i < 10; i++) {
        const driverItem = new DriverFleetItem();

        driverItem.driverId = 1;
        driverItem.driverName = "Somchai Nam";
        driverItem.driverImage = "assets/img/demo/avatars/avatar-m.png";
        driverItem.driverCount = 10;

        all.driverList.push(driverItem);
      }

      fleetlist.push(all);
    }



    return fleetlist;
  }

  getCompanyTruck(fleetNo:number) : Observable<any>{
    var filters = {
      client_ID : this.currentUser.ClientID,
      fleet_No : fleetNo
    }
    return this._apiService.post(
      FleetXTruckAPIPath.GetFleetTruck,{filter:filters}
    );
  }


  getDriverFleet(fleetNo:number): Observable<any> {
    var filters = {
      client_ID : this.currentUser.ClientID,
      fleet_No : fleetNo
    }
    return this._apiService.post(
      FleetXDriverAPIPath.GetFleetDriver,{filter:filters}
    );
  }

  
  getFleetDriverList(fleetNo:number): Observable<any> {
    var filters = {
      client_ID : this.currentUser.ClientID,
      fleet_No : fleetNo
    }
    return this._apiService.post(
      FleetXDriverAPIPath.GetFleetDriverList,{filter:filters}
    );
  }


  getClientFleet() : Observable<any>{
    var filters = {
      client_ID : this.currentUser.ClientID,
      company_ID : this.currentUser.tstS360_CompanyID
    }
    return this._apiService.post(
      FleetAPIPath.GetFleetList,filters
    );
  }

  postClientFleet(fleetItem) : Observable<any>{
    var data = {
      addItem : fleetItem
    }
    return this._apiService.post(
      FleetAPIPath.PostFleet,data
    );
  }

  UpdateCompanyTruckNumber(req: SaveAndUpdateCompanyTruckTypeCommand){
    req.comId = this.currentUser.tstS360_CompanyID;

    console.log(req);
    return this._apiService.patch(
      CompanyTruckType.SaveAndUpdateCompanyTruckType,req
    );
  }

  
  AutoAddCompanyTruck(req: AddCompanyTruckByTruckTypeIdCommand){
    req.comId = this.currentUser.tstS360_CompanyID;

    console.log(req);
    return this._apiService.patch(
      CompanyTruckType.AddCompanyTruckByTruckTypeId,req
    );
  }


  getTruckMaintenanceList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const truckMaintenanceList = [
        {
          commingSoon: true,
          isComplete: false,
          truckMaintenanceId: 1,
          companyTruckId: 1,
          maintenanceDate: '2021-08-27',
          maintenanceDesc: 'รายละเอียดกำหนดการซ่อมบำรุง เป็นรายการกำหนดการ',
          maintenanceType: {
            maintenanceTypeId: 1,
            maintenanceTypeName: 'เปลี่ยนถ่ายน้ำมันเครื่อง',
          } ,
          maintenancePhotos: [
            {
              maintenancePhotoId: 1,
              truckMaintenanceId: 1,
              filePath: 'https://i.pinimg.com/originals/99/fc/a2/99fca2177ea04dde124070afee10c613.png',
              valid: true,
              isNew: false,
              file: null,
            },
            {
              maintenancePhotoId: 2,
              truckMaintenanceId: 1,
              filePath: 'https://i.pinimg.com/originals/99/fc/a2/99fca2177ea04dde124070afee10c613.png',
              valid: true,
              isNew: false,
              file: null,
            }
          ],
        },
        {
          commingSoon: false,
          isComplete: false,
          truckMaintenanceId: 2,
          companyTruckId: 1,
          maintenanceDate: '2021-08-27',
          maintenanceDesc: 'รายละเอียดกำหนดการซ่อมบำรุง เป็นรายการกำหนดการ',
          maintenanceType: {
            maintenanceTypeId: 1,
            maintenanceTypeName: 'เปลี่ยนถ่ายน้ำมันเครื่อง',
          },
          maintenancePhotos: [
            {
              maintenancePhotoId: 3,
              truckMaintenanceId: 1,
              filePath: 'https://i.pinimg.com/originals/99/fc/a2/99fca2177ea04dde124070afee10c613.png',
              valid: true,
              isNew: false,
              file: null,
            },
            {
              maintenancePhotoId: 4,
              truckMaintenanceId: 1,
              filePath: 'https://i.pinimg.com/originals/99/fc/a2/99fca2177ea04dde124070afee10c613.png',
              valid: true,
              isNew: false,
              file: null,
            }
          ],
        },
        {
          commingSoon: false,
          isComplete: true,
          truckMaintenanceId: 1,
          companyTruckId: 1,
          maintenanceDate: '2021-08-27',
          maintenanceDesc: 'รายละเอียดกำหนดการซ่อมบำรุง เป็นรายการกำหนดการ',
          maintenanceType: {
            maintenanceTypeId: 1,
            maintenanceTypeName: 'เปลี่ยนถ่ายน้ำมันเครื่อง',
          } ,
          maintenancePhotos: [
            {
              maintenancePhotoId: 1,
              truckMaintenanceId: 1,
              filePath: 'https://i.pinimg.com/originals/99/fc/a2/99fca2177ea04dde124070afee10c613.png',
              valid: true,
              isNew: false,
              file: null,
            },
            {
              maintenancePhotoId: 2,
              truckMaintenanceId: 1,
              filePath: 'https://i.pinimg.com/originals/99/fc/a2/99fca2177ea04dde124070afee10c613.png',
              valid: true,
              isNew: false,
              file: null,
            }
          ],
        },
      ]

      resolve(truckMaintenanceList);
    })
  }




}


export const selectTruckShareList: TruckShareTopicModel[] = [
  { id: 'all', text: 'เลือกทั้งหมด', selected: false },
  { id: 'license', text: 'ทะเบียน', selected: false },
  { id: 'comtruck', text: 'ข้อมูลรถ', selected: false },
  { id: 'url-internal', text: 'ลิงก์ URL (internal)', selected: false },
  { id: 'insure', text: 'ข้อมูลประกันรถ ประกันสินค้า', selected: false },
];

export const selectTruckPrintList: TruckPrintTopicModel[] = [
  { id: 'all', text: 'เลือกทั้งหมด', selected: false },
  { id: 'license', text: 'ทะเบียน', selected: false },
  { id: 'qrcode', text: 'QR CODE', selected: false },
  { id: 'comtruck', text: 'ข้อมูลรถ', selected: false },
  { id: 'insure', text: 'ข้อมูลประกันรถ ประกันสินค้า', selected: false },
  { id: 'license-book', text: 'ข้อมูลเล่มทะเบียน', selected: false },
  { id: 'insure-car-photo', text: 'รูปภาพประกันรถ', selected: false },
  { id: 'insure-cargo-photo', text: 'รูปภาพประกันสินค้า', selected: false },
  { id: 'truck-license-photo', text: 'รูปภาพเล่มทะเบียน', selected: false },
  { id: 'url', text: 'ลิงก์ URL', selected: false },
];
