<app-breadcrumb></app-breadcrumb>
<div class="row">
    <div class="col-12">
        <div class="card">

            <div class="card-body row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="form-label" for="">ต้นทาง</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-search-location"></i></span>
                            </div>
                            <ng-select style="width: calc(100% - 45px)" [multiple]="true" [(ngModel)]="origin"
                                (change)="onChange()">
                                <ng-option *ngFor="let item of dataCompanylocation" [value]="item.locationShortAddress">
                                    {{item.locationShortAddress}}
                                </ng-option>
                            </ng-select>
                            <!-- <span *ngIf="!customer.get('customerID').valid && submited" class="invalid"> กรุณาเลือกลูกค้า </span> -->
                        </div>
                    </div>
                </div>

                <hr>
                <div class="col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-auto pt-4">
                            <h5> ปฏิธินผลการจ่ายงาน</h5>
                        </div>
                        <div class="col-auto pt-3">
                            <button class="btn " (click)="MoveBack()">
                                <i class="fas fa-angle-left"></i>
                            </button> <span> {{days[0] | thaidate}} - {{days[6] | thaidate}} </span>
                            <button class="btn " (click)="MoveNext()">
                                <i class="fas fa-angle-right"></i>
                            </button>
                        </div>

                        <div class="col-auto">
                            <app-datepicker [(ngModel)]="startDate" (ngModelChange)="onChange()" [valid]="true">
                            </app-datepicker>
                        </div>
                        <div class="col-auto pt-3">
                            <!-- <button class="btn btn-secondary" style="width:155px" (click)="toggleTruckType()">
                                <i [ngClass]="showTruckType ? 'fas fa-eye mr-1':'fas fa-eye-slash mr-1' "></i>
                                <span *ngIf="showTruckType">แสดงประเภทรถ</span>
                                <span *ngIf="!showTruckType">ไม่แสดงประเภทรถ</span>
                            </button> -->

                            <div class="input-group-text mt-1">
                                <div class="custom-control custom-checkbox">
                                    <!-- <input type="radio" id="labor{{j}}" value="1" name="labor{{j}}" (change)="laborchange()"  [checked]="!nolabor" class="custom-control-input"> -->
                                    <input type="checkbox" id="showTruckType" value="true" (change)="toggleTruckType()"
                                        [checked]="showTruckType" class="custom-control-input" />

                                    <label class="custom-control-label" for="showTruckType">
                                        <i class="fas fa-eye mr-1"></i>
                                        แสดงประเภทรถ</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-2 tableFixHead">


                    <table class="table table-bordered table-hover table-striped w-100 table-sm">
                        <thead class="bg-fusion-500">
                            <tr>
                                <th>
                                    รายชื่อขนส่ง\วันที่ส่งสินค้า
                                </th>
                                <th *ngFor="let item of days"> {{item | thaidate: "short" }}</th>
                                <th>Sum Weekly</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of datalist;let i = index;"
                                [ngClass]="{'isCompany':data.name.charAt(0)!='•','d-none':!showTruckType&&data.name.charAt(0)=='•'}">
                                <td [innerHTML]="data.name">

                                </td>
                                <td *ngFor="let num of data.truckcount" class="text-center"
                                    [ngClass]="num>0 ? '' : 'isZero'" style="font-size: 18px;">
                                    {{num}}
                                </td>
                                <td class="bg-fusion-100 text-center"
                                    [ngClass]="getSumTruck(data.truckcount)>0 ? '' : 'isZero'" style="font-size: 18px;">
                                    <u>
                                        {{getSumTruck(data.truckcount)}}
                                    </u>
                                </td>
                            </tr>

                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    </div>
</div>