import {MatchRouteModel} from "@app/modules/route-diagram/models/match-route";

export class OriNodeDataModel {
  name:string;
  factory:string;
  status:string;
  totalRoute:number;
  completedRoute:number;
  lateRoute:number;
  pendingRoute:number;
  defectRoute:number;
  defectBookingNo:string[];
  lateBookingNo:string[];
  pendingBookingNo:string[];
  inProgressBookingNo:string[];
  completedBookingNo:string[];
  lateShipments:string[];
  totalShipment:number;
  totalLateShipment:number;
  totalNormalShipment:number;
  totalCompletedShipment:number;
  locationId:number;
  placeId:string;
  shipmentId:number;
  typeName = 'OriNodeDataModel';
}

export class MiddleNodeDataModel{
  name:string;
  factory:string;
  status:string;
  completedRoute:number;
  lateRoute:number;
  pendingRoute:number;
  defectRoute:number;
  totalRoute:number;
  defectBookingNo:string[];
  lateBookingNo:string[];
  pendingBookingNo:string[];
  inProgressBookingNo:string[];
  completedBookingNo:string[];
  lateShipments:string[];
  totalShipment:number;
  totalLateShipment:number;
  totalNormalShipment:number;
  totalCompletedShipment:number;
  sequence:number;
  locationId:number;
  placeId:string;
  shipmentId:number;
  typeName = 'MiddleNodeDataModel';
}

export class DestNodeDataModel{
  name:string;
  destination_name:string;
  customer_name:string;
  status:string;
  destination_location:string;
  lateRoute:number;
  pendingRoute:number;
  defectRoute:number;
  completedRoute:number;
  totalRoute:number;
  defectBookingNo:string[];
  lateBookingNo:string[];
  pendingBookingNo:string[];
  inProgressBookingNo:string[];
  completedBookingNo:string[];
  lateShipments:string[];
  totalShipment:number;
  totalLateShipment:number;
  totalNormalShipment:number;
  totalCompletedShipment:number;
  locationId:number;
  placeId:string;
  shipmentId:number;
  typeName = 'DestNodeDataModel';
}

export class DrawRouteRowModel {
  oriNodeData:OriNodeDataModel[]
  middleNodeData:MiddleNodeDataModel[]
  destNodeData:DestNodeDataModel[]
  matchRoute:MatchRouteModel[]
}
