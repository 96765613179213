import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';
import { NotificationService } from 'src/app/core/_Services';
declare function closeModal(modalID):any;
declare function openModal(modalID):any;
@Component({
  selector: 'app-file-manage',
  templateUrl: './file-manage.component.html',
  styleUrls: ['./file-manage.component.scss']
})
export class FileManageComponent implements OnInit {
@Input() ModalID:string  = "imgViewModal";
@Input() FileUrl:string ;
@Output() OnDelete: EventEmitter<boolean> = new EventEmitter();
IsImage : boolean = false;
imageExtension = ["png","jpg","jpeg"];
filename:string;
  constructor(
    private _noti:NotificationService
  ) { }

  ngOnInit(): void {

      if(this.FileUrl){
        const ext = this.FileUrl.split('.').pop();       
        const index = this.imageExtension.indexOf(ext);
        if (index >=0) {
          this.IsImage = true;
        }
      }
      /// get fielname
      const filename =  this.FileUrl.replace(/^.*[\\\/]/, '');
      this.filename = filename;
      
  }
  onMouseMove(){
    $(".img-view").show();
  }
  onMouseLeave(){
    $(".img-view").hide();
  }
  OpenModal(){
    openModal("#"+this.ModalID);
  }

  CloseModal(){
    closeModal("#"+this.ModalID);
  }
  onDownload(){
    var win = window.open(this.FileUrl, '_blank');
    win.focus();
  }

  async onDelete(){
    let conf = await this._noti.swalDeleteConfirm();
    this.OnDelete.emit(conf);
  }


}
