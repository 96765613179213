import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare function closeModal(modalID): any;

@Component({
  selector: 'app-modal-add-item-order-plan',
  templateUrl: './modal-add-item-order-plan.component.html',
  styleUrls: ['./modal-add-item-order-plan.component.scss']
})
export class ModalAddItemOrderPlanComponent implements OnInit {
  OrderItemForm: FormGroup;

  @Output() addItem = new EventEmitter();

  material_code = "MB513544"
  qty = 10
  qty_unit = "ETG_PIECE"
  wieght = 10
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {


}

  onAdd(){

    
    this.OrderItemForm = new FormGroup({
      material_code :  new FormControl(this.material_code),
      qty :  new FormControl(this.qty),
      qty_unit :  new FormControl(this.qty_unit),
      wieght :  new FormControl(this.wieght),
    })


    this.addItem.emit(this.OrderItemForm)

    closeModal('#planOrderItem')
  }

}
