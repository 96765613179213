import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService } from '@app/core/_Services';
import { CustomNgbDateParserFormatterService } from '@app/core/_Services/NgbDatepickerService/CustomNgbDateParserFormatter.service';
import { NgbDatepickerI18nBuddhistService } from '@app/core/_Services/NgbDatepickerService/NgbDatepickerI18nBuddhist.service';
import { NgbCalendar, NgbCalendarBuddhist, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as internal from 'stream';
import { DriverDetailResponse, UserSalaryForm, UserSalaryResponse } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalTrainingHistoryDetailComponent } from '../modal-training-history-detail/modal-training-history-detail.component';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';
declare function openModal(modelID): any;

@Component({
  selector: 'app-modal-salary-rate-detail',
  templateUrl: './modal-salary-rate-detail.component.html',
  styleUrls: ['./modal-salary-rate-detail.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nBuddhistService },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatterService }
  ]
})
export class ModalSalaryRateDetailComponent implements OnInit {
  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() selectedMonth: number;
  @Input() selectedYear: number;
  @Input() userSalaries: UserSalaryResponse[] = []
  @Input() driver_id:number

  @Output() btnsubmit_event = new EventEmitter<any>();
  @Output() modalReference!: NgbModalRef;
  @Output() onModalClose = new EventEmitter<any>();

  isEdit: boolean = false
  salaryTypeSelected = 1
  roundTripTypeSelected = 1

  salaryType = [
    { id: 1, name: 'บาท/เดือน' }
  ]
  roundTripType = [
    { id: 1, name: 'บาท/เที่ยว' }
  ]

  month: any[] = []
  year: any[] = []

  endDate: NgbDateStruct | undefined;
  minDate = new Date();
  endDate_min: NgbDateStruct = { year: this.minDate.getFullYear(), month: this.minDate.getMonth() - 1, day: this.minDate.getDay() };

  longThaiMonth = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

  salaryRateForm: FormGroup

  userSalaryForm: UserSalaryForm

  user: User;

  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;
  @ViewChild("modalsalaryratedetail", { static: true }) modalsalaryratedetail!: ModalSalaryRateDetailComponent;
  constructor(
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private driverDetailService: DriverDetailService,
    private authen:AuthenticationService,
    public formatter: NgbDateParserFormatter
  ) { }

  ngOnInit(): void {
    this.getDdlMonthYear()
    this.authen.currentUser.subscribe((x) => {
      this.user = x;
    });

    console.log('minDate',this.minDate);
    console.log('endDate_min',this.endDate_min);

  }

  onSubmit(){
    this.userSalaryForm = this.salaryRateForm.value
    console.log("create",this.userSalaryForm);

    this.onCreate(this.userSalaryForm);
  }

  formInit() {

    console.log("forminit",this.userSalaries[0]);

    var {
      id,
      user_ID,
      salary,
      salaryUnit_ID,
      tripWage,
      tripWageUnit_ID,
      startDate,
      createdBy
    } = { ...this.userSalaries[0] }

    this.salaryRateForm = this.fb.group({
      id,
      user_ID,
      salary:[salary,[Validators.required]],
      salaryUnit_ID:[salaryUnit_ID,[Validators.required]],
      tripWage:[tripWage,[Validators.required]],
      tripWageUnit_ID:[tripWageUnit_ID,[Validators.required]],
      startDate:[this.formatter.parse(startDate), [Validators.required]],
      createdBy
    })

    this.salaryRateForm.get('startDate').valueChanges.subscribe(res => {
      console.log(res);

    });
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string) {
    this.isEdit = false
    this.formInit()

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalsalaryratedetail, {
        size: size,
        centered: true,
        backdrop:'static'
      });
    } else {
      this.modalReference = this.modalService.open(this.modalsalaryratedetail, {
        centered: true,
        backdrop:'static'
      });
    }
  }

  onEdit() {
    this.isEdit = !this.isEdit
  }

  onCreate(userSalaryForm:UserSalaryForm){

    const salaryRateForm = this.salaryRateForm.getRawValue();
    salaryRateForm.startDate = new Date((salaryRateForm.startDate!.year - 543), (salaryRateForm.startDate!.month - 1), salaryRateForm.startDate!.day);
    salaryRateForm.startDate = this.datePipe.transform(salaryRateForm.startDate, 'yyyy-MM-dd')!;
    userSalaryForm.startDate = salaryRateForm.startDate;

    userSalaryForm.createdBy = this.user.tstS360_UserID
    userSalaryForm.user_ID = userSalaryForm.user_ID == undefined ? this.driver_id : userSalaryForm.user_ID
    userSalaryForm.tst360_CompanyID = this.authen.currentUserValue.tstS360_CompanyID;
    console.log('pre addUserSalary',userSalaryForm);


    this.driverDetailService.addUserSalary(userSalaryForm)
      .subscribe(res => {
        if (res.isSuccess) {
          console.log("success", res);
          this.onModalClose.emit();
          this.modalReference.close()
          this.openmodalsuccess('แก้ไขอัตราค่าจ้างเรียบร้อย');
        }
      })
  }

  getDdlMonthYear() {
    this.month = []
    this.year = []
    for (let index = 0; index < 12; index++) {
      this.month.push({
        id: index + 1,
        name: this.longThaiMonth[index]
      });
    }
    for (let index = new Date().getFullYear() - 5; index <= new Date().getFullYear() + 5; index++) {
      this.year.push({
        id: (index + 543),
        name: (index + 543).toString()
      });
    }
  }

  openmodalsuccess(text:string) {
    this.modalsuccess.modalsize = undefined;
    this.modalsuccess.body_title = text;
    this.modalsuccess.btnother_text = 'ปิด';
    this.modalsuccess.openmodal(this.modalsuccess.modalsize);
  }

  success_other(value: any) {
    this.modalsuccess.modalReference.close();
  }
}
