import { Injectable } from '@angular/core';
import { Operation } from '@app/core/_Models/UserModel/UserPermission';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService, HttpService } from 'src/app/core/_Services';
import { environment } from 'src/environments/environment';

@Injectable()
export class ServicesettingService {

  constructor(private _apiService: HttpService,
    private authenticationService: AuthenticationService,
    ) { }

  destroy$: Subject<boolean> = new Subject<boolean>();

  async GetTruckType(clientId):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/TruckType/GetTruckTypeByClientId/${clientId}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  GetPermission(moduleId,operationsId):Operation{
    const appModule =  this.authenticationService.UserPermissionModule(moduleId);
    return appModule.operations.find(f=>f.id == operationsId);
  }

  async GetTruckTypeSetting(Id: number):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/SettingBooking/TruckType/${Id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetRef(Id: number):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/SettingBooking/Ref/${Id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  
  async GetBookingSetting(Id: number):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/SettingBooking/ClientSettingBooking/${Id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }


  async Getloading(Id: number):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/SettingBooking/LoadingType/${Id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetUnitPrice():Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/AppMaster/UntiPrice`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetUnitPriceSetting(Id: number):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/SettingBooking/UnitPrice/${Id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetWeightUnit():Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/WeigthUnit`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetHandlingUnit():Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/HandlingUnit`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetHandlingPrinciple():Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/HandlingPrinciple`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetProductSetting(Id: number):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/SettingBooking/Product/${Id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  async GetProduct():Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/ProductGroup`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);
      });
    });
  }

  // async updateSetting(name:string,data:any): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._apiService
  //       .patch(`/SettingBooking/${name}`,data)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe((data: any[]) => {
  //         resolve(data);
  //       });
  //   });
  // }
  updateSetting(name:string,data:any): Observable<any> {
    return this._apiService.patch(`/SettingBooking/${name}`,data);
  }

  // async updateProduct(name:string,data:any): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._apiService
  //       .patch(`/SettingBooking/Product/${name}`,data)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe((data: any[]) => {
  //         resolve(data);
  //       });
  //   });
  // }
  updateProduct(name:string,data:any): Observable<any> {
    return this._apiService.patch(`/SettingBooking/Product/${name}`,data);
  }

  getLoadLimit(client_id,location_id = 0){
    var cin = environment.endpoint_planner+"/planning/locationloadlimit/get?client_id=" + client_id;
   if(location_id >0){
    cin += "&location_id=" + location_id;
   }
    return fetch(cin, {
      method: 'GET',
     
    });
  }

  getLoadLimitgetBookings(client_id,date = ''){
    var cin = environment.endpoint_planner+"/planning/locationloadlimit/getSlot?client_id=" + client_id;
 
    cin += "&date="+date

    return fetch(cin, {
      method: 'GET',
     
    });
  }

  saveLoadlimit(loadlimits): Observable<any> {
    console.log(loadlimits);
    var request = {
      loadlimits:loadlimits
    }
    return this._apiService.postWithoutException(
      "/Scheduler/saveLoadlimit",
      request
     );
  }
}
