import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { CustomNgbDateParserFormatterService } from '@app/core/_Services/NgbDatepickerService/CustomNgbDateParserFormatter.service';
import { NgbDatepickerI18nBuddhistService } from '@app/core/_Services/NgbDatepickerService/NgbDatepickerI18nBuddhist.service';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';
import { NgbCalendar, NgbCalendarBuddhist, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DataBundle, Header, Item, Period, Row, RowItem } from 'tms-scheduler';
import { SchedulerService } from '../scheduler.service';
declare function openModal(modalID): any;
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nBuddhistService },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatterService }
  ]
})
export class SchedulerComponent implements OnInit {

  constructor(
    // private pageload: PageloadingService,
    private schedulerService: SchedulerService,
    private bookingService: BookingServiceService,
    public formatter: NgbDateParserFormatter,
    public auth: AuthenticationService,
    private _noti: NotificationService,
    private ngZone: NgZone,
    private customerservice: CustomerserviceService
  ) {
    this.auth.currentUser.subscribe((res) => {
      this.currentUser = res;
      this.filter = {
        date: null,
        senario: "CNF",
        client_id: this.currentUser.ClientID,
        origin_location_id:null,
        trucktypeId:null
      }
    });

  }
  dataCompanylocation= []
  currentUser;
  tabselected = 1;
  slotItem;
  data;
  loadComplete = false;
  bundle: DataBundle;
  backlogData;
  loadOrderData;
  bookingData = [];
  bookingDataTemp =[];
  shipments = [];
  order_bookeds = [];
  toMorrows;
  deliveryDate = null;
  deliveryDateDate = new Date();
  createResults;
  filter = {
    date: null,
    senario: "CNF",
    client_id: 103,
    origin_location_id:null,
    trucktypeId:null
  }
  searchTextDo = "";
  shipmentPlans = [];
  selectedBooking = [];
  planTypes = [
    { name: 'CNF', id: 'CNF' },
    { name: 'EXF', id: 'EX-FAC' },
    { name: 'EXPORT', id: 'EXPORT' },
    { name: 'TRF', id: 'TRF' }
  ]

  truckTypesTemp = [
    { name: '4 ล้อคอก', id: 11 ,senario:['CNF', 'EX-FAC', 'EXPORT']},
    { name: '6 ล้อพื้นเรียบ', id: 24 ,senario:['CNF', 'EX-FAC', 'EXPORT']},
    // { name: '6 ล้อคอก', id: 24 ,senario:['EX-FAC']},
    { name: '10 ล้อพื้นเรียบ', id: 33 ,senario:['CNF', 'EX-FAC', 'EXPORT']},
    // { name: '10 ล้อคอก', id: 33 ,senario:[ 'EX-FAC', 'EXPORT']},
    { name: 'รถพ่วง พื้นเรียบ ', id: 41 ,senario:[ 'EXPORT']},
    { name: 'รถพ่วง คอกดัมพ์ ', id: 41 ,senario:[ 'EXPORT']},
    { name: 'เทรลเลอร์ พื้นเรียบ ', id: 51 ,senario:['CNF', 'EX-FAC', 'EXPORT','TRF']},
    { name: 'รถลากตู้ Dry 20 ฟุต', id: 41 ,senario:[ 'EXPORT']},
  ]

  truckTypes = []

  selectedPlanType='CNF'
  carrierQuotas = [];
  async getData() {

    this.data = [];
    this.backlogData = [];
    this.loadOrderData = [];
    this.bookingData = [];
    const tempTab = this.tabselected;
    // this.tabselected = 0;

    this.schedulerService.getPlanFilter(this.filter)
      .subscribe((res) => {

        console.log("REAL carrierQuotas", res)

        this.data = res;
        this.backlogData = res.backlog;
        this.loadOrderData = res.orders;

        res.bookings.forEach(booking => {
          var sumWeight = 0;
          booking.customer.forEach(cus=>{
            cus.customerOrder.forEach(order=>{
              var orderWeight = 0;
              order.items.forEach(item=>{
                orderWeight+=item.weight;
                sumWeight+=item.weight;
              });
              order.sumWeight = orderWeight;
            })
          })
          booking.sumWeight = sumWeight;
        });

        this.bookingData = res.bookings;
        this.bookingDataTemp = res.bookings;
        this.shipments = res.shipments;
        this.order_bookeds = res.order_bookeds;
        this.carrierQuotas =  res.carrierQuotas;
        this.CookData();
        console.log(this.shipments[0]);
        this.loadComplete = true;
        // this.tabselect(tempTab);

      });

  }

  async ngOnInit() {
    // this.getData();
    // var date = new Date();

    // date.setDate(date.getDate() + 1);
    // var dateString = new Date(date).toISOString().slice(0, -1);
    // this.deliveryDate = this.formatter.parse(dateString);
    this.tabselected = 1;
    this.loadComplete = true;
    await this.getClientShipmentPlan();
    await this.getClientLocation();
    //this.pageload.hide();
    this.truckTypes = this.truckTypesTemp;

    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', () => {
        this.ngZone.run(() => {
          this.loadComplete = false;
          setTimeout(() => {
            this.loadComplete = true
          }, 500);
        });
      });
    });
  }

  getEvent(event) {
    const ordersNumbers = this.filterOrderNumber(event.data)
    let action = 'start'
    if(event.data.styleClass === 'discharge')  action = 'end'
    else if(event.data.styleClass === 'load') action = 'start'
    let data = {
      slotID: event.timeFrameIndex,
      taskID: event.data.rowItemId,
      ordersNumbers: ordersNumbers,
      action: action
    }
    this.orderDetails(event)
    this.schedulerService.PinTask(data).subscribe(() => {
      this.reload(0);
    })
  }

  filterOrderNumber(obj) {
    let odersNumbers = []
    if (obj) {
      obj.orders.map(x => {
        odersNumbers.push(x.order_number)
      })
    }
    return odersNumbers
  }

  searchDO(){
    console.log('search',this.searchTextDo);
    var result = [];
    if(this.searchTextDo.trim() != ""){
      for (const booking of this.bookingDataTemp) {
        var isInclude = false;
        for (const cus of booking.customer) {
          for (const order of cus.customerOrder) {
            if (order.ref_no_1.includes(this.searchTextDo)) {
              console.log(this.searchTextDo);
              isInclude = true;
              break;
            }
          }
        }
        if(isInclude) result.push(booking);
      }
      this.bookingData = result;
    }else{
      this.bookingData = this.bookingDataTemp;
    }



  }

  async CookData() {

    console.log("deliveryDate ", this.deliveryDate);
    var itemList: Item[] = [];
    var shipmentIndex = 0;
    var jobIndex = 0;
    var slotIndex = 0;
    console.log(this.shipments)
    this.shipments.forEach(item => {
      jobIndex = 0
      var id = 1;
      var currentDate = new Date();
      var jobs: RowItem[] = [];

      if (item.jobs != null) {

        item.jobs.forEach((job, index) => {
          //  console.log("JOB",job)

          slotIndex = 0;
          job.slots.forEach(async slot => {



            if (slot != null) {
              var endHH = await this.findLenght(job, slot);
              let isDropPoint = job.slots[slot.slotIndex]?.action == "DISCHARGE";
                if(isDropPoint) endHH = 1;
              let isFirstBook = false;
              //  console.log(slot)
              // if(slot.slotIndex!=0){
              // console.log(job.slots[slot.slotIndex-1])
              if (slot.slotIndex == 0) {
                isFirstBook = true;
              } else if (job.slots[slot.slotIndex - 1]?.action !== job.slots[slot.slotIndex]?.action) {
                isFirstBook = true;
              }
              //  console.log('isFirstBook',isFirstBook)

              //  }
              let rowitem: RowItem = {
                name: slot.action,
                rowItemId: slot.id,
                text: ' ',
                start_time: new Date(this.deliveryDate),
                end_time: new Date(this.deliveryDate),
                startHH: slot.slotIndex,
                endHH: slot.slotIndex + endHH,
                styleClass: slot.action.toLowerCase(),
                type: "line",
                startMM: 0,
                endMM: 0,
                orders: slot.orders,
                totalQTY: slot.totalQTY,
                maxQTY: slot.maxQTY,
                location_id:slot.location_id
              }
              if (isFirstBook || isDropPoint) {
                jobs.push(rowitem);
              }
              id++
              slotIndex++
            }
          }
          )
          jobIndex++;
        })
      }
      // console.log(jobs)
      var row: Row = {
        rowItem: jobs
      }
      var rowList: Row[] = [];
      rowList.push(row)

      var it: Item = {
        id: 0,
        itemID: 0,
        row: rowList,
        name: item.name
      }
      itemList.push(it)
      shipmentIndex++;
    });


    var iperiod: Period = {
      timeFramePeriod: 60,
      timeFrameType: 'day',
      timeFrameHeaders: 'HH:mm'
    }
    var iheader: Header = {
      itemHeaderName: "Shipment",
      period: iperiod
    }


    var data: DataBundle = {
      header: iheader,
      items: itemList
    }
    this.bundle = data;
     console.log("DATA",data)

    this.loadComplete = true;
    console.log("loadComplete")
  }

  // getbundle(){
  //   return this.bundle;
  // }

  async findLenght(jobs, slot) {
    //  console.log(jobs)
    //  console.log(slot.slotIndex)

    var length = 1;
    var same = true;
    var index = slot.slotIndex;
    while (same) {
      // console.log("next same",index)

      try {
        if (jobs.slots[index].action == jobs.slots[index + 1].action) {
          length++;
          // console.log(length)
        } else {
          // console.log("next not same")
          same = false;
        }
      } catch (Exception) {
        // console.log("catch",Exception)
        same = false
        return length;
      }
      index++;
    }
    // console.log("length",length)

    return length;
  }


  addOrder() {
    openModal('#planOrder')
  }

  reload(event) {
    this.getData();
  }
  tabselect(tab) {
    this.tabselected = tab;
  }

  orderDetails(data) {
    this.slotItem = data.data;
    setTimeout(() => {
      console.log(this.slotItem)
      if (!data.timeFrameIndex) openModal('#order-detail')
    }, 10)
  }

  slotData() {
    return this.slotItem;
  }

  createBookingByPlan() {
    this.bookingService.createBookingByPlan(this.bookingData).subscribe((res) => {
      console.log(res);
      this.createResults = res.data;
      setTimeout(() => {
        openModal("#create360BookingModal")

      }, 10);
    })
  }


  createBookingBySelectedPlan(selectedBookingPlan) {
    //this.pageload.show();
    this.bookingService.createBookingByPlan(selectedBookingPlan,this.carrierQuotas).subscribe((res) => {
      console.log(res);
      this.createResults = res.data;
      //this.pageload.hide();
      setTimeout(() => {
        openModal("#create360BookingModal")
        this.getData();
      }, 10);
    })
  }

  async saveShipments() {
    var shipmentRequest = await this.configShipment();

    this.schedulerService.saveShipments(this.shipments).subscribe((res) => {
      console.log(res);
      this.createResults = res.data;
      if (res.status === true) {
        this._noti.toastsuccess('', "ทำรายการสำเร็จ");

      }
      setTimeout(() => {

      }, 10);
    })
  }

  async configShipment() {
    var result = [];
    if (this.shipments.length > 0) {
      this.shipments.forEach((shipment) => {
        var sm = {
          shipment_plan_id: shipment.shipment_plan_id ? shipment.shipment_plan_id : 0,
          shipment_number: shipment.name,
          remark: shipment.notes.length > 0 ? shipment.notes[0] : "",
          client_id: this.currentUser.ClientID
        };


      }
      );
    }

    return result;
  }
  onCreate(event) {
    console.log(event);
    this.createBookingBySelectedPlan(event);
  }

  inputChange(event) {
    console.log(event);
    console.log(this.deliveryDate)



    var dateString = event.year - 543 + '-' + event.month + '-' + event.day;
    this.filter.date = dateString;
    this.deliveryDateDate = new Date(dateString);
    console.log(this.filter);
    this.getData();

  }

  async tabSenario() {
    const senario = this.selectedPlanType

    this.filter.senario = senario;

    // this.truckTypes = this.truckTypesTemp.filter(t=>t.senario.filter(s=>s == senario).length >0);
    if (this.filter.date) {

      await this.getData();
    }

  }
async getClientLocation(){
  this.customerservice
  .GetCompanyLocationList(this.currentUser.ClientID)
  .subscribe((data) => {
    this.dataCompanylocation = data;
    //this.reInitcomDatatable();
    console.log(" this.dataCompanylocation ", this.dataCompanylocation );
  });
}

LocChange(){
  this.getData();
}

TruckTypeChange(){
  this.getData();
}

  async getClientShipmentPlan() {
    var shipmentsPlan = await this.schedulerService.getShipmentPlan(this.currentUser.ClientID).then(
      async (res) => {
        var result = await res.json();
        console.log(result);
        this.shipmentPlans = result;
      }
    );
  }

  async getShipmentByPlanId(plan) {
    console.log(plan);
    this.loadComplete = false;

    var shipmentsPlan = await this.schedulerService.getShipmentByPlan(plan.id).then(
      async (res) => {
        var result = await res.json();
        //  console.log(result);
        this.shipments = result.shipmentList;
        this.bookingData = result.bookingList;
        console.log(this.shipments[0])
        this.loadComplete = true;


        setTimeout(() => {
          this.CookData();
          this.tabselect(1);
        }, 10);
      }
    );
  }

  clearFilters(){
    this.filter = {
      date: null,
      senario: "CNF",
      client_id: this.currentUser.ClientID,
      origin_location_id:null,
      trucktypeId:null
    }

    this.searchTextDo = null;
    this.bookingData = this.bookingDataTemp;
  }

}
