
<!-- Modal -->
<div class="modal fade" id="planOrderItem" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-lg">
                <div class="modal-header">
                        <h5 class="modal-title">Add Order</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body">
                <div class="container-fluid">
                 
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Material  <span class="txt-red">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" type="text" [(ngModel)]="material_code"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">QTY <span class="txt-red">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" type="number"  [(ngModel)]="qty"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Unit​  <span class="txt-red">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" type="text"  [(ngModel)]="qty_unit"/>
                                </div>
                            </div>
                        </div>
                         <div class="col-12">
                            <div class="form-group">
                                <label for="">Weight (Ton)​  <span class="txt-red">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" type="number" [(ngModel)]="wieght"/>
                                </div>
                            </div>
                        </div>
                    </div>
     
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="onAdd()">OK</button>
            </div>
        </div>
    </div>
</div>
