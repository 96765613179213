import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UnitTypeService {

UnitList : UnitType[] = new Array();

constructor(private _apiService: HttpService) { }
destroy$: Subject<boolean> = new Subject<boolean>();

   async GetMasterHandlingUnit():Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/HandlingUnit`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);       
      });
    }); 
  }

}

class UnitType {
  id:number;
  name:string;
}
