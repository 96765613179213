import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scheduler-shera',
  templateUrl: './scheduler-shera.component.html',
  styleUrls: ['./scheduler-shera.component.scss']
})
export class SchedulerSheraComponent implements OnInit {

  value = environment.planningSheraURL
  currenClient = 0 ;
  currenUserId = 0 ;

  planningURL:any = ''
  constructor(public sanitizer: DomSanitizer,
    // private pageload: PageloadingService,
    public auth: AuthenticationService,) {
    this.auth.currentUser.subscribe((res) => {
      this.currenClient = res.ClientID;
      this.currenUserId = res.id;
    });
   }

  ngOnInit(): void {
    this.planningURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.value+ this.currenClient+"&userId="+this.currenUserId )
    //this.pageload.hide();
  }

}
