import {Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-modal-adduser',
  templateUrl: './modal-adduser.component.html',
  styleUrls: ['./modal-adduser.component.scss']
})
export class ModalAdduserComponent implements OnInit {
  ngOnInit(): void {
  }
}
