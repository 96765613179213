import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService } from '@app/core/_Services';
import { DriverDetailResponse} from '../../models/driver-detail-response.model';

@Component({
  selector: 'app-driver-gen-pdf-template',
  templateUrl: './driver-gen-pdf-template.component.html',
  styleUrls: ['./driver-gen-pdf-template.component.scss']
})
export class DriverGenPdfTemplateComponent implements OnInit {
  @Input() topicList: string[] = [];
  title:string = ''
  header:string = ''
  currentUser: User;

  @Input() driverDetail = new DriverDetailResponse();

  constructor(private authenticationService: AuthenticationService, private change: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(
      (x) =>{
     (this.currentUser = x)
      console.log(x);
    });
  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.title = `${this.driverDetail?.fleetDriver?.firstName ?? ''} ${this.driverDetail?.fleetDriver?.lastName ?? ''} - ${this.currentUser?.client?.clientName ?? ''}`
    this.change.detectChanges();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.header = `ประวัติคนขับรถ`

  }
}
