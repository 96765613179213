import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OAuthTokenRequest, OAuthTokenResponse } from '@app/core/_Models/oauth-token-dto';
import { AuthenticationService, HttpService } from '@app/core/_Services';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthenticationService,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(async (param) => {
      const code = param.code;
      const state = param.state;
      const redirectParam = param.redirect;
      localStorage.removeItem('at');
      localStorage.removeItem('rt');
      if (code) {
        localStorage.setItem('ac', code);
        await this.authService.getTokenOAuth2(code, state).then(response => {
          console.log('getTokenOAuth2 response', response);
          let redirect = environment.web;
          if (redirectParam) {
            redirect = redirectParam
          }
          const auth = `${environment.apiUrl}/v3/auth/callback?code=${code}&redirect=${redirect}`;
          console.log("callback url ", auth);
          window.location.href = auth;
        });
      } else {
        localStorage.removeItem('ac');
        console.log("Error OAuth2", param.error);
        await this.authService.logout();
        window.location.href = '/logout';
        // window.location.reload();
        // this.router.navigate(['/login']);
        // window.location.href = environment.apiUrl + '/v1/auth/logout';
      }
    })
  }

}
