import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService } from '@app/core/_Services';
import { ModalConfirmComponent } from '@app/shared/Components/modal-confirm/modal-confirm.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserTrainingForm, UserTrainingResponse } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalTrainingHistoryFormComponent } from '../modal-training-history-form/modal-training-history-form.component';

@Component({
  selector: 'app-modal-training-history-detail',
  templateUrl: './modal-training-history-detail.component.html',
  styleUrls: ['./modal-training-history-detail.component.scss']
})
export class ModalTrainingHistoryDetailComponent implements OnInit {
  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() userTrainings: UserTrainingResponse[] = [];
  @Input() driver_id: number;

  @Output() modalReference!: NgbModalRef;
  @Output() btnsubmit_event = new EventEmitter<any>();
  @Output() closeTrainingForm_event = new EventEmitter<any>();
  @Output() btnOpenModal = new EventEmitter<any>();

  userTraining: UserTrainingResponse;

  img = './../../../../../assets/img/sample/image-sample.jfif'

  trainingStatusComplete = 2
  trainingStatusCancel = 3

  @ViewChild("modaltraininghistorydetail", { static: true }) modaltraininghistorydetail!: ModalTrainingHistoryDetailComponent;
  @ViewChild("modaltraininghistoryform", { static: true }) modaltraininghistoryform!: ModalTrainingHistoryFormComponent;
  @ViewChild("modaltraininghistoryform_edit", { static: true }) modaltraininghistoryform_edit!: ModalTrainingHistoryFormComponent;
  @ViewChild("modaltraininghistoryform_success", { static: true }) modaltraininghistoryform_success!: ModalTrainingHistoryFormComponent;
  @ViewChild("modaltraininghistoryform_after_success", { static: true }) modaltraininghistoryform_after_success!: ModalTrainingHistoryFormComponent;
  @ViewChild("modalConfirm", { static: true }) modalConfirm!: ModalConfirmComponent;
  constructor(
    private modalService: NgbModal,
    private driverDetailService: DriverDetailService) { }

  ngOnInit(): void { }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string) {

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modaltraininghistorydetail, {
        size: size,
        centered: true,
        backdrop: 'static'
      });
    } else {
      this.modalReference = this.modalService.open(this.modaltraininghistorydetail, {
        centered: true,
        backdrop: 'static'
      });
    }
  }

  openmodaltraininghistoryform() {
    this.modaltraininghistoryform.modalsize = undefined;
    this.modaltraininghistoryform.openmodal(this.modaltraininghistoryform.modalsize);
    this.modalReference.close()
  }

  fetchHistoryDetail() {
    this.closeTrainingForm_event.emit();
  }

  openmodaltraininghistoryform_edit(userTraining: UserTrainingForm) {
    this.modaltraininghistoryform_edit.modalsize = undefined;
    this.modaltraininghistoryform_edit.userTraining = userTraining;
    this.modaltraininghistoryform_edit.openmodal(this.modaltraininghistoryform_edit.modalsize);
    this.modalReference.close();
  }

  openmodaltraininghistoryform_success(userTraining: UserTrainingForm) {
    this.modaltraininghistoryform_success.modalsize = undefined;
    this.modaltraininghistoryform_success.userTraining = userTraining;
    this.modaltraininghistoryform_success.openmodal(this.modaltraininghistoryform_success.modalsize);
    this.modalReference.close()
  }

  openmodalmodaltraininghistoryform_after_success(userTraining: UserTrainingForm) {
    this.modaltraininghistoryform_after_success.modalsize = undefined;
    this.modaltraininghistoryform_after_success.userTraining = userTraining;
    this.modaltraininghistoryform_after_success.openmodal(this.modaltraininghistoryform_after_success.modalsize);
    this.modalReference.close()
  }

  async updateTrainingStatus(userTraining: UserTrainingForm, trainingStatus: number) {
    const modalConfirmConfig = {
      title: 'ยกเลิกรายการ',
      description: 'คุณต้องการยกเลิกประวัติการอบรมนี้ใช่ หรือ ไม่',
      iconClassName: 'fal fa-exclamation-triangle text-danger',
      buttonList: [
        { text: 'ใช่ ต้องการ', class: 'btn-danger', comfirm: true },
        { text: 'ปิด', class: 'btn-outline-secondary', comfirm: false },
      ],
    };
    this.modalConfirm.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirm.open('sm');
    modalRef.closed.subscribe(res => {
      if (res) {
        this.driverDetailService.updateUserTrainingStatus(userTraining, trainingStatus).subscribe(res => {
          if (res.isSuccess) {
            console.log("updateTrainingStatusComplete", res);
          }
          this.fetchHistoryDetail();
        })
      }
    })
    // this.driverDetailService.updateUserTrainingStatus(userTraining, trainingStatus).subscribe(res => {
    //   if (res.isSuccess) {
    //     console.log("updateTrainingStatusComplete", res);

    //   }
    //   this.fetchHistoryDetail();

    // })
  }

  onOpenModal() {
    this.btnOpenModal.emit()
  }

}
