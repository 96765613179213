<app-breadcrumb Title="Profile"></app-breadcrumb>
<div class="row">
    <div class="col-xl-12">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item"><a class="nav-link {{tabSelected === 0?'active':''}}" id="users" data-toggle="tab" href="#tab_user"
                    role="tab" #users><b>ข้อมูลส่วนตัว</b></a></li>
            <li class="nav-item"><a class="nav-link {{tabSelected === 1?'active':''}}" id="company" data-toggle="tab" href="#tab_company"
                    role="tab" #company><b>ข้อมูลบริษัท</b></a></li>
        </ul>
        <div class="tab-content p-3">
            <div class="tab-pane fade show active" id="tab_user" role="tabpanel">
                <div class="form-group mb-3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-2 border-right">
                                            <div class="row">
                                                <div class="col-md-12 d-flex justify-content-center">
                                                    <div class="form-group">
                                                        <img src="{{profileImage}}" (error)="onImgErrorProfile($event)"
                                                            class="img-fluid" style="border-radius: 50%;width: 120px;
                                            height: 120px;
                                            object-fit: cover;">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="form-group">
                                                <div class="row d-flex justify-content-center">
                                                    <div class="col-md-12">
                                                        <button class="btn btn-success btn-block" *ngIf="permission.canEdit" (click)="GetEditUser()">แก้ไขโปรไฟล์</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-md-12">
                                                    <button class="btn btn-success btn-block"
                                                        data-toggle="modal" *ngIf="permission.canEdit"
                                                        data-target="#changepassword">เปลี่ยนรหัสผ่าน</button>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="col-md-4 mb-3 border-right">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>ชื่อ :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <span>
                                                           {{profile?.data.fullName}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>Rating :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <span> <i class="fas fa-star"></i><i
                                                                class="fas fa-star"></i><i
                                                                class="fas fa-star"></i><i
                                                                class="fas fa-star"></i><i
                                                                class="fas fa-star"></i></span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>ตำแหน่ง :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        {{profile?.data.userRoleDto.roleName}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>ทีม :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <ng-container *ngFor="let team of profile?.data?.teamDto">
                                                            <span class="badge badge-secondary badge-pill mr-2">
                                                                {{team.teamName}}
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>แผนก :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        {{profile?.data?.departmentDto.departmentName}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>Username :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <span>{{profile?.data.identityUserDto?.userName}}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>เบอร์โทรศัพท์ :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <span>{{profile?.data?.phoneNumber}}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>อีเมล์ :</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <span>{{profile?.data?.email}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="tab_company" role="tabpanel">
                <app-compandetail></app-compandetail>
            </div>
        </div>
    </div>

    <app-modal-edit-user
    [IsEdit]="isEdit"
    [EditData]="userEditData"
    (AfterSubmit)="AfterFormSubmit($event)"></app-modal-edit-user>
    <app-modalchangepassword></app-modalchangepassword>
