import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicBreadcrumbComponent } from './dynamic-breadcrumb.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [DynamicBreadcrumbComponent],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [DynamicBreadcrumbComponent]
})
export class DynamicBreadcrumbModule { }
//referrence https://github.com/rajaramtt/ng7-dynamic-breadcrumb/tree/master/projects/ng-dynamic-breadcrumb
