<div class="card">
    <div class="card-header">
        เครื่องมือ​
    </div>
    <div class="card-body">

        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-md-8  mt-md-0">
                        <label class="form-label">เลือกโรงงานต้นทาง
                        </label>

                        <ng-select [items]="dataCompanylocation" [(ngModel)]="filter.origin_location_id" (change)="LocChange()"
                        bindLabel="locationShortAddress" bindValue="id">

                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 mb-2">
                        <label class="form-label">ประเภทของแผน
                        </label>
                        <ng-select [clearable]="false" [(ngModel)]="selectedPlanType" [items]="planTypes" bindLabel="name"
                            bindValue="id" (change)="tabSenario()">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <label class="form-label" for="example-time-2">Loding Date
                        </label>
                        <!-- <app-datepicker formControlName="date" placeholder="Select date" [removeTop]="true"
                            [id]="'date-picker-' + sequence" [valid]="dateCorrect()" (change)="inputChange($event)">
                        </app-datepicker> -->
                        <div class="input-group">
                            <input ngbDatepicker #date="ngbDatepicker" [(ngModel)]="deliveryDate" class="form-control"
                                placeholder="วัน/เดือน/ปี" (click)="date.toggle()" (ngModelChange)="inputChange($event)">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-secondary" (click)="date.toggle()" type="button">
                                    <span class="fal fa-calendar-alt fa-lg"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 bl">
                <div class="row ">
                    <div class="col-md-8 mt-md-0">
                    <label class="form-label">เลือกประเภทรถ
                    </label>

                        <ng-select [items]="truckTypes" [(ngModel)]="filter.trucktypeId" (change)="TruckTypeChange()"
                        bindLabel="name" bindValue="id">

                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <label class="form-label" for="example-time-2">DO Number
                        </label>

                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="searchTextDo">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-secondary" (click)="searchDO()" type="button">
                                    <span class="fal fa-search fa-lg"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-12 mt-2">
                <div class="row justify-content-between">
                    <!-- <div class="col-auto">
                        <button type="button" class="btn btn-default" (click)=" addOrder()">
                            Add Order
                        </button>
                    </div> -->

                    <div class="col-auto">
                        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#select">
                            ยืนยันแผนและสร้างการจอง
                        </button>
                    </div>
                    <div class="col-auto">
                        <button type="button" class="btn btn-danger" (click)="clearFilters()">
                            ล้างตัวกรอง
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card mt-3">
    <div class="card-header">
        แผนการขนส่ง​
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-12 mb-2">
                <ul class="nav nav-pills " role="tablist">
                    <li class="nav-item mr-1 mb-1">
                        <a id="tab1" class="nav-link active" data-toggle="tab" role="tab" href="#tab11"
                            (click)="tabselect(1)" #tab1>
                            แผนการขนส่ง​</a>
                    </li>
                    <li class="nav-item mr-1 mb-1">
                        <a id="tab2" class="nav-link" data-toggle="tab" role="tab" href="#tab22" (click)="tabselect(2)"
                            #tab2>
                            งานที่ไม่สามารถจัดแผนได้</a>
                    </li>
                    <li class="nav-item mr-1 mb-1">
                        <a id="tab3" class="nav-link" data-toggle="tab" role="tab" href="#tab33" (click)="tabselect(3)"
                            #tab3>
                            งานที่จัดแผนได้</a>
                    </li>
                    <li class="nav-item mr-1 mb-1">
                        <a id="tab4" class="nav-link" data-toggle="tab" role="tab" href="#tab44" (click)="tabselect(4)"
                            #tab4>
                            งานที่สร้างการจองแล้ว</a>
                    </li>
                    <li class="nav-item mr-1 mb-1">
                        <a id="tab5" class="nav-link " data-toggle="tab" role="tab" href="#tab55" (click)="tabselect(5)"
                            #tab5>
                            งานจัดแผน</a>
                    </li>
                    <li class="nav-item mr-1 mb-1">
                        <a id="tab6" class="nav-link " data-toggle="tab" role="tab" href="#tab66" (click)="tabselect(6)"
                            #tab6>
                            รายงาน
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-12 tab-pane text-center time-frame" id="tab00" role="tabpanel" *ngIf="!loadComplete">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="col-12 tab-pane " id="tab11" role="tabpanel" *ngIf="tabselected === 1">
                <div class="row">
                    <div class="col-12">
                        <app-tab-booking-shipment [databundle]="bundle"
                        [isExfac]="filter.senario !== 'CNF'"
                        [bookingData]="bookingData"
                        (btnsubmit_event)="onCreate($event)"
                       ></app-tab-booking-shipment>
                    </div>
                </div>
            </div>
            <div class="col-12 tab-pane fade" id="tab22" role="tabpanel" *ngIf="tabselected === 2">
                <app-tab-back-log [backlogData]="backlogData"> </app-tab-back-log>

            </div>
            <div class="col-12 tab-pane fade" id="tab33" role="tabpanel" *ngIf="tabselected === 3">
                <app-tab-load-order [LoadOrderData]="loadOrderData"></app-tab-load-order>

            </div>
            <div class="col-12 tab-pane fade" id="tab44" role="tabpanel" *ngIf="tabselected === 4">
                <app-tab-load-order [booked]="true" [LoadOrderData]="order_bookeds"></app-tab-load-order>

            </div>
            <div class="col-12 tab-pane fade" id="tab55" role="tabpanel" *ngIf="tabselected === 5">
                <tms-time-frame *ngIf="loadComplete" [type]="'day'" [dataSet]="bundle" [canEditItem]="true"
                            (events)="getEvent($event)" [adjustment]="10" [currentDate]="deliveryDateDate">
                        </tms-time-frame>
            </div>
            <div class="col-12 tab-pane fade" id="tab66" role="tabpanel" *ngIf="tabselected === 6">
                <app-slot-report [date]="deliveryDate"></app-slot-report>
            </div>

        </div>
    </div>
    <!-- <div class="col-12 time-frame text-center" *ngIf="bookingData?.length == 0">
            ไม่พบข้อมูล Order
        </div> -->
    <!-- </div>
    </div> -->


    <app-modal-add-order-plan (saved)="reload($event)"></app-modal-add-order-plan>
    <app-modal-timeframe-detail [data]="slotItem"></app-modal-timeframe-detail>
    <app-modal-create-booking [bookingData]="createResults"></app-modal-create-booking>
    <app-select-shipment-to-create [booking]="bookingData" [databundle]="bundle"
    [isExfac]="filter.senario !== 'CNF'" (btnsubmit_event)="onCreate($event)">
    </app-select-shipment-to-create>
