<div *ngIf="companyDocInsure">
  <div class="d-flex justify-content-between text-left" *ngIf="companyDocVerifyStatusId == 3">
    <span class="pl-1">{{insureCompanyName}}</span>
    <div>
      <span class="badge badge-success badge-pill">{{companyDocVerifyStatusShortName}}</span>
    </div>
  </div>
  <div class="d-flex justify-content-between text-left" *ngIf="[2, 4, 5].includes(companyDocVerifyStatusId)">
    <span class=" pl-1">{{insureCompanyName}}</span>
    <div>
      <span class="badge badge-warning badge-pill">{{companyDocVerifyStatusShortName}}</span>
    </div>
  </div>
  <div class="d-flex justify-content-between text-left"
    *ngIf="[1, 6].includes(companyDocVerifyStatusId) && comDocInsureFiles.length <= 0">
    <span class="pl-1">{{insureCompanyName}}</span>
    <div>
      <span class="badge badge-secondary badge-pill">{{companyDocVerifyStatusShortName}}</span>
    </div>
  </div>
  <div class="d-flex justify-content-between text-left">
    <span class="pl-1" *ngIf="expireDate">{{expireDate | thaidate: 'short'}}</span>
    <br *ngIf="!expireDate">
  </div>
  <ng-container *ngIf="checkReminderExpired(companyDocInsure); then thenTemplate; else elseTemplate"></ng-container>
  <ng-template #thenTemplate>
    <div class="d-flex justify-content-center">
      <button class="btn btn-danger btn-block" (click)="buttonSubmit(companyDocInsure, 'contact-center')">
        หมดอายุ <b><u>ประกันราคาพิเศษ</u></b>
      </button>
    </div>
  </ng-template>
  <ng-template #elseTemplate>
    <ng-container *ngIf="checkReminder(companyDocInsure); else nonReminder">
      <div class="d-flex justify-content-center">
        <button class="btn btn-warning btn-block" (click)="buttonSubmit(companyDocInsure, 'contact-center')">
          ใกล้หมดอายุ <b><u>ประกันราคาพิเศษ</u></b>
        </button>
      </div>
    </ng-container>
    <ng-template #nonReminder>
      <div class="d-flex justify-content-center" *ngIf="[4, 5].includes(companyDocVerifyStatusId)">
        <button class="btn btn-sm btn-success btn-block" (click)="buttonSubmit(companyDocInsure, 2)">
          อัปเดตข้อมูลประกัน
        </button>
      </div>
      <div class="d-flex justify-content-center"
        *ngIf="[1, 6].includes(companyDocVerifyStatusId) && comDocInsureFiles.length <= 0">
        <button class="btn btn-sm btn-success btn-block" (click)="buttonSubmit(companyDocInsure, 2)">
          อัปเดตข้อมูลประกัน
        </button>
      </div>
    </ng-template>
  </ng-template>
</div>

<!-- ไม่พบข้อมูลประกัน -->
<div *ngIf="!companyDocInsure">
  <div class="d-flex justify-content-between">
    <span class=" pl-1 text-secondary">ไม่มีข้อมูลประกัน</span>
  </div>
  <div class="d-flex justify-content-center">
    <a class="btn btn-sm btn-success btn-block"
      [routerLink]="companyDocTypeId == 5 ? '/insuredoc/car': '/insuredoc/cargo'"
      (click)="buttonSubmit(companyDocInsure, 'contact-center')">
      <i class="fal fa-plus-square"></i> ทำประกัน
    </a>
  </div>
</div>
