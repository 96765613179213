import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnbordingApiPath } from '../_Constants/apiConstants';
import { onbordingDto } from '../_Models/onbording';
import { User } from '../_Models/UserModel/User';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';
import { IntrojsService } from './introjs.service';

@Injectable({
  providedIn: 'root'
})
export class OnbordingService {

  user : User;
  RecordList : onbordingDto[]= [];
  clientModule
  constructor(
    private _apiService: HttpService,
    private authenticationService: AuthenticationService,
    private intro : IntrojsService
  ){
    this.authenticationService.currentUser.subscribe(a=>{
      this.user = a;
      this.GetCurrentRecord();
      this.clientModule = this.authenticationService.currentUserValue?.clientModules.find(e => e.amo_id == 110010);
    })


  }


 async GetCurrentRecord(){
  
    this._apiService.get(
     OnbordingApiPath.GetCurrentOnbordingRecord + '/' + this.user.id
   ).subscribe((res) => {
     console.log("GetCurrentRecord", res);

     this.RecordList = res.data;
   }
   );

  }

  async OnbordingMethod(pageId,force = false) {

    if(this.clientModule?.pkg_id != 30){
  await this.GetCurrentRecord();

    var item  = this.RecordList.find(a=>a.onboardingPageId === pageId);
    console.log(" OnbordingMethod",item)

    if(item == null || item?.isComplete == false ||force){
      console.log(" Intro start")
      switch(pageId){
     
     case 1 :  await  this.intro.CallBookingListIntro(); break;
     case 2 : await this.intro.CallCreateBookingIntro(); break;
     case 3 : await this.intro.CallTruckLicenseListIntro(); break;
     case 4 : await this.intro.CallDriverListIntro(); break;


      }



    }else{
      console.log(" Intro Complete")
      
    }
  }
  }


  


}
