import { Component, OnInit } from '@angular/core';
import { PageloadingService } from 'src/app/core/_Services';
@Component({
  selector: 'app-permistms',
  templateUrl: './permistms.component.html',
  styleUrls: ['./permistms.component.scss']
})
export class PermistmsComponent implements OnInit {

  constructor() {
  }
  tabSelected: number = 0;
  ngOnInit(): void {

  }


}
