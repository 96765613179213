<ng-template #modalDanger let-modal>
    <div class="modal-header bg-danger text-center text-white">
      <div class="col-12 p-0">
        <span class="fal fa-exclamation-triangle fa-9x"></span>
        <button type="button" class="close pt-0 pl-0 pb-0 pr-1" style="color:white !important" aria-label="Close"
          (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body pb-0">
      <div *ngIf="body_title" class="row mb-2">
        <div class="col-12 text-center">
          <h3 class="font-weight-bold mb-0" style="white-space: pre-wrap">
            {{body_title}}
          </h3>
        </div>
      </div>
      <div *ngIf="body_text" class="row">
        <div class="col-12 text-center">
          <h5 class="text-muted mb-0" style="white-space: pre-wrap">
            {{body_text}}
          </h5>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-12">
        <button id="btnsubmit" type="button" class="btn btn-block btn-outline-danger waves-effect waves-themed font-weight-bold"
        (click)="btnsubmit_click()">{{buttonSubmitText}}</button>
      </div>
  
    </div>
  </ng-template>
  