<div class="col-md-12">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <img src="{{company?.data.image}}" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <h6>ชื่อบริษัท</h6>
                            <span>{{company?.data.companyNameTH}}</span>
                        </div>
                        <div class="col-md-2">
                            <h6>ประเภทบริษัท</h6>
                            <span>{{company?.data.typeNameCompany}}</span>
                        </div>
                        <div class="col-md-2">
                            <h6>ประเภทสินค้า</h6>
                            <span>{{company?.data.typeNameProduct}}</span>
                        </div>
                        <div class="col-md-2">
                            <h6>อีเมล์</h6>
                            <span>{{company?.data.email}}</span>
                        </div>
                        <div class="col-md-2">
                            <h6>เบอร์โทรศัพท์</h6>
                            <span>{{company?.data.phone }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <button class="btn btn-success btn-block" data-toggle="modal"
                        data-target="#Editcompany" *ngIf="permission.canEdit">แก้ไขข้อมูลบริษัท</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngFor="let item of company?.data.companyAddresses;let i = index;" class="col-md-12">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <h5 class="text-dark">ที่อยู่ {{i+1}}</h5>
                    <span>บริษัท {{company?.data.companyNameTH}}</span>
                    <p>
                        {{item.fulladdress}}
                    </p>
                    <span *ngIf="item.id === company?.data.billing" class="badge badge-success badge-pill">
                        ที่อยู่ออกเอกสาร
                    </span>
                    <span *ngIf="item.id === company?.data.shipping" class="badge badge-info badge-pill">
                        ที่อยู่ส่งเอกสาร
                    </span>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <button class="btn btn-success btn-block" (click)="onEdit(item)" *ngIf="permission.canEdit">แก้ไขที่อยู่</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="col-md-12">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <h5 class="text-dark">ที่อยู่ 2</h5>
                    <p>
                        บริษัท พร้อมส่งทรานสปอร์ต จำกัด (สำนักงานใหญ่)
                        เลขนิติบุคคล: 11619196116661
                        ที่อยู่: ลาดพร้าว 87 แยก 10 บ้านเลขที่ 322/9 จ. กรุงเทพ อ. วังทองหลาง
                        แขวง คลองเจ้าคุณสิงห์ 10310
                    </p>
                    <span class="badge badge-info badge-pill">
                        ที่อยู่ส่งเอกสาร
                    </span>
                </div>
                <div class="col-md-2 d-flex align-items-center">
                    <button class="btn btn-success btn-block" data-toggle="modal"
                        data-target="#editlocation">แก้ไขที่อยู่</button>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="col-md-12">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-2" (click)="ondd()" *ngIf="permission.canPublish">
                    <a><i class="fal fa-plus-circle"></i> <b>เพิ่มที่อยู่</b></a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-modaleditlocation [typeadd]="typeadd" [billing]="company?.data.billing" [shipping]="company?.data.shipping"
    [locationData]="location" [companyID]="company?.data.id" (onSave)="onAfterSaveLocation($event)">
</app-modaleditlocation>
<app-modal-editcompany [Company]="company" (onSave)="onAfterSavecompany($event)"></app-modal-editcompany>