import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalImportFileModel } from '../models/import-file-model';
import { NotificationService } from '@app/core/_Services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-import-file',
  templateUrl: './modal-import-file.component.html',
  styleUrls: ['./modal-import-file.component.scss']
})
export class ModalImportFileComponent implements OnInit {
  modalReference!: NgbModalRef;
  @ViewChild('modalImportFile') modalImportFile!: ModalImportFileComponent

  @Input() dataModal: ModalImportFileModel
  @Input() showExample: boolean = true;
  @Input() textDefault = "อัพโหลดไฟล์ excel ที่กรอกแล้วเรียบร้อย เพื่อเพิ่มเข้าระบบ";
  @Input() showFuelWallet = false;
  @Input() fuelBalance: number = 0;

  fileImport: File;
  fileTemplateName: string = '';
  webBase360 = environment.webBase360;

  constructor(
    private modalService: NgbModal,
    private _noti: NotificationService,

  ) { }

  ngOnInit() {
    this.fileImport = null;
    if (this.dataModal) {
      const str = this.dataModal?.fileTemplatePath.split('/');
      this.fileTemplateName = str.slice(-1)[0];
    }
  }

  open(size?: 'sm' | 'md' | 'lg' | string): NgbModalRef {
    this.ngOnInit();
    if (size) {
      this.modalReference = this.modalService.open(this.modalImportFile, { size: size, centered: true, backdrop: 'static' });
    } else {
      this.modalReference = this.modalService.open(this.modalImportFile, { size: 'md', centered: true, backdrop: 'static' });
    }
    return this.modalReference;
  }

  close(bool: boolean): void {
    this.modalReference.close(bool ? this.fileImport : null);
  }

  ngxdropzonefile_remove(f: File) {
    this.fileImport = null;
  }
  ngxdropzonefile_change(event): void {
     console.log('file import', event);
     if(event.rejectedFiles.length > 0){
      this._noti.toasterror('รูปแบบไฟล์ไม่ถูกต้อง',`ต้องเลือกไฟล์ที่เป็นสกุลไฟล์ ${this.dataModal?.acceptType ?? 'xlsx'} เท่านั้น`);
     }
    this.fileImport = event.addedFiles[0]
  }
}

