import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-create-booking',
  templateUrl: './modal-create-booking.component.html',
  styleUrls: ['./modal-create-booking.component.scss']
})
export class ModalCreateBookingComponent implements OnInit {

  @Input() bookingData = [];
  constructor(    private router: Router,
    ) { }

  ngOnInit(): void {
  }

  goToBooking(id){

  window.open('/booking/details/'+ id, '_blank')
  }

}
