import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-truck-plate',
  templateUrl: './truck-plate.component.html',
  styleUrls: ['./truck-plate.component.scss']
})
export class TruckPlateComponent implements OnInit {

  @Input() title: string;
  @Input() plateNumber: string;
  @Input() province : string;
  constructor() { }

  ngOnInit(): void {
    if(this.province == null){
      var n = this.plateNumber.trim().split(" ");
      if(n.length > 1){ //พยายามแยก จังหวัดออกมา
        var splitted_province = n[n.length - 1];
        var hasNumber = /\d/; 
        if(!hasNumber.test(splitted_province)){ //เช็กว่าจังหวัดที่แยกออกมา มีตัวเลขมั้ย ถ้ามีตัวเลข แสดงว่าไม่ใช่จังหวััด จะไม่ทำอะไรต่อ
          this.province = splitted_province;
          this.plateNumber = this.plateNumber.replace(this.province, '').trim();
        }
      }
    }
  }

}
