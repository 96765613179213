import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamexternal',
  templateUrl: './teamexternal.component.html',
  styleUrls: ['./teamexternal.component.scss']
})
export class TeamexternalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }
}
