import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PackagingUnitService {

  constructor(private _apiService: HttpService) { }
  destroy$: Subject<boolean> = new Subject<boolean>();
  

  async getPackagingUnit():Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/PackagingUnit`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);       
      });
    }); 
  }
}
