import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trip-history-report',
  templateUrl: './trip-history-report.component.html',
  styleUrls: ['./trip-history-report.component.scss']
})
export class TripHistoryReportComponent implements OnInit {
  value = environment.tripHistoryReportURl
  reportUrl: any = ''
  currenClient;
  constructor(public sanitizer: DomSanitizer, public auth: AuthenticationService) {
    this.auth.currentUser.subscribe((res) => {
      this.currenClient = res.ClientID;

    }); }

  ngOnInit(): void {
    this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.value+ this.currenClient )
    // this.reportUrl += "?clientId=" +  this.currenClient ;
    console.log(this.reportUrl)
    // this.pageload.hide();
  }

}
