import { ShipmentConfirmPriceDto } from '@app/modules/booking/models/2022/confirm-price-modal';
import { String } from 'aws-sdk/clients/signer';

export class ShipmentTrackingStatusModel {
  clientName: string;
  trackBookingNo: string;
  customerName: string;
  origin: string;
  originDate: string;
  originFullAddress: string;
  destinationFullAddress: string;
  destination: string;
  destinationDate: string;
  truckTypeName: string;
  truckBookingStatusName: string;
  badgeStatus: string;
  shipmentDetails: ShipmentTrackingDetailModel[] = new Array<ShipmentTrackingDetailModel>();
}

export class ShipmentTrackingDetailModel {
  billReceiptStatusId: number;
  docStatus: String;
  endDate?: Date;
  startDate?: Date;
  shipmentId: number;
  shipmentNo: string;
  shipmentNoDisplay: string;
  driverUserId: number;
  driverFirstname: string;
  driverLastname: string;
  driverPhotoURL: string;
  driverMobileNo: string;
  truckLicense: string;
  truckLicenseProvince: string;
  truckLicenseTail : string;
  truckLicenseTailProvince : string;
  truckTypeName: string;
  truckTypePhoto: string;
  pO_Status: string;
  docReturn: string;
  currentSeqence: number;
  maxSeqence: number;
  currentStatus: string;
  currentShortAddress: string;
  currentDate: string;
  followupComment: string;
  followupStatusId: number;
  shipmentStatusId: number;
  billReceiptStatusName: string;
  docReturnStatusId: number;
  docReturnStatusName: string;
  clientCarrierName: string;
  truckBookingId: number;
  clientCarrierId: number;
  isTruckPlus:boolean;
  shipmentConfirmPrice: ShipmentConfirmPriceDto;
  truckBookingPoExpenses: TruckBookingPoExpenses[] = new Array<TruckBookingPoExpenses>();
  shipmentExpenseServiceReceiveds: ShipmentExpenseServiceReceivedDto[];
  truckBookingSoExpenses: TruckBookingSoExpenseDto[] ;
  shipmentExpenseServiceIssueds: ShipmentExpenseServiceIssued[] = new Array<ShipmentExpenseServiceIssued>();
  shipmentStatusDetails: ShipmentTrackingStatusDetailModel[] = new Array<ShipmentTrackingStatusDetailModel>();
  addOnExpenseRemark: string;
  isHasAddOnExpense: boolean;
  modifiedOn:Date | null;
  shipmentFileattachs:TrackingFileAttachs[]
}

export class ShipmentTrackingStatusDetailModel {
  activity?: Array<any>;
  companyName: string;
  fullAddress?: string;
  planDateFrom?: Date;
  planDateTo?: Date;
  sequence: number;
  shipmentNo: null;
  shortAddress: string;
  status: string;
  statusDate?: Date;
  statusName: string;
  trackBookingNo: string;
  isSuccessed: boolean;
  isActive: boolean;
  truckbookingLocationId: number;
  locationNumber: string;
  locationId: number;
  moreImg: number;
  shipmentLocationTrackingFileAttachs?: TrackingFileAttachs[] = new Array<TrackingFileAttachs>();
  confirmedType?: number;
  locationRemark: string;
  notDeliverRemark: string;
}

export class TruckBookingPoExpenses {
  discount: number;
  expenseTypeId: number;
  expenseTypeName: string;
  id: number;
  isVat: boolean;
  isWht: boolean;
  price: number;
  priceUnitId: number;
  priceUnitName: string;
  quantity: number;
  remarks: string;
  shipmentId: number;
  totalPrice: number;
  truckBookingPoId: number;
  valid: boolean;
  vat: number;
  vatPercent: number;
  wht: number;
  whtPercent: number;
  estPrice: number | null;
  net: number;
  modifiedBy: string;

}
export interface ShipmentExpenseServiceReceivedDto {
  id: number;
  shipmentId: number;
  truckBookingId: number | null;
  expenseTypeId: number;
  expenseTypeName: string;
  quantity: number;
  price: number;
  estPrice: number;
  totalPrice: number;
  discount: number;
  isVat: boolean;
  vat: number | null;
  isWhat: boolean;
  wht: number | null;
  truckBookingPoId: number;
  priceUnitId: number;
  priceUnitName: string;
  valid: boolean;
  net: number;
  modifiedBy: string;
}
export interface TruckBookingSoExpenseDto {
  id: number;
  truckBookingId: number | null;
  truckBookingSoId: number;
  expenseTypeId: number;
  expenseTypeName: string;
  quantity: number;
  price: number;
  estPrice: number;
  priceUnitId: number;
  priceUnitName: string;
  totalPrice: number;
  discount: number;
  vat: number;
  isVat: boolean;
  vatPercent: number;
  wht: number;
  isWht: boolean;
  whtPercent: number;
  shipmentId: number;
  remarks: string;
  remark: string;
  valid: boolean;
  net: number;
  modifiedBy: string;
}

export class ShipmentExpenseServiceIssued {
  truckBookingId: number | null;
  discount: number;
  expenseTypeId: number;
  expenseTypeName: string;
  id: number;
  isVat: boolean;
  isWht: boolean;
  price: number;
  priceUnitId: number;
  priceUnitName: string;
  quantity: number;
  remarks: string;
  remark: string;
  shipmentId: number;
  totalPrice: number;
  truckBookingSoId: number;
  valid: boolean;
  vat: number;
  vatPercent: number;
  wht: number;
  whtPercent: number;
  aR_StatusId:number;
  estPrice: number;
  net: number;
  modifiedBy: string;
}

export class TrackingFileAttachs {
  displayUri: string;
  filePath: string;
  id: number;
  isPhoto: boolean;
  resourceUri: string;
  valid: boolean;
  refShipmentFileAttachId: number | null;
}
