<div class="row">
  <div class="col-md-12">
    <label class="form-label" for="txtnamelocation1">{{InputText}} <span class="txt-red">*</span></label>
    <span *ngIf="!ReadOnly">
      <label *ngIf="Data.placeId" class="form-label after-map-select-input" for="txtnamelocation1"
        (click)="openmapWithValue()"><i class="fas fa-map-marked-alt"></i> เลือกสถานที่ </label>
    </span>
    <!-- <input *ngIf="Data.placeid" type="text" id="txtnamelocation" name="txtnamelocation" class="form-control" placeholder="กรอกชื่อสถานที่" [(ngModel)]="Data.shortaddress" (change)="shortAddressChange()"> -->
    <!-- <input *ngIf="!Data.placeid" type="text" id="txtnamelocation" name="txtnamelocation" class="form-control" placeholder="คลิกเพื่อกำหนดในแผนที่" (click)="openmap()"> -->
    <!-- After Confirm location -->
    <div class="input-group" *ngIf="Data.placeId">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fal fa-map-marker-alt"></i></span>
      </div>
      <input type="text" id="txtnamelocation1" name="txtnamelocation" class="form-control text-left" autocomplete="false"
        value="กรอกชื่อสถานที่" [(ngModel)]="Data.shortAddress"  [disabled]="ReadOnly">

        
    </div>
    <!-- Before Confirm location -->
    <div class="input-group" *ngIf="!Data.placeId">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fal fa-map-marker-alt"></i></span>
      </div>
      <input type="button" id="txtnamelocation2" name="txtnamelocation" autocomplete="false" [ngClass]="{'invalid': !valid}"
        class="form-control text-left" value="คลิกเพื่อกำหนดในแผนที่" (click)="openmap()">
    </div>
    <small id="mapHelp1" class="form-text text-muted mx-1" *ngIf="Data.fullAddress">ที่อยู่จากแผนที่:
      {{Data.fullAddress}}</small>
    <small id="mapHelp2" class="form-text text-muted mx-1" *ngIf="Data.lat">พิกัดแผนที่ : {{Data.lat}},
      {{Data.lng}}</small>
      <div class="row" *ngIf="Data.contactName && Data.placeId">
        <div class="col-md-auto col-6">
          <i class="fas fa-user-circle txt-green"></i> &nbsp; <span>{{Data.contactName}}</span>
        </div>
        <div class="col-md-auto col-6">
          <i class="fas fa-phone-square-alt  txt-green"></i> &nbsp;  <span>{{Data.contactPhone | mask:'000-000-0009'}}</span>
        </div>
      </div>
  </div>


</div>

<div class="modal fade" id="{{MapModal}}" tabindex="1" role="dialog" aria-hidden="true" data-keyboard="false"
  data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{IsMaster?'ที่อยู่ที่บันทึกไว้':'ที่อยู่จากแผนที่'}}</h5>
        <button type="button" class="close" (click)="closemap()">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="panel-container show">
          <div class="panel-content" *ngIf="!IsMaster || isMasterViewMap">
            <div class="form-group"  *ngIf="FavoriteShow || isMasterViewMap" >
              <label class="form-label" for="Se{{MapModal}}"> </label>
              <input type="checkbox" id="Se{{MapModal}}" [(ngModel)]="isCustomer">
              <label class="form-label"for="Se{{MapModal}}"> สถานที่ของลูกค้า</label>
            </div>
            <div class="mb-2" *ngIf="FavoriteShow || isMasterViewMap">
              <ng-select *ngIf="isCustomer" [(ngModel)]="customerId">
                <ng-option *ngFor="let u of customerList" [value]="u.id">{{u.customerName}}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group mb-2">
              <label class="form-label">ค้นหาสถานที่</label>
              <input type="search" #searchinput id="mapaddress" class="form-control"
                placeholder="ค้นหาจากชื่อหรือรหัสสถานที่" (keydown)="searchWithTextFn($event)">
                <span *ngIf="locError" class="text-danger">ไม่สามารถเลือกสถานที่นี้ได้ กรุณาเลือกสถานที่ใหม่อีกครั้ง</span>
            </div>
            <div class="form-group mb-2">
              <label class="form-label">ชื่อสถานที่</label>
              <input type="text" id="mapaddressShort" [(ngModel)]="tmpLocationData.shortAddress" class="form-control"
                placeholder="ตั้งชื่อสถานที่">
            </div>

            <!-- <div class="row">

              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="">ผู้ติดต่อ</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input type="text" class="form-control" name="" id="" [(ngModel)]="tmpLocationData.contactName" />
                  </div>
                </div>
              </div>
  
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="">เบอร์โทร</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" name="" id="" mask="000-000-0009"
                      [(ngModel)]="tmpLocationData.contactPhone" placeholder="โปรดกรอกเบอร์โทรศัพท์ 10 หลัก" [ngClass]="{
                        invalid:false
                      }" />
                  </div>
                </div>
              </div>
            </div> -->


           
            <div class="collapse mb-3" id="collapseIsFavLocation" *ngIf="FavoriteShow || isMasterViewMap">
              <div class=" border rounded">
                <div class="input-group p-2 mb-0">
                  <input type="text" class="form-control  shadow-inset-2 " id="js-list-msg-filter"
                    placeholder="ค้นหา สถานที่ ที่บันทึก">
                  <div class="input-group-append">
                    <div class="input-group-text ">
                      <i class="fal fa-search fs-xl"></i>
                    </div>
                  </div>
                </div>
                <ul id="js-list-msg" class="list-group px-2 pb-2">
                  <div class="d-flex justify-content-center" *ngIf="loadingLocation">
                    <div class="spinner-grow text-success mt-3 mb-3 ml-3 mr-3" role="status"></div>
                  </div>
                  <li class="list-group-item" [ngClass]="{'backdrop': loadingLocation}"
                    *ngFor="let item of LocationList;"
                    [ngClass]="item.clientLocationId === tmpLocationData.clientLocationId?' list-group-active':''"
                    (click)="onMasterSelected(item)">
                    <a>
                      <h5>{{item.shortAddress}}</h5>
                      <small>{{item.fullAddress}}</small>
                    </a>
                  </li>
                  <div class="row justify-content-center ml-0 mr-0 mt-3"
                    *ngIf="!loadingLocation && (!LocationList || LocationList.length <= 0)">
                    <h5>ไม่พบผลการค้นหา</h5>
                  </div>
                </ul>
                <div class="filter-message js-filter-message mt-0 fs-sm"></div>
              </div>
            </div>
            <div class="form-group">
              <div class="frame-wrap" *ngIf="isLoadMap">
                <div class="p-3" style="border:0;height: 450px;">
                  <div class="d-flex justify-content-center">
                    <strong style="margin-top: 25%;">กำลังโหลด แผนที่...</strong>
                  </div>
                </div>
              </div>
              <div #mapView style="border:0;height: 376px;"></div>
              <!-- <iframe src="https://maps.google.com/maps?q={{lat}},{{lng}}&z=15&output=embed" class="w-100" frameborder="0" style="border:0;height: 450px;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
            </div>
          </div>


          <div class="panel-content" *ngIf="IsMaster && !isMasterViewMap">
            <div class="form-group mb-2">
              <label class="form-label">ค้นหาสถานที่ที่บันทึกไว้</label>
              <input type="search" id="searchmasterddress" [(ngModel)]="searchText" class="form-control"
                placeholder="ค้นหาจากชื่อหรือรหัสสถานที่" (keyup)="updateKeyText()">
            </div>
            <ul class="list-group px-2 pb-2">
              <div class="d-flex justify-content-center" *ngIf="loadingLocation">
                <div class="spinner-grow text-success mt-3 mb-3 ml-3 mr-3" role="status"></div>
              </div>
              <li class="list-group-item" [ngClass]="{'backdrop': loadingLocation}"
                *ngFor="let item of LocationList;let i = index"
                [ngClass]="item.clientLocationId === tmpLocationData.clientLocationId?' list-group-active':''"
                (click)="onMasterSelected(item)">
                <a>
                  <h5>{{item.locationNumber +' '+item.shortAddress}}</h5>
                  <small>{{item.fullAddress}}</small>
                </a>
              </li>
              <div class="row justify-content-center ml-0 mr-0 mt-3"
                *ngIf="!loadingLocation && (!LocationList || LocationList.length <= 0)">
                <h5>ไม่พบผลการค้นหา</h5>
              </div>
            </ul>
            <label class="form-label after-map-select-input mt-3" style="font-size: 12px;" (click)="MasterViewMap()"><i
                class="fas fa-map-marked-alt"></i> หากไม่พบผลการค้นหา คลิกที่นี่เพื่อสร้างสถานที่ใหม่</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row w-100 ml-0 mr-0">
          <div class="col-6">
            <ng-container *ngIf="isMasterViewMap">
              <button class="btn btn-secondary" type="button" (click)="MasterViewMap()">เลือกจากสถานที่บันทึกไว้</button>
              <!-- data-toggle="collapse" data-target="#collapseIsFavLocation" aria-expanded="false" aria-controls="collapseIsFavLocation" -->
            </ng-container>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-success float-right"[disabled]="locError"mes (click)="onSubmit()">บันทึก</button>
            <button type="button" class="btn btn-primary float-right mr-3" (click)="closemap()">ยกเลิก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>