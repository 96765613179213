import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService, NotificationService, PageloadingService } from '@app/core/_Services';
import { CryptoJsService } from "@app/core/_Services/crypto-js.service";
import { bool } from 'aws-sdk/clients/signer';
import { interval, Observable, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  forgetpasswordForm = new FormGroup({
    forgetType: new FormControl('', Validators.required),
  });
  otpForm = new FormGroup({
    otp: new FormControl('', [
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ]),
  });
  otpSection = false;
  isFormsubmit = false;

  OtpBlockCount = 5; // นับจำนวนครั้งการส่ง
  OtpBlockCountTimer = 90; // นับถอยหลังส่งครั้งต่อไป
  otpCount: number = 0;
  dataType: number = 0; // 1=Email, 2=PhoneNumber
  otpCountdown: number = 0;
  stopPlay$: any;


  constructor(
    private cryto: CryptoJsService, private _apiService: HttpService, private router: Router,
    private _noti: NotificationService,
    private httpClient: HttpClient,  private  route:Router
  ) {
  }
  TitleSendmail: string;
  onsaveing: boolean = false;

  EmailGroup = new FormGroup({
    Email: new FormControl(null, Validators.required),
  });

  ngOnInit(): void {
    $('body').removeAttr('class');

  }
  ngAfterViewInit() {
    //$('body').removeAttr('class');
  }

  async onSubmit() {
    // var now = new Date();
    // now.setMinutes(now.getMinutes() + 15); // timestamp
    // now = new Date(now); // Date object

    this.onsaveing = true;
    this.TitleSendmail = "";
    let resetValue = await {
      forgetTypeEnum: 1,
      appPackageID: 3,
      userPoolEnum: 1,
      username: this.EmailGroup.value.Email,
      callbackURL: "http://"+window.location.host +"/resetpassword",
      //callbackURL:"http://tms-dev.360techx.co/resetpassword"
    }

    await this.forgetpasswordAPI(resetValue).subscribe
      (
        (data) => {
          if (data?.isSuccess) {
            //this._noti.toastsuccess('', "ทำรายการสำเร็จ");
            this.onsaveing = false;
            //this.TitleSendmail = "ระบบได้ส่งอีเมลไปที่ "+this.EmailGroup.value.Email+" แล้ว โปรดตรวจสอบอีเมลเพื่อเปลี่ยนรหัสผ่าน";
            var issave = this._noti.swalConfirmPassword("ทำรายการสำเร็จ", "ระบบได้ส่งอีเมลไปที่ " + this.EmailGroup.value.Email + " แล้ว โปรดตรวจสอบอีเมลเพื่อเปลี่ยนรหัสผ่าน");
            if (issave) {
              this.router.navigate(['/login']);
            }
          } else {
            this._noti.toasterror('', data?.message);
            this.onsaveing = false;
            return;
          }
        }
      );


    // const token = this.cryto.encrypt(resetValue);

    // const linkResetPassword = window.location.hostname + '/resetpassword?t=' + token;
  }


  async onSubmitEmail() {
    // var now = new Date();
    // now.setMinutes(now.getMinutes() + 15); // timestamp
    // now = new Date(now); // Date object
    const value = this.forgetpasswordForm.value.forgetType;
    this.onsaveing = true;
    this.TitleSendmail = "";
    let resetValue = await {
      forgetTypeEnum: 1,
      appPackageID: 3,
      userPoolEnum: 1,
      username:  value,
      callbackURL: "http://"+window.location.host +"/resetpassword",
      //callbackURL:"http://tms-dev.360techx.co/resetpassword"
    }

    await this.forgetpasswordAPI(resetValue).subscribe
      (
        (data) => {
          if (data?.isSuccess) {
            //this._noti.toastsuccess('', "ทำรายการสำเร็จ");
            this.onsaveing = false;
            //this.TitleSendmail = "ระบบได้ส่งอีเมลไปที่ "+this.EmailGroup.value.Email+" แล้ว โปรดตรวจสอบอีเมลเพื่อเปลี่ยนรหัสผ่าน";
            var issave = this._noti.swalConfirmPassword("ทำรายการสำเร็จ", "ระบบได้ส่งอีเมลไปที่ " + value + " แล้ว โปรดตรวจสอบอีเมลเพื่อเปลี่ยนรหัสผ่าน");
            if (issave) {
              this.router.navigate(['/login']);
            }
          } else {
            this._noti.toasterror('', data?.message);
            this.onsaveing = false;
            return;
          }
        }
      );


    // const token = this.cryto.encrypt(resetValue);

    // const linkResetPassword = window.location.hostname + '/resetpassword?t=' + token;
  }

  forgetpasswordAPI(data: any): Observable<any> {
    return this._apiService.postIdenv3(`/Auth/ForgetPassword`, data);
  }

  onForget() {
    const value = this.forgetpasswordForm.value.forgetType;

    //check mobile pettern
    const regx = new RegExp('^[+ 0-9]{10}$');
    if (regx.test(value)) {
      this.dataType = 2;
      this.onSentForget();
    } else {
      //check email pettern
      const regxEmail = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (regxEmail.test(value)) {
        this.dataType = 1;
        this.onSentForget();
      } else {
        const checkNan = value.replace('-', '');
        if (!isNaN(+checkNan)) {
          // number
          this.forgetpasswordForm.controls.forgetType.setErrors({
            pattern: 'หมายเลขโทรศัพท์ไม่ถูกต้อง ',
          });
        } else {
          // text
          this.forgetpasswordForm.controls.forgetType.setErrors({
            email: 'กรอกอีเมมล์ไม่ถูกต้อง ',
          });
        }
      }
    }
  }


  onSentForget() {

    this.isFormsubmit = true;
    const value = this.forgetpasswordForm.value.forgetType;
    const domain = window.location.protocol + '//' + window.location.host;
    let data = {
      forgetTypeEnum: this.dataType, // 1=Email, 2=PhoneNumber
      appPackageID: 1,
      userPoolEnum: 1,
      username: value,
      callbackURL: this.dataType == 1 ? domain + '/resetpassword' : null,
    };

    if (this.dataType == 2) {
      this.otpCountdown = this.OtpBlockCountTimer;
      this.otpCount++;
    }
    if (this.otpCount >=  this.OtpBlockCount) {
      this._noti.toasterror('ทำรายการถี่เกินไป', 'กรุณาลองใหม่อีกครั้ง');

      //emit value every 1s
      const source = interval(1000);
      const timer$ = timer(this.OtpBlockCountTimer * 1000);
      const example = source.pipe(takeUntil(timer$));
      const subscribe = example.subscribe((val) => {
        this.otpCountdown--;

        if (this.otpCountdown == 1) {
          this.otpCountdown = this.OtpBlockCountTimer;
          this.otpCount = 0;
        }
      });
      return;
    }
    if(this.dataType == 2){ //mobile number
      this.httpClient
      .post(environment.IdentityAPIUrl_V3 + '/Auth/ForgetPassword', data)
      .subscribe((res: any) => {
        this.isFormsubmit = false;
        if (res?.isSuccess) {
          if (this.dataType == 2) {
            this._noti.toastsuccess(
              'เรียบร้อย',
              'ระบบได้ส่งรหัส OTP ไปที่เบอร์ ' + value
            );
            this.otpSection = true;

          } else {
            this._noti.toastsuccess(
              'เรียบร้อย',
              'ระบบได้ส่งลิงก์เปลี่ยนรหัสผ่านไปที่ ' + value
            );
            this.forgetpasswordForm.reset();
            setTimeout(()=>{ this.route.navigate(['/']); }, 5000);

          }
        } else {
          switch (res?.statusCode) {
            case 404:
              this._noti.toasterror(value, 'ไม่พบข้อมูลในระบบ');
              break;

            default:
              this._noti.toasterror(
                'ไม่สามารถทำรายการได้',
                'กรุณาลองใหม่อีกครั้ง'
              );
              break;
          }
        }
      });
    }else{
      this.onSubmitEmail();
    }

  }

  onOtp() {
    const value = this.forgetpasswordForm.value.forgetType;
    const otp = this.otpForm.value.otp;
    let VerifyOTPDto = {
      userPoolEnum: 1,
      appPackageID: 1,
      identityUserId: null,
      phoneNumber: value,
      otpCode: otp,
      tokenType: 1, // 0 = UNKNOWN, 1 = ResetPassword, 2 = ChangePhoneNumber
    };
    this.httpClient
      .post(environment.IdentityAPIUrl_V3 + '/Auth/VerifyOTP', VerifyOTPDto)
      .subscribe((res: any) => {
        this.isFormsubmit = false;
        if (res?.isSuccess) {
          this._noti.toastinfo(
            'เรียบร้อย','ยืนยันรหัส OTP สำเร็จ.'
          );
          let resetpasswordOtp = {
              key:value,
              resetToken: encodeURIComponent(res?.token)
          };
          sessionStorage.setItem('rpwotp',JSON.stringify(resetpasswordOtp));
          this.route.navigate(['/resetpassword']);
        } else {
          switch (res?.statusCode) {
            case 417:
              this._noti.toasterror(
                otp,
                'รหัส OTP ไม่ถูกต้อง. หรือหมดอายุ'
              );
              break;

            default:
              this._noti.toasterror(
                'ไม่สามารถทำรายการได้',
                'กรุณาลองใหม่อีกครั้ง'
              );
              break;
          }
        }
      });
  }

}
