<div class="row mt-2">
    <div class="col-md-6">
        <label class="form-label" for="simpleinput">ค้นหาทีมที่คุณต้องการ</label>
        <div class="input-group">
            <input type="text" class="form-control" #searchTeamTxt (keyup)="searchTeam()" placeholder="ชื่อทีม / รหัสทีม ">
            <div class="input-group-append">
                <button class="btn btn-outline-default" type="button" id="btnsearchteam" (click)="searchTeam()"><i
                        class="fal fa-search fs-md"></i></button>
            </div>
        </div>
    </div>
    <div class="col-md-6 text-right " *ngIf="permission70200.actionDto | permission :2">
        <label class="form-label" >&nbsp;</label>
        <div class="input-group justify-content-end">
            <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modaladdteam" (click)="addteam()">สร้างทีม</button>
        </div>
    </div>
    <div class="col-12 mt-3">
        <table class="table table-bordered table-hover table-striped table-sm w-100">
            <thead class="bg-fusion-500">
                <tr>
                    <th class="text-center" style="width: 5%;">No.</th>
                    <th style="width: 15%;">รหัสทีม</th>
                    <th style="width: 25%;">ชื่อทีม</th>
                    <th style="width: 10%;">สถานะทีม</th>
                    <th style="width: 35%;">สมาชิกในทีม</th>
                    <th class="all table-action">ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of teamList|searchFilter:{'teamNumber':searchtxt,'teamName':searchtxt}; let i=index;">
                    <td class="text-center">
                        {{i+1}}
                    </td>
                    <td>{{item.teamNumber| uppercase}}</td>
                    <td>{{item.teamName }}</td>
                    <td>
                        <div class="badge  badge-pill badge-success" *ngIf="item.teamStatus">
                            เปิดใช้งาน
                        </div>
                        <div class="badge  badge-pill badge-secondary" *ngIf="!item.teamStatus">
                            ระงับการใช้งาน
                        </div>
                    </td>
                    <td class="text-right">
                        <div class="fs-sm d-flex justify-content-center flex-row-reverse " *ngIf="item.userImgList">
                            <ng-container *ngFor="let userImg of item.userImgList | slice:0:7;let img =index">
                                <a class="btn-m-s" data-toggle="tooltip" data-placement="top" [title]="userImg.altUser" (click)="onRowselect(item.teamId)">
                                    <span data-hasmore="+7" class="rounded-circle profile-image-sm" *ngIf="img > 6">
                                        <img src="{{userImg.imageUrl}}" (error)="onImgErrorProfile($event)" class="profile-image-sm rounded-circle zoom" [alt]="userImg.altUser">
                                    </span>
                                    <img src="{{userImg.imageUrl}}" (error)="onImgErrorProfile($event)" class="profile-image-sm rounded-circle zoom" [alt]="userImg.altUser" *ngIf="img <= 6">
                                </a>
                            </ng-container>
                            <ng-container *ngIf="item.userImgList.length <= 0">
                                <span class="mt-1">ไม่มีสมาชิก</span>
                            </ng-container>
                        </div>
                        <a style="color:#949494" (click)="onRowselect(item.teamId)" *ngIf="!item.userImgList"><i class="fal fa-plus"></i> เพิ่มสมาชิก</a>
                    </td>

                    <td>
                        <button class="btn btn-sm btn-icon btn-outline-success rounded-circle mr-1" title="View Record" (click)="onRowselect(item.teamId)">
                            <i class="fas fa-search"></i>
                        </button>
                        <div class="dropdown d-inline-block">
                            <button class="btn btn-sm btn-icon btn-outline-primary rounded-circle shadow-0" data-toggle="dropdown" aria-expanded="false" title="More options">
                                <i class="fal fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" *ngIf="permission70200.actionDto|permission:3" data-toggle="modal" data-target="#modaladdteam" (click)="editteam(item)">แก้ไข</a>
                                <a class="dropdown-item" *ngIf="permission70200.actionDto | permission:4" (click)="deleteTeam(item.teamId)">ลบทีม</a>
                            </div>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="alert bg-fusion-400 border-0 fade show">
            <div class="d-flex align-items-center">
                <div class="alert-icon">
                    <i class="fal fa-shield-check text-success"></i>
                </div>
                <div class="flex-1">
                    จำนวนทีมของคุณ : <span class="h5"><u>  {{teamList.length}}/5 ทีม</u></span>&nbsp;&nbsp;&nbsp;
                    <small>( หากคุณต้องการเพิ่มจำนวนททีม โปรดติดต่อเจ้าหน้าที่ 360TechX )</small>
                    <!-- <br> Install the latest version of SmartAdmin WebApp -->
                </div>
                <a class="btn btn-success btn-w-m fw-500 btn-sm">ติดต่อเจ้าหน้าที่</a>
            </div>
        </div>
    </div>
</div>
<app-modaladdteam [IsEdit]="isEdit"
    [editTeam]="editTeam"
    (OnSubmit)="AfterAddTeam()"
    [existTeam]="teamList"></app-modaladdteam>
