<ng-template #modalSuccess let-modal>
  <div class="modal-header text-center p-0">

    <div class="text-center bg-success w-100 p-5">
      <button type="button" class="close p-0" aria-label="Close"  (click)="closed(false)">
        <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
      </button>
      <div class="col-12">
        <span class="fa-8x" [ngClass]="iconClass"></span>
      </div>

    </div>
  </div>
  <div class="modal-body p-1">
    <div class="container-fluid text-center">
      <div class="row">
        <div class="col-12">
          <span class="h4">{{modalConfig.title}}</span>
        </div>
        <div class="col-12">
          <span class="h5">{{modalConfig.description}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="p-2">
    <div class="row pb-2 mt-2">
      <div class="col-12" *ngIf="!modalConfig.isNextButtonAction">
        <button class="btn btn-outline-success btn-block" (click)="closed(false)">{{modalConfig.buttonSubmitText}}</button>
      </div>
      <ng-container *ngIf="modalConfig.isNextButtonAction">
        <div class="col-12">
          <button class="btn btn-outline-success btn-block" (click)="closed(false)">{{modalConfig.buttonSubmitText}}</button>
        </div>
        <div class="col-12 mt-1">
          <button class="btn btn-success btn-block" (click)="nextActionClick(true)" *ngIf="modalConfig.isNextButtonAction"><i class="{{modalConfig.nextButtonActionIcon}}"></i> {{modalConfig.nextButtonActionText}}</button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
