import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmetnAssignCalendarComponent } from '../shipmetn-assign-calendar/shipmetn-assign-calendar.component';
import { MaterialsModule } from '@app/modules/materials/materials.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ShipmetnAssignCalendarComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialsModule,
    NgSelectModule,
    SharedModule,
  ]
})
export class ShipmentAssignCalendarModuleModule { }
