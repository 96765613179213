<!-- <app-fleet-dropdown (onFleetChange)="feetChange($event)" [ShowEditFleet]="false" [ShowAddFleet]="false">
</app-fleet-dropdown> -->

<!-- <div class="row mt-3">
  <div class="col-12">
    <ul class="nav nav-tabs nav-tabs-clean" role="tablist">
      <li class="nav-item">
        <a #tabPending class="nav-link" id="pending" data-toggle="tab" role="tab">รถบรรทุก</a>
      </li>
      <li class="nav-item">
        <a #tabPending class="nav-link active" id="pending" data-toggle="tab" role="tab">คนขับ</a>
      </li>
    </ul>
  </div>
</div> -->

<div class="col-12">
  <div class="row">
    <div class="col-12">
      <span class="h5 mt-2">ACTION</span>
    </div>

    <div class="col-12">
      <div class="row justify-content-start">
        <!-- <button class="btn btn-success m-1"><i class="fas fa-chart-pie"></i> วิเคราะห์ประสิทธิภาพ</button> -->
        <!-- <button class="btn btn-success m-1" (click)="openQRReaderModal()"><i class="fas fa-qrcode"></i> สแกน QR
          CODE</button> -->
        <!-- <button class="btn btn-success m-1" (click)="lookingTruckLocation()"><i class="fas fa-map-marker-alt"></i>
          ดูตำแหน่งรถ</button> -->
        <!-- <button class="btn btn-success m-1"><i class="fas fa-plus-square"></i> เพิ่มรถเข้า FLEET</button> -->
        <!-- <button class="btn btn-success m-1 float-right" (click)="openTruckLicenseActionModal(true)"><i class="fas fa-plus-square"></i> เพิ่มทะเบียนใหม่</button> -->
        <button class="btn btn-success m-1" *ngIf="permission130100.canEdit" [ngClass]="{'d-none':selectedFleet == 0}" (click)="addFleetDriverModal()"><i class="fas fa-plus-square"></i> เพิ่มคนขับเข้า FLEET</button>
        <button class="btn btn-success m-1" (click)="openmodaladddriverform()" *ngIf="permission130100.canPublish" id="driver-1"><i
            class="fas fa-plus-square"></i> เพิ่มคนขับใหม่</button>
        <button class="btn btn-success m-1 float-right" (click)="openImportFileModal()"  *ngIf="permission130100.canPublish" id="driver-2"><i
            class="fas fa-file-excel"></i>
          เพิ่มคนขับใหม่ด้วย Excel</button>
        <!-- <button class="btn btn-success m-1 float-right" (click)="openmodaladddriverform()"><i class="fas fa-file-excel"></i> เพิ่ทคนขับใหม่</button> -->
      </div>
    </div>

    <div class="col-12 border rounded mt-2">
      <app-driver-list-filter (searchClick)="searchFilterDriver($event)" (clearClick)="clearFilter()">
      </app-driver-list-filter>
    </div>
  </div>

</div>

<div class="row justify-content-around align-items-center">
  <div class="col-6 col-sm-8 col-lg-10 mt-3">
    <span class="h5">คนขับที่บันทึกแล้วใน FLEET {{selectedFleet}} : ทั้งหมด ({{ driverList?.length || 0 | number}}) </span>
  </div>
  <div class="col-6 col-sm-4 col-lg-2 mt-3 text-right">
    <div class="form-group">
      <div class="input-group">
        <div class="input-group-append input-group-prepend">
          <span class="input-group-text">
            <i class="fal fa-filter fs-xl"></i>
          </span>
        </div>
        <select class="custom-select" id="usertype" (change)="SetPaginationData()" [(ngModel)]="ItemsPerPage">
          <option [value]="10">10</option>
          <option [value]="50">50</option>
          <option [value]="75">75</option>
          <option [value]="100">100</option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="row overflow-auto">
  <div class="panel-container show w-100">
  <div class="col-12 ">

    <!-- <div class="card"> -->
    <!-- <div class="card-header py-2 d-flex">
        <div class="card-title mr-auto">
          <span *ngIf="fleetId > 0">รายชื่อคนขับใน FLEET{{fleetId}}</span>
          <span *ngIf="fleetId == 0">รายชื่อคนขับใน FLEET ทั้งหมด</span>
        </div>

        <div class="form-group" style="margin-bottom: 0 !important">
          <div class="input-group">
            <div class="input-group-append input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-filter fs-xl"></i>
              </span>
            </div>
            <select class="custom-select" id="usertype" aria-label="usertype">
              <option [value]="10">10</option>
              <option [value]="50">50</option>
              <option [value]="75">75</option>
              <option [value]="100">100</option>
            </select>
          </div>
        </div>

        <button type="button" class="btn btn-default waves-effect waves-themed ml-1">
          ย้อนกลับ
        </button>
        <div class="btn btn-secondary mr-1 ml-1">1</div>
        <button type="button" class="btn btn-default waves-effect waves-themed">
          กลับไป
        </button>
      </div> -->

    <table datatable id="driverListDataTable" class="table table-bordered table-hover table-striped table-sm w-100" #dtElement>
      <thead class="bg-fusion-500">
        <tr>
          <th class="text-center">ลำดับ</th>
          <th class="text-center">ชื่อ-นามสกุล</th>
          <th class="text-center">วันเกิด (อายุ)</th>
          <th class="text-center">เบอร์โทร</th>
          <th class="text-center">ใบขับขี่</th>
          <th class="text-center">สถานะผู้ใช้</th>
          <th class="text-center">สถานะรับงาน</th>
          <th class="text-center">FLEET</th>
          <th class="all table-action">ACTION</th>
        </tr>
      </thead>

      <tbody>
        <!-- <ng-container #tbody *ngFor="let item of driverList
            | searchFilter: {'firstName': filter.textSearch,'lastName': filter.textSearch,'mobileNo':filter.textSearch}
            | searchFilter: {'isEnabled':filter.isEnabled?filter.isEnabled:''}
            | searchFilter: {'driverLicenceStatus':filter.docStatus?filter.docStatus:''}
            | searchFilter: {'shipmentStatusID':filter.shipmentStatus?filter.shipmentStatus:''}
            | paginate: {
              id:'driver-pagination',
              itemsPerPage:ItemsPerPage,
              currentPage:driverPaging,
              totalItems:TotalData
            }; let i = index"> -->

            <ng-container #tbody *ngFor="let item of driverList
            | paginate: {
              id:'driver-pagination',
              itemsPerPage:ItemsPerPage,
              currentPage:driverPaging,
              totalItems:TotalData
            }; let i = index">

          <tr>
            <td class="text-center">{{((driverPaging - 1) * ItemsPerPage) + i + 1}}</td>
            <td>
              <a class="center btn-m-s" *ngIf="item.photo">
                <!-- <img src="{{item.photo}}" (error)="onImgErrorProfile($event)" class="profile-image-sm rounded-circle" alt="Profile"> -->
                <img class="profile-image-sm rounded-circle" src="{{ item.photo }}"
                  (error)="onImgErrorProfile($event)" />
                {{ item.firstName }} {{item.lastName}}
              </a>
              <span class="float-right" *ngIf="item?.isHasMobileApp"><i class="fal fa-mobile fa-2x" aria-hidden="true"></i></span>
            </td>
            <td class="text-center" >
              <span *ngIf="item?.dateOfBirth">
                {{ item?.dateOfBirth | thaidate : 'short'}} ({{getDriverAge(item?.dateOfBirth)}})
              </span>
            </td>

            <td class="text-center" >{{ item.mobileNo | mask: "000-000-0009"}}</td>
            <td class="text-center">
              <span class="badge badge-pill badge-{{item.driverLicenceStatusColor}}"
                *ngIf="item.driverLicenceStatus">{{item.driverLicenceStatus}}</span>
              <span class="badge badge-pill badge-danger" *ngIf="!item.driverLicenceStatus">ไม่ผ่าน</span>
            </td>
            <td class="text-center">
              <ng-select [items]="driverStatus" bindLabel="name" bindValue="status" [(ngModel)]="item.isEnabled"
                clearable (change)="updateUserIsEnabled(item)">
              </ng-select>
            </td>
            <td class="text-center">
              <span class="badge badge-pill {{item.isNotAvariable ? 'badge-danger' : 'badge-success'}} ">{{
                item.avariableText }}</span>

              <!-- <span *ngIf="item.shipmentStatusID != 20" class="badge badge-pill badge-success">ว่าง</span> -->
            </td>
            <td class="text-center">
              <span *ngIf="item.companyFleetNo">F{{item.companyFleetNo }}</span>
              <span *ngIf="!item.companyFleetNo">-</span>
            </td>
            <td>
              <div class="btn-group">
                <button type="button" class="
                      btn btn-sm btn-dark
                      dropdown-toggle
                      waves-effect waves-themed
                    " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  ACTION
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item text-center" [routerLink]="['/driver', item.user_ID]">ดูรายละเอียด</a>
                  <!-- <a class="dropdown-item text-center" (click)="openmodalviewdriverform(item.user_ID)" *ngIf="permission130100.canEdit">ดูข้อมูล</a> -->
                  <a class="dropdown-item text-center" (click)="openmodaleditdriverform(item.user_ID)" *ngIf="permission130100.canEdit">แก้ไขข้อมูล</a>
                  <a class="dropdown-item text-center" (click)="openChangeFleetDriverModal(item)"  *ngIf="permission130100.canEdit">เปลี่ยน FLEET</a>
                  <a class="dropdown-item text-center" (click)="openmodalchangepassword(item)"  *ngIf="permission130100.canEdit">เปลี่ยนรหัสผ่าน</a>
                  <a class="dropdown-item text-center" (click)="openmodalchangemobilenumber(item)"  *ngIf="permission130100.canEdit">เปลี่ยนเบอร์</a>
                  <!-- <a class="dropdown-item text-center" (click)="openmodalchangeemail(item)"  *ngIf="permission130100.canEdit">เปลี่ยนอีเมล</a> -->
                  <a class="dropdown-item text-center text-danger" (click)="onDeleteIdentity(item)"  *ngIf="permission130100.canDelete">ลบผู้ใช้งาน</a>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
    <div class="d-flex justify-content-center">
      <pagination-controls [id]="'driver-pagination'" (pageChange)="pageChanged($event)" responsive="true"
        previousLabel="ย้อนกลับ" nextLabel="ถัดไป">
      </pagination-controls>
    </div>
    <!-- </div> -->
  </div>

  </div>
</div>

<app-modalchangepassword #modalchangepassword></app-modalchangepassword>
<app-modalchangemobilenumber #modalchangemobilenumber></app-modalchangemobilenumber>
<!-- <app-modalchangeemail #modalchangeemail (onCallGetDriver)="getDriver()"></app-modalchangeemail> -->
<app-modalchangeemail #modalchangeemail></app-modalchangeemail>

<app-modal-edit-driver-form #modaladddriverform [IsDriver]="isDriver" [IsDisableDriverRole]="true"></app-modal-edit-driver-form>
<app-modal-edit-driver-form #modaleditdriverform [IsDriver]="isDriver"[IsDisableDriverRole]="true" [IsEdit]="true" [EditData]="userEditData"
  [IsView]="isViewUser" (AfterSubmit)="AfterFormSubmit($event)">
</app-modal-edit-driver-form>
<app-modal-edit-driver-form #modalviewdriverform [IsDriver]="isDriver" [EditData]="userEditData"
  [IsView]="true">
</app-modal-edit-driver-form>

<!-- <app-modal-add-fleet-driver [currentFleet]="currentFleet" (Refresh)="getDriver()"></app-modal-add-fleet-driver> -->

<!-- import file excel modal -->
<app-modal-import-file #modalImportFile></app-modal-import-file>
<app-modal-preview-import-data #modalPreviewImportData></app-modal-preview-import-data>
<app-modal-import-result #modalImportResult></app-modal-import-result>

<app-modal-qr-reader *ngIf="openQR" (isclose)="camClosed()" [isRead]="true"></app-modal-qr-reader>

<app-modal-change-fleet-driver #ModalChangeFleetDriver></app-modal-change-fleet-driver>

<app-modal-success #modalSuccess  (btnother_event)="success_other($event)"></app-modal-success>
<app-modal-confirm #modalConfirm></app-modal-confirm>
<app-modal-add-fleet-driver [currentFleet]="selectedFleet" [selectedDriver]="selectedDriver" [selectedDriverIds]="selectedDriverIds" (Refresh)="getDriverList()"></app-modal-add-fleet-driver>
