import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { googlemapComponent } from '../googlemap.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';
import { googlemap2Component } from '../googlemap2.component';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [googlemapComponent,googlemap2Component],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, NgSelectModule, NgxMaskModule],
  exports: [googlemapComponent,googlemap2Component],
  providers: [CustomerserviceService],
})
export class GooglemapModule {}
