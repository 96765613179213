<div class="row" *ngIf="permission90200.canRead">
    <div class="col-xl-12">
        <div id="panel-1" class="panel">
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="row" style="overflow-x:auto;">
                        <table id="ClientSettinglist" class="table table-striped table-sm w-100">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="bg-info text-white text-center" [attr.colSpan]="linegroup.length">Line Group Notify</th>
                                    <th class="bg-success text-center" colspan="4">In-app noti</th>
                                    <th class="bg-warning text-center" colspan="1">SMS</th>
                                </tr>
                                <tr class="text-center">
                                    <th></th>
                                    <th *ngFor="let itemli of linegroup">{{itemli.groupline}}</th>
                                    <th>Supply Chain Department</th>
                                    <th>Sales Deparment</th>
                                    <th>Plant/Warehouse Department</th>
                                    <th>Accounting Deparment</th>
                                    <th>SMS</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of carriersetting?.data; let i = index">
                                <tr>
                                    <td style="white-space:nowrap;">
                                        <h6>{{item.notiEventCategoryName}}</h6>
                                    </td>
                                    <td *ngFor="let linelist of item.lineGroup" class="text-center">
                                        <ng-select id="line{{i}}{{i+1}}" [(ngModel)]="linelist.notiByLineGroup.notiScopeId" *ngIf="linelist.notiByLineGroup != null" placeholder="แจ้งเตือน" [clearable]="false">
                                            <ng-option *ngFor="let Scope of linelist.notiScope" [value]="Scope.id">
                                                {{Scope.notiScopeName}}
                                            </ng-option>
                                        </ng-select>
                                        <label *ngIf="linelist.notiByLineGroup == null">N/A</label>
                                    </td>
                                    <td *ngFor="let linelist of item.departmentSetting" class="text-center">
                                        <ng-select [(ngModel)]="linelist.notiByDepartment.notiScopeId" *ngIf="linelist.notiByDepartment != null" placeholder="แจ้งเตือน" [clearable]="false">
                                            <ng-option *ngFor="let Scope of linelist.notiScope" [value]="Scope.id">   
                                                {{Scope.notiScopeName}}                                   
                                            </ng-option>
                                        </ng-select>
                                        <label *ngIf="linelist.notiByDepartment == null">N/A</label>
                                    </td>
                                    <td class="text-center">
                                        <ng-select [(ngModel)]="item?.clientSettingNotiSms.notiScopeId" *ngIf="item.clientSettingNotiSms != null" placeholder="แจ้งเตือน" [clearable]="false">
                                            <ng-option *ngFor="let Scope of item.notiScopeSms" [value]="Scope.id">   
                                                {{Scope.notiScopeName}}                                 
                                            </ng-option>
                                        </ng-select>
                                        <label *ngIf="item.clientSettingNotiSms == null">N/A</label>
                                    </td>
                                </tr>
                                <tr *ngFor="let items of item.notiEventCategoryItems">
                                    <th>
                                        <small>{{items.notiEventCategoryItemName}}</small>
                                    </th>
                                </tr>
                                  </tbody>
                        </table>
                    </div>
                    <div class="row mt-5 d-flex justify-content-center">
                        <div class="col-md-3 text-center">
                            <button (click)="OnSaveData()" [disabled]="DataSaveing" type="button" class="btn btn-success btn-block" *ngIf="permission90200.canEdit">
                                บันทึก</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!permission90200.canRead">
    <div class="col-xl-12">
        Access Denied
    </div>
</div>