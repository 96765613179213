<!-- <div class="flex-1" style="background: url(assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
    <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
        <div class="row d-flex justify-content-center">
            <div class="col-md-4 card">
                <div class="card-body">
                    <h5 class="card-title text-center">ตั้งรหัสผ่านใหม่</h5>
                    <form [formGroup]="EmailGroup" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="col-md-12">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                                </div>
                                <input class="form-control" type="text" placeholder="อีเมลล์" formControlName="Email"> 
                            </div>
                        </div>     
                    </div>
                    <div class="form-group">
                        <div class="col-md-12">
                            <button class="btn btn-success btn-block" type="submit" [disabled]="onsaveing">
                                <span *ngIf="onsaveing" class="spinner-grow spinner-grow-sm"></span>
                                ยืนยัน</button>
                        </div>
                    </div>
                    <h6 *ngIf="TitleSendmail?.length > 0" class="text-center">{{TitleSendmail}}</h6>
                    <div class="row">
                        <div *ngIf="TitleSendmail?.length > 0" class="col-md-12  text-center">
                            <a class="text-info" routerLink="/login">กลับสู่หน้า Login</a>
                        </div>                    
                    </div>
                </form>
                </div>
                <div class="card-footer text-center">
                    มีบัญชีแล้ว <a class="text-info" routerLink="/login">เข้าสู่ระบบ</a>
                </div>
            </div>
        </div>
        
    </div>
</div>


 -->

 <div class="row">
    <div class="col-xl-12">
    </div>
    <div class="col-lg-4 col-md-5 ml-auto mr-auto mt-3">
        <div class="card p-4 rounded-plus bg-faded">
            <ng-container  *ngIf="!otpSection">
                <h2 class="fs-xxl fw-500 card-header text-center mb-3">
                    ลืมรหัสผ่าน?       
                </h2>
                <form (ngSubmit)="onForget()"  [formGroup]="forgetpasswordForm">
                    <div class="form-group">
                        <label class="form-label text-center" for="lostaccount">กรุณากรอกเบอร์มือถือหรืออีเมล ที่คุณใช้ในการสมัคร เพื่อสร้างรหัสผ่านใหม่</label>
                        <input type="text" id="lostaccount" class="form-control" placeholder="กรอกเบอร์โทรศัพท์ หรืออีเมล" formControlName="forgetType" maxlength="30" style="font-size: 14px;">
                        
                        <!-- <div  class="help-block " *ngIf="forgetpasswordForm.controls.forgetType.errors?.required">
                            กรอกเบอร์โทร เพื่อรับ OTP สำหรับเปลี่ยนรหัสผ่าน  หรือ กรอกอีเมลล์เพื่อรับลิงก์เปลี่ยนรหัสผ่าน
                        </div> -->
                        <div  class="help-block " style="font-size: 100%; color: #dc3545;" *ngIf="forgetpasswordForm.controls.forgetType.errors?.pattern">
                            {{forgetpasswordForm.controls.forgetType.errors?.pattern}}
                            <small style="color: #666666;" >ตัวอย่าง 0999999999</small>
                        </div>
                        <div  class="help-block text-rad" style="font-size: 100%; color: #dc3545;" *ngIf="forgetpasswordForm.controls.forgetType.errors?.email">
                            {{forgetpasswordForm.controls.forgetType.errors?.email}}
                            <small style="color: #666666;">ตัวอย่าง example@360truck.co</small>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-4 ml-auto text-right">
                            <button class="btn btn-primary w-100 waves-effect waves-themed" type="submit" disabled  *ngIf="isFormsubmit">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>                           
                            </button>
                            <button *ngIf="!isFormsubmit" id="js-login-btn" type="submit" class="btn btn-primary w-100  waves-effect waves-themed" [disabled]="forgetpasswordForm.status === 'INVALID'">ยืนยัน</button>
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container  *ngIf="otpSection">
                <h2 class="fs-xxl fw-500 card-header text-center mb-3">
                    ยืนยันรหัส OTP
                    <h6 _ngcontent-vot-c46="" class="text-center mt-3"> ระบบได้ส่งรหัส OTP ไปที่เบอร์ {{forgetpasswordForm.value.forgetType}}</h6>
                </h2>
                <form (ngSubmit)="onOtp()" [formGroup]="otpForm">
                    <div class="form-group">
                        <label class="form-label" for="otp">โปรดกรอกรหัส OTP 4 หลัก ที่คุณได้รับ เพื่อยืนยันตัวตน</label>
                        <input type="text" id="otp" class="form-control" formControlName="otp" placeholder="กรอกรหัส OTP 4 หลัก"  maxlength="4">         
                        <div  class="help-block text-rad" style="font-size: 100%;" *ngIf="this.otpCount < OtpBlockCount">
                            <a style="cursor: pointer;" (click)="onSentForget()"><u><i class="far fa-redo"></i>&nbsp; ส่งรหัส OTP อีกครั้ง</u></a>
                        </div>  
                        <div  class="help-block text-rad" style="font-size: 100%;" *ngIf="this.otpCount >= OtpBlockCount">
                            ส่งรหัส OTP อีกครั้งได้ในอีก {{otpCountdown}}
                            </div>           
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-4 ml-auto text-right">
                            <button id="js-login-btn" type="submit" class="btn btn-dark waves-effect waves-themed" [disabled]="otpForm.status === 'INVALID'">ยืนยัน</button>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>

        <div class="mt-3 text-center">
            <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60p">
                <small>  <b>หากมีบัญชีแล้ว</b>&nbsp; <a href="/"><u>เข้าสู่ระบบ</u></a></small>
            </small>
        </div>

    </div>
</div>
