import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamdatabaseComponent } from "@app/modules/team/pages/teamdatabase/teamdatabase.component";
import { TeammemberComponent } from "@app/modules/team/pages/teammember/teammember.component";

const routes: Routes = [
  {
    path: '', component: TeamdatabaseComponent,
    data: {
      titlePage: 'ผู้ใช้และทีม',
      breadcrumb: [
        // { label: 'ตั้งค่าระบบ TMS', url: '/team' },
        { label: 'จัดการผู้ใช้และทีม', url: '' },
      ]
    },
  },
  {
    path: 'member/:teamId', component: TeammemberComponent,
    // data: {
    //   titlePage: 'รายชื่อสมาชิกในทีม',
    //   breadcrumb: [
    //     { label: 'จัดการผู้ใช้และทีม', url: '/team' },
    //     { label: 'รายชื่อสมาชิกในทีม', url: '' }
    //   ]
    // },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamRoutingModule { }
