import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ClientUserService} from "@app/modules/team/services/client-user.service";
import {AddTeamUserModel} from "@app/modules/team/models/AddTeamUserModel";
import {TeamService} from "@app/modules/team/services/team.service";
import {takeUntil} from "rxjs/operators";
import {TMSAPIResponse} from "@app/core/_Models/TMSAPIResponse";
import {Subject} from "rxjs";
import {NotificationService} from "@app/core/_Services";
import { TeamUserMember } from '../../models/TeamUserListModel';

declare function openModal(modalID): any;

declare function closeModal(modalID): any;

@Component({
  selector: 'app-modal-addteammember',
  templateUrl: './modal-addteammember.component.html',
  styleUrls: ['./modal-addteammember.component.scss']
})
export class ModalAddteammemberComponent implements OnInit,OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  loadingUserdata = false;
  searchTxt: string;
  userCollapse: number;
  teamUser: AddTeamUserModel[] = [];
  @Input() public TeamId: number;
  @Input() public TeamName: string;
  @Input() disabled: boolean = false;
  @Input() existTeamUserList: TeamUserMember[] = [];
  @Output() AfterAdd = new EventEmitter<boolean>();
  @ViewChild('searchUTxt') private searchEleRef: ElementRef;

  constructor(
    private _clintUserService: ClientUserService,
    private _teamService: TeamService,
    private _noti: NotificationService,
  ) {
  }

  ngOnInit(): void {
  }

  async onOpenModal() {
    this.loadingUserdata = true;
    openModal("#addMemeberModal");
    const dept = await this._clintUserService.GetClientUserAllList().pipe(takeUntil(this.destroy$))
      .subscribe((res: TMSAPIResponse) => {
        if (res.isSuccess) {
          res.data.map((item) => {
            if (
              !this.existTeamUserList.find(
                (f) => f.tst360UserId === item.tst360UserId
              )
            ) {
              let teamU = new AddTeamUserModel();
              teamU.isSelected = false;
              teamU.clientId = item.clientId;
              teamU.tst360CompanyId = item.tst360CompanyId;
              teamU.tst360UserId = item.tst360UserId;
              teamU.photo = item.photo;
              teamU.fullname = item.fullname;
              teamU.mobileNo = item.mobileNo;
              teamU.email = item.email;
              teamU.departmentName = item.departmentName;
              this.teamUser.push(teamU);
            }
          });
          this.loadingUserdata = false;
        }
      });

  }

  search() {
    this.searchTxt = this.searchEleRef.nativeElement.value;
  }

  async onSubmit() {
    const data = this.teamUser.filter(f => f.isSelected === true);
    const result = await this._teamService.AddTeamUser(data, this.TeamId).pipe(takeUntil(this.destroy$)).subscribe((res: TMSAPIResponse) => {
      if (res.isSuccess) {
        this._noti.toastsuccess("บันทึกข้อมูลเรียบร้อย", "");
        closeModal("#addMemeberModal");
        this.teamUser = [];
        this.AfterAdd.emit(true);
      } else {
        this._noti.toasterror("บันทึกข้อมูลไม่สำเร็จ", res.message);
      }
    });

  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
