import { Injectable } from '@angular/core';
import { ApmService, } from '@elastic/apm-rum-angular';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ElasticService {
  constructor(
    private service: ApmService,
    private auth: AuthenticationService
  ) { }

  init(): any {
    const apm = this.service.init({
      serviceName: 'tms-web-' + environment.ENV_NAME,
      serverUrl: environment.loggerUrl,
      environment: environment.ENV_NAME,
      transactionSampleRate: 0.2,
    });


    this.auth.currentUser.subscribe((s) => {
      if (s) {
        this.service.apm.setUserContext({
          username: s?.fullName,
          id: s.id,
          email: s?.email,
        });
      }
    });
    return apm;
  }
  getCurrentTransaction(apm: any) {
    const activeTransaction = apm.getCurrentTransaction();
  }

  setUserContext(apm: any) {
    this.auth.currentUser.subscribe((s) => {
      if (s) {
        apm.setUserContext({
          username: s?.fullName,
          id: s.id,
          email: s?.email,
        });
      }
    });
  }
}
