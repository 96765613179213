<ng-template #modalsharedriverdetail let-modal>
    <div class="modal-header">
        <h5 class="modal-title"> <i class="fal fa-share color-success-100 fw-900 mr-1"></i>แชร์รายละเอียดคนขับ</h5>
        <button type="button" class="btn btn-sm-custom btn-outline-danger waves-effect waves-themed"
            aria-hidden="true" data-dismiss="modal" aria-label="Close" (click)="closemodal()">
            <i class="fal fa-times"></i>
        </button>
    </div>

    <span class="row">
        <span class="col-12 height-0">
            <div class="border border-left-0 border-right-0 border-top-0 m-3"></div>
        </span>
    </span>

    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <label class="mt-2 fw-500">โปรดเลือกหัวข้อที่ต้องการแชร์ข้อมูล</label>
            </div>

            <div class="col-4">
                <div class="row">
                    <div class="col-12 mb-1 mt-2">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="defaultUnchecked"
                                [(ngModel)]="driverDetailSharedCheckAll" (change)="onCheckedAll()">
                            <label class="custom-control-label" for="defaultUnchecked">เลือกทั้งหมด</label>
                        </div>
                    </div>

                    <div *ngFor="let item of driverDetailSharedCheckList;let i = index" class="col-12 mb-1">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="defaultUnchecked{{i}}"
                                [checked]="item.checked" (click)="checkDriverDetail(item)">
                            <label class="custom-control-label"
                                for="defaultUnchecked{{i}}">{{item.name}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-8">
                <div #container id="for-copy" class="row">
                    <div class="col-12 mt-2">
                        <label class="form-label">ข้อมูลคนขับรถ {{this.currentUser.client.clientName}}</label>
                    </div>

                    <div *ngFor="let item of driverDetailSharedCheckList;let i = index" class="col-12">
                        <div *ngIf="item.checked" class="row">
                            <!-- <span class="col-6">
                                <label class="form-label text-muted">{{item.name}}:</label>
                            </span>
                            <span class="col-6">
                                <label class="form-label">{{item.detail}}</label>
                            </span> -->

                            <span class="col-12">
                                <label class="form-label text-muted">{{item.name}}</label>:
                                <label *ngIf="item.id!='birthdate'" class="form-label">{{item.detail}}</label>
                                <label *ngIf="item.id=='birthdate'" class="form-label">{{item.detail|thaidate:'short'}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-outline-success waves-effect waves-themed btn-block"
                            data-dismiss="modal" (click)="closemodal()">ปิดหน้านี้</button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-success waves-effect waves-themed btn-block" ngxClipboard
                            [cbContent]="copytext" [container]="container"
                            (click)="copy()">คัดลอกข้อความ</button>
                    </div>
                </div>

            </div>




        </div>
    </div>
</ng-template>
