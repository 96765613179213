import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthenticationService,
  NotificationService,
  PageloadingService,
} from 'src/app/core/_Services';
import { TeamListModel } from '../../models/TeamListModel';
import { TeamService } from '../../services/team.service';
import { BehaviorSubject } from 'rxjs';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { takeWhile } from 'rxjs/operators';
import { GoolemapService } from '@app/shared/Components/ModalGoogleMap/modalgooglemap/googlemap/Services/goolemap.service';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';
import { User } from '@app/core/_Models/UserModel/User';

declare function closeModal(modalID): any;

@Component({
  selector: 'app-modaladdteam',
  templateUrl: './modaladdteam.component.html',
  styleUrls: ['./modaladdteam.component.scss'],
})
export class ModaladdteamComponent implements OnInit, OnChanges, OnDestroy {
  @Input() IsEdit: boolean;
  @Input() existTeam: TeamListModel[] = [];
  @Input() editTeam: TeamListModel;
  @Output() OnSubmit = new EventEmitter<boolean>();

  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onSaving: boolean = false;
  LocationList = [];
  Active = [
    { id: false, name: 'ระงับการใช้งาน' },
    { id: true, name: 'เปิดใช้งาน' },
  ];
  user : User;
  teamForm = new FormGroup({
    teamName: new FormControl(null, Validators.required),
    teamNumber: new FormControl(),
    teamStatus: new FormControl(true),
    teamClientLocationIds : new FormControl([])

  });

  constructor(
    private teamService: TeamService,
    private _noti: NotificationService,
    // private _loading: PageloadingService,
    private _mapService: GoolemapService,
    private _customerservice: CustomerserviceService,
    private authService: AuthenticationService
  ) {
    this.authService.currentUser.subscribe((x) => {
      this.user = x;
    })
  }

  ngOnInit(): void {
    this.GetMasterLocation();

  }

  ngOnChanges() {
    // on edit team
    if (this.editTeam && this.IsEdit) {
      this.teamForm.controls.teamName.setValue(this.editTeam.teamName);
      if (this.editTeam.teamName === 'Default') {
        this.teamForm.controls.teamName.disable();
      } else {
        this.teamForm.controls.teamName.enable();
      }
      this.teamForm.controls.teamNumber.setValue(this.editTeam.teamNumber);
      this.teamForm.controls.teamStatus.setValue(this.editTeam.teamStatus);
      if (this.editTeam.teamClientLocationIds) {
        this.teamForm.controls.teamClientLocationIds.setValue(
          this.editTeam.teamClientLocationIds
        );
      } else {
        this.teamForm.controls.teamClientLocationIds.setValue([]);
      }
    } else {
      this.teamForm.controls.teamName.enable();
      this.teamForm = new FormGroup({
        teamName: new FormControl(null, Validators.required),
        teamNumber: new FormControl(),
        teamStatus: new FormControl(true),
        teamClientLocationIds: new FormControl([]),
      });
    }
  }

  async onSubmit() {
    this.onSaving = true;
console.log(this.teamForm.value)
    if (this.IsEdit) {
      //this._loading.show();
      this.teamService
        .EditTeam(this.editTeam.teamId, this.teamForm)
        .subscribe((res: TMSAPIResponse) => {
          //this._loading.hide();
          if (res.isSuccess) {
            this._noti.toastsuccess('แก้ไขข้อมูลเรียบร้อย', '');
            this.teamForm.reset();
            closeModal('#modaladdteam');
            this.OnSubmit.emit(res.isSuccess);
          } else {
            //this._loading.hide();
            this._noti.toasterror('แก้ไขข้อมูลไม่สำเร็จ', res.message);
          }
        });
    } else {
      //this._loading.show();
      this.teamService.AddTeam(this.teamForm)
      .pipe(takeWhile(t => !this.destroy$.value))
      .subscribe(
        (res: TMSAPIResponse) => {
          //this._loading.hide();
          if (res.isSuccess) {
            this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
            this.teamForm.reset();
            closeModal('#modaladdteam');
            this.OnSubmit.emit(res.isSuccess);
          } else {
            this._noti.toasterror('บันทึกข้อมูลไม่สำเร็จ', res.message);
          }
        },
        (err) => {
          //this._loading.hide();
          console.log('error on addteam', err);
          this._noti.toasterror('บันทึกข้อมูลล้มเหลว', err);
        }
      );
    }

    this.onSaving = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async GetMasterLocation() {
    try{
      await this._customerservice.GetCompanyLocationList(this.user.ClientID).subscribe(
        async (data) => {
          this.LocationList = await data;
          console.log(this.LocationList)
          //await this.reInitDatatable();
        },
        (err) => {
        }
      );
    } catch (e) {
    }
  }

  isExistTeamNumber(): boolean {
    if (this.editTeam) {
      return this.existTeam.some(
        (x) =>
          x.teamNumber === this.teamForm.controls.teamNumber.value &&
          x.teamId !== this.editTeam.teamId
      );
    } else {
      return this.existTeam.some(
        (x) => x.teamNumber === this.teamForm.controls.teamNumber.value
      );
    }
  }

  isExistTeamName(): boolean {
    if (this.editTeam) {
      return this.existTeam.some(
        (x) =>
          x.teamName === this.teamForm.controls.teamName.value &&
          x.teamId !== this.editTeam.teamId
      );
    } else {
      return this.existTeam.some(
        (x) => x.teamName === this.teamForm.controls.teamName.value
      );
    }
  }
}
