import {
  Component,
  OnInit,
  forwardRef,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

declare const $;
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent
  implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('date') datePicker: ElementRef<HTMLInputElement>;

  public textValue: string;
  public value?: string;
  public date?: Date;

  @Input()
  id: string;
  @Input()
  valid = false;
  @Input()
  disabled = false;
  @Input()
  removeTop = false;
  @Input()
  isRequire = false;
  @Input()
  class: string = '';
  @Input()
  title: string = '';
  @Input()
  secondpicker = false;
  @Input()
  minDate = null;
  @Output()
  change = new EventEmitter<any>();

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor() { }

  ngOnInit(): void {
    // console.log('init value', this.value);
    this.openDate();
    this.checkDate();
  }

  ngAfterViewInit(): void {
    this.openDate();
    this.checkDate();
  }

  ngOnChanges(): void {
    if (this.minDate) {
      this.minDate = new Date(this.minDate)
    }
    $(`#${this.id}`).datepicker('setStartDate', this.minDate);
  }
  openDate(): void {
    if (this.minDate) {
      this.minDate = new Date(this.minDate)
    }
    // console.log(this.minDate);

    $(`#${this.id}`)
      .datepicker({
        startDate: this.minDate,
        format: 'dd-mm-yyyy',
        autoclose: true,
        todayHighlight: true
      })
      .on('changeDate', (e) => {
        const textDate = this.datePicker.nativeElement.value;
        console.log('dateValue', textDate);
        if (textDate) {
          const dateValue = moment(textDate, 'DD-MM-YYYY').toDate();
          this.textValue = moment(dateValue).format('DD-MM-YYYY');
          this.value = moment(dateValue).format('YYYY-MM-DD');
          this.date = dateValue;
          // this.value.setDate(this.value.getDate() + 1)
          this.datePicker.nativeElement.value = this.textValue;
          this.onChange(this.value);
          this.change.emit(this.value);
          // this.checkDate();
        } else {
          this.onChange(null);
          this.textValue = null;
        }
      });

    // console.log('textValue', this.textValue);
  }

  checkDate(): void {
    if (this.value) {
      this.textValue = moment(this.value).format('DD-MM-YYYY'); // new DatePipe('en-US').transform(this.value, 'dd-MMM-yyyy');
    } else {
      this.textValue = null;
    }
  }

  writeValue(value: Date): void {
    // console.log('writeValue', value);
    if (value) {
      this.date = value;
      // this.value.setDate(this.value.getDate() + 1)
      this.textValue = moment(value).format('DD-MM-YYYY');
      this.value = moment(this.date).format('YYYY-MM-DD');
      this.checkDate();
    } else {
      this.value = null;
      this.textValue = null;
    }

    // console.log('writeValue  this.textValue', this.textValue);
  }

  registerLocalData(fn: any): void {

  }

  registerOnChange(fn: any): void {
    // console.log('registerOnChange', fn, this.textValue);
    this.onChange = fn;
    setTimeout(() => {
      if (this.textValue) {
        const dateValue = moment(this.textValue, 'DD-MM-YYYY').toDate();
        this.value = moment(dateValue).format('YYYY-MM-DD');
        this.date = dateValue;
        this.onChange(this.value);
        this.change.emit(this.value);
      } else {
        this.value = null;
      }
    }, 1);
  }

  registerOnTouched(fn: any): void {
    // console.log('registerOnTouched', fn);
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // console.log('setDisabledState', isDisabled);
    this.disabled = isDisabled;
  }
}
