import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../_Services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-cookie-login',
  templateUrl: './cookie-login.component.html',
})
export class CookieLoginComponent implements OnInit {
  constructor(private authService: AuthenticationService, private route: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {


    console.log('CookieLoginComponent.ngOnInit called');
    // this.authService.onSetUser(new User());
    this.activeRoute.queryParams.subscribe(params => {
      this.authService.loginCheck()
        .pipe(first())
        .subscribe({
          next: async (res: any) => {
            console.log('CookieLoginComponent.loginCheck res', res);
            console.log('CookieLoginComponent.ngOnInit next called');

            if (res.status == 200) {
              if (params.ac) { // jwt token
                await this.authService.SetUserDataCookieLogin(res, params.ac)
                // this.authService.getTokenOAuth2(params.ac, environment.oauth.state).then(response => {
                //   console.log('getTokenOAuth2 response', response);
                // });
              } else {
                await this.authService.SetUserDataCookieLogin(res)
              }

              if (params.redirect) {
                let redirect = params.redirect;
                console.log('rediect params', redirect);
                // debugger;
                if (redirect.includes('signup')) {
                  await this.authService.logout();
                }

                window.location.assign(redirect);

              } else {
                window.location.href = '/booking';
              }
            } else {
              window.location.href = '/logout';
            }

            // stopl อันนี้ไม่ใช่แล้ว โดยรวมไม่ work
            //if(localStorage.getItem('stopl')!=='true'){

            //}
          },

          error: async (error) => {
            debugger;
            console.log('cookie login loginCheck', error);
            // if (error?.status === 401) {
            //   this.authService.logout()
            //   window.location.href = environment.loginUrl + "";
            //   return;
            // }
            await this.authService.logout();
            // window.location.href = '/'
            const oauth2Param = `?client_id=${environment.oauth.client_id}&response_type=${environment.oauth.response_type}&redirect_uri=${environment.oauth.redirect_uri}&state=${environment.oauth.state}`;
            window.location.href = environment.loginUrl + oauth2Param;
            // window.location.href = environment.loginUrl + "/login/tms";
          }
        });
    })



  }
}
