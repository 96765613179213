<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="select" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
                <div class="modal-header">
                        <h5 class="modal-title">เลือก Shipment </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body">
                <div class="row pl-3 pb-2">
                    <div class="col-auto">
                        <button type="button" class="btn btn-default" (click)="selectAll(!isSelectedAll)">
                           เลือก Shipment  ทั้งหมด
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="container-fluid">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Shipment</th>
                                        <th style="width: 15%;">Orders</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of booking">
                                        <td>
                                            <input type="checkbox" name="shipment" [checked]="isSelected(item)"
                                            (change)="onselect(item)"/>
                                        </td>
                                        <td>
                                            {{item?.truckBookingNo}}
                                        </td>
                                        <td>
                                           <div *ngFor="let cus of item?.customer; let i = index">
                                                <div *ngFor="let order of cus.customerOrder; let j = index">
                                                    <span>
                            
                                                        {{order?.ref_no_1}} {{order?.orderWeight}}
                                                       
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="item.sumWeight">
                                                นน.รวม {{item.sumWeight | number: '1.2-2'}} กก.
                                            </div>
                                        </td>
                                        <td>
                                            <app-booking-shipment-item [data]="item" [shipmentData]="shipmentData(item)" [isExfac]="isExfac"
                                           [onlyLoc]="true"
                                            ></app-booking-shipment-item>
                                            
                                        </td>
                                 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
                <button type="button" class="btn btn-success" [disabled]="selectedBooking.length == 0"
                 (click)="onCreate()" data-dismiss="modal">   ยืนยันแผนและสร้างการจอง</button>
            </div>
        </div>
    </div>
</div>
