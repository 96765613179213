import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { AddFleetXDriverListRequest } from '@app/modules/truck-license-management/models/fleet-x-truck-model';
import { clientFleetDto } from '@app/modules/truck-management/models/TruckFleet';
import { TruckManagementService } from '@app/modules/truck-management/service/truck-management.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DriverDetailService } from '../../services/driver-detail.service';
import { FleetXDriverService } from '../../services/fleet-x-driver.service';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';

@Component({
  selector: 'app-modal-add-fleet-driver-form',
  templateUrl: './modal-add-fleet-driver-form.component.html',
  styleUrls: ['./modal-add-fleet-driver-form.component.scss']
})
export class ModalAddFleetDriverFormComponent implements OnInit {

  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() driver = 0
  @Output() modalReference!: NgbModalRef;
  @Output() Refresh = new EventEmitter();

  user: User;
  clientFleets: clientFleetDto[];
  selectCompanyFleetNo = 0
  selectedFleet: number = 0;

  routerSubscribe: Subscription;
  paramUrl: any;

  @ViewChild("modaladdfleetdriverform", { static: true }) modaladdfleetdriverform!: ModalAddFleetDriverFormComponent;
  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;
  constructor(
    private modalService: NgbModal,
    private truckmenagementService: TruckManagementService,
    private fleetXDriver: FleetXDriverService,
    private authenService: AuthenticationService,
    // private pageLoading: PageloadingService,
    private route: ActivatedRoute,
    private driverDetailService: DriverDetailService

  ) { }

  ngOnInit(): void {
    this.authenService.currentUser.subscribe((x) => {
      this.user = x;
    });

    this.routerSubscribe = this.route.queryParamMap.subscribe(async (param) => {
      this.paramUrl = {
        fleet: param.get('fleet') ? parseInt(param.get('fleet')) : param.get('fleet'),
      }

      this.selectedFleet = this.paramUrl?.fleet || 0;
    });
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string, backdrop?: boolean | 'static', keyboard?: boolean) {

    this.getDriverList();

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modaladdfleetdriverform, { size: size, centered: true, backdrop: backdrop, keyboard: keyboard });
    }
    else {
      this.modalReference = this.modalService.open(this.modaladdfleetdriverform, { centered: true, backdrop: backdrop, keyboard: keyboard });
    }

    this.getFleetList();
    return this.modalReference
  }

  closed(event) {
    this.modalReference.close(event)
  }

  getFleetList() {
    //this.pageLoading.show()
    this.truckmenagementService.getClientFleet().subscribe(res => {
      if(res.isSuccess){
        this.clientFleets = res.data;
      this.clientFleets[0].fleetName = "ยังไมต้องมี FLEET (เปลี่ยนภายหลังได้)"
      //this.pageLoading.hide()
      this.selectCompanyFleetNo =  this.clientFleets[0].companyFleetNo;

      }

      // this.getQueryParam();
    });
  }

  onSelectCompanyFleetNo(companyFleetNo: any) {
    this.selectCompanyFleetNo = companyFleetNo
    console.log(companyFleetNo)
  }

  onSaveFleetDriver() {
    if (this.selectCompanyFleetNo != 0) {
      const selectedDriverIds = []
      selectedDriverIds.push(this.driver)

      const data = {
        updateBy: this.user.tstS360_UserID,
        companyId: this.user.tstS360_CompanyID,
        driverUserID: selectedDriverIds,
        fleetNo: this.selectCompanyFleetNo,
      } as AddFleetXDriverListRequest;
      console.log(data)

      //this.pageLoading.show()

      this.fleetXDriver.AddFleetXDriverList(data).subscribe(x => {
        if (x.isSuccess) {
          //this.pageLoading.hide()
          console.log("onSaveFleetDriver -> AddFleetXDriverList", x);

          this.openmodalsuccess();
          this.getDriverList()
        }
      });
    }
    this.Refresh.emit()

    this.closed(true);

  }

  openmodalsuccess() {
    this.modalsuccess.modalsize = 'sm';
    this.modalsuccess.title_icon = 'fa-user-circle';
    this.modalsuccess.body_title = 'เพิ่มคนขับเข้า FLEET เรียบร้อยแล้ว';
    this.modalsuccess.btnsubmit_text = 'ปิดหน้านี้';
    this.modalsuccess.openmodal(this.modalsuccess.modalsize);
  }

  onSuccessSubmit(event: any) {
    this.modalsuccess.modalReference.close();
  }

  getDriverList(): void {
    console.log("this.selectedFleet",this.selectedFleet);

    this.driverDetailService.getDriverFleet(this.selectedFleet != 0 ? this.selectedFleet : null)
  }
}
