<div class="alert line-alert alert-dismissible fade show p-2 mb-2" role="alert">
    <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true"><i class="fal fa-times"></i> &nbsp; <label style="font-size: 12px;">ปิดหน้านี้</label></span>
    </button> -->

        <div class="row ">
            <div class="col-3 col-md-1 d-flex justify-content-center align-items-center ">
                <i class="fas fa-bell-on" style="font-size: 25px;"></i>
            </div>
            <div class="col d-flex justify-content-left align-items-center">
                <div class="row ">
                    <div class="col-12 col-auto p-0 m-0">
                        <span class=" m-0 fw-700"> {{text}}
                            <a  (click)="goTo()"> <u>คลิกที่นี่  </u>  </a> </span>
                    </div>
                </div>
            </div>
            <div class="col-1 d-md-none ">
                <a class="" data-dismiss="alert" aria-label="Close">
                  <i class="fal fa-times"></i>
                </a>
            </div>
            <div class="col-auto d-none d-md-block text-right">
                <a class="" style="color: #fff;" data-dismiss="alert" aria-label="Close">
                    <i class="fal fa-times"></i> &nbsp; <u><label style="font-size: 12px;">ปิดหน้านี้</label></u>   
                </a>
            </div>
          
        </div>
</div>