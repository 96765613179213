<ng-template #modaladdfleetdriverform let-modal>
    <div class="card-header pb-1">
        <div class="card-title">
            <h4 class="fw-500">เพิ่มผู้ใช้งาน (คนขับ)</h4>
        </div>
    </div>

    <div class="card-body">
        <h5 class="fw-500">คุณต้องการให้คนขับเข้า FLEET ไหน</h5>

        <div *ngFor="let item of clientFleets;let i = index" class="custom-control custom-radio mb-1">
            <input type="radio" class="custom-control-input" id="defaultUncheckedRadio{{i}}" [checked]="selectCompanyFleetNo === item.companyFleetNo"
                name="defaultExampleRadios" (click)="onSelectCompanyFleetNo(item.companyFleetNo)">
            <label class="custom-control-label" for="defaultUncheckedRadio{{i}}"> <span *ngIf="item.companyFleetNo != 0">Fleet {{item.companyFleetNo}}:</span> {{item.fleetName}}</label>
        </div>
        <!-- <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="defaultCheckedRadio" name="defaultExampleRadios" checked="">
            <label class="custom-control-label" for="defaultCheckedRadio">Checked</label>
        </div>
        <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input active" id="defaultUncheckedRadio2" name="defaultExampleRadios">
            <label class="custom-control-label" for="defaultUncheckedRadio2">Unchecked</label>
        </div> -->
    </div>

    <div class="card-footer">
        <div class="row">
            <div class="col-6">
                <button class="btn btn-sm btn-block btn-default" (click)="closed()">ปิดหน้านี้</button>
            </div>
            <div class="col-6">
                <button class="btn btn-sm btn-block btn-success" (click)="onSaveFleetDriver()">บันทึก</button>
            </div>
        </div>
    </div>
</ng-template>

<app-modalsuccess #modalsuccess (btnsubmit_event)="onSuccessSubmit($event)" [btnother_ishide]=true></app-modalsuccess>