import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialsComponent } from './materials.component';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { DatePipe } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { BreadcrumbComponent } from 'src/app/shared/Components/breadcrumb/breadcrumb.component';

@NgModule({
  providers: [DatePipe],
  declarations: [
    MaterialsComponent,
    InputPhoneComponent,
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule,
    NgSelectModule,
    NgxDropzoneModule,

  ],
  exports:[
    BreadcrumbComponent
  ]
})
export class MaterialsModule { }
