import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { clientFleetDto } from '@app/modules/truck-management/models/TruckFleet';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-fleet-form',
  templateUrl: './change-fleet-form.component.html',
  styleUrls: ['./change-fleet-form.component.scss']
})
export class ChangeFleetFormComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() fleetList: clientFleetDto[] = [];
  @Input() fleetNo: number = 0;
  @Output() changeFleetFormEvent: EventEmitter<any> = new EventEmitter();

  changeFleetForm: FormGroup;
  constructor(private formbuilder: FormBuilder) { }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit() {
    this.changeFleetFormInit();
    console.log('change fleet form ngOnInit',this.fleetList);
    
  }

  changeFleetFormInit(): void {
    this.changeFleetForm = this.formbuilder.group({
      fleetNo: new FormControl([this.fleetNo, [Validators.required]])
    })

    // this.changeFleetForm.statusChanges.subscribe(res => {
    //   this.changeFleetFormEvent.emit(this.changeFleetForm);
    // })
    this.changeFleetFormEvent.emit(this.fleetNo);
  }

  fleetNoChange(): void {
    // console.log('this.fleetNo',this.fleetNo);
    this.changeFleetFormEvent.emit(this.fleetNo);
  }
}
