import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer, Remarks, TruckBookingModel } from '@app/core/_Models/BookingModel/TruckBookingModel';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService } from '@app/core/_Services';
import { PackagingUnitService } from '@app/core/_Services/packaging-unit.service';
import { RouteService } from '@app/core/_Services/route.service';
import { UnitTypeService } from '@app/core/_Services/unit-type.service';
import { WeightUnitService } from '@app/core/_Services/weight-unit.service';
import { bookingSettingModel } from '@app/modules/booking/models/BookingSettingModel';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';
import { ServicesettingService } from '@app/modules/setting/service/servicesetting.service';
declare function closeModal(modalID): any;
declare function openModal(modalID): any;

@Component({
  selector: 'app-model-edit-order-ref',
  templateUrl: './model-edit-order-ref.component.html',
  styleUrls: ['./model-edit-order-ref.component.scss']
})
export class ModelEditOrderRefComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private noti: NotificationService,
    private routeService: RouteService,
    private authen: AuthenticationService,
    private unitService: UnitTypeService,
    private serviceSetting: ServicesettingService,
    private PackagingUnitService: PackagingUnitService,
    private WeightUnitService: WeightUnitService,
    private customerservice: CustomerserviceService,
    private bs: BookingServiceService
  ) { }
  @Input() bookingID;
  @Output() updateData: EventEmitter<boolean> = new EventEmitter();
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  saving = false;
  loadDataComplete = false;
  ModalId = "ModalEditRef"
  editForm: FormGroup;
  orderForm: FormArray;
  customer: Customer;
  bookingRef;
  user: User;
  booking ;
  ngOnInit(): void {
    this.ModalId += this.bookingID;
    console.log(this.ModalId)
    this.authen.currentUser.subscribe((x) => {
      this.user = x;
      this.GetRef()
    });
    console.log("EDITREFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF ",this.bookingID)
    this.editForm = this.fb.group({
      customerID: ['', Validators.required],
      customerNumber: [''],

      customerName: [''],
      salesID: [0],
      salesName: [''],
      customerRemark: this.fb.array([this.createRemark(null)]),
      customerOrder: this.fb.array([this.createOrder()]),
    });
    var order = this.editForm.get('customerOrder') as FormArray;
    this.orderForm = order;

    if(this.bookingID != undefined){
      this.getData()
    }

  }

  
  SubmitForm() {
    console.log(this.editForm);
    var updateData = {
      bookingId: parseInt(this.bookingID),
      customer: this.editForm.value,
    };
    var json = JSON.stringify(updateData);
    console.log(json);
    this.saving = true;
    if (this.editForm.valid) {
      this.bs.updateCustomerOrder(updateData).subscribe((response) => {
        this.noti.toastsuccess('บันทึกสำเร็จ', 'บันทึก Reference สำเร็จ');
        this.updateData.emit(true);
        this.saving = false;
        this.modalClose.emit(true);
        closeModal('#ModalEditRef'+this.bookingID);
      });
    } else {
      this.noti.toastwarning('ข้อมูลไม่สมบูรณ์', 'กรุณากรอกข้อมูลให้ครบถ้วน');
      this.saving = false;
    }
  }

  createRemark(item: Remarks): FormGroup {
    // console.log(item)
    if (item) {
      return this.fb.group({
        remark: item.remark,
        sendto: [item.sendto],
      });
    }
    return this.fb.group({
      remark: '',
      sendto: [],
    });
  }

  createOrder(): FormGroup {
    return this.fb.group({
      orderID: [0],
      ref_no_1: [''],
      ref_no_2: [''],
      ref_no_3: [''],
      items: this.fb.array([this.createProduct()]),
    });
  }

  createProduct(): FormGroup {
    return this.fb.group({
      amountPerHandling: [''],
      itemID: [0, Validators.required],
      itemNo: [''],
      productID: [''],
      productName: [''],
      productGroupID: [''],
      clientProductID: ['', Validators.required],
      packagingVolume: ['', Validators.required],
      packagingUnitID: ['', Validators.required],
      weightPerPackage: [0],
      weightPerHandling: [0],
      handlingVolume: ['', Validators.required],
      handlingUnitID: ['', Validators.required],
      weight: ['', Validators.required],
      weightUnitID: ['', Validators.required],
      handlingPrincipleID: [''],
      totalWeight: [0],
      productValue: [0],
    });
  }
  addOrder() {
    const control = (<FormArray>(
      this.editForm.get('customerOrder')
    )) as FormArray;
    //const control = <FormArray>this.survey.get(['sections',0,'questions',k,'options']); // also try this new syntax
    // console.log(control);
    control.push(this.createOrder());
    // this.OrderList.push(1);
  }
  getCustomerOrder() {
    return this.orderForm.controls;
  }
  getOrderItem(form) {
    return form.controls.items.controls;
  }
  addItem(i, j) {
    const control = (<FormArray>(
      (<FormArray>this.editForm.get('customerOrder')).controls[i].get('items')
    )) as FormArray;

    // console.log(control);
    control.push(this.createProduct());
  }

  async getData(){
    this.bs.getBookingById(this.bookingID).subscribe((res)=>{
      console.log(res)
      this.booking =res.data[0]
      this.setDataEdit( this.booking);
    
    })
    this.loadDataComplete = true;
  }
   setDataEdit(bookingData: TruckBookingModel) {
     console.log(bookingData)
    var customer = new Customer();
    this.customer = customer = bookingData.customer[0];
    var cusotmerOrder = this.editForm.get('customerOrder') as FormArray;

    let customerForm = this.editForm;
    customerForm.patchValue({
      customerID: customer.customerID,
      salesID: customer.salesID,
    });
    
    for (let k = 0; k < customer.customerOrder.length; k++) {
      if (k > this.getCustomerOrder().length - 1) {
        this.addOrder();
      }
      
      let Order = (<FormGroup>cusotmerOrder.controls[k]) as FormGroup;

      Order.patchValue({
        orderID: customer.customerOrder[k].orderID,
        ref_no_1: customer.customerOrder[k].ref_no_1,
        ref_no_2: customer.customerOrder[k].ref_no_2,
        ref_no_3: customer.customerOrder[k].ref_no_3,
      });
      for (let m = 0; m < customer.customerOrder[k].items.length; m++) {
        if (m > this.getOrderItem(Order).length - 1) {
          this.addItem(k, m);
        }
        let item = (<FormGroup>Order.get('items')).controls[m] as FormGroup;
        item.patchValue({
          itemID: customer.customerOrder[k].items[m].itemID,
          clientProductID: customer.customerOrder[k].items[m].clientProductID,
          weight: customer.customerOrder[k].items[m].weight,
          weightUnitID: customer.customerOrder[k].items[m].weightUnitID,
          packagingVolume: customer.customerOrder[k].items[m].packagingVolume,
          packagingUnitID: customer.customerOrder[k].items[m].packagingUnitID,
          handlingVolume: customer.customerOrder[k].items[m].handlingVolume,
          handlingUnitID: customer.customerOrder[k].items[m].handlingUnitID,
          productGroupID: customer.customerOrder[k].items[m].productGroupID,
          handlingPrincipleID:
            customer.customerOrder[k].items[m].handlingPrincipleID,
        });
      }
    }

     console.log(this.editForm.value)
  }

  async GetRef() {
    var Ref = await this.serviceSetting.GetRef(this.user.ClientID);
    //  console.log("Reffffff",Ref.data);
    this.bookingRef = Ref.data;
  }
  CloseModal(){
    this.modalClose.emit(true);
  }
}
