<!-- <div class="row" *ngIf="ImagesViews.length > 0">
    <div class="col-12">
        <div *ngFor="let item of ImagesViews" class="thumbnail-small p-1" (click)="viewGellery()">
            <img class="rounded " src="{{item}}" alt="">
            <div [ngClass]="isSeeMore?'caption':'d-none' ">
                <p> <i class="far fa-images"></i>&nbsp;</p>
            </div>
        </div>

    </div>
    <div class="col-12" *ngIf="imageGallery">
        <p-galleria [(value)]="imageGallery" [(visible)]="displayBasic" [responsiveOptions]="responsiveOptions"
            [containerStyle]="{'max-width': '90%'}" [numVisible]="9" [circular]="true" [fullScreen]="true"
            [showItemNavigators]="true" [baseZIndex]="100000">
            <ng-template pTemplate="item" let-item>
                <img [src]="item.previewImageSrc" style="width: 100%; display: block;" />
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
                <div class="p-grid p-nogutter p-justify-center">
                    <img [src]="item.thumbnailImageSrc" style="display: block;   height: 75px;" />
                </div>
            </ng-template>
        </p-galleria>
    </div>
</div> -->
<div *ngIf="ImagesViews?.length > 0">
  <!-- <div class="col-12"> -->
  <div id="{{galleryID}}">
    <a class="" *ngFor="let item of ImagesViews;let im = index" href="{{item}}" class=""
      data-sub-html="The free in pointed they their for the so fame." target="_blank">
      <img class="h-auto rounded" src="{{item}}" alt="{{item}}">
    </a>
  </div>
  <div class="more-img d-flex align-items-center"
    *ngIf="ImagesViews.length >0 && moreImg && (ImagesViews.length-(moreImg?moreImg:0) > 0)" (click)="viewGellery()">
    + {{ImagesViews.length-(moreImg?moreImg:0)}}
    รูปภาพ
  </div>
  <!-- </div> -->
</div>
