export class Permid
{
    IsRead:boolean = null;
    IsCreate:boolean = null;
    IsEdit:boolean = null;
    IsDel:boolean = null;
    IsApp:boolean = null;
}

export class Subtitle
{
    subName:string;
    listIs:Permid;
}

export class Titleshow
{
    Title:string;
    sublist:Subtitle[];
}

export class CorporatePackage
{
    Corporate:Titleshow[];
}
