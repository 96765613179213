import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss']
})
export class ModalSuccessComponent implements OnInit {
  @Input() modalConfig: ModalSuccessModel;

  @ViewChild("modalSuccess", { static: true }) modalSuccess!: ModalSuccessComponent;
  modalReference!: NgbModalRef;
  modalOption: NgbModalOptions;

  iconClass: string = '';
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.modalConfig = {
      ...this.modalConfig,
      iconClassName: this.modalConfig?.iconClassName ?? 'fas fa-check'
    }
    this.iconClass = `${this.modalConfig?.iconClassName} ${this.modalConfig?.iconClassColor}`
    // this.modalOption
  }

  open(size?: 'sm' | 'lg' | 'xl' | string): NgbModalRef {
    this.ngOnInit();
    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalSuccess, { size: size, centered: true, backdrop: 'static' });
    }
    else {
      this.modalReference = this.modalService.open(this.modalSuccess, { centered: true });
    }
    return this.modalReference;
  }

  closed(bool: boolean): void {
    this.modalReference.close(false);
  }

  nextActionClick(bool: boolean): void {
    this.modalReference.close(bool);
  }

}


export class ModalSuccessModel {
  elementId: string = 'modal-success';
  title: string = 'เรียบร้อยแล้ว';
  description: string = 'บันทึกข้อมูลเรียบร้อยแล้ว';
  buttonSubmitText: string = 'ตกลง';
  iconClassName: string = 'fas fa-check';
  iconClassColor: string = 'text-success';
  isNextButtonAction: boolean = false;
  nextButtonActionText: string = 'ต้องการ';
  nextButtonActionIcon: string = 'fal fa-plus';
}
