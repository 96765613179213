import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '@app/core/_Services';

@Component({
  selector: 'app-tab-booking-shipment',
  templateUrl: './tab-booking-shipment.component.html',
  styleUrls: ['./tab-booking-shipment.component.scss']
})
export class TabBookingShipmentComponent implements OnInit {


  @Input() databundle;
  @Input() bookingData;
  @Input() isExfac = false;
  @Output() btnsubmit_event = new EventEmitter();
  selectedBooking= [];

  constructor( private _noti: NotificationService) { }

  ngOnInit(): void {
    // console.log(this.databundle);
    // console.log(this.bookingData);
  }

  shipmentData(item){

    var shipments = this.databundle.items;
    var shipment = shipments.filter(l => l.name === item.truckBookingNo);

  return shipment[0]
  }

  onselect(event){
    console.log(event);
    let item = event;
    if(this.selectedBooking.length > 0){
    if(this.selectedBooking.find(e=>e.truckBookingNo === item.truckBookingNo)){
      var index = this.selectedBooking.findIndex(e=>e.truckBookingNo === item.truckBookingNo);
      this.selectedBooking.splice(index, 1);
    }else{
      this.selectedBooking.push(item);
    }}
    else{
      this.selectedBooking.push(item);

    }

  //  console.log(this.selectedBooking)
  }

  onCreate(){

    this._noti
    .swalConfirm(
      'ยืนยันสร้าง booking','ยืนยัน'
    )
    .then((result) => {
      if (result) {
        this.btnsubmit_event.emit(this.selectedBooking);
        setTimeout(() => {
          this.selectedBooking = [];
        });
      }
    });
  
  }
}
