import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';
import * as moment from 'moment';
import { ShipmentCalendarModel, ShipmentCalendarTableModel } from '../Model/ShipmentAssingModel';
import { ShipmentAssignCalendarService } from '../shipment-assign-calendar.service';

@Component({
  selector: 'app-shipmetn-assign-calendar',
  templateUrl: './shipmetn-assign-calendar.component.html',
  styleUrls: ['./shipmetn-assign-calendar.component.scss']
})
export class ShipmetnAssignCalendarComponent implements OnInit {
  showTruckType = true;
  orginFilter;
  days;
  currentUser: User = new User();
  datalist: ShipmentCalendarTableModel[] = [];
  dataCompanylocation: any[]=[];
  startDate = new Date();
  endDate = new Date();
  origin: string[] = [];
  startDateString;
  endDateString;
  shipmentCarlendarList: ShipmentCalendarModel[] = new Array();
  constructor(
    // public pageload: PageloadingService,
    public shipmentCalendarService: ShipmentAssignCalendarService,
    private authen: AuthenticationService,
    private customerservice: CustomerserviceService,
  ) {
    // pageload.hide();

  }

  ngOnInit(): void {
    const date2 = new DatePipe('en-US').transform(this.startDate, 'yyyy-MM-dd')
    this.startDateString = date2;
    this.dateGen();
    this.authen.currentUser.subscribe((x) => {
      this.currentUser = x;
      this.shipmentCalendarService.getShipmentAssignCalendar(this.currentUser.ClientID, this.startDate, this.origin).subscribe(async (res) => {
        console.log(res);
        this.datalist = res.data;
        console.log(this.datalist);
        await this.GetCompanyLocationList();

      })
    });


  }

  onChange() {

    this.dateGen();
    this.shipmentCalendarService.getShipmentAssignCalendar(this.currentUser.ClientID, this.startDate, this.origin).subscribe((res) => {

      this.datalist = res.data;

    })
  }

  dateGen() {
    console.log(this.startDate)

    this.days = [];
    for (var i = 0; i < 7; i++) {
      var new_date = moment(this.startDate).add(i, 'days').toDate();
      console.log(new_date)
      this.days.push(new_date);
    }
    console.log(this.days)

  }

  MoveBack() {
    this.startDate = new Date( this.startDate);
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.dateGen();
    this.onChange();
  }

  MoveNext() {
    this.startDate = new Date( this.startDate);
    this.startDate.setDate(this.startDate.getDate() + 1)
    this.dateGen();
    this.onChange();

  }

  getSumTruck(count): number {
    var sum = 0;
    count.forEach(numb => {
      sum += numb;
    });
    return sum;
  }

  async GetCompanyLocationList() {

    await this.customerservice
      .GetCompanyLocationList(this.currentUser.ClientID)
      .subscribe((data) => {
        var temp =  data;
        temp.forEach(item => {
          if(item.locationTypeId == 1){
            this.dataCompanylocation.push(item);
          }
        });

        //this.reInitcomDatatable();
        console.log(this.dataCompanylocation);

      });
  }

  toggleTruckType(){
    this.showTruckType = !this.showTruckType;
  }


}


