import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {PageloadingService} from 'src/app/core/_Services';
import Tippy from 'node_modules/tippy.js/dist/tippy-bundle.umd.min.js';
import {deliver_status} from "../../models/booking-model";
import _ from "lodash";
import {MatchDefectRoute} from "../../models/match-defect-route-model";
import {bookingData} from "./simple-data";
import {AppContants} from 'src/app/core/_Constants/appContants';
import {MiddleNodeDataModel, OriNodeDataModel, DrawRouteRowModel} from "../../models/node-data-model";
import {DestNodeDataModel} from "../../models/node-data-model";
import Drawflow from 'drawflow'
import {ActivatedRoute} from "@angular/router";
import {BookingServiceService} from "@app/modules/booking/service/booking-service.service";
import {
  ShipmentForDiagramDto,
  ShipmentLocationForDiagramDto
} from "@app/modules/route-diagram/models/shipment-for-diagram-dto";
import {MatchRouteModel} from "@app/modules/route-diagram/models/match-route";
import {DateRangeSearchDiagram} from "@app/modules/route-diagram/models/date-range-search-diagram";
import {TruckBookingForDiagramDto} from "@app/modules/route-diagram/models/tuckbooking-for-diagram-dto";
@Component({
  selector: 'app-route-diagram',
  templateUrl: './route-diagram.component.html',
  styleUrls: ['./route-diagram.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RouteDiagramComponent implements OnInit, AfterViewInit {

  @ViewChild('drawflow') drawflowElement: ElementRef;
  editor: Drawflow;

  bookingId: number;
  mappedShipments:ShipmentForDiagramDto[];
  mappedTruckBookings:TruckBookingForDiagramDto[];

  search:DateRangeSearchDiagram;

  constructor(
    // private pageLoad: PageloadingService,
    private activedRoute: ActivatedRoute,
    private shipmentService: BookingServiceService
  ) { }

  ngOnInit(): void {
    AppContants.beadcrumb = [];
    AppContants.beadcrumb = [{name: 'Dasboard', url: '/route-diagram'}];

    // Get BookingId Param from url
    this.activedRoute.params.subscribe((p) => this.bookingId = p.bookingId);

    this.search = new DateRangeSearchDiagram();
    this.search.startDateFrom = new Date();
    this.search.startDateTo = new Date();
    // Default 1 Day after present
    this.search.startDateFrom.setDate(this.search.startDateFrom.getDate() - 1);
  }

  ngAfterViewInit(): void {

    this.shipmentService.getTruckBookingForDiagramAPI(this.search).then((shipments) => {
      this.mappedTruckBookings = <TruckBookingForDiagramDto[]>shipments;
      this.prepDiagramDataV3();
    });
    //this.pageload.hide();
  }
  onSearchDateChange(){
    if(this.editor){
      this.editor.clear();
    }
    this.search.startDateTo.setHours(23,59,59);
    this.shipmentService.getTruckBookingForDiagramAPI(this.search).then((shipments) => {
      this.mappedTruckBookings = <TruckBookingForDiagramDto[]>shipments;
      this.prepDiagramDataV3();
    }).catch(reason => {
      console.error(reason)
    });
  }

  prepDiagramDataV3(){
    const prepTruckBooking = this.mappedTruckBookings;
    let prepShipmentRow = new DrawRouteRowModel();
    for (let i = 0; i < prepTruckBooking.length; i++) {

      for (let j = 0; j < prepTruckBooking[i]?.truckBookingLocationForDiagram?.length; j++) {

        let prepOriNodeData:OriNodeDataModel;
        let prepMiddleNodeData:MiddleNodeDataModel;
        let prepDestNodeData:DestNodeDataModel;


        // Ori > 0 , Dest > 0 ถือว่า Shipment สามารถแสดงผลได้
        if(prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originLocationId > 0 && prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationLocationId > 0)
        {
          // const testVar = prepShipmentRow?.oriNodeData?.find(a =>
          //   a.name == prepTruckBooking[i].shipmentLocations[j].originShortAddress);

          // เพิ่มจุดแรก Origin แรกของ Location นั้น
          if(prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originSequence === 1 &&
            _.isEmpty(prepShipmentRow?.oriNodeData?.find(a =>
              a.placeId == prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originPlaceId)))
          {
            prepOriNodeData = new OriNodeDataModel()
            prepOriNodeData.name = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShortAddress;
            prepOriNodeData.factory = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShortAddress;
            //prepOriNodeData.shipmentId = prepTruckBooking[i]?.shipmentId;
            prepOriNodeData.locationId = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originLocationId;
            prepOriNodeData.placeId = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originPlaceId;

            // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
            prepOriNodeData.totalRoute = prepTruckBooking[i].totalRoute;
            prepOriNodeData.completedRoute = prepTruckBooking[i].completedRoute;
            prepOriNodeData.lateRoute = prepTruckBooking[i].lateRoute;
            prepOriNodeData.pendingRoute = prepTruckBooking[i].pendingRoute;
            //prepOriNodeData.defectRoute = 0;

            if(prepTruckBooking[i]?.lateRoute > 0){
              prepOriNodeData.lateBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }
            if(prepTruckBooking[i]?.pendingRoute > 0){
              prepOriNodeData.pendingBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }
            if(prepTruckBooking[i]?.inProgressRoute > 0){
              prepOriNodeData.inProgressBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }
            if(prepTruckBooking[i]?.completedRoute > 0){
              prepOriNodeData.inProgressBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }

            //prepOriNodeData.shipmentNo = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipmentNo;

            prepOriNodeData.totalShipment = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.length;

            const oriLateShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.filter(a=>a.shipmentStatus == 2);
            prepOriNodeData.totalLateShipment = oriLateShip.length;

            const oriCompletedShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.filter(a=>a.shipmentStatus == 3);
            prepOriNodeData.totalCompletedShipment = oriCompletedShip.length;

            // wait for definition
            prepOriNodeData.totalNormalShipment = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.filter(a=>a.shipmentStatus == 1).length;

            _.forEach(oriLateShip, o => {
              if(!prepOriNodeData.lateShipments) prepOriNodeData.lateShipments = []
              prepOriNodeData.lateShipments.push(o.shipmentNo);
            });

          }else
          if(prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originSequence === 1 &&
            !_.isEmpty(prepShipmentRow?.oriNodeData?.find(a =>
              a.placeId == prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originPlaceId)))
          {
            prepOriNodeData = prepShipmentRow?.oriNodeData?.find(a =>
              a.placeId == prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originPlaceId)

            prepOriNodeData.totalRoute += prepTruckBooking[i].totalRoute;
            prepOriNodeData.completedRoute += prepTruckBooking[i].completedRoute;
            prepOriNodeData.lateRoute += prepTruckBooking[i].lateRoute;
            prepOriNodeData.pendingRoute += prepTruckBooking[i].pendingRoute;

            if(prepTruckBooking[i]?.lateRoute > 0 && _.isEmpty(prepOriNodeData.lateBookingNo)){
              prepOriNodeData.lateBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }else if(prepTruckBooking[i]?.lateRoute > 0 && _.isEmpty(prepOriNodeData?.lateBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepOriNodeData.lateBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            if(prepTruckBooking[i]?.pendingRoute > 0 && _.isEmpty(prepOriNodeData.pendingBookingNo)){
              prepOriNodeData.pendingBookingNo = [prepTruckBooking[i]?.truckBookingNo]
            }else if(prepTruckBooking[i]?.pendingRoute > 0 && _.isEmpty(prepOriNodeData?.pendingBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepOriNodeData.pendingBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            if(prepTruckBooking[i]?.inProgressRoute > 0 && _.isEmpty(prepOriNodeData.inProgressBookingNo)){
              prepOriNodeData.inProgressBookingNo = [prepTruckBooking[i]?.truckBookingNo]
            }else if(prepTruckBooking[i]?.inProgressRoute > 0 && _.isEmpty(prepOriNodeData?.inProgressBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepOriNodeData.inProgressBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            if(prepTruckBooking[i]?.completedRoute > 0 && _.isEmpty(prepOriNodeData.completedBookingNo)){
              prepOriNodeData.completedBookingNo = [prepTruckBooking[i]?.truckBookingNo]
            }else if(prepTruckBooking[i]?.completedRoute > 0 && _.isEmpty(prepOriNodeData?.completedBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepOriNodeData.completedBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            // if(prepOriNodeData.shipmentNo) prepOriNodeData.shipmentNo.push(...prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipmentNo);
            // else prepOriNodeData.shipmentNo = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipmentNo;

            prepOriNodeData.totalShipment += prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.length;

            const oriLateShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.filter(a=>a.shipmentStatus == 2);
            prepOriNodeData.totalLateShipment += oriLateShip.length;

            const oriCompletedShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.filter(a=>a.shipmentStatus == 3);
            prepOriNodeData.totalCompletedShipment += oriCompletedShip.length;

            // wait for definition
            prepOriNodeData.totalNormalShipment += prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originShipments.filter(a=>a.shipmentStatus == 1).length;

            _.forEach(oriLateShip, o => {
              if(!prepOriNodeData.lateShipments) prepOriNodeData.lateShipments = [];
              prepOriNodeData.lateShipments.push(o.shipmentNo);
            });
          }

          // หาจุด Middle บนเส้นทาง
          if(prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originSequence != 1 &&
            !_.isEmpty(prepTruckBooking[i].truckBookingLocationForDiagram.find(a => a.destinationSequence == prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.originSequence)) &&
            !_.isEmpty(prepTruckBooking[i].truckBookingLocationForDiagram.find(a => a.originSequence == prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationSequence))){

            // prepMiddleNodeData = new MiddleNodeDataModel();
            // prepMiddleNodeData.name = prepTruckBooking[i]?.shipmentLocations[j]?.originShortAddress;
            // prepMiddleNodeData.factory = prepTruckBooking[i]?.shipmentLocations[j]?.originShortAddress;
            // prepMiddleNodeData.shipmentId = prepTruckBooking[i]?.shipmentId;
            // prepMiddleNodeData.locationId = prepTruckBooking[i]?.shipmentLocations[j]?.destinationLocationId;
            //
            // // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
            // prepMiddleNodeData.totalRoute = prepTruckBooking[i].totalRoute;
            // prepMiddleNodeData.completedRoute = prepTruckBooking[i].completedRoute;
            // prepMiddleNodeData.lateRoute = prepTruckBooking[i].lateRoute;
            // prepMiddleNodeData.pendingRoute = prepTruckBooking[i].pendingRoute;
          }
          // อื่นๆ คือว่าเป็น Des สุดท้าย เช็คก่อนว่า เป็น location เดียวกันหรือปล่าว ถ้าเป็นที่เดียวกันจะใช้ Node เดียวกัน
          else if(_.isEmpty(prepShipmentRow?.destNodeData?.find(a =>
            a.placeId === prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationPlaceId))
          ){
            prepDestNodeData = new DestNodeDataModel();
            prepDestNodeData.name = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShortAddress;
            prepDestNodeData.customer_name = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShortAddress;
            //prepDestNodeData.shipmentId = prepTruckBooking[i]?.shipmentId;
            //prepDestNodeData.locationId = prepTruckBooking[i]?.shipmentLocations[j]?.destinationLocationId;
            prepDestNodeData.placeId = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationPlaceId;

            prepDestNodeData.totalRoute = prepTruckBooking[i].totalRoute;
            prepDestNodeData.completedRoute = prepTruckBooking[i].completedRoute;
            prepDestNodeData.lateRoute = prepTruckBooking[i].lateRoute;
            prepDestNodeData.pendingRoute = prepTruckBooking[i].pendingRoute;

            if(prepTruckBooking[i]?.lateRoute > 0){
              prepDestNodeData.lateBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }
            if(prepTruckBooking[i]?.pendingRoute > 0){
              prepDestNodeData.pendingBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }
            if(prepTruckBooking[i]?.inProgressRoute > 0){
              prepDestNodeData.inProgressBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }
            if(prepTruckBooking[i]?.completedRoute > 0){
              prepDestNodeData.completedBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }

            prepDestNodeData.totalShipment = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.length;

            const destLateShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.filter(a=>a.shipmentStatus == 2)
            prepDestNodeData.totalLateShipment = destLateShip.length;

            const destCompletedShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.filter(a=>a.shipmentStatus == 3)
            prepDestNodeData.totalCompletedShipment = destCompletedShip.length;

            // wait for definition
            prepDestNodeData.totalNormalShipment = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.filter(a=>a.shipmentStatus == 1).length;

            _.forEach(destLateShip, d => {
              if(!prepDestNodeData.lateShipments) prepDestNodeData.lateShipments = []
              prepDestNodeData.lateShipments.push(d.shipmentNo);
            });
          }
          else if(!_.isEmpty(prepShipmentRow?.destNodeData?.find(a =>
            a.placeId === prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationPlaceId))
          ){
            // Update Defect Route
            prepDestNodeData = prepShipmentRow?.destNodeData?.find(a =>
              a.placeId == prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationPlaceId);

            prepDestNodeData.totalRoute += prepTruckBooking[i].totalRoute;
            prepDestNodeData.completedRoute += prepTruckBooking[i].completedRoute;
            prepDestNodeData.lateRoute += prepTruckBooking[i].lateRoute;
            prepDestNodeData.pendingRoute += prepTruckBooking[i].pendingRoute;

            if(prepTruckBooking[i]?.lateRoute > 0 && _.isEmpty(prepDestNodeData.lateBookingNo)){
              prepDestNodeData.lateBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }else if(prepTruckBooking[i]?.lateRoute > 0 && _.isEmpty(prepDestNodeData?.lateBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepDestNodeData.lateBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            if(prepTruckBooking[i]?.pendingRoute > 0 && _.isEmpty(prepDestNodeData.pendingBookingNo)){
              prepDestNodeData.pendingBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }else if(prepTruckBooking[i]?.pendingRoute > 0 && _.isEmpty(prepDestNodeData?.pendingBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepDestNodeData.pendingBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            if(prepTruckBooking[i]?.inProgressRoute > 0 && _.isEmpty(prepDestNodeData.inProgressBookingNo)){
              prepDestNodeData.inProgressBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }else if(prepTruckBooking[i]?.inProgressRoute > 0 && _.isEmpty(prepDestNodeData?.inProgressBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepDestNodeData.inProgressBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            if(prepTruckBooking[i]?.completedRoute > 0 && _.isEmpty(prepDestNodeData.completedBookingNo)){
              prepDestNodeData.completedBookingNo = [prepTruckBooking[i]?.truckBookingNo];
            }else if(prepTruckBooking[i]?.completedRoute > 0 && _.isEmpty(prepDestNodeData?.completedBookingNo?.find(a=>a === prepTruckBooking[i]?.truckBookingNo))){
              prepDestNodeData.completedBookingNo.push(prepTruckBooking[i]?.truckBookingNo);
            }

            // if(prepDestNodeData.shipmentNo) prepDestNodeData.shipmentNo.push(...prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipmentNo);
            // else prepDestNodeData.shipmentNo = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipmentNo;

            prepDestNodeData.totalShipment += prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.length;

            const destLateShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.filter(a=>a.shipmentStatus == 2)
            prepDestNodeData.totalLateShipment += destLateShip.length;

            const destCompletedShip = prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.filter(a=>a.shipmentStatus == 3)
            prepDestNodeData.totalCompletedShipment += destCompletedShip.length;

            // wait for definition
            prepDestNodeData.totalNormalShipment += prepTruckBooking[i]?.truckBookingLocationForDiagram[j]?.destinationShipments.filter(a=>a.shipmentStatus == 1).length;

            _.forEach(destLateShip, d => {
              if(!prepDestNodeData.lateShipments) prepDestNodeData.lateShipments = [];
              prepDestNodeData.lateShipments.push(d.shipmentNo);
            });
          }
        }

        if(prepOriNodeData){
          if(prepShipmentRow.oriNodeData) {
            if(_.isEmpty(prepShipmentRow.oriNodeData.find(a=>a.placeId == prepOriNodeData.placeId)))
            {
              prepShipmentRow.oriNodeData.push(prepOriNodeData);
            }
          }else{
            prepShipmentRow.oriNodeData = [prepOriNodeData]
          }
        }
        if(prepMiddleNodeData){
          if(prepShipmentRow.middleNodeData) {
            prepShipmentRow.middleNodeData.push(prepMiddleNodeData)
          }else{
            prepShipmentRow.middleNodeData = [prepMiddleNodeData];
          }
        }
        if(prepDestNodeData){
          if(prepShipmentRow.destNodeData) {
            if(_.isEmpty(prepShipmentRow.destNodeData.find(a=>a.placeId == prepDestNodeData.placeId)))
            {
              prepShipmentRow.destNodeData.push(prepDestNodeData);
            }
          }else{
            prepShipmentRow.destNodeData = [prepDestNodeData];
          }
        }

        // Connection Prep
        let matchRoute = new MatchRouteModel();
        matchRoute.oriClassName = 'loc-id_' + _.snakeCase(prepTruckBooking[i]?.truckBookingLocationForDiagram[j].originPlaceId) + '_flowbox';
        matchRoute.destClassName = 'loc-id_' + _.snakeCase(prepTruckBooking[i]?.truckBookingLocationForDiagram[j].destinationPlaceId) + '_flowbox';

        if(prepShipmentRow.matchRoute){
          if(_.isEmpty(prepShipmentRow.matchRoute.find(a=>a.oriClassName === matchRoute.oriClassName && a.destClassName === matchRoute.destClassName))) {
            prepShipmentRow.matchRoute.push(matchRoute);
          }else {
            matchRoute = prepShipmentRow.matchRoute.find(a=>a.oriClassName === matchRoute.oriClassName && a.destClassName === matchRoute.destClassName);
          }
        }else{
          prepShipmentRow.matchRoute = [matchRoute];
        }

        // Will be checked defect.
        if(prepTruckBooking[i]?.pendingRoute > 0)
        {
          matchRoute.isPending = true;
        }
        if(prepTruckBooking[i]?.lateRoute > 0)
        {
          matchRoute.isLate = true;
        }
        if(prepTruckBooking[i]?.completedRoute > 0)
        {
          matchRoute.isCompleted = true;
        }
      }
      //shipmentRowList.push(prepShipmentRow);
    }
    if(prepShipmentRow.destNodeData && prepShipmentRow.oriNodeData && prepShipmentRow.matchRoute)
    {
      this.generateDiagram3(prepShipmentRow);
    }
  }

  generateDiagram3(shipmentRowModel:DrawRouteRowModel){
    //#region Init Drawflow

    // เพื่อใช้เรียกใช้แทน This
    let forEditor = new Drawflow(this.drawflowElement.nativeElement);
    this.editor = forEditor;
    forEditor.start();
    forEditor.reroute = false;
    forEditor.editor_mode = "fixed";
    this.drawflowRegisterEventV2(forEditor);

    //#region Prep Origin Box
    let globeRow = 0; // row index

    // PrepTemplate
    const oriTemplate = this.getFactoryOriginTemplateV2();
    const middleTemplate = this.getFactoryOriginTemplateV2();
    const destTemplate = this.getFactoryDestTemplateV2();
    const onGoingStatusTemplate = '<span style="color:#24a56d">ราบรื่น</style>'
    const issuesStatusTemplate = '<span style="color:red">มีปัญหา</style>'


    //for (const shipmentRowModel of shipmentRowList) {
      // จะ Fetch Shipment เป็นแบบ Row แทน
      // ความสูงระหว่าง Box
      let col = 1;
      const hightRef = 230;
      let highCalResult = hightRef * globeRow;
      let horizonCalResult = 160 * col;
      //let horizonCalResult = 160 * col;
      //let inSideRow:number = rowIndex === 0 ? 1 : rowIndex + row;
      for (let rowIndex = 0; rowIndex < shipmentRowModel.oriNodeData.length; rowIndex++) {
        let inSideHeight = highCalResult + (rowIndex * hightRef);
        // ClassName ที่จะนำมาใช้ สร้าง connection
        const normalizeOriClassName = 'loc-id_' + _.snakeCase(shipmentRowModel.oriNodeData[rowIndex].placeId) + '_flowbox';
        if(shipmentRowModel.oriNodeData[rowIndex].lateRoute > 0 ||  shipmentRowModel.oriNodeData[rowIndex].defectRoute > 0)
        {
          shipmentRowModel.oriNodeData[rowIndex].status = issuesStatusTemplate;
        }else {
          shipmentRowModel.oriNodeData[rowIndex].status = onGoingStatusTemplate;
        }

        // Add Node
        try {
          forEditor.addNode(normalizeOriClassName, 0, 1, 30, inSideHeight,
            normalizeOriClassName + '_flowbox',
            shipmentRowModel.oriNodeData[rowIndex],
            oriTemplate, false);
        }
        catch (error)
        {
          console.error(error);
        }
        if(globeRow < rowIndex) globeRow++;
      }

      col++;
      horizonCalResult = 160 * col;

      // Add Middle Nodes
      if(shipmentRowModel?.middleNodeData?.length > 0){
        for (let rowIndex = 0; rowIndex < shipmentRowModel.middleNodeData.length; rowIndex++){
          let inSideHeight = highCalResult + (rowIndex * hightRef);
          const normalizeClassName = 'loc-id_' + _.snakeCase(shipmentRowModel.middleNodeData[rowIndex].placeId) + '_flowbox';
          if(shipmentRowModel.middleNodeData[rowIndex].lateRoute > 0 ||  shipmentRowModel.middleNodeData[rowIndex].defectRoute > 0)
          {
            shipmentRowModel.middleNodeData[rowIndex].status = issuesStatusTemplate;
          }else {
            shipmentRowModel.middleNodeData[rowIndex].status = onGoingStatusTemplate;
          }
          // Add Node
          try {
            forEditor.addNode(normalizeClassName, 1, 1, horizonCalResult, inSideHeight,
              normalizeClassName,
              shipmentRowModel.middleNodeData[rowIndex],
              middleTemplate, false);
          }
          catch (error)
          {
            console.error(error);
          }
          if(globeRow < rowIndex) globeRow++;
        }
        col++;
      }

      horizonCalResult = 160 * col;

      // Add Dest Nodes
      for (let rowIndex = 0; rowIndex < shipmentRowModel.destNodeData.length; rowIndex++){
        let inSideHeight = highCalResult + (rowIndex * hightRef);
        const normalizeClassName =  'loc-id_' + _.snakeCase(shipmentRowModel.destNodeData[rowIndex].placeId) + '_flowbox';
        if(shipmentRowModel.destNodeData[rowIndex].lateRoute > 0 ||  shipmentRowModel.destNodeData[rowIndex].defectRoute > 0)
        {
          shipmentRowModel.destNodeData[rowIndex].status = issuesStatusTemplate;
        }else {
          shipmentRowModel.destNodeData[rowIndex].status = onGoingStatusTemplate;
        }
        // Add Node
        try {
          forEditor.addNode(normalizeClassName, 1, 0, horizonCalResult, inSideHeight,
            normalizeClassName,
            shipmentRowModel.destNodeData[rowIndex],
            destTemplate, false);
        }
        catch (error)
        {
          console.error(error);
        }
        if(globeRow < rowIndex) globeRow++;
      }
      globeRow++
      // Add Connection
      for (const matchRoute of shipmentRowModel.matchRoute) {
        const oriNodeId = forEditor.getNodesFromName(matchRoute.oriClassName)[0];
        const destNodeId = forEditor.getNodesFromName(matchRoute.destClassName)[0];
        forEditor.addConnection(oriNodeId, destNodeId, "output_1", "input_1");
        if(matchRoute.isCompleted)
        {
          console.debug("Complete Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "green");
        }
        if(matchRoute.isPending)
        {
          console.debug("Pending Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "gray");
        }
        if(matchRoute.isInProgress)
        {
          console.debug("IsInProgress Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "#0b77c9");
        }
        if(matchRoute.isLate)
        {
          console.debug("Late Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "red");
        }
        if (matchRoute.isDefect) {
          console.debug("Defect Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "red");
        }
      }
    //}
  }

  getFactoryOriginTemplateV2(): string {
    return `
    <div class="no-v-align">
      <a href="#">
        <h5><i class="fas fa-warehouse"></i> <span df-factory></span></h5>
        <span>สถานะรวม: </span><span df-status></span><br/>
        <span>จำนวน Shipment ทั้งหมด: </span><span df-totalShipment></span><br/>
        <span>&nbsp;&nbsp;- รอเริ่มงาน: </span><span df-pendingRoute></span><br/>
        <span>&nbsp;&nbsp;- ที่ล่าช้า: </span><span df-totalLateShipment style="color: red"></span><br/>
        <span>&nbsp;&nbsp;- กำลังขนส่ง: </span><span df-totalNormalShipment style="color: green"></span><br/>
        <span>&nbsp;&nbsp;- สำเร็จ </span><span df-totalCompletedShipment style="color: green"></span><br/>
        <input type="hidden" df-name />
      </a>
    </div>
    `
    //<span>Shipment ID: </span><span df-shipmentId></span><br/> df-totalShipment
    //<span>มีปัญหา: </span><span df-defectRoute></span><br/>
  }

  getFactoryDestTemplateV2(): string {
    return `
    <div class="no-v-align">
      <a href="#">
        <h5><i class="fas fa-map-marker-alt"></i> <span df-customer_name></span></h5>
        <span>สถานะรวม: </span><span df-status></span><br/>
        <span>จำนวน Shipment ทั้งหมด: </span><span df-totalShipment></span><br/>
        <span>&nbsp;&nbsp;- รอเริ่มงาน: </span><span df-pendingRoute></span><br/>
        <span>&nbsp;&nbsp;- ที่ล่าช้า: </span><span df-totalLateShipment style="color: red"></span><br/>
        <span>&nbsp;&nbsp;- กำลังขนส่ง: </span><span df-totalNormalShipment style="color: green"></span><br/>
        <span>&nbsp;&nbsp;- สำเร็จ </span><span df-totalCompletedShipment style="color: green"></span><br/>
        <input type="hidden" df-name />
      </a>
    </div>
    `
    ///df-totalShipment
  }

  getNodeToolTipTemplateV2(nodeData: any): [string, boolean] {
    let isHasData = false;
    let str:string = '<div class="row">';
    // Template for FactoryNodeDataModel
    // if (nodeData.typeName === OriNodeDataModel.name && nodeData.defectBookingNo !== undefined && nodeData.defectBookingNo.length !== 0) {
    //   str += `<div class="col">
    //   <span>Booking ที่มีปัญหา</span><br/>
    // `;
    //   nodeData.defectBookingNo.forEach(value => {
    //     str += '<span>' + value + '</span><br/>'
    //   });
    //   str += '<br/></div>';
    // }

    if (nodeData.typeName === OriNodeDataModel.name && nodeData.lateShipments !== undefined && nodeData.lateShipments.length !== 0) {
      str += `<div class="col">
      <span>Shipment ที่ล่าช้า</span><br/>
    `;
      nodeData.lateShipments.forEach(value => {
        str += '<span>' + value + '</span><br/>'
      });
      str += '<br/></div>';
      isHasData = true;
    }

    // if (nodeData.typeName === OriNodeDataModel.name && nodeData.pendingBookingNo !== undefined && nodeData.pendingBookingNo.length !== 0) {
    //   str += `<div class="col">
    //   <span>Booking ที่รอเริ่มงาน</span><br/>
    // `;
    //   nodeData.pendingBookingNo.forEach(value => {
    //     str += '<span>' + value + '</span><br/>'
    //   });
    //   str += '<br/></div>';
    // }

    // if (nodeData.typeName === OriNodeDataModel.name && nodeData.inProgressBookingNo !== undefined && nodeData.inProgressBookingNo.length !== 0) {
    //   str += `<div class="col">
    //   <span>Booking ที่อยู่ระหว่างขนส่ง</span><br/>
    // `;
    //   nodeData.inProgressBookingNo.forEach(value => {
    //     str += '<span>' + value + '</span><br/>'
    //   });
    //   str += '<br/></div>';
    // }

    // Template for DestNodeData
    // if (nodeData.typeName === DestNodeDataModel.name && nodeData.defectBookingNo !== undefined && nodeData.defectBookingNo.length !== 0) {
    //   str += `<div class="col">
    //   <span>Booking ที่มีปัญหา</span><br/>
    // `;
    //   nodeData.defectBookingNo.forEach(value => {
    //     str += '<span>' + value + '</span><br/>'
    //   });
    //   str += '<br/></div>';
    // }

    if (nodeData.typeName === DestNodeDataModel.name && nodeData.lateShipments !== undefined && nodeData.lateShipments.length !== 0) {
      str += `<div class="col">
      <span>Shipment ที่ล่าช้า</span><br/>
    `;
      nodeData.lateShipments.forEach(value => {
        str += '<span>' + value + '</span><br/>'
      });
      str += '<br/></div>';
      isHasData = true;
    }

    // if (nodeData.typeName === DestNodeDataModel.name && nodeData.pendingBookingNo !== undefined && nodeData.pendingBookingNo.length !== 0) {
    //   str += `<div class="col">
    //   <span>Booking ที่รอเริ่มงาน</span><br/>
    // `;
    //   nodeData.pendingBookingNo.forEach(value => {
    //     str += '<span>' + value + '</span><br/>'
    //   });
    //   str += '<br/></div>';
    // }

    // if (nodeData.typeName === DestNodeDataModel.name && nodeData.inProgressBookingNo !== undefined && nodeData.inProgressBookingNo.length !== 0) {
    //   str += `<div class="col">
    //   <span>Booking ที่อยู่ระหว่างขนส่ง</span><br/>
    // `;
    //   nodeData.inProgressBookingNo.forEach(value => {
    //     str += '<span>' + value + '</span><br/>'
    //   });
    //   str += '<br/></div>';
    // }

    return [str, isHasData];
  }

  // Prep Node creation event
  drawflowRegisterEventV2(editor: any): void {
    let currentPage = this;
    editor.on("nodeCreated", function (id) {
      const node = editor.getNodeFromId(id);
      const data = node.data;
      const content = document.querySelector("#node-" + id);

      // This function add parameter to the template
      Object.entries(data).forEach(function (key, value) {
        if (typeof key[1] === "object") {
          insertObjectkeys(null, key[0], key[0]);
        } else {
          let elems = content.querySelectorAll("[df-" + key[0] + "]");
          for (let i = 0; i < elems.length; i++) {
            elems[i].innerHTML = key[1] as string;
          }
        }
      });
      // Add Tip on node
      const tooltipNodeTemplate = currentPage.getNodeToolTipTemplateV2(data);
      if (tooltipNodeTemplate[1]) {
        Tippy("#node-" + id, {content: tooltipNodeTemplate[0], allowHTML: true, followCursor: true});
      }

      function insertObjectkeys(object, name, completname) {
        if (object === null) {
          var object = data[name];
        } else {
          var object = object[name];
        }
        Object.entries(object).forEach(function (key, value) {
          if (typeof key[1] === "object") {
            insertObjectkeys(object, key[0], name + "-" + key[0]);
          } else {
            let elems = content.querySelectorAll(
              "[df-" + completname + "-" + key[0] + "]"
            );
            for (let i = 0; i < elems.length; i++) {
              elems[i].innerHTML = key[1] as string;
            }
          }
        });
      }
    });

    editor.on("connectionCreated", function (info) {

      // console.log(info);
      // const classname = info.output_class + " " + info.input_class;
      // let element = document.getElementsByClassName(classname);
      // if(element.classname == "input_4 input_1")
      // {
      //   element[0].style = "stroke: red !important";
      // }
    });
  }


  //===================================== Deprecated ============================================================
  // This Function is deprecated
  prepDiagramDataV2(){
    const prepShipment = this.mappedShipments;
    let prepShipmentRow = new DrawRouteRowModel();
    for (let i = 0; i < prepShipment.length; i++) {

      for (let j = 0; j < prepShipment[i].shipmentLocations.length; j++) {

        let prepOriNodeData:OriNodeDataModel;
        let prepMiddleNodeData:MiddleNodeDataModel;
        let prepDestNodeData:DestNodeDataModel;


        // Ori > 0 , Dest > 0 ถือว่า Shipment สามารถแสดงผลได้
        if(prepShipment[i]?.shipmentLocations[j]?.originLocationId > 0 && prepShipment[i]?.shipmentLocations[j]?.destinationLocationId > 0)
        {
          // const testVar = prepShipmentRow?.oriNodeData?.find(a =>
          //   a.name == prepShipment[i].shipmentLocations[j].originShortAddress);

          // เพิ่มจุดแรก Origin แรกของ Location นั้น
          if(prepShipment[i]?.shipmentLocations[j]?.originSequence === 1 &&
            _.isEmpty(prepShipmentRow?.oriNodeData?.find(a =>
              a.placeId == prepShipment[i]?.shipmentLocations[j]?.originPlaceId)))
          {
            prepOriNodeData = new OriNodeDataModel()
            prepOriNodeData.name = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            prepOriNodeData.factory = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            prepOriNodeData.shipmentId = prepShipment[i]?.shipmentId;
            prepOriNodeData.locationId = prepShipment[i]?.shipmentLocations[j]?.originLocationId;
            prepOriNodeData.placeId = prepShipment[i]?.shipmentLocations[j]?.originPlaceId;

            // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
            prepOriNodeData.totalRoute = prepShipment[i].totalRoute;
            prepOriNodeData.completedRoute = prepShipment[i].completedRoute;
            prepOriNodeData.lateRoute = prepShipment[i].lateRoute;
            prepOriNodeData.pendingRoute = prepShipment[i].pendingRoute;
            //prepOriNodeData.defectRoute = 0;

            if(prepShipment[i]?.lateRoute > 0){
              prepOriNodeData.lateBookingNo = [prepShipment[i]?.truckBookingNo];
            }
            if(prepShipment[i]?.pendingRoute > 0){
              prepOriNodeData.pendingBookingNo = [prepShipment[i]?.truckBookingNo];
            }
            if(prepShipment[i]?.inProgressRoute > 0){
              prepOriNodeData.inProgressBookingNo = [prepShipment[i]?.truckBookingNo];
            }
            if(prepShipment[i]?.completedRoute > 0){
              prepOriNodeData.inProgressBookingNo = [prepShipment[i]?.truckBookingNo];
            }

          }else
          if(prepShipment[i]?.shipmentLocations[j]?.originSequence === 1 &&
            !_.isEmpty(prepShipmentRow?.oriNodeData?.find(a =>
              a.placeId == prepShipment[i]?.shipmentLocations[j]?.originPlaceId)))
          {
            prepOriNodeData = prepShipmentRow?.oriNodeData?.find(a =>
              a.placeId == prepShipment[i]?.shipmentLocations[j]?.originPlaceId)

            prepOriNodeData.totalRoute += prepShipment[i].totalRoute;
            prepOriNodeData.completedRoute += prepShipment[i].completedRoute;
            prepOriNodeData.lateRoute += prepShipment[i].lateRoute;
            prepOriNodeData.pendingRoute += prepShipment[i].pendingRoute;

            if(prepShipment[i]?.lateRoute > 0 && _.isEmpty(prepOriNodeData.lateBookingNo)){
              prepOriNodeData.lateBookingNo = [prepShipment[i]?.truckBookingNo];
            }else if(prepShipment[i]?.lateRoute > 0 && _.isEmpty(prepOriNodeData?.lateBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepOriNodeData.lateBookingNo.push(prepShipment[i]?.truckBookingNo);
            }

            if(prepShipment[i]?.pendingRoute > 0 && _.isEmpty(prepOriNodeData.pendingBookingNo)){
              prepOriNodeData.pendingBookingNo = [prepShipment[i]?.truckBookingNo]
            }else if(prepShipment[i]?.pendingRoute > 0 && _.isEmpty(prepOriNodeData?.pendingBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepOriNodeData.pendingBookingNo.push(prepShipment[i]?.truckBookingNo);
            }

            if(prepShipment[i]?.inProgressRoute > 0 && _.isEmpty(prepOriNodeData.inProgressBookingNo)){
              prepOriNodeData.inProgressBookingNo = [prepShipment[i]?.truckBookingNo]
            }else if(prepShipment[i]?.inProgressRoute > 0 && _.isEmpty(prepOriNodeData?.inProgressBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepOriNodeData.inProgressBookingNo.push(prepShipment[i]?.truckBookingNo);
            }

            if(prepShipment[i]?.completedRoute > 0 && _.isEmpty(prepOriNodeData.completedBookingNo)){
              prepOriNodeData.completedBookingNo = [prepShipment[i]?.truckBookingNo]
            }else if(prepShipment[i]?.completedRoute > 0 && _.isEmpty(prepOriNodeData?.completedBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepOriNodeData.completedBookingNo.push(prepShipment[i]?.truckBookingNo);
            }
          }

          // หาจุด Middle บนเส้นทาง
          if(prepShipment[i]?.shipmentLocations[j]?.originSequence != 1 &&
            !_.isEmpty(prepShipment[i].shipmentLocations.find(a => a.destinationSequence == prepShipment[i]?.shipmentLocations[j]?.originSequence)) &&
            !_.isEmpty(prepShipment[i].shipmentLocations.find(a => a.originSequence == prepShipment[i]?.shipmentLocations[j]?.destinationSequence))){

            // prepMiddleNodeData = new MiddleNodeDataModel();
            // prepMiddleNodeData.name = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            // prepMiddleNodeData.factory = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            // prepMiddleNodeData.shipmentId = prepShipment[i]?.shipmentId;
            // prepMiddleNodeData.locationId = prepShipment[i]?.shipmentLocations[j]?.destinationLocationId;
            //
            // // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
            // prepMiddleNodeData.totalRoute = prepShipment[i].totalRoute;
            // prepMiddleNodeData.completedRoute = prepShipment[i].completedRoute;
            // prepMiddleNodeData.lateRoute = prepShipment[i].lateRoute;
            // prepMiddleNodeData.pendingRoute = prepShipment[i].pendingRoute;
          }
          // อื่นๆ คือว่าเป็น Des สุดท้าย เช็คก่อนว่า เป็น location เดียวกันหรือปล่าว ถ้าเป็นที่เดียวกันจะใช้ Node เดียวกัน
          else if(_.isEmpty(prepShipmentRow?.destNodeData?.find(a =>
            a.placeId === prepShipment[i]?.shipmentLocations[j]?.destinationPlaceId))
          ){
            prepDestNodeData = new DestNodeDataModel();
            prepDestNodeData.name = prepShipment[i]?.shipmentLocations[j]?.destinationShortAddress;
            prepDestNodeData.customer_name = prepShipment[i]?.shipmentLocations[j]?.destinationShortAddress;
            //prepDestNodeData.shipmentId = prepShipment[i]?.shipmentId;
            //prepDestNodeData.locationId = prepShipment[i]?.shipmentLocations[j]?.destinationLocationId;
            prepDestNodeData.placeId = prepShipment[i]?.shipmentLocations[j]?.destinationPlaceId;

            prepDestNodeData.totalRoute = prepShipment[i].totalRoute;
            prepDestNodeData.completedRoute = prepShipment[i].completedRoute;
            prepDestNodeData.lateRoute = prepShipment[i].lateRoute;
            prepDestNodeData.pendingRoute = prepShipment[i].pendingRoute;

            if(prepShipment[i]?.lateRoute > 0){
              prepDestNodeData.lateBookingNo = [prepShipment[i]?.truckBookingNo];
            }
            if(prepShipment[i]?.pendingRoute > 0){
              prepDestNodeData.pendingBookingNo = [prepShipment[i]?.truckBookingNo];
            }
            if(prepShipment[i]?.inProgressRoute > 0){
              prepDestNodeData.inProgressBookingNo = [prepShipment[i]?.truckBookingNo];
            }
            if(prepShipment[i]?.completedRoute > 0){
              prepDestNodeData.completedBookingNo = [prepShipment[i]?.truckBookingNo];
            }
          }
          else if(!_.isEmpty(prepShipmentRow?.destNodeData?.find(a =>
            a.placeId === prepShipment[i]?.shipmentLocations[j]?.destinationPlaceId))
          ){
            // Update Defect Route
            prepDestNodeData = prepShipmentRow?.destNodeData?.find(a =>
              a.placeId == prepShipment[i]?.shipmentLocations[j]?.destinationPlaceId);

            prepDestNodeData.totalRoute += prepShipment[i].totalRoute;
            prepDestNodeData.completedRoute += prepShipment[i].completedRoute;
            prepDestNodeData.lateRoute += prepShipment[i].lateRoute;
            prepDestNodeData.pendingRoute += prepShipment[i].pendingRoute;

            if(prepShipment[i]?.lateRoute > 0 && _.isEmpty(prepDestNodeData.lateBookingNo)){
              prepDestNodeData.lateBookingNo = [prepShipment[i]?.truckBookingNo];
            }else if(prepShipment[i]?.lateRoute > 0 && _.isEmpty(prepDestNodeData?.lateBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepDestNodeData.lateBookingNo.push(prepShipment[i]?.truckBookingNo);
            }

            if(prepShipment[i]?.pendingRoute > 0 && _.isEmpty(prepDestNodeData.pendingBookingNo)){
              prepDestNodeData.pendingBookingNo = [prepShipment[i]?.truckBookingNo];
            }else if(prepShipment[i]?.pendingRoute > 0 && _.isEmpty(prepDestNodeData?.pendingBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepDestNodeData.pendingBookingNo.push(prepShipment[i]?.truckBookingNo);
            }

            if(prepShipment[i]?.inProgressRoute > 0 && _.isEmpty(prepDestNodeData.inProgressBookingNo)){
              prepDestNodeData.inProgressBookingNo = [prepShipment[i]?.truckBookingNo];
            }else if(prepShipment[i]?.inProgressRoute > 0 && _.isEmpty(prepDestNodeData?.inProgressBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepDestNodeData.inProgressBookingNo.push(prepShipment[i]?.truckBookingNo);
            }

            if(prepShipment[i]?.completedRoute > 0 && _.isEmpty(prepDestNodeData.completedBookingNo)){
              prepDestNodeData.completedBookingNo = [prepShipment[i]?.truckBookingNo];
            }else if(prepShipment[i]?.completedRoute > 0 && _.isEmpty(prepDestNodeData?.completedBookingNo?.find(a=>a === prepShipment[i]?.truckBookingNo))){
              prepDestNodeData.completedBookingNo.push(prepShipment[i]?.truckBookingNo);
            }
          }
        }

        if(prepOriNodeData){
          if(prepShipmentRow.oriNodeData) {
            if(_.isEmpty(prepShipmentRow.oriNodeData.find(a=>a.placeId == prepOriNodeData.placeId)))
            {
              prepShipmentRow.oriNodeData.push(prepOriNodeData);
            }
          }else{
            prepShipmentRow.oriNodeData = [prepOriNodeData]
          }
        }
        if(prepMiddleNodeData){
          if(prepShipmentRow.middleNodeData) {
            prepShipmentRow.middleNodeData.push(prepMiddleNodeData)
          }else{
            prepShipmentRow.middleNodeData = [prepMiddleNodeData];
          }
        }
        if(prepDestNodeData){
          if(prepShipmentRow.destNodeData) {
            if(_.isEmpty(prepShipmentRow.destNodeData.find(a=>a.placeId == prepDestNodeData.placeId)))
            {
              prepShipmentRow.destNodeData.push(prepDestNodeData);
            }
          }else{
            prepShipmentRow.destNodeData = [prepDestNodeData];
          }
        }

        // Connection Prep
        let matchRoute = new MatchRouteModel();
        matchRoute.oriClassName = 'loc-id_' + _.snakeCase(prepShipment[i]?.shipmentLocations[j].originPlaceId) + '_flowbox';
        matchRoute.destClassName = 'loc-id_' + _.snakeCase(prepShipment[i]?.shipmentLocations[j].destinationPlaceId) + '_flowbox';

        if(prepShipmentRow.matchRoute){
          if(_.isEmpty(prepShipmentRow.matchRoute.find(a=>a.oriClassName === matchRoute.oriClassName && a.destClassName === matchRoute.destClassName))) {
            prepShipmentRow.matchRoute.push(matchRoute);
          }else {
            matchRoute = prepShipmentRow.matchRoute.find(a=>a.oriClassName === matchRoute.oriClassName && a.destClassName === matchRoute.destClassName);
          }
        }else{
          prepShipmentRow.matchRoute = [matchRoute];
        }

        // Will be checked defect.
        if(prepShipment[i]?.pendingRoute > 0)
        {
          matchRoute.isPending = true;
        }
        if(prepShipment[i]?.lateRoute > 0)
        {
          matchRoute.isLate = true;
        }
        if(prepShipment[i]?.completedRoute > 0)
        {
          matchRoute.isCompleted = true;
        }
      }
      //shipmentRowList.push(prepShipmentRow);
    }
    this.generateDiagram3(prepShipmentRow);
  }

  // This Function is deprecated
  generateDiagram2(shipmentRowList:Array<DrawRouteRowModel>){
    //#region Init Drawflow

    // เพื่อใช้เรียกใช้แทน This
    let forEditor = new Drawflow(this.drawflowElement.nativeElement);
    this.editor = forEditor;
    forEditor.start();
    forEditor.reroute = false;
    forEditor.editor_mode = "fixed";
    this.drawflowRegisterEvent(forEditor);

    //#region Prep Origin Box
    let globeRow = 0; // row index

    // PrepTemplate
    const oriTemplate = this.getFactoryOriginTemplate();
    const middleTemplate = this.getFactoryOriginTemplate();
    const destTemplate = this.getFactoryDestTemplate();
    const completeStatusTemplate = '<span style="color:#24a56d">ราบรื่น</style>'
    const issuesStatusTemplate = '<span style="color:red">มีปัญหา</style>'


    for (const shipmentRowModel of shipmentRowList) {
      // จะ Fetch Shipment เป็นแบบ Row แทน
      // ความสูงระหว่าง Box
      let col = 1;
      const hightRef = 230;
      let highCalResult = hightRef * globeRow;
      let horizonCalResult = 160 * col;
      //let horizonCalResult = 160 * col;
      //let inSideRow:number = rowIndex === 0 ? 1 : rowIndex + row;
      for (let rowIndex = 0; rowIndex < shipmentRowModel.oriNodeData.length; rowIndex++) {
        let inSideHeight = highCalResult + (rowIndex * hightRef);
        // ClassName ที่จะนำมาใช้ สร้าง connection
        const normalizeOriClassName = 'ship-id_'+ shipmentRowModel.oriNodeData[rowIndex].shipmentId
          + '_loc-id_' + shipmentRowModel.oriNodeData[rowIndex].locationId + '_flowbox';
        // Add Node
        try {
          forEditor.addNode(normalizeOriClassName, 0, 1, 30, inSideHeight,
            normalizeOriClassName + '_flowbox',
            shipmentRowModel.oriNodeData[rowIndex],
            oriTemplate, false);
        }
        catch (error)
        {
          console.error(error);
        }
        if(globeRow < rowIndex) globeRow++;
      }

      col++;
      horizonCalResult = 160 * col;

      // Add Middle Nodes
      if(shipmentRowModel?.middleNodeData?.length > 0){
        for (let rowIndex = 0; rowIndex < shipmentRowModel.middleNodeData.length; rowIndex++){
          let inSideHeight = highCalResult + (rowIndex * hightRef);
          const normalizeClassName = 'ship-id_' + shipmentRowModel.middleNodeData[rowIndex].shipmentId
            + '_loc-id_' + shipmentRowModel.middleNodeData[rowIndex].locationId + '_flowbox';
          // Add Node
          try {
            forEditor.addNode(normalizeClassName, 1, 1, horizonCalResult, inSideHeight,
              normalizeClassName,
              shipmentRowModel.middleNodeData[rowIndex],
              middleTemplate, false);
          }
          catch (error)
          {
            console.error(error);
          }
          if(globeRow < rowIndex) globeRow++;
        }
        col++;
      }

      horizonCalResult = 160 * col;

      // Add Dest Nodes
      for (let rowIndex = 0; rowIndex < shipmentRowModel.destNodeData.length; rowIndex++){
        let inSideHeight = highCalResult + (rowIndex * hightRef);
        const normalizeClassName = 'ship-id_' + shipmentRowModel.destNodeData[rowIndex].shipmentId
          +'_loc-id_' + shipmentRowModel.destNodeData[rowIndex].locationId + '_flowbox';
        // Add Node
        try {
          forEditor.addNode(normalizeClassName, 1, 0, horizonCalResult, inSideHeight,
            normalizeClassName,
            shipmentRowModel.destNodeData[rowIndex],
            destTemplate, false);
        }
        catch (error)
        {
          console.error(error);
        }
        if(globeRow < rowIndex) globeRow++;
      }
      globeRow++
      // Add Connection
      for (const matchRoute of shipmentRowModel.matchRoute) {
        const oriNodeId = forEditor.getNodesFromName(matchRoute.oriClassName)[0];
        const destNodeId = forEditor.getNodesFromName(matchRoute.destClassName)[0];
        forEditor.addConnection(oriNodeId, destNodeId, "output_1", "input_1");
        if(matchRoute.isCompleted)
        {
          console.error("Complete Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "green");
        }
        if(matchRoute.isPending)
        {
          console.log("Pending Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "gray");
        }
        if(matchRoute.isLate)
        {
          console.log("Late Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "red");
        }
        if (matchRoute.isDefect) {
          console.log("Defect Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "red");
        }
      }
    }
  }

  // This Function is deprecated
  prepDiagramData(){
    const prepShipment = this.mappedShipments;
    let shipmentRowList:Array<DrawRouteRowModel> = [];
    for (let i = 0; i < prepShipment.length; i++) {
      let prepShipmentRow = new DrawRouteRowModel();

      for (let j = 0; j < prepShipment[i].shipmentLocations.length; j++) {

        let prepOriNodeData:OriNodeDataModel;
        let prepMiddleNodeData:MiddleNodeDataModel;
        let prepDestNodeData:DestNodeDataModel;


        // Ori > 0 , Dest > 0 ถือว่า Shipment สามารถแสดงผลได้
        if(prepShipment[i]?.shipmentLocations[j]?.originLocationId > 0 && prepShipment[i]?.shipmentLocations[j]?.destinationLocationId > 0)
        {
          // const testVar = prepShipmentRow?.oriNodeData?.find(a =>
          //   a.name == prepShipment[i].shipmentLocations[j].originShortAddress);

          // หาจุดเพิ่มของ Shipment นี้
          if(prepShipment[i]?.shipmentLocations[j]?.originSequence === 1 &&
            _.isEmpty(prepShipmentRow?.oriNodeData?.find(a =>
              a.name == prepShipment[i]?.shipmentLocations[j]?.originShortAddress)))
          {
            prepOriNodeData = new OriNodeDataModel()
            prepOriNodeData.name = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            prepOriNodeData.factory = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            prepOriNodeData.shipmentId = prepShipment[i]?.shipmentId;
            prepOriNodeData.locationId = prepShipment[i]?.shipmentLocations[j]?.originLocationId;

            // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
            prepOriNodeData.totalRoute = prepShipment[i].totalRoute;
            prepOriNodeData.completedRoute = prepShipment[i].completedRoute;
            prepOriNodeData.lateRoute = prepShipment[i].lateRoute;
            prepOriNodeData.pendingRoute = prepShipment[i].pendingRoute;
            //prepOriNodeData.defectRoute = 0;
          }

          // หาจุด Middle บนเส้นทาง
          if(prepShipment[i]?.shipmentLocations[j]?.originSequence != 1 &&
            !_.isEmpty(prepShipment[i].shipmentLocations.find(a => a.destinationSequence == prepShipment[i]?.shipmentLocations[j]?.originSequence)) &&
            !_.isEmpty(prepShipment[i].shipmentLocations.find(a => a.originSequence == prepShipment[i]?.shipmentLocations[j]?.destinationSequence))){

            prepMiddleNodeData = new MiddleNodeDataModel();
            prepMiddleNodeData.name = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            prepMiddleNodeData.factory = prepShipment[i]?.shipmentLocations[j]?.originShortAddress;
            prepMiddleNodeData.shipmentId = prepShipment[i]?.shipmentId;
            prepMiddleNodeData.locationId = prepShipment[i]?.shipmentLocations[j]?.destinationLocationId;

            // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
            prepMiddleNodeData.totalRoute = prepShipment[i].totalRoute;
            prepMiddleNodeData.completedRoute = prepShipment[i].completedRoute;
            prepMiddleNodeData.lateRoute = prepShipment[i].lateRoute;
            prepMiddleNodeData.pendingRoute = prepShipment[i].pendingRoute;
          }
          // อื่นๆ คือว่าเป็น Des สุดท้าย เช็คก่อนว่า เป็น location เดียวกันหรือปล่าว ถ้าเป็นที่เดียวกันจะใช้ Node เดียวกัน
          else if(_.isEmpty(prepShipmentRow?.destNodeData?.find(a =>
            a.name == prepShipment[i]?.shipmentLocations[j]?.destinationShortAddress))
          ){
            prepDestNodeData = new DestNodeDataModel();
            prepDestNodeData.name = prepShipment[i]?.shipmentLocations[j]?.destinationShortAddress;
            prepDestNodeData.customer_name = prepShipment[i]?.shipmentLocations[j]?.destinationShortAddress;
            prepDestNodeData.shipmentId = prepShipment[i]?.shipmentId;
            prepDestNodeData.locationId = prepShipment[i]?.shipmentLocations[j]?.destinationLocationId;

            // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
            //prepDestNodeData. = prepShipment[i].shipmentLocations.length;
            prepDestNodeData.defectRoute = 0;
          }
        }

        // Ori > 0 , Dest = 0 ถือว่าเป็น Shipment ที่ไม่สมบูรณ์
        // if(prepShipmentRowKey.originLocationId > 0 && prepShipmentRowKey.destinationLocationId === 0)
        // {
        //   prepDestNodeData = new DestNodeDataModel();
        //   prepDestNodeData.name = prepShipmentRowKey.originShortAddress;
        //   prepDestNodeData.factory = prepShipmentRowKey.originShortAddress;
        //
        //   // ชั่วคร่าว ต้องเอามาหา defect route ก่อน
        //   prepDestNodeData.successRoute = prepShipment[i].shipmentLocations.length;
        //   prepDestNodeData.defectRoute = 0;
        // }

        if(prepOriNodeData){
          if(prepShipmentRow.oriNodeData) {
            prepShipmentRow.oriNodeData.push(prepOriNodeData);
          }else{
            prepShipmentRow.oriNodeData = []
            prepShipmentRow.oriNodeData.push(prepOriNodeData)
          }
        }
        if(prepMiddleNodeData){
          if(prepShipmentRow.middleNodeData) {
            prepShipmentRow.middleNodeData.push(prepMiddleNodeData)
          }else{
            prepShipmentRow.middleNodeData = [];
            prepShipmentRow.middleNodeData.push(prepMiddleNodeData)
          }
        }
        if(prepDestNodeData){
          if(prepShipmentRow.destNodeData) {
            prepShipmentRow.destNodeData.push(prepDestNodeData);
          }else{
            prepShipmentRow.destNodeData = [];
            prepShipmentRow.destNodeData.push(prepDestNodeData)
          }
        }

        // Connection Prep
        let matchRoute = new MatchRouteModel();
        matchRoute.oriClassName = 'ship-id_' + prepShipment[i]?.shipmentId + '_loc-id_' + _.snakeCase(prepShipment[i]?.shipmentLocations[j].originLocationId) + '_flowbox';
        matchRoute.destClassName = 'ship-id_' + prepShipment[i]?.shipmentId + '_loc-id_' + _.snakeCase(prepShipment[i]?.shipmentLocations[j].destinationLocationId) + '_flowbox';
        // Will be checked defect.
        if(prepShipment[i]?.pendingRoute > 0)
        {
          matchRoute.isPending = true;
        }
        if(prepShipment[i]?.lateRoute > 0)
        {
          matchRoute.isLate = true;
        }
        if(prepShipment[i]?.completedRoute > 0)
        {
          matchRoute.isCompleted = true;
        }


        if(prepShipmentRow.matchRoute){
          if(_.isEmpty(prepShipmentRow.matchRoute.find(a=>a.oriClassName === matchRoute.oriClassName && a.destClassName === matchRoute.destClassName))) {
            prepShipmentRow.matchRoute.push(matchRoute);
          }
        }else{
          prepShipmentRow.matchRoute = [matchRoute];
        }
      }
      shipmentRowList.push(prepShipmentRow);
    }
    this.generateDiagram2(shipmentRowList);
  }

  // this for example
  generateDiagram(shipmentModel: any){

    //#region Init Drawflow
    this.editor = new Drawflow(this.drawflowElement.nativeElement);
    this.editor.start();
    this.editor.reroute = false;
    this.editor.editor_mode = "fixed";
    this.drawflowRegisterEvent(this.editor);

    //#endregion init Drawflow

    //#region Init variables
    // GetSimple Data
    const itemList = bookingData;
    // Group by ชื่อโรงงาน
    let originFactoryItems = _.groupBy(itemList, (item) => {
      return [item['factory']]
    });
    // Group by Destination
    let desticationLvl1 = _.groupBy(itemList, (item) => {
      return [item['destination_name']]
    });
    // Init DefectRoute Match
    let defectRouteMatcher: MatchDefectRoute[] = [];
    //#endregion

    //#region Prep Origin Box
    let r = 0; // row index
    let c = 1; // column index
    const oriTemplate = this.getFactoryOriginTemplate();
    const destTemplate = this.getFactoryDestTemplate();
    let forEditor = this.editor;
    _.forEach(originFactoryItems, function (item) {
      // ความสูงละหว่าง Box
      let highCalResult = 180 * r;
      const firstItem = _.head(item);

      let defectRoute: number = 0;
      let successRoute: number = item.length;
      let status = '<span style="color:#24a56d">ราบรื่น</style>'
      let defectBookingNo: string[] = [];
      // หาจำนวนเส้นทางที่มีปัญหา
      _.forEach(item, function (routeItem) {
        if (routeItem.deliver_status === deliver_status.late) {
          defectRouteMatcher.push({
            oriName: _.snakeCase(firstItem.factory),
            destName: _.snakeCase(routeItem.destination_name)
          });
          status = '<span style="color:red">มีปัญหา</style>'
          defectBookingNo.push(routeItem.booking_no);
          successRoute--;
          defectRoute++;
        }
      });
      // สร้าง Obj สำหรับ Add node
      const nodeData: OriNodeDataModel = Object.assign(new OriNodeDataModel(), {
        name: firstItem.factory,
        factory: firstItem.factory,
        status,
        successRoute,
        defectRoute,
        defectBookingNo
      });
      // ClassName ที่จะนำมาใช้ สร้าง connection
      const normalizeClassName = _.snakeCase(firstItem.factory);
      // Add Node
      try {
        forEditor.addNode(normalizeClassName, 0, 1, 30, highCalResult,
          normalizeClassName + '_flowbox',
          nodeData,
          oriTemplate, false);
      }
      catch (error)
      {
        console.error(error);
      }
      r++;
    });
    //#endregion

    //#region Prep lvl 1 Dest
    c++;
    r = 0;

    _.forEach(desticationLvl1, function (item) {
      // ความสูงละหว่าง Box
      let highCalResult = 180 * r;
      let horizonCalResult = 160 * c;
      const firstItem = _.head(item);

      let defectRoute: number = 0;
      let successRoute: number = item.length;
      let status = "ราบรื่น"
      let defectBookingNo: string[] = [];
      // หาจำนวนเส้นทางที่มีปัญหา
      _.forEach(item, function (routeItem) {
        if (routeItem.deliver_status === deliver_status.late) {
          status = 'มีปัญหา'
          defectBookingNo.push(routeItem.booking_no);
          successRoute--;
          defectRoute++;
        }
      });
      // สร้าง Obj สำหรับ Add node
      const nodeData: DestNodeDataModel = Object.assign(new DestNodeDataModel(), {
        name: firstItem.destination_name,
        destination_name: firstItem.destination_name,
        customer_name: firstItem.customer_name,
        destination_location: firstItem.destination_location,
        defectRoute,
        defectBookingNo
      });
      // ClassName ที่จะนำมาใช้ สร้าง connection
      const normalizeClassName = _.snakeCase(firstItem.destination_name);
      // Add Node
      forEditor.addNode(normalizeClassName, 1, 0, horizonCalResult, highCalResult,
        normalizeClassName + '_flowbox',
        nodeData,
        destTemplate, false);
      r++;
    });
    //#endregion

    //#region create connection
    _.forEach(originFactoryItems, function (oriTtem) {
      const oriNodeId = forEditor.getNodesFromName(_.snakeCase(_.head(oriTtem).factory))[0];
      _.forEach(oriTtem, function (destItem) {
        const destNodeId = forEditor.getNodesFromName(_.snakeCase(destItem.destination_name))[0];
        forEditor.addConnection(oriNodeId, destNodeId, "output_1", "input_1");
        const defectedRoute = _.find(defectRouteMatcher, function (defectItem) {
          return defectItem.oriName === _.snakeCase(_.head(oriTtem).factory) && _.snakeCase(destItem.destination_name);
        });
        if (defectedRoute) {
          console.log("Defect Found");
          $('.connection.node_in_node-' + destNodeId + '.node_out_node-' + oriNodeId + ' > .main-path').css("stroke", "red");
        }
      });
    });
    //#endregion
  }

  // This Function is deprecated
  getFactoryOriginTemplate(): string {
    return `
    <div class="no-v-align">
      <a href="#">
        <h5><i class="fas fa-warehouse"></i> <span df-factory></span></h5>
        <span>Shipment ID: </span><span df-shipmentId></span><br/>
        <span>สถานะ: </span><span df-status></span><br/>
        <span>เส้นทางทั้งหมด: </span><span df-totalRoute></span><br/>
        <span>เส้นทางที่สำเร็จ: </span><span df-completedRoute></span><br/>
        <span>เส้นทางที่ล้าช้า: </span><span df-lateRoute></span><br/>
        <span>เส้นทางที่รอเริ่มงาน: </span><span df-pendingRoute></span><br/>
        <span>มีปัญหา: </span><span df-defectRoute></span><br/>
        <input type="hidden" df-name />
      </a>
    </div>
    `
  }

  // This Function is deprecated
  getFactoryDestTemplate(): string {
    return `
    <div class="no-v-align">
      <a href="#">
        <h5><i class="fas fa-map-marker-alt"></i> <span df-customer_name></span></h5>
        <span>จังหวัด: </span><span df-destination_location></span><br/>
        <span>มีปัญหา(late): </span><span df-defectRoute></span><br/>
        <input type="hidden" df-name />
      </a>
    </div>
    `
  }

  // This Function is deprecated
  getNodeToolTipTemplate(nodeData: any): string {
    let str: string;
    // Template for FactoryNodeDataModel
    if (nodeData.typeName === OriNodeDataModel.name && nodeData.defectBookingNo !== undefined && nodeData.defectBookingNo.length !== 0) {
      str = `
      <span>Booking ที่มีปัญหา</span><br/>
    `;
      nodeData.defectBookingNo.forEach(value => {
        str += '<span>' + value + '</span><br/>'
      })
    }
    // Template for DestNodeData
    else if (nodeData.typeName === DestNodeDataModel.name && nodeData.defectBookingNo !== undefined && nodeData.defectBookingNo.length !== 0) {
      str = `
      <span>Booking ที่มีปัญหา</span><br/>
    `;
      nodeData.defectBookingNo.forEach(value => {
        str += '<span>' + value + '</span><br/>'
      })
    }
    str += '</div>';
    return str;
  }

  // This Function is deprecated
  // Prep Node creation event
  drawflowRegisterEvent(editor: any): void {
    let currentPage = this;
    editor.on("nodeCreated", function (id) {
      const node = editor.getNodeFromId(id);
      const data = node.data;
      const content = document.querySelector("#node-" + id);

      // This function add parameter to the template
      Object.entries(data).forEach(function (key, value) {
        if (typeof key[1] === "object") {
          insertObjectkeys(null, key[0], key[0]);
        } else {
          let elems = content.querySelectorAll("[df-" + key[0] + "]");
          for (let i = 0; i < elems.length; i++) {
            elems[i].innerHTML = key[1] as string;
          }
        }
      });
      // Add Tip on node
      const tooltipNodeTemplate = currentPage.getNodeToolTipTemplate(data);
      if (tooltipNodeTemplate !== undefined) {
        Tippy("#node-" + id, {content: tooltipNodeTemplate, allowHTML: true, followCursor: true});
      }

      function insertObjectkeys(object, name, completname) {
        if (object === null) {
          var object = data[name];
        } else {
          var object = object[name];
        }
        Object.entries(object).forEach(function (key, value) {
          if (typeof key[1] === "object") {
            insertObjectkeys(object, key[0], name + "-" + key[0]);
          } else {
            let elems = content.querySelectorAll(
              "[df-" + completname + "-" + key[0] + "]"
            );
            for (let i = 0; i < elems.length; i++) {
              elems[i].innerHTML = key[1] as string;
            }
          }
        });
      }
    });

    editor.on("connectionCreated", function (info) {

      // console.log(info);
      // const classname = info.output_class + " " + info.input_class;
      // let element = document.getElementsByClassName(classname);
      // if(element.classname == "input_4 input_1")
      // {
      //   element[0].style = "stroke: red !important";
      // }
    });
  }

  // RedLine Function, This Function is deprecated
  changeColorForDefectRoute(defectRoute: number[], oriRouteId, oriNode, destNode) {
    // "connection node_in_node-5 node_out_node-1"
    defectRoute.forEach(defRoute => {
      if (defRoute === oriRouteId) {
        $('.connection.node_in_node-' + destNode + '.node_out_node-' + oriNode + ' > .main-path').css("stroke", "red");
      }
    });
  }

  // This Function is deprecated
  //<span df-destination_name></span><br/>
  getOriginTemplate(): string {
    return `
    <div class="no-v-align">
      <a href="#">
        <h5>Origin</h5>
        <span df-name></span>
        <span df-address></span>
        <input type="hidden" df-name />
      </a>
    </div>
    `
  }
  // This Function is deprecated
  getDestinationTemplate(): string {
    return `
      <div class="no-v-align">
        <a href="#">
          <h5>Destination</h5>
          <span df-name></span>
          <span df-address></span>
          <input type="hidden" df-name />
        </a>
      </div>
      `
  }
  // This Function is deprecated
  generateOldData(editor: any) {
    let htmlOri = this.getOriginTemplate();
    let htmlDest = this.getDestinationTemplate();
    /// Simple Data
    // Origin
    let dataOri = [
      {id: 10, name: "ori1", no: "111220-1", address: "ชลบุรี", dest: [13, 14]},
      {id: 11, name: "ori2", no: "111220-2", address: "ปทุม", dest: [13, 20]},
      {id: 12, name: "ori3", no: "111220-3", address: "กทม", dest: [13, 14, 20]},
    ];
    // middle
    let dataDest2 = [
      {id: 13, name: "mtest", address: "อยุธยา", dest: [32, 35]},
      {id: 14, name: "mtest2", address: "สิงห์บุรี", dest: [32, 38], defectRoute: [10, 12]},
      {id: 20, name: "mtest3", address: "พิษณุโลก", dest: [38]},
    ];
    // Dest
    let dataDest3 = [
      {id: 32, name: "dtest", address: "เชียงใหม่", defectRoute: [14]},
      {id: 35, name: "dtest2", address: "เชียงราย"},
      {id: 38, name: "dtest3", address: "แม่ห้องสอน"},
    ];
    /// End Simple Data

    /// Arrange item position
    // Add node usage
    // editor.addNode(<Name>,<input>,<output>,<pos_x>,<pox_y>,<class_name>,<data>,<html_Template>);
    //#region Prep Origin Box
    let i = 0;
    let c = 1;
    dataOri.forEach((element) => {
      let highCalResult = 130 * i;
      editor.addNode(
        element.name,
        0,
        1,
        30,
        highCalResult,
        element.name,
        element,
        htmlOri
      );
      i++;
    });
    c++;
    //#endregion

    //#region Prep Middle Box
    i = 0;
    dataDest2.forEach((element) => {
      let highCalResult = 130 * i;
      let horizonCalResult = 160 * c;
      editor.addNode(
        element.name,
        1,
        1,
        horizonCalResult,
        highCalResult,
        element.name,
        element,
        htmlDest
      );
      i++;
    });
    c++;
    //#endregion

    //#region Prep Dest 1 gen
    i = 0;
    dataDest3.forEach((element) => {
      let highCalResult = 130 * i;
      let horizonCalResult = 200 * c;
      editor.addNode(
        element.name,
        1,
        0,
        horizonCalResult,
        highCalResult,
        element.name,
        element,
        htmlDest
      );
      i++;
    });
    //#endregion

    // Gen Connection
    //#region Gen Connection Lvl1
    dataOri.forEach(ori => {
      const oriNode = editor.getNodesFromName(ori.name)[0];
      ori.dest.forEach(dest => {
        const destItem = dataDest2.find(a => a.id == dest);
        const destNode = editor.getNodesFromName(destItem.name)[0];
        editor.addConnection(oriNode, destNode, "output_1", "input_1")
        if (destItem.defectRoute) {
          // Check Defect Route

          this.changeColorForDefectRoute(destItem.defectRoute, ori.id, oriNode, destNode);
        }
      });
    });
    //#endregion

    //#region Connection Lvl2
    dataDest2.forEach(ori => {
      const oriNode = editor.getNodesFromName(ori.name)[0];
      ori.dest.forEach(dest => {
        const destItem = dataDest3.find(a => a.id == dest);
        const destNode = editor.getNodesFromName(destItem.name)[0];
        editor.addConnection(oriNode, destNode, "output_1", "input_1")
        if (destItem.defectRoute) {
          // Check Defect Route

          this.changeColorForDefectRoute(destItem.defectRoute, ori.id, oriNode, destNode);
        }
      });
    });
    //#endregion
  }
}
