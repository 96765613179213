import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, AfterViewInit {
  @ViewChild("modalConfirm", { static: true }) modalConfirm!: ModalConfirmComponent;
  modalReference!: NgbModalRef;

  @Input() modalConfig: ModalConfirmModel;

  iconClass = '';
  constructor(private modalService: NgbModal) { }
  ngAfterViewInit(): void {
    this.iconClass = `${this.modalConfig?.iconClassName}`
  }

  ngOnInit() {
    if (this.modalConfig) {
      this.iconClass = `${this.modalConfig?.iconClassName}`;
    }
  }
  open(size?: 'sm' | 'lg' | 'xl' | string): NgbModalRef {
    this.ngOnInit();
    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalConfirm, { size: size, centered: true, backdrop: 'static' });
    }
    else {
      this.modalReference = this.modalService.open(this.modalConfirm, { centered: true });
    }
    return this.modalReference;
  }


  modalSubmit(comfirm: boolean): void {
    this.modalReference.close(comfirm);
    // this.confirmEvent.emit(comfirm);
  }

}

export class ModalConfirmModel {
  title: string = 'ลบออกจากระบบ';
  description: string = 'คุณต้องการจะลบรถบรรทุกออกจากระบบใช่ หรือ ไม่';
  iconClassName: string = 'fas fa-times text-danger';
  buttonList: ButtonList[] = [
    { text: 'ยืนยัน', class: 'btn-success', comfirm: true },
    { text: 'ยกเลิก', class: 'btn-outline-success', comfirm: true },
  ];
}

export class ButtonList {
  text: string;
  class: string;
  comfirm: boolean;
}
