import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NotificationService,
  PageloadingService,
} from 'src/app/core/_Services';
import { TeamListModel } from '../../models/TeamListModel';
import { TeamService } from '../../services/team.service';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { Operation } from '@app/core/_Models/UserModel/UserPermission';

@Component({
  selector: 'app-teaminternal',
  templateUrl: './teaminternal.component.html',
  styleUrls: ['./teaminternal.component.scss'],
})
export class TeaminternalComponent implements OnInit, OnDestroy {
  teamList: TeamListModel[] = new Array();
  @ViewChild('searchTeamTxt') private searchEleRef: ElementRef;

  permission70200: Operation;
  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    // private pagloading: PageloadingService,
    private _teamService: TeamService,
    private _notiService: NotificationService
  ) {
    //this.pagloading.show();
  }

  isEdit: boolean = false;
  editTeam: TeamListModel;
  searchtxt: string;

  async ngOnInit() {
    //Team Management Permission 70200
    this.permission70200 = this._teamService.GetPermission(70000, 70200);
    await this.GetTeamList();
  }

  AfterAddTeam() {
    this.GetTeamList();
  }

  searchTeam() {
    this.searchtxt = this.searchEleRef.nativeElement.value;
  }

  async GetTeamList() {
    //this.pagloading.show();
    this._teamService
      .GetTeamListWithClient()
      .subscribe((res: TMSAPIResponse) => {
        if (!res.isSuccess) {
          //this.pagloading.hide();
          this._notiService.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          this.teamList = res.data;
          console.log(this.teamList)
          //this.pagloading.hide();
        }
      });
  }

  onRowselect(id: number) {
    //this.pagloading.show();
    const returnUrl = '/team/member/' + id;
    this.router.navigate([returnUrl]);
  }

  addteam() {
    this.isEdit = false;
  }

  editteam(item: TeamListModel) {
    this.editTeam = item;
    this.isEdit = true;
  }

  async deleteTeam(temaID: number) {
    const conf = await this._notiService.swalDeleteConfirm();
    if (conf) {
      this._teamService.DeleteTeam(temaID).subscribe((res: TMSAPIResponse) => {
        if (res.isSuccess) {
          this._notiService.toastsuccess('ลบข้อมูลเรียบร้อย', '');
          this.GetTeamList();
        } else {
          this._notiService.toasterror('ลบข้อมูลไม่สำเร็จ', res.message);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }
}
