export class AppContants {
  public static AppTile = "Hello word";
  public static Insurance_360Contact_No = '06-5764-2227';
  public static SignupContact = '065-764-2227';
  public static beadcrumb = [{ name: '', url: '/' }];
}
export const AppBookingSortingList = [
  // { value: '', text: 'ทั้งหมด' },
  { value: 'provinceOrigin', text: 'ต้นทาง', order: 'asc' },
  { value: 'provinceDest', text: 'ปลายทาง', order: 'asc' },
  { value: 'dateOrigin', text: 'วันที่ต้นทาง (น้อยไปมาก)', order: 'asc' },
  { value: 'dateOrigin', text: 'วันที่ต้นทาง (มากไปน้อย)', order: 'desc' },
  { value: 'dateDest', text: 'วันที่ปลายทาง (น้อยไปมาก)', order: 'asc' },
  { value: 'dateDest', text: 'วันที่ปลายทาง (มากไปน้อย)', order: 'desc' },
]
