<ng-template #modalchangemobilenumber let-modal>
    <div class="modal-header bg-warning text-center text-white">
        <div class="col-12 p-0">
            <span class="fal fa-question-circle fa-9x"></span>
            <button type="button" class="close pt-0 pl-0 pb-0 pr-1" style="color:white !important" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body pb-0">
        <div *ngIf="body_title" class="row mb-2">
            <div class="col-12 text-center">
                <h3 class="font-weight-bold mb-0" style="white-space: pre-wrap">
                    {{body_title}}
                </h3>
            </div>
        </div>
        <div *ngIf="body_text" class="row">
            <div class="col-12 text-center">
                <h5 *ngIf="body_text" class="text-muted" style="white-space:pre-wrap">
                {{body_text}}
                </h5>
            </div>
        </div>
        <div *ngIf="!isRequestOTP" class="row">
            <div class="form-group col-12">
                <span class="h5">เบอร์โทรเดิม</span>
                <input type="text" class="form-control" disabled [(ngModel)]="userItem.mobileNo" mask="999-999-9999">
            </div>

            <div class="form-group col-12">
                <span class="h5">เบอร์โทรใหม่</span>
                <p-inputMask type="text" styleClass="form-control" mask="999-999-9999"
                    placeholder="(___)-___-____" [unmask]=true [(ngModel)]="mobileNo" onkeyup="checkUsedMobileNo($event)" (change)="checkUsedMobileNo($event)">
                </p-inputMask>

                <!-- <input class="form-control" type="text" mask="(000)-000-0000" placeholder="เบอร์โทรศัพท์ 10 หลัก" (change)="checkUsedMobileNo()" [(ngModel)]="mobileNo"/> -->

                <div *ngIf="isUsedMobileNo === true">
                    <span class="text-success">เบอร์โทรศัพท์นี้สามารถใช้งานได้</span>
                </div>
                <div *ngIf="isUsedMobileNo === false">
                    <span class="text-danger">{{mobileMessageError}}</span>
                </div>
            </div>
        </div>

        <div *ngIf="isRequestOTP" class="row mt-5">
            <div class="form-group col-12">
                <span class="h5">โปรดกรอก OTP ที่ได้รับ</span>
                <input type="text" class="form-control" [(ngModel)]="OTP">
                <div *ngIf="isVerifyOTP === false">
                    <span class="text-danger">{{verifyOTPMessageError}}</span>
                </div>
                <a type="button" class="text-primary mt-4" (click)="changePhoneNumber()">คลิกที่นี่เพื่อส่ง OTP อีกครั้ง</a>
            </div>
        </div>

    </div>
    <div class="modal-footer justify-content-between">
        <button id="btnother" type="button" class="btn btn-outline-secondary waves-effect waves-themed font-weight-bold"
            style="width:37% !important" (click)="modal.dismiss('Cross click')">ยกเลิก</button>
        <button *ngIf="!isRequestOTP" id="btnsubmit" type="button" class="btn btn-warning waves-effect waves-themed font-weight-bold"
            style="width:58% !important" (click)="onRequestOTP()" [disabled]="!isUsedMobileNo">ขอ OTP เพื่อยืนยันเบอร์โทรใหม่</button>

            <button *ngIf="isRequestOTP" id="btnsubmit" type="button" class="btn btn-warning waves-effect waves-themed font-weight-bold"
            style="width:58% !important" (click)="btnsubmit_click()" [disabled]="!OTP">ยืนยันเปลี่ยนเบอร์โทร</button>
    </div>
</ng-template>

<app-modalsuccess #modalsuccess (btnother_event)="success_other($event)" [btnsubmit_ishide]=true></app-modalsuccess>
