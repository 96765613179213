<app-breadcrumb Title="การแจ้งเตือน"></app-breadcrumb>
<div class="row">
    <div class="col-xl-12">
        <div class="panel-content">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item"><a id="notiCarrier" class="nav-link {{tabSelected === 0?'active':''}}" data-toggle="tab"
                        href="#tab_notiCarrier" role="tab" #notiCarrier><b>แจ้งเตือนงานขนส่ง</b></a></li>
                <!-- <li class="nav-item"><a id="notiReport" class="nav-link {{tabSelected === 1?'active':''}}" data-toggle="tab"
                        href="#tab_notiReport" role="tab" #notiReport><b>แจ้งรายงานสรุป</b></a></li> -->
                <li class="nav-item"><a id="lineGroup" class="nav-link {{tabSelected === 2?'active':''}}" data-toggle="tab"
                        href="#tab_lineGroup" role="tab" #lineGroup><b>Line Group Notify</b></a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane fade show active" id="tab_notiCarrier" role="tabpanel">
                    <app-noticarrier></app-noticarrier>
                </div>
                <!-- <div class="tab-pane fade" id="tab_notiReport" role="tabpanel">
                    <app-setnotification></app-setnotification>
                </div> -->
                <div class="tab-pane fade" id="tab_lineGroup" role="tabpanel">
                    <app-linegroup></app-linegroup>
                </div>
            </div>
        </div>
    </div>
</div>
