import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { UserTrainingResponse } from '../../models/driver-detail-response.model';

@Component({
  selector: 'app-gallery-item',
  templateUrl: './gallery-item.component.html',
  styleUrls: ['./gallery-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryItemComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() userTraining: UserTrainingResponse;
  @Input() galleryId:string;


  images: any[] = [];

  imageItems: GalleryItem[];
  imgMoreCount: number = 3;

  constructor(public gallery: Gallery, private lightbox: Lightbox) { }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    // this.images = this.userTraining?.userTrainingFiles?.map(e => e.filePath) || [];
    // this.galleryImagesInit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    // this.destroyGallery();
    this.images = this.userTraining?.userTrainingFiles?.map(e => e.filePath) || [];
    this.galleryImagesInit();
  }

  ngOnInit(): void {
  }

  lightboxLoad() {
    const galleryRef = this.gallery.ref(this.galleryId);
    galleryRef.load(this.imageItems);
    console.log('lightboxLoad', this.imageItems);

    // this.gallery.ref(this.galleryId).load(this.imageItems);
  }
  openInFullScreen(index: number) {
    console.log(index);

    this.lightbox.open(index, this.galleryId, {
      // panelClass: 'fullscreen'
    });
  }

  galleryImagesInit(): void {
    console.log('galleryImagesInit this.images', this.images);

    this.imageItems = this.images.map(item => new ImageItem({ src: item, thumb: item }));
    // console.log('this.galleryImages', this.galleryImages);

    console.log('galleryImagesInit this.imageItems', this.imageItems);

    this.lightboxLoad()
  }
}
