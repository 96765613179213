import { Injectable } from '@angular/core';
import { MasterAPIPath } from '@app/core/_Constants/apiConstants';
import { ProvinceDto } from '@app/core/_Models/MasterModel/ProvinceModel';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { HttpService } from '@app/core/_Services';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  provinceListOnChange: BehaviorSubject<ProvinceDto[]>;
  constructor(private httpService: HttpService) {
    this.provinceListOnChange = new BehaviorSubject(new Array<ProvinceDto>());
  }

  getProvinceList(): Promise<ProvinceDto[]> {
    return new Promise((resolve, reject) => {
      this.httpService.get(MasterAPIPath.GetProvinceList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: TMSAPIResponse) => {
        this.provinceListOnChange.next(res.data)
        resolve(res.data);
      })
    })

  }
}