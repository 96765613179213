import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppContants } from 'src/app/core/_Constants/appContants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() Title: string;
  @Input() Uri : string;
  @Input() Item:any;
  @Input() IntroAction:boolean = false;
  @Output() introAction:any = new EventEmitter();
  menulist = [
    { name: 'หน้าหลัก', url: '' }
  ];

  description = {
    title: 'Material',
    detail: "",
  };
  constructor() {}

  ngOnInit(): void {
    
    this.menulist = [
      { name: 'หน้าหลัก', url: '' }
    ];
    Array.prototype.push.apply(this.menulist,AppContants.beadcrumb); 
  }
  
  clickIntro(): void {
    this.introAction.emit();
  }
}
