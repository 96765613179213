
export class UpdateClientUserDto {
    public ClientID:number;
    public TSTS360_UserID:number;
    public TST360_CompanyID:number;
    public BasicUserClientDto :BaseClientUserDto;
    public IdentityUserClientDto :IdentityUserDto;
}

export class BaseClientUserDto {
    public ClientID :number;
    public TST360_CompanyID :number;
    public TST360_UserID :number;
    public  Username:string;
    public  PhoneNumber :string;
    public  Email:string;
    public  FirstName:string;
    public  LastName:string;
    public  PhotoURL:string;
    public  IDcard :string;
    public  TaxId:string;
    public  BirthDate :Date;
}

export class IdentityUserDto 
{
    public  TSTUserId :string;
    public  UserName :string;
    public  PhoneNumber :string;
    public  Email :string;
    public  ClientIP :string;
}