import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { User } from "../_Models/UserModel/User";
import { AuthenticationService } from "../_Services";
// declare function TriggerFbMsg(): any;
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html'
})

export class AppLayoutComponent implements OnDestroy, AfterViewInit {
  overlayMenuOpenSubscription: Subscription;
  currentUser: User;
  constructor(
    private authenticationService: AuthenticationService,
    private detech: ChangeDetectorRef,
    private facebookService: FacebookService
  ) {

  }
  ngAfterViewInit(): void {
    try {
      this.authenticationService.currentUser.subscribe((x) => {
        this.currentUser = x;
        if (x) {
          this.authenticationService.FilesServer360TechXCookiieCreate();
          this.authenticationService.FilesServer360TruckCookiieCreate();
          this.authenticationService
            .getProfilePhotoByServer(x.tstS360_UserID)
            .subscribe((img) => {
              const date = new Date();
              this.authenticationService.photoAsync.next(img.photo + `?time=${date.getTime()}`);
            });
            let clientModule = this.authenticationService.currentUserValue?.clientModules.find(e => e.amo_id == 110010);
           console.log("clientModule pkg_id",clientModule)
            if(clientModule?.pkg_id < 30){
              this.initFacebookService();
              // TriggerFbMsg();
            }
          // this.analytic.PushUserInfo(x,x.phoneNumber);

        }
        this.detech.detectChanges();
      });
    }catch{

    }
  }

  ngOnDestroy(): void {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

  }
  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }
}
