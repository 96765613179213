<div class="form-steps-container">
  <ng-container *ngFor="let step of steps | async; let i = index;">
    <div class="d-flex flex-column text-center">
      <div (click)="onStepClick(step)"
      [ngClass]="{ 'step-complete': step.isComplete, 'step-incomplete': !step.isComplete, 'step-current': (currentStep | async)?.stepIndex === step.stepIndex }"
      class="step-bubble ml-3">
      <span *ngIf="step.isComplete"><i class="fas fa-check"></i></span>
    </div>
    <div class="font-weight-bold text-black fs-md mt-1">{{step.text}}</div>
    </div>
      <div *ngIf="i < (steps | async)?.length - 1" class="step-divider"></div>
    </ng-container>
</div>
