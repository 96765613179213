<button class="btn btn-success waves-effect waves-themed btn-block"
    (click)="onOpenModal()">ดูประวัติจ่ายเงิน/เบิกเงิน</button>

<div class="modal fade" id="modalPaymentHistory" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title fw-500">
                    ประวัติการจ่ายเงิน
                </h4>
                <button type="button" class="btn btn-sm-custom btn-outline-danger waves-effect waves-themed"
                    aria-hidden="true" data-dismiss="modal" aria-label="Close">
                    <i class="fal fa-times"></i>
                </button>
            </div>

            <span class="row">
                <span class="col-12 height-0">
                    <div class="border border-left-0 border-right-0 border-top-0 m-3"></div>
                </span>
            </span>

            <div class="modal-body row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-4">
                            <div class="help-block text-muted">สรุปประจำเดือน</div>
                            <ng-select style="width: calc(100%)" [items]="month" bindLabel="name" bindValue="id"
                                [(ngModel)]="monthSelected" (change)="onselectedMonth()" clearable>
                            </ng-select>
                        </div>
                        <div class="col-4">
                            <div class="help-block text-muted">ค่าจ้างทั้งหมด</div>
                            <div
                                class="border-custom rounded font-custom-div p-2 text-center text-amount-custom fw-700">
                                <span *ngIf="userStatementMonth.monthAmount">{{userStatementMonth.monthAmount|number:'1.0'}}
                                    บาท</span>
                                <span *ngIf="!userStatementMonth.monthAmount">-</span>
                        </div>
                        <!-- <div class="col-4">
                            <div class="help-block text-muted">ค่าเที่ยวเดือนนี้</div>
                            <div class="border-custom rounded font-custom-div p-2 text-center text-amount-custom fw-700">
                                {{9000|number:'1.0'}} บาท</div>
                        </div> -->
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <div class="row">
                        <div class="col-4">
                            <div class="help-block text-muted">Order No.</div>
                        </div>
                        <div class="col-4">
                            <div class="help-block text-muted">หัวข้อการชำระ</div>
                        </div>
                        <div class="col-4">
                            <div class="help-block text-muted">ยอดเงิน</div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div *ngFor="let userStatementDate of userStatementDates;last as islast"
                        class="row">

                        <div class="col-12">
                            <label class="fw-900">{{userStatementDate.statementDate|thaidate:"short"}}</label>
                        </div>

                        <div class="col-12">
                            <div *ngFor="let userStatementDto of userStatementDate.userStatementDtos" class="row">
                                <div class="col-4">
                                    <label>{{userStatementDto.statementNo}}</label>
                                </div>
                                <div class="col-4 text-center">
                                    <label class="border-custom font-custom-div p-1"
                                        style="border-radius:15px;width: 5rem;">{{userStatementDto.m_StatementType_Name}}</label>
                                </div>
                                <div class="col-4 text-right">
                                    <label class="fw-700"
                                        [ngClass]="{'text-success':userStatementDto.amount >=0,'text-danger':userStatementDto.amount <0}">
                                        <span *ngIf="userStatementDto.amount >= 0">+</span>
                                        <span *ngIf="userStatementDto.amount < 0"></span>
                                        {{userStatementDto.amount|number:'1.2'}} บาท
                                    </label>
                                </div>
                            </div>
                        </div>

                        <span *ngIf="!islast" class="col-12 height-0 mb-3">
                            <div class="border border-left-0 border-right-0 border-top-0"></div>
                        </span>

                    </div>
                </div>
            </div>


        </div>
    </div>
</div>