
<div class="row" *ngIf="permission90100.canRead">
    <div class="col-12">
        <h5><i class="fas fa-cogs"></i> ตั้งค่าข้อมูลสำหรับการสร้างสินค้า</h5>
    </div>
    <div class="col-md-10">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-2">
                        1.ประเภทการระบุหน่วยสินค้า
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-auto col-6" *ngFor="let Unit of UnitType">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="Unit{{Unit.id}}" (change)="UnitTypeChange(Unit)">
                                            <label class="custom-control-label" for="Unit{{Unit.id}}">{{Unit.name}}</label>
                                        </div>

                            </div>
                        </div>
                    </div> -->
                    <div class="col-4 col-md-auto mt-2 mt-md-0 text-right"> เลือกค่าเริ่มต้น  </div>
                    <div class="col-8 col-md-3 mt-1  mt-md-0">
                        <ng-select [(ngModel)]="productsetting?.data.handlingprincipleId">
                            <ng-option *ngFor="let item of Principle?.data" [value]="item.id">{{item.handlingPrincipleName}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12 mb-2">
                        2. ประเภทหน่วยสินค้า
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-auto col-6" *ngFor="let Package of PackageType">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="Package{{Package.id}}" (change)="PackageTypeChange(Package)">
                                            <label class="custom-control-label" for="Package{{Package.id}}">{{Package.name}}</label>
                                        </div>

                            </div>
                        </div>
                    </div> -->
                    <div class="col-4 col-md-auto mt-2 mt-md-0 text-right"> เลือกค่าเริ่มต้น  </div>
                    <div class="col-8 col-md-3 mt-1  mt-md-0">
                        <ng-select [(ngModel)]="productsetting?.data.packagingunitId">
                            <ng-option *ngFor="let item of Packaging?.data" [value]="item.id">{{item.packagingUnitName}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12 mb-2">
                        3. ประเภทหน่วยยก
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-auto col-6" *ngFor="let LoadUnit of LoadUnitType">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="LoadUnit{{LoadUnit.id}}" (change)="LoadUnitTypeChange(LoadUnit)">
                                            <label class="custom-control-label" for="LoadUnit{{LoadUnit.id}}">{{LoadUnit.name}}</label>
                                        </div>

                            </div>
                        </div>
                    </div> -->
                    <div class="col-4 col-md-auto mt-2 mt-md-0 text-right"> เลือกค่าเริ่มต้น  </div>
                    <div class="col-8 col-md-3 mt-1  mt-md-0">
                        <ng-select [(ngModel)]="productsetting?.data.handlingUnit">
                            <ng-option *ngFor="let item of Handling?.data" [value]="item.id">{{item.handlingUnitName}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12 mb-2">
                        4. หน่วยน้ำหนัก
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-auto col-6" *ngFor="let Weigth of WeigthUnit">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="Weigth{{Weigth.id}}" (change)="WeigthUnitChange(Weigth)">
                                            <label class="custom-control-label" for="Weigth{{Weigth.id}}">{{Weigth.name}}</label>
                                        </div>

                            </div>

                        </div>
                    </div> -->
                    <div class="col-4 col-md-auto mt-2 mt-md-0 text-right"> เลือกค่าเริ่มต้น</div>
                    <div class="col-8 col-md-3 mt-1  mt-md-0">
                        <ng-select [(ngModel)]="productsetting?.data.weightUnit">
                            <ng-option *ngFor="let item of this.weight?.data" [value]="item.id">{{item.weightUnitName}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <hr>

                <div class="row justify-content-center mt-3">
                    <div class="col-auto">
                        <button *ngIf="permission90100.canEdit" class="btn btn-success" (click)="Onsave()" [disabled]="OnSaveing"><span *ngIf="OnSaveing" class="spinner-grow spinner-grow-sm"></span> บันทึกการเปลี่ยนแปลง </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="row" *ngIf="!permission90100.canRead">
    <div class="col-12">
        <h5><i class="fas fa-cogs"></i> Access Denied</h5>
    </div>
</div>
