import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SchedulerService } from '../../scheduler.service';
declare function openModal(modalID): any;
declare function closeModal(modalID): any;

@Component({
  selector: 'app-modal-add-order-plan',
  templateUrl: './modal-add-order-plan.component.html',
  styleUrls: ['./modal-add-order-plan.component.scss']
})
export class ModalAddOrderPlanComponent implements OnInit {


 OrderForm: FormGroup;
 @Output() saved: EventEmitter<any> = new EventEmitter();

 locations = []
  constructor(private fb: FormBuilder,
    private schedulerService:SchedulerService) { }

  ngOnInit(): void {
    this.getLoc();
    var today = new Date();
    this.OrderForm = new FormGroup({
      order_number :  new FormControl('81M1B00911'),
      remark :  new FormControl('ทดสอบบบบบบบบ'),
      start_slot :  new FormControl(17),
      end_slot :  new FormControl(22),
      origin_location_code :  new FormControl('B-BM'),
      destination_location_code :  new FormControl('N084'),
      delivery_date :  new FormControl('2022-05-'+(today.getDate()+1).toString()),
      destination_requirement_slot :  new FormControl([20,21,22,23]),
      order_items :  this.fb.array([]),
    })
  }

  async getLoc(){
    // this.locations = await this.schedulerService.getLocations();
    // console.log(this.locations)
  }
  createOrderItem(){
    return this.fb.group({
      material_code: [''],
      qty: [0],
      qty_unit: ['CBM'],
      wieght: [0]
    });
  }

  addOrderItem(){
    openModal('#planOrderItem')
  }

  AddItem(event){
    const control = <FormArray>this.OrderForm.get('order_items') as FormArray;
    console.log(event)

    control.push(event)
  }

  getItem(){
    const control = <FormArray>this.OrderForm.get('order_items') as FormArray;
    // console.log(control.value)
     return control.value
  }

  OnsaveOrder(){
    console.log(this.OrderForm)
    console.log( JSON.stringify(this.OrderForm.value))
   
  //   this.schedulerService.AddPlan2(this.OrderForm.value)
  //   .then((t) => t.json())
  //   .then((res)=>{
  //   console.log("then",res)
  //     this.saved.emit(res)
  //     closeModal('#planOrder')
  // }).catch((error) => {
  //   console.error('Error:', error);
  // });

  this.schedulerService.AddPlan3(this.OrderForm.value).subscribe((res)=>{
    console.log("then",res)
      this.saved.emit(res)
      closeModal('#planOrder')
  })
  }
}
