import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxDropzonePreviewComponent } from 'ngx-dropzone';
import { IAlbum, Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'ngx-custom-dropzone-image-preview',
  templateUrl: './custom-dropzone-image-preview.component.html',
  styleUrls: ['./custom-dropzone-image-preview.component.scss'],
  providers: [
    {
      provide: NgxDropzonePreviewComponent,
      useExisting: CustomDropzoneImagePreviewComponent
    }
  ]
})
export class CustomDropzoneImagePreviewComponent extends NgxDropzonePreviewComponent implements OnInit {

  constructor(
    sanitizer: DomSanitizer,
    private lightbox: Lightbox) {
    super(sanitizer);
  }

  /** The file to preview. */
  @Input()
  set file(value: File) {
    this._file = value;
    if (this._file) {
      if (this._file.type.startsWith('image')) {
        this.renderImage();
      }
      else {
        this.imageSrc = undefined;
        if (this._file?.type.includes('pdf')) {
          this.icon = 'fa-file-pdf';
        }
        else if (this._file.type.includes('xls') || this._file.type.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          this.icon = 'fa-file-excel';
        }
        else if (this._file.type.includes('doc') || this._file.type.includes('docx')) {
          this.icon = 'fa-file-word';
        }
        else {
          this.icon = 'fa-file-alt';
        }
      }
    }

  }
  get file(): File { return this._file; }

  /** The image data source. */
  defaultImgLoading = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiByZ2IoMjQxLCAyNDIsIDI0Mykgbm9uZSByZXBlYXQgc2Nyb2xsIDAlIDAlOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjIyNHB4IiBoZWlnaHQ9IjIyNHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiPgo8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSIxNCIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2U9IiM4NWEyYjYiIHN0cm9rZS1kYXNoYXJyYXk9IjIxLjk5MTE0ODU3NTEyODU1MiAyMS45OTExNDg1NzUxMjg1NTIiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+CiAgPGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGR1cj0iMS4xNjI3OTA2OTc2NzQ0MTg0cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGtleVRpbWVzPSIwOzEiIHZhbHVlcz0iMCA1MCA1MDszNjAgNTAgNTAiPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+CjxjaXJjbGUgY3g9IjUwIiBjeT0iNTAiIHI9IjEwIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZT0iI2JiY2VkZCIgc3Ryb2tlLWRhc2hhcnJheT0iMTUuNzA3OTYzMjY3OTQ4OTY2IDE1LjcwNzk2MzI2Nzk0ODk2NiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjE1LjcwNzk2MzI2Nzk0ODk2NiIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgZHVyPSIxLjE2Mjc5MDY5NzY3NDQxODRzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIga2V5VGltZXM9IjA7MSIgdmFsdWVzPSIwIDUwIDUwOy0zNjAgNTAgNTAiPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+CjwhLS0gW2xkaW9dIGdlbmVyYXRlZCBieSBodHRwczovL2xvYWRpbmcuaW8vIC0tPjwvc3ZnPg==';
  @Input() imageSrc: any = this.sanitizer.bypassSecurityTrustUrl(this.defaultImgLoading);
  @Input() extension: string = '';
  @Input() fileUrl: string = '';
  @Input() fileName: string = '';
  imageExtensions: string[] = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
  icon: string = '';

  ngOnInit() {
    this.renderImage();
  }

  renderImage() {
    this.readFile().then(img => {
      setTimeout(() => this.imageSrc = img);
    }).catch(err => {
      if (err) {
        if (this.extension) {
          if (!this.imageExtensions.some(e => e == this.extension)) {
            if (this.extension != '') {
              this.imageSrc = undefined;
              switch (this.extension) {
                case ".pdf":
                case "pdf":
                  this.icon = 'fa-file-pdf';
                  break;
                case ".doc":
                case "doc":
                case ".docx":
                case "docx":
                  this.icon = 'fa-file-word';
                  break;
                case "xls":
                case "xlsx":
                  this.icon = 'fa-file-excel';
                  break;
                default:
                  this.icon = 'fa-file-alt';
                  break;
              }
            }
          }
        }
        else {
          this.imageSrc = undefined;
          this.icon = 'fa-file-alt';
        }
      }
    });
  }

  //OpenLightbox
  openlightbox(imgsrc: string, index: number, imgcaption?: string, imgthumb?: string) {
    let albums: Array<IAlbum> = [
      {
        src: imgsrc,
        caption: imgcaption ? imgcaption : (this.file ? (this.file.name ? this.file.name : undefined) : undefined),
        thumb: imgthumb ? imgthumb : imgsrc,
      }
    ];
    this.lightbox.open(albums, index, { showImageNumberLabel: true, alwaysShowNavOnTouchDevices: true, wrapAround: true, showZoom: true, showRotate: true });
  }

  windowopen(target?: string) {
    let fileUrl = this.fileUrl ? this.fileUrl : URL.createObjectURL(this.file);
    window.open(fileUrl, target);
  }
}
