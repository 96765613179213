
<!-- Modal -->
<div class="modal fade" id="create360BookingModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-lg">
                <div class="modal-header">
                        <h5 class="modal-title">Booking Created</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-striped table-bordered">
                                <thead>

                                    <tr>
                                        <th>
                                            BookingNo
                                        </th>
                                        <th>
                                            Orders
                                        </th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of bookingData">
                                        <td>
                                            <a (click)="goToBooking(item.id)">
                                                <u>

                                                    {{item.truckBookingNo}}
                                                </u>
                                              </a>
                                          
                                        </td>
                                        <td>
                                            <span *ngFor="let sm of item?.shipments">
                                                {{ sm.orderNumber.toString() }}
                                             </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>