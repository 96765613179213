<div class="row">
    <div class="col-12 tableFixHead">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>OrderNo</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Materials</th>
                    <th>QTY</th>
                    <th>CBM</th>
                    <th *ngIf="booked">Booking No</th>
                </tr>
            </thead>
            <tbody >
                <tr *ngFor="let item of LoadOrderData">
                    <td>
                        {{item?.order_number}}
                    </td>
                    <td>
                        {{item?.origin?.code}}
                        {{item?.origin?.name}} 
                    </td>
                    <td>
                        {{item?.destination?.code}}
                        {{item?.destination?.name}} 
                    </td>
                    <td>
                        <span *ngFor="let mat of item?.items"> {{mat?.material?.name}}</span>
                    </td>
                    <td>
                        <span *ngFor="let mat of item?.items"> {{mat?.qty}}</span>
                        
                    </td>
                    <td>
                        {{item?.totalQTY | number:'1.0-3'}}
                    </td>
                    <td *ngIf="booked">
                        {{item?.booking_no}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>