import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, NotificationService } from '../../_Services';
import { User } from '../../_Models/UserModel/User';
import { NoficationserviceService } from '../service/noficationservice.service';
import { MessagingService } from '@app/core/_Services/messaging.service';
import { FireBaseNotiModel } from '@app/core/_Models/FireBaseNotiModel';
import { ClientModule } from '@app/core/_Models/UserModel/UserPermission';
import { environment } from 'src/environments/environment';
import { UserEventService } from '@app/core/_Services/user-event.service';
import { BookingV2022Service } from '@app/modules/booking/service/2022/booking-v2022.service';
import { ResgisterPackageModel } from '@app/core/_SignUp/models/signup.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  public currentUser: User;
  countnofication: number;

  photoImage: string;

  clientModule: ClientModule;
  currentPackage: ResgisterPackageModel;
  isShowTrial: boolean = false;
  diffDays: number = 0;
  shipmentUsagePercent: number = 0;
  constructor(
    public auth: AuthenticationService,
    private router: Router,
    private NoficationService: NoficationserviceService,
    private FBMsg: MessagingService,
    private _noti: NotificationService,
    private detech: ChangeDetectorRef,
    private _userEvent: UserEventService,
    private shipmentService: BookingV2022Service,
  ) {
    this.auth.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
    this.currentPackage = this.auth?.currentUserValue?.registerPackage;
    const subscription = this.currentPackage?.subscriptionRecords?.find(e => e.registerPackageID == this.currentPackage.id && !e.refSubscriptionID);
    if (subscription) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const a = new Date();
      const b = new Date(subscription.endDate);

      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      const diffTime = Math.abs(utc2 - utc1);
      this.diffDays = Math.ceil(diffTime / _MS_PER_DAY);
      console.log('diffDays', this.diffDays);
    }
    this.isShowTrial = this.currentPackage.isPremiumTrial;
  }


  datalistNofication: any;
  datalistShipment: any;
  notiPrimary: any;
  notiShipment: any;
  noti360 = [];
  noti360Type1: any;
  noti360Type2: any;
  noti360Bubble: any;

  selecttab: boolean = true;
  pagesizePrimary: number = 15;
  pagesizeshipment: number = 15;
  pagesize360: number = 15;
  notiInAppList_1 = [];
  countnoti360 = 0;
  web360 = "https://webv3-dev.360truck.co";
  shipmentCount = 0;
  shipmentTotal = 0;
  isShowCurentShipoment = false;
  ngOnInit(): void {
    this.clientModule = this.auth.currentUserValue?.clientModules.find(e => e.amo_id == 110010);
    this.web360 = environment.web360;
    if (this.clientModule) {
      if (this.clientModule.pkg_id < 30) {
        this.isShowCurentShipoment = true
      }
      this.getShipmentCount();
    }
  }

  test() {
    // this._noti.toastsuccess("xxxxx",'vvvvvv');
    // this._noti.toastinfo("xxxxx",'vvvvvv');
    // this._noti.toastwarning("xxxxx",'vvvvvv');
    // this._noti.toasterror("xxxxx",'vvvvvv');
    this._noti.firebaseMessage('res.body', 'res.click_action');
  }

  ngAfterViewInit(): void {
    this.auth.photoAsync.subscribe((img) => {
      if (img) {
        this.photoImage = img;
        this.detech.detectChanges();
      }
    });
    this.FBMsg.currentMessage.subscribe(async (res: FireBaseNotiModel) => {
      if (res.body) {
        //fix fornow
        if (!res.body?.includes('noti')) {
          await this._noti.firebaseMessage(res.body, res.click_action);
        } else if (res.body?.includes('noti:e')) {
          await this._noti.firebaseMessageError(res.body, res.click_action);
        } else if (res.body?.includes('noti:w')) {
          await this._noti.firebaseMessageWarning(res.body, res.click_action);
        }
      }
    });
    this.GetNotiInApp();
    this.shipmentService.shipmentCountOnChange.subscribe(res => {
      if (res) {
        this.shipmentCount = res.current;
        this.shipmentTotal = res.total;
        if (res.total > 0) {
          this.shipmentUsagePercent = (res.current * 100) / res.total
        }
      }
    })
  }
  GetNotiInApp() {
    var token = 'Bearer ' + this.currentUser.access_token;
    this.NoficationService.getNoficationInAppByfontend(
      this.currentUser.id,
      token,
      2,
      this.pagesizeshipment
    ).subscribe((data) => {
      this.notiShipment = data;
      //  let result1 = this.notiShipment.filter(m => m.msgStatus == 1);
      //  let result2 = this.notiPrimary.filter(m => m.msgStatus == 1);
      //  this.countnofication = Object.keys(result2).length + Object.keys(result1).length;
    });
    this.NoficationService.getNoficationInAppByfontend(
      this.currentUser.id,
      token,
      1,
      this.pagesizePrimary
    ).subscribe((data) => {
      this.notiPrimary = data;
      console.log('getNoficationInAppByfontend', this.notiPrimary);
    });

    this.NoficationService.getNofication360InAppByfontend(
      this.currentUser.id,
      token,
      1,
      this.pagesizePrimary
    ).subscribe((data) => {
      // this.countnoti360 += data.length;
      this.noti360Type1 = data;
      console.log("getNofication360InAppByfontend", this.noti360Type1);

      this.noti360.push(...this.noti360Type1);
    });

    this.NoficationService.getNofication360InAppByfontend(
      this.currentUser.id,
      token,
      2,
      this.pagesizePrimary
    ).subscribe((data) => {
      // this.countnoti360 += data.length;
      this.noti360Type2 = data.slice(0, this.pagesizePrimary);
      console.log("noti3602", this.noti360Type2);
      this.noti360.push(...this.noti360Type2);

    });

    this.getcountNofication();
  }

  Get360NotiInApp() {

  }
  LogOut() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  getcountNofication() {
    var token = 'Bearer ' + this.currentUser.access_token;
    this.NoficationService.getCountNoficationInApp(
      this.currentUser.id,
      token,
      0
    ).subscribe((data) => {
      console.log("countnofication", data?.length)
      this.countnofication = data?.length;
    });

    this.NoficationService.countUnreadNofication360InAppByfontend(
      this.currentUser.id,
      token,
      1,
      this.pagesizePrimary
    ).subscribe((data) => {
      console.log("countnoti360", data?.length)
      this.countnoti360 += data?.length;
    });
  }

  onclickRead(item: any) {
    var token = 'Bearer ' + this.currentUser.access_token;
    console.log(item)
    if (item.msgStatus == 2) {
      if (item.url) {
        window.open(item.url, '_blank');

      }
      return;
    }
    let data = {
      notiapp: item,
    };
    this.NoficationService.UpdateReadNoficationInApp(token, data).subscribe((data) => {
      console.log(data)
      item.msgStatus = 2;
      if (data?.isSuccess) {
        item.msgStatus = 2;
        let result1 = this.notiPrimary.filter((m) => m.id == item.id);
        result1[0].msgStatus = 2;
        console.log(result1[0])
        if (result1[0].url) {
          window.open(result1[0].url, '_blank');

        }
        this.getcountNofication();
      }
    });
  }

  onclickReadShipment(item: any) {
    if (item.msgStatus == 2) {
      return;
    }
    let data = {
      notiapp: item,
    };
    this.NoficationService.UpdateNoficationInApp(data).subscribe((data) => {
      if (data?.isSuccess) {
        item.msgStatus = 2;
        let result1 = this.notiShipment.filter((m) => m.id == item.id);
        result1[0].msgStatus = 2;
        console.log(result1[0])
        if (result1[0].url) {
          window.open(result1[0].url, '_blank');

        }
        this.getcountNofication();
      }
    });
  }
  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  selecttapPrimary() {
    this.selecttab = true;
  }

  selecttapShipment() {
    this.selecttab = false;
  }

  select360() {

  }

  async loadNofication() {
    var token = 'Bearer ' + this.currentUser.access_token;
    if (this.selecttab) {
      this.pagesizePrimary = this.pagesizePrimary + 15;
      // await this.NoficationService.getNoficationInAppByfontend(
      //   this.currentUser.id,
      //   token,
      //   1,
      //   this.pagesizePrimary
      // ).subscribe((data) => {
      //   this.notiPrimary = data;
      //   this.getcountNofication();
      // });
    } else {
      this.pagesizeshipment = this.pagesizeshipment + 15;
      // await this.NoficationService.getNoficationInAppByfontend(
      //   this.currentUser.id,
      //   token,
      //   2,
      //   this.pagesizeshipment
      // ).subscribe((data) => {
      //   this.notiShipment = data;
      //   this.getcountNofication();
      // });
    }

  }

  clickloadMore360() {
    this.pagesize360 = this.pagesize360 + 15;
    // var token = 'Bearer ' + this.currentUser.access_token;
    // this.NoficationService.getNofication360InAppByfontend(
    //   this.currentUser.id,
    //   token,
    //   1,
    //   this.pagesizePrimary
    // ).subscribe((data) => {
    //   this.noti360Type1  = data.slice(0, this.pagesizePrimary);
    //   console.log("noti360",this.noti360Type1);
    // });

    // this.NoficationService.getNofication360InAppByfontend(
    //   this.currentUser.id,
    //   token,
    //   2,
    //   this.pagesizePrimary
    // ).subscribe((data) => {
    //   this.noti360Type2  = data.slice(0, this.pagesizePrimary);
    //   console.log("noti360",this.noti360Type1);
    // });
  }

  onReadInAppNoti360Item(item, type) {
    var token = 'Bearer ' + this.currentUser.access_token;


    if (item.msgStatus == 2) {
      const redirectUrl = this.getUrlContent(item.url);
      if (redirectUrl) {
        window.open(item.url, '_blank');
      }
      return;
    }

    let data = {
      notiapp: item,
    };

    this.NoficationService.UpdateNoficationInApp360
      (token,
        data
      )
      .subscribe((res: any) => {
        item.msgStatus = 2;
        this.getcountNofication();

        const redirectUrl = this.getUrlContent(item.url);
        if (redirectUrl) {
          window.open(item.url, '_blank');
        }
      });



  }
  getUrlContent(url: any): any {
    const urlData = new URL(url);

    return urlData;
  }

  setInnerHTML(value: any): string {
    let content = '<div class="set-p-bottom-0" >' + value + '</div>';
    return content;
  }

  goto360jobsearch() {
    this.SaveUserAction("ค้นหางาน(TopMenuBar)")
    var urlData = environment.webBase360 + "/jobsearch";
    window.open(urlData, '_blank');
  }

  SaveUserAction(actionName) {
    this._userEvent.SaveUserAction(actionName);
  }

  getShipmentCount() {
    this.shipmentService.getShipmenCount(this.currentUser.ClientID);
  }
}
