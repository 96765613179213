import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { Subject } from 'rxjs';
import { MasterCarrierNoti, subCarrier, isseting } from '../../model/carrierNotiModel';
import { NoficationserviceService } from '../../service/noficationservice.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ServicesettingService } from '@app/modules/setting/service/servicesetting.service';
import { Operation, PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
declare function initDataTable(tableID): any;
@Component({
  selector: 'app-noticarrier',
  templateUrl: './noticarrier.component.html',
  styleUrls: ['./noticarrier.component.scss']
})
export class NoticarrierComponent implements OnInit, OnDestroy, AfterViewInit {
  gg = 2;
  user: User;
  carriersetting: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  private tableWidget: any;
  DataSaveing: boolean = true;
  resultdata: any;
  permission90200 : PermissionGroup= {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  constructor(private notiservice: NoficationserviceService, private authService: AuthenticationService, public sanitizer: DomSanitizer, private _noti: NotificationService,
    private sv :ServicesettingService) {
    //this.Createsub();
    this.authService.currentUser.subscribe((x) => {this.user = x;
      var p: PermissionModule = this.authService.UserPermissionModule(90000);
      if (p) {
        console.log('permission', p);

        this.permission90200 = this.authService.FindPermissionAction(
          90200,
          p.operations
        );

      }
        });
  }

  ngOnInit(): void {


    this.notiservice.GetCarrier(this.user.ClientID).subscribe
      (
        (data) => {
          this.carriersetting = data;
          this.DataSaveing = false;
        }
      );
      //this.permission90200 = this.sv.GetPermission(90000,90200);
  }

  ngOnDestroy(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async ngAfterViewInit() {

  }

  noti = [
    { id: 1, name: 'ไม่แจ้ง' },
    { id: 2, name: 'แจ้งเตือน' },
  ];

  linegroup = [
    { id: 1, groupline: "Line Group 1", name: 'Team A' },
    { id: 2, groupline: "Line Group 2", name: 'Team B' },
    { id: 3, groupline: "Line Group 3", name: 'Team C' },
    { id: 4, groupline: "Line Group 4", name: 'Team A, Team B, Team C' },
    { id: 5, groupline: "Line Group 5", name: 'Team A, Team B, Team C' },
  ];

  masterCarrierNoti: Array<MasterCarrierNoti> = new Array<MasterCarrierNoti>();

  async getCarrier() {
    this.carriersetting = await this.notiservice.GetCarrier(this.user.ClientID);
    await this.reInitDatatable();
  }

  private reInitDatatable(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null
    }
    setTimeout(() => this.tableWidget = initDataTable("ClientSettinglist"), 100);
  }

  OnSaveData() {
    this.DataSaveing = true;
    //this.pageLoading.show();
    this.notiservice.updateCarrierSetting(this.user.ClientID.toString(), this.user.id.toString(), this.carriersetting.data).subscribe
      (
        (data) => {
          if (data?.isSuccess) {
            this._noti.toastsuccess("บันทึกข้อมูลเรียบร้อย", "");
          } else {
            this._noti.toasterror("บันทึกข้อมูลไม่สำเร็จ", "");
          }
          //this.pageLoading.hide();
          this.DataSaveing = false;
        }
      );
  }

}
