import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriverDetailComponent } from './page/driver-detail/driver-detail.component';
import { DriverListComponent } from './page/driver-list/driver-list.component';

const routes: Routes = [
  {path: '', component: DriverListComponent},
  {path: ':id', component: DriverDetailComponent,
data: {
  titlePage: 'ข้อมูลคนขับ',
  breadcrumb: [
    { label: 'จัดการฟลีทรถ และคนขับ', url: '/truck-management?t=myTruck' },
    { label: 'รถบรรทุก และคนขับ', url: '/truck-management/truck-license-list?tab=driver' },
    { label: 'ข้อมูลคนขับ', url: ''}
  ]
}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriverManagementRoutingModule { }
