import { Injectable } from '@angular/core';
import { ClientAPIPath, ShipmentApi } from '@app/core/_Constants/apiConstants';
import { User } from '@app/core/_Models/UserModel/User';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { AuthenticationService } from '@app/core/_Services';
import { HttpService } from '@app/core/_Services/http.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShipmentAssignCalendarService {

  destroy$: Subject<boolean> = new Subject<boolean>();
  currentUser: User;
  private permission :PermissionModule;
  
  constructor(
    private _apiService: HttpService,
    private authenticationService: AuthenticationService,
  ) { 
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  getShipmentAssignCalendar(companyId : number , startDate : Date,origin :string[]): Observable<any> {
    console.log("getShipmentAssignCalendar",startDate);

    var reqdate = new Date(startDate);
    var data = {
      companyId : companyId,
      startDate : reqdate.toLocaleDateString(),
      origin :origin
    }
    return this._apiService.post(ShipmentApi.Shipment+"/getShipmentAssignCalendar",data);
  }
}
