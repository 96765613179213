<app-breadcrumb Title="ตั้งค่าสำหรับการสร้างข้อมูล"></app-breadcrumb>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#tab-booking" role="tab">Booking Setting</a></li>
    <li class="nav-item"><a class="nav-link " data-toggle="tab" href="#tab-product" role="tab">Product Setting</a></li>
    <li class="nav-item"><a class="nav-link " data-toggle="tab" href="#tab-load-limit" role="tab">Load-limit Setting</a></li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-booking" role="tabpanel">
        <app-booking-setting></app-booking-setting>
    </div>

    <div class="tab-pane fade " id="tab-product" role="tabpanel">
        <app-product-setting></app-product-setting>
    </div>

    <div class="tab-pane fade " id="tab-load-limit" role="tabpanel">
        <app-load-limit-setting></app-load-limit-setting>
    </div>
</div>
