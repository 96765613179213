<div class="row" *ngIf="permission90200.canRead">
    <div class="col-xl-12">
                <div class="panel-content">
                    <div class="row">
                        <table class="table table-striped table-sm w-100">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="bg-info text-white text-center" [attr.colSpan]="linegroup.length">Line Group Notify</th>
                                    <th class="bg-warning text-center" colspan="4">Email</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th *ngFor="let item of linegroup">{{item.groupline}}</th>
                                    <th>Supply Chain Department</th>
                                    <th>Sales Deparment</th>
                                    <th>Plant/Warehouse Department</th>
                                    <th>Accounting Deparment</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of masterReport">
                                <tr>
                                    <td>
                                        <h6>{{item.name}}</h6>
                                        <div class="form-group" *ngIf="item.isTime">
                                            <label class="form-label" for="txttime">เวลา</label>
                                            <input class="form-control" type="text" name="txttime" placeholder="9.00 AM"
                                                name="txttime">
                                        </div>
                                        <div class="form-group" *ngIf="item.islocation">
                                            <app-googlemap InputText="ชื่อสถานที่" (LocData)="LocationChange($event)">
                                            </app-googlemap>
                                        </div>
                                        <ng-select placeholder="ประจำวัน" *ngIf="item.isday">
                                            <ng-option *ngFor="let item of week" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                </tr>
                                <tr *ngFor="let items of item.sub">
                                    <th class="text-right">
                                        <small>{{items.name}}</small>
                                    </th>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.Isgroup1" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.Isgroup2" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.Isgroup3" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.Isgroup4" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.IsSupply" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.IsSales" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.IsPlant" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td>
                                        <ng-select [(ngModel)]="items.Isset.IsAccount" placeholder="แจ้งเตือน">
                                            <ng-option *ngFor="let item of status" [value]="item.id">{{item.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-5 d-flex justify-content-center">
                        <div class="col-md-3 text-center">
                            <button type="button" class="btn btn-success btn-block" *ngIf="permission90200.canEdit">
                                บันทึก</button>
                        </div>
                    </div>
                </div>
    </div>
</div>
<div class="row" *ngIf="!permission90200.canRead">
    <div class="col-12">
        Access Denied
    </div>
</div>