import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from '../_Services';
import { environment } from 'src/environments/environment';
import { Router, RouterStateSnapshot } from '@angular/router';
import { from, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { OAuthTokenResponse } from '../_Models/oauth-token-dto';
import { errorMonitor } from 'stream';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const currentUser = this.authenticationService.currentUserValue;
    const currentToken = this.authenticationService.currentToken.value;
    const accessToken = localStorage.getItem('at');
    // localStorage.getItem('rt');
    if (accessToken && accessToken != "null") {
      request = request.clone({
        setHeaders: {
          // "X-Timezone-Offset": '420',
          Authorization: `Bearer ${accessToken}`,
          'TST-UserPool': '1',
          'TST-AppPackage': '3',
        },
        body: request.body
      });
    } else if (currentUser?.access_token) {
      request = request.clone({
        setHeaders: {
          // "X-Timezone-Offset": '420',
          Authorization: `Bearer ${currentUser?.access_token}`,
          'TST-UserPool': '1',
          'TST-AppPackage': '3',
        },
        body: request.body
      });
    }
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.status == 401) {
              console.warn('line 52 Unauthorized access!')
              // // ==========================================================
              // // Logic ชุดนี้ คือ ถ้าเกิด Error ให้ Redirect ไปที่หน้า CookieLogin
              // // โดยจะ set ตัว 'stopl' ไว้ที่ localstorage เพื่อให้ Component cookie-login
              // // เช็ค เพื่อกัน Loop redirect
              // const currentLocation = origin + this.router.url;
              // // localStorage.setItem('stopl', 'true');
              // if (currentLocation.search('/cookie-login') === -1) {
              //   this.authenticationService.logout();
              //   this.router.navigate(['/cookie-login'], {
              //     queryParams: { redirect: currentLocation }
              //   });
              // }
              // // ควรจะทำเป็น หน้า Overlay แล้วให้ ลูกค้ากด logout หรือ ลองอีกครั้ง
              // // ==========================================================
              this.refreshTokenMethod(request, next);

            }
          }
          return event;
        },
        error: (error) => {
          console.warn('JwtInterceptor error!', error)
          // debugger
          if (error.status === 400) {
            console.warn('error.status === 400')
            return throwError(error);
          } else if (error.status === 401) {
            this.refreshTokenMethod(request, next);
            console.warn('error.status === 401 Unauthorized access!')
          }
          else if (error.status === 403) {
            this.refreshTokenMethod(request, next);
            console.warn('error.status === 403 Unauthorized access!')
          }
          else if (error.status === 404) {
            console.warn('error.status === 404!')
          }
          else {
            return throwError(error);
          }
        }
      }));;
  }

  isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this._isoDateFormat.test(value);
    } return false;
  }
  convert(body: any) {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIsoDateString(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convert(value);
      }
    }
  }

  refreshTokenMethod(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    //get refresh token for retry httpRequest
    const currentUser = this.authenticationService.currentUserValue;
    return from(this.authenticationService.getTokenOAuth2(currentUser?.access_token, environment.oauth.state, "refresh_token")).pipe(
      switchMap((res: OAuthTokenResponse) => {
        if (!res.access_token) {
          this.redirectLogout();
        }
        const accessToken = localStorage.getItem('at');
        // localStorage.getItem('rt');
        if (accessToken) {
          request = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + accessToken,
            },
          });
        }
        return next.handle(request);
      }),
      catchError((error) => {
        //Refresh Token Issue.
        console.log('catchError Refresh Token Issue.');
        this.redirectLogout();

        return throwError(() => error);
      })
    );
  }

  redirectLogout() {
    this.authenticationService.logout();
    const oauth2Param = `&client_id=${environment.oauth.client_id}&response_type=${environment.oauth.response_type}&redirect_uri=${environment.oauth.redirect_uri}&state=${environment.oauth.state}`;
    window.location.href = environment.loginUrl + '?redirect=' + encodeURIComponent(origin + this.router.routerState.snapshot.url) + oauth2Param;
  }
}
