
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="{{index}}" class="{{index === 0?'active':''}}" *ngFor="let ads of AdsUrlList;let index = index"></li>
    </ol>
    <div class="carousel-inner" style="max-height: 250px;">
      <div class="carousel-item " [ngClass]="{'active':a === 0}" *ngFor="let ads of AdsUrlList;let a = index">
        <img class="d-block w-100" src="{{ads}}" >
      </div>
      
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>