
<!-- Modal -->
<div class="modal fade" id="planOrder" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-lg">
                <div class="modal-header">
                        <h5 class="modal-title">Modal title</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form [formGroup]="OrderForm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Order Number  <span class="txt-red">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" type="text" formControlName="order_number"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Origin <span class="txt-red">*</span></label>
                                <div class="input-group">
                                    <ng-select style="width:100%; " [items]="locations" formControlName="origin_location_code"
                                    bindLabel="name"  bindValue="code" >
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item.name}} ({{item.code}})
                                    </ng-template>  
                                    </ng-select>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="">Destination  <span class="txt-red">*</span></label>
                                <div class="input-group">
                                    <ng-select style="width:100%; " [items]="locations" formControlName="destination_location_code"
                                    bindLabel="name"  bindValue="code" >
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item.name}} ({{item.code}})
                                    </ng-template>  
                                    </ng-select>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="submit" class="btn btn-primary mb-1" (click)="addOrderItem()">
                                +
                            </button>
                        </div>
                        <div class="col-12">
                            <table class="table table-striped table-bordered">
                                <tr class="text-center">
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Material
                                    </th>
                                    <th>
                                        QTY​
                                    </th>
                                    <th>
                                        UNIT
                                    </th>
                                    <th>
                                        Weight (TON)​
                                    </th>
                                </tr>
                               <tr *ngFor="let item of getItem();let itemNum = index">
                                   <td>
                                    {{itemNum}}
                                   </td>
                                   <td>
                                    {{item.material_code}}
                                   </td>
                                   <td>
                                    {{item.qty}}
                                   </td>
                                   <td>
                                    {{item.qty_unit}}
                                   </td>
                                   <td>
                                    {{item.wieght}}
                                   </td>
                               </tr>
                            </table>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="OnsaveOrder()">Save</button>
            </div>
        </div>
    </div>
</div>
<app-modal-add-item-order-plan (addItem)="AddItem($event)"></app-modal-add-item-order-plan>