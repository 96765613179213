import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DepartmentModel } from '@app/core/_Models/MasterModel/DepartmentModel';
import { RoleModel } from '@app/core/_Models/MasterModel/RoleModel';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { TstImageItemModel } from '@app/core/_Models/TstImageItemModel';
import { User } from '@app/core/_Models/UserModel/User';
import { ClientModule, Operation } from '@app/core/_Models/UserModel/UserPermission';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { CustomNgbDateParserFormatterService } from '@app/core/_Services/NgbDatepickerService/CustomNgbDateParserFormatter.service';
import { NgbDatepickerI18nBuddhistService } from '@app/core/_Services/NgbDatepickerService/NgbDatepickerI18nBuddhist.service';
import { AddClientUserModel } from '@app/modules/team/models/ClientUser/AddClientUserModel';
import { TeamListModel } from '@app/modules/team/models/TeamListModel';
import { ClientUserService } from '@app/modules/team/services/client-user.service';
import { TeamService } from '@app/modules/team/services/team.service';
import { ModalConfirmComponent } from '@app/shared/Components/modal-confirm/modal-confirm.component';
import { NgbCalendar, NgbCalendarBuddhist, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { UserDriverLicenceService } from '../../services/user-driver-licence.service';
import { UserIDCardService } from '../../services/user-id-card.service';
import { ModalAddFleetDriverFormComponent } from '../modal-add-fleet-driver-form/modal-add-fleet-driver-form.component';
import { ModalAddUserSuccessComponent } from '../modal-add-user-success/modal-add-user-success.component';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';
declare const $;

@Component({
  selector: 'app-modal-edit-driver-form',
  templateUrl: './modal-edit-driver-form.component.html',
  styleUrls: ['./modal-edit-driver-form.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nBuddhistService },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatterService }
  ]

})
export class ModalEditDriverFormComponent implements OnInit, OnChanges {

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() IsView = false;
  @Input() IsEdit = false;
  @Input() IsDriver = false;
  @Input() IsAddToFleet = true;
  @Input() EditData: AddClientUserModel;
  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Output() AfterSubmit = new EventEmitter<boolean>();
  @Output() driverIdEvent = new EventEmitter<number>();
  @Input() IsDisableDriverRole = false;

  canChange = true;
  onSaving = false;
  imagefile: File;
  profileImageUri: string;
  base64Image: string;
  departmentList: DepartmentModel[];
  roleList: RoleModel[];
  teamList: TeamListModel[];
  IsusernamePhone = true;
  teamIdstestset: number[] = [];
  userId = 0;
  setAuthorizeIDdefault: number = 1;
  fixedAuthorizeIDdriver: number = 3;
  fixedDepartmentDriver: number = 5;
  fixedRoleDriver: number = 9;

  driverlicenseReq = false;
  valid: string

  isCanUserMobileNo = false
  isCanUseIDCard = false
  currentYear = new Date();
  maxYear = 2565;
  roleSelected = "";
  roleItem: any;
  currentUser: User;
  userform = new FormGroup({
    nametitle: new FormControl(null),
    firstname: new FormControl(null, Validators.required),
    lastname: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    mobileNumber: new FormControl(null, [Validators.required, Validators.minLength(10)]),
    status: new FormControl(true),
    username: new FormControl(),
    departmentId: new FormControl(null),
    roleId: new FormControl(null, Validators.required),
    teamIds: new FormControl([], Validators.required),
    birthDate: new FormControl(null),
    idCard: new FormControl(null),
    authorizeID: new FormControl(1),
    recievePasswordChanel: new FormControl(1, Validators.required),
    photoURL: new FormControl(null),
  });

  permission70100: Operation;
  Active = [
    { id: false, name: 'ระงับการใช้งาน' },
    { id: true, name: 'เปิดใช้งาน' },
  ];

  deleteItem: TstImageItemModel = null;
  imageMaxFileSize: number = 0;

  userIDCards: TstImageItemModel[] = [];
  userIDCardsNewImages: File[] = [];

  userDriverLicences: TstImageItemModel[] = [];
  userDriverLicencesNewImages: File[] = [];

  userPhoto = {
    userID: 0,
    photoURL: ''
  }


  @Output() modalReference!: NgbModalRef;

  @ViewChild('modaleditdriverform', { static: true }) modaleditdriverform!: ModalEditDriverFormComponent
  @ViewChild("modalConfirm", { static: true }) modalConfirm!: ModalConfirmComponent;
  @ViewChild("modaladdfleetdriverform", { static: true }) modaladdfleetdriverform!: ModalAddFleetDriverFormComponent;
  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;
  @ViewChild("modaladdsuccess", { static: true }) modaladdsuccess!: ModalAddUserSuccessComponent;

  clientModule_70400: ClientModule;
  clientModule_70200: ClientModule;
  clientModule_70110: ClientModule;
  constructor(
    public authService: AuthenticationService,
    // private pageLoading: PageloadingService,
    private _ClientUserService: ClientUserService,
    private _noti: NotificationService,
    private _teamService: TeamService,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    private _userIDCardService: UserIDCardService,
    private _userDriverLicenceService: UserDriverLicenceService,
    private change: ChangeDetectorRef
  ) {
    this.setAuthorizeIDdefault = 1;
    this.fixedAuthorizeIDdriver = 3;
    this.fixedDepartmentDriver = 5;
    this.fixedRoleDriver = 9;
    this.maxYear = this.currentYear.getFullYear() + 1;
    console.log("maxYear", this.maxYear);


  }

  ngOnInit(): void {
    this.getRoleByDepartment();

    this.maxYear = new Date().getFullYear() + 543;

    this.permission70100 = this._teamService.GetPermission(70000, 70100);
    this.imageMaxFileSize = (10 * 1024) * 1024;
    this.authService.currentUser.subscribe((x) => {
      this.currentUser = x;
      this.clientModule_70400 = this.authService.currentUserValue.clientModules.find(e => e.amo_id == 70400);
      this.clientModule_70200 = this.authService.currentUserValue.clientModules.find(e => e.amo_id == 70200);
      this.clientModule_70110 = this.authService.currentUserValue.clientModules.find(e => e.amo_id == 70110);
      console.log('clientModule_70400', this.clientModule_70400);
      console.log('clientModule_70200', this.clientModule_70200);

    });

    this.setform();
    // // getmaster
    // this.getAllDepartment();
    // this.getTeam();
    this.initMasterData();

  }

  ngOnChanges() {
    this.imageMaxFileSize = (10 * 1024) * 1024;
    // console.log(this.IsEdit);
    // console.log(this.EditData);
    // console.log(!this.IsView);
    this.setform();
    this.canChange = !this.IsView;
    if (this.IsEdit) {
      this.userform.controls.firstname.setValue(this.EditData?.firstName);
      this.userform.controls.lastname.setValue(this.EditData?.lastName);
      this.userform.controls.email.setValue(this.EditData?.email);
      this.userform.controls.mobileNumber.setValue(this.EditData?.phoneNumber);
      this.userform.controls.username.setValue(this.EditData?.username);
      this.userform.controls.departmentId.setValue(this.EditData?.departmentId);
      this.userform.controls.roleId.setValue(this.EditData?.roleId);
      this.userform.controls.teamIds.setValue(this.EditData?.teamIds);
      this.userform.controls.status.setValue(this.EditData?.roleUser?.isActive || true);
      this.userform.controls.idCard.setValue(this.EditData?.idCard);
      this.userform.controls.birthDate.setValue(this.formatter.parse(this.EditData?.birthDate))
      this.userform.controls.photoURL.setValue(this.EditData?.photoURL);
      this.userform.controls.authorizeID.setValue(this.EditData?.roleUser.authorizeLevelID);
      this.profileImageUri = this.EditData?.photoURL;

      this.userIDCards = this.EditData?.userIDCard.map(e => {
        return {
          ImageId: e.id,
          ImageUrl: e.fileURL,
          ImageIsNew: false,
          ImageExtension: `.${(e.fileURL.split('.')).splice(-1).pop()}`  //
        } as TstImageItemModel;
      })

      this.userDriverLicences = this.EditData?.userDriverLicence.map(e => {
        return {
          ImageId: e.id,
          ImageUrl: e.fileURL,
          ImageIsNew: false,
          ImageExtension: `.${(e.fileURL.split('.')).splice(-1).pop()}`  //
        } as TstImageItemModel;
      })
      if (this.EditData?.email && this.IsView)
        this.userform.get('email').clearValidators();

      console.log(this.userform.value)

      if (this.profileImageUri && this.profileImageUri.indexOf('http') !== -1) {
        this.authService.photoAsync.next(this.profileImageUri);
      }
      // if (this.EditData.username == null || this.EditData.username == this.EditData.phoneNumber) {
      //   this.IsusernamePhone = true;
      // } else {
      //   this.IsusernamePhone = false;
      // }
      // this.setform();
      this.userPhoto.userID = this.EditData?.tsT360_UserID;
      this.userPhoto.photoURL = this.EditData?.photoURL;
    } else {
      this.userform.reset();
      this.userPhoto.userID = 0;
      this.userPhoto.photoURL = '';
      this.userIDCards = [];
      this.userDriverLicences = [];
      this.profileImageUri = null;
    }

    this.CheckCanUseIDCard()
    this.change.detectChanges();

    var roleIndex = this.roleList?.findIndex(a => a.id == this.EditData?.roleId)
    console.log("ROLE", this.roleList)

    if (this.EditData?.roleUser?.authorizeLevelID == 3) {
      this.driverlicenseReq = true;
    }

    if (roleIndex && roleIndex > -1) {
      if (this.roleList[roleIndex].roleID == 9) {
        this.driverlicenseReq = true;
      } else {
        this.driverlicenseReq = false;
      }
    }

  }

  setform() {
    if (this.IsEdit) {
      this.userform = new FormGroup({
        nametitle: new FormControl(null),
        firstname: new FormControl(null, Validators.required),
        lastname: new FormControl(null, Validators.required),
        email: new FormControl(null),
        mobileNumber: new FormControl(null, [Validators.required, Validators.minLength(10)]),
        status: new FormControl(true),
        username: new FormControl(),
        departmentId: new FormControl(null),
        roleId: new FormControl(null, Validators.required),
        teamIds: new FormControl([]),
        birthDate: new FormControl(null),
        idCard: new FormControl(null),
        authorizeID: new FormControl(null),
        recievePasswordChanel: new FormControl(1, Validators.required),
        photoURL: new FormControl(null),
      });
    }
    else {
      this.profileImageUri = null;
      this.userform = new FormGroup({
        nametitle: new FormControl(null),
        firstname: new FormControl(null, Validators.required),
        lastname: new FormControl(null, Validators.required),
        email: new FormControl(null, [Validators.email]),
        mobileNumber: new FormControl(null, [Validators.required, Validators.required, Validators.minLength(10)]),
        status: new FormControl(true),
        username: new FormControl(),
        departmentId: new FormControl(null),
        roleId: new FormControl(null, Validators.required),
        teamIds: new FormControl([]),
        birthDate: new FormControl(null),
        idCard: new FormControl(null),
        authorizeID: new FormControl(null),
        recievePasswordChanel: new FormControl(1, Validators.required),
        photoURL: new FormControl(null),
      });

      this.userform.get('birthDate').valueChanges.subscribe(val => {
        if (val) {
          console.log('birthDate', val);
          let date = val;
          var bd = new Date(date.year - 543, date.month - 1, date.day);
          console.log('calculateAge', !this.calculateAge(bd));

          if (!this.calculateAge(bd)) {
            this.userform.controls['birthDate'].setErrors({ 'incorrect': true });
          } else {
            this.userform.controls['birthDate'].setErrors(null);
          }
        }

      })

    }
    if (this.clientModule_70400?.pkg_id > 20) {
      this.userform.controls["departmentId"].setValidators([Validators.required]);
    }

    if (this.clientModule_70200?.pkg_id > 20 && !this.IsDriver) {
      this.userform.controls["teamIds"].setValidators([Validators.required]);
    }

    console.log("setForm", this.userform)

  }



  initMasterData(): void {
    const forkData = [
      this._ClientUserService.GetAllDepartment(),
      this._ClientUserService.GetTeamByClient(),
    ];

    //this.pageLoading.show();
    forkJoin([...forkData]).subscribe(data => {

      //this.pageLoading.hide();
      if (!data[0].isSuccess) {
        this._noti.toasterror('ไม่สามารถดึงข้อมูล Department ได้', data[0].message);
      } else {
        this.departmentList = data[0].data;


        if (this.IsDriver) {
          this.userform.controls.departmentId.setValue(this.fixedDepartmentDriver);
          this.userform.controls.authorizeID.setValue(this.fixedAuthorizeIDdriver);
          this.getRoleByClient();

          // this.authService.currentUser.subscribe(async (user) => {
          //   this._ClientUserService
          //     .GetRoleByDepartment(this.fixedDepartmentDriver, user)
          //     .subscribe((res: TMSAPIResponse) => {
          //       if (!res.isSuccess) {
          //         this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
          //       } else if (res.isSuccess) {
          //         this.roleList = res.data;

          //         const roleFindDriver = this.roleList.find(c => {
          //           return c.roleID == this.fixedRoleDriver;
          //         });

          //         console.log('roleFindDriver', roleFindDriver);

          //         if (roleFindDriver) {
          //           this.userform.controls.roleId.setValue(roleFindDriver.id);
          //         }

          //         console.log('roleList', this.roleList);
          //       }
          //     });
          // });
        } else {
          this.getRoleByClient();
        }
      }
      if (!data[1].isSuccess) {
        this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', data[1].message);
      } else if (data[1].isSuccess) {
        this.teamList = data[1].data;
      }

      this.userform.controls.recievePasswordChanel.setValue(1);

    }, err => {
      //this.pageLoading.hide();
      this._noti.toasterror('ข้อมูล Master', 'ไม่สามารถโหลดข้อมูล Team และ Department ได้.');
    });

    this.userIDCardsNewImages = []
    this.userDriverLicencesNewImages = []

  }

  async checkUsedMobileNo() {
    if (this.EditData?.roleUser.authorizeLevelID == 1 && this.userform.value.authorizeID == 1 && this.userform.value.mobileNumber == this.EditData.phoneNumber) {
      return;
    } else if (this.EditData?.roleUser.authorizeLevelID == 1 && this.userform.value.authorizeID == 3 && this.userform.value.mobileNumber == this.EditData.phoneNumber) {
      return;
    } else {
      this.isCanUserMobileNo = false
      const mobileNo = this.userform.value.mobileNumber;
      if (mobileNo)
        if (mobileNo.length == 10 && this.userform.value.roleId != null) {
          const authorizeID = this.userform.value.authorizeID;
          const companyId = this.currentUser.tstS360_CompanyID;

          const result = await this._ClientUserService
            .CheckCanUseMobileNo(mobileNo, authorizeID, companyId.toString(), this.EditData?.tsT360_UserID)
            .subscribe((res: any) => {
              if (!res.isSuccess) {
                let errors: any;
                if (this.userform.value.mobileNumber.length < 10) {
                  this.userform.controls.mobileNumber.setErrors({
                    canUse: res.message || 'กรอกเบอร์โทรศัพท์ให้ครบถ้วน',
                  });
                } else {
                  this.userform.controls.mobileNumber.setErrors({
                    canUse: res.message || 'เบอร์โทรศัพท์ถูกใช้งานไปแล้ว',
                  });
                }
              }
              else if (res.isSuccess) {
                this.userform.controls.mobileNumber.setErrors(null);
                this.isCanUserMobileNo = true
              }
            });
        }
    }

  }


  async checkUsedEmail() {
    this.valid = ''
    const email = this.userform.value.email;
    console.log('email', this.userform);
    if (email) {
      const result = await this._ClientUserService
        .CheckCanUseEmail(email)
        .subscribe((res: any) => {
          if (res == false && !(this.IsEdit && this.EditData?.email == email)) {
            let errors: any;
            this.userform.controls.email.setErrors({
              canUse: 'อีเมลนี้ถูกใช้งานไปแล้ว',
            });
            this.valid = 'is-invalid'

          }
          else if (res == true) {
            this.valid = 'is-valid'
            this.userform.controls.email.setErrors(null);
          }
          console.log('checkUsedEmail', res);
        });
    }
  }

  onSubmit() {
    // console.log(this.userform)
    if (this.IsusernamePhone == false) {
      if (this.userform.value.username == null) {
        this._noti.toasterror('กรุณากรอก Username', '');
        return;
      }
    }

    // console.log('this.userform : ', this.userform);
    this.onSaving = true;
    if (this.IsDriver)
      this.userform.controls.authorizeID.setValue(this.fixedAuthorizeIDdriver);
    if (this.IsEdit) {
      //this.pageLoading.show();
      let date = this.userform.value.birthDate
      if (date) {
        let convertDate = `${date.year - 543}-${date.month}-${date.day}`;
        var bd = new Date(date.year - 543, date.month - 1, date.day);


        // console.log(convertDate)

        if (!this.calculateAge(bd)) {

          this._noti.toastwarning('ผู้ใช้งานต้องมีอายุตั้งแต่ 18 ปีขึ้นไป', '');
          this.userform.controls['birthDate'].setErrors({ 'incorrect': true });
          //this.pageLoading.hide();
          this.onSaving = false;

          return;
        } else {
          this.userform.controls['birthDate'].setErrors(null);
        }
        if (this.EditData)
          this.EditData.birthDate = convertDate
      }


      // this.EditData.birthDate = this.userform.value.birthDate

      // console.log('test');
      // console.log('EditData', this.EditData);
      // console.log(this.userform.value);

      this._ClientUserService
        .UpdateClientUser(this.userform, this.EditData)
        .subscribe(
          (res: any) => {

            if (res.isSuccess) {
              console.log(res.tstUser360);

              const userRes = res.tstUser360;

              Promise.all([this.saveIdCardImage(userRes), this.saveUserDriverLiscenseImage(userRes), this.saveUserIamge(userRes)])
                .then(results => {
                  console.log('Promise.all', results); // Array of results from the three functions
                  // this._noti.toastsuccess('บันทึกข้อมูลคนขับสำเร็จ', res.message);
                })
                .catch(error => {
                  console.error(error);
                }).finally(() => {
                  this.modalReference?.close(true)
                  this.openmodalsuccess();
                  this.EditData = null;
                });

              // this.AfterSubmit.emit(res.isSuccess);

            } else {
              this._noti.toasterror('แก้ไขข้อมูลไม่สำเร็จ', res.message);
            }


          },
          (err) => {
            //this.pageLoading.hide();
          }
        );

    } else {
      //this.pageLoading.show();

      let date = this.userform.value.birthDate
      if (date) {
        var bd = new Date(date.year - 543, date.month - 1, date.day);
        let convertDate = `${date.year - 543}-${date.month}-${date.day}`;

        console.log(convertDate)

        if (!this.calculateAge(bd)) {

          this._noti.toastwarning('ผู้ใช้งานต้องมีอายุตั้งแต่ 18 ปีขึ้นไป', '');
          this.userform.controls['birthDate'].setErrors({ 'incorrect': true });
          //this.pageLoading.hide();
          this.onSaving = false;

          return;
        } else {
          this.userform.controls['birthDate'].setErrors(null);
        }
        this.userform.controls.birthDate.setValue(convertDate)
      }

      this._ClientUserService.AddClientUser(this.userform).subscribe(
        (res: TMSAPIResponse) => {
          try {
            //this.pageLoading.hide();
            console.log(res)
            if (res.isSuccess) {
              const userRes = res.data;

              this.driverIdEvent.emit(userRes?.id)
              Promise.all([this.saveIdCardImage(userRes), this.saveUserDriverLiscenseImage(userRes), this.saveUserIamge(userRes)])
                .then(results => {
                  console.log('Promise.all', results); // Array of results from the three functions
                  // this._noti.toastsuccess('บันทึกข้อมูลคนขับสำเร็จ', res.message);
                  if (this.IsDriver || this.roleItem?.roleID == 9) {
                    this._noti.toastsuccess('บันทึกข้อมูลคนขับสำเร็จ', res.message);
                    if (this.IsAddToFleet) {
                      this.modalReference.close(true);
                      this.openmodaladdfleetdriverform(userRes.id)
                    } else {
                      this.modalReference.close(true);
                      this.AfterSubmit.emit(res.isSuccess);
                    }
                  } else {
                    this.modalReference.close(true);
                    this.openmodaladdsuccess();
                  }
                })
                .catch(error => {
                  console.error(error);
                });
            } else {
              this._noti.toasterror('บันทึกข้อมูลไม่สำเร็จ', res.message);
            }
          } catch (e) {
            this._noti.toasterror('บันทึกข้อมูลไม่สำเร็จ', res.message);
            // window.location.reload();
          }
        },
        (err) => {
          //this.pageLoading.hide();
        }
      );
    }
    this.onSaving = false;
  }
  saveIdCardImage(userRes) {
    if (this.userIDCardsNewImages.length > 0) {
      const userIdCardData = new FormData();
      userIdCardData.append('userid', userRes.id);
      userIdCardData.append('companyId', `${this.currentUser.tstS360_CompanyID}`);
      userIdCardData.append('file', this.userIDCardsNewImages[0]);

      this._userIDCardService.addUserIDCard(userIdCardData).subscribe(res => {
        console.log(res);
      });
    }
  }
  saveUserDriverLiscenseImage(userRes) {
    if (this.userDriverLicencesNewImages.length > 0) {
      const userDriverLicenseData = new FormData();
      userDriverLicenseData.append('userid', userRes.id);
      userDriverLicenseData.append('companyId', `${this.currentUser.tstS360_CompanyID}`);
      userDriverLicenseData.append('file', this.userDriverLicencesNewImages[0]);

      this._userDriverLicenceService.addUserDriverLicence(userDriverLicenseData).subscribe(res => {
        console.log(res);
      });
    }
  }
  saveUserIamge(userRes: any) {
    if (this.imagefile != null) {
      if (this.imagefile.size > 0) {
        const formData = new FormData();
        const imgPath = 'c' + userRes.companyId;
        const fileExt = this.imagefile.name.substring(
          this.imagefile.name.lastIndexOf('.')
        );
        const imgName = 'u' + userRes.id + '-profile' + fileExt;

        formData.append('file', this.imagefile);
        formData.append('newpath', imgPath);
        formData.append('filename', imgName);
        formData.append('uid', userRes.id);
        formData.append('companyId', `${this.currentUser.tstS360_CompanyID}`);
        this._ClientUserService
          .UploadUserImage(formData)
          .subscribe((data) => {
            // console.log('upload image data', data);
            this.imagefile = null;
            this.profileImageUri = data.path;
            // this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
            this.userform.reset();
          });
      }
    }
  }

  async getAllDepartment() {
    const dept = this._ClientUserService
      .GetAllDepartment()
      .subscribe((res: TMSAPIResponse) => {
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          this.departmentList = res.data;
        }
      });
  }

  getRoleByDepartment() {
    const departmnetid = this.userform.value.departmentId;
    if (departmnetid > 0) {
      this.authService.currentUser.subscribe(async (user) => {
        this._ClientUserService
          .GetRoleByDepartment(departmnetid, user)
          .subscribe((res: TMSAPIResponse) => {
            if (!res.isSuccess) {
              this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
            } else if (res.isSuccess) {
              this.roleList = res.data;
              let defaultRole = null
              if (this.roleList.length > 0) { defaultRole = this.roleList[0].id; }
              this.userform.controls.roleId.setValue(defaultRole);
              this.setAuthlevelByClientRole(this.roleList[0])
            }
          });
      });
    }
  }

  getRoleByClient() {

    this.authService.currentUser.subscribe(async (user) => {

      console.log(this.currentUser)
      this._ClientUserService
        .GetRoleByClientId(user)
        .subscribe((res: TMSAPIResponse) => {
          if (!res.isSuccess) {
            this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
          } else if (res.isSuccess) {

            console.log("ROLE res.data", res.data)

            console.log("(this.clientModule_70400", this.clientModule_70400)
            if (this.clientModule_70400?.pkg_id <= 20) {
              this.roleList = res.data.filter(a => this.currentUser.Role.roleID >= a.roleID);
            } else {
              this.roleList = res.data;
            }


            console.log("ROLE", this.roleList)
            if (this.IsDriver) {
              const roleFindDriver = this.roleList.find(c => {
                return c.roleID == this.fixedRoleDriver;
              });

              console.log('roleFindDriver', roleFindDriver);

              if (roleFindDriver) {
                this.userform.controls.roleId.setValue(roleFindDriver.id);
              }
            }
          }
        });
    });

  }


  async getTeam() {
    const result = await this._ClientUserService
      .GetTeamByClient()
      .subscribe((res: TMSAPIResponse) => {
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else if (res.isSuccess) {
          this.teamList = res.data;
        }
      });
  }

  handleFileInput(event: any) {
    console.log('handleFileInput', event);
    const files = event?.target?.files;
    if (files && files[0]) {
      this.imagefile = files[0];
      const base64Type = 'data:' + this.imagefile.type + ';base64,';
      this.base64Image = base64Type;
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(files[0]);
    }
  }

  handleFileInput2(event: any) {
    console.log('handleFileInput', event);
    const files = event;
    if (files && files[0]) {
      this.imagefile = files[0];
      const base64Type = 'data:' + this.imagefile.type + ';base64,';
      this.base64Image = base64Type;
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(files[0]);
    }
  }

  _handleReaderLoaded(readerEvt) {
    if (readerEvt) {
      console.log('_handleReaderLoaded', readerEvt);
      const binaryString = readerEvt.target.result;
      this.base64Image += btoa(binaryString);
      this.profileImageUri = this.base64Image;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkUsernameChnage(event) {
    if (this.IsusernamePhone) {
      this.IsusernamePhone = false;
    } else {
      this.IsusernamePhone = true;
    }
  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  CheckCanUseIDCard() {
    if (this.userform.value.idCard != null && this.userform.value.idCard != '') {
      this.isCanUseIDCard = this.chkDigitPid(this.userform.value.idCard)
    } else {
      this.isCanUseIDCard = true;
    }
  }

  chkDigitPid(p_iPID) {
    var total = 0;
    var iPID;
    var chk;
    var Validchk;
    iPID = p_iPID?.replace(/-/g, "");
    Validchk = iPID?.substr(12, 1);
    var j = 0;
    var pidcut;
    for (var n = 0; n < 12; n++) {
      pidcut = parseInt(iPID?.substr(j, 1));
      total = (total + ((pidcut) * (13 - n)));
      j++;
    }

    chk = 11 - (total % 11);

    if (chk == 10) {
      chk = 0;
    } else if (chk == 11) {
      chk = 1;
    }
    if (chk == Validchk) {
      // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
      return true;
    } else {
      this.userform.controls.idCard.setErrors({
        canUse: 'ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง',
      });
      // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      return false;
    }
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string): NgbModalRef {

    this.setform();
    this.initMasterData();

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modaleditdriverform, {
        size: size,
        centered: true,
        backdrop: 'static'
      });
    } else {
      this.modalReference = this.modalService.open(this.modaleditdriverform, {
        centered: true,
        backdrop: 'static'
      });

      return this.modalReference
    }

  }

  closed() {
    this.modalReference.close(true)
    this.userIDCards = [];
    this.userDriverLicences = [];
    this.EditData = null;
  }

  addFleedClose() {
    this.openmodaladdsuccess();
    this.closed()
  }

  ngxdropzoneimage_change(event: any) {
    console.log('ngxdropzoneimage_change', event);
    this.userIDCardsNewImages.push(...event.addedFiles);
  }

  ngxdropzoneimage_remove(f: File) {
    this.userIDCardsNewImages.splice(this.userIDCardsNewImages.indexOf(f), 1);
  }

  ngxdropzoneimage_old_delete(item: TstImageItemModel) {
    this.deleteItem = item;
    const modalConfirmConfig = {
      title: 'ลบข้อมูล',
      description: 'คุณต้องการจะลบออกจากระบบใช่ หรือ ไม่',
      iconClassName: 'fas fa-times text-danger',
      buttonList: [
        { text: 'ใช่ ฉันต้องการลบ', class: 'btn-danger', comfirm: true },
        { text: 'ยกเลิก', class: 'btn-outline-secondary', comfirm: false },
      ],
    };
    this.modalConfirm.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirm.open('sm');
    modalRef.closed.subscribe((comfirm: boolean) => {
      if (comfirm) {
        this.userIDCards.splice(this.userIDCards.indexOf(this.deleteItem), 1);
      }
      this.deleteItem = null;
    })
  }
  // upload driverlicense --------------------------------------------------------------------------------
  user_driver_license_ngxdropzoneimage_change(event: any) {
    console.log('ngxdropzoneimage_change', event);
    this.userDriverLicencesNewImages.push(...event.addedFiles);
  }

  user_driver_license_ngxdropzoneimage_remove(f: File) {
    this.userDriverLicencesNewImages.splice(this.userDriverLicencesNewImages.indexOf(f), 1);
  }

  user_driver_license_ngxdropzoneimage_old_delete(item: TstImageItemModel) {
    this.deleteItem = item;
    const modalConfirmConfig = {
      title: 'ลบข้อมูล',
      description: 'คุณต้องการจะลบออกจากระบบใช่ หรือ ไม่',
      iconClassName: 'fas fa-times text-danger',
      buttonList: [
        { text: 'ใช่ ฉันต้องการลบ', class: 'btn-danger', comfirm: true },
        { text: 'ยกเลิก', class: 'btn-outline-secondary', comfirm: false },
      ],
    };
    this.modalConfirm.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirm.open('sm');
    modalRef.closed.subscribe((comfirm: boolean) => {
      if (comfirm) {
        this.userDriverLicences.splice(this.userDriverLicences.indexOf(this.deleteItem), 1);
      }
      this.deleteItem = null;
    })
  }

  openmodaladdfleetdriverform(driverid: number) {
    this.modaladdfleetdriverform.modalsize = undefined;
    this.modaladdfleetdriverform.driver = driverid;
    const modalRef = this.modaladdfleetdriverform.openmodal(this.modaladdfleetdriverform.modalsize);
    modalRef.closed.subscribe(res => {
      if (!res) { this.AfterSubmit.emit(true) }
    })
  }

  openmodalsuccess() {
    var head = this.IsEdit ? "แก้ไขข้อมูล" : "เพิ่ม";
    let modalValue = this.IsDriver ? 'คนขับ' : 'ผู้ใช้งาน'
    this.modalsuccess.modalsize = 'sm';
    this.modalsuccess.title_icon = 'fa-user-circle';
    this.modalsuccess.body_title = `${head}${modalValue}เรียบร้อย`;
    this.modalsuccess.btnother_ishide = false;
    this.modalsuccess.btnother_text = 'ปิด';

    if (!this.IsEdit) {
      this.modalsuccess.body_text = "test body_text";
    }
    const modalRef = this.modalsuccess.openmodal(this.modalsuccess.modalsize);
    modalRef.closed.subscribe(e => {
      this.AfterSubmit.emit(true)
    })
  }

  openmodaladdsuccess() {
    var formvalue = this.userform.value;
    console.log(formvalue)
    let isMobile = formvalue.recievePasswordChanel == 1;

    var head = this.IsEdit ? "แก้ไขข้อมูล" : "เพิ่ม";
    let modalValue = this.IsDriver ? 'คนขับ' : 'ผู้ใช้งาน'
    let recievePasswordChanel = isMobile ? 'เบอร์มือถือ' : 'อีเมล';
    let recievePasswordChanel2 = isMobile ? 'SMS' : 'อีเมล';
    this.modaladdsuccess.modalsize = 'lg';
    this.modaladdsuccess.title_icon = 'fa-check-circle';
    this.modaladdsuccess.btnother_text = 'ปิด';
    this.modaladdsuccess.body_title = `${head}${modalValue}เรียบร้อย`;
    if (!this.IsEdit) {
      this.modaladdsuccess.body_text = `ระบบได้ส่ง Username และ Password ไปยัง${recievePasswordChanel}ของผู้ใช้งานแล้ว`;

      this.modaladdsuccess.body_text2 = `คุณ  ${formvalue.firstname}  ${formvalue.lastname} ได้ถูกเพิ่มเป็นผู้ใช้งานสิทธิ์ระดับ ${this.roleSelected} ในบัญชี  ${this.currentUser.client.clientName} เรียบร้อยแล้ว \n

      Username: ${formvalue.mobileNumber} \n
      Password: โปรดตรวจสอบใน ${recievePasswordChanel2} \n

      เข้าใช้งานเว็บไซต์ที่ https://tms.360techx.co \n
      เข้าใช้งานผ่าน 360TRUCKer Moblie App ที่ http://onelink.to/atgbvq
      `
    }


    this.modaladdsuccess.btnsubmit_text = 'ปิดหน้านี้';

    const modalRef = this.modaladdsuccess.openmodal(this.modaladdsuccess.modalsize);
    modalRef.closed.subscribe(e => {
      this.AfterSubmit.emit(true);
    })
  }

  inputChange(event) {
    console.log(event);
  }

  dateCorrect() {
    const control = (<FormControl>(
      (<FormControl>this.userform.get('birthDate'))));
    return control.valid
  }

  setAuthlevelByClientRole(item) {
    console.log(item);
    this.roleSelected = item.roleName;
    var authId = 1;
    // this.IsDriver = false;

    if (item.roleID == 9) {
      authId = 3;
      this.driverlicenseReq = true;
      this.IsDriver = true;
      this.userform.controls["teamIds"].clearValidators();
    } else if (item.roleID == 10) {
      authId = 2;
      this.driverlicenseReq = false;
      this.IsDriver = false;
    } else if (item.roleID == 11) {
      authId = 1;
      this.driverlicenseReq = false;
      this.IsDriver = false;
    }
    if (this.clientModule_70200?.pkg_id > 20 && !this.IsDriver) {
      this.userform.controls["teamIds"].setValidators([Validators.required]);
    }
    this.userform.controls["teamIds"].updateValueAndValidity();
    // console.log('this.userform', this.userform)

    //console.log(authId);
    this.roleItem = item;
    this.userform.controls.authorizeID.setValue(authId);
    this.userform.controls.departmentId.setValue(item.departmentID);
    this.checkUsedMobileNo();

    let date = this.userform.value.birthDate
    if (date) {
      let convertDate = `${date.year - 543}-${date.month}-${date.day}`;
      var bd = new Date(date.year - 543, date.month - 1, date.day);


      console.log('this.userform', this.userform)

      if (!this.calculateAge(bd)) {
        this.userform.controls['birthDate'].setErrors({ 'incorrect': true });
      } else {
        this.userform.controls['birthDate'].setErrors(null);
      }
    }
  }
  recievePasswordChanelChanges(chanel: number) {
    console.log('change ', chanel)
    console.log(this.userform.controls["email"])

    var emailcontrol = this.userform.controls["email"];
    chanel == 2 ? emailcontrol.setValidators(Validators.required) : emailcontrol.setValidators(null);

    emailcontrol.updateValueAndValidity();
    console.log(emailcontrol)
  }
  photoURLChange(url) {
    console.log(url)
    this.profileImageUri = url;
    this.userform.controls.photoURL.setValue(url);

    console.log(this.userform.value)
  }

  calculateAge(birthday) { // birthday is a date
    var currentDate = new Date();

    var minDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    if (this.userform.controls.authorizeID.value != 3 && !this.IsDriver) {
      return true
    }
    if (birthday > minDate) {
      return false
    }
    return true
  }
  success_other(value: any) {
    this.modalsuccess.modalReference?.close();
    this.modaladdsuccess.modalReference?.close();
  }


}
