<div class="row">
    <div class="col-12">
        <button class="btn btn-success" (click)="onCreate()" [disabled]="selectedBooking.length==0">
          สร้างการจองรถ
        </button>
    </div>
    <div class="col-12 pt-1" *ngFor="let item of bookingData">
        <app-booking-shipment-item [data]="item" [shipmentData]="shipmentData(item)" [isExfac]="isExfac"
        (selected_event)="onselect($event)"
        ></app-booking-shipment-item>
    </div>
    <div class="col-12 text-center" *ngIf="bookingData.length ==0">
        ไม่พบข้อมูล Shipment
    </div>

</div>
