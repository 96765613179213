import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/_Services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class NoficationserviceService {

  constructor(private _apiService: HttpService) { }
  destroy$: Subject<boolean> = new Subject<boolean>();

  async GetGroupLine(userid:number):Promise<any> {
    return new Promise((resolve,reject)=>{
      this._apiService
      .get(`/SettingNoti/GroupLine/${userid}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any[]) => {
        resolve(data);       
      });
    }); 
  }

   GetCarrier(userid:number): Observable<any> {
    return this._apiService.get(`/SettingNoti/GetCarrier/${userid}`);
    // return new Promise((resolve,reject)=>{
    //   this._apiService
    //   .get(`/SettingNoti/GetCarrier/${userid}`)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((data: any[]) => {
    //     resolve(data);       
    //   });
    // }); 
  }

  updateCarrierSetting(Id: string,name:string,form: any): Observable<any> {
    return this._apiService.patch(`/SettingNoti/UpdateCarrier/${Id}/${name}`, form);
  }

  updateGroupline(Id: number,name:string,data:any): Observable<any> {
    return this._apiService.patch(`/SettingNoti/GroupLine/${Id}/${name}`, data);
    // return new Promise((resolve, reject) => {
    //   this._apiService
    //     .patch(`/SettingNoti/GroupLine/${Id}/${name}`,data)
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe((data: any[]) => {
    //       resolve(data);
    //     });
    // });
  }
}
