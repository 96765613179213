import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { User } from '@app/core/_Models/UserModel/User';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { ClientUserService } from '@app/modules/team/services/client-user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { FleetDriverResponse } from '../../models/driver-detail-response.model';

@Component({
  selector: 'app-profile-upload',
  templateUrl: './profile-upload.component.html',
  styleUrls: ['./profile-upload.component.scss']
})
export class ProfileUploadComponent implements OnInit, OnChanges {
  @Input() set setFleetDriver(fleetDriver: FleetDriverResponse) {
    console.log('setFleetDriver', fleetDriver);

    this._items = fleetDriver;
  }
  @Input() canChange :boolean = true;
  // get items() {
  //   return this._items.getValue();
  // }
  // @Input() fleetDriver = new FleetDriverResponse();
  profileImage: string = '';
  @Output() refresh = new EventEmitter<boolean>();
  @Output() photoFile = new EventEmitter<any>();
  @Output() photoURL = new EventEmitter<any>();
  images = [
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/male-1.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/male-2.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/male-3.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/male-4.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/male-5.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/male-6.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-1.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-2.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-3.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-4.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-5.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-6.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-7.png',
    'https://tst-web-public.s3.ap-southeast-1.amazonaws.com/avatar/women-8.png',
  ];

  selected!: string | undefined;

  defaultImageProfileUrl: string = 'assets/img/demo/avatars/avatar-m.png';
  uploadImage!: string | undefined;

  private _items: FleetDriverResponse;
  fleetDriver: FleetDriverResponse
  file: File[] = [];
  currentUser: User;
  permission130100: PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  modalReference!: NgbModalRef;
  @ViewChild("uploadProfile", { static: true }) uploadProfile!: ProfileUploadComponent;

  constructor(
    private modalService: NgbModal,
    public authService: AuthenticationService,
    private _ClientUserService: ClientUserService,
    private _noti: NotificationService,
    // private pageLoading: PageloadingService,
    private changeRef: ChangeDetectorRef,

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    // this._items.subscribe(x => {
    //   console.log("init profile upload", x);
    //   this.fleetDriver = x
    //   this.profileImage = x.photoURL
    //   this.uploadImage = x.photoURL;
    // })
    this.fleetDriver = this._items;
    this.profileImage = this._items?.photoURL || this.defaultImageProfileUrl
    this.uploadImage = this._items?.photoURL || this.defaultImageProfileUrl

    console.log("ngOnChangesthis.profileImage", this.profileImage);
    this.changeRef.detectChanges();
  }

  ngOnInit(): void {
    this.profileImage = this.defaultImageProfileUrl;
    this.authService.currentUser.subscribe((x) => {
      this.currentUser = x;
      var p2: PermissionModule = this.authService.UserPermissionModule(130000);
      console.log('permission p2', p2);
      if (p2) {
        this.permission130100 = this.authService.FindPermissionAction(
          130100,
          p2.operations
        );
      }

    });

    // this._items.subscribe(x => {
    //   console.log("init profile upload", x);
    //   this.fleetDriver = x
    //   this.profileImage = x.photoURL
    //   this.uploadImage = x.photoURL;
    // })
    this.fleetDriver = this._items;
    this.profileImage = this._items.photoURL || this.defaultImageProfileUrl
    this.uploadImage = this._items.photoURL || this.defaultImageProfileUrl
  }

  openLg(content: any) {
    this.modalReference = this.modalService.open(content, { size: 'lg' });
  }

  selectedImg(img: string) {
    if (img.includes('tst-web')) {
      console.log('onclick tst-web');
      this.file = [];
    }
    console.log('selectedImg', img);

    this.selected = img;
    this.uploadImage = this.selected
  }

  onFileUpload(files: File[]) {
    this.file = files;
    this.selected = null;
    files.map((file, index) => {
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        // this.msg = "Only images are supported";
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.uploadImage = reader.result?.toString() || '';
        this.fleetDriver.photoURL = this.uploadImage;
        this.selected =  this.uploadImage;
      };
    });
  }

  onSaveImageProfile(): void {
    console.log('file', this.file);
    if (this.fleetDriver.userID > 0) {
      if (this.file.length > 0) {
        const _file = this.file[0];
        if (_file) {
          //this.pageLoading.show();
          const formData = new FormData();
          const imgPath = 'c' + this.fleetDriver.userID;
          const fileExt = _file.name.substring(
            _file.name.lastIndexOf('.')
          );
          const imgName = 'u' + this.fleetDriver.userID + '-profile' + moment(new Date).format('YYYYMMDDHHmmss') + fileExt;

          formData.append('file', _file);
          formData.append('newpath', imgPath);
          formData.append('filename', imgName);
          formData.append('uid', `${this.fleetDriver.userID}`);
          formData.append('companyId', `${this.currentUser.tstS360_CompanyID}`);

          this._ClientUserService
            .UploadUserImage(formData)
            .subscribe((data) => {
              console.log('upload image data', data);
              this.uploadImage = data.path;
              this.fleetDriver.photoURL = data.path;
              this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
              //this.pageLoading.hide();
              this.modalReference.close(true);
              this.profileImage = data.path;
              this.selected =  data.path;
              this.photoURL.emit(data.path)
              this.refresh.emit(true);
            });
        } else {
          this._noti.toastwarning('กรุณาเลือกรูปภาพ', '');
        }
      }
      else if (this.selected) {
        const data = {
          userId: this.fleetDriver.userID,
          companyId: this.currentUser.tstS360_CompanyID,
          imgPath: this.selected
        }
        console.log('data', data);
        //this.pageLoading.show();
        this._ClientUserService
          .UpdateUserImageAvatar(data)
          .subscribe((data) => {
          this.profileImage =  this.selected;
            console.log('upload image data', data);
            this.uploadImage = this.selected
            this.fleetDriver.photoURL = this.selected
            this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
            //this.pageLoading.hide();
            this.modalReference.close(true);
            this.photoURL.emit(this.profileImage)
            this.refresh.emit(true);
          });
        //this.pageLoading.hide();
      }
    } else {
      if(this.file.length > 0){
        this.modalReference.close(true);
        this.profileImage =  this.selected;
        this.photoFile.emit(this.file)
      }else{

        this.profileImage =  this.selected;
        this.modalReference.close(true);
        this.photoURL.emit(this.profileImage)
      }
    }
  }
}
