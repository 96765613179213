import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataImportIncorrectListColumns } from './data-import-incorrect-list-columns';
declare function initDataTable(tableID, param1, param2, param3, param4): any;
declare function  ChangeDataTablesEmptyText(tableId, textEmpty)
@Component({
  selector: 'app-data-import-incorrect-list',
  templateUrl: './data-import-incorrect-list.component.html',
  styleUrls: ['./data-import-incorrect-list.component.scss']
})
export class DataImportIncorrectListComponent implements OnInit, OnChanges {
  dataImportIncorrectListColumns = DataImportIncorrectListColumns
  @Input() dataIncorrectList: any[] = [];
  private tableWidget: any;

  constructor(private changeRef: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataIncorrectList.currentValue.length <= 0) {
      ChangeDataTablesEmptyText('dataIncorrectTable', "ไม่พบข้อผิดพลาด")
    }
  }


  ngOnInit() {
    this.reInitDatatable();
  }
  private reInitDatatable(): void {
    // console.log(this.tableWidget);
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
      this.changeRef.detectChanges();
    }

    setTimeout(
      () => {
        (this.tableWidget = initDataTable(
          'dataIncorrectTable',
          false,
          false,
          false,
          false
        ))
        if (this.dataIncorrectList?.length <= 0) {
          ChangeDataTablesEmptyText('dataIncorrectTable', "ไม่พบข้อผิดพลาด")
        }
      },
      1
    );
  }
}
