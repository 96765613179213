import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService } from '@app/core/_Services';
import { CompanyModel, UpdateClientUserDto } from '../../models/Company';
import { GetUserService } from '../../services/get-user.service';
declare function closeModal(modalID): any;
@Component({
  selector: 'app-modal-editcompany',
  templateUrl: './modal-editcompany.component.html',
  styleUrls: ['./modal-editcompany.component.scss']
})
export class ModalEditcompanyComponent implements OnInit, OnChanges {
  user: User;
  constructor(private usersersive: GetUserService, private auth: AuthenticationService) {
    this.user = this.auth.currentUserValue;
  }
  ngOnChanges() {
    this.setform();
  }
  @Input() Company: any;
  @Output() onSave: EventEmitter<boolean> = new EventEmitter();
  typecom: any;
  typeproduct: any;
  imagefile: File;
  profileImageUri: string;
  IsSaveing:boolean = false;
  ngOnInit(): void {
    this.setTypecompany();
  }
  Type = [
    { id: 1, name: 'A' },
    { id: 2, name: 'B' },
  ];
  companyform = new FormGroup({
    NameTH: new FormControl(),
    NameEN: new FormControl(null, Validators.required),
    Email: new FormControl(),
    Phone: new FormControl(),
    TypeCompany: new FormControl(),
    TypeProduct: new FormControl(),
  });
  shipping: number;
  billing: number;
  setform() {
    if (this.Company != null) {
      this.companyform.controls.NameTH.setValue(this.Company?.data.companyNameTH);
      this.companyform.controls.NameEN.setValue(this.Company?.data.companyNameEN);
      this.companyform.controls.Email.setValue(this.Company?.data.email);
      this.companyform.controls.Phone.setValue(this.Company?.data.phone);
      this.companyform.controls.TypeCompany.setValue(this.Company?.data.typeCompanyID);
      this.companyform.controls.TypeProduct.setValue(this.Company?.data.typeProductID);
      this.shipping = this.Company?.data.companyNameTH;
      this.billing = this.Company?.data.companyNameEN;
      this.profileImageUri = this.Company?.data.image;
    }
  }
  async setTypecompany() {
    this.typecom = this.usersersive.GetTypeCompany();
    this.typeproduct = this.usersersive.GetProductgroup();
  }

  async onSubmit() {
    this.IsSaveing = true;
    let formModel = new CompanyModel();
    formModel.CompanyNameTH = this.companyform.value.NameTH;
    formModel.CompanyNameEN = this.companyform.value.NameEN;
    formModel.Email = this.companyform.value.Email;
    formModel.phone = this.companyform.value.Phone;
    formModel.typeCompanyID = this.companyform.value.TypeCompany;
    formModel.typeProductID = this.companyform.value.TypeProduct;
    await this.usersersive.updateCompany(this.Company?.data.id, "admin", formModel);

    if (this.imagefile != null) {
      if (this.imagefile.size > 0) {
        let S3Path = 'client/' + this.user.ClientID + '/client_logo/';
        const fileExt = this.imagefile.name.substring(this.imagefile.name.lastIndexOf('.'));
        let newfilename = 'logo'+ '_' + new Date().valueOf() + fileExt;
        let formdata = new FormData();
        formdata.append("filepath", S3Path);
        formdata.append("name", newfilename);
        formdata.append("image", this.imagefile);
        formdata.append("ID", this.user.ClientID.toString())
        await this.usersersive.Uploadlogo(formdata).subscribe
        (
          (data) => {
            this.onSave.emit(true);  
            closeModal("#Editcompany");
            this.IsSaveing = false;
          }
        ); 
      }else
      {
        this.onSave.emit(true);
        closeModal("#Editcompany");
        this.IsSaveing = false;
      }
    }else{
      this.onSave.emit(true);
      closeModal("#Editcompany");
      this.IsSaveing = false;
    }  
    
  }

  handleFileInput(event: any) {
    let file = event.target.files[0];
    if (file) {
      this.imagefile = file;
      const base64Type = 'data:' + this.imagefile.type + ';base64,';
      this.profileImageUri = base64Type;
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;

    this.profileImageUri += btoa(binaryString);
  }
}
