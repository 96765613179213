
import { Customercontact } from './CustomerContact';
import { CustomerAddress } from './CustomerAddress';
import { Customernote } from './CustomerNote';

export class customer {
    customerNumber: string;
    customerName: string;
    isActive: boolean;
    clientID: number;
    CreatedBy: string;
    customerContacts:Customercontact[];
    customerAddresses:CustomerAddress[];
    customerRemarks:Customernote[];
    Id: number;
}


export const CustomerHeaders = [
    'customerNumber',
    'customerName',
    'position',
    'contactName',
    'phoneNumber',
    'email'
  ];

  
export class ImportCustomerExcelRequest {
    CliendId: number;
    UserId: number;
    ExcelData: ICustomerImportModel[] = [];
  }
export class customers {
    customers: customer[];
}

export interface ICustomerImportModel{
    customerNumber: string;
    customerName: string;
    position:string;
    contactName:string;
    phoneNumber:string;
    email:string;
}

