<div class="row">
    <div class="col-12">

        <div class="row">
            <div class="col-4 col-md-auto mt-2 mt-md-0 text-right"> เลือกโรงงานต้นทาง </div>
            <div class="col-8 col-md-6 mt-1  mt-md-0">
                <ng-select [items]="dataCompanylocation" [(ngModel)]="filterClientLocation" (change)="LocChange()"
                bindLabel="name" bindValue="id">

                </ng-select>
            </div>
        </div>
        <div class="row" *ngIf="filterClientLocation > 0">
            <div class="col-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Time (24h format)</th>
                            <th scope="col">Load Limit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let load of loadLimits" scope="row">
                            <td>{{ load.time }}</td>
                            <td>
                                <input type="number" class="form-control" [(ngModel)]="load.size" (change)="onLimitChange(load,$event)">
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
        </div>

    </div>
</div>