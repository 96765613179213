import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/core/_Services';

@Component({
  selector: 'app-modal-add-driver',
  templateUrl: './modal-add-driver.component.html',
  styleUrls: ['./modal-add-driver.component.scss']
})
export class ModalAddDriverComponent implements OnInit {
  currentStep=1;
  permission=[{id:1, name: "Driver"}];
  team=[{id:1, name: "Xrider"}];
  department=[{id:1, name: "SupplyChain"}];
  p=1;
  fleetList=[1,2,3]
    
    constructor(private noti: NotificationService,) { }

  ngOnInit(): void {
  }


  DateChange(event: any): void {
    console.log(event)
  }

  save(){
    this.currentStep = 2;
  }

  fleetSave(){
    this.noti.toastsuccess("สำเร็จ", 'เพิ่มคนขับเข้า FLEET เรียบร้อยแล้ว');
  }
}
