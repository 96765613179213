import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserDocVerifyStatusService } from '@app/core/_Services/user-doc-verify-status.service';

@Component({
  selector: 'app-driver-list-filter',
  templateUrl: './driver-list-filter.component.html',
  styleUrls: ['./driver-list-filter.component.scss']
})
export class DriverListFilterComponent implements OnInit {

  @Output() searchClick = new EventEmitter<any>();
  @Output() clearClick = new EventEmitter<any>();

  searchFilterForm = new FormGroup({
    textSearch: new FormControl(null),
    isEnabled: new FormControl(null),
    driverLicenceStatusId: new FormControl(null),
    shipmentStatusId: new FormControl(null),
  })

  driverStatus = [
    { id: 'true', name: 'เปิดใช้งาน' },
    { id: 'false', name: 'ระงับการใช้งาน' }
  ];

  shipmentStatusId = [
    { id: 99, name: 'ว่าง' },
    { id: 20, name: 'ไม่ว่าง' }
  ];

  driverLicenceStatusId:any

  constructor(private userDocVerifyStatusService: UserDocVerifyStatusService) { }

  ngOnInit(): void {
    this.getUserDocVerifyStatus()
  }

  formInit() {
    this.searchFilterForm = new FormGroup({
      textSearch: new FormControl(null),
      isEnabled: new FormControl(null),
      driverLicenceStatusId: new FormControl(null),
      shipmentStatusId: new FormControl(null)
    })
  }

  searched() {
    this.searchClick.emit(this.searchFilterForm.value)
  }

  cleared() {
    this.searchFilterForm.reset()
    this.formInit();

    this.clearClick.emit()
  }

  async getUserDocVerifyStatus(){
    await this.userDocVerifyStatusService.getDocVerifyStatus()
    .subscribe(res=>{
      if(res.isSuccess){
        console.log('getUserDocVerifyStatus',res.data);
        this.driverLicenceStatusId = res.data
      }
    })
  }
}