import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnbordingApiPath } from '../_Constants/apiConstants';
import { onbordingDto } from '../_Models/onbording';
import { User } from '../_Models/UserModel/User';
import { AuthenticationService } from './authentication.service';
import { HttpService } from './http.service';
import { IntrojsService } from './introjs.service';

@Injectable({
  providedIn: 'root'
})
export class OnbordingRecordService {


  user : User;
  constructor(   private _apiService: HttpService,
    private authenticationService: AuthenticationService,) {
      this.authenticationService.currentUser.subscribe(a=>{
        this.user = a;
      })
     }


  
  InsertOnbordingRecord(pageId,stepNumber){

    var data = {
      record :{
        CurrentStep : stepNumber,
        OnboardingPageId : pageId,
        UserId : this.user.id
      }
     
    };

    this._apiService.postWithoutException(
      OnbordingApiPath.GetCurrentOnbordingRecord  , data
    ).subscribe((res)=>{

      console.log("res",res);
    }
    );
  }
}
