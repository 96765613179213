import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './pages/profile/profile.component';
import { MaterialsModule } from '../materials/materials.module';
import { ModaleditprofileComponent } from './components/modaleditprofile/modaleditprofile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalchangepasswordComponent } from './components/modalchangepassword/modalchangepassword.component';
import { CompandetailComponent } from './components/compandetail/compandetail.component';
import { ModalEditcompanyComponent } from './components/modal-editcompany/modal-editcompany.component';
import { ModaleditlocationComponent } from './components/modaleditlocation/modaleditlocation.component';
import { TeamModule } from '../team/team.module';




import {GetUserService} from "@app/modules/usermanage/services/get-user.service";

@NgModule({
  declarations: [
    ProfileComponent,
    ModaleditprofileComponent,
    ModalchangepasswordComponent,
    CompandetailComponent,
    ModalEditcompanyComponent,
    ModaleditlocationComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialsModule,
    NgSelectModule,
    ReactiveFormsModule,
    TeamModule,
  ],
  providers:[GetUserService]
})
export class UsermanageModule { }
