import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PageloadingService } from 'src/app/core/_Services';
@Component({
  selector: 'app-notitms',
  templateUrl: './notitms.component.html',
  styleUrls: ['./notitms.component.scss']
})
export class NotitmsComponent implements OnInit {
  @ViewChild('notiCarrier') notiCarrier: ElementRef<HTMLAnchorElement>;
  @ViewChild('notiReport') notiReport: ElementRef<HTMLAnchorElement>;
  @ViewChild('lineGroup') lineGroup: ElementRef<HTMLAnchorElement>;
  behaviorTitle = new BehaviorSubject<string>('notiCarrier');
  title: string;
  constructor(private route: ActivatedRoute,
    private dectech: ChangeDetectorRef) {
    // pageload.hide();
  }
  tabSelected: number = 0;
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((data) => {
      if (data) {
        if(data && data.t){
        this.behaviorTitle.next(data.t);
      }
      }
    });

    this.behaviorTitle.subscribe((title) => {
      this.title = title;
      this.dectech.detectChanges();
      if (this.title) {
        switch (this.title) {
          case 'notiCarrier': {
            this.notiCarrier.nativeElement.click();
            break;
          }
          case 'notiReport': {
            this.notiReport.nativeElement.click();
            break;
          }
          case 'lineGroup':{
            this.lineGroup.nativeElement.click();
            break;
          }
          default: {
            break;
          }
        }
        this.dectech.detectChanges();
      }
    });
  }
  ngOnInit(): void {
  }

}
