<div class="custom-ngx-dropzone" >
    <ngx-dropzone #drop (change)="onSelect($event)" [maxFileSize]="maxDataFileSize" [accept]="acceptType"
        [multiple]="multiple">
        <ngx-dropzone-label>{{placeholder}}
            <small>({{maxSizePlacehoder}})</small>
        </ngx-dropzone-label>
    </ngx-dropzone>
</div>
<div *ngIf="files?.length && showPreview" class="custom-ngx-dropzone-preview">
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true"
        (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ (f.size/1024)/1204 | number : '1.2-2'}} MB.)</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
</div>
