<div class="row">
    <div class="col-12 tableFixHead">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>OrderNo</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>QTY</th>
                    <th>NOTE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of backlogData">
                    <td>
                        {{item.order?.order_number}}
                    </td>
                    <td>
                        {{item?.order?.origin.code}}
                        {{item?.order?.origin.name}} 
                    </td>
                    <td>
                        {{item?.order.destination?.code}}
                        {{item?.order.destination?.name}} 
                    </td>
                    <td>
                        {{item?.order?.totalQTY}}
                    </td>
                    <td>
                        {{item?.message}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>