import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService } from '@app/core/_Services';
import { GetUserService } from '../../services/get-user.service';
declare function closeModal(modalID): any;
@Component({
  selector: 'app-modalchangepassword',
  templateUrl: './modalchangepassword.component.html',
  styleUrls: ['./modalchangepassword.component.scss']
})
export class ModalchangepasswordComponent implements OnInit, OnChanges {
  user: User;
  constructor(private usersersive: GetUserService, private _noti: NotificationService, private auth: AuthenticationService) {
    this.user = this.auth.currentUserValue;
  }
  checkpassword: string;

  ngOnInit(): void {
    this.setform();
  }

  ngOnChanges() {
   
  }

  Passwordform = new FormGroup({
    passwordcurrent: new FormControl(),
    txtnewpassword: new FormControl(),
    txtconpassword: new FormControl(),
  });

  setform() {
    this.Passwordform = new FormGroup({
      passwordcurrent: new FormControl(null, Validators.required),
      txtnewpassword: new FormControl(null, Validators.required),
      txtconpassword: new FormControl(null, Validators.required),
    });
  }

  clearform() {
    this.Passwordform.controls.passwordcurrent.reset();
    this.Passwordform.controls.txtnewpassword.reset();
    this.Passwordform.controls.txtconpassword.reset();
  }

  async onSubmit() {
    let oldpassword = this.Passwordform.value.passwordcurrent;
    let newpassword = this.Passwordform.value.txtnewpassword;
    let conpassword = this.Passwordform.value.txtconpassword;
    if (newpassword == conpassword && oldpassword != null) {
      this.usersersive.ChangePassword(this.user.id, newpassword, oldpassword).subscribe
        (
          (data) => {
            if (data?.isSuccess) {
              if (data?.data == "Old password not matched!") {
                this._noti.toasterror('', data?.data);
              } else {
                this._noti.toastsuccess('', 'บันทึกสำเร็จ');
                this.clearform();
                closeModal("#changepassword");
              }
            } else {
              this._noti.toasterror('', data?.data);
            }
          }
        );

    } else {
      this._noti.toasterror('', 'รหัสผ่านไม่ตรงกัน');
    }

  }
}
