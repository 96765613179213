
<!-- Modal -->
<div class="modal fade modal-xl " id="order-detail" role="dialog" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true"
style="left:15%">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content ">
                <div class="modal-header">
                        <h5 class="modal-title">Task Details</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
            <div class="modal-body">
                <div class="container-fluid">
                 
                    <div class="row">
                        <div class="col-12">
                        <div class="row">
                            <div class="col-md-3 col-6">
                                <h3>Action</h3>
                            </div>
                            <div class="col-md-3 col-6">
                                <span>{{data?.name}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-6">
                                <h3>Period</h3>
                            </div>
                            <div class="col-md-3 col-6">
                                <span>{{data?.startHH}} to {{data?.endHH-1}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-6">
                                <h3>Total QTY</h3>
                            </div>
                            <div class="col-md-3 col-6">
                                <span>{{data?.totalQTY | number:'1.0-3'}} / {{data?.maxQTY}}</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h3>Orders</h3>
                        </div>
                        <div class="col-12">
                            <app-tab-load-order [LoadOrderData]="data?.orders"></app-tab-load-order>
                        </div>
                    </div>
     
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
