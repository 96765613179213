import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TMSFileuploadComponent } from './tmsfileupload.component';
import { FileManageComponent } from '../file-manage/file-manage.component';
import { ModalImportFileComponent } from '../modal-import-file/modal-import-file.component';
import { CustomDropzoneImagePreviewComponent } from '../ngxdropzone/custom-dropzone-image-preview/custom-dropzone-image-preview.component';
import { ModalPreviewImportDataComponent } from '../modal-preview-import-data/modal-preview-import-data.component';
import { DataImportIncorrectListComponent } from '../data-import-incorrect-list/data-import-incorrect-list.component';
import { ModalImportResultComponent } from '../modal-import-result/modal-import-result.component';



@NgModule({
  declarations: [
    TMSFileuploadComponent,
    FileManageComponent,
    ModalImportFileComponent,
    CustomDropzoneImagePreviewComponent,
    ModalPreviewImportDataComponent,
    DataImportIncorrectListComponent,
    ModalImportResultComponent
  ],
  imports: [
    CommonModule,
    NgxDropzoneModule,
  ],
  exports:[
    TMSFileuploadComponent,
    FileManageComponent,
    ModalImportFileComponent,
    CustomDropzoneImagePreviewComponent,
    ModalPreviewImportDataComponent,
    ModalImportResultComponent
  ]
})
export class TMSFileuploadModule { }
