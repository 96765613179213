<div class="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
    <h1 class="page-error color-fusion-500">
        ERROR <span class="text-gradient">404</span>
        <small class="fw-500">
            มีบางอย่าง <u>ผิดพลาด</u>!
        </small>
    </h1>
    <h4>
      ไม่พบหน้าที่ท่านต้องการ หรือ ท่านไม่มีสิทธิเข้าถึง
        <br>
    
    </h4>
</div>