import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { AuthenticationService, HttpService, NotificationService, PageloadingService } from '@app/core/_Services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DriverImportHeaders, IDriverImportModel, ImportDriverExcelRequest } from '../models/driver.model';
import * as XLSX from 'xlsx';
import { ClientAPIPath, UserApiPath } from '@app/core/_Constants/apiConstants';
import { User } from '@app/core/_Models/UserModel/User';
@Injectable({
  providedIn: 'root'
})
export class AddDriverExcelService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private apiService: HttpService,
    private authen: AuthenticationService,
    private httpClient: HttpClient,
    // private pageload: PageloadingService,
    private _noti: NotificationService,

  ) {


  }

  currentUser: User

  import(file: File): Promise<TMSAPIResponse> {
    //this.pageload.show();
    let DriverImport: IDriverImportModel[] = [];
    let isFileValid = true;
    return this.getDataFile(file).then(data => {
      DriverImport = data;
      return new Promise((resolve, reject) => {
        if (DriverImport.length == 0) {
          //this.pageload.hide();
          resolve({
            isSuccess: false,
            statusCode: 400,
            message: 'ไม่พบข้อมูลในไฟล์',
            errorDetail: '',
            data: [],
            dataTotalCount: 0
          });

        } else {
          // DriverImport.map(element => {
          //   var dateString: any = element.birthDate;
          //   var newDate = new Date(dateString);
          //   console.log(newDate, dateString, this.isCorrectDate(newDate),dateString.includes('undefined'))
          //   if (!this.isCorrectDate(newDate) || dateString.includes('undefined')) {
          //     //this.pageload.hide();
          //     isFileValid = false;
          //     resolve({
          //       isSuccess: false,
          //       statusCode: 400,
          //       message: 'ข้อมูลในไฟล์ไม่ถูกต้อง',
          //       errorDetail: '',
          //       data: [],
          //       dataTotalCount: 0
          //     });
          //   }
          // });
          console.log(DriverImport)
          const request = new ImportDriverExcelRequest;
          request.ExcelData = DriverImport;
          let companyId = 0;
          this.authen.currentUser.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            companyId = res.tstS360_CompanyID;
            this.currentUser = res;
            request.CliendId = this.currentUser.ClientID;
            request.UserId = this.currentUser.id;
            request.CompanyId = this.currentUser.tstS360_CompanyID;
            request.ServiceTypeId = this.currentUser.registerPackage.serviceTypeId

            if (isFileValid) {
              this.apiService.post(ClientAPIPath.ValidateDriverExcel, { RequestData: request })
                .pipe(takeUntil(this.destroy$))
                .subscribe((res: TMSAPIResponse) => {
                  console.log(res.data)
                  //this.pageload.hide();
                  resolve(res);
                });

            } else {
              //this.pageload.hide();
            }

          });
        }
      })
    });
  }

  onConfirmImport(requestList: IDriverImportModel[]): Promise<TMSAPIResponse> {
    //this.pageload.show();


    const request = new ImportDriverExcelRequest;
    let companyId = 0;
    return new Promise((resolve, reject) => {
      let companyId = 0;
      let actionBy = 0;
      this.authen.currentUser.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        companyId = res.tstS360_CompanyID;
        this.currentUser = res;
        request.CliendId = this.currentUser.ClientID;
        request.UserId = this.currentUser.id;
        request.CompanyId = this.currentUser.tstS360_CompanyID;
        request.ServiceTypeId = this.currentUser.registerPackage.serviceTypeId;
        request.ExcelData = requestList;

        this.apiService.post(ClientAPIPath.AddDriverListByExcel, { RequestData: request })
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: TMSAPIResponse) => {
            console.log(res.data)
            //this.pageload.hide();
            if(res.isSuccess){
              this._noti.toastsuccess('', "เพิ่มรายการคนขับสำเร็จ");
    }
            resolve(res);
          });
      })

    })
  }

  getDataFile(file: File): Promise<IDriverImportModel[]> {
    const keys = DriverImportHeaders;
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', cellText: true, cellDates: true });
        // console.log('wb.Workbook.WBProps.date1904',wb.Workbook.WBProps.date1904);

        // wb.Workbook.WBProps.date1904
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        let data = XLSX.utils.sheet_to_json(ws, { range: 1, header: keys, raw: false, dateNF: 'yyyy/mmm/dd' }) as IDriverImportModel[];
        const rowIndex = 2;
        data = data.map((e, index) => {
          return {
            ...e,
            birthDate: this.parseDate(e.birthDate),
            rowIndex: rowIndex + index,
            mobileNo: e.mobileNo ? e.mobileNo.replace(/\D/g,'') : null
          }
        })
        console.log('data', data);
        resolve(data);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    })
  }

  private parseDate(date: string): string {
    const DELIMITER = '/';

    if (date) {
      let newDate = date.split(DELIMITER);
      console.log("newDate", newDate)
      if (newDate.length <= 2) {
        console.log("1", `${newDate[1]}-${newDate[0]}-01`)
        return `${newDate[1]}-${newDate[0]}-01`
      } else {
        newDate[2] = newDate[2].length <= 2 ? "25" + newDate[2] : newDate[2]

        var BE = Number(newDate[2]);
        if (Number(newDate[1]) > 12) {
          return `${BE - 543}-${newDate[0]}-${newDate[1]}`
        }
        return `${BE - 543}-${newDate[1]}-${newDate[0]}`
      }
    }
    return null;
  }

  private parseDate2(date: string): string {
    const DELIMITER = '/';
    if (date) {
      let newDate = date.split(DELIMITER);
      if (newDate.length <= 2) {
        return `${newDate[1]}-${newDate[0]}-01`
      } else {
        newDate[2] = newDate[2].length <= 2 ? "25" + newDate[2] : newDate[2]
        if (Number(newDate[1]) > 12) {
          return `${newDate[2]}-${newDate[0]}-${newDate[1]}`
        }
        return `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      }
    }
    return null;
  }

  isCorrectDate(date) {
    return date instanceof Date && isFinite(date as any);

    // return date instanceof Date && !isNaN(date);
  }
}

