import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TstImageItemModel } from '@app/core/_Models/TstImageItemModel';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { CustomNgbDateParserFormatterService } from '@app/core/_Services/NgbDatepickerService/CustomNgbDateParserFormatter.service';
import { NgbDatepickerI18nBuddhistService } from '@app/core/_Services/NgbDatepickerService/NgbDatepickerI18nBuddhist.service';
import { ModalConfirmComponent } from '@app/shared/Components/modal-confirm/modal-confirm.component';
import { NgbCalendar, NgbCalendarBuddhist, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserTrainingForm } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';

@Component({
  selector: 'app-modal-training-history-form',
  templateUrl: './modal-training-history-form.component.html',
  styleUrls: ['./modal-training-history-form.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nBuddhistService },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatterService }
  ]
})
export class ModalTrainingHistoryFormComponent implements OnInit {
  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() modalTitle = "";
  @Input() action = "";
  @Input() userTraining: UserTrainingForm
  @Input() driver_id: number;
  @Input() trainingImage: TstImageItemModel[] = [];
  @Input() status_update = false;

  @Output() modalReference!: NgbModalRef;
  @Output() btnsubmit_event = new EventEmitter<any>();
  @Output() modal_closed = new EventEmitter<any>();
  @Output() historydetail_fetch = new EventEmitter<any>();
  @Output() onCompleteEvent = new EventEmitter<any>();

  user: User;
  trainingNewImage: File[] = [];
  deleteImageItem: TstImageItemModel = null;
  trainingStatusComplete = 2

  @ViewChild('modaltraininghistoryform', { static: true }) modaltraininghistoryform!: ModalTrainingHistoryFormComponent
  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;
  @ViewChild("modalsuccess_complete", { static: true }) modalsuccess_complete!: ModalsuccessComponent;
  @ViewChild("modalConfirm", { static: true }) modalConfirm!: ModalConfirmComponent;

  // trainingForm = new FormGroup(
  //   {
  //   date: new FormControl(Date),
  //   title: new FormControl(null),
  //   detail: new FormControl(null),
  //   // isSubscribeJobNotify: new FormControl(false),
  // }
  // );

  trainingForm: FormGroup

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private driverDetailService: DriverDetailService,
    private datePipe: DatePipe,
    private authen: AuthenticationService,
    public formatter: NgbDateParserFormatter,
    // private pageload: PageloadingService
    ) { }

  ngOnInit(): void {
    this.authen.currentUser.subscribe((x) => {
      this.user = x;
    });
  }

  onSubmit() {
    switch (this.action) {
      case "CREATE": {
        this.userTraining = this.trainingForm.value;
        console.log("create", this.userTraining);
        this.onCreate(this.userTraining);
        break;
      }

      case "EDIT": {
        this.userTraining = this.trainingForm.value;
        console.log("update", this.userTraining);
        this.onUpdate(this.userTraining)
        break;
      }

      case "SUCCESS": {
        this.userTraining = this.trainingForm.value;
        console.log("success", this.userTraining);
        this.onUpdate(this.userTraining)
        break;
      }
    }
  }

  formInit() {
    console.log("formInit", this.userTraining);
    this.trainingNewImage = []
    let file: TstImageItemModel[] = [];
    let datenow = new Date()

    var {
      id,
      date,
      title,
      detail,
      user_ID = this.driver_id,
      createdBy = this.user.id
    } = { ...this.userTraining };

    console.log(this.action);
    switch (this.action) {

      case 'CREATE':
        this.trainingForm = this.fb.group({
          user_ID,
          createdBy,
          date: [this.formatter.parse(datenow.toISOString()), [Validators.required]],
          title: ['', [Validators.required]],
          detail: ['', [Validators.required, Validators.maxLength(500)]],
        });

        this.trainingForm.get('date').valueChanges.subscribe(res => {
          console.log(res);
        });

        break;

      case 'EDIT':
        this.trainingForm = this.fb.group({
          id,
          user_ID,
          createdBy,
          date: [this.formatter.parse(date), [Validators.required]],
          title: [title, [Validators.required]],
          detail: [detail, [Validators.required, Validators.maxLength(500)]],
        });

        this.trainingForm.get('date').valueChanges.subscribe(res => {
          console.log(res);
        });

        file = this.userTraining.userTrainingFiles.map((e) => {
          return {
            ImageId: e.id,
            ImageUrl: e.filePath,
            ImageIsNew: false,
            ImageExtension: `.${(e.filePath.split('.')).splice(-1).pop()}`  //

          } as TstImageItemModel;
        })

        this.trainingImage = file
        break;

        case 'SUCCESS':
          this.trainingForm = this.fb.group({
            id,
            user_ID,
            createdBy,
            date: [this.formatter.parse(date), [Validators.required]],
            title: [title, [Validators.required]],
            detail: [detail, [Validators.required, Validators.maxLength(500)]],
          });

          this.trainingForm.get('date').valueChanges.subscribe(res => {
            console.log(res);
          });

          file = this.userTraining.userTrainingFiles.map((e) => {
            return {
              ImageId: e.id,
              ImageUrl: e.filePath,
              ImageIsNew: false,
              ImageExtension: `.${(e.filePath.split('.')).splice(-1).pop()}`  //

            } as TstImageItemModel;
          })

          this.trainingImage = file

      default:
        break;
    }


  }

  onCreate(userTraining: UserTrainingForm) {
    //this.pageload.show();

    const trainingForm = this.trainingForm.getRawValue();
    trainingForm.date = new Date((trainingForm.date!.year - 543), (trainingForm.date!.month - 1), trainingForm.date!.day);
    trainingForm.date = this.datePipe.transform(trainingForm.date, 'yyyy-MM-dd')!;
    userTraining.date = trainingForm.date;

    userTraining.trainingDoc = this.trainingNewImage
    userTraining.tst360_CompanyID = this.authen.currentUserValue.tstS360_CompanyID

    this.driverDetailService.addUserTraining(userTraining)
      .subscribe(res => {
        if (res.isSuccess) {
          //this.pageload.hide();
          console.log("success", res);
          this.modalReference.close()
          this.openmodalsuccess('เพิ่มประวัติการอบรมเรียบร้อย');
        }
      })
  }

  onUpdate(userTraining: UserTrainingForm) {
    //this.pageload.show();

    const trainingForm = this.trainingForm.getRawValue();
    trainingForm.date = new Date((trainingForm.date!.year - 543), (trainingForm.date!.month - 1), trainingForm.date!.day);
    trainingForm.date = this.datePipe.transform(trainingForm.date, 'yyyy-MM-dd')!;
    userTraining.date = trainingForm.date;
    userTraining.tst360_CompanyID = this.authen.currentUserValue.tstS360_CompanyID;

    userTraining.trainingDoc = this.trainingNewImage
    this.driverDetailService.updateUserTraining(userTraining)
      .subscribe(res => {
        if (res.isSuccess) {
          //this.pageload.hide();
          this.historydetail_fetch.emit();
          switch (this.action) {
            case "EDIT": {
              this.openmodalsuccess('แก้ไขประวัติการอบรมเรียบร้อย');
              break;
            }

            case "SUCCESS": {
              console.log('this.status_update', this.status_update);

              if (this.status_update) {
                this.updateTrainingStatus(userTraining, this.trainingStatusComplete);
              }
              this.openmodalsuccess_complete('บันทึกข้อมูลสำเร็จ');
              break;
            }
          }

          this.modalReference.close();
        }
      })
  }

  openmodal(size?: 'sm' | 'lg' | 'xl' | string) {

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modaltraininghistoryform, {
        size: size,
        centered: true,
        backdrop: 'static'
      });
    } else {
      this.modalReference = this.modalService.open(this.modaltraininghistoryform, {
        centered: true,
        backdrop: 'static'
      });
    }

    this.formInit();
  }

  openmodalsuccess(text: string) {
    this.modalsuccess.modalsize = undefined;
    this.modalsuccess.body_title = text;
    this.modalsuccess.btnother_text = 'ปิด';
    this.modalsuccess.openmodal(this.modalsuccess.modalsize);
  }

  success_other(value: any) {
    this.modalsuccess.modalReference.close();
    this.modalReference.close();
    this.modal_closed.emit();
    this.historydetail_fetch.emit();
  }

  openmodalsuccess_complete(text: string) {
    this.modalsuccess_complete.modalsize = undefined;
    this.modalsuccess_complete.body_title = text;
    this.modalsuccess_complete.body_text = 'คุณต้องการเพิ่มกำหนดการอบรมครั้งหน้า หรือไม่ ?';
    this.modalsuccess_complete.btnother_text = 'ปิด';
    this.modalsuccess_complete.btnsubmit_text = 'ต้องการ';
    this.modalsuccess_complete.openmodal(this.modalsuccess_complete.modalsize);
  }

  success_complete(value: any) {
    this.modalsuccess_complete.modalReference.close();
    this.modalReference.close();
  }

  submit_complete() {
    this.modalsuccess_complete.modalReference.close();
    this.onCompleteEvent.emit()
  }

  ngxdropzoneimage_change(event: any) {
    // debugger;
    // console.log('ngxdropzoneimage_change', event);
    this.trainingNewImage.push(...event.addedFiles);
  }

  ngxdropzoneimage_remove(f: File) {
    this.trainingNewImage.splice(this.trainingNewImage.indexOf(f), 1);
  }

  ngxdropzoneimage_old_delete(item: TstImageItemModel) {
    this.deleteImageItem = item;
    const modalConfirmConfig = {
      title: 'ลบข้อมูล',
      description: 'คุณต้องการจะลบออกจากระบบใช่ หรือ ไม่',
      iconClassName: 'fas fa-times text-danger',
      buttonList: [
        { text: 'ใช่ ฉันต้องการลบ', class: 'btn-danger', comfirm: true },
        { text: 'ยกเลิก', class: 'btn-outline-secondary', comfirm: false },
      ],
    };
    this.modalConfirm.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirm.open('sm');
    modalRef.closed.subscribe((comfirm: boolean) => {
      if (comfirm) {
        this.trainingImage.splice(this.trainingImage.indexOf(item), 1);

        this.deleteTrainingFile(item.ImageId);

      }
      this.deleteImageItem = null;
    })
  }

  async updateTrainingStatus(userTraining: UserTrainingForm, trainingStatus: number) {
    this.driverDetailService.updateUserTrainingStatus(userTraining, trainingStatus).subscribe(res => {
      if (res.isSuccess) {
        console.log("updateTrainingStatusComplete", res);
      }
    })
  }

  closed() {
    this.modalReference.close()
    this.modal_closed.emit();
  }

  async deleteTrainingFile(id: number) {
    this.driverDetailService.deleteUserTrainingFile(id).subscribe(res => {
      if (res.isSuccess) {
        console.log("delete userTrainingFile complete.", res);
        this.historydetail_fetch.emit();
      }
    })
  }
}
