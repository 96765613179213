import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ClientUserService } from '../../services/client-user.service';
import { DepartmentModel } from '../../../../core/_Models/MasterModel/DepartmentModel';
import { RoleModel } from '../../../../core/_Models/MasterModel/RoleModel';
import { TeamListModel } from '../../models/TeamListModel';
import { AddClientUserModel } from '../../models/ClientUser/AddClientUserModel';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import {
  AuthenticationService,
  NotificationService,
  PageloadingService,
} from '@app/core/_Services';
import { ClientModule, Operation } from '@app/core/_Models/UserModel/UserPermission';
import { TeamService } from '@app/modules/team/services/team.service';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from '@app/core/_Models/UserModel/User';

@Component({
  selector: 'app-modal-edit-user',
  templateUrl: './modal-edit-user.component.html',
  styleUrls: ['./modal-edit-user.component.scss'],
})
export class ModalEditUserComponent implements OnInit, OnChanges, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() IsView: boolean = false;
  @Input() IsEdit: boolean;
  @Input() EditData: AddClientUserModel;
  @Output() AfterSubmit = new EventEmitter<boolean>();
  onSaving: boolean = false;
  imagefile: File;
  profileImageUri: string;
  base64Image: string;
  departmentList: DepartmentModel[];
  roleList: RoleModel[];
  teamList: TeamListModel[];
  IsusernamePhone: boolean = true;
  teamIdstestset: number[] = [];
  userform = new FormGroup({
    nametitle: new FormControl(null),
    firstname: new FormControl(null, Validators.required),
    lastname: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.required),
    mobileNumber: new FormControl(null, Validators.required),
    status: new FormControl(true),
    username: new FormControl(),
    departmentId: new FormControl(null, Validators.required),
    roleId: new FormControl(null, Validators.required),
    teamIds: new FormControl(),
    authorizeID: new FormControl(1),
  });

  permission70100: Operation;

  clientModule_70400: ClientModule;
  clientModule_70200: ClientModule;
  currentUser: User;

  constructor(
    public authService: AuthenticationService,
    // private pageLoading: PageloadingService,
    private _ClientUserService: ClientUserService,
    private _noti: NotificationService,
    private _teamService: TeamService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    this.authService.currentUser.subscribe((x) => {
      this.currentUser = x;
      this.clientModule_70400 = this.authService.currentUserValue.clientModules.find(e => e.amo_id == 70400);
      this.clientModule_70200 = this.authService.currentUserValue.clientModules.find(e => e.amo_id == 70200);
    });
  }

  ngOnInit(): void {
    this.permission70100 = this._teamService.GetPermission(70000, 70100);

    this.setform();
    // // getmaster
    // this.getAllDepartment();
    // this.getTeam();
    this.initMasterData();
  }

  async ngOnChanges() {
    if (this.IsEdit) {
      this.userform.controls.firstname.setValue(this.EditData.firstName);
      this.userform.controls.lastname.setValue(this.EditData.lastName);
      this.userform.controls.email.setValue(this.EditData.email);
      this.userform.controls.mobileNumber.setValue(this.EditData.phoneNumber);
      this.userform.controls.username.setValue(this.EditData.username);
      this.userform.controls.departmentId.setValue(this.EditData.departmentId);
      this.userform.controls.roleId.setValue(this.EditData.roleId);
      this.userform.controls.teamIds.setValue(this.EditData.teamIds);
      this.userform.controls.status.setValue(this.EditData.roleUser?.isActive);
      this.profileImageUri = this.EditData.photoURL;
      if (this.profileImageUri && this.profileImageUri.indexOf('http') !== -1) {
        this.authService.photoAsync.next(this.profileImageUri);
      }
      // if (this.EditData.username == null || this.EditData.username == this.EditData.phoneNumber) {
      //   this.IsusernamePhone = true;
      // } else {
      //   this.IsusernamePhone = false;
      // }
     // await this.getRoleByDepartment();
     await this.getRoleByClient();
      //this.setform();
    } else {
      this.userform.reset();
      this.profileImageUri = null;
    }
  }

  setform() {
    this.userform = new FormGroup({
      nametitle: new FormControl(null),
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      mobileNumber: new FormControl(null, Validators.required),
      status: new FormControl(true),
      username: new FormControl(),
      departmentId: new FormControl(),
      roleId: new FormControl(null, Validators.required),
      teamIds: new FormControl(),
      authorizeID: new FormControl(1),
    });

    if (this.clientModule_70400?.pkg_id > 10) {
      this.userform.controls["departmentId"].setValidators([Validators.required]);
    }

    if (this.clientModule_70200?.pkg_id > 10) {
      this.userform.controls["teamIds"].setValidators([Validators.required]);
    }
  }

  initMasterData(): void {
    const forkData = [
      this._ClientUserService.GetAllDepartment(),
      this._ClientUserService.GetTeamByClient(),
    ];
    this.getRoleByClient();
    //this.pageLoading.show();
    forkJoin([...forkData]).subscribe(data => {
      //this.pageLoading.hide();
      if (!data[0].isSuccess) {
        this._noti.toasterror('ไม่สามารถดึงข้อมูล Department ได้', data[0].message);
      } else {
        this.departmentList = data[0].data;
      }
      if (!data[1].isSuccess) {
        this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', data[1].message);
      } else if (data[1].isSuccess) {
        this.teamList = data[1].data;
      }
    }, err => {
      //this.pageLoading.hide();
      this._noti.toasterror('ข้อมูล Master', 'ไม่สามารถโหลดข้อมูล Team และ Department ได้.');
    });
  }

  async checkUsedMobileNo() {
    let mobileNo = this.userform.value.mobileNumber;
    const result = await this._ClientUserService
      .CheckCanUseMobileNo(mobileNo)
      .subscribe((res: any) => {
        if (res == false && !(this.IsEdit && this.EditData.phoneNumber == mobileNo)) {
          let errors: any;
          if (this.userform.value.mobileNumber.length < 10) {
            this.userform.controls['mobileNumber'].setErrors({
              canUse: 'กรอกเบอร์โทรศัพท์ให้ครบถ้วน',
            });
          } else {
            this.userform.controls['mobileNumber'].setErrors({
              canUse: 'เบอร์โทรศัพท์ถูกใช้งานไปแล้ว',
            });
          }
        }
      });
  }

  async checkUsedEmail() {
    let email = this.userform.value.email;
    const result = await this._ClientUserService
      .CheckCanUseEmail(email)
      .subscribe((res: any) => {
        if (res == false && !(this.IsEdit && this.EditData.email == email)) {
          let errors: any;
          this.userform.controls['email'].setErrors({
            canUse: 'อีเมลนี้ถูกใช้งานไปแล้ว',
          });
        }
      });
  }

  async onSubmit() {
    if (this.IsusernamePhone == false) {
      if (this.userform.value.username == null) {
        this._noti.toasterror('กรุณากรอก Username', '');
        return;
      }
    }
    console.log('this.userform : ', this.teamIdstestset);
    console.log(this.userform.value);
    this.onSaving = true;
    if (this.IsEdit) {
      //this.pageLoading.show();
      this._ClientUserService
        .UpdateClientUser(this.userform, this.EditData)
        .subscribe(
          (res: TMSAPIResponse) => {
            //this.pageLoading.hide();
            if (res.isSuccess) {
              var userRes = res.data;
              if (this.imagefile != null) {
                if (this.imagefile.size > 0) {
                  let formData = new FormData();
                  const imgPath = 'c' + userRes.companyId;
                  const fileExt = this.imagefile.name.substring(
                    this.imagefile.name.lastIndexOf('.')
                  );
                  const imgName = 'u' + userRes.id + '-profile' + fileExt;

                  formData.append('file', this.imagefile);
                  formData.append('newpath', imgPath);
                  formData.append('filename', imgName);
                  formData.append('uid', userRes.id);
                  formData.append('companyId', `${this.currentUser.tstS360_CompanyID}`);

                  this._ClientUserService
                    .UploadUserImage(formData)
                    .subscribe((data) => {
                      console.log('upload image data', data);
                      this.imagefile = null;
                      this.profileImageUri = data.path;
                      this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
                      this.userform.reset();
                   //   window.location.reload();
                       this.AfterSubmit.emit(res.isSuccess);
                    });
                }
              } else {
                this._noti.toastsuccess('แก้ไขข้อมูลเรียบร้อย', '');
                this.userform.reset();
                //window.location.reload();
                 this.AfterSubmit.emit(res.isSuccess);
              }
            } else {
              this._noti.toasterror('แก้ไขข้อมูลไม่สำเร็จ', res.message);
            }
          },
          (err) => {
            //this.pageLoading.hide();
          }
        );
    } else {
      //this.pageLoading.show();
      this._ClientUserService.AddClientUser(this.userform).subscribe(
        (res: TMSAPIResponse) => {
          try {
            //this.pageLoading.hide();
            if (res.isSuccess) {
              var userRes = res.data;
              if (this.imagefile != null) {
                if (this.imagefile.size > 0) {
                  let formData = new FormData();
                  const imgPath = 'c' + userRes.companyId;
                  const fileExt = this.imagefile.name.substring(
                    this.imagefile.name.lastIndexOf('.')
                  );
                  const imgName = 'u' + userRes.id + '-profile' + fileExt;
                  formData.append('file', this.imagefile);
                  formData.append('newpath', imgPath);
                  formData.append('filename', imgName);
                  formData.append('uid', userRes.id);
                  formData.append('companyId', `${this.currentUser.tstS360_CompanyID}`);

                  this._ClientUserService
                    .UploadUserImage(formData)
                    .subscribe((data) => {
                      if (data) {
                        this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
                      } else {
                        this._noti.toastwarning(
                          'บันทึกข้อมูลเรียบร้อย',
                          'บันทึกรูปภาพไม่สำเร็จ'
                        );
                      }
                      this.imagefile = null;
                      this.userform.reset();
                     // window.location.reload();
                       this.AfterSubmit.emit(res.isSuccess);
                    });
                }
              } else {
                this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
                this.userform.reset();
                //window.location.reload();
                 this.AfterSubmit.emit(res.isSuccess);
              }
            } else {
              this._noti.toasterror('บันทึกข้อมูลไม่สำเร็จ', res.message);
            }
          } catch (e) {
            window.location.reload();
          }
        },
        (err) => {
          //this.pageLoading.hide();
        }
      );
    }
    this.onSaving = false;
  }

  async getAllDepartment() {
    const dept = this._ClientUserService
      .GetAllDepartment()
      .subscribe((res: TMSAPIResponse) => {
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          this.departmentList = res.data;
        }
      });
  }

  getRoleByDepartment() {
    const departmnetid = this.userform.value.departmentId;
    if (departmnetid > 0) {
      this.authService.currentUser.subscribe(async (user) => {
        this._ClientUserService
          .GetRoleByDepartment(departmnetid, user)
          .subscribe((res: TMSAPIResponse) => {
            console.log("GetRoleByDepartment",    res)
            if (!res.isSuccess) {
              this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
            } else if (res.isSuccess) {
              this.roleList = res.data;
              let defaultRole = null
              if (this.roleList.length > 0) { defaultRole = this.roleList[0].id; }
              this.userform.controls.roleId.setValue(defaultRole);
            }
          });
      });
    }
  }

  getRoleByClient() {
      this.authService.currentUser.subscribe(async (user) => {
        this._ClientUserService
          .GetRoleByClientId(user)
          .subscribe((res: TMSAPIResponse) => {
            if (!res.isSuccess) {
              this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
            } else if (res.isSuccess) {
              this.roleList = res.data;
              console.log("ROLE",    this.roleList = res.data)
              // this.userform.controls.roleId.setValue(this.roleList[0].id);
            }
          });
        });

  }

  async getTeam() {
    const result = await this._ClientUserService
      .GetTeamByClient()
      .subscribe((res: TMSAPIResponse) => {
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else if (res.isSuccess) {
          this.teamList = res.data;
        }
      });
  }

  handleFileInput(event: any) {
    console.log('handleFileInput', event);
    let files = event?.target?.files;
    if (files && files[0]) {
      this.imagefile = files[0];
      const base64Type = 'data:' + this.imagefile.type + ';base64,';
      this.base64Image = base64Type;
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(files[0]);
    }
  }

  _handleReaderLoaded(readerEvt) {
    if (readerEvt) {
      console.log('_handleReaderLoaded', readerEvt);
      var binaryString = readerEvt.target.result;
      this.base64Image += btoa(binaryString);
      this.profileImageUri = this.base64Image;
    }
  }

  Active = [
    { id: false, name: 'ระงับการใช้งาน' },
    { id: true, name: 'เปิดใช้งาน' },
  ];

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkUsernameChnage(event) {
    if (this.IsusernamePhone) {
      this.IsusernamePhone = false;
    } else {
      this.IsusernamePhone = true;
    }
  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  setAuthlevelByClientRole(item){

    var authId = 1;


    if(item.roleID == 9){
      authId = 3;
    }else if(item.roleID == 10){
      authId = 2;
    }else if(item.roleID == 11){
       authId = 1;
    }

    //console.log(authId);

    this.userform.controls.authorizeID.setValue(authId);
  }
}
