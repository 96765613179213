import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../../models/Address';
import { GetUserService } from '../../services/get-user.service';
declare function closeModal(modalID): any;
@Component({
  selector: 'app-modaleditlocation',
  templateUrl: './modaleditlocation.component.html',
  styleUrls: ['./modaleditlocation.component.scss']
})
export class ModaleditlocationComponent implements OnInit, OnChanges {

  @Input() shipping: number;
  @Input() billing: number;
  @Input() locationData: any;
  @Input() typeadd: boolean;
  @Input() companyID: number;
  @Output() onSave: EventEmitter<boolean> = new EventEmitter();
  province: any;
  District: any;
  SubDistrict: any;
  locationDetail = new FormGroup({
    titleName: new FormControl(),
    Brance: new FormControl(null, Validators.required),
    TaxID: new FormControl(),
    Detail: new FormControl(),
    zipcode: new FormControl(),
    TypeProduct: new FormControl(),
    provinceId: new FormControl(),
    districtId: new FormControl(),
    subdistrictId: new FormControl(),
    billing: new FormControl(),
    shipping: new FormControl(),
    firstName: new FormControl(),
    lastName: new FormControl(),
    phoneNo: new FormControl(),
    address: new FormControl(),
  });
  constructor(private usersersive: GetUserService) { }
  ngOnChanges() {
    if (this.typeadd) {
      this.clearform();
    } else {
      this.clearform();
      this.setform();
    }
  }

  clearform() {
    this.locationDetail.controls.titleName.reset();
    this.locationDetail.controls.address.reset();
    this.locationDetail.controls.provinceId.reset();
    this.locationDetail.controls.districtId.reset();
    this.locationDetail.controls.subdistrictId.reset();
    this.locationDetail.controls.TaxID.reset();
    this.locationDetail.controls.zipcode.reset();
    this.locationDetail.controls.firstName.reset();
    this.locationDetail.controls.lastName.reset();
    this.locationDetail.controls.phoneNo.reset();
    this.locationDetail.controls.billing.reset();
    this.locationDetail.controls.shipping.reset();
  }

  setform() {
    this.locationDetail.controls.titleName.setValue(this.locationData.titleName);
    this.locationDetail.controls.address.setValue(this.locationData.address);
    this.locationDetail.controls.provinceId.setValue(this.locationData.provinceId);
    this.locationDetail.controls.districtId.setValue(this.locationData.districtId);
    this.locationDetail.controls.subdistrictId.setValue(this.locationData.subdistrictId);
    this.locationDetail.controls.TaxID.setValue(this.locationData.taxId);
    this.locationDetail.controls.zipcode.setValue(this.locationData.zipcode);
    this.locationDetail.controls.firstName.setValue(this.locationData.firstName);
    this.locationDetail.controls.lastName.setValue(this.locationData.lastName);
    this.locationDetail.controls.phoneNo.setValue(this.locationData.phoneNo);
    if (this.billing == this.locationData.id) {
      this.locationDetail.controls.billing.setValue(true);
    }
    if (this.shipping == this.locationData.id) {
      this.locationDetail.controls.shipping.setValue(true);
    }

    // this.locationDetail.controls.Phone.setValue(this.location.data.phone);
    // this.locationDetail.controls.TypeCompany.setValue(this.location.data.typeCompanyID);
    this.GetDistrict(this.locationData.provinceId);
    this.GetSubDistrict(this.locationData.districtId);
  }

  async GetProvince() {
    this.province = await this.usersersive.GetProvince();
  }

  async GetDistrict(id: number) {
    this.District = await this.usersersive.GetDistrict(id);
  }

  async GetSubDistrict(id: number) {
    this.SubDistrict = await this.usersersive.GetSubDistrict(id);
  }

  ngOnInit(): void {
    this.GetProvince();
  }

  async onSubmit() {

    let addresss = new Address();
    addresss.titleName = this.locationDetail.value.titleName;
    addresss.address = this.locationDetail.value.address;
    addresss.provinceId = this.locationDetail.value.provinceId;
    addresss.districtId = this.locationDetail.value.districtId;
    addresss.subdistrictId = this.locationDetail.value.subdistrictId;
    addresss.taxId = this.locationDetail.value.TaxID;
    addresss.zipcode = this.locationDetail.value.zipcode;
    addresss.firstName = this.locationDetail.value.firstName;
    addresss.lastName = this.locationDetail.value.lastName;
    addresss.phoneNo = this.locationDetail.value.phoneNo;
    addresss.billing = this.locationDetail.value.billing == null ? false : this.locationDetail.value.billing;
    addresss.shipping = this.locationDetail.value.shipping == null ? false : this.locationDetail.value.shipping;

    if (this.typeadd) {
      addresss.companyId = this.companyID;
      addresss.id = 0;
      await this.usersersive.AddAddress(addresss);
    } else {
      addresss.id = this.locationData.id;
      await this.usersersive.updateAddress(addresss.id, "admin", addresss);
    }
    this.onSave.emit(true);
    closeModal('#editlocation');
  }
  onchangeProvince() {
    this.GetDistrict(this.locationDetail.value.provinceId);
  }
  onchangeDistrict() {
    this.GetSubDistrict(this.locationDetail.value.districtId);
  }
}
