import { Injectable } from '@angular/core';
import { User } from '../_Models/UserModel/User';
import { TstUser360 } from '../_SignUp/models/UserResultResponse.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsServiceService {
  constructor() {}


  PushUserInfo(user: TstUser360,userPhone:any = null) {
    const userInfo = {
      event: 'userInfo',
      user: {
        userID: user?.id, // Customer ID
        companyID: user?.companyId, // Company ID
       companyName: user?.company.companyNameTH, // Company Name
        userPhone: userPhone, // userPhone
        userEmail: user?.email, // Customer Email
      },
    };

    this.trigger(userInfo);
  }

  PushUserInfo2(user: User) {
    const userInfo = {
      event: 'userInfo',
      user: {
        userID: user?.id, // Customer ID
        companyID: user?.tstS360_CompanyID, // Company ID
       companyName: user?.client.clientName, // Company Name
        userPhone: user?.phoneNumber, // userPhone
        userEmail: user?.email, // Customer Email
      },
    };

    this.trigger(userInfo);
  }

  trigger(dimensionsSet: any) {
    (<any>window).dataLayer.push(dimensionsSet);
  }
}
