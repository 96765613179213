import { ExpressionStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BookingListDeliveredModel } from '../../../core/_Models/BookingModel/BookingListDeliveredModel';
import { BookingListOnGoingModel } from '../../../core/_Models/BookingModel/BookingListOnGoingModel';
import {
  BookingListBilling,
  BookingListWaitForPay,
} from '../../../core/_Models/BookingModel/BookingListPayModel';
import { driverModel } from '../../../core/_Models/BookingModel/driverModel';
import { HttpService } from '../../../core/_Services/http.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { User } from 'src/app/core/_Models/UserModel/User';
import {
  BookingModel,
  FollowTrackingModel,
  TATrackingModel,
  TDTrackingModel,
  TrackingModel,
} from '@app/modules/booking/models/BookingModel';
import { Utilities } from '@app/shared/utilities';
import { Observable } from 'rxjs/internal/Observable';
import { BookingListSearch } from '../models/BookingListSearchModel';
import { environment } from 'src/environments/environment';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import {
  BookingAPIPath,
  RouteAPIPath,
  ShipmentApi,
  TeamAPIPath,
  TruckBookingV2ApiPath,
} from '@app/core/_Constants/apiConstants';
import {
  BookingFilterModel,
  BookingFilterValueModel,
  EXFBookingFilter,
} from '@app/modules/booking/models/BookingFilterModel';
import { CryptoJsService } from '@app/core/_Services/crypto-js.service';
import { HttpHeaders } from '@angular/common/http';
import {
  Operation,
  PermissionModule,
} from '@app/core/_Models/UserModel/UserPermission';
import { AuthenticationService } from '@app/core/_Services';
import { BookingOnGoingFilterModel } from '@app/core/_Models/BookingModel/BookingOnGoingFilterModel';
import { DateRangeSearchDiagram } from "@app/modules/route-diagram/models/date-range-search-diagram";
import { SchedulerService } from '@app/modules/scheduler/scheduler.service';
import { Order } from '../models/OrderModel';
import { TruckbookingSettingDto } from '../models/TruckbookingSettingModel';
import { ShipmentTrackingStatusModel } from '@app/core/_Models/BookingModel/ShipmentTrackingStatusModel';

interface TruckBookingAssignmentV2 {
  clientCarrierName: string;
  assignToShipmentNo: string;
  carrierResponStatusId: number;
  truckTypeId: number;
  truckLicense: string;
  driverFirstName: string;
  driverLastName: string;
  driverPhone: string;
  weight?: number;
  shippingPrice?: number;
  laborPrice?: number;
  shippingPriceUnit: string;
  shippingPriceUnitId: number;
  laborPriceUnit: string;
  laborPriceUnitId: number;
}

@Injectable()
export class BookingServiceService {
  public bookingModel: BookingModel = new BookingModel();

  private currentFilterSubject: BehaviorSubject<BookingFilterModel>;
  public currentFilter: Observable<BookingFilterModel>;
  truckbookingSetting: BehaviorSubject<TruckbookingSettingDto>;

  destroy$: Subject<boolean> = new Subject<boolean>();
  ApiOrder = '/Order';
  ApiTruckBooking = '/TruckBooking';
  ApiTruckBookingProduct = '/TruckbookingProduct';
  ApiTruckShipment = '/Shipment';
  ApiTruckBookingCarrierAssing = '/TruckBookingCarrierAssignment';
  ApiTruckBookingCarrierAssingQuick = '/TruckBookingCarrierAssignment/quickAssign';
  dataList = [
    {
      id: 'B20K0001',
      customerName: 'บจก.ไทยวัสดุ',
      start: ' คลังเก็บสินค้า กทม 1',
      fullStart: 'กรุงเทพมหานคร',
      startDate: new Date('2020-11-19 08:30:00'),
      end: 'โรงงาน ณ ประจวบ',
      fullEnd: 'ประจวบคีรีขันธ์',
      endDate: new Date('2020-11-20 08:30:00'),
      truck: 'เทลเลอร์พื้นเรียบ x 3',
      status: 'รอจ่ายงาน',
    },
  ];
  user;
  constructor(
    private _apiService: HttpService,
    private cryto: CryptoJsService,
    private authenticationService: AuthenticationService,
    private schedulerService: SchedulerService
  ) {
    // Expense Module

    const getFilterStorage = localStorage.getItem('bf');
    if (getFilterStorage) {
      let decrypt = this.cryto.decrypt(getFilterStorage);
      let bookingFilter = decrypt;
      let bffilter = new BookingFilterModel();
      bffilter.useMyOnly = bookingFilter.useMyOnly;
      bffilter.teamIds = [];
      bookingFilter.team.map((m) => {
        bffilter.teamIds.push(m.teamId);
      });
      bffilter.originIds = [];
      bookingFilter.Origin.map((m) => {
        bffilter.originIds.push(m?.originId);
      });
      this.currentFilterSubject = new BehaviorSubject<BookingFilterModel>(
        bffilter
      );
      this.currentFilter = this.currentFilterSubject.asObservable();
    } else {
      this.currentFilterSubject = new BehaviorSubject<BookingFilterModel>(
        new BookingFilterModel()
      );
      this.currentFilter = this.currentFilterSubject.asObservable();
    }
    this.truckbookingSetting = new BehaviorSubject<TruckbookingSettingDto>(null);
  }

  GetPermission(moduleId, operationsId): Operation {
    const appModule = this.authenticationService.UserPermissionModule(moduleId);
    return appModule.operations.find((f) => f.id == operationsId);
  }

  GetSharedPublicToken(uri: string) {
    return this._apiService.get(
      this.ApiTruckBooking +
      '/public/GetSharedPublicToken?url=' +
      encodeURI(uri)
    );
  }

  SetCurrentFilter(filter: BookingFilterValueModel) {
    
    const encrypt = this.cryto.encrypt(filter);
    localStorage.setItem('bf', encrypt);
    let bffilter = new BookingFilterModel();
    bffilter.useMyOnly = filter.useMyOnly;
    bffilter.teamIds = [];
    filter.team.map((m) => {
      bffilter.teamIds.push(m.teamId);
    });
    bffilter.originIds = [];
    filter.Origin.map((m) => {
      bffilter.originIds.push(m?.originId);
    });

    this.currentFilterSubject.next(bffilter);
  }

  public get currentFilterValue(): BookingFilterModel {
    return this.currentFilterSubject.value;
  }

  DeleteTruckBookingFileAttach(fileAttaId: number) {
    return this._apiService.delete('/TruckBookingFileAttach?Id=' + fileAttaId);
  }

  AddTruckBookingFileAttach(data: any) {
    return this._apiService.fileupload('/TruckBookingFileAttach/', data);
  }

  GetTeamListWithClient(ClientID: number): Observable<TMSAPIResponse> {
    return this._apiService.get(TeamAPIPath.GetTeamWithClient + ClientID);
  }

  GetUserTeamListWithClient(UserID: number): Observable<TMSAPIResponse> {
    return this._apiService.get('/Team/from-user-id/' + UserID);
  }

  GetOriginWithClient(ClientID: number): Observable<TMSAPIResponse> {
    return this._apiService.get(
      RouteAPIPath.Route + '/GetOrigin?ClientId=' + ClientID
    );
  }

  //#region  Create Booking
  createBooking(form, type): Observable<TMSAPIResponse> {
    const booking = { bookking: form, createStatus: type };
    console.log(booking);
    var ss = JSON.stringify(booking);
    console.log('booking', ss);
    return this._apiService.postWithoutException(
      BookingAPIPath.AddBoooking,
      booking
    );
  }

  createBookingV2(form, type, epod = false): Observable<TMSAPIResponse> {
    const booking = { bookking: form, createStatus: type, IsEpodAvailable: epod };
    console.log(booking);
    var ss = JSON.stringify(booking);
    console.log('booking', ss);
    return this._apiService.postWithoutException(
      BookingAPIPath.AddBoooking + "/V2",
      booking
    );
  }

  createBookingV3(form, type): Observable<any> {
    const booking = { bookking: form, createStatus: type };
    console.log(booking);
    var ss = JSON.stringify(booking);
    console.log('booking', ss);
    return this._apiService.postWithoutException(
      BookingAPIPath.AddBoooking + "/V3",
      booking
    );
  }

  createBookingByPlan(list, quotas = null): Observable<TMSAPIResponse> {
    const booking = {
      bookingList: list,
      carrierQuotas: quotas
    };
    console.log(booking);
    var ss = JSON.stringify(booking);
    console.log('booking', ss);
    return this._apiService.postWithoutException(
      BookingAPIPath.AddBoooking + "/ListByPlan",
      booking
    );
  }
  createBookingBasic(booking): Observable<TMSAPIResponse> {
    return this._apiService.postWithoutException(
      BookingAPIPath.AddBoooking + '/Basic',
      booking
    );
  }

  createBookingBasicV2(form, type): Observable<TMSAPIResponse> {
    const booking = { bookking: form, createStatus: type };
    console.log(booking);
    var ss = JSON.stringify(booking);
    console.log('booking BasicV2', ss);
    return this._apiService.postWithoutException(
      BookingAPIPath.AddBoooking + "/BasicV2",
      booking
    );
  }

  createBooking360(shipmentId, companyID, shipmentPrice, laborPrice): Observable<TMSAPIResponse> {
    const booking = { shipmentId: shipmentId, companyID: companyID, shipmentPrice: shipmentPrice, laborPrice: laborPrice };
    console.log(booking);
    var ss = JSON.stringify(booking);
    console.log('booking Basic', ss);
    return this._apiService.postWithoutException(
      BookingAPIPath.AddBoooking + "/Create360booking",
      booking
    );
  }

  UpdateCreateBookingV2(bookingId, type): Observable<any> {
    const booking = { truckBookingID: bookingId, createStatus: type };
    console.log(booking);
    var ss = JSON.stringify(booking);
    console.log('booking', ss);
    return this._apiService.patch(
      BookingAPIPath.AddBoooking + "/UpdateValidBookingToCreate",
      booking
    );
  }


  getClientPordByRouteId(routeId): Observable<any> {
    return this._apiService.get(`/ClientProduct/ByProjectRoute/${routeId}`);
  }

  getBookingById(bookingId): Observable<any> {
    return this._apiService.get(`${this.ApiTruckBooking}/Rebook/${bookingId}`);
  }

  getBookingByIdBasic(bookingId): Observable<any> {
    return this._apiService.get(`${this.ApiTruckBooking}/RebookBasic/${bookingId}`);
  }

  //#endregion

  //#region Checkprice

  async CalculateCost(form, token): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(form);
      this._apiService
        .postToMatching('/MatchCarriers/MatchedByTruckLocationDto', form, token)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  //#endregion

  // #endregion Booking Details
  getBookingDetail(ClientID, bookingID): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckBooking}/GetBookingDetailsByBookingId/${ClientID}/${bookingID}`
    );
  }

  getOrderHistories(
    bookingID: number,
    truckbookingLocationId?: number
  ): Observable<Order[]> {
    const url = `${this.ApiOrder}/histories?TruckBookingId=${bookingID}`;
    return this._apiService.get(
      truckbookingLocationId
        ? `${url}&LocationId=${truckbookingLocationId}`
        : url
    );
  }

  checkExitsTruckBookingProductInShipmentItem(bookingID): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckBookingProduct}/CheckTruckBookingProductExitsInShipmentItem/${bookingID}`
    );
  }

  checkProductLoad(bookingID): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckBookingProduct}/CheckProductLoad/${bookingID}`
    );
  }

  checkShipmetnNoproduct(bookingID): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckShipment}/checkShipmetnNoProduct/${bookingID}`
    );
  }

  updateCustomerOrder(formData): Observable<any> {
    return this._apiService.patch(
      `${this.ApiTruckBookingProduct}/CustomerOrder`,
      formData
    );
  }

  updateCustomerOrderItem(formData): Observable<any> {
    return this._apiService.patch(
      `${this.ApiTruckBookingProduct}/CustomerOrderItem`,
      formData
    );
  }

  deleteOrder(orderId): Observable<any> {
    return this._apiService.delete(
      `${this.ApiTruckBookingProduct}/DeleteOrder/${orderId}`
    );
  }

  deleteOrderItem(orderItemId): Observable<any> {
    return this._apiService.delete(
      `${this.ApiTruckBookingProduct}/DeleteOrderItem/${orderItemId}`
    );
  }

  updateCustomer(truckBookingId, formData): Observable<any> {
    return this._apiService.patch(
      `${this.ApiTruckBooking}/${truckBookingId}/order`,
      formData
    );
  }

  updateTruckBookingLocation(truckBookingId, formData): Observable<any> {
    console.log(formData);
    return this._apiService.patch(
      `${this.ApiTruckBooking}/${truckBookingId}/truckbookinglocation`,
      formData,
    );
  }

  updateTruckBookingLocationBasic(truckBookingId, formData): Observable<any> {
    console.log(formData);
    return this._apiService.patch(
      `${this.ApiTruckBooking}/${truckBookingId}/truckbookinglocationBasic`,
      formData
    );
  }

  deleteLocation(truckBookingId, truckbookingLocationId): Observable<any> {
    return this._apiService.delete(
      `${this.ApiTruckBooking}/${truckBookingId}/truckbookinglocation/${truckbookingLocationId}`
    );
  }

  updateTruckType(formData): Observable<any> {
    return this._apiService.patch(
      `${this.ApiTruckBooking}/UpdateTruckType`,
      formData
    );
  }

  updateTruckBookingTeam(data): Observable<any> {
    return this._apiService.patch(
      `${this.ApiTruckBooking}/UpdateTruckBookingTeam`,
      data
    );
  }

  // #endregion

  // #region Booking-Details-Assign
  getCarrierResponseModal() {
    var data = [
      {
        id: 1,
        carrierName: 'กอไก่ ขนส่ง',
        assignment: ' BK12456-1/2',
        response: 'ตอบรับงานแล้ว',
        license: '6 กง 2067',
        maxWeight: '10 ตัน',
        driverName: 'นายยม ขับดี',
        driverPhone: '080 7998596',
      },
    ];
    return data;
  }

  getCarrierByClientId(ClientID): Observable<any> {
    return this._apiService.get(`/ClientCarrier/?ClientID=${ClientID}`);
  }

  getBookingAssign(ClientID, bookingID): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckBooking}/GetBookingAssignByBookingId/${ClientID}/${bookingID}`
    );
  }

  async getBookingAssignShipmentProduct(ClientID, bookingID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(
          `${this.ApiTruckBooking}/GetBookingAssignShipmentWeight/${ClientID}/${bookingID}`
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          console.log('AssignWeight', data);

          resolve(data);
        });
    });
  }

  getMatchedCarrier(ClientID, bookingID): Observable<any> {
    return this._apiService.get(
      `/TruckBookingCarrierMatched/GetCarrierMatchedByTruckBookingId/${ClientID}/${bookingID}`
    );
  }

  createMatchedCarrier(form): Observable<any> {
    return this._apiService.post(
      `/TruckBookingCarrierMatched/CreateCarrierMatchedByTruckBooking`,
      form
    );
  }

  UpdateProductPlaning(AssignList): Observable<any> {
    return this._apiService.patch(`/ShipmentPlaning`, { produtAssignWeight: AssignList });
  }

  UpdateShipmentCarrier(truckBookingId, clientID, shipmentList): Observable<any> {
    const shipment = { truckBookingId, clientID, carrierAssignments: shipmentList };
    return this._apiService.patch(`/Shipment/UpdateShipment`, shipment);
  }

  updateBookingCarrierAssign(assignList: any): Observable<any> {
    return this._apiService.patch(
      `/TruckBookingCarrierAssignment/UpdateCarrierTruck`,
      { assignList }
    );
  }

  updateBookingCarrierAssignV2(formValue): Observable<any> {
    return this._apiService.patch(
      `/TruckBookingCarrierAssignment/UpdateCarrierTruck`, formValue
    );
  }

  createTruckBookingCarrierAssignment(
    clientId: number,
    defaultWeight: boolean,
    value: any,
    isAutoConfirm: boolean
  ): Observable<any> {
    return this._apiService.post(
      this.ApiTruckBookingCarrierAssing,
      Object.assign({
        clientId,
        defaultWeight,
        isAutoConfirmOnBehalfOfCarrier: isAutoConfirm,
        truckBookingCarrierAssign: value,
      })
    );
  }

  createQuickTruckBookingCarrierAssignment(
    clientId: number,
    defaultWeight: boolean,
    value: any,
    isAutoConfirm: boolean,
    assignmentStatus: number
  ): Observable<any> {
    return this._apiService.post(
      this.ApiTruckBookingCarrierAssingQuick,
      Object.assign({
        clientId,
        defaultWeight,
        isAutoConfirmOnBehalfOfCarrier: isAutoConfirm,
        truckBookingCarrierAssign: value,
        assignmentStatus
      })
    );
  }

  async GetTruckBookingCarrierAssignment(ClientID, bookingID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(
          this.ApiTruckBookingCarrierAssing +
          `/GetTruckBookingAssign/${ClientID}/${bookingID}`
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  GetTruckBookingCarrierAssignmentForUpdate(
    ClientID,
    bookingID
  ): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckBookingCarrierAssing}/GetTruckBookingAssignmentForUpdate/${ClientID}/${bookingID}`
    );
  }

  GetCarrierResponseStatus(): Observable<any> {
    return this._apiService.get(`/AppMaster/CarrierResponse`);
  }

  //#endregion

  //#region BookingList Wait For Progress

  GetBookingWaitForProgressList(search: BookingListSearch): Observable<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    var SearchParam = {
      bookingListSearch: search,
      filter: filterValue,
    };
    var json = JSON.stringify(SearchParam);
    console.log(json);
    return this._apiService.postWithoutException(
      `${this.ApiTruckBooking}/GetBookingWaitForProgressList`,
      SearchParam
    );
  }

  //#endregion

  //#region Settlement
  GetBookingSettlement(bookingId): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckBooking}/GetTruckBookingPOExpenseByBookingId/${bookingId}`
    );
  }

  GetBookingSettlementAP(bookingId): Observable<any> {
    return this._apiService.get(
      `${this.ApiTruckBooking}/GetTruckBookingExpenseServiceIssuedByBookingId/${bookingId}`
    );
  }

  //#endregion
  GetBookingWaitForProgressCounter(clientId: number): Observable<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    const search = new BookingListSearch();
    search.clientId = clientId;
    return this._apiService.post(
      `${this.ApiTruckBooking}/GetBookingWaitForProgressCounter`,
      { BookingListSearch: search, Filter: filterValue }
    );
  }

  //#endregion
  getdataAll(tab: string, search: BookingListSearch): Observable<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    const taboj = { tab: tab, filter: filterValue, };
    return this._apiService.post(
      `${this.ApiTruckBooking}/GetBookingListAll`,
      Object.assign(taboj, { BookingListSearch: search, filterDriverFleetDTO : search.filterDriverFleetDTO })
    );
  }

  getWait() {
    var result = this.dataList.filter((x) => x.status == 'รอจ่ายงาน');
    console.log(result);

    return result;
  }

  getWaitResponse() {
    var result = this.dataList.filter((x) => x.status == 'รอตอบรับ');
    // console.log(result);

    return result;
  }

  getPriceRequest() {
    var result = this.dataList.filter((x) => x.status == 'ขอราคา');
    //console.log(result);

    return result;
  }

  getDraff() {
    var result = this.dataList.filter((x) => x.status == 'บันทึกร่าง');
    //console.log(result);

    return result;
  }

  checkMaxWeight(truckbookingid: number): Observable<any> {
    return this._apiService.getWithoutHandleError(
      `/shipment/check-max-weight/${truckbookingid}`
    );
  }

  getShipmentAPI(param: BookingOnGoingFilterModel): Promise<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    param.filter = filterValue;
    console.log(param);
    return new Promise(async (resolve, reject) => {
      try {
        const data: any = await this._apiService
          .postWithoutException(`/shipment/from-status`, param)
          .pipe(takeUntil(this.destroy$))
          .toPromise();

        if (param.clientId == 103) {
          for (let item of data.shipment) {
            try {
              const queue = await this.schedulerService.getQueue(item.shipmentId);
              if (queue.data != null) {
                item.isRegister = queue.data.isRegister;
              } else {
                item.isRegister = false;
              }
            } catch (error) {
              console.error('Error fetching queue:', error);
            }
          }
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getShipmentObserve(param: BookingOnGoingFilterModel): Observable<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    param.filter = filterValue;
    return this._apiService
      .post(`/shipment/from-status`, param);
  }

getTruckBookingObserveExcel(tab: string, search: BookingListSearch): Observable<any> {
  const taboj = { tab: tab };
  return this._apiService.postWithBlobType(
    `${this.ApiTruckBooking}/GetBookingListAllForExportExcel`,
    Object.assign(taboj, { BookingListSearch: search })
  );
}


  getShipmentObserveExcel(param: BookingOnGoingFilterModel): Observable<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    param.filter = filterValue;
    return this._apiService.postWithBlobType(`/shipment/excel-from-status`, param);
  }

  getShipmentTrackingStatusAPI(inputObj: BookingModel): Promise<any> {
    const param = {
      TruckBookingId: inputObj.truckBookingId,
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/from-bookingid`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getShipmmentDetail(
    bookingId: number
  ): Observable<ShipmentTrackingStatusModel> {
    const params = {
      TruckBookingId: bookingId,
    };
    return this._apiService.post(`/shipment/from-bookingid`, params);
  }

  getShipmentFromBookingIdAPI(searchDate: DateRangeSearchDiagram): Promise<any> {

    return new Promise((resolve, reject) => {
      this._apiService
        //.get(`/shipment/diagram/` + bookingId)
        .get(`/shipment/diagram?from=` +
          searchDate.startDateFrom.toISOString() + `&to=` +
          searchDate.startDateTo.toISOString())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getTruckBookingForDiagramAPI(searchDate: DateRangeSearchDiagram): Promise<any> {

    return new Promise((resolve, reject) => {
      this._apiService
        //.get(`/shipment/diagram/` + bookingId)
        .get(`/truckbooking/diagram?from=` +
          searchDate.startDateFrom.toISOString() + `&to=` +
          searchDate.startDateTo.toISOString())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getDocPoGenerator(id: number): Promise<any> {
    return fetch(`${environment.apiDocgen}/Docs/PO/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ff7725f6c4f05b87700aa00ebb2a5448f32461ec91db528d323f833471250c107b2b5b4c05cd18f923eac0c624984f1d33b4df6d37a7aa2ce3d0073d70e4867e',
        svc: 'docgen-svc',
      },
    });
  }

  getForceDocPoGenerator(id: number): Promise<any> {
    return fetch(`${environment.apiDocgen}/Docs/PO/force/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ff7725f6c4f05b87700aa00ebb2a5448f32461ec91db528d323f833471250c107b2b5b4c05cd18f923eac0c624984f1d33b4df6d37a7aa2ce3d0073d70e4867e',
        svc: 'docgen-svc',
      },
    });
  }

  trackingStartProgressAPI(inputObj: TrackingModel): Promise<any> {
    const param = {
      shipmentId: inputObj.shipmentID,
      startDate: Utilities.ConvertDateToString(
        inputObj.startDate,
        'YYYY-MM-DD HH:mm'
      ),
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/start-tracking`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  exfacEndTracking(shipmentID: number[], userId: number): Promise<any> {
    {

      const param = {
        ShipmentIds: shipmentID,
        UserId: userId
      };
      return new Promise((resolve, reject) => {
        this._apiService
          .post(ShipmentApi.ShipmentGoodissue, param)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (data: any) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }

  }
  trackingEndProgressAPI(inputObj: TrackingModel): Promise<any> {
    const param = {
      shipmentId: inputObj.shipmentID,
      endDate: Utilities.ConvertDateToString(
        inputObj.endDate,
        'YYYY-MM-DD HH:mm'
      ),
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/end-tracking`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  followProgressAPI(inputObj: FollowTrackingModel): Promise<any> {
    const param = {
      shipmentId: inputObj.shipmentId,
      followupStatusId: inputObj.followupStatusId,
      followupComment: inputObj.followupComment,
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/followup-tracking`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  approveEndProgressAPI(inputObj: TrackingModel): Promise<any> {
    const param = {
      shipmentId: inputObj.shipmentID,
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/approve-end-tracking`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addLocationTrackingAPI(inputObj: TATrackingModel): Promise<any> {
    const param = {
      truckbookingLocationId: inputObj.truckbookingLocationId || 0,
      shipmentId: inputObj.shipmentId,
      atDate: Utilities.ConvertDateToString(
        inputObj.ataDate,
        'YYYY-MM-DD HH:mm'
      ),
      confirmedType: inputObj.confirmedType,
      notDeliverRemark: inputObj.notDeliverRemark
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/add-location-tracking`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateLocationTrackingAPI(inputObj: TATrackingModel): Promise<any> {
    const param = {
      truckbookingLocationId: inputObj.truckbookingLocationId || 0,
      shipmentId: inputObj.shipmentId,
      atDate: Utilities.ConvertDateToString(
        inputObj.ataDate,
        'YYYY-MM-DD HH:mm'
      ),
      confirmedType: inputObj.confirmedType,
      notDeliverRemark: inputObj.notDeliverRemark
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/update-location-tracking`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addLocationFileattachAPI(param: FormData): Promise<any> {
    // const param = {
    //   truckbookingLocationId: inputObj.truckbookingLocationId || 0,
    //   shipmentId: inputObj.shipmentId,
    //   atDate: Utilities.ConvertDateToString(
    //     inputObj.atdDate,
    //     'YYYY-MM-DD HH:mm'
    //   ),
    // };

    return new Promise((resolve, reject) => {
      this._apiService
        .fileupload(`/shipment/add-location-tracking-fileattach`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateLocationFileattachAPI(param: FormData): Promise<any> {
    // const param = {
    //   ClientId: inputObj.clientId,
    //   truckbookingLocationId: inputObj.truckbookingLocationId || 0,
    //   TruckBookingId: inputObj.truckBookingId,
    //   shipmentId: inputObj.shipmentId,
    //   ClientCarrierId: inputObj.clientCarrierId,
    //   atDate: Utilities.ConvertDateToString(
    //     inputObj.atDate,
    //     'YYYY-MM-DD HH:mm'
    //   ),
    //   Images: inputObj.images,
    //   Documents: inputObj.documents
    //   // shipmentLocationTrackingFileAttach:
    //   //   inputObj.shipmentLocationTrackingFileAttach || [],
    // };

    return new Promise((resolve, reject) => {
      this._apiService
        .fileupload(`/shipment/update-location-tracking-fileattach`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getUntiPriceTypeAPI(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/appMaster/untiprice`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getExpenseTypeAPI(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/ClientExpenseType/byClient/${this.authenticationService?.currentUserValue?.ClientID}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updatePoExpenseAPI(inputObj: BookingModel): Promise<any> {
    const param = {
      isApproveEnd: inputObj.isApproveEnd,
      poExpenses: inputObj.poExpenses || [],
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/truckbooking/update-po-expense`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateExpenseServiceIssuedAPI(inputObj: BookingModel): Promise<any> {
    const param = {
      isApproveEnd: inputObj.isApproveEnd,
      shipmentExpenseServiceIssued: inputObj.shipmentExpenseServiceIssued || [],
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/truckbooking/update-expense-service-issued`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateSoExpenseAPI(request: any): Promise<any> {
    const params = {
      truckBookingSoExpenses: request
    }
    return new Promise((resolve, reject) => {
      this._apiService.post(`/truckbooking/update-so-expense`, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res.isSuccess) {
            resolve(true)
          }
        }, (error) => {
          reject(error);
        })
    })


  }

  getFollowupTrackingAPI(inputObj: BookingModel, paging: any): Promise<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    const param = {
      filterDriverFleetDTO : {
        fleet_No : inputObj.selectedFleet
      },
      status: inputObj.status,
      clientId: inputObj.clientId || 0,
      isSearch: inputObj.isSearch || false,
      originDestination: inputObj.originDestination || null,
      clientCarrierId: inputObj.clientCarrierId || 0,
      customerId: inputObj.customerId || 0,
      endDateFrom: inputObj.endDateFrom
        ? Utilities.ConvertDateToString(inputObj.endDateFrom)
        : null,
      endDateTo: inputObj.endDateTo
        ? Utilities.ConvertDateToString(inputObj.endDateTo)
        : null,
      atdDateFrom: inputObj.atdDateFrom
        ? Utilities.ConvertDateToString(inputObj.atdDateFrom)
        : null,
      atdDateTo: inputObj.atdDateTo
        ? Utilities.ConvertDateToString(inputObj.atdDateTo)
        : null,
      keyword: inputObj.keyword,
      createdBy: inputObj.createdBy,
      filter: filterValue,
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .postWithoutException(`/shipment/followup-tracking-list`, Object.assign(param, paging))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // cancel เนื่องจากยกเลิกการใช้งาน ย้ายไปใช้ getReceiptBill
  // getWaitForBill(){
  //   const param = {
  //   }
  //   return new Promise((resolve, reject) => {
  //     this._apiService.post(`/shipment/wait-for-bill`,param).pipe(takeUntil(this.destroy$)).subscribe((data:any) => {
  //       resolve(data);
  //     }, (error => {
  //       reject(error)
  //     }));
  //   })
  // }

  addBillReceipt(inputObj: BookingModel): Promise<any> {
    const param = {
      clientId: inputObj.clientId || 0,
      isApprove: inputObj.isApprove || false,
      aPBillReceiptDetail: inputObj.billExpenses,
    };
    console.log(param);
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/apbillreceipt/add`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getReceiptBill(inputObj: BookingModel, paging: any, stateBill?: any): Promise<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    const param = {
      filterDriverFleetDTO : {
        fleet_No : inputObj.selectedFleet
      },
      status: inputObj.status,
      clientId: inputObj.clientId,
      isSearch: inputObj.isSearch || false,
      originDestination: inputObj.originDestination || null,
      text: inputObj.keyword || null,
      clientCarrierId: inputObj.clientCarrierId || 0,
      customerId: inputObj.customerId || inputObj.customerName || 0,
      endDateFrom: inputObj.endDateFrom
        ? Utilities.ConvertDateToString(inputObj.endDateFrom)
        : null,
      endDateTo: inputObj.endDateTo
        ? Utilities.ConvertDateToString(inputObj.endDateTo)
        : null,
      atdDateFrom: inputObj.startDateFrom
        ? Utilities.ConvertDateToString(inputObj.startDateFrom)
        : null,
      atdDateTo: inputObj.startDateTo
        ? Utilities.ConvertDateToString(inputObj.startDateTo)
        : null,
      filter: filterValue,
      billState: stateBill,
      createdBy: inputObj.createdBy,
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .postWithoutException(`/apbillreceipt/list`, Object.assign(param, paging))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getReceiptBillExcel(inputObj: BookingModel, paging: any, stateBill?: any): Promise<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    const param = {
      status: inputObj.status,
      clientId: inputObj.clientId,
      isSearch: inputObj.isSearch || false,
      originDestination: inputObj.originDestination || null,
      text: inputObj.keyword || null,
      clientCarrierId: inputObj.clientCarrierId || 0,
      customerId: inputObj.customerName || 0,
      endDateFrom: inputObj.endDateFrom
        ? Utilities.ConvertDateToString(inputObj.endDateFrom)
        : null,
      endDateTo: inputObj.endDateTo
        ? Utilities.ConvertDateToString(inputObj.endDateTo)
        : null,
      atdDateFrom: inputObj.atdDateFrom
        ? Utilities.ConvertDateToString(inputObj.atdDateFrom)
        : null,
      atdDateTo: inputObj.atdDateTo
        ? Utilities.ConvertDateToString(inputObj.atdDateTo)
        : null,
      filter: filterValue,
      billState: stateBill
    };

    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/apbillreceipt/excel-list`, Object.assign(param, paging))
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getUserBankAccount(clientCarrierID: number) {
    return this._apiService.get(
      `/ClientCompany/GetUserBankAccount?clientCarrierID=${clientCarrierID}`
    );
  }

  getReceiptBillDetail(inputObj: BookingModel) {
    const param = {
      APBillReceiptId: inputObj.APBillReceiptId,
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/apbillreceipt/detail`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateReciptBill(inputObj: BookingModel) {
    const param = {
      action: inputObj.action,
      aPBillReceiptDetail: inputObj.billExpenses,
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/apbillreceipt/update`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getDocStatusAPI() {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/appmaster/docreturnstatus`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateDocStatusAPI(inputObj: BookingModel) {
    const param = {
      shipmentId: inputObj.shipmentID,
      docReturnStatusId: inputObj.docStatusID,
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/update-docreturn`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateRejectShipment(shipmentId: number): Observable<any> {
    return this._apiService.patch(`/shipment/updateRejectShipment`, {
      shipmentId,
    });
  }

  updateRejectAssignmentShipment(
    isCarrier: boolean,
    shipmentId: number
  ): Observable<any> {
    return this._apiService.patch(`/shipment/updateRejectAssignShipment`, {
      isCarrier,
      shipmentId,
    });
  }

  getMatchedByTruckBookingId(
    bookingId: number,
    token: string
  ): Observable<any> {
    return this._apiService.getMathing(
      `/MatchCarriers/MatchedByTruckBookingId/${bookingId}`,
      token
    );
  }

  patchWeightPerTruck(bookingId: number): Observable<any> {
    return this._apiService.patch(`/truckbooking/patchWeightPerTruck`, {
      bookingId,
    });
  }

  patchRequestPriceBooking(bookingId: number): Observable<any> {
    return this._apiService.patch(`/truckbooking/requestPriceBooking`, {
      bookingId,
    });
  }

  updateRejectBooking(bookingId: number): Observable<any> {
    return this._apiService.delete(
      `/truckbooking/updateRejectBooking/${bookingId}`
    );
  }

  cancelApproveAPI(inputObj: TrackingModel) {
    const param = {
      shipmentId: inputObj.shipmentID,
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipment/cancel-approved `, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  delteFileShipmentAPI(inputObj: BookingModel) {
    const param = {
      ID: inputObj.id,
    };
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/shipmentlocationtrackingfileattach/delete`, param)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getBookingpoExpeness(shipmentd: number): Observable<any> {
    return this._apiService.get(
      `/ClientCarrier/carrier360-shipmentByShipmentId/${shipmentd}`
    );
  }

  deleteDraftBooking(bookingId: number): Observable<any> {
    return this._apiService.delete(
      `/truckbooking/deleteDraftBooking/${bookingId}`
    );
  }

  updateDocumentReturn(formData): Observable<any> {
    return this._apiService.patch(
      `/truckbooking/UpdateDocumentReturn`, formData
    );
  }


  getBookingBasicInvoice(bookingId: number): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this._apiService.get(`${BookingAPIPath.GetBookingInvoice}/${bookingId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          console.log('res', res);
          resolve(res)
        })
    })

  }

  getPricing(req): Observable<any> {
    return this._apiService.postWithoutException(
      `/pricing/getPrice`, req
    );

  }

  getShipmentEXF(req: EXFBookingFilter): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.post(`${ShipmentApi.getShipmentEXFList}`, req).pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          console.log('getShipmentEXFList', res);
          resolve(res.isSuccess ? res.data : null);
        })
    })
  }

  cancelShipmentTrackingLocation(trackId: number, status: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .patch(`/shipment/cancel-checkin`, { shipmentLocationTrackingId: trackId, status: status })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getShipmentBudgetExcelTemplate(param: any, isEnterprise: boolean = true): Observable<any> {
    let filterValue: BookingFilterModel;
    this.currentFilter.subscribe((c) => {
      filterValue = c;
    });
    param.filter = filterValue;
    return isEnterprise ?
      this._apiService.postWithBlobType(`/v2/shipment/getShipmentBudgetExcelEnterpriseTemplate`, param) :
      this._apiService.postWithBlobType(`/v2/shipment/getShipmentBudgetExcelBasicTemplate`, param);
  }

  getTruckbookingSetting(truckBookingId: number): Promise<TruckbookingSettingDto> {
    return new Promise((resolve, reject) => {
      this._apiService.get(`${TruckBookingV2ApiPath.Setting}/${truckBookingId}/${this.authenticationService.currentUserValue.ClientID}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: TMSAPIResponse) => {
            this.truckbookingSetting.next(response.data);
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
    })
  }
  getTruckbookingSettingByRefTrucbookingId(truckBookingId: number): Promise<TruckbookingSettingDto> {
    return new Promise((resolve, reject) => {
      this._apiService.get(`${TruckBookingV2ApiPath.Setting}/${truckBookingId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: TMSAPIResponse) => {
            this.truckbookingSetting.next(response.data);
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
    })
  }

}
