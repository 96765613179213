<div class="row m-0" id="page-loading">
    <div class="col-12 page-loading">
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <!-- <img id="imgOnloading" src="360techx/images/Logo/ibid_horizontal_white.png" style="width: 250px; margin-left: -48px;" /> -->
            <h3 id="txtMessageload" style="margin-top: 25%; margin-left: -20px;">กำลังดำเนินการ...</h3>
        </div>
    </div>
</div>
