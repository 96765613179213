import { Injectable } from '@angular/core';
import { FleetXTruckAPIPath } from '@app/core/_Constants/apiConstants';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { HttpService } from '@app/core/_Services';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddFleetXTruckListRequest, SaveChangeFleetRequest } from '../models/fleet-x-truck-model';

@Injectable({
  providedIn: 'root'
})
export class FleetXTruckService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private apiService: HttpService) { }

  saveChangeFleet(dataReq: SaveChangeFleetRequest): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService.post(FleetXTruckAPIPath.SaveChangeFleet, dataReq)
      .pipe(takeUntil(this.destroy$)).subscribe(res => {
        resolve(res);
      })
    })
  }

  AddFleetXTruckList(dataReq: AddFleetXTruckListRequest): Observable<TMSAPIResponse> {
    return this.apiService.post(
      FleetXTruckAPIPath.AddFleetXTruckList,dataReq
    ); 
  }


}
