<ol class="breadcrumb breadcrumb-seperator-1">
    <li class="breadcrumb-item" *ngFor="let item of menulist">
        <span *ngIf="!item.url">{{item.name}}</span>
        <a *ngIf="item.url" href="{{item.url}}"> {{item.name}}</a>
    </li>
</ol>
<div class="subheader sub-header-scale  pt-0 mt-1">
    <div class="row justify-content-between" style="width: 100%;">
        <div class="col">
            <h3 class="pb-0 mb-0">
                <i class='subheader-icon fal fa-window'></i> {{Title}}
                <!-- <small> -->
                    <!-- {{description.detail}} -->
                <!-- </small> -->
            </h3>
        </div>
        <div class="col-auto" *ngIf="IntroAction" style="cursor: pointer">
            <span (click)="clickIntro()"><i class="fas fa-comment-dots"></i> เรียนรู้การใช้งาน
            </span>

        </div>
    </div>

</div>
