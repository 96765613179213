export class BookingFilterModel {
   useMyOnly:boolean;
   teamIds: number[] = [];
   originIds: number[] =[];
}

export class BookingFilterValueModel {
  useMyOnly:boolean;
  team:TeamFilter[] =[];
  Origin: OriginFilter[] =[];
}

export class TeamFilter {
  teamId:number;
  teamName:string;
}
export class OriginFilter {
  originId:number;
  originName:string;
}

export class EXFBookingFilter {
  statusIds: number[] = [];
  clientId: number | null;
  customerId: number | null;
  clientCarrierId: number | null;
  sorting: string;
  order: string;
  offset: number;
  limit: number;
}


