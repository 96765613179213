import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserChangePhoneNumber, UserConfirmChangePhoneNumber, UserVerifyOTP } from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';
import { AuthenticationService } from '@app/core/_Services';

@Component({
  selector: 'app-modalchangemobilenumber',
  templateUrl: './modalchangemobilenumber.component.html',
  styleUrls: ['./modalchangemobilenumber.component.scss'],
})
export class ModalchangemobilenumberComponent implements OnInit {
  @ViewChild('modalchangemobilenumber', { static: true }) modalchangemobilenumber!:ModalchangemobilenumberComponent
  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;


  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() body_title: string = '';
  @Input() body_text: string = '';
  @Input() userItem: any;
  @Input() userAuthorizationLevelId: number;
  @Output() modalReference!: NgbModalRef;
  @Output() btnsubmit_event = new EventEmitter<any>();


  isUsedMobileNo: boolean = false;

  mobileMessageError: string | undefined;
  mobileNo: string | undefined;
  OTP: string | undefined;
  identityUserId: string | undefined;

  userChangePhoneNumber = new UserChangePhoneNumber();
  userVerifyOTP = new UserVerifyOTP();
  isVerifyOTP: boolean = false;
  verifyOTPMessageError: string | undefined;
  isRequestOTP: boolean = false;

  @ViewChild(ModalsuccessComponent)
  modalsuccessComponent!: ModalsuccessComponent;

  constructor(
    private modalService: NgbModal,
    private driverDetailService: DriverDetailService,
    private authenticationService: AuthenticationService

  ) {}

  ngOnInit(): void {}

  openmodal(size?: 'sm' | 'lg' | 'xl' | string) {
    this.body_text = '';
    this.isUsedMobileNo = false;
    this.mobileMessageError = undefined;
    this.isRequestOTP = false;
    this.mobileNo = undefined;
    this.OTP = undefined;
    console.log('this.userAuthorizationLevelId',this.userAuthorizationLevelId);



    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(
        this.modalchangemobilenumber,
        { size: size, centered: true }
      );
    } else {
      this.modalReference = this.modalService.open(
        this.modalchangemobilenumber,
        { centered: true }
      );
    }
  }

  async btnsubmit_click() {
    if (this.OTP != '') {
      this.userVerifyOTP.appPackageID = this.userChangePhoneNumber.appPackageID;
      this.userVerifyOTP.userPoolEnum = this.userChangePhoneNumber.userPoolEnum;
      this.userVerifyOTP.phoneNumber = this.userChangePhoneNumber.phoneNumber;
      this.userVerifyOTP.tokenType = 2;
      this.userVerifyOTP.phoneNumber =
      this.userChangePhoneNumber.newPhoneNumber;
      this.userVerifyOTP.otpCode = this.OTP;

      await this.driverDetailService
        .verifyOTP(this.userVerifyOTP)
        .subscribe((res: any) => {
          console.log('verifyOTP', res);
          if (res.isSuccess) {
            this.isVerifyOTP = res.isSuccess;
            this.comfirmChangePhoneNumber(res.token);
          } else {
            this.isVerifyOTP = res.isSuccess;

            if (res.message.indexOf('OTP not matched') != -1) {
              this.verifyOTPMessageError = 'OTP ไม่ถูกต้อง';
            } else if (res.message.indexOf('already exists') != -1) {
              this.verifyOTPMessageError = 'เบอรนี้ได้ถูกใช้งานไปแล้ว';
            }else{
              this.verifyOTPMessageError = 'เกิดข้อผิดพลาด';
            }
          }
        });
    }

    // this.btnsubmit_event.emit();
  }

  async onRequestOTP() {
    this.isRequestOTP = true;
    // let userIdentityId = this.getUserIdentityId(this.userItem.id!);

    await this.driverDetailService
      .getUserIdentityId(this.userItem.user_ID!)
      .subscribe((res: any) => {
        console.log('getUserIdentityId', res);
        if (res.isSuccess) {
          this.identityUserId = res.data;
          this.changePhoneNumber();
        }
      });

    // this.changePhoneNumber();

    this.body_text = `ระบบได้ส่ง OTP ไปยังเบอร์ ${this.mobileNo} แล้ว`;
  }

  async checkUsedMobileNo() {
    // this.isUsedMobileNo = false;
    console.log('userItem',this.userItem);

    this.mobileMessageError = '';
    if (this.mobileNo != '' && this.mobileNo.length == 10) {
      let mobileNo = this.mobileNo;
      await this.authenticationService
        .CanUserThisMobileNo(mobileNo, `${this.userAuthorizationLevelId}`, `${this.authenticationService.currentUserValue.tstS360_CompanyID}`)
        .subscribe((res: any) => {
          if (res.isSuccess) {
            this.isUsedMobileNo = res.isSuccess;
            console.log('res success', res);
          } else {
            this.isUsedMobileNo = res.isSuccess;
            this.mobileMessageError = res.message;

            // if (res.message.indexOf('Invalid phone number format') != -1) {
            //   this.mobileMessageError = res.message;
            // } else if (res.message.indexOf('already exists') != -1) {
            //   this.mobileMessageError = res.message;
            // }
            // console.log('res failed', res);
          }
          console.log('checkUsedMobileNo else res', res);
        });
    } else {
      this.mobileMessageError = '';
    }
  }

  // async getUserIdentityId() {
  //   await this.userService
  //     .getUserIdentityId(this.userItem.id!)
  //     .subscribe((res: any) => {
  //       console.log('getUserIdentityId', res);
  //       if (res.isSuccess) {
  //         this.userIdentityId = res.data;
  //       }
  //     });
  // }

  async changePhoneNumber() {
    this.userChangePhoneNumber.appPackageID = 1;
    this.userChangePhoneNumber.userPoolEnum = 1;
    this.userChangePhoneNumber.phoneNumber = this.userItem.mobileNo;
    this.userChangePhoneNumber.newPhoneNumber = this.mobileNo;
    this.userChangePhoneNumber.identityUserId = this.identityUserId;

    await this.driverDetailService
      .changePhoneNumber(this.userChangePhoneNumber!)
      .subscribe((res: any) => {
        console.log('changePhoneNumber', res);
        if (res.isSuccess) {
        }
      });
  }

  async comfirmChangePhoneNumber(token: string) {
    let userConfirmChangePhoneNumber = new UserConfirmChangePhoneNumber();
    userConfirmChangePhoneNumber.identityUserId = this.identityUserId;
    userConfirmChangePhoneNumber.appPackageID = this.userVerifyOTP.appPackageID;
    userConfirmChangePhoneNumber.userPoolEnum = this.userVerifyOTP.userPoolEnum;
    userConfirmChangePhoneNumber.phoneNumber = this.userVerifyOTP.phoneNumber;
    userConfirmChangePhoneNumber.token = token;

    await this.driverDetailService
      .confirmChangePhoneNumber(userConfirmChangePhoneNumber)
      .subscribe((res: any) => {
        console.log('confirmChangePhoneNumber', res);
        if (res.isSuccess) {
          this.openmodalsuccess();
        }
      });
  }

  openmodalsuccess() {
    this.modalsuccessComponent.modalsize = undefined;
    this.modalsuccessComponent.body_title = 'เปลี่ยนเบอร์เรียบร้อย';
    this.modalsuccessComponent.body_text = `คุณได้เปลี่ยนเบอร์จาก ${this.userItem.mobileNo} เป็น ${this.mobileNo} เรียบร้อยแล้ว`;
    this.modalsuccessComponent.btnother_text = 'ปิด';
    this.modalsuccessComponent.openmodal(this.modalsuccessComponent.modalsize);
  }

  success_other(value: any) {
    console.log("test");
    this.modalsuccessComponent.modalReference.close();
    this.modalReference.close();
    this.btnsubmit_event.emit();
  }
}
