>

<!-- Modal -->
<div class="modal fade" id="qrReader" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h5 class="modal-title">สแกน QR Code</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row" *ngIf="!isRead">
                        <div class="col-12 text-center">
                    <qrcode [qrdata]="myAngularxQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

                        </div>
                    </div>
                    
                    <div class="row" *ngIf="isRead">
                        <div class="col-12 text-center">
                            <select matNativeControl matInput (change)="onDeviceSelectChange($event.target.value)">
                                <option value="" [selected]="!currentDevice">No Device Selected</option>
                                <option *ngFor="let device of availableDevices" [value]="device.deviceId"
                                  [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
                              </select>
                              <ng-select></ng-select>
                        </div>
                        <!-- <qr-scanner [debug]="true" [canvasWidth]="500" 
                         [canvasHeight]="500" [stopAfterScan]="true"
                            [updateTime]="500">
                           
                        </qr-scanner> -->

                        <zxing-scanner  [formats]="['QR_CODE']" (scanSuccess)="onCodeResult($event)" 
                        [(device)]="currentDevice" (permissionResponse)="onHasPermission($event)"
                        (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"
                        ></zxing-scanner>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>