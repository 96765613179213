<div class="dropdown-wrapper {{className}} {{isFocused ? 'focus':''}}" [ngClass]="{'is-open':isOpen, 'disabled':isReadOnly}" *ngIf="options" (contextmenu)="$event.stopPropagation();">
  <div class="box" (click)="toggle($event)">
    <ng-container>
      <div class="dropdown-selected" *ngIf="isSelectedValue" l10nTranslate><span>{{options[selected]?.title}}</span></div>
      <div class="dropdown-selected" *ngIf="!isSelectedValue" l10nTranslate><span>{{placeholder}}</span></div>
    </ng-container>
  </div>

  <ul class="dropdown-options" *ngIf="options">
    <li *ngIf="placeholder" (click)="$event.stopPropagation()">{{placeholder}}</li>
    <ng-container *ngFor="let option of options; let i = index">
      <li id="li-{{i + 1}}"
        [class.active]="selected === i"
        (click)="optionSelect(option, i, $event)"
        [value]="option.value"
        l10nTranslate
      >
        <span class="span-title">{{option.title}}</span>
        <ng-container *ngIf="option.description">
          <div class="ddl-height"></div>
          <span class="span-small mt-1">{{option.description}}</span>
        </ng-container>
      </li>
    </ng-container>

  </ul>
</div>
