<!-- <div class="flex-1" style="background: url(assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
    <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
        <div class="row d-flex justify-content-center">
            <div class="col-md-4 card">
                <div class="card-body">
                    <h5 class="card-title text-center">ลืมรหัสผ่าน</h5>
                    <form [formGroup]="PasswordGroup" (ngSubmit)="onSubmit()">
                        <div *ngIf="TokenString != null || TokenString !=''" class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="form-label" for="txtnewpassword">รหัสผ่านใหม่<span
                                            class="text-danger">*</span></label>
                                    <input type="password" id="txtnewpassword" name="txtnewpassword" class="form-control" formControlName="Passwordnew"
                                        placeholder="รหัสผ่านใหม่" minlength="8">
                               </div>                
                            </div>
                        </div>
                        <div *ngIf="TokenString != null || TokenString !=''" class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="form-label" for="txtconpassword">ยืนยันรหัสผ่านใหม่<span
                                            class="text-danger">*</span></label>
                                    <input type="password" id="txtconpassword" name="txtconpassword" class="form-control" formControlName="Passwordconfirm"
                                        placeholder="ยืนยันรหัสผ่านใหม่" minlength="8">
                                </div>
                            </div>
                        </div>
                    <div *ngIf="TokenString != null || TokenString !=''" class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-success btn-block" type="submit" [disabled]="onsaveing">
                                    <span *ngIf="onsaveing" class="spinner-grow spinner-grow-sm"></span>
                                    ยืนยัน</button>
                            </div>
                        </div>      
                    </div>
                </form>
                </div>

            </div>           
        </div>
        
    </div>
</div>
 -->

 <div class="row">
    <div class="col-xl-12">
        <h2 class="fs-xxl fw-500 mt-4 text-white text-center" >
            ตั้งรหัสผ่านใหม่
        </h2>
     
    </div>
    <div class="col-md-6 ml-auto mr-auto" *ngIf="isTokenExpiry">
        <div class="alert alert-danger text-center" role="alert" >
            ลิ้งการเปลี่ยนรหัสผ่าน <b>หมดอายุ</b> กรุณา <a routerLink="/" class="alert-link">เข้าสู่ระบบ</a> หรือ กดลืมรหัสผ่านอีกครั้ง.
        </div>
    </div>
   
    
    <div class="col-md-4 ml-auto mr-auto" *ngIf="!isTokenExpiry">
      
        <div class="card p-4 rounded-plus bg-faded"  >
            <form (ngSubmit)="onReset()"  [formGroup]="resetPasswordForm">
                <div class="form-group">
                    <label class="form-label" for="lostaccount">โปรดกรอกรหัสผ่านใหม่ </label>
                    <input type="password" id="lostaccount" class="form-control" placeholder="รหัสผ่านใหม่" formControlName="newPassword" maxlength="30">                    
                   
                    <div  class="help-block " style="font-size: 100%; color: #dc3545;" *ngIf="resetPasswordForm.controls.newPassword.errors?.minlength">
                        รหัสผ่านใหม่อย่างน้อย 8 ตัวอักษร                       
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="lostaccount">ยืนยันรหัสผ่าน </label>
                    <input type="password" id="lostaccount" class="form-control" placeholder="ยืนยันรหัสผ่าน" formControlName="confirmPassword" maxlength="30">                    
                   
                    <div  class="help-block " style="font-size: 100%; color: #dc3545;" *ngIf="resetPasswordForm.controls.confirmPassword.errors?.minlength">
                        รหัสผ่านใหม่อย่างน้อย 8 ตัวอักษร                       
                    </div>
                    <div  class="help-block text-rad" style="font-size: 100%; color: #dc3545;" *ngIf="resetPasswordForm.controls.confirmPassword.errors?.notMatching">
                        รหัสผ่านไม่ตรงกัน   
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-6 ml-auto text-right">
                        <button class="btn btn-success  waves-effect waves-themed" type="submit" disabled  *ngIf="isFormsubmit">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>                           
                        </button>
                        <button *ngIf="!isFormsubmit" id="js-login-btn" type="submit" class="btn btn-success  waves-effect waves-themed" [disabled]="resetPasswordForm.status === 'INVALID'">ยืนยันเปลี่ยนรหัสผ่าน</button>
                    </div>
                </div>
            </form>
        </div>      
    </div>
</div>