import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare function closeModal(modalID): any;

@Component({
  selector: 'app-tab-load-order',
  templateUrl: './tab-load-order.component.html',
  styleUrls: ['./tab-load-order.component.scss']
})
export class TabLoadOrderComponent implements OnInit {
  
  constructor(private fb: FormBuilder) { }
  @Input() LoadOrderData: any;
  @Input() booked = false;
  ngOnInit(): void {
    console.log(this.LoadOrderData)

}


}
