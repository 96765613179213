<div class="modal fade" id="modaladdteam" tabindex="0" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="teamForm" (ngSubmit)="onSubmit()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{IsEdit?"แก้ไขทีม":"เพิ่มทีม"}}
                    </h4>
                    <a class="Close"><i class="fal fa-times" data-dismiss="modal"></i></a>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label" for="txtidteam">รหัสทีม</label>
                                <input type="text"
                                    id="txtidteam"
                                    name="txtidteam"
                                    class="form-control {{ isExistTeamNumber() && 'is-invalid' }}"
                                    formControlName="teamNumber"
                                    placeholder="รหัสทีม">
                                <div *ngIf="isExistTeamNumber()"
                                    class="invalid-feedback">
                                    รหัสทีมนี้มีอยู่แล้ว                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label" for="txtteamname">ชื่อทีม</label>
                                <input type="text"
                                    id="txtteamname"
                                    name="txtteamname"
                                    class="form-control {{(teamForm.controls.teamName.invalid || isExistTeamName())?'is-invalid':'is-valid'}}"
                                    formControlName="teamName"
                                    placeholder="ชื่อทีม"
                                    required>

                                <div *ngIf="teamForm.controls.teamName.invalid" class="invalid-feedback">
                                    <div *ngIf="teamForm.controls.teamName.errors.required">
                                        กรุณากรอก ชื่อทีม
                                    </div>
                                </div>
                                <div *ngIf="isExistTeamName()"
                                    class="invalid-feedback">
                                    ชื่อทีมนี้มีอยู่แล้ว                              
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label">สถานะ</label>
                                <ng-select placeholder="สถานะ"
                                    formControlName="teamStatus"
                                    [items]="Active"
                                    bindLabel="name"
                                    bindValue="id">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label">สถานที่ต้นทางที่ใช้เป็นประจำ</label>
                                <ng-select placeholder="ต้นทาง" formControlName="teamClientLocationIds" [multiple]="true">
                                    <ng-option *ngFor="let item of LocationList" [value]="item.id">{{item.locationShortAddress}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>
                    <button *ngIf="!onSaving"
                        type="submit"
                        [disabled]="!teamForm.valid || isExistTeamNumber() || isExistTeamName()"
                        class="btn {{IsEdit?'btn-warning':'btn-success'}}">
                        บันทึกข้อมูล
                    </button>
                    <button *ngIf="onSaving" class="btn {{IsEdit?'btn-warning':'btn-success'}}" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        กำลังบันทึกข้อมูล...
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>