import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';

import { HttpService } from './http.service';
import { Subject } from 'rxjs/internal/Subject';
import { SharedBidRouteModel } from '../_Models/RouteModel/SharedBidRouteModel';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private apiService: HttpService) { }

  getCarrierManager(routeId: number): Observable<any> {
    return this.apiService.get(`/Routes/getCarrierManager?routeId=${routeId}`);
  }

  getSpotRoutesById(clientId: number, routeId: number): Observable<any> {
    return this.apiService.get(
      `/Routes?clientId=${clientId}&routeId=${routeId}`
    );
  }

  getSpotRoutes(clientId: number): Observable<any> {
    console.log("clientId -> ",clientId)
    return this.apiService.get(`/Routes?clientId=${clientId}`);
  }

  getRoutesProvince(clientId: number): Observable<any> {
    console.log("clientId -> ",clientId)
    return this.apiService.get(`/Routes/GetProvinceRoute/${clientId}`);
  }


  getProjectRoute(routeId: number): Observable<any> {
    return this.apiService.get(`/Routes/getProjectRoute?routeId=${routeId}`);
  }

  getProjectRouteByBookingId(bookingId: number): Observable<any> {
    return this.apiService.get(`/Routes/getProjectRouteByBookingId?bookingId=${bookingId}`);
  }

  getBidRoutes(
    projectRouteId: number,
    routeId: number,
    clientId: number,
    location?: string,
    project?: string,
    customer?: number,
    truck?: number,
    skip?: number,
    take?: number
  ): Observable<any> {
    let url = `/BidRoute?ClientId=${clientId}&limit=${take}&offset=${skip}&projectRouteId=${projectRouteId}&routeId=${routeId}`;
    console.log('value search', { location, project, customer, truck });
    if (location) {
      url += `&locationName=${location}`;
    }
    if (project) {
      url += `&projectName=${project}`;
    }
    if (customer) {
      url += `&customerId=${customer}`;
    }
    if (truck) {
      url += `&truckId=${truck}`;
    }
    return this.apiService.get(
      `${url}`
    );
  }

  getEditSpotRouteById(id: number): Observable<any> {
    return this.apiService.get(`/Routes/getById?id=${id}`);
  }

  getRouteForModal(
    clientUd: number,
    origin: string,
    destination: string
  ): Observable<any> {
    return this.apiService.post(`/Routes/getForCreate`, {
      clientId: clientUd,
      origin,
      destination,
    });
  }

  getRouteForModalWithTruckType(
    limit: number,
    clientUd: number,
    origin: string,
    destination: string,
    truckTypeIds: number[]
  ): Observable<any> {
    return this.apiService.post(`/Routes/getForCreate`, {
      limit,
      clientId: clientUd,
      origin,
      destination,
      truckTypeIds
    });
  }

  getSharedBidRoute(clientId: number, routeId: number): Observable<any> {
    return this.apiService.get(
      `/Routes/getSummaryAuction?id=${routeId}&clientId=${clientId}`
    );
  }

  getSpotSharedBidRoute(clientId: number, routeId: number): Observable<any> {
    return this.apiService.get(
      `/Routes/getSummaryAuction?id=${routeId}&clientId=${clientId}`
    );
  }

  getSharedCalculateBaseFuel(
    clientId: number,
    routeId: number
  ): Observable<any> {
    return this.apiService.get(
      `/Routes/getSharedCalculateBaseFuel?routeId=${routeId}&clientId=${clientId}&type=route`
    );
  }

  getRouteLocationForCreate(RouteId: number): Observable<any> {
    return this.apiService.get(
      `/Routes/getLocationsForCreate?RouteId=${RouteId}`
    );
  }

  saveRoute(form: any): Observable<any> {
    return this.apiService.post(`/Routes`, {
      addRouteLocationType: form,
    });
  }

  addCarrierRoute(form: any): Observable<any> {
    console.log('form', form);
    return this.apiService.post(`/Routes/addCarrierRoute`, form);
  }

  updateRoute(form: any): Observable<any> {
    return this.apiService.put(`/Routes`, form);
  }

  updateRouteCarrier(form: any): Observable<any> {
    return this.apiService.put(`/Routes/updateRouteCarrier`, form);
  }

  deleteRoute(id: number): Observable<any> {
    return this.apiService.delete(`/Routes?id=${id}`);
  }

  deleteRouteCarrierById(id: number): Observable<any> {
    return this.apiService.delete(`/Routes/deleteRouteCarrierById?id=${id}`);
  }

  getLocationForCreate(clientLocId: number): Observable<any> {
    return this.apiService.get(
      `/ClientLocation/GetClientLocationForCreateByClientLocationId?ClientLocationId=${clientLocId}`
    );
  }

  getDropdownFuelPriceMethod(clientId: number): Observable<any> {
    return this.apiService.get(
      `/Routes/dropdownFuelPriceMethod?clientId=${clientId}`
    );
    
  }

  CheckIsLocaInRoute(clientLocId: number): Observable<any> {
    return this.apiService.get(`/ClientLocation/CheckIsLocaInRoute?ClientLocationId=${clientLocId}`);
  }
}
