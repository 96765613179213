import {teamUserDto, userRoleDto} from "@app/modules/team/models/ClientUser/AddClientUserModel";

export class UpdateClientUserModel {
  clientID: number;
  tst360UserId: number;
  tst360CompanyId: number;
  baseClientUser: basicUserClientDto = new basicUserClientDto();
  identityUserClient:identityUserClientDto = new identityUserClientDto();
  teamUser: Array<teamUserDto>;
  userRole:userRoleDto;
}

export class basicUserClientDto {
  clientID:number;
  tsT360_CompanyID:number;
  username:string;
  phoneNumber:string;
  mobileNo:string;
  email:string;
  firstName:string;
  lastName:string;
  photoURL:string;
  iDcard:string;
  taxId:string;
  birthDate:string;
}

export  class  identityUserClientDto {
  tstUserId:string;
  userName:string;
  phoneNumber:string;
  email:string;
  clientIP:string;
}
