import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
//import { VERSION } from '../environments/version';
import {map} from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from './../environments/environment';
import { AppContants } from './core/_Constants/appContants';
import { User } from './core/_Models/UserModel/User';
import { AuthenticationService, PageloadingService } from './core/_Services';
import { AnalyticsServiceService } from './core/_Services/analytics-service.service';
// declare function TriggerFbMsg(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit,AfterContentChecked {
  //title = 'TST-TMS-WEB';
  title = AppContants.AppTile;
  currentUser: User;
  isSignUpPage: boolean = false;
  isPackagePage: boolean = false;
  loading$ = this.loader.isLoading$;
  constructor(
    private router: Router,
    private detech: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private Location:Location,
    private analytic:AnalyticsServiceService,
    private loader: PageloadingService

  ) {
    // console.log(`Application version is: version (from package.json)=${VERSION.version}, git-tag=${VERSION.tag}, git-hash=${VERSION.hash}`);
    // Get Uer data after login
    //this.currentUser  = new User();

    // this.isSignUpPage = (this.Location.path().includes("signup") || this.Location.path().includes("signup/b1") || this.Location.path().includes("signup/360") || this.Location.path().includes("signup/initclient"))
  }
  ngAfterContentChecked(): void {
    // this.isSignUpPage = (this.Location.path().includes("signup") || this.Location.path().includes("signup/b1") || this.Location.path().includes("signup/360") || this.Location.path().includes("signup/initclient"))
  }

  // checkPathSignup(): boolean {
  //    return this.Location.path().includes("signup")
  // }


  ngAfterViewInit(): void {
    try {
      this.authenticationService.currentUser.subscribe((x) => {
        this.currentUser = x;
        if (x) {
          this.authenticationService.FilesServer360TechXCookiieCreate();
          this.authenticationService.FilesServer360TruckCookiieCreate();
          this.authenticationService
            .getProfilePhotoByServer(x.tstS360_UserID)
            .subscribe((img) => {
              const date = new Date();
              this.authenticationService.photoAsync.next(img.photo + `?time=${date.getTime()}`);
            });
            let clientModule = this.authenticationService.currentUserValue?.clientModules.find(e => e.amo_id == 110010);
           console.log("clientModule pkg_id",clientModule)
            // if(clientModule?.pkg_id < 30){
            //   TriggerFbMsg();
            // }
          // this.analytic.PushUserInfo(x,x.phoneNumber);

        }
        this.detech.detectChanges();
      });
    }catch{

    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
