import { Component, OnInit } from '@angular/core';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { bool } from 'aws-sdk/clients/signer';
import { User } from 'src/app/core/_Models/UserModel/User';
import {
  AuthenticationService,
  NotificationService,
} from 'src/app/core/_Services';
import { GetUserService } from '../../services/get-user.service';
declare function openModal(modelID): any;
@Component({
  selector: 'app-compandetail',
  templateUrl: './compandetail.component.html',
  styleUrls: ['./compandetail.component.scss'],
})
export class CompandetailComponent implements OnInit {
  user: User;
  company: any;
  location: any;
  typeadd: bool = true;
  permission: PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  constructor(
    private usersersive: GetUserService,
    private auth: AuthenticationService,
    private _noti: NotificationService
  ) {
    const p: PermissionModule = this.auth.UserPermissionModule(80000);
    if (p) {
      console.log('permission', p);
      this.permission = this.auth.FindPermissionAction(
        80200,
        p.operations
      );
    }
  }

  ngOnInit(): void {
    this.user = this.auth.currentUserValue;
    this.GetCompany();
  }

  async GetCompany() {
    this.company = await this.usersersive.GetCompany(
      this.user.tstS360_CompanyID,
      this.user.ClientID
    );
  }

  async onEdit(data: any) {
    this.location = await data;
    this.typeadd = false;
    openModal('#editlocation');
  }

  async ondd() {
    this.location = null;
    this.typeadd = true;
    openModal('#editlocation');
  }

  onAfterSaveLocation(event) {
    if (event) {
      this.GetCompany();
      this._noti.toastsuccess('บันทึกสำเร็จ', '');
    }
  }
  onAfterSavecompany(event) {
    if (event) {
      this.GetCompany();
      this._noti.toastsuccess('บันทึกสำเร็จ', '');
    }
  }
}
