import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageloadingService {

  isLoading$ = new Subject<boolean>();
  count: number = 0;
  constructor() { }

  hide(): void {
    // $("#page-loading").hide();
    this.count -=1;
    // console.log('- http request count ', this.count);
    if (this.count <= 0) {
      this.isLoading$.next(false);
      this.count = 0;
    }
  }

  show(): void {
    this.count++;
    this.isLoading$.next(true);
    // console.log('+ http request count ', this.count);
    // $("#page-loading").show();
  }


}
