import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { AddClientUserModel } from '@app/modules/team/models/ClientUser/AddClientUserModel';
import { ClientUserService } from '@app/modules/team/services/client-user.service';
import { ModalImportFileComponent } from '@app/shared/Components/FileUpload/modal-import-file/modal-import-file.component';
import { ModalImportResultComponent } from '@app/shared/Components/FileUpload/modal-import-result/modal-import-result.component';
import { ModalPreviewImportDataComponent } from '@app/shared/Components/FileUpload/modal-preview-import-data/modal-preview-import-data.component';
import {
  ModalImportFileModel,
  ModalImportResultModel,
  ModalPreviewImportDataModel
} from '@app/shared/Components/FileUpload/models/import-file-model';
import { ModalConfirmComponent } from '@app/shared/Components/modal-confirm/modal-confirm.component';
import { ModalSuccessComponent, ModalSuccessModel } from '@app/shared/Components/modal-success/modal-success.component';
import { ModalchangeemailComponent } from '../../component/modalchangeemail/modalchangeemail.component';
import { ModalchangemobilenumberComponent } from '../../component/modalchangemobilenumber/modalchangemobilenumber.component';
import { ModalchangepasswordComponent } from '../../component/modalchangepassword/modalchangepassword.component';
import { ModalEditDriverFormComponent } from '../../component/modal-edit-driver-form/modal-edit-driver-form.component';
import { DriverList, IDriverImportModel, ReturnValidateDriverExcel } from '../../models/driver.model';
import { AddDriverExcelService } from '../../services/add-driver-excel.service';
import { DriverDetailService } from '../../services/driver-detail.service';
import { Subject } from 'rxjs';
import { ModalChangeFleetDriverComponent } from '../../component/modal-change-fleet-driver/modal-change-fleet-driver.component';
import { TruckManagementService } from '@app/modules/truck-management/service/truck-management.service';
import { takeUntil } from 'rxjs/operators';
import { clientFleetDto } from '@app/modules/truck-management/models/TruckFleet';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { UserEventService } from '@app/core/_Services/user-event.service';

declare function openModal(ModalID): any;
declare function closeModal(ModalID): any;
declare function initDataTable(tableID, param1, param2, param3, param4, responsive): any;

@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss'],
})
export class DriverListComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() lookingTruckLocationEvent = new EventEmitter<boolean>();

  fleets = [{ id: 1, name: 'FLEET2 : ทีมงานสายลุยภาคอีสาน (60)' }];
  f = 1;
  curentYear = new Date().getFullYear();
  @Input() currentFleet: number = 0;

  driverList: DriverList[] = [];
  driverStatus = [
    { status: true, name: 'เปิดใช้งาน' },
    { status: false, name: 'ระงับการใช้งาน' }
  ];

  fleetId = 0;

  isEdit = false;
  isViewUser = false;
  isDriver = true;

  userEditData: AddClientUserModel;

  openQR: boolean = false;

  filter = {
    textSearch: null,
    isEnabled: null,
    driverLicenceStatusId: null,
    shipmentStatusId: null
  }

  driverPaging: number = 1;
  ItemsPerPage: number = 10;
  TotalData: number = 0;

  private tableWidget: any;

  fleetList: clientFleetDto[] = [];

  @Input() selectedFleet: number = 0;

  totalDriver: number = 0;

  @ViewChild('modalchangepassword') modalchangepasswordComponent!: ModalchangepasswordComponent;
  @ViewChild('modalchangemobilenumber') modalchangemobilenumberComponent!: ModalchangemobilenumberComponent;
  @ViewChild('modalchangeemail') modalchangeemail!: ModalchangeemailComponent;

  @ViewChild('modalImportFile', { static: true }) modalImportFile!: ModalImportFileComponent;
  @ViewChild('modalPreviewImportData') modalPreviewImportData!: ModalPreviewImportDataComponent;
  @ViewChild('modalImportResult') modalImportResult: ModalImportResultComponent;
  @ViewChild('modalSuccess', { static: true }) modalSuccess!: ModalSuccessComponent;
  @ViewChild('modalConfirm', { static: true }) modalConfirm!: ModalConfirmComponent;

  @ViewChild('modaladddriverform', { static: true }) modaladddriverform!: ModalEditDriverFormComponent;
  @ViewChild('modaleditdriverform', { static: true }) modaleditdriverform!: ModalEditDriverFormComponent;
  @ViewChild('modalviewdriverform', { static: true }) modalviewdriverform!: ModalEditDriverFormComponent;

  @ViewChild("dtElement") dtElement: ElementRef;

  @ViewChild("ModalChangeFleetDriver", { static: true }) ModalChangeFleetDriver!: ModalChangeFleetDriverComponent;


  validateResponse: ReturnValidateDriverExcel[] = [];

  permission130100  : PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };


  constructor(
    // private pageload: PageloadingService,
    private driverDetailService: DriverDetailService,
    private addDriverService: AddDriverExcelService,
    private _ClientUserService: ClientUserService,
    private _noti: NotificationService,
    private changeRef: ChangeDetectorRef,
    private truckManagementService: TruckManagementService,
    private authService: AuthenticationService,
    private _UserEvent : UserEventService
  ) {
  }

  ngAfterViewInit(): void {
    this.onSubscribe();
  }

  ngOnInit(): void {

    var p2: PermissionModule = this.authService.UserPermissionModule(130000);
    console.log('permission p2', p2);
    if (p2) {
      this.permission130100 = this.authService.FindPermissionAction(
        130100,
        p2.operations
      );
      console.log('permission130100',   this.permission130100 );
    }

    // //this.pageload.hide();
    this.onSubscribe();
    // this.getDriver();
    this.getClientFleetList();
  }

  ngOnDestroy(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
      this.changeRef.detectChanges();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // getDriver(): void {
  //   this.driverDetailService.getDriverFleet(this.currentFleet).subscribe((res) => {
  //     console.log(res);
  //     this.driverList = res.data;
  //     // this.driverList = this.driverList.filter(c=>c.isEnabled == false)

  //   });
  // }

  onSubscribe(): void {

    this.filter = {
      textSearch: null,
      isEnabled: null,
      driverLicenceStatusId: null,
      shipmentStatusId: null
    }

    this.driverDetailService.driverListOnChange.pipe(takeUntil(this.destroy$)).subscribe(res => {
      // console.log('onSubscribe', res);

      // this.driverList = res;
      this.SetPaginationData();
      // this.reInitDatatable();
      // //this.pageload.hide();
    });
  }



  feetChange(event: any): void {
    this.currentFleet = event;
    console.log('fleet No ', event);
    this.fleetId = event;

    // this.getDriver();

  }

  onImgErrorProfile(event): void {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event): void {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  openmodalchangepassword(userItem: any): void {
    console.log(userItem);
    this.modalchangepasswordComponent.modalsize = undefined;
    this.modalchangepasswordComponent.body_title = 'ต้องการเปลี่ยนรหัสผ่าน ?';
    this.modalchangepasswordComponent.body_text = `คุณต้องการเปลี่ยนรหัสผ่านสำหรับ ${userItem.user_Name} (${userItem.mobileNo})`;
    this.modalchangepasswordComponent.userItem = userItem;
    this.modalchangepasswordComponent.openmodal(
      this.modalchangepasswordComponent.modalsize
    );
    // this.modalReference = this.modalchangepasswordComponent.modalReference;
  }

  openmodalchangemobilenumber(userItem: any): void {
    this.modalchangemobilenumberComponent.modalsize = undefined;
    this.modalchangemobilenumberComponent.userItem = userItem;
    this.modalchangemobilenumberComponent.userAuthorizationLevelId = userItem.authorizationLevelId || 3;
    this.modalchangemobilenumberComponent.body_title =
      'ต้องการเปลี่ยนเบอร์มือถือ(ที่ใช้ในการเข้าสู่ระบบ) ?';

    this.modalchangemobilenumberComponent.openmodal(
      this.modalchangemobilenumberComponent.modalsize
    );
  }

  openmodalchangeemail(userItem: any): void {
    this.modalchangeemail.modalsize = undefined;
    this.modalchangeemail.userItem = userItem;
    this.modalchangeemail.body_title = 'ต้องการเปลี่ยนอีเมล?';
    const modalChangeEmailRef = this.modalchangeemail.openmodal(
      this.modalchangeemail.modalsize
    );

    modalChangeEmailRef.closed.subscribe(res => {
      if (res) {
        this.getDriverList();
      }
    })
  }

  async updateUserIsEnabled(userItem: any): Promise<void> {
    await this.driverDetailService.updateUserIsEnabledByUserId(userItem)
      .subscribe(res => {
        if (res.isSuccess) {
          console.log('update isEnabled success');
        }
      },(err)=>{
        this._noti.toasterror(err.message,'');
        console.log(err);
        userItem.isEnabled = !userItem.isEnabled;
      });
  }

  addUser(): void {
    this.isEdit = false;
    this.isViewUser = false;
    openModal('#EditUser');
  }


  openImportFileModal(): void {
    this._UserEvent.SaveUserAction("เพิ่มคนขับใหม่ด้วย Excel")
    this.modalImportFile.dataModal = {
      title: 'เพิ่มบัญชีผู้ขับขี่รถบรรทุกด้วย Excel',
      description: 'จะช่วยให้คุณสามารถเพิ่มบัญชีผู้ขับขี่รถบรรทุก ได้อย่างรวดเร็วและง่ายดายทีเดียวหลายๆ บัญชี โหลดเลย',
      placeholder: 'อัพโหลดไฟล์ Excel (.xlsx)',
      maxDataFileSize: 20 * 1024 * 1024,
      acceptType: '.xlsx',
      fileTemplatePath: 'assets/files-template/ONETIME_ADD DRIVER_360TRUCK_ต้นฉบับ-v0.3.xlsx',
      iconUpload: 'fa-file-plus',
      iconHeader: 'fa-file-excel'
    } as ModalImportFileModel;

    const modalRef = this.modalImportFile.open('lg');
    modalRef.closed.subscribe((file: File) => {
      if (file) {
        this.addDriverService.import(file).then(res => {
          if (res.isSuccess) {
            console.log(res);
            const json = JSON.stringify(res.data);
            console.log(json);
            this.validateResponse = res.data;

            console.log(this.validateResponse);
            this.openPreviewImportDataModal(this.validateResponse);
          } else {
            this.modalConfirm.modalConfig = {
              title: 'ไม่สามารถทำรายการได้!',
              description: 'ขออภัยค่ะ ไม่สามารถทำรายการได้ เนื่องจากข้อมูลไม่ครบถ้วน โปรดตรวจสอบไฟล์ Excel และทำการอัปโหลดใหม่อีกครั้ง ขอบคุณค่ะ',
              iconClassName: 'fal fa-exclamation-triangle fa-5x text-warning',
              buttonList: [
                { text: 'ตกลง', class: 'btn-warning', comfirm: true },
              ],
            };
            this.modalConfirm.open('sm');
          }
        });
      }
    });
  }

  openPreviewImportDataModal(dataVerifys: ReturnValidateDriverExcel[]): void {
    this.modalPreviewImportData.dataModal = {
      title: 'ตรวจสอบข้อมูลในการนำเข้าผู้ขับขี่รถบรรทุก',
      description: 'จะช่วยให้คุณสามารถเพิ่มข้อมูลผู้ขับขี่รถบรรทุกที่ให้บริการได้อย่างรวดเร็ว และง่ายดายทีเดียวหลาย ๆ คน โหลดเลย',
      iconHeader: 'fa-file-excel',
      dataPreviewList: dataVerifys
    } as ModalPreviewImportDataModel;
    const modalRef = this.modalPreviewImportData.open('lg');
    modalRef.closed.subscribe(res => {
      console.log('res modalRef.closed', res);
      if (res) {
        const dataCorrectList = dataVerifys.filter(e => e.isCorrect).map(r => {
          return r.record;
        }) as IDriverImportModel[];

        console.log('dataCorrectList', dataCorrectList);
        this.addDriverService.onConfirmImport(dataCorrectList).then(response => {
          console.log('saveCompanyTruckDataImport res', res);
          if (response.isSuccess) {
            console.log(response)
            this.openImportResultModal(response.data);
          }
        });
      }
    });
  }

  openImportResultModal(data:ModalImportResultModel): void {
    this.modalImportResult.dataModal = {
      iconHeader: 'fa-file-excel text-success',
      iconContent: 'fa-check-circle text-success',
      title: 'นำเข้าข้อมูลคนขับสำเร็จ',
      successTotal: data.successTotal,
      failedTotal: data.failedTotal,
      failedList: data.failedList,
      ...data
    } as ModalImportResultModel;
    const modalRef = this.modalImportResult.open('sm');
    modalRef.closed.subscribe((res: boolean) => {
      if (res) {
        // refresh
        this.getDriverList();
      }
    });
  }

  openmodaleditdriverform(tst360UserId: number, isView = false) {
    this.modaleditdriverform.modalsize = undefined;
    const modalRef = this.modaleditdriverform.openmodal(this.modaleditdriverform.modalsize);

    console.log('get edit user', tst360UserId);
    //this.pageload.show();
    this._ClientUserService
      .GetClientUserById(tst360UserId)
      .subscribe((res: TMSAPIResponse) => {
        //this.pageload.hide();
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          if (res.isSuccess) {
            this.isEdit = true;
            this.isViewUser = isView;
            this.isDriver = true;
            const userData = res.data;
            console.log('userData : ', userData);
            console.log(
              'userData.userRoleDto.isActive : ',
              userData.userRoleDto.isActive
            );
            this.userEditData = new AddClientUserModel();
            this.userEditData.clientID = userData.clientID;
            this.userEditData.tsT360_CompanyID = userData.tsT360_CompanyID;
            this.userEditData.tsT360_UserID = userData.tsT360_UserID;
            this.userEditData.username = userData.username;
            this.userEditData.phoneNumber = userData.phoneNumber;
            this.userEditData.email = userData.email;
            this.userEditData.firstName = userData.firstName;
            this.userEditData.lastName = userData.lastName;
            this.userEditData.photoURL = userData.photoURL;
            this.userEditData.idCard = userData.iDcard;
            this.userEditData.taxId = userData.taxId;
            this.userEditData.roleUser.isActive = userData.userRoleDto.isActive;

            this.userEditData.tsT360_DefaultUserServiceType = 2;
            this.userEditData.departmentId = userData.departmentDto.id;

            this.userEditData.roleId = userData.userRoleDto.roleID;
            this.userEditData.teamIds.push(userData.teamDto.id);

            this.userEditData.teamUser = userData.teamUserDto;
            this.userEditData.roleUser = userData.userRoleDto;
            this.userEditData.userIDCard = userData.userIDCardDto;
            this.userEditData.userDriverLicence = userData.userDriverLicenceDto;

            console.log('map team id: ', userData.teamUserDto);
            if (userData.teamUserDto) {
              this.userEditData.teamIds = userData.teamUserDto.map((m) => m.teamID);
            }

            this.userEditData.tstUserId = userData.identityUserDto?.tstUserId;
            console.log('userEditData : ', this.userEditData);

            this.userEditData.birthDate = userData.birthDate
          }
        }
      }, err => {
        //this.pageload.hide();
      });

      modalRef.closed.subscribe(res => {
        if(res) {
          this.getDriverList();
        }
      })
  }

  openmodalviewdriverform(tst360UserId: number, isView = true) {
    this.modaleditdriverform.modalsize = undefined;
    const modalRef = this.modaleditdriverform.openmodal(this.modaleditdriverform.modalsize);

    console.log('get edit user', tst360UserId);
    //this.pageload.show();
    this._ClientUserService
      .GetClientUserById(tst360UserId)
      .subscribe((res: TMSAPIResponse) => {
        //this.pageload.hide();
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          if (res.isSuccess) {
            this.isEdit = true;
            this.isViewUser = isView;
            this.isDriver = true;
            const userData = res.data;
            console.log('userData : ', userData);
            console.log(
              'userData.userRoleDto.isActive : ',
              userData.userRoleDto.isActive
            );
            this.userEditData = new AddClientUserModel();
            this.userEditData.clientID = userData.clientID;
            this.userEditData.tsT360_CompanyID = userData.tsT360_CompanyID;
            this.userEditData.tsT360_UserID = userData.tsT360_UserID;
            this.userEditData.username = userData.username;
            this.userEditData.phoneNumber = userData.phoneNumber;
            this.userEditData.email = userData.email;
            this.userEditData.firstName = userData.firstName;
            this.userEditData.lastName = userData.lastName;
            this.userEditData.photoURL = userData.photoURL;
            this.userEditData.idCard = userData.iDcard;
            this.userEditData.taxId = userData.taxId;
            this.userEditData.roleUser.isActive = userData.userRoleDto.isActive;

            this.userEditData.tsT360_DefaultUserServiceType = 2;
            this.userEditData.departmentId = userData.departmentDto.id;

            this.userEditData.roleId = userData.userRoleDto.roleID;
            this.userEditData.teamIds.push(userData.teamDto.id);

            this.userEditData.teamUser = userData.teamUserDto;
            this.userEditData.roleUser = userData.userRoleDto;
            this.userEditData.userIDCard = userData.userIDCardDto;
            this.userEditData.userDriverLicence = userData.userDriverLicenceDto;

            console.log('map team id: ', userData.teamUserDto);
            if (userData.teamUserDto) {
              this.userEditData.teamIds = userData.teamUserDto.map((m) => m.teamID);
            }

            this.userEditData.tstUserId = userData.identityUserDto?.tstUserId;
            console.log('userEditData : ', this.userEditData);

            this.userEditData.birthDate = userData.birthDate
          }
        }
      }, err => {
        //this.pageload.hide();
      });

      modalRef.closed.subscribe(res => {
        if(res) {
          this.getDriverList();
        }
      })
  }

  async AfterFormSubmit(event: any) {
    if (event) {
      // await this.getDriver();
      closeModal('#EditUser');
    }
  }


  openmodaladddriverform() {
    this.isDriver = true;
    this._UserEvent.SaveUserAction("เพิ่มคนขับใหม่");
    this.modaladddriverform.modalsize = undefined;
    this.modaladddriverform.IsDriver = true;
    const modalRef = this.modaladddriverform.openmodal(this.modaladddriverform.modalsize);
    modalRef.closed.subscribe(res => {
      console.log(res);

      if(res) {
        console.log('openmodaladddriverform',res);

        this.getDriverList();
      }
    })
  }

  openQRReaderModal() {
    this.openQR = true;
    setTimeout(() =>
      openModal("#qrReader")

      , 500)
  }

  camClosed() {
    this.openQR = false;
  }

  lookingTruckLocation(): void {
    alert()
    this.lookingTruckLocationEvent.emit(true);
  }

  searchFilterDriver(filter: any) {
    this.filter = filter
    console.log(filter);
    console.log('driver', this.driverList);
    this.getDriverList();
  }

  clearFilter() {
    this.filter = {
      textSearch: null,
      isEnabled: null,
      driverLicenceStatusId: null,
      shipmentStatusId: null
    }
    this.getDriverList();
  }

  pageChanged(event) {
    console.log('pageChanged', event);

    this.driverPaging = event;
  }

  SetPaginationData() {
    this.driverDetailService.driverListOnChange.pipe(takeUntil(this.destroy$)).subscribe(res => {
      // console.log('driverListOnChange', res);
      this.driverList = res;
      this.driverPaging = 1;
      this.TotalData = this.driverList.length;
      // if (this.TotalData > 0) {
      //   this.reInitDatatable();
      // }
      // this.reInitDatatable();
    })
  }

  private reInitDatatable(): void {
    console.log("retable")
    if (this.tableWidget) {
      this.tableWidget.clear();
      this.tableWidget.destroy();
      this.tableWidget = null;
      this.changeRef.detectChanges();
    }
    setTimeout(() => {
      this.tableWidget = initDataTable('driverListDataTable', false, false, false, false, false);
      // this.tableWidget.init()

    }, 100);
  }

  getClientFleetList(): void {
    this.truckManagementService.getClientFleet().pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.fleetList = res?.data;
        console.log('getClientFleetList', this.fleetList);

      })
  }

  openChangeFleetDriverModal(item: DriverList): void {
    console.log(item);

    // this.ModalChangeFleetDriver.fleetList = this.fleetList;
    this.ModalChangeFleetDriver.companyFleetNo = item?.companyFleetNo || 0;
    this.ModalChangeFleetDriver.user_ID = item.user_ID;
    const modalRef = this.ModalChangeFleetDriver.open('md');
    modalRef.closed.subscribe((res: boolean) => {
      console.log('closed ChangeFleetModal', res);
      if (res) {
        // this.refreshEvent.emit(res);
        const modalSuccessConfig = {
          elementId: 'modal-success',
          title: 'เรียบร้อยแล้ว',
          description: 'บันทึกข้อมูลเรียบร้อยแล้ว',
          buttonSubmitText: 'ตกลง',
          iconClassName: 'fas fa-check',
          iconClassColor: '',
          isNextButtonAction: false,
          nextButtonActionText: 'ต้องการ',
          nextButtonActionIcon: ''
        }
        this.driverDetailService.getDriverFleet(this.fleetId);
        this.onModalSuccessOpen(modalSuccessConfig);
      }
    })
  }

  onModalSuccessOpen(modalSuccessConfig: ModalSuccessModel): void {
    this.modalSuccess.modalConfig = modalSuccessConfig;
    this.modalSuccess.modalConfig.nextButtonActionText = 'ปิด';
    const modalRef = this.modalSuccess?.open('sm');
    modalRef.closed.subscribe(res => {
      return res;
    });
  }

  getDriverList(): void {
    // //this.pageload.show();
    this.driverDetailService.getDriverFleet(this.selectedFleet != 0 ? this.selectedFleet : null, this.filter)
      .then(res => {
        // debugger
        this.totalDriver = res.length
        this.TotalData = this.totalDriver;
      })

  }

  async onDeleteIdentity(driver: DriverList) {
    console.log('userId', driver);

    const modalConfirmConfig = {
      title: 'ลบคนขับ',
      description: `คุณต้องลบคนขับ ${driver.firstName} ${driver.lastName} ออกจากระบบหรือ ไม่ ?`,
      iconClassName: 'fal fa-exclamation-triangle text-danger',
      buttonList: [
        { text: 'ใช่ ต้องการ', class: 'btn-danger', comfirm: true },
        { text: 'ปิด', class: 'btn-outline-secondary', comfirm: false },
      ],
    };
    this.modalConfirm.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirm.open('sm');

    modalRef.closed.subscribe(res => {
      if (res) {
        this.driverDetailService
          .deleteUserIdentity(driver.user_ID,driver.tst360UserServiceId)
          .subscribe((res: any) => {
            console.log('delete', res);
            if(res.isSuccess){
              this.getDriverList();
              this._noti.toastsuccess('ลบข้อมูลเรียบร้อย', '');
            }else{
              this._noti.toasterror('ลบข้อมูลไม่สำเร็จ', res.message);
            }
          });
      }
    })
  }

  success_other(value: any) {
    console.log("test");
    this.modalSuccess.modalReference.close();
  }
  selectedDriver = [];
  selectedDriverIds = [];
  addFleetDriverModal() {
    this.selectedDriver = []
    this.selectedDriverIds = []
    openModal("#addFleetDriver")
  }

  getDriverAge(dateOfBirth:Date){
    //console.log(dateOfBirth)
    let bd = new Date(dateOfBirth)
    let age = this.curentYear - bd.getFullYear();

    return age+" ปี"
  }
}
