import { Injectable } from '@angular/core';
import { UserIDCardPath } from '@app/core/_Constants/apiConstants';
import { HttpService } from '@app/core/_Services';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserIDCardService {
    constructor(
        private _apiService: HttpService
    ) { }
    
    addUserIDCard(dataReq:any):Observable<any>{
        return this._apiService.post(`${UserIDCardPath.UserIDCard}`,dataReq)
    }
}