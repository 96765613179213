import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateClientUserDto, BaseClientUserDto, IdentityUserDto } from '../../models/EditUser';

@Component({
  selector: 'app-modaleditprofile',
  templateUrl: './modaleditprofile.component.html',
  styleUrls: ['./modaleditprofile.component.scss']
})
export class ModaleditprofileComponent implements OnInit, OnChanges {
  constructor() { }
  ngOnChanges() {
    this.setform();
  }

  @Input() Profile: any;

  ngOnInit(): void {

  }
  Active = [
    { id: false, name: 'ระงับการใช้งาน' },
    { id: true, name: 'เปิดใช้งาน' },
  ];
  Team = [
    { id: 1, name: 'Team A' },
    { id: 2, name: 'Team B' },
  ];
  role = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'User' },
  ];
  Department = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'User' },
    { id: 8, name: 'Mangeger' },
  ];

  EditProfile = new FormGroup({
    FristName: new FormControl(),
    LastName: new FormControl(null, Validators.required),
    Email: new FormControl(),
    Phone: new FormControl(),
    StatusUser: new FormControl(1),
    Username: new FormControl(),
    Department: new FormControl(1),
    Role: new FormControl(1),
    Team: new FormControl(1),
  });
  setform() {
    this.EditProfile.controls.FristName.setValue(this.Profile.data.firstName);
    this.EditProfile.controls.LastName.setValue(this.Profile.data.lastName);
    this.EditProfile.controls.Email.setValue(this.Profile.data.email);
    this.EditProfile.controls.Phone.setValue(this.Profile.data.phoneNumber);
    this.EditProfile.controls.StatusUser.setValue(this.Profile.data.isSuccess);
    this.EditProfile.controls.Username.setValue(this.Profile.data.username);
    this.EditProfile.controls.Department.setValue(this.Profile.data.departmentDto.id);
    this.EditProfile.controls.Role.setValue(2);
    this.EditProfile.controls.Team.setValue(2);
  }
  async onSubmit() {
    let UpdateClientUser = new UpdateClientUserDto;

    let BaseClientUser = new BaseClientUserDto;

    BaseClientUser.ClientID =this.Profile.data.clientID;
    BaseClientUser.TST360_CompanyID = this.Profile.data.tsT360_CompanyID;
    BaseClientUser.TST360_UserID = this.Profile.data.tsT360_UserID;
    BaseClientUser.Username= this.EditProfile.value.username;
    BaseClientUser.PhoneNumber = this.EditProfile.value.Phone;
    // BaseClientUser.Email:string;
    // BaseClientUser.FirstName:string;
    // BaseClientUser.LastName:string;
    // BaseClientUser.PhotoURL:string;
    // BaseClientUser.IDcard =this.Profile.data.iDcard;
    // BaseClientUser.TaxId:string;

    let IdentityUser = new IdentityUserDto;

    UpdateClientUser.ClientID = this.Profile.data.clientID;
    UpdateClientUser.TSTS360_UserID = this.Profile.data.tsT360_UserID;
    UpdateClientUser.TST360_CompanyID = this.Profile.data.tsT360_CompanyID;
    UpdateClientUser.BasicUserClientDto = BaseClientUser;
    UpdateClientUser.IdentityUserClientDto = IdentityUser;
  }
}
