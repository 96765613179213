import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InsureCargoResponse, InsureCarResponse } from '@app/modules/truck-license-management/models/company-truck-doc-insure-model';
import * as moment from 'moment';

@Component({
  selector: 'app-button-insure-reminder',
  templateUrl: './button-insure-reminder.component.html',
  styleUrls: ['./button-insure-reminder.component.scss']
})
export class ButtonInsureReminderComponent implements OnInit {
  @Input() companyDocInsure: any;
  @Input() companyDocTypeId: number = 5;
  @Input() insureCompanyName: string = '';
  @Input() companyDocVerifyStatusId: number = 5;
  @Input() companyDocVerifyStatusShortName: string = '';
  @Input() comDocInsureFiles: any[] = [];

  @Output() buttonSubmitEvent: EventEmitter<any> = new EventEmitter();


  companyDocInsureCar: InsureCarResponse | undefined;
  companyDocInsureCargo: InsureCargoResponse| undefined;
  expireDate: any;
  constructor() { }

  ngOnInit() {
    if (this.companyDocTypeId == 5) {
      this.companyDocInsureCar = this.companyDocInsure
      this.expireDate = this.companyDocInsureCar?.expireDate
    } else {
      this.companyDocInsureCargo = this.companyDocInsure
      this.expireDate = this.companyDocInsureCargo?.cargoExpireDate
    }
  }

  checkReminder(item: any): boolean {
    let result = false;
    const expireDate = this.companyDocTypeId == 5 ? item.expireDate : item.cargoExpireDate
      // debugger;

    if (expireDate) {
      this.expireDate = expireDate
      const expire = moment(expireDate).dayOfYear();
      const expireYear = moment(expireDate).year();
      const now = moment(new Date()).dayOfYear();
      const nowYear = moment(new Date()).year();
      if (expireYear < nowYear)
        return true;
      else if (expireYear <= nowYear) {
        if ((expire - now) <= item.companyDocType?.daysToExpireReminder && (expire - now) > 0) {
          result = true;
        }
      }
    }

    return result;
  }

  checkReminderExpired(item: any): boolean {
    let result = false;
    const expireDate = this.companyDocTypeId == 5 ? item.expireDate : item.cargoExpireDate
      // debugger;
      if (expireDate) {
      const expire = moment(expireDate).dayOfYear();
      const expireYear = moment(expireDate).year();
      const now = moment(new Date()).dayOfYear();
      const nowYear = moment(new Date()).year();
      if (expireYear < nowYear)
        return true;
      else if (expireYear <= nowYear) {
        if ((expire - now) <= item.companyDocType?.daysToExpireReminder && (expire - now) <= 0) {
          result = true;
        }
      }
    }
    return result;
  }

  buttonSubmit(item: any, goto: any) {
    this.buttonSubmitEvent.emit({
      goto: goto,
      item: item,
    });
  }
}
