<div class="flex-1" style="background: url(img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
    <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
        <div class="row">
            <div class="col col-md-6 col-lg-7 hidden-sm-down">
                <h2 class="fs-xxl fw-500 mt-4 text-white">
                    เรียน บริษัท ขนส่งทั่วไทย จำกัด
                    <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60">
                        คุณได้รับเชิญเข้าเป็นผู้ขนส่งของ บริษัท กลุ่มน้ำตาลไทยรุ่งเรื่อง จำกัด
                       <ul>
                           <li>
                            โดยคุณจะต้องมีบัญชี 360TRUCK ในการเข้าสู่ระบบ เพื่อรับการแจ้งงานขนส่ง, ตอบรับวิ่งงานขนส่ง, และอัปเดตสถานะงานขนส่ง ที่คุณได้รับมอบหมายจาก บริษัท กลุ่มน้ำตาลไทยรุ่งเรื่อง จำกัด
                            หากคุณมีบัญชี 360TRUCK อยู่แล้ว โปรดเข้าสู่ระบบด้วยบัญชีและรหัสผ่านเดิมของคุณ
                            หากคุณยังไม่เคยมีบัญชีกับ 360TRUCK โปรดเลือกสมัครสมาชิก
                            โปรดทราบว่าลิงก์เชิญนี้จะสามารถใช้ได้เพียงครั้งเดียว</li>
                       </ul>
                    </small>
                </h2>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
                <h1 class="text-white fw-300 mb-3 d-sm-block d-md-none">
                    Secure login
                </h1>
                <div class="card p-4 rounded-plus bg-faded">
<!--                    <form [formGroup]="loginForm" (ngSubmit)="onLogin()">-->
<!--                        <div class="form-group">-->
<!--                            <label class="form-label" for="username">Username</label>-->
<!--                            <input type="text" id="username" class="form-control form-control-lg {{loginForm.controls.username.invalid?'is-invalid':'is-valid'}}" placeholder="กรอกผู้ใช้งาน" formControlName="username" required>-->
<!--                            <div *ngIf="loginForm.controls.username.invalid" class="invalid-feedback">-->
<!--                                <div *ngIf="loginForm.controls.username.errors.required">-->
<!--                                    กรุณากรอกผู้ใช้งาน-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="form-group">-->
<!--                            <label class="form-label" for="password">Password</label>-->
<!--                            <input type="password" id="password" class="form-control form-control-lg {{loginForm.controls.password.invalid?'is-invalid':'is-valid'}}" placeholder="รหัสผ่าน อย่างน้อย 6 ตัวอักษร" formControlName="password" required>-->
<!--                            <div *ngIf="loginForm.controls.password.invalid" class="invalid-feedback">-->
<!--                                <div *ngIf="loginForm.controls.password.errors.required">-->
<!--                                    กรุณากรอกรหัสผ่าน-->
<!--                                </div>-->
<!--                                <div *ngIf="loginForm.controls.password.errors.minlength">-->
<!--                                    กรุณารหัสผ่าน อย่างน้อย 6 ตัวอักษร-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="form-group text-left">-->
<!--                            <div class="custom-control custom-checkbox">-->
<!--                                <input type="checkbox" class="custom-control-input" id="rememberme" formControlName="remember">-->
<!--                                <label class="custom-control-label" for="rememberme"> Remember me for the next 30-->
<!--                                    days</label>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="row no-gutters">-->
<!--                            <div class="col-12 pl-lg-1 my-2">-->
<!--                                <button *ngIf="!onLoging" type="submit" [disabled]="!loginForm.valid" class="btn btn-success btn-block btn-lg">-->
<!--                                    เข้าสู่ระบบ-->
<!--                                </button>-->
<!--                                <button *ngIf="onLoging" class="btn btn-success btn-block btn-lg" type="button" disabled>-->
<!--                                    <span class="spinner-border spinner-border-sm" role="status"-->
<!--                                        aria-hidden="true"></span>-->
<!--                                    กำลังเข้าสู่ระบบ...-->
<!--                                </button>-->
<!--                            </div>-->
<!--                            <p style="color:red">{{error}}</p>-->
<!--                        </div>-->
<!--                    </form>-->
                    <hr style="border-bottom: 1px dashed #afafaf;">
                    <div class="row ">
                        <div class="col-12">
                            <h5>หากยังไม่มีบัญชี 360TECHX โปรดสมัครสมาชิก</h5>
                        </div>

                        <div class="col-12 pl-lg-1 my-2">
                            <button class="btn btn-outline-success btn-block btn-lg">
                                สมัครสมาชิก 360TECHX
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
