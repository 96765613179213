<form [formGroup]="changeFleetForm">
  <div class="row">
    <div class="col-12">
      <div class="form-check">
        <input class="form-check-input mt-1" type="radio" formControlName="fleetNo" name="fleetNo" id="fleet0"
          [value]="0" checked required [(ngModel)]="fleetNo" (ngModelChange)="fleetNoChange()">
        <label class="form-check-label" for="fleet0">
          ยังไม่ต้องมี FLEET (เปลี่ยนภายหลังได้)
        </label>
      </div>
      <div class="form-check" *ngFor="let item of fleetList; let index = index;">
        <input class="form-check-input mt-1" type="radio" formControlName="fleetNo" name="fleetNo" id="fleet{{index+1}}"
          [value]="item?.companyFleetNo" required [(ngModel)]="fleetNo">
        <label class="form-check-label" for="fleet{{index+1}}">
          FLEET {{item?.companyFleetNo}} : {{item?.fleetName}}
        </label>
      </div>
      <!-- <div class="form-check">
        <input class="form-check-input mt-1" type="radio" name="exampleRadios" id="exampleRadios3" value="option3"
          >
        <label class="form-check-label" for="exampleRadios3">
          FLEET 2 : โก๋แก่มันส์ทุกเม็ดภาคเหนือ
        </label>
      </div> -->
    </div>
  </div>
</form>
