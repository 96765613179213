import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService, HttpService, NotificationService} from 'src/app/core/_Services';
import {AddTeamModel} from '../models/AddTeamModel';
import {UpdateTeamModel} from '../models/UpdateTeamModel';
import {TMSAPIResponse} from 'src/app/core/_Models/TMSAPIResponse';
import {User} from 'src/app/core/_Models/UserModel/User';
import {TeamAPIPath} from 'src/app/core/_Constants/apiConstants';
import {AddTeamUserModel} from "@app/modules/team/models/AddTeamUserModel";
import {Operation} from "@app/core/_Models/UserModel/UserPermission";

@Injectable()
export class TeamService {
  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentUser: User;

  constructor(
    private _apiService: HttpService,
    private _noti: NotificationService,
    private authenticationService: AuthenticationService,
  ) {
    // get current user on login
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);


  }
  GetPermission(moduleId,operationsId):Operation{
    const appModule =  this.authenticationService.UserPermissionModule(moduleId);
    return appModule.operations.find(f=>f.id == operationsId);
  }
  DeleteTeamUser(teamUserId: number): Observable<TMSAPIResponse> {
    return this._apiService.delete(TeamAPIPath.TeamUser + teamUserId + '/' + this.currentUser.id);
  }

  GetTeamUserList(teamId: number): Observable<TMSAPIResponse> {
    return this._apiService.get(TeamAPIPath.GetTeamUserList + teamId);
  }

  AddTeamUser(data: AddTeamUserModel[], teamId: number): Observable<TMSAPIResponse> {
    let teamUser: any[] = [];
    data.map((item) => {
      teamUser.push({
        userID: item.tst360UserId,
        teamID: teamId,
        createdBy: this.currentUser.id
      });
    });
    return this._apiService.post("/TeamUser", {"teamUsers": teamUser});
  }


  GetTeamListWithClient(): Observable<TMSAPIResponse> {
    return this._apiService.get(TeamAPIPath.GetTeamWithClient + this.currentUser.ClientID);
  }

  AddTeam(form: FormGroup): Observable<TMSAPIResponse> {
    let data = new AddTeamModel();
    data.TeamName = form.value.teamName;
    data.TeamNumber = form.value.teamNumber;
    data.TeamStatus = form.value.teamStatus;
    data.IsActive = true;
    data.ClientID = this.currentUser.ClientID;
    data.CreatedBy = this.currentUser.id
    data.teamClientLocationIds = form.value.teamClientLocationIds;
    return this._apiService.postWithoutException("/Team", {"teams": [data]});
  }

  EditTeam(teamID:number, form:FormGroup):Observable<TMSAPIResponse> {
      let data = new UpdateTeamModel();
      data.TeamName = form.value.teamName;
      data.TeamNumber = form.value.teamNumber;
      data.TeamStatus = form.value.teamStatus;
      data.IsActive = form.value.teamStatus;
      data.teamClientLocationIds = form.value.teamClientLocationIds;
      console.log(data)
      return  this._apiService.patch("/Team/" + teamID + "/" + this.currentUser.id, data);
  }

  DeleteTeam(teamID : number ):  Observable<TMSAPIResponse> {
     return  this._apiService.delete("/team/" + teamID + "/" + this.currentUser.id);
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
