<ng-template #modalImportResult let-modal>
  <div class="modal-header" style="border-bottom: 1px solid #ccc;">
    <div class="modal-title w-100">
      <button type="button" class="close pt-1 pl-0 pb-0 pr-1" aria-label="Close" data-dismiss="modal"
        (click)="close(false)">
        <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
      </button>
      <div>
        <span class="h4"> <i class="fas" [ngClass]="dataModal?.iconHeader"></i> {{dataModal?.title}}</span>
      </div>
    </div>
  </div>
  <div class="modal-body pb-0">
    <div class="row">
      <div class="col-12 text-center">
        <span><i class="fal fa-7x" [ngClass]="dataModal?.iconContent"></i></span>
      </div>
    </div>

    <div class="row m-0 mt-3">
      <div class="col-8 p-0">
        <span class="">รายการที่นำเข้าสำเร็จ</span>
      </div>
      <div class="col-2 p-0"><span class="h5 text-success">{{dataModal?.successTotal | number}}</span></div>
      <div class="col-2 p-0"><span class="">รายการ</span></div>
    </div>
    <div class="row m-0">
      <div class="col-8 p-0">
        <span class="">รายการที่ยังเกิดข้อผิดพลาด</span>
      </div>
      <div class="col-2 p-0"><span class="h5 text-danger">{{dataModal?.failedTotal | number}}</span></div>
      <div class="col-2 p-0"><span class="">รายการ</span></div>
    </div>
    <div class="row m-0">
      <div *ngFor="let element of dataModal?.failedList">
        <span class="h5">{{'แถวที่ ' + element.rowIndex + ': '}}</span>
        <span class="h5 text-danger" >{{element?.errorMsg}}</span>
      </div>
      <!-- <ul>
        <li *ngFor="let item of dataModal?.failedList">{{item}}</li>
      </ul> -->
    </div>
  </div>
  <!-- Footer -->
  <div class="p-2">
    <div class="row m-1 text-center">
      <div class="col-12">
        <button type="button" class="btn btn-success waves-effect waves-themed btn-block"
          (click)="close(true)">ปิด</button>
      </div>
    </div>
  </div>
  <!-- Footer -->
</ng-template>

