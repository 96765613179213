
<ng-template #modalaccountform let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
        <button type="button" class="btn btn-sm-custom btn-outline-danger waves-effect waves-themed" aria-hidden="true"
            data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <i class="fal fa-times"></i>
        </button>
    </div>

<form [formGroup]="accountForm">

    <div class="modal-body">
        <div class="container-fluid">
            <div class="row mt-2">
                <div class="col-12">
                    <label class="form-label" for="firstName">รูปสมุดบัญชี <span class="txt-red">*</span></label>
                    <!-- <div class="custom-dropzone dropzone" ngx-dropzone>
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fal fa-cloud-upload text-muted mb-3"></i> <br>
                                <span class="text-uppercase">Drop files here or click to upload.</span>
                                <br>
                            </div>
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true">
                            <ngx-dropzone-label> </ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                        <ngx-dropzone-preview [removable]="true">
                            <ngx-dropzone-label></ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </div> -->
                    <div class="row">
                        <div *ngFor="let image of trainingImage" class="col-6 text-center mb-3">
                            <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                [imageSrc]="image.ImageUrl" [extension]="image.ImageExtension!" [removable]="true"
                                (removed)="ngxdropzoneimage_old_delete(image)">
                            </ngx-custom-dropzone-image-preview>

                        </div>
                        <div class="col-6 text-center mb-3" *ngFor="let f of trainingNewImage">
                            <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f"
                                [removable]="true" (removed)="ngxdropzoneimage_remove(f)">
                            </ngx-custom-dropzone-image-preview>
                            <ngx-dropzone-label>{{f.name}}</ngx-dropzone-label>
                        </div>
                        <div *ngIf="this.trainingNewImage.length + this.trainingImage.length < 1" class="col-12 col-lg-12 text-center">
                            <div id="ngxdropzoneimage-insurecar" class="custom-ngx-dropzone" ngx-dropzone
                                [accept]="'image/jpg, image/jpeg, image/png, .pdf'" [maxFileSize]="10*(1024*1024)"
                                [multiple]="true" (change)="ngxdropzoneimage_change($event)">
                                <ngx-dropzone-label>
                                    กดเพื่อเลือกรูปภาพ,หรือวางไฟล์ที่นี่

                                    <br />
                                    <small>(ขนาดไฟล์สูงสุด 10 MB)</small>
                                </ngx-dropzone-label>
                            </div>
                            <span class="text-danger"
                                *ngIf="this.trainingNewImage.length + this.trainingImage.length > 1">คุณสามารถอัปโหลดรูปภาพได้สูงสุด
                                1 รูป</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12">
                    <div class="form-group">
                        <label class="form-label">ธนาคาร<span class="txt-red">*</span></label>

                        <ng-select [items]="banks" bindLabel="fullName" formControlName="bankName_ID" bindValue="id" [(ngModel)]="selectedBank"
                            clearable>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="form-label">เลขบัญชีธนาคาร 10 หลัก<span
                                class="txt-red">*</span></label>

                        <div class="input-group">
                            <input class="form-control" formControlName="accountNo" type="text" mask="0-000-00000-0"
                                placeholder="เลขบัญชีธนาคาร 10 หลัก" />
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label class="form-label">ชื่อบัญชี (ตามที่ปรากฎอยู่บนสมุดบัญชี)<span
                                class="txt-red">*</span></label>

                        <div class="input-group">
                            <input class="form-control" formControlName="accountName" type="text" placeholder="ตัวอย่าง: อุดม ทำดี" />
                        </div>

                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="form-label">สาขา (ตามที่ปรากฎอยู่บนสมุดบัญชี)<span
                          class="txt-red">*</span></label>

                        <div class="input-group">
                            <input class="form-control" formControlName="bankBranch" type="text" placeholder="ตัวอย่าง: เดอะ ไนน์ พระราม 9" />
                        </div>

                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" formControlName="defaultFlag" class="custom-control-input" id="defaultChecked" disabled>
                            <label class="custom-control-label" for="defaultChecked">ตั้งเป็นบัญชีหลัก</label>
                        </div>

                    </div>
                </div>
            </div>



        </div>

    </div>

    <div class="card-footer">
        <div class="row pb-2 mt-2">
            <div class="col-md-6">
                <button class="btn btn-outline-secondary btn-block" (click)="modal.dismiss('Cross click')">ปิดหน้านี้</button>
            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-block" (click)="onSubmit()" [disabled]="accountForm.invalid || (this.trainingNewImage.length + this.trainingImage.length < 1)">บันทึก</button>
            </div>
        </div>
    </div>
</form>

</ng-template>

<app-modalsuccess #modalsuccess (btnother_event)="success_other($event)" [btnsubmit_ishide]=true></app-modalsuccess>

<app-modal-confirm #modalConfirm></app-modal-confirm>
