import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { NoficationserviceService } from '@app/core/_Template/service/noficationservice.service';
import { Operation, PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { ServicesettingService } from '@app/modules/setting/service/servicesetting.service';
import { title } from 'process';
import { ActionList, Appmodule, ClientRoles, MenuListDto } from '../../model/PermissSetting';
import {
  CorporatePackage,
  Titleshow,
  Subtitle,
  Permid,
} from '../../model/settingmodule';
import { Pagelist, PageLists, Permidview } from '../../model/viewmodel';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-SettingPermission',
  templateUrl: './SettingPermission.component.html',
  styleUrls: ['./SettingPermission.component.scss'],
})
export class SettingPermissionComponent implements OnInit, AfterViewInit {
  constructor(private activedRoute: ActivatedRoute, private router: Router, private notiservice: NoficationserviceService
    , private authService: AuthenticationService,private _noti : NotificationService
    ,private sv : ServicesettingService) {
      this.authService.currentUser.subscribe((x) => {this.user = x;
        var p: PermissionModule = this.authService.UserPermissionModule(90000);
        if (p) {
          console.log('permission', p);

          this.permission90300 = this.authService.FindPermissionAction(
            90300,
            p.operations
          );

        }
          });
  }
  user: User;
  corporatePackage: CorporatePackage = new CorporatePackage();
  AppPermiss: Array<Appmodule> = new Array<Appmodule>();
  Action: Array<ActionList> = new Array<ActionList>();
  ClientRole: Array<ClientRoles> = new Array<ClientRoles>();
  MenuListWeb: Array<MenuListDto> = new Array<MenuListDto>();

  IsSaveing:boolean = false;
  Onload:boolean = false;
  SelectRole = new FormGroup({
    RoleId: new FormControl(),
  });
permission90300 : PermissionGroup= {
  canApprove: false,
  canPublish: false,
  canEdit: false,
  canDelete: false,
  canRead: false,
};
  SelectMenu = new FormGroup({
    RoleId: new FormControl(),
  });

  ngOnInit(): void {

    this.getAction();
    this.getClientRole();

  }

  ngAfterViewInit(): void {
    this.activedRoute.queryParams.subscribe((param) => {
      const tab = param['tab'];
      if (tab) {
        switch (tab) {
          case 'menu': {
            console.log('click menu');
            document.getElementById('menu-tab').click();
            break;
          }
          case 'access': {
            console.log('click access');
            document.getElementById('access-tab').click();
            break;
          }
          default: {
            console.log('click default menu');
            this.router.navigate(['/permis'], { queryParams: { tab: 'menu' } });
            break;
          }
        }
      }
    });
  }

  clickTab(tab: string): void {
    if (tab === 'menu') {
      this.router.navigate(['/permis'], { queryParams: { tab: 'menu' } });
    } else if (tab === 'access') {
      this.router.navigate(['/permis'], { queryParams: { tab: 'access' } });
    }
  }

  // async getMenuSetting() {
  //   await this.notiservice
  //     .GetMenu(this.user.tstS360_UserID)
  //     .subscribe((data) => {
  //       console.log('MenuSetting',data);
  //     });
  // }

  async getPermiss(ClientRoleId:number) {
    //this.pageLoading.show();
    await this.notiservice
      .GetPermiss(ClientRoleId)
      .subscribe((data) => {
        if(data?.isSuccess)
        {
          this.AppPermiss = data.data;
          //this.pageLoading.hide();
          console.log(this.AppPermiss)
        }
      });
  }

  async getSettingMenu(ClientRoleId:number) {
    //this.pageLoading.show();
    await this.notiservice
      .GetSettingMenu(ClientRoleId)
      .subscribe((data) => {
        if(data?.isSuccess)
        {
          this.MenuListWeb = data.data;
          //this.pageLoading.hide();
          //console.log('ClientRole',JSON.stringify(data.data));
        }
      });
  }

  async getAction()
  {
    await this.notiservice
    .GetAction()
    .subscribe((data) => {
      if(data?.isSuccess)
      {
        this.Action = data.data;
        console.log( this.Action)
      }
    });
  }

  async getClientRole()
  {
    await this.notiservice
    .GetClientRole(this.user.ClientID)
    .subscribe((data) => {
      if(data?.isSuccess)
      {
        this.ClientRole =data.data;
        this.ClientRole = this.ClientRole.filter((m) => m.roleId_FK < 999);
        console.log('this.ClientRole',this.ClientRole);
        this.SelectRole.controls.RoleId.setValue(this.ClientRole[0].id);
        this.SelectMenu.controls.RoleId.setValue(this.ClientRole[0].id);
        this.getPermiss(this.SelectRole.value.RoleId);
        this.getSettingMenu(this.SelectMenu.value.RoleId);
        //console.log('ClientRole', this.ClientRole)
        //console.log('ClientRole',JSON.stringify(data.data));
      }
    });
  }

  OnClientRoleChange()
  {
    this.getPermiss(this.SelectRole.value.RoleId);
  }

  OnClientMenuChange()
  {
    this.getSettingMenu(this.SelectMenu.value.RoleId);
  }


  OnSubmitAppModule()
  {
    this.IsSaveing =true;
    this.notiservice
    .UpdatePermiss(this.AppPermiss,this.SelectRole.value.RoleId,this.user.tstS360_UserID.toString())
    .subscribe((data) => {
      if(data?.isSuccess)
      {
        this.IsSaveing =false;
        this.getPermiss(this.SelectRole.value.RoleId);
        this._noti.toastsuccess('บันทึกสำเร็จ','');
        if (this.SelectRole.value.RoleId == this.user.Role.id) {
          this.authService.loginCheck()
          .pipe(first())
          .subscribe({
            next: async (res: any) => {
              await this.authService.SetUserDataCookieLogin(res);
            }
          })
        }

      }else{
        this.IsSaveing =false;
        this._noti.toasterror('บันทึกไม่สำเร็จ','');
      }
    });
  }

  OnSubmitMenuModule()
  {
    this.IsSaveing =true;
    this.notiservice
    .UpdateMenu(this.MenuListWeb,this.SelectMenu.value.RoleId,this.user.tstS360_UserID.toString())
    .subscribe((data) => {
      if(data?.isSuccess)
      {
        this.IsSaveing =false;
        this.getSettingMenu(this.SelectMenu.value.RoleId);
        this._noti.toastsuccess('บันทึกสำเร็จ','');
      }else{
        this.IsSaveing =false;
        this._noti.toasterror('บันทึกไม่สำเร็จ','');
      }
    });
  }

}
