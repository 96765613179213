import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import {
  Operation,
  PermissionModule,
} from '@app/core/_Models/UserModel/UserPermission';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import { AddTeamUserModel } from '@app/modules/team/models/AddTeamUserModel';
import { TeamListModel } from '@app/modules/team/models/TeamListModel';
import { ClientUserService } from '@app/modules/team/services/client-user.service';
import { TeamService } from '@app/modules/team/services/team.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/_Models/UserModel/User';
import {
  AuthenticationService,
  NotificationService,
  PageloadingService,
} from 'src/app/core/_Services';
import { ServicesettingService } from '../../service/servicesetting.service';

@Component({
  selector: 'app-booking-setting',
  templateUrl: './booking-setting.component.html',
  styleUrls: ['./booking-setting.component.scss'],
})
export class BookingSettingComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  trucktypeData: any;
  trucktypeDataSetting: any;
  SettingRef: any;
  loadingType: any;
  priceunit: any;
  user: User;
  unitPriceSetting: any;
  OnSaveing: boolean = true;
  IsShow: boolean = false;
  teamList: TeamListModel[] = new Array();
  UserList: AddTeamUserModel[] = [];
  permission90100: PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  constructor(
    private serviceSetting: ServicesettingService,
    private authService: AuthenticationService,
    private _noti: NotificationService,
    // private _loading: PageloadingService,
    private _teamService: TeamService,
    private _clintUserService: ClientUserService,

  ) {
    this.authService.currentUser.subscribe((x) => {
      this.user = x;
      var p: PermissionModule = this.authService.UserPermissionModule(90000);
      if (p) {
        console.log('permission', p);
        this.permission90100 = this.authService.FindPermissionAction(
          90100,
          p.operations
        );
      }
    });
  }

  settingform = new FormGroup({
    RefNo1: new FormControl(''),
    RefNo2: new FormControl(''),
    RefNo3: new FormControl(''),
    IsRefNo1: new FormControl(''),
    IsRefNo2: new FormControl(''),
    IsRefNo3: new FormControl(''),

    TypeUnitPrice: new FormControl(''),
    TypeLabor: new FormControl(''),
  });

bookingSetting = new FormGroup({
  clientSettingBookingName: new FormControl(''),
  requireSameTeamToEdit: new FormControl(false),
  requireSameTeamToView: new FormControl(false),
  globalEditPermisTeamId: new FormControl([]),
  globalViewPermisTeamId: new FormControl([]),
  defaultOwnerId: new FormControl(),
  isAutoInput2ndLocFromCustomer: new FormControl(false),
  isAutoRedirectAfterCreateBooking: new FormControl(false),
  clientID: new FormControl(''),
})

  ngOnInit(): void {
    this.getuser();
    this.GetTeamList();
    this.gettrucktype();
    this.getSettingRef();
    this.getSettingloading();
    this.getSettinguntiprice();
    this.getSettinguntipriceUser();
    this.gettrucktypeSetting();
    this.GetBookingSettting();
    this.OnSaveing = false;
  }

  async gettrucktype() {
    // this.trucktypeData = await this.serviceSetting.GetTruckType();
    // console.log(this.trucktypeData);
  }

  async gettrucktypeSetting() {
    this.trucktypeDataSetting = await this.serviceSetting.GetTruckTypeSetting(
      this.user.ClientID
    );
  }
  async GetBookingSettting(){
    var bookingSetting = await this.serviceSetting.GetBookingSetting(this.user.ClientID);
    console.log("Booking",bookingSetting);
     this.bookingSetting.controls.clientSettingBookingName.setValue(bookingSetting.data.clientSettingBookingName)
     this.bookingSetting.controls.globalEditPermisTeamId.setValue(bookingSetting.data.globalEditPermisTeamId)
     this.bookingSetting.controls.isAutoInput2ndLocFromCustomer.setValue(bookingSetting.data.isAutoInput2ndLocFromCustomer)
     this.bookingSetting.controls.isAutoRedirectAfterCreateBooking.setValue(bookingSetting.data.isAutoRedirectAfterCreateBooking)
     this.bookingSetting.controls.requireSameTeamToEdit.setValue(bookingSetting.data.requireSameTeamToEdit)
     this.bookingSetting.controls.requireSameTeamToView.setValue(bookingSetting.data.requireSameTeamToView)
     this.bookingSetting.controls.defaultOwnerId.setValue(bookingSetting.data.defaultOwnerId)
  }
  async getSettingRef() {
    this.SettingRef = await this.serviceSetting.GetRef(this.user.ClientID);

    if (this.SettingRef.data.isRef1 === 'false') {
      this.SettingRef.data.isRef1 = null;
    }

    if (this.SettingRef.data.isRef2 === 'false') {
      this.SettingRef.data.isRef2 = null;
    }

    if (this.SettingRef.data.isRef3 === 'false') {
      this.SettingRef.data.isRef3 = null;
    }
  }

  async getSettingloading() {
    this.loadingType = await this.serviceSetting.Getloading(this.user.ClientID);
  }

  async getSettinguntiprice() {
    this.priceunit = await this.serviceSetting.GetUnitPrice();
  }

  async getSettinguntipriceUser() {
    let data = await this.serviceSetting.GetUnitPriceSetting(
      this.user.ClientID
    );

    if (data.data.laborPriceUnitId == 0) {
      data.data.laborPriceUnitId = null;
    }
    if (data.data.shippingPriceUnitId == 0) {
      data.data.shippingPriceUnitId = null;
    }
    this.unitPriceSetting = data;
  }

  Onsave() {
    this.OnSaveing = true;
    this.SettingRef.data.clientID = this.user.ClientID;
    this.unitPriceSetting.data.clientID = this.user.ClientID;
    this.bookingSetting.controls.clientID.setValue(this.user.ClientID);
    let data = {
      reference: this.SettingRef.data,
      priceUnit: this.unitPriceSetting.data,
      loadingType: this.loadingType.data,
      data: this.trucktypeDataSetting.data,
      bookingSetting : this.bookingSetting.value
    };
    console.log(data)
    ////this._loading.show();
    this.serviceSetting.updateSetting(this.user.id.toString(), data).subscribe(
      (data) => {
        ////this._loading.hide();
        if (data?.isSuccess) {
          this._noti.toastsuccess('บันทึกข้อมูลเรียบร้อย', '');
        } else {
          this._noti.toasterror('บันทึกข้อมูลไม่สำเร็จ', '');
        }
      },
      (err) => {
        ////this._loading.hide();
      }
    );
    this.OnSaveing = false;
  }

  async GetTeamList() {
    this._teamService
      .GetTeamListWithClient()
      .subscribe((res: TMSAPIResponse) => {
        if (!res.isSuccess) {

          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          this.teamList = res.data;
          console.log(this.teamList)
        }
      });
  }

  async getuser() {
    const dept = await this._clintUserService
      .GetClientUserAllList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: TMSAPIResponse) => {
        if (res.isSuccess) {
          console.log(res.data)
          res.data.map((item) => {
            if (item.isActive && item.departmentId == 1) {
              let teamU = new AddTeamUserModel();
              teamU.isSelected = false;
              teamU.clientId = item.clientId;
              teamU.tst360CompanyId = item.tst360CompanyId;
              teamU.tst360UserId = item.tst360UserId;
              teamU.photo = item.photo;
              teamU.fullname = item.fullname;
              teamU.mobileNo = item.mobileNo;
              teamU.email = item.email;
              teamU.departmentName = item.departmentName;
              this.UserList.push(teamU);
            }
          });
        }
      });
  }

}
