<!-- Modal center -->
<button type="button" class="btn btn-success" data-toggle="modal" data-target="#addusermodal">เพิ่มผู้ใช้งาน</button>
<div class="modal fade" id="addusermodal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    เพิ่มผู้ใช้งาน
                </h4>
            </div>
            <div class="modal-body text-left">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtname">ชื่อจริง</label>
                            <input type="text" id="txtname" name="txtname" class="form-control" placeholder="ชื่อจริง">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtsurname">นามสกุลจริง</label>
                            <input type="text" id="txtsurname" name="txtsurname" class="form-control" placeholder="นามสกุลจริง">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="">เบอร์โทร</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i
                                    class="fal fa-phone"></i></span>
                        </div>
                        <input type="text" class="form-control" name="" id="" mask="000-000-0009">
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtemail">e-mail :</label>
                            <input type="email" id="txtemail" name="txtemail" class="form-control" placeholder="e-mail">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txttypeproduct">User Role :</label>
                            <ng-select placeholder="user role">
                                <ng-option [value]="1">Admin</ng-option>
                                <ng-option [value]="21">User</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <!-- DROP ZONE -->
                            <div class="custom-dropzone dropzone" [accept]="'image/*'" ngx-dropzone>
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fal fa-cloud-upload text-muted mb-3"></i> <br>
                                        <span class="text-uppercase">อัปโหลดรูปภาพ</span>
                                        <br>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true">
                                    <ngx-dropzone-label> </ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                            </div>
                            <!-- DROP ZONE -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>
                <button type="button" class="btn btn-success">บันทึกข้อมูล</button>
            </div>
        </div>
    </div>
</div>