import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare function closeModal(modalID): any;

@Component({
  selector: 'app-tab-back-log',
  templateUrl: './tab-back-log.component.html',
  styleUrls: ['./tab-back-log.component.scss']
})
export class TabBackLogComponent implements OnInit {
  
  constructor(private fb: FormBuilder) { }
  @Input() backlogData: any;

  ngOnInit(): void {

    console.log(this.backlogData)
}


}
