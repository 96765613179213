import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppContants} from 'src/app/core/_Constants/appContants';
import {NotificationService, PageloadingService} from 'src/app/core/_Services';
import {ActivatedRoute, Router} from "@angular/router";
import {TeamService} from "@app/modules/team/services/team.service";
import {TeamUserListModel} from "@app/modules/team/models/TeamUserListModel";
import {BehaviorSubject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {TMSAPIResponse} from "@app/core/_Models/TMSAPIResponse";
import {Operation} from "@app/core/_Models/UserModel/UserPermission";

declare function initDataTable(tableID): any;


@Component({
  templateUrl: './teammember.component.html',
  styleUrls: ['./teammember.component.scss']
})
export class TeammemberComponent implements OnInit, OnDestroy {
  teamId: number;
  teamUserList: TeamUserListModel;
  isLoading: boolean = false;
  private tableWidget: any;

  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  permission70300 : Operation;
  constructor(
    // private pagload: PageloadingService,
    private route: ActivatedRoute,
    private router: Router,
    private _teamService: TeamService,
    private _noti: NotificationService
  ) {
    //this.pagload.hide();
    this.teamId = this.route.snapshot.params.teamId;
  }

  async ngOnInit() {
    AppContants.beadcrumb = [];
    AppContants.beadcrumb = [{name: 'ผู้ใช้และทีม', url: '/team'}, {
      name: 'รายชื่อสมาชิกในทีม',
      url: '/team/member/' + this.teamId
    }];
    await this.getTeamUser();
    //Add Team User Permission 70300
    this.permission70300 = this._teamService.GetPermission(70000,70300);
  }

  async getTeamUser() {
    this.isLoading = true;
    const result = await this._teamService.GetTeamUserList(this.teamId).subscribe(async (res: TMSAPIResponse) => {
      if (res.isSuccess) {
        this.teamUserList = res.data;
        await this.reInitDatatable();
        this.isLoading = false;
      } else {
        this._noti.toasterror("ไม่สามารถดึงข้อมูลได้", res.message);
        this.isLoading = false;
      }
    });
  }

  async DeleteTeamUser(teamUserId: number) {
    const conf = await this._noti.swalDeleteConfirm();
    if (conf) {
      const result = await this._teamService.DeleteTeamUser(teamUserId).subscribe(async (res: TMSAPIResponse) => {
        if (res.isSuccess) {
          this._noti.toastsuccess("บันทึกข้อมูลเรียบร้อย", "");
          await this.getTeamUser();
        } else {
          this._noti.toasterror("บันทึกข้อมูลไม่สำเร็จ", res.message);
        }
      });

    }
  }

  private async reInitDatatable() {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
    }
    setTimeout(async () => this.tableWidget = await initDataTable('teamUserListTableId'), 100);
  }

  ngOnDestroy(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  redirectToTeam(): void {
    this.router.navigate(['/team']);
  }
}
