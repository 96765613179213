import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { Observable, Subject,of } from 'rxjs';
import { takeUntil ,map } from 'rxjs/operators';
import { AuthenticationService, HttpService, NotificationService, PageloadingService } from 'src/app/core/_Services';
import { customer, CustomerHeaders, ICustomerImportModel, ImportCustomerExcelRequest } from '../model/CustomerModel';
import * as XLSX from 'xlsx';
import { User } from '@app/core/_Models/UserModel/User';
import { ClientAPIPath, CustomerAPIPath } from '@app/core/_Constants/apiConstants';
import { environment } from 'src/environments/environment';
import { resolve } from 'dns';
import { Customer } from '@app/modules/booking/models/BookingCustomerModel';
@Injectable()


export class CustomerserviceService {
  plannerURL = environment.endpoint_planner + '/location/loadlimit'

  constructor(private _apiService: HttpService, private authen: AuthenticationService,
    private httpClient: HttpClient,
    private _noti: NotificationService,
    // private pageload: PageloadingService,
    ) { }
    destroy$: Subject<boolean> = new Subject<boolean>();
    currentUser: User

  productList = [];

  async GetCustomerList(clientId: number,search:string=""): Promise<any> {
    console.log("search",search);
    var url =`${CustomerAPIPath.GetCustomerListPath}/${clientId}`
    if(search != ""){
      url =`${CustomerAPIPath.GetCustomerListPath}/${clientId}?search=${search}`
    }
    console.log("url",url);


    return new Promise((resolve, reject) => {
      this._apiService
        .get(url)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  GetCustomerRunningNo(clientId: number): Observable<any> {
    return this._apiService.getWithoutHandleError(`${CustomerAPIPath.GetCustomerRunningNo}/${clientId}`)
  }

  async GetCustomerFilter(clientId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/Customer/for-spotrute-filter/${clientId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetCustomerListByPaging(
    userid: number,
    offset?: number,
    limit?: number,
    search?: string
  ): Promise<any> {
    if (!search) {
      return new Promise((resolve, reject) => {
        this._apiService
          .get(
            `/Customer/from-client-id-paging/${userid}?offset=${offset}&limit=${limit}`
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((data: any[]) => {
            resolve(data);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        this._apiService
          .get(
            `/Customer/from-client-id-paging/${userid}?offset=${offset}&limit=${limit}&search=${search}`
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((data: any[]) => {
            resolve(data);
          });
      });
    }
  }

  // async GetLocationList(userid:number):Promise<any> {
  //   return new Promise((resolve,reject)=>{
  //     this._apiService
  //     .get(`/CustomerLocation?ClientID=${userid}`)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((data: any[]) => {
  //       resolve(data);
  //     });
  //   });
  // }

  GetLocationList(clientId: number): Observable<any> {
    return this._apiService.get(`/CustomerLocation?ClientID=${clientId}`);
  }

  // async GetCompanyLocationList(userid:number):Promise<any> {
  //   return new Promise((resolve,reject)=>{
  //     this._apiService
  //     .get(`/ClientLocation?ClientID=${userid}`)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((data: any[]) => {
  //       resolve(data);
  //     });
  //   });
  // }

  GetCompanyLocationList(clientId: number): Observable<any> {
    return this._apiService.get(`/ClientLocation?ClientID=${clientId}`);
  }

  // async GetMasterProduct(userid:number):Promise<any> {
  //   return new Promise((resolve,reject)=>{
  //     this._apiService
  //     .get(`/ClientProduct?ClientID=${userid}`)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((data: any[]) => {
  //       resolve(data);
  //     });
  //   });
  // }

  GetMasterProduct(clientId: number): Observable<TMSAPIResponse> {
    return this._apiService.get(`/ClientProduct?ClientID=${clientId}&Limit=100`);
  }


    GetMasterProductV2(clientId: number,search:string = ""): Observable<TMSAPIResponse>  {
        // If productList is not present, make an HTTP GET request to the API
        return this._apiService.get(`/ClientProduct?ClientID=${clientId}&Limit=50&SearchProduct=${search}`)

    }

    GetMasterProductV2Async(clientId: number,search:string = ""): Promise<TMSAPIResponse>  {
      // If productList is not present, make an HTTP GET request to the API
      return new Promise((resolve, reject) => {
        this._apiService
          .get(`/ClientProduct?ClientID=${clientId}&Limit=50&SearchProduct=${search}`)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data: any) => {
            resolve(data);
          });
      });
  }

    GetMasterProductById(clientProductId: number): Observable<TMSAPIResponse>  {
      // If productList is not present, make an HTTP GET request to the API
      return this._apiService.get(`/ClientProduct/${clientProductId}`)

  }



  GetMasterProductByPaging(
    userid: number,
    offset: number,
    limit: number,
    searchProduct?: string,
    searchProductType?: string
  ): Observable<TMSAPIResponse> {
    let param = '';
    if (searchProduct) {
      param += `&searchProduct=${searchProduct}`;
    }
    if (searchProductType) {
      param += `&searchProductType=${searchProductType}`;
    }
    return this._apiService.get(
      `/ClientProduct?ClientID=${userid}&offset=${offset}&limit=${limit}${param}`
    );
  }

  async GetDimension(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/AppMaster/Dimension`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetPackaging(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/PackagingUnit`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  async GetLocationType(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(`/AppMaster/LocationType`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  updateCustomer(Id: number, name: string, form: customer): Observable<any> {
    return this._apiService.patchWithoutException(`/Customer/${Id}/${name}`, form);
  }

  AddRemark(form: any): Observable<any> {
    return this._apiService.post(`/CustomerRemark`, form);
  }

  GetimageLocationList(userid: number): Observable<TMSAPIResponse> {
    return this._apiService.get(
      `/ClientLocationFileAttach/from-client-location/${userid}`
    );
  }

  //Excel import function
  import(file: File): Promise<TMSAPIResponse> {
    //this.pageload.show();
    let customerImport: ICustomerImportModel[] = [];
    let isFileValid = true;
    return this.getDataFile(file).then(data => {
      customerImport = data;
      return new Promise((resolve, reject) => {
        if (customerImport.length == 0) {
          //this.pageload.hide();
          resolve({
            isSuccess: false,
            statusCode: 400,
            message: 'ไม่พบข้อมูลในไฟล์',
            errorDetail: '',
            data: [],
            dataTotalCount: 0
          });

        } else {

          console.log(customerImport)
          const request = new ImportCustomerExcelRequest;
          request.ExcelData = customerImport;
          let companyId = 0;
          this.authen.currentUser.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            companyId = res.tstS360_CompanyID;
            this.currentUser = res;
            request.CliendId = this.currentUser.ClientID;
            request.UserId = this.currentUser.id;

            if (isFileValid) {
              this._apiService.post(CustomerAPIPath.ValidateCustomerExcel, { RequestData: request })
                .pipe(takeUntil(this.destroy$))
                .subscribe((res: TMSAPIResponse) => {
                  console.log(res.data)
                  //this.pageload.hide();
                  resolve(res);
                });

            } else {
              //this.pageload.hide();
            }

          });
        }
      })
    });
  }

  getDataFile(file: File): Promise<ICustomerImportModel[]> {
    const keys = CustomerHeaders;
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', cellText: true, cellDates: true });
        // console.log('wb.Workbook.WBProps.date1904',wb.Workbook.WBProps.date1904);

        // wb.Workbook.WBProps.date1904
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        let data = XLSX.utils.sheet_to_json(ws, { range: 1, header: keys, raw: false, dateNF: 'yyyy/mmm/dd' }) as ICustomerImportModel[];
        const rowIndex = 2;
        data = data.map((e, index) => {
          return {
            ...e,
            rowIndex: rowIndex + index
          }
        })
        console.log('data', data);
        resolve(data);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    })
  }

  saveCustomerkDataImport(dataReq: ICustomerImportModel[]): Promise<TMSAPIResponse> {
    //this.pageload.show();
    return new Promise((resolve, reject) => {
      let companyId = 0;
      let actionBy = 0;
      this.authen.currentUser.pipe(takeUntil(this.destroy$)).subscribe(res => {
        companyId = res.tstS360_CompanyID;
        actionBy = res.id
      })
      const request = new ImportCustomerExcelRequest;
      request.ExcelData = dataReq;
      request.CliendId = this.currentUser.ClientID;
      request.UserId = this.currentUser.id;
      setTimeout(() => {
        this._apiService.post(CustomerAPIPath.AddCustomerListByExcel, { RequestData: request }).pipe(takeUntil(this.destroy$))
          .subscribe((res: TMSAPIResponse) => {
            console.log(res.data)
            //this.pageload.hide();
            if (res.isSuccess) {
              this._noti.toastsuccess('', "เพิ่มรายการลูกค้าสำเร็จ");
            }
            resolve(res);
          });
      }, 1);
    })
  }

  async createLoadLimit(payload): Promise<any> {
    // const headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', '*/*');
    // headers.append('Access-Control-Allow-Origin', 'No');
    // headers.append('Access-Control-Allow-Credentials', 'true');

    // const url = this.plannerURL + '/create'
    // const response =  fetch(url, {
    //   method: 'POST',
    //   body:JSON.stringify(payload),
    //   headers: headers,
    // });
    // return response;

    const url = this.plannerURL + `/create?tmsLocationId=${payload.tmsLocationId}&clientId=${payload.clientId}&slot=${payload.slot}&size=${payload.size}`
    const response = await fetch(url, {
      method: 'GET',

    });
    return response;
  }

  async updateLoadLimit(payload): Promise<any> {
    const url = this.plannerURL + `/update?id=${payload.id}&slot=${payload.slot}&size=${payload.size}`
    const response = await fetch(url, {
      method: 'GET',

    });
    return response;
  }

  async deleteLoadLimit(id): Promise<any> {
    const url = this.plannerURL + `/delete?id=${id}`
    const response = await fetch(url, {
      method: 'GET',

    });
    return response;
  }

  async getLoadLImit(locationId): Promise<any> {
    const url = this.plannerURL + `/get?tmsLocationId=${locationId}`
    const response = await fetch(url, {
      method: 'GET',

    });
    return response;
  }

   ImportExcelBooking(data: any) {
    return this._apiService.post('/TruckBooking/ImportBookingExcel/', data);

  }

  excelBookingValidate(data: any) {
    return new Promise((resolve, reject) => {
      this._apiService
        .postWithoutException(`/TruckBooking/ImportBookingExcelValidate/`, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
    //return this._apiService.postWithoutException('/TruckBooking/ImportBookingExcelValidate/', data);
  }

  excelAREtg(data: any) {
    return this._apiService.postWithoutException('/TruckBooking/update-etg-ar/', data);
  }

  excelAPARMitsuEtg(data: any) {
    return new Promise((resolve, reject) => {
      this._apiService
        .post(`/TruckBooking/update-mitsubishi-etg-ap-ar`, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any[]) => {
          resolve(data);
        });
    });
  }

  getCustomerOrderCompleteShipmentList(
    params?: HttpParams
  ): Observable<Customer[]> {
    return this._apiService.get(
      CustomerAPIPath.GetCustomerOrderCompleteShipment,
      params
    );
  }
}
