import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RouteDiagramComponent} from "@app/modules/route-diagram/pages/route-diagram/route-diagram.component";

const routes: Routes = [
  //{path: '', redirectTo: '/booking', pathMatch: 'full'},
  //{path: ':bookingId', component: RouteDiagramComponent}
  {path: '', component: RouteDiagramComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RouteDiagramRoutingModule{}
