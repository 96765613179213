import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTimepickerModule, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {

  @Output() change: EventEmitter<string> = new EventEmitter();
  date = new Date();
  time: NgbTimeStruct;
	seconds = true;
  constructor() { }

  ngOnInit() {
    this.time = {
      hour: this.date.getHours(),
      minute: this.date.getMinutes(),
      second: this.date.getSeconds()
    }
  }

  onChange(): void {
    this.change.emit(`${this.time.hour}:${this.time.minute}:${this.time.second}`);
  }

	toggleSeconds() {
		this.seconds = !this.seconds;
	}

}
