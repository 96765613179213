import { HostListener, Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';
import { HttpService } from './http.service';
import { OnbordingRecordService } from './onbording-record.service';
import { OnbordingService } from './onbording.service';

declare function StartIntro(id);
@Injectable({ providedIn: 'root' })
export class IntrojsService {
  screenHeight: number;
  screenWidth: number;
  @HostListener('window:resize', ['$event'])

  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    console.log(this.screenHeight, this.screenWidth);
  }
  constructor(private record: OnbordingRecordService) {
    this.getScreenSize();
  }
  //  async CallStartIntro(elementId:string){

  //   var introguide = introJs();
  //   window.addEventListener('load', function () {
  //       var doneTour = localStorage.getItem('MyTour') === 'Completed';
  //       if (doneTour) {
  //           return ;
  //       }
  //       else {
  //           introguide.start()

  //           introguide.oncomplete(function () {
  //               localStorage.setItem('MyTour', 'Completed');
  //           });

  //           introguide.onexit(function () {
  //               localStorage.setItem('MyTour', 'Completed');
  //           });
  //       }
  //   });

  //   console.log('Intro')
  //   }

  async CallBookingListIntro(): Promise<any> {
    var currentStep = 1;
    var introguide = introJs();
    var test = "test";
    console.log("screenWidth", this.screenWidth);
    var obService = this.record;
    var onbording_step = []

    if (this.screenWidth > 450) {
      onbording_step = [
        {
          // element: '#welcome-intro',
          intro: '<img src="https://tst-web-public.s3.ap-southeast-1.amazonaws.com/TMS/OnBording/step0.jpeg" style="zoom:65% !important;">'
        },
        {
        
          // element: '#slide-intro',
          intro: '<img src="https://tst-web-public.s3.ap-southeast-1.amazonaws.com/TMS/OnBording/box1.png">'
        },
        {
 
          // element: '#welcome-1',
          intro: '<img src="https://tst-web-public.s3.ap-southeast-1.amazonaws.com/TMS/OnBording/box2.png">'

        },
        {
     
          // element: '#welcome-0',
          intro: '<img src="https://tst-web-public.s3.ap-southeast-1.amazonaws.com/TMS/OnBording/box3.png">'

        }
      ]

    } else {
      onbording_step = [
        {
          // element: '#slide-intro',
          intro: '<img src="https://tst-web-public.s3.ap-southeast-1.amazonaws.com/TMS/OnBording/box1-mobile.png">'
        },
        {
          // element: '#welcome-1',
          intro: '<img src="https://tst-web-public.s3.ap-southeast-1.amazonaws.com/TMS/OnBording/box2-mobile.png">'
        },
        {
          // element: '#welcome-0',
          intro: '<img src="https://tst-web-public.s3.ap-southeast-1.amazonaws.com/TMS/OnBording/box3-mobile.png">'
        }
      ]
    }

    var result = await introguide.setOptions({
      tooltipPosition: 'auto',
      positionPrecedence: ['left', 'right', 'top', 'bottom'],
      steps: onbording_step
    }).onchange(function (targetElement) {
      console.log(targetElement.id);
      switch (targetElement.id) {
        case 'welcome-intro': currentStep = 1; break;
        case 'slide-intro': currentStep = 2; break;
        case 'welcome-1': currentStep = 3; break;
        case 'welcome-0': currentStep = 4; break;

        case '': ; break;
      }
    })

      .oncomplete(function () {

        console.log("Complete Intro");
        obService.InsertOnbordingRecord(1, 4)

        return currentStep;
      })
      .onexit(function () {

        obService.InsertOnbordingRecord(1, currentStep)
        return currentStep;
      }).start();

    console.log("result : ", result);

  }


  async CallCreateBookingIntro(): Promise<any> {
    var currentStep = 1;
    var introguide = introJs();
    var test = "test";

    var obService = this.record;
    var result = await introguide.setOptions({
      steps: [
        {
          element: '#create-customer',
          intro: 'เลือกหรือใส่ชื่อลูกค้าและเลขงานขนส่งของคุณ',
          position: 'right'
        },
        {
          element: '#create-product',
          intro: 'ใส่รายละเอียดสินค้าของคุณ',
          position: 'right'
        },
        {
          element: '#create-truck',
          intro: 'ใส่ข้อมูลประเภทรถ และจำนวนรถของคุณ',
          position: 'right'
        },
        {
          element: '#create-locations',
          intro: 'ใส่ข้อมูลจุดรับ-ส่งสินค้า ในงานขนส่งของคุณ หรือ เลือกจากตารางเส้นทางที่คุณบันทึกไว้ได้',
          position: 'right'
        },
        {
          element: '#create-add-locations',
          intro: 'คุณสามารถเพิ่มจุดรับ -ส่งสินค้า ได้โดยการกดปุ่มนี้',
          position: 'right'
        },
        {
          element: '#create-targetprice-recieve',
          intro: 'ระบุค่าบริการที่เก็บกับผู้ว่าจ้าง ',
          position: 'right'
        },
        {
          element: '#create-targetprice-pay',
          intro: 'ระบุค่าใช้จ่ายต่อผู้รับเหมาขนส่ง (คนขับรถ)  ',
          position: 'right'
        },
        {
          element: '#create-doctrurn',
          intro: 'กดเครื่องหมายถูกในช่อง หากต้องการเอกสารขนส่งกลับ ',
          position: 'right'
        },
        {
          element: '#create-draft',
          intro: 'กดเพื่อบันทึกร่างบุ๊คกิ้งนี้',
          position: 'top'
        },
        {
          element: '#create-booking',
          intro: 'กดเพื่อสร้างบุ๊คกิ้งนี้ทันที ',
          position: 'top'
        }
      ]
    }).onchange(function (targetElement) {
      console.log(targetElement.id);
      switch (targetElement.id) {
        case 'create-customer': currentStep = 1; break;
        case 'create-product': currentStep = 2; break;
        case 'create-truck': currentStep = 3; break;
        case 'create-locations': currentStep = 4; break;
        case 'create-add-locations': currentStep = 5; break;
        case 'create-targetprice-recieve': currentStep = 6; break;
        case 'create-targetprice-pay': currentStep = 7; break;
        case 'create-doctrurn': currentStep = 8; break;
        case 'create-draft': currentStep = 9; break;
        case 'create-booking': currentStep = 10; break;

        case '': ; break;
      }
    })

      .oncomplete(function () {

        console.log("Complete Intro");
        return currentStep;
      })
      .onexit(function () {

        obService.InsertOnbordingRecord(2, currentStep)
        return currentStep;
      }).start();

    console.log("result : ", result);

  }


  async CallTruckLicenseListIntro(): Promise<any> {
    var currentStep = 1;
    var introguide = introJs();
    var test = "test";

    var obService = this.record;
    var result = await introguide.setOptions({
      steps: [
        {
          element: '#license-1',
          intro: 'คุณสามารถใส่ข้อมูลฟลีทรถบรรทุกและคนขับรถได้ เพื่อให้ง่ายต่อการจัดการงานขนส่ง',
          position: 'bottom'
        },
        {
          element: '#license-2',
          intro: 'เลือกผู้ขนส่งของคุณที่บันทึกไว้',
          position: 'right'
        },
        {
          element: '#license-3',
          intro: 'เลือกประเภทรถขนส่งของคุณ',
          position: 'right'
        },
        {
          element: '#license-4',
          intro: 'กดเพื่อเพิ่มทะเบียนรถบรรทุกใหม่ เมื่อบันทึกข้อมูลแล้ว คุณสามารถเรียกข้อมูลได้ทันที ไม่เสียเวลาในการกรอกใหม่',
          position: 'right'
        },
        {
          element: '#license-5',
          intro: 'กดเพื่อดูตำแหน่งรถบรรทุกปัจจุบันของคุณ ตามเลขทะเบียนที่บันทึกไว้',
          position: 'right'
        },
        {
          element: '#license-6',
          intro: 'เพิ่มข้อมูลทะเบียนใหม่ด้วย Microsoft Excel',
          position: 'right'
        },
        //  {
        //    element: '#license-7',
        //      intro: 'กดเพื่อเพิ่มข้อมูลคนขับรถใหม่ เมื่อบันทึกข้อมูลแล้ว คุณสามารถเรียกข้อมูลได้ทันที ไม่เสียเวลาในการกรอกใหม่',
        //      position: 'right'
        //     }
      ]
    }).onchange(function (targetElement) {
      console.log(targetElement.id);
      switch (targetElement.id) {
        case 'license-1': currentStep = 1; break;
        case 'license-2': currentStep = 2; break;
        case 'license-3': currentStep = 3; break;
        case 'license-4': currentStep = 4; break;
        case 'license-5': currentStep = 5; break;
        case 'license-6': currentStep = 6;; break;
        //  case 'license-7' : currentStep = 7 ;  document.getElementById("driver").click();break;

        case '': ; break;
      }
    })

      .oncomplete(function () {

        console.log("Complete Intro");
        return currentStep;
      })
      .onexit(function () {

        obService.InsertOnbordingRecord(3, currentStep)
        return currentStep;
      }).start();

    console.log("result : ", result);

  }

  async CallDriverListIntro(): Promise<any> {
    var currentStep = 1;
    var introguide = introJs();
    var test = "test";

    var obService = this.record;
    var result = await introguide.setOptions({
      steps: [
        {
          element: '#driver-1',
          intro: 'กดเพื่อเพิ่มข้อมูลคนขับรถใหม่ เมื่อบันทึกข้อมูลแล้ว คุณสามารถเรียกข้อมูลได้ทันที ไม่เสียเวลาในการกรอกใหม่',
          position: 'bottom'
        },
        {
          element: '#driver-2',
          intro: 'เพิ่มข้อมูลคนขับรถใหม่ด้วย Microsoft Excel',
          position: 'right'
        }
      ]
    }).onchange(function (targetElement) {
      console.log(targetElement.id);
      switch (targetElement.id) {
        case 'driver-1': currentStep = 1; break;
        case 'driver-2': currentStep = 2; break;

        case '': ; break;
      }
    })

      .oncomplete(function () {

        console.log("Complete Intro");
        return currentStep;
      })
      .onexit(function () {

        obService.InsertOnbordingRecord(4, currentStep)
        return currentStep;
      }).start();

    console.log("result : ", result);

  }



  exitIntro(any) {
    console.log("exit", any);
  }

}
