import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { TmsSchedulerModule } from 'tms-scheduler';
import { ModalAddOrderPlanComponent } from './components/modal-add-order-plan/modal-add-order-plan.component';
import { ModalAddItemOrderPlanComponent } from './components/modal-add-item-order-plan/modal-add-item-order-plan.component';
import { SharedModule } from 'primeng/api';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TabBackLogComponent } from './components/tab-back-log/tab-back-log.component';
import { TabLoadOrderComponent } from './components/tab-load-order/tab-load-order.component';
import { ModalTimeframeDetailComponent } from './components/modal-timeframe-detail/modal-timeframe-detail.component';
import { ModalCreateBookingComponent } from './components/modal-create-booking/modal-create-booking.component';
import { MaterialsModule } from '@app/modules/materials/materials.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectShipmentToCreateComponent } from './components/select-shipment-to-create/select-shipment-to-create.component';
import { TabBookingShipmentComponent } from './components/tab-booking-shipment/tab-booking-shipment.component';
import { BookingShipmentItemComponent } from './components/booking-shipment-item/booking-shipment-item.component';
import { SlotReportComponent } from './components/slot-report/slot-report.component';
import { SchedulerSheraComponent } from './pages/scheduler-shera/scheduler-shera.component';
// import { MasterModule } from '@app/modules/master/master.module';
// import { MasterModule } from '@app/modules/master/master.module';

@NgModule({
  declarations: [SchedulerComponent, ModalAddOrderPlanComponent, ModalAddItemOrderPlanComponent,
    ModalTimeframeDetailComponent,
    TabBackLogComponent, TabLoadOrderComponent, ModalCreateBookingComponent, SelectShipmentToCreateComponent,
    TabBookingShipmentComponent,
    BookingShipmentItemComponent,
    SlotReportComponent,
    SchedulerSheraComponent
  ],
  imports: [
    CommonModule,
    // MasterModule,
    SharedModule,
    TmsSchedulerModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxPaginationModule,
    MaterialsModule,
    NgbModule,

  ]
})
export class SchedulerModule { }
