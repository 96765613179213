export class BookingAPIPath {
     // Insert truck booking
     public static AddBoooking = "/TruckBooking";
     public static GetBookingDraftList = '/booking/bookingDraftList';
     public static GetBookingAssignByBookingId = '/TruckBooking/GetBookingAssignByBookingId/v2022';
    public static GetBookingInvoice = "/TruckBooking/GetBookingInvoice"
}
export class AuthenticationAPIPath {
  public static  CanUseThisMobileNo = "/auth/CanRegisterwithThisPhoneNumber?phoneNumber=";
  public static  CanUseThisEmail = "/auth/CanRegistwithThisEmail?email=";
  public static UserDetail = "/v1/auth/user/detail";
  public static Logout = "/v1/auth/logout";
  public static CheckPassword = "/Auth/password-check";
}
export class AppMasterApiPath {
  public static ProvinceGroupByName = "/AppMaster/province-group-getId";
}
export class ShipmentApi {
  public static Shipment ="/Shipment";
  public static getShipmentBasic ="/Shipment/getShipmentBasic";
  public static Shipments360TruckCarrier ="/Shipment/shipments360TruckCarrier";
  public static getShipmentTotalStatus ="/Shipment/getShipmentTotalStatus";
  public static saveShipmentTruckAndDriver ="/Shipment/saveShipmentTruckAndDriver";
  public static getShipmentX360 ="/Shipment/x360";
  public static getShipmentEXFList ="/Shipment/getShipmentEXFList";
  public static ShipmentGoodissue ="/Shipment/good-issue-exfac";
  public static GetShipmentCount ="/Shipment/GetShipmentCount";
  public static ComfirmPrice = "/Shipment/confirmPrice";
  public static CancelConfirmPrice = "/Shipment/cancel-confirmPrice";
  public static getShipmentSetting ="/v2/shipment/setting";
}
export class ClientAPIPath {
  //public static ClientGetUser = "/ClientUser";
 // public static ClientAddUser = "/ClientUser";
  public static ClientUser = "/ClientUser/";
  public static ClientGetUser = "/ClientUser/";
  public static ClientGetCurrentUser = "/ClientUser";
  public static ClientGetUserAllList = "/ClientUser/from-client/";
  public static ClientGetUserNoDriverAllList = "/ClientUser/from-client-no-driver/";
  public static ClientUserUploadImage = "/ClientUser/UserImage/";
  public static ActiveClientCarrier = "/ClientCarrier/active/";
  public static EnableClientCarrier = "/ClientCarrier/enable-carrier/";
  public static ClientGoogleMapLocation = "/ClientLocation/googleMap/";
  public static ClientGoogleMapSearchLocation = "/ClientLocation/googleMap-search";

  public static  ClientLocationAddNewWithGoogleMap = "/ClientLocation/googlemap-add-new";
  public static  getCustomerLocation = "/customerLocation/getCustomerLocation";

  public static ValidateDriverExcel = "/ClientUser/ValidateDriverExcel";
  public static AddDriverListByExcel = "/ClientUser/AddDriverListByExcel";
  public static GetDriverWithShipment = '/ClientUser/getDriverWithShipment';
  public static InitClient = "/client/InitClient";

  public static GetCarrierProposeTmsWorkById = "/ClientCarrier/carrier360-works-waitConfirm-ById";
  public static CreateCarrierAssignment = "/ClientCarrier/create-assign-shipment";
  public static CarrierRejectAssignment = "/ClientCarrier/reject-assign-shipment";
  public static UpgradeBasicToPremium = "/Client/convertBasicToPremium";


}

export class CustomerAPIPath{
  public static GetCustomerListPath ="/Customer/from-client-id"
  public static ValidateCustomerExcel = "/Customer/ValidateExcel";
  public static AddCustomerListByExcel = "/Customer/AddCustomerExcel";
  public static GetCustomerRunningNo = "/Customer/customer-running-no";
  public static GetCustomerOrderCompleteShipment =
    '/Customer/OrderCompleteShipment';
}

export  class ClientCompanyAPIPath {
  public static GetCompamyDetail = "/ClientCompany/";

}
export class TeamAPIPath {
    public static GetTeamWithClient = "/team/list-from-client-id/";
    public static GetTeamUserList = "/TeamUser/by-team-id/";
    public static TeamUser = "/TeamUser/";
}

export  class MasterAPIPath {
  public static  GetAllDepartment = "/Department";
  public static GetRoleByDepartment = "/Role/from-department/";
  public static GetRoleByClient = "/Role/from-client/";
  public static GetProvinceList = "/appmaster/province";
}
export class RouteAPIPath {
  public static Route = "/Routes";
}

export class DasboardAPIPath {
  public static  getTransportationDasboard = "/Dashboard/getTransportationDasboard";
  public static  getDriverLocation = "/Dashboard/GetDriverLocation";
  public static  getDriverLocationByBookkingId = "/Dashboard/GetDriverLocationByBookkingId";
  public static  getShipmentTrackingByLocation = "/Dashboard/shipment-tracking-by-location";
  public static  getOverSpeed = "/operation/overSpeed/data";
  public static  getDoorOpen = "/operation/DoorOpen/data";
  public static  getOverTemp = "/operation/OverTemp/data";


}

export class FleetAPIPath{
public static PostFleet = "/Fleet";
public static GetFleetOverAll = "/Fleet/GetFleetOverAll";
public static GetFleetList = "/Fleet/GetFleetList";

}

export class TruckLocationsTrackingAPIPath{
  public static GetTruckTrackingLocation = "/TruckLocationTracking/GetTruckTrackingLocation";
  }

  export class FleetXDriverAPIPath{
    public static FleetDriver = "/FleetXDriver";
    public static GetFleetDriver = "/FleetXDriver/GetFleetDriver";
    public static GetFleetDriverList = "/FleetXDriver/GetFleetDriverList";
    public static AddFleetXDriverList = "/FleetXDriver/addFleetXDriverList";
    public static SaveChangeFleetDriver = "/FleetXDriver/saveChangeFleetDriver";
    }

  export class FleetXTruckAPIPath{
    public static FleetTruck = "/FleetXTruck";
    public static GetFleetTruck = "/FleetXTruck/GetFleetTruck";
    public static SaveChangeFleet = "/FleetXTruck/saveChangeFleet";
    public static AddFleetXTruckList = "/FleetXTruck/addFleetXTruckList";

    }

    export class UserTrainingAPIPath{
      public static UserTraining = "/UserTraining"
      public static UserTrainingFile = "/UserTrainingFile"
    }



    export class UserSalaryAPIPath{
      public static UserSalary = "/UserSalary"
    }

    export class UserStatementAPIPath{
      public static UserStatement = "/UserStatement"
    }

    export class UserBookbankAPIPath{
      public static UserBookbank = "/UserBookbank"
    }

    export class BankNameAPIPath{
      public static BankName = "/BankName"
    }

export class TruckApiPath {
  public static CompanyTruck = '/companytruck';
  public static GetCompanyTruckList = '/companytruck/getCompanyTruckList';
  public static GetCompanyTruckById = '/companytruck/getCompanyTruckById';
  public static DeleteCompanyTruck = '/companytruck/delete';
  public static GetCompanyTruckByCompanyTruckTypeId = '/companytruck/getCompanyTruckByCompanyTruckTypeId';
  public static VerifyTruckDataImport = '/companytruck/verifyTruckDataImport';
  public static SaveCompanyTruckDataImport = '/companytruck/saveCompanyTruckDataImport';
  public static GetCompanyTruckWithShipment = '/companytruck/getCompanyTruckWithShipment';
  public static SaveDefaultDriver = '/companytruck/saveDefaultDriver';
  public static GetCompanyTruckListByShipmentId = '/companyTruck/getCompanyTruckListByShipmentId';
}
export class TailApiPath {
  public static CompanyTail = '/companytail';
  public static GetCompanyTruckList = '/companytail/List';
  public static GetTailTypeByTruckTypeId = '/companytail/getTailTypeByTruckTypeId';
  public static GetTailTypeList = '/companytail/getTailTypeList';
  public static DeleteCompanyTail = '/companytail/delete';
  public static GetCompanyTailWithShipment = '/companytail/getCompanyTailWithShipment';
}
export class TruckDocInsureApiPath {
  public static GetCompanyTruckDocInsureList = '/companytruckdocinsure/List';
}
export class InsuranceCompanyApiPath {
  public static GetList = '/insurancecompany';
}

export class CompanyTruckType {
  public static GetCompanyTruckType = '/CompanyTruckType/GetCompanyTruckTypeList'
  public static SaveAndUpdateCompanyTruckType = '/CompanyTruckType/SaveAndUpdateCompanyTruckType'
  public static AddCompanyTruckByTruckTypeId = '/CompanyTruckType/AddCompanyTruckByTruckTypeId'

}
export class CompanyTruckTypeApiPath {
  public static CompanyTruckType = '/companytrucktype';
}

export class TruckMaintenanceApiPath {
  public static GetTruckMaintenanceList = '/truckMaintenance';
  public static SaveAndUpdateTruckMaintenance = '/truckMaintenance';
  public static CancelTruckMaintenance = '/truckMaintenance';
  public static GetTruckMaintenanceTypeList = '/truckMaintenance/maintenanceTypes';
}

export class UserApiPath {
  public static User: string = "/User"
  public static Users: string = "/Users"
  public static UserPhto: string = "/User/Photo"
  public static UserIdentityId: string = "/User/get-userid"
  public static CanUseThisMobileNo = "/auth/CanRegisterwithThisPhoneNumber";
  public static CanUseThisEmail = "/auth/CanRegisterwithThisEmail";
  public static Register = "/User/Register";
  public static Remove = "/User/Remove";
  public static ChangePhoneNumber = "/User/ChangePhoneNumber";
  public static ConfirmChangePhoneNumber = "/User/ConfirmChangePhoneNumber";
  public static VerifyOTP = "/Auth/VerifyOTP";
  public static ChangeEmailWithNoti = "/User/ChangeEmailWithNoti";
  public static ChangePassword = "/Users/ChangePassword/Identity";
  public static AgreeCondition = "/User/agree-condition";
  public static PasswordCheck = "/Auth/password-check";

}

export class TruckBasicBookingApiPath {
  public static GetBasicBookingList = '/booking/basicBookingList';
}

export class UserDocVerifyStatusApiPath {
  public static UserDocVerifyStatus:string = "/UserDocVerifyStatus"
}

export class UserIDCardPath {
  public static UserIDCard = "/UserIDCard";
}
export class UserDriverLicence {
  public static UserDriverLicence = "/UserDriverLicence";
}

export class RegisterApiPath {
  public static GetRegisterSteps: string = "/register/registersteps";
  public static GetCompanyType: string = "/register/companytype";
  public static GetProvince: string = "/register/province";
  public static GetDistrict: string = "/register/district";
  public static GetSubDistrict: string = "/register/subdistrict";
  public static GetZipCode: string = "/register/zipcode";
  public static GetAddress: string = "/register/address";
  public static GetProductGroup: string = "/register/productgroup";
  public static GetTruckType: string = "/register/trucktype";
  public static GetRegion: string = "/register/region";
  public static CreateFirstRegister: string = "/user/createfirstregister";
  public static SubscriptionPackage: string = "/apppackagesubscription/subscription360package";
  public static ActivateFirstRegister: string = "/user/activatefirstregister";
  public static GetMobileOTP: string = "/user/mobile-otp";
  public static GetCompanyTypeByUser: string = "/register/companytypebyuser";
  public static GetCompanyServiceIdByUser: string = "/register/companyserviceidbyuser";
  public static AddProfileInfo: string = "/register/addprofileinfo";
  public static AddTransportationInfo: string = "/register/addtransportationinfo";
  public static AddCompanyServiceArea: string = "/register/addcompanyservicearea";
  public static AddUserDocument: string = "/register/adduserdocument";
  public static UpdateTerms: string = "/register/updateterms";
  public static UpdateCompanyChannel: string = "/register/updatecompanychannel";
  public static CanUseThisMobileNo = "/auth/CanRegisterwithThisPhoneNumber";
  public static CanUseThisEmail = "/auth/CanRegisterwithThisEmail";
  public static GetUserByMobileNo: string = "/register/userbymobileno";
  public static GetAgreeTerm: string = "/register/agreeterm";
  public static CheckAgreeTerms: string = "/register/checkAgreeTerms";
}
export class SignupApiPath {
  public static GetPackages: string = "/signup/packages";
  public static SaveCallBackCusomer: string = "/signup/saveCallBackCustomer";
}

export class OnbordingApiPath{
  public static GetCurrentOnbordingRecord : string = "/Onbording"
}

export class SubscriptionApiPath {
  public static subscription: string = "/subscription"
  public static filter: string = "/subscription/filter"
  public static summarySubscriptionActive: string = "/subscription/summarySubscriptionActive";
  public static mySubscriptions: string = "/subscription/mySubscriptions"
  public static getPackages: string = "/subscription/packages"
  public static checkCanDeleteSubscription: string = "/subscription/checkCanDeleteSubscription"
  public static delete: string = "/subscription/delete"
}

export class PaymentApiPath {
  public static payment: string = "/payment";
  public static createUserPaymentMethod: string = "/payment/createUserPaymentMethod";
  public static addCreditCard: string = "/payment/addCreditCard";
  public static myPaymentMethods: string = "/payment/myPaymentMethods";
  public static chargeUsingCard: string = "/payment/chargeUsingCard";
  public static updatePaymentDefault: string = '/payment/updatePaymentDefault';
  public static getTMSBookBanks: string = '/payment/getTMSBookBanks';
  public static createTransfer: string = '/payment/createTransfer';
  public static updateTransfer: string = '/payment/updateTransfer';
  public static comfirmTransfer: string = '/payment/confirmTransfer';
  public static getPaymentTransfer: string = "/payment/getPaymentTransfer";
}

export class UserEventApiPath{
  public static AddUserEvent : string = "/UserEvent"
}


export class WidgetApiPath{
  public static GetWidget : string = "/widget"
}

export class CustomFieldInfoApiPath {
  public static getDetailInfo : string = "/v3/CustomFieldInfo/getDetailInfo?clientNo={clientNo}&bookingNo={bookingNo}&bookingReference01={bookingReference01}&refTruckbookingId={refTruckbookingId}"
  public static saveBookingInfo : string = "/v3/CustomFieldInfo/saveBookingInfo"
  public static saveOrderInfo : string = "/v3/CustomFieldInfo/saveOrderInfo"
  public static saveOrderItemInfo : string = "/v3/CustomFieldInfo/saveOrderItemInfo"
  public static saveShipmenntInfo : string = "/v3/CustomFieldInfo/saveShipmentInfo"
}


export class TruckBookingV2ApiPath {
  public static Setting : string = "/v2/truckbooking/setting"
}

export class ARApiPath {
  public static getTabCounter = '/v3/Ar/tabCounter';
  public static getOrderCompletedShipments =
    '/v3/Ar/getOrderCompletedShipments';
  public static getInvoiceList = '/v3/Ar/getInvoiceList/ByClient';
  public static getInvoiceDetail = '/v3/Ar/getInvoiceDetail';
  public static saveInvoice = '/v3/Ar/saveInvoice';
  public static cancelInvoice = '/v3/Ar/cancelInvoice';
  public static getReceiptList = '/v3/Ar/getReceiptList/ByClient';
  public static getReceiptOfInvoice = '/v3/Ar/getReceiptOfInvoice';
  public static saveReceipt = '/v3/Ar/saveReceipt';
  public static getReceiptDetail = '/v3/Ar/getReceiptDetail';
  public static cancelReceipt = '/v3/Ar/cancelReceipt';
  public static getAROrderExpense = '/v3/Ar/getAROrderExpense';
  public static saveAROrderExpense = '/v3/Ar/saveAROrderExpense';
  public static getOrderDetail = '/v3/Ar/getOrderDetail';
  public static getCompanyPaymentChannel = '/v3/Ar/getCompanyPaymentChannel';
}
export class BannerApiPath{
  public static GetBanner : string = "/Banner/getBanner"
}
export class OrderApiPath {
  public static Upload : string = "/do/saveMultipleDeliveryOrder?clientid="
  public static ExportError : string = "/do/exportValidationResult"
  public static Gettemplate : string = "/do/template"
  public static ExportClientLocation: string = '/location/export';
  public static ImportClientLocation: string = '/location/import';
}

export class LAPIPath{
  public static GetFileTemplateLA = "/do/template_LA";
  public static SaveMultipleDeliveryOrder = "/do/saveMultipleDeliveryOrder_LA?clientid=";

}
