<div class="row text-center w-100 d-block float-none" >
  <div class="col-12">
    <p>
      <img height="40px" src="assets/img/360techx/360techx_logo_horizontal_nobg_white 2.png" alt="SmartAdmin WebApp" aria-roledescription="logo">
      เอกสารนี้ถูกสร้างจากระบบ 360TECHXbasic - เริ่มใช้ฟรี ที่ www.360TECHX.co
    </p>
  </div>
</div>
<div class="text-center">
  <div style="font-size: 1.8rem;">{{header}}</div>
  <h1>{{title}}</h1>
</div>

