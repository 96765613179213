export class Customernote {
   title:string;
   remark:string;
   clientID:number;
   departmentID:number[]= [];
   remarkCategoryID:number;
   createdBy:string;
   Id:number;
}

export class ClientRemarksList
{
   ClientRemarks:Customernote[];
}

export class ResponeRemark extends Customernote
{
    id:number;
}

export class ResponeRemarkList
{
   ClientRemarks:ResponeRemark[];
}