import { Component, Input, OnInit } from '@angular/core';
import { ServicesettingService } from '@app/modules/setting/service/servicesetting.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-slot-report',
  templateUrl: './slot-report.component.html',
  styleUrls: ['./slot-report.component.scss']
})
export class SlotReportComponent implements OnInit {

  @Input() date ;
  matched = false;
  times=[];
  bookingList 
  constructor( private service :ServicesettingService) { 
    this.generateTimes(60);

  }

  ngOnChanges(){
    this.get();
  }

  ngOnInit(): void {
    console.log(this.date);
    this.get();
  }


  async get(){
    console.log(this.date);

     var date = (this.date.year -543).toString() + this.date.month.toString().padStart(2,'0') + this.date.day.toString().padStart(2,'0');
    await  this.service.getLoadLimitgetBookings(103,date).then(
      async (res) => {
        var datas = await res.json();

        console.log(datas);
        this.bookingList = datas;

        this.getMatchTime();
    })
  }

  generateTimes(timeFrame: number) {
    this.times = [];
    for (let i = 0; i < 1440; i += timeFrame) {
      let hours = Math.floor(i / 60);
      let minutes = i % 60;
      let time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      let item = {
        hours: hours,
        time: time
      }
      this.times.push(item);
      
    }
    console.log(this.times);
  }

  getMatchTime(){
    this.times.forEach(t => {
      t.bookings =[]
      for (let b of this.bookingList){
       var time2  = t.time.slice(0,5);
       var btime = b.time.slice(0,5);
       console.log(btime, time2)
        if(btime== time2){
          t.bookings.push(b);
        }
      }
    });
    console.log(this.times);
    this.matched = true;
  }
}
