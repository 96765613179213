<ng-template #modalchangepassword let-modal>
    <div class="modal-header bg-warning text-center text-white">
        <div class="col-12 p-0">
            <span class="fal fa-question-circle fa-9x"></span>
            <button type="button" class="close pt-0 pl-0 pb-0 pr-1" style="color:white !important" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body pb-0">
        <div *ngIf="body_title" class="row mb-2">
            <div class="col-12 text-center">
                <h3 class="font-weight-bold mb-0" style="white-space: pre-wrap">
                    {{body_title}}
                </h3>
            </div>
        </div>
        <div *ngIf="body_text" class="row">
            <div class="col-12 text-center">
                <h5 *ngIf="body_text" class="text-muted" style="white-space: pre-wrap">
                    {{body_text}}
                </h5>
            </div>
        </div>
        <div class="row">
            <!-- <div class="form-group col-12">
                    <span class="h5">อีเมลเดิม</span>
                    <input type="text" class="form-control" disabled value="ball.kijtisak@gmail.com">
                </div> -->
            <form *ngIf="!isOldPassword" [formGroup]="checkPasswordForm" class="form-group col-12">
                <span class="h5">โปรดระบุรหัสผ่านของคุณเพื่อยืนยันตัวตน</span>
                <input type="password" class="form-control" formControlName="password" placeholder="กรุณาระบุรหัสผ่าน">
                <div *ngIf="!isOldPassword">
                    <span class="text-danger">{{passwordMessageError}}</span>
                </div>
            </form>

            <form *ngIf="isOldPassword" [formGroup]="changePasswordForm" class="form-group col-12">
                <span class="h5">รหัสผ่านใหม่</span>
                <input type="password" class="form-control" formControlName="newPassword"
                    placeholder="กรุณาระบุรหัสผ่าน">
                <div class="help-block " style="font-size: 100%; color: #dc3545;"
                    *ngIf="changePasswordForm.controls.newPassword.errors?.minlength">
                    รหัสผ่านใหม่อย่างน้อย 8 ตัวอักษร
                </div>
                <span class="h5">รหัสผ่านใหม่อีกครั้ง</span>
                <input type="password" class="form-control" formControlName="confirmPassword"
                    placeholder="กรุณาระบุรหัสผ่าน">
                <div class="help-block " style="font-size: 100%; color: #dc3545;"
                    *ngIf="changePasswordForm.controls.confirmPassword.errors?.minlength">
                    รหัสผ่านใหม่อย่างน้อย 8 ตัวอักษร
                </div>
                <div *ngIf="isOldPassword">
                    <div *ngIf="changePasswordForm.value.newPassword != changePasswordForm.value.confirmPassword"
                        class="text-danger">{{passwordMessageError2}}</div>
                    <div class="text-danger">{{passwordMessageError}}</div>
                </div>
            </form>

        </div>

    </div>
    <div class="modal-footer justify-content-between">
        <button id="btnother" type="button" class="btn btn-outline-secondary waves-effect waves-themed font-weight-bold"
            style="width:37% !important" (click)="modal.dismiss('Cross click')">ยกเลิก</button>
        <button *ngIf="!isOldPassword" id="btnsubmit" class="btn btn-warning waves-effect waves-themed font-weight-bold"
            style="width:58% !important" type="submit" (click)="checkpassword()">ยืนยันเปลี่ยนรหัสผ่าน</button>
        <button *ngIf="isOldPassword" id="btnsubmit" class="btn btn-danger waves-effect waves-themed font-weight-bold"
            style="width:58% !important" type="button" (click)="changepassword()"
            [disabled]="!changePasswordForm.valid">ยืนยันเปลี่ยนรหัสผ่าน</button>
    </div>

</ng-template>

<app-modalsuccess #modalsuccess (btnother_event)="success_other($event)" [btnsubmit_ishide]=true></app-modalsuccess>