import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_Services';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SchedulerSheraGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // logged in so return true
            //check is client shera
            // 103 = shera , 236 = Techx Testing
            // if (currentUser.ClientID !== 103 && currentUser.ClientID !== 236) {
            //     this.router.navigate(['/scheduler']);
            // }
            return true;
        }
        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        const oauth2Param = `&client_id=${environment.oauth.client_id}&response_type=${environment.oauth.response_type}&redirect_uri=${environment.oauth.redirect_uri}&state=${environment.oauth.state}`;
        window.location.href = environment.loginUrl + '?redirect=' + encodeURIComponent(origin + state.url) + oauth2Param;
        return false;
    }
}
