import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService } from '@app/core/_Services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { DriverDetailService } from '../../services/driver-detail.service';

@Component({
  selector: 'app-modal-share-driver-detail',
  templateUrl: './modal-share-driver-detail.component.html',
  styleUrls: ['./modal-share-driver-detail.component.scss']
})
export class ModalShareDriverDetailComponent implements OnInit {
  @Input() driverDetailSharedCheckList:any

  @Output() modalReference!: NgbModalRef;
  @Output() modalCloseEvent = new EventEmitter<boolean>();

  element: HTMLElement;
  copytext: string = '';
  currentUser: User


  driverDetailSharedCheckAll: boolean = false;

  @ViewChild("modalsharedriverdetail", { static: true }) modalsharedriverdetail!: ModalShareDriverDetailComponent;
  constructor(
    private modalService: NgbModal,
    private clipboardService: ClipboardService,
    private authenticationService: AuthenticationService) { }

    openmodal(size?: 'sm' | 'lg' | 'xl' | string) {

      if (size != undefined && size != '' && size != null) {
        this.modalReference = this.modalService.open(this.modalsharedriverdetail, {
          size: size,
          centered: true,
          backdrop:'static'
        });
      } else {
        this.modalReference = this.modalService.open(this.modalsharedriverdetail, {
          size:'lg',
          centered: true,
          backdrop:'static'
        });
      }
    }

    closemodal(bool): void {
      this.modalCloseEvent.emit(bool);
      this.modalReference.close(bool);
    }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(
      (x) =>{
     (this.currentUser = x)
      console.log(x);
    }
    );
  }

  checkDriverDetail(driverDetailShared: any) {
    this.driverDetailSharedCheckList.filter((c) => {
      if (c.id == driverDetailShared.id) {
        c.checked = !c.checked;
      }
    });
    console.log(driverDetailShared);

    let chkAllchecked = this.driverDetailSharedCheckList.every((member) => {
      return member.checked;
    });

    if (chkAllchecked) {
      this.driverDetailSharedCheckAll = true;
    } else {
      this.driverDetailSharedCheckAll = false;
    }
    console.log(chkAllchecked);
  }

  onCheckedAll() {
    if (this.driverDetailSharedCheckAll) {
      this.driverDetailSharedCheckList.forEach((c) => (c.checked = true));
    } else {
      this.driverDetailSharedCheckList.forEach((c) => (c.checked = false));
    }
  }

  copy(): void {
    this.element = document.getElementById('for-copy');
    let cbCheck = this.clipboardService.copyFromContent(this.element.innerText);

    console.log('cbCheck', cbCheck);
    console.log(this.element.innerText);

    this.copytext = this.element.innerText;
  }

}
