import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetnotificationComponent } from './components/setnotification/setnotification.component';
import { NotitmsComponent } from './pages/notitms/notitms.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SettingPermissionComponent } from './components/SettingPermission/SettingPermission.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PermistmsComponent } from './pages/permistms/permistms.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialsModule } from '../materials/materials.module';
import { GooglemapModule } from 'src/app/shared/Components/ModalGoogleMap/modalgooglemap/googlemap/googlemap.module';
import { LinegroupComponent } from './components/linegroup/linegroup.component';
import { NoticarrierComponent } from './components/noticarrier/noticarrier.component';
import {TeamService} from "@app/modules/team/services/team.service";



@NgModule({
  declarations: [
    SetnotificationComponent,
    NotitmsComponent,
    SettingPermissionComponent,
    PermistmsComponent,
    LinegroupComponent,
    NoticarrierComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    MaterialsModule,
    GooglemapModule,
    ReactiveFormsModule,
  ],
  providers:[TeamService]
})
export class NotificationModule { }
