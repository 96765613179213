import { Injectable } from '@angular/core';
import { TMSAPIResponse } from '../../../../../../core/_Models/TMSAPIResponse';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { User } from '../../../../../../core/_Models/UserModel/User';
import {
  AuthenticationService,
  HttpService,
  NotificationService,
} from '../../../../../../core/_Services';
import {
  AppMasterApiPath,
  ClientAPIPath,
} from '../../../../../../core/_Constants/apiConstants';
import { LocationData } from '../Model/LocationDataModel';
import {
  ClientLocationAddItem,
  ClientLocationAddModel,
} from '../Model/ClientLocationAddModel';
import { LocationDirection } from '@shared/Components/ModalGoogleMap/modalgooglemap/googlemap/Model/LocationDirection';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';

@Injectable({
  providedIn: 'root',
})
export class GoolemapService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentUser: User;

  constructor(
    private _apiService: HttpService,
    private _noti: NotificationService,
    private authenticationService: AuthenticationService,
    private customerservice: CustomerserviceService
  ) {
    // get current user on login
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  //#region Get Master Data
  GetMapCustomerList(): Promise<any> {
    return this.customerservice.GetCustomerList(this.currentUser.ClientID);
  }

  SearchLocation(searchTxt: string): Observable<TMSAPIResponse> {
    return this._apiService
      .post(ClientAPIPath.ClientGoogleMapSearchLocation, {
        clientId: this.currentUser.ClientID,
        searchText: searchTxt,
      })
      .pipe(takeUntil(this.destroy$));
  }
  DirectionService(
    result: LocationDirection[]
  ): Observable<LocationDirection[]> {
    return new Observable((observer) => {
      const directionsService = new google.maps.DirectionsService();
      const renderOptions = {
        draggable: false,
      };
      var waypoints = [];
      for (var i = 0; i < result.length; i++) {
        if (i > 0 && i < result.length - 1) {
          waypoints.push({
            location: new google.maps.LatLng(result[i].lat, result[i].lng),
            stopover: true,
          });
        }
      }

      if (result.length > 0) {
        const originAddress = new google.maps.LatLng(
          result[0].lat,
          result[0].lng
        );
        const destinationAddress = new google.maps.LatLng(
          result[result.length - 1].lat,
          result[result.length - 1].lng
        );

        let request = {
          origin: originAddress,
          destination: destinationAddress,
          waypoints: waypoints, //an array of waypoints
          provideRouteAlternatives: true,
          optimizeWaypoints: false, //set to true if you want google to determine the shortest route or false to use the order specified.
          travelMode: google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (reponse, status) => {
          if (status === 'OK') {
            let legs = reponse.routes[0].legs;
            console.log('directionsService legs', legs);
            for (let i = 0; i < legs.length; i++) {
              for (let k = 0; k < result.length; k++) {
                if (i === 0 && k === 0) {
                  result[k].sequence = k + 1;
                  result[k].distance = 0;
                  result[k].eshr = 0;
                } else if (i + 1 == k) {
                  result[k].sequence = k + 1;
                  result[k].distance = legs[i].distance.value;
                  result[k].eshr = legs[i].duration.value;
                }
              }
            }
            observer.next(result);
            observer.complete();
          } else {
            observer.error(result);
          }
        });
      }
    });
  }

  DirectionServiceWayPoint(routes: LocationDirection[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const directionsService = new google.maps.DirectionsService();
      var waypoints = []; // จุดแวะ ไม่รวม ต้นทางปลายทาง

      routes.map((item, index) => {
        if (index > 0 && index < routes.length - 1) {
          waypoints.push({
            location: new google.maps.LatLng(item.lat, item.lng),
            stopover: true,
          });

        }
      })
      //TODO อย่าลืม comment
      // waypoints.push({
      //   location: new google.maps.LatLng(13.766522816289724, 100.64242486336028),
      //   stopover: true,
      // });
      // waypoints.push({
      //   location: new google.maps.LatLng(13.77909449718146, 100.62370596280131),
      //   stopover: true,
      // });

      const originAddress = new google.maps.LatLng(
        routes[0].lat,
        routes[0].lng
      );
      const destinationAddress = new google.maps.LatLng(
        routes[routes.length - 1].lat,
        routes[routes.length - 1].lng
      );
      // routes.push(routes[0])
      // routes.push(routes[0])

      let request = {
        origin: originAddress,
        destination: destinationAddress,
        waypoints: waypoints, //an array of waypoints
        // provideRouteAlternatives: true,
        // optimizeWaypoints: false, //set to true if you want google to determine the shortest route or false to use the order specified.
        travelMode: google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (reponse, status) => {
        if (status == "OK") {
          // console.log('directionsService legs reponse', reponse);
          let legs = reponse.routes.slice(0, 1).shift().legs;
          // console.log('directionsService legs', legs);
          legs.map((item, i) => {
            // console.log('item.start_location', item.start_location.toJSON());
            // console.log('item.end_location', item.end_location.toJSON());
            routes[i+1].distanceText = item.distance.text;
            routes[i+1].durationText = item.duration.text;
            routes[i+1].distance = item.distance.value;
            routes[i+1].duration = item.duration.value;

          })
          // กำหนดระยะทาง/เวลา จุดแรกเป็น 0
          routes[0].distance = 0;
          routes[0].duration = 0;
          resolve(routes);
        }
      })
    })
  }

  DirectionServiceIFrame(routes: LocationDirection[]): Promise<string> {
    console.log('routes',routes)
    return new Promise((resolve, reject) => {
      let origin: string;
      let destination: string;
      let url: string;
      let waypoint = '';

      routes.map((c: any) => {
        if (c.sequence == 1) {
          /// orgin
          origin = c.lat + ',' + c.lng;
        } else if (c.sequence == routes.length) {
          //destination
          destination = c.lat + ',' + c.lng;
        } else {
          //way point
          waypoint += c.lat + ',' + c.lng + '|';
        }
        if (waypoint != '') {
          url =
            'https://www.google.com/maps/embed/v1/directions?key=AIzaSyA-U4Q_HYWub2fGi8qEG72cRaQuWMw4J0A&origin=' +
            origin +
            '&destination=' +
            destination +
            '&waypoints=' +
            waypoint.substring(0, waypoint.length - 1) +
            '&mode=driving&language=th';
        } else {
          url =
            'https://www.google.com/maps/embed/v1/directions?key=AIzaSyA-U4Q_HYWub2fGi8qEG72cRaQuWMw4J0A&origin=' +
            origin +
            '&destination=' +
            destination +
            '&mode=driving&language=th';
        }
      });
      resolve(url);
    })
  }

  AddNewClientLocation(data: LocationData): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      let loc = new ClientLocationAddItem();
      loc.createdBy = this.currentUser.id.toString();
      loc.locationNumber =
        'GMR-' + Math.floor(Math.random() * (1000000 - 10000 + 1) + 10000);
      loc.locationShortAddress = data.shortAddress;
      loc.locationFullAddress = data.fullAddress;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.provinceID = data.provinceId;
      loc.districtID = data.districtId;
      loc.subdistrictID = data.subDistrictId;
      loc.LocationTypeId = 1;
      loc.ClientID = this.currentUser.ClientID;
      loc.placeId = data.placeId;
      loc.ContactName = data.contactName;
      loc.ContactPhone = data.contactPhone;

      if (data.customerId && data.customerId > 0) {
        loc.CustomerId = data.customerId;
      }

      this._apiService
        .post(ClientAPIPath.ClientLocationAddNewWithGoogleMap, {
          locationData: loc,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          if (!res.isSuccess) {
            this._noti.toasterror('ไม่สามารถเพิ่มสถานที่นี้ได้', res.message);
          }
          resolve(res);
        });
    });
  }

  GetClientLocation(): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this._apiService
        .get(ClientAPIPath.ClientGoogleMapLocation + this.currentUser.ClientID)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          if (!res.isSuccess) {
            this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
          }
          resolve(res);
        });
    });
  }

  ProvinceGroupGetDetail(
    provinceName: string,
    districtName: string,
    subDistrictName: string
  ): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      let data = {
        provinceName: provinceName,
        districtName: districtName,
        subDistrictName: subDistrictName,
      };

      this._apiService
        .post(AppMasterApiPath.ProvinceGroupByName, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          resolve(res);
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
