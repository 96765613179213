  <ng-template #modaleditdriverform let-modal>
    <div class="modal-header" *ngIf="!IsView">

        <h4 class="modal-title">
          <i class="fas fa-user txt-green"></i>
          {{IsEdit ? "แก้ไขข้อมูลผู้ใช้" : "เพิ่มผู้ใช้งาน"}}
        </h4>
        <a class="Close"><i class="fal fa-times" (click)="closed()"></i></a>
      </div>


      <form [formGroup]="userform" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="col-12">
            <!-- <div class="d-flex flex-column align-items-center justify-content-center p-4">
              <img [src]="profileImageUri" class=" profile-file-input rounded-circle shadow-2 img-thumbnail" alt=""
                (error)="onImgErrorProfile($event)">
              <input type="file" class="profile-file-input" id="CompanyImg" accept="image/*"
                (change)="handleFileInput($event)" *ngIf="!IsView" #uploadFile>
              <label for="CompanyImg" class="mt-3 cursor-pointer text-hover" *ngIf="!IsView"><i
                  class="fas fa-upload"></i>&nbsp;
                <span>{{IsEdit ? 'เปลี่ยนรูปภาพ': 'เลือกรูปภาพ'}}</span>
              </label>
            </div> -->
            <app-profile-upload [setFleetDriver]="userPhoto"  (photoFile)="handleFileInput2($event)" (photoURL)="photoURLChange($event)" [canChange]="canChange"></app-profile-upload>
          </div>
          <!--                <div class="form-group">-->
          <!--                    <div class="row">-->
          <!--                        <div class="col-md-12">-->
          <!--                            <label class="form-label" for="txttitlename">คำนำหน้า</label>-->
          <!--                            <input type="text" id="txttitlename" name="txttitlename" class="form-control" formControlName="nametitle"-->
          <!--                                placeholder="คำนำหน้า">-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                </div>-->
          <div class="form-group mt-2">
            <div class="row my-2">
              <div class="col-12 col-md-12">
                <label class="form-label">สิทธิ์ผู้ใช้งาน  <span class="txt-red">*</span></label>
                <ng-select placeholder="สิทธิ์ผู้ใช้งาน" formControlName="roleId"
                  [readonly]="(IsView || !(permission70100.actionDto | permission : 3)) || IsDisableDriverRole"
                  [items]="roleList" bindLabel="roleName" bindValue="id"
                  (change)="setAuthlevelByClientRole($event)">
                  <!-- <ng-option *ngFor="let item of roleList" [value]="item.id">{{item.roleName}}</ng-option> -->
                </ng-select>
                <div *ngIf="userform.controls.roleId.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.roleId.errors.required">
                    กรุณาเลือกสิทธิ์ผู้ใช้งาน
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label" for="txtname">ชื่อ <span class="txt-red">*</span> </label>
                <input type="text" id="txtname" name="txtname"
                  class="form-control {{userform.controls.firstname.invalid?'is-invalid':'is-valid'}}"
                  formControlName="firstname" placeholder="ชื่อ" [readonly]="IsView">
                <div *ngIf="userform.controls.firstname.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.firstname.errors.required">
                    กรุณากรอกชื่อ
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <label class="form-label" for="txtlastname">นามสกุล <span class="txt-red">*</span> </label>
                <input type="text" id="txtlastname" name="txtlastname"
                  class="form-control {{userform.controls.lastname.invalid?'is-invalid':'is-valid'}}"
                  formControlName="lastname" placeholder="นามสกุล" [readonly]="IsView">
                <div *ngIf="userform.controls.lastname.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.lastname.errors.required">
                    กรุณากรอกนามสกุล
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6 mb-2"  *ngIf="clientModule_70400?.pkg_id > 20">
                <label class="form-label">Department  <span class="txt-red">*</span></label>
                <ng-select placeholder="Department" (change)="getRoleByDepartment()" formControlName="departmentId"
                  [readonly]="IsView || !(permission70100.actionDto | permission : 3) || IsDriver">
                  <ng-option *ngFor="let item of departmentList" [value]="item.id">{{item.departmentName}}</ng-option>
                </ng-select>
              </div>
              <!-- <div class="col-md-6">
                <label class="form-label">Role  <span class="txt-red">*</span></label>
                <ng-select placeholder="สิทธิ์" formControlName="roleId"
                  [readonly]="IsView || !(permission70100.actionDto | permission : 3) || IsDriver"
                  [items]="roleList" bindLabel="roleName" bindValue="id"
                  (change)="setAuthlevelByClientRole($event)">
                </ng-select>
              </div> -->
              <div class="col-md-6 col-auto" *ngIf="clientModule_70200?.pkg_id > 20 && !IsDriver">
                <label class="form-label">Team  <span class="txt-red">*</span></label>
                <ng-select placeholder="ทีม" formControlName="teamIds" [readonly]="IsView" [multiple]="true">
                  <ng-option *ngFor="let item of teamList" [value]="item.teamId">{{item.teamNumber}}
                    : {{item.teamName}}</ng-option>
                </ng-select>
              </div>

            </div>
          </div>

          <div class="form-group" *ngIf="IsEdit == false && clientModule_70110?.pkg_id > 20 && !IsDriver" >
            <div class="row">
              <div class="col-md-auto col-12">
                <label class="form-label" for="recievepassword">ช่องทางรับรหัสผ่าน</label>
              </div>
              <div class="col-md-auto col-12">

                <div class="custom-control custom-radio">
                  <input type="radio" id="bymobile" name="recievePasswordChanel"    formControlName="recievePasswordChanel"
                  [checked]="userform.controls.recievePasswordChanel.value===1" (change)="recievePasswordChanelChanges(1)"
                      class="custom-control-input" [value]="1" />
                  <label class="custom-control-label"
                      for="bymobile">รับรหัสผ่านทางเบอร์มือถือของผู้ใช้งาน</label>
              </div>


              <div class="custom-control custom-radio">
                <input type="radio" id="byemail" name="recievePasswordChanel"    formControlName="recievePasswordChanel"
                (change)="recievePasswordChanelChanges(2)"
                    class="custom-control-input" [value]="2"  [checked]="userform.controls.recievePasswordChanel.value===2"/>
                <label class="custom-control-label"
                    for="byemail">รับรหัสผ่านทางอีเมลของผู้ใช้งาน</label>
            </div>

                <!-- <input type="radio" id="bymobile" name="recievePasswordChanel" class="pt-1"  formControlName="recievePasswordChanel"
                [value]="1" checked> <label for="bymobile"> รับรหัสผ่านทางเบอร์มือถือของผู้ใช้งาน</label>
                <br>
                <input type="radio" id="byemail" name="recievePasswordChanel"  class="pt-1" formControlName="recievePasswordChanel"
                 [value]="2">  <label for="byemail"> รับรหัสผ่านทางอีเมลของผู้ใช้งาน</label> -->

              </div>

            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label" for="txttel">เบอร์โทรศัพท์ <span class="txt-red">*</span></label>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-phone"></i></span>
                  </div>
                  <!-- <input type="text" class="form-control" name="" id="" mask="000-0000000"
                            placeholder="โปรดกรอกเบอร์โทรศัพท์ 10 หลัก" /> -->

                  <input *ngIf="!IsEdit" type="tel" id="txttel" name="txttel"
                    class="form-control {{(userform.controls.mobileNumber.invalid || !isCanUserMobileNo)?'is-invalid':'is-valid'}}"
                    formControlName="mobileNumber" mask="000-000-0009"
                    placeholder="โปรดกรอกเบอร์โทรศัพท์ 10 หลัก" (keyup)="checkUsedMobileNo()" [readonly]="IsView">

                  <input *ngIf="IsEdit" type="tel" id="txttel" name="txttel"
                    class="form-control"
                    formControlName="mobileNumber"  mask="000-000-0009"
                    placeholder="โปรดกรอกเบอร์โทรศัพท์ 10 หลัก" [readonly]="IsEdit">

                </div>

                <div *ngIf="userform.controls.mobileNumber.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.mobileNumber.errors.canUse">
                    {{userform.controls.mobileNumber.errors.canUse}}
                  </div>
                  <div *ngIf="userform.controls.mobileNumber.errors && !userform.controls.mobileNumber.errors.canUse">
                    กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6"  *ngIf="!IsDriver && !driverlicenseReq">
                <label class="form-label" for="txtemail">Email<span class="txt-red" *ngIf="userform.controls.recievePasswordChanel.value===2">*</span> </label>

                <input *ngIf="IsDriver" type="email" id="txtemail" name="txtemail"
                  class="form-control  {{valid}}" formControlName="email"  [readonly]="IsView || IsEdit"
                  placeholder="email" (change)="checkUsedEmail()" [readonly]="IsView">

                <input *ngIf="!IsDriver" type="email" id="txtemail" name="txtemail"
                  class="form-control  {{valid}}"
                  formControlName="email" placeholder="email"
                  (change)="checkUsedEmail()"
                   [readonly]="IsView || IsEdit">
                <div *ngIf="userform.controls.email.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.email.errors.canUse">
                    {{userform.controls.email.errors.canUse}}
                  </div>
                </div>

                <div *ngIf="userform.controls.recievePasswordChanel.value===2 && ( userform.controls.email.value==null || userform.controls.email.value=='')" class="invalid-feedback">
                  <div>
                    กรุณากรอกอีเมล
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <label class="form-label" for="txtbirthdate">วัน/เดือน/ปีเกิด</label>
                <!-- <input type="text" id="'birthdate'" autocomplete="off" formControlName="birthDate" required  class="form-control date-picker"
                data-provide="datepicker" date-format="dd/MM/yyyy" placeholder="เลือกวันที่" #date data-toggle="tooltip" data-placement="top"/> -->
                <!-- <app-datepicker placeholder="Select date" [valid]="userform?.controls?.birthDate.valid" [title]="'วัน/เดือน/ปีเกิด'" formControlName="birthDate"
                    [id]="'birthdate-date-picker'" (change)="$event">
                  </app-datepicker> -->
                <div class="input-group">
                 <input ngbDatepicker #birthDate="ngbDatepicker"  formControlName="birthDate"
                  [minDate]="{year: 2450, month:1, day: 1}" [maxDate]="{year: maxYear, month:12, day: 31}"
                    class="form-control {{userform.controls.birthDate.invalid?'is-invalid':''}}"
                    placeholder="ระบุวันเดือนปีเกิด" (click)="!IsView ? birthDate.toggle() : false" [readonly]="IsView">
                  <div class="input-group-append">
                    <button class="btn btn-sm btn-outline-secondary" (click)="birthDate.toggle()" type="button">
                      <span class="fal fa-calendar-alt fa-lg"></span>
                    </button>
                  </div>
                  <!-- <app-datepicker formControlName="birthDate" placeholder="Select date" [removeTop]="true"
                  [id]="'date-picker'" [valid]="dateCorrect()" (change)="inputChange($event)">
                </app-datepicker> -->
                </div>
                <div *ngIf="userform.controls.birthDate.invalid && IsDriver" class="invalid-feedback">
                  <div>
                    ผู้ใช้งานต้องมีอายุตั้งแต่ 18 ปีขึ้นไป
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label" for="txtidcard">เลขบัตรประชาชน</label>
                <input type="text" id="txtidcard" name="txtidcard"
                  class="form-control {{(userform.controls.idCard.invalid || !isCanUseIDCard)?'is-invalid':''}}" mask="0-0000-00000-00-0"
                  formControlName="idCard" placeholder="เลขบัตรประชาชน 13 หลัก" [readonly]="IsView" (keyup)="CheckCanUseIDCard()">
                <div *ngIf="userform.controls.idCard.invalid" class="invalid-feedback">
                  <div *ngIf="userform.controls.idCard.errors.required">
                    กรุณากรอกเลขบัตรประชาชน 13 หลัก
                  </div>

                  <div *ngIf="userform.controls.idCard.errors.canUse">
                    {{userform.controls.idCard.errors.canUse}}
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div class="form-group">
            <div class="row">




              <div class="col-12 col-md-6" >
                <span class="h6 form-label">สำเนาบัตรประชาชน</span>
                <div class="row">
                  <div *ngFor="let image of userIDCards" class="col-12  text-center">
                    <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [imageSrc]="image.ImageUrl"
                      [extension]="image.ImageExtension!" [removable]="true" (removed)="ngxdropzoneimage_old_delete(image)">
                    </ngx-custom-dropzone-image-preview>
                    <!-- <ngx-dropzone-label>{{image.fileName}}</ngx-dropzone-label> -->
                  </div>
                  <div *ngIf="IsView && userIDCards.length == 0" class="col-12">
                    ไม่มีข้อมูลบัตรประชาชน
                  </div>
                  <div class="col-12  text-center" *ngFor="let f of userIDCardsNewImages">
                    <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true"
                      (removed)="ngxdropzoneimage_remove(f)"></ngx-custom-dropzone-image-preview>
                    <ngx-dropzone-label>{{f.name}}</ngx-dropzone-label>
                  </div>
                  <div  class="col-12 text-center" *ngIf="!IsView">
                    <!-- <div *ngIf="this.userIDCardsNewImages?.length + this.userIDCards?.length <= 0" id="ngxdropzoneimage" class="custom-ngx-dropzone"
                    [ngClass]="{'required': this.userIDCardsNewImages?.length + this.userIDCards?.length <= 0}"
                    ngx-dropzone
                     [accept]="'image/jpg, image/jpeg, image/png, .pdf'" [maxFileSize]="imageMaxFileSize" [multiple]="false"
                     (change)="ngxdropzoneimage_change($event)"> -->
                    <div *ngIf="this.userIDCardsNewImages?.length + this.userIDCards?.length <= 0" id="ngxdropzoneimage" class="custom-ngx-dropzone"
                     ngx-dropzone
                      [accept]="'image/jpg, image/jpeg, image/png, .pdf'" [maxFileSize]="imageMaxFileSize" [multiple]="false"
                      (change)="ngxdropzoneimage_change($event)">
                      <ngx-dropzone-label>
                        กดเพื่อเลือกรูปภาพ,หรือวางไฟล์ที่นี่
                        <!-- {{imageplaceholder}} -->
                        <br />
                        <small>(ขนาดไฟล์สูงสุด 10 MB)</small>
                      </ngx-dropzone-label>
                    </div>
                    <!-- <span class="text-danger"
                      *ngIf="this.userIDCardsNewImages?.length + this.userIDCards?.length <= 0">โปรดอัปโหลดรูปภาพ</span> -->
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6" *ngIf="IsDriver || driverlicenseReq">
                <span class="h6 form-label">สำเนาใบขับขี่</span>
                <div class="row">
                  <div *ngFor="let image of userDriverLicences" class="col-12  text-center">
                    <ngx-custom-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [imageSrc]="image.ImageUrl"
                      [extension]="image.ImageExtension!" [removable]="true" (removed)="user_driver_license_ngxdropzoneimage_old_delete(image)">
                    </ngx-custom-dropzone-image-preview>
                    <!-- <ngx-dropzone-label>{{image.fileName}}</ngx-dropzone-label> -->
                  </div>
                  <div *ngIf="IsView && userDriverLicences.length == 0" class="col-12">
                    ไม่มีข้อมูลสำเนาใบขับขี่
                  </div>
                  <div class="col-12  text-center" *ngFor="let f of userDriverLicencesNewImages">
                    <ngx-custom-dropzone-image-preview class="custom-ngx-dropzone required" ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true"
                      (removed)="user_driver_license_ngxdropzoneimage_remove(f)"></ngx-custom-dropzone-image-preview>
                    <ngx-dropzone-label>{{f.name}}</ngx-dropzone-label>
                  </div>
                  <div  class="col-12 text-center" *ngIf="!IsView">
                    <div *ngIf="this.userDriverLicencesNewImages?.length + this.userDriverLicences?.length <= 0" id="ngxdropzoneimage" class="custom-ngx-dropzone" ngx-dropzone
                      [accept]="'image/jpg, image/jpeg, image/png, .pdf'" [maxFileSize]="imageMaxFileSize" [multiple]="false"
                      (change)="user_driver_license_ngxdropzoneimage_change($event)">
                      <ngx-dropzone-label>
                        กดเพื่อเลือกรูปภาพ,หรือวางไฟล์ที่นี่
                        <!-- {{imageplaceholder}} -->
                        <br />
                        <small>(ขนาดไฟล์สูงสุด 10 MB)</small>
                      </ngx-dropzone-label>
                    </div>
                    <!-- <span class="text-danger"
                      *ngIf="this.userDriverLicencesNewImages?.length + this.userDriverLicences?.length <= 0 && (IsDriver || driverlicenseReq)">โปรดอัปโหลดรูปภาพ</span> -->
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- <div class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">สถานะ User</label>
                <ng-select [readonly]="IsView || !(permission70100.actionDto | permission : 3)" placeholder="สถานะ"
                  formControlName="status" [disabled]="">
                  <ng-option *ngFor="let item of Active" [value]="item.id">{{item.name}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div> -->

          <!-- <div *ngIf="IsEdit == false" class="form-group">
            <div class="row">
              <div class="col-md-12">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="IsusernamePhone" [checked]="IsusernamePhone"
                    [readOnly]="!(permission70100.actionDto | permission : 3)" (change)="checkUsernameChnage($event)">
                  <label class="custom-control-label" for="IsusernamePhone">ใช้เบอร์มือถือและอีเมลเป็น Username</label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="IsusernamePhone != true && IsEdit == false" class="form-group">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label" for="txtusername">Username</label>
                <input type="text" id="txtusername" name="txtusername" class="form-control" formControlName="username"
                  placeholder="Username" [readonly]="IsEdit">
              </div>
            </div>
          </div> -->



          <div *ngIf="IsEdit == false" class="panel-tag">
            บัญชีผู้ใช้ที่เพิ่มใหม่จะได้รับแจ้ง Username และ Password (ที่สร้างโดยระบบ) <br/> สำหรับการเข้าใช้งานครั้งแรก
            ผ่านทาง {{userform.controls.recievePasswordChanel.value===1 ? 'SMS ตามเบอร์' : 'อีเมล'}} ที่ระบุไว้
          </div>
        </div>
        <!--            <div class="modal-footer">-->
        <!--                <button type="reset" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>-->
        <!--                <button type="submit" class="btn btn-success">บันทึกข้อมูล</button>-->
        <!--            </div>-->
        <div class="modal-footer">
          <button type="reset" class="btn btn-primary" data-dismiss="modal" (click)="closed()">ยกเลิก</button>
          <button *ngIf="!onSaving && !IsView" type="submit" [disabled]="userform.invalid"
            class="btn {{IsEdit?'btn-success':'btn-success'}}">
            {{IsEdit?"บันทึกข้อมูล":"เพิ่ม User"}}
          </button>
          <button *ngIf="onSaving" class="btn {{IsEdit?'btn-warning':'btn-success'}}" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            กำลังบันทึกข้อมูล...
          </button>
        </div>
      </form>
  </ng-template>

  <app-modal-confirm #modalConfirm></app-modal-confirm>

  <app-modal-add-fleet-driver-form #modaladdfleetdriverform (Refresh)="addFleedClose()"></app-modal-add-fleet-driver-form>
  <app-modalsuccess #modalsuccess [btnsubmit_ishide]=true [btnother_ishide]=true  (btnother_event)="success_other($event)"></app-modalsuccess>
  <app-modal-add-user-success #modaladdsuccess [btnsubmit_ishide]=true [btnother_ishide]=true  (btnother_event)="success_other($event)"></app-modal-add-user-success>
