<ng-template #modaltraininghistorydetail let-modal>
  <div class="modal-header">
    <div class="modal-title">
      <h5 class="modal-title"> <i class="fal fa-star color-success-100 fw-900 mr-1"></i>ประวัติการอบรม</h5>

      <!-- <span class="help-block text-muted mr-2">
        <span>คำอธิบาย</span>
      </span>

      <span class="help-block text-warning mr-2">
        <i class="fal fa-edit mr-1"></i><span>แก้ไขประวัติการอบรม</span>
      </span>

      <span class="help-block text-success mr-2">
        <i class="fal fa-check mr-1"></i><span>อบรมสำเร็จ</span>
      </span>

      <span class="help-block text-danger">
        <i class="fal fa-times mr-1"></i><span>ยกเลิกการอบรม</span>
      </span> -->

    </div>
    <!-- <button type="button" class="close topright" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button> -->

    <button type="button" class="btn btn-sm-custom btn-outline-danger waves-effect waves-themed" aria-hidden="true"
      data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="fal fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="col-12 text-center">
        <a class="text-success" (click)="openmodaltraininghistoryform()"><i
            class="fas fa-plus-square mr-1"></i><u>เพิ่มประวัติการอบรม</u></a>
      </div>

      <div class="row">
        <div class="col-12 d-flex">
          <div class="help-block text-muted mr-auto">
            กำหนดการอบรมที่กำลังจะถึง
          </div>
          <span *ngIf="userTrainings.length > 0" class="badge badge-success badge-pill align-middle"><span
              class="align-middle">กำหนดถัดไป
              {{userTrainings[0].date|thaidate:"short"}}</span></span>
        </div>

        <div *ngFor="let userTraining of userTrainings" class="col-12">
          <div *ngIf="userTraining.trainingStatus == 1" class="row mt-2 border rounded">
            <div class="col-12">
              <div class="row">
                <div class="help-block text-muted col-4">วันที่</div>
                <div class="help-block text-muted col-8">หัวข้อการอบรม</div>
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <label class="col-4">{{userTraining.date|thaidate:'short'}}</label>
                <label class="col-8">{{userTraining.title}}</label>
              </div>
            </div>

            <div *ngIf="userTraining.detail" class="col-12">
              <div class="row">
                <div class="help-block text-muted col-4">รายละเอียด</div>
              </div>
            </div>

            <!-- <div class="col-12">
              <div class="row">
                <div *ngFor="let item of userTraining.userTrainingFiles" class="col-4">
                  <img class="img-fluid rounded" src="{{item.filePath}}" alt="">
                </div>
              </div>
            </div> -->

            <div class="col-12">{{userTraining.detail}}</div>


            <div class="col-12">
              <app-gallery-item [userTraining]="userTraining" [galleryId]="'training-history-detail-card' + userTraining?.id"></app-gallery-item>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <button class="btn btn-sm btn-block btn-success waves-effect waves-themed mt-2 mb-2"
                    (click)="openmodaltraininghistoryform_success(userTraining)">กดเมื่อทำเสร็จ</button>
                </div>
              </div>
            </div>



          </div>
          <!-- <div *ngIf="userTraining.trainingStatus == 1" class="topright">
            <button class="btn btn-sm btn-icon btn-outline-success rounded-circle shadow-0 m-1" (click)="openmodaltraininghistoryform_edit(userTraining)"><i
              class="fal fa-ellipsis-h"></i></button>
          </div> -->

          <div *ngIf="userTraining.trainingStatus == 1" class="btn-group topright">
            <button type="button" class="btn btn-sm btn-icon btn-outline-success rounded-circle shadow-0 m-1"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fal fa-ellipsis-h"></i>
            </button>

            <div class="dropdown-menu">
              <button class="dropdown-item" type="button"
                (click)="openmodaltraininghistoryform_edit(userTraining)">แก้ไข</button>
              <button class="dropdown-item" type="button"
                (click)="updateTrainingStatus(userTraining,trainingStatusCancel)">ยกเลิก</button>
            </div>
          </div>

          <!-- <div *ngIf="userTraining.trainingStatus == 1" class="topright">
            <button class="btn btn-sm btn-icon btn-outline-warning rounded-circle shadow-0 m-1" (click)="openmodaltraininghistoryform_edit(userTraining)"><i
                class="fal fa-edit"></i></button>
            <button class="btn btn-sm btn-icon btn-outline-success rounded-circle shadow-0 m-1" (click)="updateTrainingStatus(userTraining,trainingStatusComplete)"><i
                class="fal fa-check"></i></button>
            <button class="btn btn-sm btn-icon btn-outline-danger rounded-circle shadow-0 m-1" (click)="updateTrainingStatus(userTraining,trainingStatusCancel)"><i
                class="fal fa-times"></i></button>
          </div> -->
        </div>

        <div *ngIf="userTrainings.length <= 0" class="col-12">
          <div class="row mt-2 border rounded">
            <div class="col-12 text-center">
              <span class="text-muted">ไม่มีข้อมูล</span>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex mt-4">
          <div class="help-block text-muted mr-auto text-success">
            ประวัติการอบรมที่สำเร็จแล้ว
          </div>
        </div>

        <div *ngFor="let userTraining of userTrainings" class="col-12">
          <div *ngIf="userTraining.trainingStatus != 1"  class="row mt-2 border rounded">
            <div class="col-12" *ngIf="userTraining.trainingStatus == 2">
              <div class="row">
                <div class="help-block text-muted col-4">วันที่</div>
                <div class="help-block text-muted col-8">หัวข้อการอบรม</div>
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <label class="col-4">{{userTraining.date|thaidate:'short'}}</label>
                <label class="col-8">{{userTraining.title}}</label>
              </div>
            </div>

            <div *ngIf="userTraining.detail" class="col-12">
              <div class="row">
                <div *ngIf="userTraining.detail" class="help-block text-muted col-4">รายละเอียด</div>
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-12">{{userTraining.detail}}</div>
                <!-- <div class="col-12">
                            <button class="btn btn-sm btn-block btn-success waves-effect waves-themed mt-2 mb-2">กดเมื่อทำเสร็จ</button>
                        </div> -->
              </div>
            </div>

            <div class="col-12">
              <app-gallery-item [userTraining]="userTraining" [galleryId]="'training-history-detail-card' + userTraining?.id"></app-gallery-item>
            </div>

            <!-- <div class="col-12">
              <div class="row">
                <div *ngFor="let item of userTraining.userTrainingFiles" class="col-4">
                  <img class="img-fluid rounded" src="{{item.filePath}}" alt="">
                </div>
              </div>
            </div> -->

          </div>
          <!-- <div class="topright">Top Right</div> -->
          <div *ngIf="userTraining.trainingStatus == 2" class="topright">
            <button class="btn btn-sm btn-icon btn-success rounded-circle shadow-0 m-1"
              (click)="openmodaltraininghistoryform_success(userTraining)"><i class="fal fa-edit"></i></button>
          </div>
          <div *ngIf="userTraining.trainingStatus == 3" class="topright">
            <span class="badge badge-danger badge-pill">ยกเลิก</span>
          </div>
        </div>

        <div *ngIf="userTrainings.length <= 0" class="col-12">
          <div class="row mt-2 border rounded">
            <div class="col-12 text-center">
              <span class="text-muted">ไม่มีข้อมูล</span>
            </div>
          </div>
        </div>

        <!-- <div class="col-12 mt-2 border rounded">
          <div class="row mt-3">
            <div class="col-12">
              <div class="row">
                <label class="col-4">21/12/2564</label>
                <label class="col-8">อบรมการป้องกันอุบัติเหตุขณะขนส่ง</label>
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              </div>
            </div>

            <div class="col-6">
              <img src="{{img}}" class="h-auto w-50 rounded">
            </div>

          </div>
          <button class="btn btn-sm btn-icon btn-success rounded-circle shadow-0 topright"><i
              class="fal fa-edit"></i></button>
        </div> -->
      </div>

    </div>
  </div>
</ng-template>

<app-modal-training-history-form #modaltraininghistoryform modalTitle="เพิ่มประวัติการอบรม" action="CREATE"
  driver_id={{driver_id}} (modal_closed)="onOpenModal()" (historydetail_fetch)="fetchHistoryDetail()">
</app-modal-training-history-form>

<app-modal-training-history-form #modaltraininghistoryform_edit modalTitle="แก้ไขประวัติการอบรม" action="EDIT"
  driver_id={{driver_id}} (historydetail_fetch)="fetchHistoryDetail()" (modal_closed)="onOpenModal()">
</app-modal-training-history-form>

<app-modal-training-history-form #modaltraininghistoryform_success modalTitle="ประวัติการอบรมสำเร็จ" action="SUCCESS"
  driver_id={{driver_id}} status_update=true (historydetail_fetch)="fetchHistoryDetail()"
  (onCompleteEvent)="openmodaltraininghistoryform()" (modal_closed)="onOpenModal()">
</app-modal-training-history-form>

<app-modal-training-history-form #modaltraininghistoryform_after_success modalTitle="ประวัติการอบรมสำเร็จ"
  action="SUCCESS" driver_id={{driver_id}} (historydetail_fetch)="fetchHistoryDetail()"
  (onCompleteEvent)="openmodaltraininghistoryform()" (modal_closed)="onOpenModal()">
</app-modal-training-history-form>

<app-modal-confirm #modalConfirm></app-modal-confirm>
