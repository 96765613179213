import {BookingModel, deliver_status} from "../../models/booking-model";

export const bookingData: BookingModel[] =  [
  { id:1,
    booking_no:"B20L0002",
    do:"DS2010-0008",
    factory:"โรงงาน BSI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.success},
  { id:2,
    booking_no:"B20L0003",
    do:"DS2010-0001",
    factory:"โรงงาน TMI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.success},
  { id:3,
    booking_no:"B20L0004",
    do:"DS2010-0009",
    factory:"โรงงาน BSI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.success},
  { id:4,
    booking_no:"B20L0005",
    do:"DS20100015",
    factory:"โรงงาน BSI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.success},
  { id:5,
    booking_no:"B20L0006",
    do:"DS20100018",
    factory:"โรงงาน TRR",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"8.00น.",
    customer_name:"บจก.คาราบาวตะวันแดง",
    destination_name:"Carabao Dang Co. factory.",
    destination_detail:  "261-263 หมู่ 2 ถ.ปานวิถี ต.บางเพรียง บางบ่อ Samut Prakan 10560",
    destination_location:"ฉะเชิงเทรา",
    deliver_status:deliver_status.success},
  { id:6,
    booking_no:"B20L0007",
    do:"DS2010-0002",
    factory:"โรงงาน TMI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.late},
  { id:7,
    booking_no:"B20L0008",
    do:"DS2010-0061",
    factory:"โรงงาน BSI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.late},
  { id:8,
    booking_no:"B20L0009",
    do:"DS20100105 - DS20100108",
    factory:"โรงงาน TRR",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"ไทยน้ำทิพย์ ปทุมธานี",
    destination_name:"ThaiNamthip Pathumthani plant Coca Cola",
    destination_detail:  "ตำบล บางขะแยง อำเภอเมืองปทุมธานี ปทุมธานี 12000",
    destination_location:"ปทุมธานี",
    deliver_status:deliver_status.success},
  { id:9,
    booking_no:"B20L0010",
    do:"DS20100063",
    factory:"โรงงาน PS",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บจ.ซันสวีท",
    destination_name:"บริษัท ซันสวีท จำกัด (มหาชน)",
    destination_detail:  "ถนน สันป่าตอง-แม่วาง ตำบล ทุ่งสะโตก อำเภอสันป่าตอง เชียงใหม่ 50120",
    destination_location:"เชียงใหม่",
    deliver_status:deliver_status.success},
  { id:10,
    booking_no:"B20L0011",
    do:"DS2010-0062",
    factory:"โรงงาน BSI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.success},
  { id:11,
    booking_no:"B20L0012",
    do:"DS2010-0064",
    factory:"โรงงาน PS",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"9.00 -10.00",
    customer_name:"บริษัท ไทยเพรซิเดนท์ฟูดส์ จำกัด (มหาชน)",
    destination_name:"Thai President Foods PCL (Lumphun)",
    destination_detail:  "99, ตำบล ป่าสัก อำเภอเมืองลำพูน ลำพูน 51000",
    destination_location:"ลำพูน",
    deliver_status:deliver_status.success},
  { id:12,
    booking_no:"B20L0013",
    do:"DS2010-0065",
    factory:"โรงงาน PS",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"9.00 -10.00",
    customer_name:"บริษัท ไทยเพรซิเดนท์ฟูดส์ จำกัด (มหาชน)",
    destination_name:"Thai President Foods PCL (Lumphun)",
    destination_detail:  "99, ตำบล ป่าสัก อำเภอเมืองลำพูน ลำพูน 51000",
    destination_location:"ลำพูน",
    deliver_status:deliver_status.success},
  { id:13,
    booking_no:"B20L0014",
    do:"DS20100067",
    factory:"โรงงาน PS",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"09.00-10.30",
    customer_name:"ไทยน้ำทิพย์ ลำปาง",
    destination_name:"บริษัท ไทยน้ำทิพย์ จำกัด สาขาลำปาง",
    destination_detail:  "76 ม. 1, ถ.ไฮเวย์ลำปาง-เชียงใหม่, ต.ปงแสนทอง อ.เมือง จ.ลำปาง, 52100",
    destination_location:"ลำพูน",
    deliver_status:deliver_status.success},
  { id:17,
    booking_no:"B20L0017",
    do:"DS2010-0004",
    factory:"โรงงาน TMI",
    delivered_date: new Date("1-Oct-20"),
    delivered_time:"10.00 น.(พร้อมคนลง)",
    customer_name:"บริษัท คิวพี (ประเทศไทย) จำกัด",
    destination_name:"Kewpie Thailand Company Limited",
    destination_detail:  "55 ตำบล หลุมดิน อำเภอเมืองราชบุรี ราชบุรี 70000",
    destination_location:"ราชบุรี",
    deliver_status:deliver_status.late},
];
