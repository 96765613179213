import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { QrScannerComponent } from 'angular2-qrscanner';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-qr-reader',
  templateUrl: './modal-qr-reader.component.html',
  styleUrls: ['./modal-qr-reader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalQrReaderComponent implements OnInit {
  public myAngularxQrCode: string = null;

  @ViewChild(QrScannerComponent,{static : false}) qrScannerComponent: QrScannerComponent ;
 
  @Output()  isclose = new EventEmitter<string>();
  @Input() isRead = true;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  hasDevices: boolean;
  hasPermission: boolean;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  constructor( private router: Router,) { 
    this.myAngularxQrCode = '/booking/create';
  }

  ngOnInit(): void {
    
   
  }

  ngAfterViewInit(): void {
   if(this.isRead){

   //  this.reader()
    }
  }

  reader(){
    this.qrScannerComponent.getMediaDevices().then(devices => {
      console.log(devices);
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
          if (device.kind.toString() === 'videoinput') {
              videoDevices.push(device);
          }
      }
      if (videoDevices.length > 0){
          let choosenDev;
          for (const dev of videoDevices){
              if (dev.label.includes('front')){
                  choosenDev = dev;
                  break;
              }
          }
          if (choosenDev) {
              this.qrScannerComponent.chooseCamera.next(choosenDev);
          } else {
              this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
          }
      }
  });

  this.qrScannerComponent.capturedQr.subscribe(result => {
      console.log(result);
      window.location.href = result;

      this.qrScannerComponent.ngOnDestroy();
      console.log(this.validURL(result))
      if(this.validURL(result)){
       // this.router.navigateByUrl(result,{ skipLocationChange: true });

      }

  });
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  close(){
    try{

      this.qrScannerComponent.ngOnDestroy();
    }catch(e){
      console.log("cant destroy qrScannerComponent");
    }
    this.isclose.emit('closeed')
  }

  onCodeResult(resultString: string) {
    console.log(this.validURL(resultString))
    if(this.validURL(resultString)){
     // this.router.navigateByUrl(resultString,{ skipLocationChange: true });
     window.location.href = resultString;
    }
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }


  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

}
