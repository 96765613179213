<div class="modal fade" id="ModalEditRef{{bookingID}}" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <form (ngSubmit)="SubmitForm()">
                <div class="modal-header">
                    <h5 class="modal-title">{{booking?.bookingNo}} แก้ไข Reference No.</h5>

                </div>

                <div class="modal-body" [formGroup]="editForm">
                    <div class="container-fluid">
                        <div class="row" *ngIf="!loadDataComplete">
                            <div class="col-12 text-center">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                        </div>
                        <div class="row mt-3" formArrayName="customerOrder" *ngIf="loadDataComplete">

                            <div class="col-12">
                                <h4> กรอกรายละเอียด Order </h4>
                                <div class="panel" [formGroupName]="j"
                                    *ngFor="let order of getCustomerOrder();let j = index">
                                    <div class="panel-hdr  bg-light-gray">
                                        <h2>
                                            Order {{j+1}}
                                        </h2>
                                        <div class="panel-toolbar">
                                            <button type="button"
                                                class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0"
                                                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10"
                                                data-original-title="Collapse"><i
                                                    class="fas fa-window-minimize"></i></button>

                                            <button type="button"
                                                class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0"
                                                [ngClass]="getCustomerOrder().length == 1 ?'d-none' : ''"
                                                (click)="removeOrder(j)" data-original-title="Delete"><i
                                                    class="fas fa-trash" style="color: white;"
                                                  ></i></button>
                                        </div>
                                    </div>
                                    <div class="panel-container show">
                                        <div class="panel-content">
                                            <div class="row">
                                                <div class="col-md-4" *ngIf="bookingRef?.isRef1 == 'true'">
                                                    <div class="form-group">
                                                        <label class="form-label" for="">Reference No.</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    {{bookingRef?.referenceNO1}}
                                                                </span>
                                                            </div>
                                                            <input class="form-control" type="text" formControlName="ref_no_1"
                                                               >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="bookingRef?.isRef2 == 'true'">
                                                    <div class="form-group">
                                                        <label class="form-label" for="">Reference No.</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    {{bookingRef?.referenceNO2}}
                                                                </span>
                                                            </div>
                                                            <input class="form-control" type="text"
                                                                formControlName="ref_no_2"
                                                                >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="bookingRef?.isRef3 == 'true'">
                                                    <div class="form-group">
                                                        <label class="form-label" for="">Reference No.</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    {{bookingRef?.referenceNO3}}
                                                                </span>
                                                            </div>
                                                            <input class="form-control" type="text"
                                                                formControlName="ref_no_3">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <span class="help-block" [ngClass]="Ref.length < 3 ? '':'d-none'"> <u> <a (click)="addRef()">+ Reference</a></u> </span> -->

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" *ngIf="loadDataComplete">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" [disabled]="saving"
                        (click)="CloseModal()">
                        ยกเลิก
                    </button>
                    <button [disabled]="saving" type="submit" class="btn btn-success">
                        <span *ngIf="saving" class="spinner-grow spinner-grow-sm"></span>
                        บันทึก
                    </button>
                </div>
            </form>
        </div>

    </div>
</div>
