import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../../../core/_Models/UserModel/User';
import {
  AuthenticationService,
  HttpService,
  NotificationService,
} from '../../../core/_Services';
import { TMSAPIResponse } from '../../../core/_Models/TMSAPIResponse';
import {
  ClientAPIPath,
  MasterAPIPath,
  TeamAPIPath,
} from '../../../core/_Constants/apiConstants';
import { takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { AddClientUserModel } from '../models/ClientUser/AddClientUserModel';
import { UpdateClientUserModel } from '@app/modules/team/models/ClientUser/UpdateClientUserModel';

@Injectable()
export class ClientUserService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentUser: User;

  constructor(
    private _apiService: HttpService,
    private _noti: NotificationService,
    private authenticationService: AuthenticationService
  ) {
    // get current user on login
    this.authenticationService.currentUser.subscribe(
      (x) => {

        (this.currentUser = x)
        console.log(x);
      }
    );
  }

  GetClientUserById(tst360UserId: number): Observable<TMSAPIResponse> {
    return this._apiService.get(ClientAPIPath.ClientGetUser + tst360UserId);
  }

  CheckCanUseMobileNo(mobileNo: string, authorizeId? : string, companyID? : string, userId?: number): Observable<object> {
    return this.authenticationService.CanUserThisMobileNo(mobileNo, authorizeId, companyID, userId);
  }

  CheckCanUseEmail(email: string): Observable<object> {
    return this.authenticationService.CanUserThisEmail(email);
  }

  GetClientUserNoDriverAllList(): Observable<TMSAPIResponse> {
    return this._apiService.get(
      ClientAPIPath.ClientGetUserNoDriverAllList + this.currentUser.ClientID
    );
  }

  GetClientUserAllList(textSearch: string = ''): Observable<TMSAPIResponse> {
    if (textSearch != '') {

      return this._apiService.get(
        ClientAPIPath.ClientGetUserAllList + this.currentUser.ClientID + '/' + textSearch
      );
    }
    else {
      return this._apiService.get(
        ClientAPIPath.ClientGetUserAllList + this.currentUser.ClientID
      );
    }
  }
  UploadUserImage(data: FormData): Observable<any> {
    return this._apiService.fileupload(
      ClientAPIPath.ClientUserUploadImage,
      data
    );
  }
  UpdateUserImageAvatar(data: any): Observable<any> {
    return this._apiService.patch(
      ClientAPIPath.ClientUserUploadImage,
      data
    );
  }
  AddClientUser(form: FormGroup): Observable<TMSAPIResponse> {

    //console.log(JSON.stringify(form.value));
    let userData = new AddClientUserModel();
    userData.clientID = this.currentUser.ClientID;
    userData.tsT360_CompanyID = this.currentUser.tstS360_CompanyID;

    userData.username = form.value.username;
    userData.phoneNumber = form.value.mobileNumber;
    userData.email = form.value.email;
    userData.password = '360@tst@truck';
    userData.firstName = form.value.firstname;
    userData.lastName = form.value.lastname;
    userData.idCard = form.value.idCard;
    userData.taxId = null;
    userData.photoURL = form.value.photoURL;
    userData.tsT360_DefaultUserServiceType = this.authenticationService.currentUserValue?.registerPackage?.serviceTypeId;
    userData.departmentId = form.value.departmentId;
    userData.emailLink = 'http://' + window.location.host + '/login';
    userData.roleId = form.value.roleId;
    userData.teamIds = form.value.teamIds;
    userData.isActive = form.value.status || true;

    userData.birthDate = form.value.birthDate;


    userData.authorizeID = form.value.authorizeID;
    userData.recievePasswordChanel = form.value.recievePasswordChanel;
    //console.log(JSON.stringify(userData));
    return this._apiService.post(ClientAPIPath.ClientGetUser, {
      addClientUserDto: userData,
    });
  }

  UpdateClientUser(
    form: FormGroup,
    EditData: AddClientUserModel
  ): Observable<TMSAPIResponse> {
    let userData = new UpdateClientUserModel();
    userData.clientID = this.currentUser.ClientID;
    userData.tst360CompanyId = EditData.tsT360_CompanyID;
    userData.tst360UserId = EditData.tsT360_UserID;
    userData.baseClientUser.clientID = EditData.clientID;
    userData.baseClientUser.tsT360_CompanyID = EditData.tsT360_CompanyID;
    userData.baseClientUser.username = form.value.username;
    userData.baseClientUser.phoneNumber = form.value.mobileNumber;
    userData.baseClientUser.mobileNo = form.value.mobileNumber;
    userData.baseClientUser.email = form.value.email;
    userData.baseClientUser.firstName = form.value.firstname;
    userData.baseClientUser.lastName = form.value.lastname;
    userData.baseClientUser.iDcard = form.value.idCard;
    userData.baseClientUser.taxId = EditData.taxId;
    userData.baseClientUser.birthDate = EditData.birthDate;
    userData.baseClientUser.photoURL = form.value.photoURL;

    userData.identityUserClient.tstUserId = EditData.tstUserId;

    if (EditData.phoneNumber != form.value.mobileNumber) userData.identityUserClient.phoneNumber = form.value.mobileNumber;
    if (EditData.email != form.value.email) userData.identityUserClient.email = form.value.email;
    userData.identityUserClient.userName = form.value.username;
    EditData.roleUser.roleID = form.value.roleId;
    EditData.roleUser.userID = EditData.tsT360_UserID;

    if (form.value.teamIds) {
      EditData.teamUser = [];
      for (let i = 0; i < form.value.teamIds.length; i++) {
        EditData.teamUser.push({
          id: 0,
          teamID: form.value.teamIds[i],
          userID: EditData.tsT360_UserID,
          valid: true
        });
      }
    }

    userData.teamUser = EditData.teamUser;
    userData.userRole = EditData.roleUser;
    userData.userRole.id = form.value.roleId;
    userData.userRole.isActive = form.value.status;
    userData.userRole.valid = true;

    return this._apiService.patch(ClientAPIPath.ClientGetUser, {
      updateClientUserDto: userData,
    });
  }

  updateStatusClientUser(
    EditData: AddClientUserModel
  ): Observable<TMSAPIResponse> {
    let userData = new UpdateClientUserModel();
    userData.clientID = this.currentUser.ClientID;
    userData.tst360CompanyId = EditData.tsT360_CompanyID;
    userData.tst360UserId = EditData.tsT360_UserID;
    userData.baseClientUser.clientID = EditData.clientID;
    userData.baseClientUser.tsT360_CompanyID = EditData.tsT360_CompanyID;
    userData.baseClientUser.username = EditData.username;
    userData.baseClientUser.phoneNumber = EditData.phoneNumber;
    userData.baseClientUser.mobileNo = EditData.phoneNumber;
    userData.baseClientUser.email = EditData.email;
    userData.baseClientUser.firstName = EditData.firstName;
    userData.baseClientUser.lastName = EditData.lastName;
    userData.baseClientUser.iDcard = EditData.idCard;
    userData.baseClientUser.taxId = EditData.taxId;
    userData.baseClientUser.birthDate = EditData.birthDate;
    userData.baseClientUser.photoURL = EditData.photoURL;

    userData.identityUserClient.tstUserId = EditData.tstUserId;

    userData.identityUserClient.userName = EditData.username;
    EditData.roleUser.roleID = EditData.roleId;
    EditData.roleUser.userID = EditData.tsT360_UserID;

    if (EditData.teamIds) {
      EditData.teamUser = [];
      for (let i = 0; i < EditData.teamIds.length; i++) {
        EditData.teamUser.push({
          id: 0,
          teamID: EditData.teamIds[i],
          userID: EditData.tsT360_UserID,
          valid: true
        });
      }
    }

    userData.teamUser = EditData.teamUser;
    userData.userRole = EditData.roleUser;
    userData.userRole.id = EditData.roleId;
    userData.userRole.isActive = EditData.isActive;
    userData.userRole.valid = true;

    return this._apiService.patch(ClientAPIPath.ClientGetUser, {
      updateClientUserDto: userData,
    });
  }

  GetAllDepartment(): Observable<TMSAPIResponse> {
    return this._apiService.get(MasterAPIPath.GetAllDepartment + `/by-clientid/${this.currentUser.ClientID}`);
  }

  GetRoleByDepartment(departmentId: number, user: User): Observable<TMSAPIResponse> {
    return this._apiService.get(
      MasterAPIPath.GetRoleByDepartment + departmentId + `/${user.ClientID}`
    );
  }

  GetRoleByClientId(user: User): Observable<TMSAPIResponse> {
    return this._apiService.get(
      MasterAPIPath.GetRoleByClient + `${user.ClientID}`
    );
  }

  GetTeamByClient(): Observable<TMSAPIResponse> {
    return this._apiService.get(
      TeamAPIPath.GetTeamWithClient + this.currentUser.ClientID
    );
  }

  uploadfile(file: any) {
    let clientID = this.currentUser.ClientID; // get from user client id
    // get from after add product
    // set file Pattern  ref. https://360truck.backlog.com/wiki/DEV/360TRUCK%2FTMS%2FFileServer%2FFile+Pattern+%28S3%29
    let S3Path =
      'tst-user/c' +
      this.currentUser.tstS360_CompanyID +
      '/u' +
      this.currentUser.tstS360_UserID +
      '/';
    let filename = file.name.substring(0, file.name.lastIndexOf('.'));
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    let newfilename =
      'u' + this.currentUser.tstS360_UserID + '-profile' + fileExt;
    // let result =  await this._fileupload.Upload(file,S3Path,newfilename);
  }
  DeleteUser(userId: number): Observable<TMSAPIResponse> {
    return this._apiService.delete(ClientAPIPath.ClientUser + userId);
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
