<!-- <div [ngClass]="Col"> -->
<div class="mt-3" *ngIf="IsImage">
    <div class="card-body p-0">
        <img src="{{FileUrl}}" class="card-img-top" (mouseout)="onMouseLeave()" (mouseenter)="onMouseMove()" alt="..." (click)="OpenModal()">
        <div class="img-view text-center" (click)="OpenModal()">
            <i class="fas fa-eye"></i>
        </div>
        <a (click)="onDelete()" class="card-link mt-2" style="color: red;float: right;">
            <i class="fas fa-trash-alt"></i> ลบ
        </a>
    </div>
</div>
<div class="mt-3" *ngIf="!IsImage">
    <button class="btn btn-block btn-outline-secondary" (click)="onDownload()">
                <i class="fal fa-cloud-download"></i>&nbsp;{{filename}}
            </button>
    <a (click)="onDelete()" class="card-link mt-2" style="color: red;float: right;">
        <i class="fas fa-trash-alt"></i> ลบ
    </a>
</div>
<!-- </div> -->



<!-- Fullscreen Modal -->
<div class="modal fade modal-fullscreen example-modal-fullscreen" id="{{ModalID}}" style="z-index: 3000!important;" tabindex="1" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content h-100 border-0 shadow-0 ">
            <button type="button" class="close p-sm-2 p-md-4 text-white fs-xxl position-absolute pos-right mr-sm-2 mt-sm-1 z-index-space" (click)="CloseModal()" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
            <div class="modal-body p-0">
                <img class="d-block w-100" src="{{FileUrl}}" alt="Second slide">
            </div>
        </div>
    </div>
</div>