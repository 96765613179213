<form [formGroup]="searchFilterForm">
  <div class="row">
    <div class="col-12">
      <!-- <div class="card-title mt-2">Filter ตัวกรองข้อมูล</div> -->

      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
          <div class="help-block text-muted">ค้นหา</div>
          <div class="form-group">
            <input type="text" class="form-control" placeholder="ชื่อ/นามสกุล / เบอร์โทรศัพท์"
              aria-label="ชื่อ/นามสกุล / เบอร์โทรศัพท์" formControlName="textSearch" />
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-2">
          <div class="help-block text-muted">สถานะใบขับขี่</div>
          <div class="form-group">
              <ng-select placeholder="เลือกสถานะ" formControlName="driverLicenceStatusId">
                <ng-option *ngFor="let item of driverLicenceStatusId" [value]="item.id">{{item.name}}</ng-option>
              </ng-select>

          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-2">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="help-block text-muted">สถานะผู้ใช้</div>
                <ng-select placeholder="เลือกสถานะ" formControlName="isEnabled">
                  <ng-option *ngFor="let item of driverStatus" [value]="item.id">{{item.name}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>

        </div>



        <div class="col-12 col-sm-6 col-md-2">
          <div class="help-block text-muted">สถานะรับงาน</div>
          <div class="form-group">
            <ng-select placeholder="เลือกสถานะ" formControlName="shipmentStatusId">
              <ng-option *ngFor="let item of shipmentStatusId" [value]="item.id">{{item.name}}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="col-12 mt-2 mb-2">

          <div class="row align-text-bottom">
            <div class="col-6 col-sm-2">
              <button class="btn btn-success waves-effect waves-themed btn-block" (click)="searched()">ค้นหา</button>
            </div>
            <div class="col-6 col-sm-2">
              <button class="btn btn-dark waves-effect waves-themed btn-block" (click)="cleared()">ล้างตัวกรอง</button>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</form>