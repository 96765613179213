<table class="table table-borderd" *ngIf="matched">
    <thead>
        <tr>
            <th>
                time
            </th>
            <th>
                bookingCount 
            </th>
            <th>
                TruckBookingNo : DO Numbers
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of times">
            <td>
                {{item.time}}
            </td>
            <td>
                {{item.bookings.length}}
            </td>
            <td>
                <div *ngFor="let b of item?.bookings">
                    {{b.truckbookingNo}} : 
                    <span *ngFor="let do of b.DOs ;let i = index">
                      <span *ngIf="i >0">,</span>  {{do}}
                    </span>
                </div>
            </td>
        </tr>
    </tbody>
</table>