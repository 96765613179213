import { Injectable } from '@angular/core';
import { InsuranceCompanyApiPath } from '@app/core/_Constants/apiConstants';
import { InsuranceCompanyModel } from '@app/core/_Models/MasterModel/InsuranceCompanyModel';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { HttpService } from '@app/core/_Services';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCompanyService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  insurnaceCompanyListOnChange: BehaviorSubject<InsuranceCompanyModel[]>;
  constructor(private apiService: HttpService) {
    this.insurnaceCompanyListOnChange = new BehaviorSubject(new Array<InsuranceCompanyModel>());
  }
  getInsuranceCompanyList(): Promise<InsuranceCompanyModel[]> {
    return new Promise((resolve, reject) => {
      this.apiService.get(InsuranceCompanyApiPath.GetList)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // console.log('getCompanyTruckLis', res);
          this.insurnaceCompanyListOnChange.next(res.data);
          resolve(res.data);
        });
    })
  }

}
