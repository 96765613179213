<header class="page-header" role="banner" id="welcome-intro">
    <!-- we need this logo when user switches to nav-function-top -->
    <div class="page-logo">
        <a href="#" class="page-logo-link press-scale-down d-flex align-items-center position-relative"
            data-toggle="modal" data-target="#modal-shortcut">
            <img src="assets/img/logo.png" (error)="onImgErrorProfile($event)" alt="SmartAdmin WebApp"
                aria-roledescription="logo">
            <span class="page-logo-text mr-1">SmartAdmin WebApp</span>
            <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
            <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
        </a>
    </div>
    <!-- DOC: nav menu layout change shortcut -->
    <div class="hidden-md-down dropdown-icon-menu position-relative">
        <a href="#" class="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden"
            title="Hide Navigation">
            <i class="ni ni-menu"></i>
        </a>
        <ul>
            <li>
                <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-minify"
                    title="Minify Navigation">
                    <i class="ni ni-minify-nav"></i>
                </a>
            </li>
            <li>
                <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed"
                    title="Lock Navigation">
                    <i class="ni ni-lock-nav"></i>
                </a>
            </li>
        </ul>
    </div>
    <!-- DOC: mobile button appears during mobile width -->
    <div class="hidden-lg-up">
        <a href="#" class="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
            <i class="ni ni-menu"></i>
        </a>
    </div>

    <div *ngIf="currentPackage?.id == 'PC'" style="color: white;">
      <span class="h4 m-0">จำนวนเที่ยว : <span [ngClass]="{ 'text-warning': shipmentUsagePercent > 70 && shipmentUsagePercent <= 99, 'text-danger': shipmentUsagePercent >= 100 }">{{shipmentCount | number}}</span>/{{shipmentTotal | number}}</span>
    </div>
    <!-- <div class="search">
        <form class="app-forms hidden-xs-down" role="search" action="page_search.html" autocomplete="off">
            <input type="text" id="search-field" placeholder="ค้นหา..." class="form-control" tabindex="1">
            <a href="#" onclick="return false;" class="btn-danger btn-search-close js-waves-off d-none" data-action="toggle" data-class="mobile-search-on">
                <i class="fal fa-times"></i>
            </a>
        </form>
    </div> -->
    <div class="ml-auto d-flex">
        <div class="d-none d-md-flex">
          <a href="/pricing" class="btn btn-success mt-3 mb-3 bg-brand-gradient btn-11" type="button"  *ngIf="currentPackage?.id == 'BC' && !currentPackage?.isShowUpgradePremium">
            <div class="dot"></div>อัพเกรดเป็น Premium ฟรีทดลองใช้งาน 30 วัน
          </a>
          <a href="/pricing" class="btn btn-success mt-3 mb-3 bg-brand-gradient btn-11" type="button"  *ngIf="currentPackage?.id == 'BC' && currentPackage?.isShowUpgradePremium">
            <div class="dot"></div>อัพเกรดเป็น Premium
          </a>
          <a href="/pricing" class="btn btn-outline-warning mt-3 mb-3 bg-brand-gradient btn-11"  *ngIf="currentPackage?.id == 'PC' && isShowTrial && diffDays >= 0">
            ทดลองใช้งานอีก {{diffDays}} วัน
          </a>
            &nbsp;
            <button class="btn btn-success mt-3 mb-3 " type="button" (click)="goto360jobsearch()"  *ngIf="clientModule?.pkg_id < 30">
             <i class="fal fa-flag"></i> ค้นหางาน
            </button>

            &nbsp;

            <button class="btn btn-success mt-3 mb-3 " type="button" (click)="SaveUserAction('สร้างงานใหม่(TopMenuBar)')">
                <a  [routerLink]="['/booking/create-basic']" style="color: #000;"> <i class="fal fa-layer-plus"></i> สร้างงานใหม่</a>
            </button>


        </div>
        <!-- <div class="hidden-sm-up">
            <a href="#" class="header-icon" data-action="toggle" data-class="mobile-search-on" data-focus="search-field" title="Search">
                <i class="fal fa-search"></i>
            </a>
        </div>
        <div class="hidden-md-down">
            <a href="#" class="header-icon" data-toggle="modal" data-target=".js-modal-settings">
                <i class="fal fa-cog"></i>
            </a>
        </div>
        <div>
            <a href="#" class="header-icon" data-toggle="dropdown" title="My Apps">
                <i class="fal fa-cube"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-animated w-auto h-auto">
                <div class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top">
                    <h4 class="m-0 text-center color-white">
                        เมนูลัด
                    </h4>
                </div>
                <div class="custom-scroll h-100">
                    <ul class="app-list">
                        <li>
                            <a href="/route-diagram" class="app-list-item hover-white">
                                <span class="icon-stack">
                                            <i class="base-6 icon-stack-3x color-danger-600"></i>
                                            <i class="fal fa-chart-line icon-stack-1x text-white"></i>
                                        </span>
                                <span class="app-list-name">
                                            Dashboard
                                        </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="app-list-item hover-white">
                                <span class="icon-stack">
                                            <i class="base-19 icon-stack-3x color-primary-400"></i>
                                            <i class="base-7 icon-stack-2x color-primary-300"></i>
                                            <i class="base-7 icon-stack-1x fs-xxl color-primary-200"></i>
                                            <i class="base-7 icon-stack-1x color-primary-500"></i>
                                            <i class="fal fa-globe icon-stack-1x text-white opacity-85"></i>
                                        </span>
                                <span class="app-list-name">
                                            จองงานขนส่ง
                                        </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="app-list-item hover-white">
                                <span class="icon-stack">
                                            <i class="base-7 icon-stack-3x color-info-500"></i>
                                            <i class="base-7 icon-stack-2x color-info-700"></i>
                                            <i class="ni ni-graph icon-stack-1x text-white"></i>
                                        </span>
                                <span class="app-list-name">
                                            ติดตามงาน
                                        </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="app-list-item hover-white">
                                <span class="icon-stack">
                                            <i class="base-4 icon-stack-3x color-fusion-400"></i>
                                            <i class="base-5 icon-stack-2x color-fusion-200"></i>
                                            <i class="base-5 icon-stack-1x color-fusion-100"></i>
                                            <i class="fal fa-keyboard icon-stack-1x color-info-50"></i>
                                        </span>
                                <span class="app-list-name">
                                            ข้อมูลที่บันทึก
                                        </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="app-list-item hover-white">
                                <span class="icon-stack">
                                            <i class="base-2 icon-stack-3x color-primary-400"></i>
                                            <i class="base-10 text-white icon-stack-1x"></i>
                                            <i class="ni md-profile color-primary-800 icon-stack-2x"></i>
                                        </span>
                                <span class="app-list-name">
                                            โปรไฟล์์
                                        </span>
                            </a>
                        </li>
                       <li>
                            <a href="#" class="app-list-item hover-white">
                                <span class="icon-stack">
                                            <i class="base-2 icon-stack-3x color-primary-600"></i>
                                            <i class="base-3 icon-stack-2x color-primary-700"></i>
                                            <i class="ni ni-settings icon-stack-1x text-white fs-lg"></i>
                                        </span>
                                <span class="app-list-name">
                                            การตั้งค่า
                                        </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
        <!-- app message -->
        <!--         <a  class="header-icon" (click)="test()">-->
        <!--            <i class="fal fa-globe"></i>-->
        <!--            <span class="badge badge-icon">!</span>-->
        <!--        </a>-->
        <!-- app notification -->
        <div>
            <a href="#" class="header-icon" data-toggle="dropdown" title="You got 11 notifications">
                <i class="fal fa-bell"></i>
                <span *ngIf="clientModule?.pkg_id < 30 && countnofication+countnoti360 > 0" class="badge badge-icon">{{countnofication+countnoti360}}</span>
                <span *ngIf="clientModule?.pkg_id >= 30 && countnofication> 0" class="badge badge-icon">{{countnofication}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-animated dropdown-xl">
                <div
                    class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top mb-2">
                    <h2 *ngIf="clientModule?.pkg_id < 30 && countnofication+countnoti360 > 0" class="m-0 text-center color-white">
                        <!-- {{countnofication+countnoti360}} -->
                        <span class="badge badge-icon position-none">{{countnofication+countnoti360}}</span> <small class="mb-0 opacity-80">การแจ้งเตือน ใหม่!</small>

                    </h2>
                    <h2 *ngIf="clientModule?.pkg_id >= 30 && countnofication > 0" class="m-0 text-center color-white">
                      <!-- {{countnofication}} -->
                      <span class="badge badge-icon position-none">{{countnofication}}</span> <small class="mb-0 opacity-80">การแจ้งเตือน ใหม่!</small>
                    </h2>
                </div>
                <ul class="nav nav-tabs nav-tabs-clean" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link px-4 fs-md js-waves-on fw-500 active" data-toggle="tab" href="#tab-messages"
                            data-i18n="drpdwn.messages" (click)="selecttapPrimary()">สำคัญ</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link px-4 fs-md js-waves-on fw-500" data-toggle="tab" href="#tab-feeds"
                            data-i18n="drpdwn.feeds" (click)="selecttapShipment()">สถานะงาน</a>
                    </li>
                    <li class="nav-item" *ngIf="clientModule?.pkg_id < 30">
                        <a class="nav-link px-4 fs-md js-waves-on fw-500" data-toggle="tab" href="#tab-360"
                            data-i18n="drpdwn.feeds" (click)="selecttapShipment()">งาน 360</a>
                    </li>
                    <!--
                    <li class="nav-item">
                        <a class="nav-link px-4 fs-md js-waves-on fw-500" data-toggle="tab" href="#tab-events" data-i18n="drpdwn.events">Events</a>
                    </li>
                    -->
                </ul>
                <div class="tab-content tab-notification">
                    <!-- <div class="tab-pane active p-3 text-center">
                        <h5 class="mt-4 pt-4 fw-500">
                            <span class="d-block fa-3x pb-4 text-muted">
                                        <i class="ni ni-arrow-up text-gradient opacity-70"></i>
                                    </span> Select a tab above to activate
                            <small class="mt-3 fs-b fw-400 text-muted">
                                        This blank page message helps protect your privacy, or you can show the first message here automatically through
                                        <a href="#">settings page</a>
                                    </small>
                        </h5>
                    </div> -->
                    <div class="tab-pane show active" id="tab-messages" role="tabpanel">
                        <div class="custom-scroll h-100" style="overflow: auto;">
                            <ul class="notification">
                                <li *ngFor="let item of notiPrimary?.slice(0,pagesizePrimary) let last = last"
                                    class="{{item?.msgStatus === 1?'unread':''}}">
                                    <a *ngIf="!item?.text.includes('noti')" class="d-flex align-items-center p-3 " (click)="onclickRead(item)">
                                        <!-- <span class="status mr-2">
                                                    <span class="profile-image rounded-circle d-inline-block" style="background-image:url('assets/img/demo/avatars/avatar-c.png')"></span>
                                        </span> -->
                                        <span class="d-flex flex-column flex-1 ml-1">
                                            <span *ngIf="item?.msgStatus === 1"
                                                class="badge badge-danger fw-n position-absolute pos-top pos-right">New</span>
                                            <span class="name" [innerHTML]="item?.title"></span>
                                            <span class="msg-b fs-xs" [innerHTML]="item?.text"></span>
                                            <span class="fs-nano mt-1 text-muted">{{item?.createDate | date:'dd/MM/yyyy HH:mm'}} น.</span>
                                        </span>
                                    </a>
                                    <a *ngIf="item?.text.includes('noti:e')" class="d-flex align-items-center p-3 bg-danger" (click)="onclickRead(item)">
                                        <!-- <span class="status mr-2">
                                                    <span class="profile-image rounded-circle d-inline-block" style="background-image:url('assets/img/demo/avatars/avatar-c.png')"></span>
                                        </span> -->
                                        <span class="d-flex flex-column flex-1 ml-1">
                                            <span *ngIf="item?.msgStatus === 1"
                                                class="badge badge-danger fw-n position-absolute pos-top pos-right">New</span>
                                            <span class="name" [innerHTML]="item?.title"></span>
                                            <span class="msg-b fs-xs text-white"   [innerHTML]="item?.text"></span>
                                            <span class="fs-nano mt-1 text-white" >{{item?.createDate | date:'dd/MM/yyyy HH:mm'}} น.</span>
                                        </span>
                                    </a>
                                    <a *ngIf="item?.text.includes('noti:w')" class="d-flex align-items-center p-3 " style="background-color: #ffc107;" (click)="onclickRead(item)">
                                        <!-- <span class="status mr-2">
                                                    <span class="profile-image rounded-circle d-inline-block" style="background-image:url('assets/img/demo/avatars/avatar-c.png')"></span>
                                        </span> -->
                                        <span class="d-flex flex-column flex-1 ml-1">
                                            <span *ngIf="item?.msgStatus === 1"
                                                class="badge badge-danger fw-n position-absolute pos-top pos-right">New</span>
                                            <span class="name" [innerHTML]="item?.title"></span>
                                            <span class="msg-b fs-xs" [innerHTML]="item?.text"></span>
                                            <span class="fs-nano mt-1 text-muted">{{item?.createDate | date:'dd/MM/yyyy HH:mm'}} น.</span>
                                        </span>
                                    </a>
                                    <a *ngIf="notiPrimary.length  >= pagesizePrimary && last"
                                        style="cursor: pointer;justify-content: center;"
                                        class="d-flex align-items-center">
                                        <span class="d-flex flex-column flex-1 ml-2 text-center">
                                            <div class="{{!idLoadMore?'':'d-none'}}" (click)="loadNofication()">
                                                <span class="name mt-3 ">โหลดเพิ่ม</span>
                                                <span class="d-block fa-3x pb-4 text-muted">
                                                    <i class="ni ni-arrow-down text-gradient opacity-70"></i>
                                                </span>
                                            </div>
                                            <div class="{{idLoadMore?'':'d-none'}}">
                                                <span class="name mt-3 ">กำลังโหลดข้อมูล</span>
                                                <span class="d-block  pb-4 text-muted">
                                                    <div class="spinner-border text-success p-0 mt-2" role="status">
                                                        <span class="sr-only"></span>
                                                    </div>
                                                </span>
                                            </div>
                                        </span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab-feeds" role="tabpanel">
                        <div class="custom-scroll h-100" style="overflow: auto;">
                            <ul class="notification">
                                <li *ngFor="let item of  notiShipment?.slice(0,pagesizeshipment) let last = last"
                                    class="{{item?.msgStatus === 1?'unread':''}}">
                                    <a class="d-flex align-items-center p-3" (click)="onclickRead(item)">
                                        <!-- <span class="status mr-2">
                                                    <span class="profile-image rounded-circle d-inline-block" style="background-image:url('assets/img/demo/avatars/avatar-c.png')"></span>
                                        </span> -->
                                        <span class="d-flex flex-column flex-1 ml-1">
                                            <span *ngIf="item?.msgStatus === 1"
                                                class="badge badge-danger fw-n position-absolute pos-top pos-right">New</span>
                                            <span class="name" [innerHTML]="item?.title"></span>
                                            <span class="msg-b fs-xs" [innerHTML]="item?.text"></span>
                                            <span class="fs-nano text-muted mt-1">{{item?.createDate | date:'dd/MM/yyyy HH:mm'}} น.</span>
                                        </span>
                                    </a>

                                    <a *ngIf="notiShipment.length  >= pagesizeshipment && last"
                                        style="cursor: pointer;justify-content: center;"
                                        class="d-flex align-items-center">
                                        <span class="d-flex flex-column flex-1 ml-2 text-center">

                                            <div class="{{!idLoadMore?'':'d-none'}}" (click)="loadNofication()">
                                                <span class="name mt-3 ">โหลดเพิ่ม</span>
                                                <span class="d-block fa-3x pb-4 text-muted">
                                                    <i class="ni ni-arrow-down text-gradient opacity-70"></i>
                                                </span>
                                            </div>
                                            <div class="{{idLoadMore?'':'d-none'}}">
                                                <span class="name mt-3 ">กำลังโหลดข้อมูล</span>
                                                <span class="d-block  pb-4 text-muted">
                                                    <div class="spinner-border text-success p-0 mt-2" role="status">
                                                        <span class="sr-only"></span>
                                                    </div>
                                                </span>
                                            </div>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab-360" role="tabpanel">
                        <div class="custom-scroll h-100" style="overflow: auto;">
                            <ul class="notification">
                                <!-- <li *ngFor="let item of noti360Type1;let last = last;"
                                    [ngClass]="{'unread': item.msgStatus == 1}">
                                    <a *ngIf="!last" (click)="onReadInAppNotiItem(item)" style="cursor: pointer;"
                                        class="d-flex align-items-center">
                                        <span
                                            [ngClass]="{'status  status-danger mr-2': item.msgStatus == 1,' mr-2': item.msgStatus == 2}">
                                            <span class="profile-image-noti rounded-circle d-inline-block"
                                                style="background-image:url('{{item.logo}}');background-size: cover;"></span>
                                        </span>
                                        <span class="d-flex flex-column flex-1 ml-2">
                                            <span class="name">{{item.title}}</span>
                                            <span class="msg-a fs-sm" *ngIf="item.body">{{item.body}}</span>
                                            <span class="msg-a fs-sm set-p-bottom-0" *ngIf="!item.body"
                                                [innerHTML]="setInnerHTML(item.text)| safeHtml"></span>
                                            <span class="fs-nano text-muted mt-1">{{item?.createDate | date:'dd/MM/yyyy
                                                HH:mm'}} น.</span>
                                        </span>
                                    </a>

                                </li> -->
                                <li *ngFor="let item of noti360?.slice(0,pagesize360);let last = last;"
                                    [ngClass]="{'unread': item.msgStatus == 1}">
                                    <a (click)="onReadInAppNoti360Item(item)" style="cursor: pointer;"
                                        class="d-flex align-items-center">
                                        <span
                                            [ngClass]="{'status  status-danger mr-2': item.msgStatus == 1,' mr-2': item.msgStatus == 2}">
                                            <span class="profile-image-noti rounded-circle d-inline-block"
                                                style="background-image:url('{{item.logo}}');background-size: cover;"></span>
                                        </span>
                                        <span class="d-flex flex-column flex-1 ml-2">
                                            <span class="name">{{item.title}}</span>
                                            <span class="msg-a fs-sm" *ngIf="item.body">{{item.body}}</span>
                                            <span class="msg-a fs-sm set-p-bottom-0" *ngIf="!item.body"
                                                [innerHTML]="setInnerHTML(item.text)| safeHtml"></span>
                                            <span class="fs-nano text-muted mt-1">{{item?.createDate | date:'dd/MM/yyyyHH:mm'}} น.</span>
                                        </span>
                                    </a>
                                    <a *ngIf="countnoti360 >= pagesize360 && last"
                                        style="cursor: pointer;justify-content: center;"
                                        class="d-flex align-items-center">
                                        <span class="d-flex flex-column flex-1 ml-2 text-center">
                                            <div class="{{!idLoadMore?'':'d-none'}}" (click)="clickloadMore360()">
                                                <span class="name mt-3 ">โหลดเพิ่ม</span>
                                                <span class="d-block fa-3x pb-4 text-muted">
                                                    <i class="ni ni-arrow-down text-gradient opacity-70"></i>
                                                </span>
                                            </div>
                                            <div class="{{idLoadMore?'':'d-none'}}">
                                                <span class="name mt-3 ">กำลังโหลดข้อมูล</span>
                                                <span class="d-block  pb-4 text-muted">
                                                    <div class="spinner-border text-success p-0 mt-2" role="status">
                                                        <span class="sr-only"></span>
                                                    </div>
                                                </span>
                                            </div>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab-events" role="tabpanel">
                        <div class="d-flex flex-column h-100">
                            <div class="h-auto">
                                <table class="table table-bordered table-calendar m-0 w-100 h-100 border-0">
                                    <tr>
                                        <th colspan="7" class="pt-3 pb-2 pl-3 pr-3 text-center">
                                            <div class="js-get-date h5 mb-2">[your date here]</div>
                                        </th>
                                    </tr>
                                    <tr class="text-center">
                                        <th>Sun</th>
                                        <th>Mon</th>
                                        <th>Tue</th>
                                        <th>Wed</th>
                                        <th>Thu</th>
                                        <th>Fri</th>
                                        <th>Sat</th>
                                    </tr>
                                    <tr>
                                        <td class="text-muted bg-faded">30</td>
                                        <td>1</td>
                                        <td>2</td>
                                        <td>3</td>
                                        <td>4</td>
                                        <td>5</td>
                                        <td><i
                                                class="fal fa-birthday-cake mt-1 ml-1 position-absolute pos-left pos-top text-primary"></i>
                                            6</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>8</td>
                                        <td>9</td>
                                        <td class="bg-primary-300 pattern-0">10</td>
                                        <td>11</td>
                                        <td>12</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>15</td>
                                        <td>16</td>
                                        <td>17</td>
                                        <td>18</td>
                                        <td>19</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td>21</td>
                                        <td>22</td>
                                        <td>23</td>
                                        <td>24</td>
                                        <td>25</td>
                                        <td>26</td>
                                        <td>27</td>
                                    </tr>
                                    <tr>
                                        <td>28</td>
                                        <td>29</td>
                                        <td>30</td>
                                        <td>31</td>
                                        <td class="text-muted bg-faded">1</td>
                                        <td class="text-muted bg-faded">2</td>
                                        <td class="text-muted bg-faded">3</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="flex-1 custom-scroll">
                                <div class="p-2">
                                    <div class="d-flex align-items-center text-left mb-3">
                                        <div class="width-5 fw-300 text-primary l-h-n mr-1 align-self-start fs-xxl">
                                            15
                                        </div>
                                        <div class="flex-1">
                                            <div class="d-flex flex-column">
                                                <span class="l-h-n fs-md fw-500 opacity-70">
                                                    October 2020
                                                </span>
                                                <span class="l-h-n fs-nano fw-400 text-secondary">
                                                    Friday
                                                </span>
                                            </div>
                                            <div class="mt-3">
                                                <p>
                                                    <strong>2:30PM</strong> - Doctor's appointment
                                                </p>
                                                <p>
                                                    <strong>3:30PM</strong> - Report overview
                                                </p>
                                                <p>
                                                    <strong>4:30PM</strong> - Meeting with Donnah V.
                                                </p>
                                                <p>
                                                    <strong>5:30PM</strong> - Late Lunch
                                                </p>
                                                <p>
                                                    <strong>6:30PM</strong> - Report Compression
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="py-2 px-3 bg-faded d-block rounded-bottom text-right border-faded border-bottom-0 border-right-0 border-left-0">
                    <a class="fs-xs fw-500 ml-auto" (click)="loadNofication()">Load earlier notification</a>
                </div> -->
            </div>
        </div>
        <!-- app user menu -->
        <div>
            <a href="#" data-toggle="dropdown" [title]="currentUser?.email ? currentUser.email :currentUser?.fullName"
                class="header-icon d-flex align-items-center justify-content-center ml-2">
                <img src="{{photoImage}}" (error)="onImgErrorProfile($event)" class="profile-image rounded-circle"
                    alt="">
                <!-- <img *ngIf="!auth.photoAsync | async" src="https://sermpanya.com/sponline/assets/webimg/img_avatar.png" (error)="onImgErrorProfile($event)" class="profile-image rounded-circle" alt=""> -->

                <!-- you can also add username next to the avatar with the codes below:
                        <span class="ml-1 mr-1 text-truncate text-truncate-header hidden-xs-down">Me</span>
                        <i class="ni ni-chevron-down hidden-xs-down"></i> -->
            </a>
            <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
                <div class="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                    <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                        <span class="mr-2">
                            <img src="{{photoImage}}" (error)="onImgErrorProfile($event)"
                                class="rounded-circle profile-image" alt="">
                            <!-- <img *ngIf="!auth.photoAsync | async" src="https://sermpanya.com/sponline/assets/webimg/img_avatar.png" (error)="onImgErrorProfile($event)" class="rounded-circle profile-image" alt="">                                -->
                        </span>
                        <div class="info-card-text">
                            <div class="fs-lg text-truncate text-truncate-lg">{{currentUser?.fullName}}</div>
                            <span class="text-truncate text-truncate-md opacity-80">{{currentUser?.email}}</span>
                        </div>
                    </div>
                </div>
                <div class="dropdown-divider m-0"></div>
                <!--
                <a href="#" class="dropdown-item" data-action="app-reset">
                    <span data-i18n="drpdwn.reset_layout">Reset Layout</span>
                </a>  -->
                <!-- <a href="#" class="dropdown-item" data-toggle="modal" data-target=".js-modal-settings">
                    <span data-i18n="drpdwn.settings">Settings</span>
                </a> -->
                <a class="dropdown-item" routerLink="/profile" [queryParams]="{t: 'tab_user'}">
                    <span data-i18n="drpdwn.settings">ข้อมูลส่วนตัว</span>
                </a>
                <a class="dropdown-item" routerLink="/pricing" *ngIf="clientModule?.pkg_id == 10">
                  <span data-i18n="drpdwn.settings" class="text-success">อัพเกรดเป็น Premium ฟรีทดลองใช้งาน 30 วัน</span>
                </a>
                <div class="dropdown-divider m-0"></div>
                <!--                <a href="#" class="dropdown-item" data-action="app-fullscreen">-->
                <!--                    <span data-i18n="drpdwn.fullscreen">Fullscreen</span>-->
                <!--                    <i class="float-right text-muted fw-n">F11</i>-->
                <!--                </a>-->
                <!--                <a href="#" class="dropdown-item" data-action="app-print">-->
                <!--                    <span data-i18n="drpdwn.print">Print</span>-->
                <!--                    <i class="float-right text-muted fw-n">Ctrl + P</i>-->
                <!--                </a>-->
                <!--
                <div class="dropdown-multilevel dropdown-multilevel-left">
                    <div class="dropdown-item">
                        Language
                    </div>
                    <div class="dropdown-menu">
                        <a href="#?lang=fr" class="dropdown-item" data-action="lang" data-lang="fr">Français</a>
                        <a href="#?lang=en" class="dropdown-item active" data-action="lang" data-lang="en">English (US)</a>
                        <a href="#?lang=es" class="dropdown-item" data-action="lang" data-lang="es">Español</a>
                        <a href="#?lang=ru" class="dropdown-item" data-action="lang" data-lang="ru">Русский язык</a>
                        <a href="#?lang=jp" class="dropdown-item" data-action="lang" data-lang="jp">日本語</a>
                        <a href="#?lang=ch" class="dropdown-item" data-action="lang" data-lang="ch">中文</a>
                    </div>
                </div>  -->
                <div class="dropdown-divider m-0"></div>
                <a class="dropdown-item fw-500 pt-3 pb-3" href="/logout">
                    <span data-i18n="drpdwn.page-logout">Logout</span>

                    <!--<span class="float-right fw-n">codexlantern</span>-->
                </a>
            </div>
        </div>
    </div>
</header>
