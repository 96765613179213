import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService, PageloadingService } from 'src/app/core/_Services';

@Component({
  selector: 'app-invite-carrier',
  templateUrl: './invite-carrier.component.html',
  styleUrls: ['./invite-carrier.component.scss']
})
export class InviteCarrierComponent implements OnInit {

  error: string;
  loginForm = new FormGroup({
    username: new FormControl('',Validators.required),
    password: new FormControl('',[Validators.required,Validators.minLength(6)]),
    remember: new FormControl(false),
  });
  onLoging:boolean = false;
  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private _authService: AuthenticationService,
    // private pageload:PageloadingService
    ) {
      // pageload.hide();
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(){
    $("body").removeAttr('class');
  }
  onLogin(){
    console.log('onLogin');
    this.onLoging = true;

    // this._authService.login(this.loginForm.value.username, this.loginForm.value.password,this.loginForm.value.remember)
    // .pipe(first())
    // .subscribe({
    //     next: (res) => {
    //       console.log(res);
    //       this.onLoging = false;
    //       if(res.isSuccess){
    //         this.pageload.show();
    //         const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    //         this.router.navigate([returnUrl]);
    //       }else{
    //         this.error = res?.statusCode+" : "+ res?.message;
    //       }

    //     },
    //     error: error => {
    //       this.onLoging = false;
    //       this.error = error;
    //       console.info(error);
    //       this.pageload.hide();
    //     }
    // });
  }

}
