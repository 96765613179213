import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common'
import { NotificationService, PageloadingService } from 'src/app/core/_Services';
declare function LightGallery(galleryID):any
@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit,AfterContentInit {
date = new Date;
dateString :string ;
mobile :string;
index;


  //btn-loading
  inprogress= false;
  isDisable = false;
  btnSave = "Save";
    //End-btn-loading

    selectedCar;
    MultiselectedCar;
    cars = [
      { id: 1, name: 'Volvo' },
      { id: 2, name: 'Saab' },
      { id: 3, name: 'Opel' },
      { id: 4, name: 'Audi' },
  ];

  d1;
  d2;
  dateRangeStart;
  dateRangeEnd;

  text1="123";

imgList = [{
  url:"https://image.freepik.com/free-photo/bridge-crossing-lake_1088-575.jpg",
  description:"Description here ...."
},
{
  url:"https://image.freepik.com/free-photo/cityscape-with-seagulls-black-white-photo-with-film-grain-effect_1401-369.jpg",
  description:"Description here ...."
},
{
  url:"https://image.freepik.com/free-photo/cityscape-with-seagulls-black-white-photo-with-film-grain-effect_1401-369.jpg",
  description:"Description here ...."
}
]
  constructor(
    public datepipe: DatePipe,
    public noti:NotificationService,
    // public pageload : PageloadingService
    ) {
    // pageload.hide();
   }


  ngOnInit(): void {
    this.realtimeDate();
  }
  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    LightGallery('matgalleryID');
  }

  recieve($event){
    console.log($event);
    this.mobile = $event;
  }

  print(){
    console.log(this.selectedCar);
    console.log(this.MultiselectedCar);
  }

  ToastSuccess(){
    // ( หัวเรื่อง , ข้อความ)
    // success , info , warning , error
    this.noti.toastsuccess("This is Success Toast","Success Message");
  }

  ToastInfo(){
    this.noti.toastinfo("This is Info Toast","Info Message");

  }
  ToastWarning(){
    this.noti.toastwarning("This is Warning Toast","Warning Message");

  }

  ToastError(){

    this.noti.toasterror("This is Error Toast","Error Message");
  }

  async confirm(){
   var issave = await this.noti.swalConfirm("ท่านต้องการบันทึกการแก้ไขใช่หรือไม่","แน่ใจนะ");
   console.log(issave);
  }

  showLoading(){

      //this.pageload.show();
     var counter = 2
      let intervalId = setInterval(() => {
        counter = counter - 1;
        if(counter === 0){
          clearInterval(intervalId);
          //this.pageload.hide();
        }
    }, 1000)

  }

  realtimeDate(){

    let intervalId = setInterval(() => {
      this.date = new Date;
     this.dateString = this.datepipe.transform(this.date,'dd MMM yyyy HH:mm:ss');

  }, 1000)

  }


  dateChange(event){
    console.log(event.target.value);
    this.dateRangeStart =  event.target.value;
  }

  getValue(){
   var item = ((document.getElementById("datepicker-rang-2") as HTMLInputElement).value);;

   console.log(typeof (item) ,item);
  }

  inputChange(event){
    console.log(event.target.value)
  }


  files: File[] = [];
  filesImg: File[] = [];
  onSelect(event) {
    console.log(event);
    console.log(event.addedFiles);

    event.addedFiles.forEach(item => {
      console.log(item.type.indexOf("image"));
      if(item.type.indexOf("image") != -1){
        this.filesImg.push(...event.addedFiles);
      }else{
        this.files.push(...event.addedFiles);
      }

    });
	}

	onRemove(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
  }

  onRemoveImg(event) {
		console.log(event);
		this.filesImg.splice(this.filesImg.indexOf(event), 1);
  }

}
