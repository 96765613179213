import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { FleetXTruckService } from '@app/modules/truck-license-management/services/fleet-x-truck.service';
import { clientFleetDto } from '@app/modules/truck-management/models/TruckFleet';
import { TruckManagementService } from '@app/modules/truck-management/service/truck-management.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SaveChangeFleetRequest } from '../../models/driver-detail-response.model';
import { FleetXDriverService } from '../../services/fleet-x-driver.service';

@Component({
  selector: 'app-modal-change-fleet-driver',
  templateUrl: './modal-change-fleet-driver.component.html',
  styleUrls: ['./modal-change-fleet-driver.component.scss']
})
export class ModalChangeFleetDriverComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() user_ID: number = 0;
  @Input() companyFleetNo: number = 0;

  private fleetNo: number = 0;

  fleetList: clientFleetDto[] = [];


  modalRef!: NgbModalRef;
  @ViewChild("ModalChangeFleetDriver", { static: true }) ModalChangeFleetDriver!: ModalChangeFleetDriverComponent;

  constructor(
    private modalService: NgbModal,
    private fleetXDriverService: FleetXDriverService,
    private authen: AuthenticationService,
    // public pageload: PageloadingService,
    private truckManagementService: TruckManagementService
  ) { }
  // ngOnChanges(changes: SimpleChanges): void {

  //   console.log('ngOnChanges this.fleetList',this.fleetList);


  //   this.fleetList = this.fleetList.filter(e => e.companyFleetNo != 0);
  //   this.changeRef.detectChanges();
  // }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    console.log('modal fleetList', this.fleetList);

    this.truckManagementService.getClientFleet().pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.fleetList = res?.data;
        this.fleetList = this.fleetList.filter(e => e.companyFleetNo != 0);

      })

    console.log('test', this.fleetList);



  }
  open(size?: 'md' | 'lg' | 'xl' | string): NgbModalRef {
    this.ngOnInit()
    if (size != undefined && size != '' && size != null) {
      this.modalRef = this.modalService.open(this.ModalChangeFleetDriver, { size: size, centered: true, backdrop: 'static' });
    }
    else {
      this.modalRef = this.modalService.open(this.ModalChangeFleetDriver, { size: 'md', centered: true, backdrop: 'static' });
    }
    return this.modalRef;
  }
  closeModal(bool: boolean): void {
    console.log('modal close');
    this.modalRef.close(bool);
  }

  changeFleetFormEvent(event: number): void {
    console.log('changeFleetFormEvent', event);
    this.fleetNo = event;
  }

  saveSubmit(): void {

    this.authen.currentUser.pipe(takeUntil(this.destroy$)).subscribe(auth => {
      const data = {
        updateBy: auth.id,
        companyId: auth.tstS360_CompanyID,
        user_ID: this.user_ID,
        fleetNo: this.fleetNo,
      } as SaveChangeFleetRequest;
      //this.pageload.show()
      this.fleetXDriverService.saveChangeFleet(data).then((res: TMSAPIResponse) => {
        if (res.isSuccess) {
          //this.pageload.hide();
          this.closeModal(res.isSuccess);
        }
      })
    });
  }

}
