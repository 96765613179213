export class AddTeamUserModel {
  isSelected:boolean;
  clientId:number;
  tst360CompanyId:number;
  tst360UserId:number;
  photo:string;
  fullname:string;
  mobileNo:string;
  email:string;
  departmentName:string;
}
