<div class="modal fade" id="Editprofile" tabindex="0" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    แก้ไขข้อมูลส่วนตัว
                </h4>
                <a class="Close"><i class="fal fa-times" data-dismiss="modal"></i></a>
            </div>
            <form [formGroup]="EditProfile" (ngSubmit)="onSubmit()">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <div class="form-group">
                                <img src="https://sermpanya.com/sponline/assets/webimg/img_avatar.png" class="img-fluid"
                                    style="border-radius: 50%;width: 120px;
                                height: auto;
                                object-fit: cover;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txttitlename">คำนำหน้า</label>
                            <input type="text" id="txttitlename" name="txttitlename" class="form-control"
                                placeholder="คำนำหน้า">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtname">ชื่อ</label>
                            <input type="text" id="txtname" name="txtname" class="form-control" placeholder="ชื่อ" formControlName="FristName">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtlastname">นามสกุล</label>
                            <input type="text" id="txtlastname" name="txtlastname" class="form-control"
                                placeholder="นามสกุล" formControlName="LastName">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtemail">Email</label>
                            <input type="email" id="txtemail" name="txtemail" class="form-control"
                                placeholder="Email" formControlName="Email">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txttel">เบอร์โทรศัพท์</label>
                            <input type="text" id="txttel" name="txttel" class="form-control" mask="000-000-0009"
                                placeholder="เบอร์โทรศัพท์" formControlName="Phone">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">สถานะ User</label>
                            <ng-select placeholder="สถานะ" formControlName="StatusUser">
                                <ng-option *ngFor="let item of Active" [value]="item.id">{{item.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label" for="txtusername">Username</label>
                            <input type="text" id="txtusername" name="txtusername" class="form-control"
                                placeholder="Username" formControlName="Username">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">Department</label>
                            <ng-select placeholder="Department" formControlName="Department">
                                <ng-option *ngFor="let item of Department" [value]="item.id">{{item.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">Role</label>
                            <ng-select placeholder="สิทธิ์" formControlName="Role">
                                <ng-option *ngFor="let item of role" [value]="item.id">{{item.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">Team</label>
                            <ng-select placeholder="ทีม" formControlName="Team">
                                <ng-option *ngFor="let item of Team" [value]="item.id">{{item.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">ยกเลิก</button>
                <button type="button" class="btn btn-success">บันทึกข้อมูล</button>
            </div>
            </form>
        </div>
    </div>
</div>