export class LocationData {
  public lat: number;
  public lng: number;
  public placeId: string;
  public distance: number;
  public shortAddress: string;
  public fullAddress: string;
  public province: string;
  public provinceId: number;
  public district: string;
  public districtId: number;
  public subDistrict: string;
  public subDistrictId: number;
  public clientLocationId: number;
  public locationNumber: string;
  public locationTypeId: number;
  public locationTypeName: string;
  public contactName: string;
  public contactPhone: string;
  public locationRemark:LocationMapRemark[] = [];
  public customerId :any;
  public sequence? :number;
}

export class LocationMapRemark {
  id: number;
  title: string;
  remark: string;
  clientID: number;
  departmentID: number[];
  remarkCategoryID: number;
  deletedOn?: any;
  deletedBy?: any;
  modifiedBy?: any;
  createdBy?: any;
  valid: boolean;
  createdOn: Date;
  modifiedOn: Date;
}
