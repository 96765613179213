<app-dynamic-breadcrumb></app-dynamic-breadcrumb>
<!-- <app-breadcrumb  Title="รายชื่อสมาชิกในทีม" Item="[{ name: 'ผู้ใช้และทีม', url: '/team' }, { name: 'Team member', url: '/team/member/1' }]"></app-breadcrumb> -->
<div class="row mt-3">
    <div class="col-xl-12">
        <div id="panel-1" class="panel">
            <div class="panel-hdr">
                <h2>
                    &nbsp; รายชื่อสมาชิกในทีม {{teamUserList?.teamNumber}}: {{teamUserList?.teamName}}
                </h2>
                <div class="panel-toolbar">
                    <h5 class="mt-2" *ngIf="teamUserList?.createdBy" >
                        <small><b>สร้างโดย : </b>{{teamUserList?.createdBy}}</small>
                    </h5>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="row">
                        <div class="col-6">
                            <button type="button"
                                class="btn btn-dark"
                                (click)="redirectToTeam()">
                                <i class="fal fa-arrow-square-left"></i> กลับไปหน้าทีม
                            </button>
                        </div>
                        <div class="col-6 text-right"
                            *ngIf="(permission70300?.actionDto|permission:3) && teamUserList?.teamName !== 'Default'">
                            <app-modal-addteammember (AfterAdd)="getTeamUser()"
                                [disabled]="isLoading"
                                [existTeamUserList]="teamUserList?.teamUserDtosList"
                                [TeamName]="teamUserList?.teamNumber +':'+ teamUserList?.teamName"
                                [TeamId]="teamUserList?.teamId"></app-modal-addteammember>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <table class="table table-bordered table-hover table-striped table-sm w-100 " id="teamUserListTableId">
                                <thead class="bg-fusion-500">
                                    <tr>
                                        <th class="text-center">No.</th>
                                        <th class="text-center">รูปภาพ</th>
                                        <th class="text-center">ชื่อ-นามสกุล</th>
                                        <th class="text-center">เบอร์โทร</th>
                                        <th class="text-center">อีเมล</th>
                                        <th class="text-center">แผนก</th>
                                        <th class="text-center">Role</th>
                                        <th class="all">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of teamUserList?.teamUserDtosList;let i = index;">
                                        <td class="text-center">
                                            {{i + 1}}
                                        </td>
                                        <td class="text-center">
                                            <div class="fs-sm d-flex justify-content-center flex-row-reverse" *ngIf="item?.photo">
                                                <a class="btn-m-s">
                                                    <img src="{{item?.photo}}" (error)="onImgErrorProfile($event)" class="profile-image-sm rounded-circle" alt="">
                                                </a>
                                            </div>
                                        </td>
                                        <td>{{item?.fullname}}</td>
                                        <td>{{item?.mobileNo | mask: "000-000-0009"}}</td>
                                        <td>{{item?.email}}</td>
                                        <td class="text-center">{{item?.departmentName}}</td>
                                        <td class="text-center">
                                            <div [ngClass]="{'badge badge-pill badge-success': item?.roleName}">
                                                {{item?.roleName}}
                                            </div>
                                        </td>
                                        <td>
<!--                                            <button class="btn btn-sm btn-icon btn-outline-success rounded-circle mr-1" title="View Record">-->
<!--                                                <i class="fas fa-search"></i>-->
<!--                                            </button>-->
                                            <div class="dropdown d-inline-block">
                                                <button class="btn btn-sm btn-icon btn-outline-primary rounded-circle shadow-0" data-toggle="dropdown" aria-expanded="false" title="More options">
                                                    <i class="fal fa-ellipsis-v"></i>
                                                </button>
                                                <div class="dropdown-menu" *ngIf="permission70300?.actionDto | permission: 4">
                                                    <a class="dropdown-item" (click)="DeleteTeamUser(item.id)">ลบออกจากทีม</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- datatable end -->
                </div>
            </div>
        </div>
    </div>
</div>
