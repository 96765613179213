import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "@app/core/_Services";
import {Router} from "@angular/router";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    public auth: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
      this.auth.logout();
      // window.location.reload();
      // this.router.navigate(['/login']);
      window.location.href = environment.apiUrl + '/v3/auth/logout';
  }

}
