import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tmsfileupload',
  templateUrl: './tmsfileupload.component.html',
  styleUrls: ['./tmsfileupload.component.scss']
})
export class TMSFileuploadComponent implements OnInit {
@Input() placeholder: string = 'อัปโหลดรูปภาพ';
@Input() acceptType: string = "image/*,.pdf,.docx,.doc,.xlsx,.xls";
@Input() maxDataFileSize: number = 20000000;
@Output() FilesData:EventEmitter<File[]> = new EventEmitter();
@Input() filesInput: File[] = [];
@Input() isPhoto: boolean = true
@Input() multiple: boolean = true;

  files: File[] = [];
  filesImg: File[] = [];

  constructor() { }
  ngOnChanges() {
    this.filesImg = this.filesInput;
    // this.files = this.filesInput;
  }

  ngOnInit(): void {
  }

  onImageSelect(event) {
    event.addedFiles.forEach(item => {
      this.filesImg.push(item);
      // if(item.type.indexOf("image") != -1){
      //   this.filesImg.push(item);
      //   this.FilesData.emit(this.filesImg);
      // }else{
      //   this.files.push(item);
      //   this.FilesData.emit(this.files);
      // }
    });
    // console.log('onImageSelect : ',this.filesImg);

    this.FilesData.emit(this.filesImg);
	}

  onRemoveImg(event) {
		this.filesImg.splice(this.filesImg.indexOf(event), 1);
  }
  onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
  }
}
