export class MasterReport
{
    name:string;
    sub:subReport[];
    isTime:boolean;
    islocation:boolean;
    isday:boolean;
}

export class subReport
{
    name:string;
    Isset:isseting;
}

export class isseting
{
    Isgroup1:Number;
    Isgroup2:Number;
    Isgroup3:Number;
    Isgroup4:Number;
    IsSupply:Number;
    IsSales:Number;
    IsPlant:Number;
    IsAccount:Number;
}