<!-- DROP ZONE -->
<div *ngIf="isPhoto" class="custom-dropzone dropzone" [maxFileSize]="maxDataFileSize" [accept]="acceptType" ngx-dropzone (change)="onImageSelect($event)">
    <ngx-dropzone-label>
        <div class="dz-message needsclick">
            <i class="fal fa-cloud-upload text-muted mb-3"></i> <br>
            <span class="text-uppercase">{{placeholder}}</span>
            <br>
        </div>
    </ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesImg" [file]="f" [removable]="true" (removed)="onRemoveImg(f)">
        <ngx-dropzone-label>
            <div class="label-filesize">
                {{ (f.size / 1024 )/1024 | number}} MB
            </div>
        </ngx-dropzone-label>
    </ngx-dropzone-image-preview>
</div>
<!-- DROP ZONE -->

<!-- DROP ZONE -->
<div *ngIf="!isPhoto" class="custom-dropzone dropzone" [maxFileSize]="maxDataFileSize" [multiple]="multiple" [accept]="acceptType" ngx-dropzone (change)="onImageSelect($event)">
  <ngx-dropzone-label>
      <div class="dz-message needsclick">
          <i class="fal fa-cloud-upload text-muted mb-3"></i> <br>
          <span class="text-uppercase">{{placeholder}}</span>
          <br>
      </div>
  </ngx-dropzone-label>
  <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesImg" [file]="f" [removable]="true" (removed)="onRemoveImg(f)">
      <ngx-dropzone-label>
          <div>
              {{f.name}} {{ (f.size / 1024 )/1024 | number}} MB
          </div>
      </ngx-dropzone-label>
  </ngx-dropzone-preview>
</div>
<!-- DROP ZONE -->
