import { Component, OnInit } from '@angular/core';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { Operation, PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import { CustomerserviceService } from '@app/modules/master/service/customerservice.service';
import { User } from 'src/app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService } from 'src/app/core/_Services';
import { ServicesettingService } from '../../service/servicesetting.service';

@Component({
  selector: 'app-product-setting',
  templateUrl: './product-setting.component.html',
  styleUrls: ['./product-setting.component.scss']
})
export class ProductSettingComponent implements OnInit {
  UnitType= [{id:1,name:"ระบุจำนวนและหน่วยยก"},{id:2,name:"ระบุหน่วยยกเท่านั้น"}]
  PackageType= [{id:1,name:"ขวด"},{id:2,name:"กล่อง"}]
  LoadUnitType= [{id:1,name:"Pallet"},{id:2,name:"Box"}]
  WeigthUnit= [{id:1,name:"กิโลกรัม"},{id:2,name:"ตัน"}]


  UnitTypeList=[{id:1,name:"ระบุจำนวนและหน่วยยก"},{id:2,name:"ระบุหน่วยยกเท่านั้น"}];
  PackageTypeList=[{id:1,name:"ขวด"},{id:2,name:"กล่อง"}];
  LoadUnitTypeList=[{id:1,name:"Pallet"},{id:2,name:"Box"}];
  WeigthUnitList=[{id:1,name:"กิโลกรัม"},{id:2,name:"ตัน"}];

  user: User;
  weight:any;
  Handling:any;
  Principle:any;
  productsetting:any;
  OnSaveing:boolean = true;
  Packaging: any;
  permission90100 :  PermissionGroup= {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };
    constructor(private serviceSetting: ServicesettingService,private authService: AuthenticationService,private _noti: NotificationService,
    private customerservice: CustomerserviceService,) {
      this.authService.currentUser.subscribe((x) => {this.user = x;
        var p: PermissionModule = this.authService.UserPermissionModule(90000);
        if (p) {
          console.log('permission', p);
          this.permission90100 = this.authService.FindPermissionAction(
            90100,
            p.operations
          );
        }
          });
  }

  ngOnInit(): void {
    this.getWeight();
    this.getHandling();
    this.getPrinciple();
    this.getPackaging();
    this.getProductsetting();
    this.OnSaveing =false;
  }

  async getWeight() {
    this.weight = await this.serviceSetting.GetWeightUnit();
  }

  async getHandling() {
    this.Handling = await this.serviceSetting.GetHandlingUnit();
  }

  async getPrinciple() {
    this.Principle = await this.serviceSetting.GetHandlingPrinciple();
  }

  async getPackaging() {
    this.Packaging = await this.customerservice.GetPackaging();
  }

  async getProductsetting() {
    this.productsetting = await this.serviceSetting.GetProductSetting(this.user.ClientID);
    if(this.productsetting.data.handlingprincipleId == 0)
    {
      this.productsetting.data.handlingprincipleId = null;
    }
    if(this.productsetting.data.handlingUnit == 0)
    {
      this.productsetting.data.handlingUnit = null;
    }
    if(this.productsetting.data.packagingunitId == 0)
    {
      this.productsetting.data.packagingunitId = null;
    }
    if(this.productsetting.data.weightUnit == 0)
    {
      this.productsetting.data.weightUnit = null;
    }
  }

   async Onsave()
  {
    this.OnSaveing = true;
    await this.serviceSetting.updateProduct(this.user.firstName,this.productsetting.data).subscribe
    (
      (data)=> {
        if(data?.isSuccess)
        {
          this._noti.toastsuccess("บันทึกข้อมูลเรียบร้อย", "");
        }else{
          this._noti.toasterror("บันทึกข้อมูลไม่สำเร็จ", "");
        }
      }
    );
    this.OnSaveing = false;
  }
}
