<div class="row mt-2">

    <div class="col-md-6">
        <label class="form-label" for="simpleinput">ค้นหารายชื่อ</label>
        <div class="input-group">
            <input type="text" id="simpleinput" class="form-control"
             placeholder="รหัส / ชื่อ-นามสกุล / เบอร์โทร   " aria-label="Recipient's username" aria-describedby="btnsearchteam"
              [(ngModel)]="searchtxt" >
            <div class="input-group-append">
                <button class="btn btn-outline-default" type="button" id="btnsearchteam" (click)="getClientUserList()"><i
                        class="fal fa-search fs-md"></i></button>
            </div>
        </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <div class="col-md-6 text-right " *ngIf="permission70100?.actionDto | permission : 2">
        <label class="form-label" for="txtsearchcustomer">&nbsp;</label>
        <div class="input-group justify-content-end" id="txtsearchcustomer">
            <!-- <button type="button" class="btn btn-success" (click)="addUser()">เพิ่มผู้ใช้งาน</button> -->
            <button class="btn btn-success ml-2" (click)="openmodaladduserform()"><i class="fas fa-plus-square"></i>
                เพิ่มผู้ใช้งาน</button>
        </div>
    </div>
    <div class="col-md-4 text-right d-flex align-items-end justify-content-end">
        <div class="input-group">
          <div class="input-group-append input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-filter fs-xl"></i>
            </span>
          </div>
          <select class="custom-select" id="usertype" (change)="SetPaginationData()" [(ngModel)]="ItemsPerPage">
            <option [value]="10">10</option>
            <option [value]="50">50</option>
            <option [value]="75">75</option>
            <option [value]="100">100</option>
          </select>
        </div>
    </div>
    </div>




    <div class="col-12">
        <table id="clientUserListDataTable" class="table table-bordered table-hover table-striped table-sm w-100 ">
            <thead class="bg-fusion-500">
                <tr>
                    <th class="text-center">No.</th>
                    <th class="text-center">ชื่อ-นามสกุล</th>
                    <th class="text-center">สถานะ</th>
                    <th class="text-center">รูปภาพ</th>
                    <th class="text-center">เบอร์โทร</th>
                    <th class="text-center">อีเมล</th>
                    <th class="text-center" *ngIf="notBasic">แผนก</th>
                    <th class="text-center">สิทธิ์ผู้ใช้งาน</th>
                    <th class="all table-action">ACTION</th>
                </tr>
            </thead>
            <tbody>
              <ng-container #tbody *ngFor="let item of userList | paginate
            : {
                id: 'tail-pagination',
                itemsPerPage: ItemsPerPage,
                currentPage: paging,
                totalItems: TotalData
              } ;let rowIndex = index;">

                <tr>
                    <td class="text-center">
                      {{((paging - 1) * ItemsPerPage) + rowIndex + 1}}
                    </td>
                    <td>{{item.fullname}}</td>
                  <td>
                    <ng-select [items]="activeStatus" bindLabel="name" bindValue="status" [(ngModel)]="item.isActive" [clearable]="false"   [disabled]="item.tst360UserId == currentUser.id"
                     (change)="updateUserIsEnabled(item)">
                  </ng-select>
                  </td>
                    <td class="text-center">
                        <div class="fs-sm d-flex justify-content-center flex-row-reverse">
                            <a class="center btn-m-s" *ngIf="item.photo">
                                <!-- <img src="{{item.photo}}" (error)="onImgErrorProfile($event)" class="profile-image-sm rounded-circle" alt="Profile"> -->
                                <div class="click-zoom">
                                    <label>
                                      <input type="checkbox">
                                      <img class="profile-image-sm rounded-circle" src="{{item.photo}}" (error)="onImgErrorProfile($event)">
                                    </label>
                                </div>
                            </a>
                        </div>
                    </td>
                    <td>{{item.mobileNo | mask: "000-000-0009"}}</td>
                    <td>{{item?.email?.includes("360truck_temp") ? '' : item?.email}}</td>
                    <td  *ngIf="notBasic">{{item.departmentName}}</td>
                    <td>
                        <div class="badge  badge-pill badge-success" *ngIf="item.roleId > 0">
                          {{item.roleName}}
                        </div>
                    </td>


                    <td>
                        <button id="view-action" class="btn btn-sm btn-icon btn-outline-success rounded-circle mr-1" title="View Record" (click)="GetEditUser(item.tst360UserId,true)">
                            <i class="fas fa-search"></i>
                        </button>
                        <!-- <div class="dropdown d-inline-block">
                            <button class="btn btn-sm btn-icon btn-outline-primary rounded-circle shadow-0" data-toggle="dropdown" aria-expanded="false" title="View Record" (click)="clickView(item.tst360UserId)">
                                <i class="fas fa-search"></i>
                            </button>
                            <div class="dropdown-menu">
                                <a [id]="'view-id-' + item.tst360UserId" class="dropdown-item" (click)="GetEditUser(item.tst360UserId,true)">ดู</a>
                            </div>
                        </div> -->
                        <div class="dropdown d-inline-block">
                            <button class="btn btn-sm btn-icon btn-outline-primary rounded-circle shadow-0" data-toggle="dropdown" aria-expanded="false" title="More options">
                                <i class="fal fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <ng-container *ngIf="(item.roleId >0 || item.isActive) && permission70100.actionDto | permission: 3">
                                    <a class="dropdown-item" (click)="GetEditUser(item.tst360UserId)">แก้ไข</a>
                                </ng-container>
                                <ng-container *ngIf="!item.isActive && item.roleId === 0 && permission70100.actionDto | permission: 2">
                                    <a class="dropdown-item" (click)="GetEditUser(item.tst360UserId)">Activate</a>
                                </ng-container>
                                <ng-container *ngIf="(item.roleId >0 || item.isActive) && permission70100.actionDto | permission: 3">
                                    <a class="dropdown-item" (click)="openmodalchangepassword(item)"  >เปลี่ยนรหัสผ่าน</a>
                                </ng-container>
                                <ng-container *ngIf="(item.roleId >0 || item.isActive) && permission70100.actionDto | permission: 3">
                                    <a class="dropdown-item" (click)="openmodalchangemobilenumber(item)"  >เปลี่ยนเบอร์โทร</a>
                                </ng-container>
                                <ng-container *ngIf="(item.roleId != 9 && (item.roleId > 0 || item.isActive)) && permission70100.actionDto | permission: 3">
                                    <a class="dropdown-item" (click)="openmodalchangeemail(item)" >เปลี่ยนอีเมล</a>
                                </ng-container>

                                <ng-container *ngIf="(item.roleId >0 || item.isActive) && permission70100.actionDto | permission: 4">
                                    <a class="dropdown-item" (click)="onDeleteUser(item.tst360UserId, item.tst360UserServiceId)" >ลบ</a>

                                </ng-container>

                            </div>
                        </div>
                    </td>
                </tr>
              </ng-container>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls
            [id]="'tail-pagination'"
            (pageChange)="pageChanged($event)"
            responsive="true"
            previousLabel="ย้อนกลับ"
            nextLabel="ถัดไป">
          </pagination-controls>
        </div>
    </div>
</div>
<!-- <app-modal-edit-user
  [IsEdit]="isEdit"
  [EditData]="userEditData"
  [IsView]="isViewUser"
  (AfterSubmit)="AfterFormSubmit($event)">
</app-modal-edit-user> -->

<app-modal-edit-driver-form #modaladddriverform [IsDriver]="isDriver" [EditData]="userEditData"
(AfterSubmit)="AfterFormSubmit($event)" [IsEdit]="isEdit"  [IsView]="isViewUser"></app-modal-edit-driver-form>
<app-modalchangepassword #modalchangepassword (btnsubmit_event)="getClientUserList()"></app-modalchangepassword>
<app-modalchangemobilenumber #modalchangemobilenumber  (btnsubmit_event)="getClientUserList()"></app-modalchangemobilenumber>
<!-- <app-modalchangeemail #modalchangeemail (onCallGetDriver)="getDriver()"></app-modalchangeemail> -->
<app-modalchangeemail #modalchangeemail  (btnsubmit_event)="getClientUserList()"></app-modalchangeemail>
