import { Injectable } from '@angular/core';
import { FleetXDriverAPIPath, FleetXTruckAPIPath } from '@app/core/_Constants/apiConstants';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { AuthenticationService, HttpService } from '@app/core/_Services';
import { AddFleetXDriverListRequest } from '@app/modules/truck-license-management/models/fleet-x-truck-model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SaveChangeFleetRequest } from '../models/driver-detail-response.model';

@Injectable({
  providedIn: 'root'
})
export class FleetXDriverService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private apiService: HttpService,
    private authenService: AuthenticationService) { }

  AddFleetXDriverList(dataReq: AddFleetXDriverListRequest): Observable<TMSAPIResponse> {
    return this.apiService.post(
      FleetXDriverAPIPath.AddFleetXDriverList, dataReq
    );
  }

  saveChangeFleet(dataReq: SaveChangeFleetRequest): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.apiService.post(FleetXDriverAPIPath.SaveChangeFleetDriver, dataReq)
        .pipe(takeUntil(this.destroy$)).subscribe(res => {
          resolve(res);
        })
    })
  }
}
