import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { PageloadingService } from "../_Services/pageloading.service";

@Injectable()
export class LoaderInterceptor {
  //private loader: PageloadingService LoaderService
  constructor(private loader: PageloadingService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('LoaderInterceptor this.loader.show()');
    const urlIgnoreLoading = ['NotiDB','CanRegisterwithThisPhoneNumber', 'UserEvent']
    if(!urlIgnoreLoading.some(s => req.url.includes(s))) {
      this.loader.show();
      console.log("urlIgnoreLoading Value not exists");
    }

    return next.handle(req).pipe(
      finalize(() => {
        // console.log('LoaderInterceptor this.loader.hide()');
        this.loader.hide();
      }));
  }
}
