<div class="row">
    <div class="col-12 mb-2">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">

                        <ng-select placeholder="เลือก Fleet" [multiple]="false" [items]="clientFleets" [clearable]="false"
                            bindLabel="fleetName" bindValue="companyFleetNo" [(ngModel)]="currentFleet" (change)="fleetChange($event)">
                            <ng-template ng-label-tmp let-item="item">
                                <div class="row">
                                    <div class="col-auto pr-0" *ngIf="item.companyFleetNo != 0">
                                        <label class="mt-1 fleet-color pr-0 mr-0" [ngStyle]="{'background-color': item.fleetColor}">
                                        </label>
                                    </div>
                                    <div class="col pt-1">

                                        <span [ngStyle]="{'color': item.fleetColor}" *ngIf="item.companyFleetNo != 0"> Fleet {{item.companyFleetNo}} </span>
                                        <span [ngStyle]="{'color': item.fleetColor}"> {{item.fleetName}} </span>
                                    </div>
                                </div>
                             
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <div class="row">
                                    <div class="col-auto pr-0" *ngIf="item.companyFleetNo != 0">
                                        <label class="mt-1 fleet-color pr-0 mr-0" [ngStyle]="{'background-color': item.fleetColor}">
                                        </label>
                                    </div>
                                    <div class="col pt-1">

                                        <span [ngStyle]="{'color': item.fleetColor}" *ngIf="item.companyFleetNo != 0"> FLEET {{item.companyFleetNo}} </span>
                                        <span [ngStyle]="{'color': item.fleetColor}"> {{item.fleetName}} </span>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                        <small *ngIf="currentFleet!==0"><i class="fas fa-map-marker-alt"></i> 
                        {{currentFleetItem.fullAddress}}
                        </small>
                    </div>
                    <div class="col-md-2 col-12 " *ngIf="currentFleet!==0 && ShowEditFleet">
                        <button class="btn btn-success btn-block" (click)="editFleetModal()" *ngIf="permission120100.canEdit">
                            แก้ไขข้อมูล FLEET
                        </button>
                    </div>
                    <div class="col-md-2" *ngIf="currentFleet!==0 && !ShowEditFleet && ShowAddFleet && permission130100.canEdit">
                        <button type="button" class="btn btn-success waves-effect waves-themed btn-block" data-toggle="modal" data-target="#addFleetDriver">
                            เพิ่มคนขับเข้า Fleet
                          </button>
                        <app-modal-add-fleet-driver *ngIf="permission130100.canPublish" [currentFleet]="currentFleet" (Refresh)="getDriver()"></app-modal-add-fleet-driver>
                    </div>
                    <div class="col-md-2"  *ngIf="!ShowEditFleet && ShowAddFleet && permission130100.canPublish">
                        <!-- <app-modal-add-driver></app-modal-add-driver> -->
                        <button type="button" class="btn btn-success waves-effect waves-themed btn-block" data-toggle="modal" data-target="#addusermodal" (click)="addUser()">
                          เพิ่มคนขับใหม่
                      </button>
                      </div>

                </div>
            </div>
        </div>

    </div>

</div>