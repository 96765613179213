import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import {
  AuthenticationService,
  HttpService,
  PageloadingService,
} from '../../_Services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  error: string;
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    remember: new FormControl(false),
  });
  onLoging: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _apiSerive: HttpService,
    private http: HttpClient
  ) {
  }

  userSubscribtion: Subscription;

  ngOnInit(): void {

  }
  ngAfterViewInit() {
    $('body').removeAttr('class');
  }
  ngOnDestroy(): void {
    try {
      this.userSubscribtion.unsubscribe();
    } catch (e) {}
  }

  test() {
    let data = {
      userID: '2327',
      companyID: '20092',
    };
    let headers = new HttpHeaders({
      Authorization: environment.IdentityBearerToken,
      // ContentType: 'text/plain'
    });
    this.http
      .post(environment.setting_AWS.Cloudfront.SignatureKey, data, {
        headers: headers,
        responseType: 'text',
      })
      .subscribe((res) => {
        console.log('FilesServer360TechXCookiieCreate : ', res);
        // var iframe = document.createElement('iframe');
        // iframe.src = environment.setting_AWS.Cloudfront.Fileserver360THECX+'?signature='+encodeURI(res);
        // iframe.style.display = 'none';
        // document.body.appendChild(iframe);
      });
  }
  async onLogin() {
    console.log('onLogin');
    this.onLoging = true;

    await this._authService
      .login(
        this.loginForm.value.username,
        this.loginForm.value.password,
        this.loginForm.value.remember
      )
      .pipe(first())
      .subscribe({
        next: async (res) => {
          const result = await res;
          this.onLoging = false;
          if (result.isSuccess) {
            let redirect = environment.web;
            const auth = `${environment.apiUrl}/v3/auth/callback?code=${result?.tstUserJwt}&redirect=${redirect}`;
            console.log("callback url ", auth);
            window.location.href = auth;
            // const returnUrl =
            //   this.route.snapshot.queryParams['returnUrl'] || '/';
            //   console.log(returnUrl);
            // this.router.navigateByUrl(returnUrl);
          } else {
            this.error = result?.statusCode + ' : ' + result?.message;
          }
        },
        error: (error) => {
          this.onLoging = false;
          this.error = error;
          console.info(error);
        },
      });
    // fake login
    // let user  = new User();
    //  user.access_token = "DS?GFSPJg3924-923894";
    //  user.id = 1;
    //  user.ClientID = 1;
    // this._authService.fakelogin(user);
    // const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // this.router.navigate([returnUrl]);
  }
}
