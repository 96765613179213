import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ClientUserService } from '../../services/client-user.service';
import { ClientUserAllListModel } from '../../models/ClientUser/ClientUserAllListModel';
import { AddClientUserModel } from '../../models/ClientUser/AddClientUserModel';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { Operation } from '@app/core/_Models/UserModel/UserPermission';
import { TeamService } from '@app/modules/team/services/team.service';
import { ModalEditDriverFormComponent } from '@app/modules/driver-management/component/modal-edit-driver-form/modal-edit-driver-form.component';
import { DriverDetailService } from '@app/modules/driver-management/services/driver-detail.service';
import { ModalchangepasswordComponent } from '@app/modules/driver-management/component/modalchangepassword/modalchangepassword.component';
import { ModalchangemobilenumberComponent } from '@app/modules/driver-management/component/modalchangemobilenumber/modalchangemobilenumber.component';
import { ModalchangeemailComponent } from '@app/modules/driver-management/component/modalchangeemail/modalchangeemail.component';
import { User } from '@app/core/_Models/UserModel/User';
import { AnalyticsServiceService } from '@app/core/_Services/analytics-service.service';
declare function initDataTable(tableID, param1, param2, param3, param4, responsive): any;
declare function openModal(ModalID): any;
declare function closeModal(ModalID): any;

@Component({
  selector: 'app-all-user',
  templateUrl: './all-user.component.html',
  styleUrls: ['./all-user.component.scss'],
})
export class AllUserComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('modaladddriverform', { static: true }) modaladddriverform!: ModalEditDriverFormComponent;
  @ViewChild('modalchangepassword') modalchangepasswordComponent!: ModalchangepasswordComponent;
  @ViewChild('modalchangemobilenumber') modalchangemobilenumberComponent!: ModalchangemobilenumberComponent;
  @ViewChild('modalchangeemail') modalchangeemail!: ModalchangeemailComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() onChangeTab: boolean;
  @Input() notBasic = false;

  searchtxt: string = '';
  isEdit: boolean = false;
  isViewUser: boolean = false;
  isDriver: boolean = false;
  userList: ClientUserAllListModel[];
  userEditData: AddClientUserModel;
  currentUser:User;
  private tableWidget: any;

  permission70100: Operation;

  paging: number = 1;
  ItemsPerPage: number = 10;
  TotalData: number = 0;
  constructor(
    private _ClientUserService: ClientUserService,
    private _noti: NotificationService,
    private _teamService: TeamService,
    // private pageLoading: PageloadingService,
    private detech: ChangeDetectorRef,
    private driverDetailService: DriverDetailService,
    private authService: AuthenticationService,
    private anlaytic: AnalyticsServiceService,

  ) {}

  activeStatus = [
    { status: true, name: 'เปิดใช้งาน' },
    { status: false, name: 'ระงับการใช้งาน' }
  ];
  async ngOnInit() {
    //Team Management Permission 70200
    this.authService.currentUser.subscribe((x) => {
      this.anlaytic.PushUserInfo2(x);
      this.currentUser = x;
      console.log(this.currentUser);
       this.getClientUserList();
    });

    this.permission70100 = this._teamService.GetPermission(70000, 70100);
    this.detech.detectChanges();
  }

  ngAfterViewInit(): void {}

  async ngOnChanges() {
    if (this.onChangeTab) {
      await this.getClientUserList();
    }
  }

  clickView(id): void {
    setTimeout(() => {
      document.getElementById('view-id-' + id).click();
    }, 100);
  }

  GetEditUser(tst360UserId: number, isView = false) {
    console.log('get edit user', tst360UserId);
    //this.pageLoading.show();
    this._ClientUserService
      .GetClientUserById(tst360UserId)
      .subscribe((res: TMSAPIResponse) => {
        //this.pageLoading.hide();
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          if (res.isSuccess) {
            this.isEdit = true;
            this.isViewUser = isView;
          //  openModal('#EditUser');

            const userData = res.data;
            console.log('userData : ', userData);
            console.log(
              'userData.userRoleDto.isActive : ',
              userData.userRoleDto.isActive
            );
            this.isDriver = userData?.roleDto?.roleID == 9;
            this.userEditData = new AddClientUserModel();
            this.userEditData.clientID = userData.clientID;
            this.userEditData.tsT360_CompanyID = userData.tsT360_CompanyID;
            this.userEditData.tsT360_UserID = userData.tsT360_UserID;
            this.userEditData.username = userData.username;
            this.userEditData.phoneNumber = userData.phoneNumber;
            this.userEditData.email = userData.email;
            this.userEditData.firstName = userData.firstName;
            this.userEditData.lastName = userData.lastName;
            this.userEditData.photoURL = userData.photoURL;
            this.userEditData.idCard = userData.iDcard;
            this.userEditData.taxId = userData.taxId;
            this.userEditData.roleUser.isActive = userData.userRoleDto.isActive;
            this.userEditData.birthDate = userData.birthDate;
            this.userEditData.tsT360_DefaultUserServiceType = 2;
            this.userEditData.departmentId = userData.departmentDto.id;

            this.userEditData.roleId = userData.userRoleDto.roleID;
            this.userEditData.teamIds.push(userData.teamDto.id);

            this.userEditData.teamUser = userData.teamUserDto;
            this.userEditData.roleUser = userData.userRoleDto;
            console.log('map team id: ', userData.teamUserDto);
            if (userData.teamUserDto) {
              this.userEditData.teamIds = userData.teamUserDto.map((m) => m.teamID);
            }
            this.userEditData.userDriverLicence = userData.userDriverLicenceDto;
            this.userEditData.userIDCard = userData.userIDCardDto;
            this.userEditData.tstUserId = userData.identityUserDto?.tstUserId;

            let authLevel;

            if( userData.permissionDto.role.id==9){
              authLevel = 3;
            }else if(userData.permissionDto.role.id==10){
              authLevel = 2;
            }else if(userData.permissionDto.role.id==11){
              authLevel = 1;
            }

            this.userEditData.roleUser.authorizeLevelID = authLevel || userData.userRoleDto?.authorizeLevelID

            console.log('userEditData : ', this.userEditData);


            this.modaladddriverform.modalsize = undefined;
            this.modaladddriverform.IsDriver = this.isDriver;
            this.modaladddriverform.openmodal(this.modaladddriverform.modalsize);
          }
        }
      }, err => {
        //this.pageLoading.hide();
      });
  }


  async getClientUserList() {
    //this.pageLoading.show();
    const dept = await this._ClientUserService
      .GetClientUserAllList(this.searchtxt)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: TMSAPIResponse) => {
        if (res.isSuccess) {
          this.userList = res.data;
          this.TotalData = this.userList.length;
          console.log(this.userList);
          this.reInitDatatable();
          //this.pageLoading.hide();
        }
      });
  }

  async SetPaginationData() {
    this.paging = 1;
    this.userList = [];
    await this.getClientUserList()
    // this.reInitDatatable();
    // this.onSubscribe()

    // this.dataList = [];
    // this.Search.limit = this.ItemsPerPage;
    // this.Search.offset = (this.paging - 1) * this.ItemsPerPage;
    // this.onGetQueryParam(this.currentParam);
  }
  pageChanged(event) {
    console.log('pageChanged', event);
    this.paging = event;
    // this.SetPaginationData();
  }

  private reInitDatatable(): void {
    // if (this.tableWidget) {
    //   this.tableWidget.destroy();
    //   this.tableWidget = null;
    // }
    if (this.tableWidget) {
      this.tableWidget.clear();
      this.tableWidget.destroy();
      this.tableWidget = null;
      // this.changeRef.detectChanges();
    }
    setTimeout(
      () => (this.tableWidget = initDataTable('clientUserListDataTable', false, false, false, false, false)),
      100
    );
  }

  async onDeleteUser(tst360UserId: number, userServiceId: number) {
    const conf = await this._noti.swalDeleteConfirm(
      'ยืนยันลบบัญชีผู้ใช้งานนี้?',
      'โปรดทราบว่าการลบบัญชีผู้ใช้ จะเป็นการลบโดยถาวร และไม่สามารถย้อนกลับได้'
    );
    if (conf) {
      this.driverDetailService
        .deleteUserIdentity(tst360UserId, userServiceId)
        .subscribe((res: TMSAPIResponse) => {
          if (res.isSuccess) {
            this._noti.toastsuccess('ลบข้อมูลเรียบร้อย', '');
            this.getClientUserList();
          } else {
            this._noti.toasterror('ลบข้อมูลไม่สำเร็จ', res.message);
            console.log(res)
          }
        });
    }
  }

  async AfterFormSubmit(event: any) {
    if (event) {
      this.userEditData = null;
      await this.getClientUserList();
      // closeModal('#EditUser');
    }
  }

  addUser() {
    this.isEdit = false;
    this.isViewUser = false;
    // openModal('#EditUser');
  }

  ngOnDestroy(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  openmodaladduserform(){
      this.isEdit = false;
      this.isViewUser = false;
      this.isDriver = false;
      this.modaladddriverform.modalsize = undefined;
      this.modaladddriverform.IsDriver = this.isDriver;
      this.modaladddriverform.openmodal(this.modaladddriverform.modalsize);


  }

  async updateUserIsEnabled(userItem: any): Promise<void> {
    console.log(userItem)
    var item={
      ...userItem,
      user_ID: userItem.tst360UserId,
      isEnabled: userItem.isActive
    }
    await this.driverDetailService.updateUserIsEnabledByUserId(item)
      .subscribe(res => {
        console.log(res);
        if (res.isSuccess) {
          console.log('update isEnabled success');
        }
      },(err)=>{
        this._noti.toasterror(err.message,'');
        console.log(err);
        userItem.isActive = !userItem.isActive;
      });
  }

  update(){
    this._ClientUserService
    .updateStatusClientUser(this.userEditData)
    .subscribe(res=>{
      console.log(res)
    });
  }


  openmodalchangepassword(userItem: any): void {
    console.log(userItem);

    var userData = {
      user_ID : userItem.tst360UserId,
      mobileNo : userItem.mobileNo
    }
    console.log(userData);

    this.modalchangepasswordComponent.modalsize = undefined;
    this.modalchangepasswordComponent.body_title = 'ต้องการเปลี่ยนรหัสผ่าน ?';
    this.modalchangepasswordComponent.body_text = `คุณต้องการเปลี่ยนรหัสผ่านสำหรับ ${userItem.fullname} (${userItem.mobileNo})`;
    this.modalchangepasswordComponent.userItem = userData;
    this.modalchangepasswordComponent.openmodal(
      this.modalchangepasswordComponent.modalsize
    );
    // this.modalReference = this.modalchangepasswordComponent.modalReference;
  }

  openmodalchangemobilenumber(userItem: any): void {
    userItem = {
      ...userItem,
      user_ID : userItem.tst360UserId,
    }
    // var userData = {
    //   user_ID : userItem.tst360UserId,
    //   mobileNo : userItem.mobileNo,
    //   authorizationLevelId: userItem?.authorizationLevelId
    // }
    this.modalchangemobilenumberComponent.modalsize = undefined;
    this.modalchangemobilenumberComponent.userItem = userItem;
    this.modalchangemobilenumberComponent.userAuthorizationLevelId = userItem?.authorizationLevelId;
    this.modalchangemobilenumberComponent.body_title =
      'ต้องการเปลี่ยนเบอร์มือถือ(ที่ใช้ในการเข้าสู่ระบบ) ?';

    this.modalchangemobilenumberComponent.openmodal(
      this.modalchangemobilenumberComponent.modalsize
    );
  }

  openmodalchangeemail(userItem: any): void {

    var userData = {
      user_ID : userItem.tst360UserId,
      mobileNo : userItem.mobileNo,
      email: userItem.email
    }
    this.modalchangeemail.modalsize = undefined;
    this.modalchangeemail.userItem = userData;
    this.modalchangeemail.body_title = 'ต้องการเปลี่ยนอีเมล?';
    const modalChangeEmailRef = this.modalchangeemail.openmodal(
      this.modalchangeemail.modalsize
    );

    modalChangeEmailRef.closed.subscribe(res => {
      if (res) {
        this.getClientUserList();
      }
    })
  }
}
