<div *ngIf="isShow == true" class="row d-flex align-items-center my-2 ml-2" style="font-size: 14px">
    <p class="m-0">หลักฐานการจัดส่งลายเซ็นต์อิเล็กทรอนิกส์ (E-POD)</p>
    <ng-template #popTitle>
        <b style="min-width: 560px;">หลักฐานการจัดส่งลายเซ็นอิเล็กทรอนิกส์ E-POD (Electronic Proof of Delivery)</b>
    </ng-template>
    <ng-template #popContent>
        ระบบที่ช่วยเก็บรวบรวมข้อมูลยืนยันหลักฐานการจัดส่งสินค้าด้วย ลายเซ็นอิเล็กทรอนิกส์ เพื่อให้สามารถติดตามงานและปิดงาน พร้อมระบบ รูปถ่ายและลายเซ็นได้ โดยยืนยันจากผู้ตรวจรับหรือผู้ส่งสินค้า (ระบุจากชื่อผู้ติดต่อแต่ละจุด)
    </ng-template>
    <i (click)="showEpod()" class="fal fa-question-circle ml-1" 
        aria-hidden="true"
        placement="end"
        [popoverTitle]="popTitle"
        popoverClass="popover-min-w-600"
        [ngbPopover]="popContent"
    ></i>
    <div class="input-group-prepend">
        <div class="form-check form-check-inline mx-3">
            <input type="radio" id="customRadio1" [value]="1" 
            class="form-check-input" name="customRadio"
            (change)="actionChange(true)" [disabled]="isDisable" [checked]="checked"/>
            <label class="form-check-label" for="customRadio1">
                ต้องการ
            </label>
        </div>
        <div class="form-check form-check-inline mx-3">
            <input type="radio" id="customRadio2" [value]="0" 
            class="form-check-input" name="customRadio" 
            (change)="actionChange(false)" [disabled]="isDisable" [checked]="checked == false ? true : false"/>
            <label class="form-check-label" for="customRadio2">
                ไม่ต้องการ
            </label>
        </div>
    </div>
</div>