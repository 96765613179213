import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<string>();

  number : string ;
  mobile:string ;
  constructor() { }

  ngOnInit(): void {
  }


  SendData(){
    console.log(this.mobile)
    this.messageEvent.emit(this.mobile)
  }

}
