import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from '@app/core/_Services';
import {
  ModalSuccessComponent,
  ModalSuccessModel,
} from '../modal-success/modal-success.component';
import {
  ModalConfirmComponent,
  ModalConfirmModel,
} from '../modal-confirm/modal-confirm.component';
import { BookingV2022Service } from '@app/modules/booking/service/2022/booking-v2022.service';
import {
  CancelConfirmPriceDto,
  ConfirmPriceDto,
  ShipmentConfirmPriceDto,
} from '@app/modules/booking/models/2022/confirm-price-modal';

@Component({
  selector: 'app-modal-confirm-price',
  templateUrl: './modal-confirm-price.component.html',
  styleUrls: ['./modal-confirm-price.component.scss'],
})
export class ModalConfirmPriceComponent implements OnInit {
  @Input() confirmButtonName: string = 'ยืนยันราคา';
  @Input() shipmentStatusId: number = 0;
  @Input() shipmentId: number = 0;
  @Input() shipmentConfirmPrice!: ShipmentConfirmPriceDto;
  @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modalSuccess', { static: true })
  modalSuccess!: ModalSuccessComponent;
  @ViewChild('modalConfirm', { static: true })
  modalConfirm!: ModalConfirmComponent;

  constructor(
    private authService: AuthenticationService,
    private shipmentService: BookingV2022Service
  ) {}

  ngOnInit(): void {}

  isShownConfirmButton(): boolean {
    return (
      [35, 40].includes(this.shipmentStatusId) &&
      this.authService.isTruckPlusUser()
    );
  }

  isDisabledConfirmButton(): boolean {
    return (
      this.shipmentStatusId === 40 || this.shipmentConfirmPrice?.isConfirmPrice
    );
  }

  isShownComfirmBy(): boolean {
    return (
      this.shipmentConfirmPrice?.confirmPriceDate &&
      this.shipmentConfirmPrice?.isConfirmPrice
    );
  }

  isShownCancelBy(): boolean {
    return (
      this.shipmentConfirmPrice?.confirmPriceDate &&
      !this.shipmentConfirmPrice?.isConfirmPrice
    );
  }

  isAllowToCancelConfirmPrice(): boolean {
    return (
      !this.authService.isSuperUser() &&
      this.shipmentConfirmPrice?.isConfirmPrice
    );
  }

  onConfirmPrice(): void {
    const modalConfirmConfig = {
      title: 'ยืนยันราคา?',
      description: `โปรดตรวจสอบให้แน่ใจว่าข้อมูลราคาทั้งหมดครบถ้วนถูกต้องแล้ว`,
      iconClassName: 'fal fa-exclamation-triangle text-warning',
      buttonList: [
        { text: 'ยกเลิก', class: 'btn-danger', comfirm: false },
        { text: 'ยืนยัน', class: 'btn-success', comfirm: true },
      ],
    } as ModalConfirmModel;
    this.modalConfirm.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirm.open('sm');
    modalRef.closed.subscribe((confirm) => {
      if (confirm) {
        const data: ConfirmPriceDto = {
          shipmentIds: [this.shipmentId],
          updateBy: this.authService.currentUserValue.fullName || null,
        };
        this.shipmentService.confirmPrice(data).then((res) => {
          if (res.statusCode === 200) {
            this.onSuccess();
          }
        });
      }
    });
  }

  onCancelConfirmPrice(): void {
    const data: CancelConfirmPriceDto = {
      shipmentIds: [this.shipmentId],
      cancelConfirmPriceBy: this.authService.currentUserValue.fullName || null,
    };
    this.shipmentService.cancelConfirmPrice(data).then((res) => {
      if (res.statusCode === 200) {
        this.onSuccess('ยกเลิกราคาเรียบร้อย');
      }
    });
  }

  onSuccess(text: string = 'ยืนยันราคาเรียบร้อย'): void {
    const modalSuccessConfig = {
      elementId: 'modal-success',
      title: text,
      description: ``,
      buttonSubmitText: 'ตกลง',
      iconClassName: 'fas fa-check',
      iconClassColor: '',
      isNextButtonAction: false,
      nextButtonActionText: 'ต้องการ',
      nextButtonActionIcon: 'fas fa-plus',
    } as ModalSuccessModel;
    this.modalSuccess.modalConfig = modalSuccessConfig;
    const modalSuccessRef = this.modalSuccess.open('sm');
    modalSuccessRef.closed.subscribe(() => {
      this.refreshData.emit();
    });
  }
}
