import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-list-action',
  templateUrl: './driver-list-action.component.html',
  styleUrls: ['./driver-list-action.component.scss']
})
export class DriverListActionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
