import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalPreviewImportDataModel } from '../models/import-file-model';

@Component({
  selector: 'app-modal-preview-import-data',
  templateUrl: './modal-preview-import-data.component.html',
  styleUrls: ['./modal-preview-import-data.component.scss']
})
export class ModalPreviewImportDataComponent implements OnInit {
  modalReference!: NgbModalRef;
  @ViewChild('modalPreviewImportData') modalPreviewImportData!: ModalPreviewImportDataComponent;

  @Input() dataModal: ModalPreviewImportDataModel;

  // ไม่สามารถนำเข้าข้อมูลที่ไม่ผ่านการตรวจสอบ
  @Input() isNotAllowSubmitDataHasIncorrect: boolean = false;

  dataIncorrectList: any[] = [];
  dataCorrectList: any[] = [];
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.dataIncorrectList = [];
    this.dataCorrectList = [];
    if (this.dataModal) {
      this.dataIncorrectList = this.dataModal.dataPreviewList.filter(e => !e.isCorrect);
      this.dataCorrectList = this.dataModal.dataPreviewList.filter(e => e.isCorrect);
    }
  }

  open(size?: 'md' | 'lg' | string): NgbModalRef {
    this.ngOnInit();
    if (size) {
      this.modalReference = this.modalService.open(this.modalPreviewImportData, { size: size, centered: true, backdrop: 'static' });
    } else {
      this.modalReference = this.modalService.open(this.modalPreviewImportData, { size: 'md', centered: true, backdrop: 'static' });
    }
    return this.modalReference;
  }

  close(bool: boolean): void {
    this.modalReference.close(bool);
  }

}
