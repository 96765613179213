<!-- <div class="row">
    <div class="col-12">
        <div class="plate text-center ">
           <b>{{title}} {{plateNumber}}</b>
            <small> {{province}} </small>
        </div>

    </div>
</div> -->

<div class="card license-plate">
  <span class="text-number">{{title}} {{plateNumber}}</span>
  <span class="text-province" >{{province}}</span>
</div>
