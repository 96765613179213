export class BookingListDeliveredModel {
  id: number;
  //shipmentNo:string;
  pOCarrier: string;
  //customerName:string;
  truckDetails: string;
  routeDetails: string;
  pastTiem: string;
  successDate: Date;
  refNo: string;
  statusID: number;
  status: string;
  carrierPrice: number;
  //startDate:Date;
  finishDate: Date;
  carrierName: string;
  //truckLicense:string;
  driverName: string;
  driverContact: string;
  salesInfo: string;
  inchargeName: string;
  makeSureBy: string;
  makeSureDate: Date;

  //ccc-dev
  shipmentId: number;
  followupStatusId: number;
  shipmentNo: string;
  pO_Number: string;
  customerName: string;
  truckTypeName: string;
  origin: string;
  destination: string;
  startDate: Date;
  endDate: Date;
  atdDate: Date;
  orderRefNo: string;
  shipmentStatusId: number;
  shipmentStatusName: string;
  productName: string;
  price: number;
  clientCarrierCompanyName: string;
  truckLicense: string;
  truckLicenseTail: string;
  driverFirstname: string;
  driverLastname: string;
  driverMobileNo: string;
  sale: string;
  owner: string;
  approveShipmentEndDate: string;
  approveShipmentEndBy: string;
  truckBookingId: number;
  shipmentFollowups: BookingListDeliveredModel[];
  total: TotalModel;
  teamEdit : boolean ;
  truckBookingTeam : any;
}

interface TotalModel {
  all: number;
  followCase: number;
  waitForVerify: number;
}
