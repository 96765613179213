import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-step-button',
  templateUrl: './step-button.component.html',
  styleUrls: ['./step-button.component.scss']
})
export class StepButtonComponent implements OnInit {

  @Input() stepNumber: number = 1;
  @Input() stepText: string = '';
  @Input() stepValid: boolean = false;
  @Output() stepClickEvent: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  stepSubmit(): void {
    this.stepClickEvent.emit(this.stepNumber);
  }

}
