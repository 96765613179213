import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tst-fileupload',
  templateUrl: './tst-fileupload.component.html',
  styleUrls: ['./tst-fileupload.component.scss']
})
export class TstFileuploadComponent implements OnInit {

  @Input() placeholder: string = 'กดเพื่อเลือกรูปภาพ,หรือวางไฟล์ที่นี่ ';
  @Input() acceptType: string = "image/*,.pdf";
  @Input() maxFileSize: number = 20;
  @Input() multiple: boolean = false;
  @Input() showPreview: boolean = true;
   maxDataFileSize: number ;
  @Input() height: number = 80;

  @Output() FilesData: EventEmitter<File[]> = new EventEmitter();
  maxSizePlacehoder: string | undefined;
  heightStyle: string | undefined;
  files: File[] = [];
  constructor() {
    this.maxSizePlacehoder = "ขนาดไม่เกิน " + this.maxFileSize + "MB.";
    this.maxDataFileSize = (this.maxFileSize *1024)*1024;
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.heightStyle = this.height + 'px !important;'

  }


  onSelect(event: any) {
    this.files.push(...event.addedFiles);
    this.FilesData.emit(this.files);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}
