<ng-template #ModalChangeFleetDriver let-modal>
    <div class="modal-header" style="border-bottom: 1px solid #ccc;">
      <h4 class="modal-title"><i class="fas fa-truck text-success"></i> เปลี่ยน FLEET</h4>
      <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button> -->
      <button type="button" class="close pt-1 pl-0 pb-0 pr-1" aria-label="Close" data-dismiss="modal" (click)="closeModal(false)">
        <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
      </button>
    </div>
    <div class="modal-body p-1">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <span class="h4">คุณต้องการให้คนขับเข้า FLEET ไหน</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-change-fleet-form [fleetList]="fleetList" [fleetNo]="companyFleetNo" (changeFleetFormEvent)="changeFleetFormEvent($event)"></app-change-fleet-form>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2">
      <div class="row pb-2 mt-2">
        <div class="col-md-6">
          <button class="btn btn-outline-secondary btn-block" data-dismiss="modal"
            (click)="closeModal(false)">ปิดหน้านี้</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-success btn-block" (click)="saveSubmit(true)">บันทึก</button>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- Modal -->
  <!-- <div class="modal fade" id="changeFleetModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
  
      </div>
    </div>
  </div> -->
  