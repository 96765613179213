import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ModalsuccessComponent } from '../modalsuccess/modalsuccess.component';

@Component({
  selector: 'app-modal-add-user-success',
  templateUrl: './modal-add-user-success.component.html',
  styleUrls: ['./modal-add-user-success.component.scss']
})
export class ModalAddUserSuccessComponent implements OnInit {

  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;

  @Input() title_icon: string = 'fa-check-circle';
  @Input() title_class: string = 'h3 text-white';
  @Input() title_text: string = '';

  @Input() body_title_class: string = 'h3 text-dark';
  @Input() body_title: string = '';

  @Input() body_text_class: string = 'h5 text-muted';
  @Input() body_text: string = '';
  @Input() body_text2: string = '';

  // @Input() body_textclipboard_class: string = 'h5 text-dark';
  // @Input() body_textclipboard: string = '';

  // @Input() btnclipboard_icon: string = 'fal fa-copy';
  // @Input() btnclipboard_text: string = 'คัดลอก';
  // @Input() btnclipboard_content: string = '';

  @Input() btnother_text: string = '';
  @Input() btnother_ishide: boolean = false;

  @Input() btnsubmit_text: string = '';
  @Input() btnsubmit_ishide: boolean = false;

  @Output() modalReference!: NgbModalRef;
  @Output() btnother_event = new EventEmitter<any>();
  @Output() btnsubmit_event = new EventEmitter<any>();
  // @Output() btnclipboard_event = new EventEmitter<any>();

  @ViewChild("modalsuccess", { static: true }) modalsuccess!: ModalsuccessComponent;
  constructor(private modalService: NgbModal,private clipboardService: ClipboardService) { }


  element: HTMLElement;

  copytext ;

  ngOnInit(): void {
  }

  
  openmodal(size?: 'sm' | 'lg' | 'xl' | string, backdrop?: boolean | 'static', keyboard?: boolean): NgbModalRef {

    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalsuccess, { size: size, centered: true, backdrop: backdrop, keyboard: keyboard });
    }
    else {
      this.modalReference = this.modalService.open(this.modalsuccess, { centered: true, backdrop: backdrop, keyboard: keyboard });
    }
    return this.modalReference
  }

  btnother_click() {
    this.btnother_event.emit();
  }

  btnsubmit_click() {
    this.btnsubmit_event.emit();
  }

  close(): void {
    this.modalReference.close(true);
  }

  onCopy(){
    this.element = document.getElementById('for-copy');
    let cbCheck = this.clipboardService.copyFromContent(this.element.innerText);

    console.log('docReturn', cbCheck);
    console.log(this.element.innerText);

    this.copytext = this.element.innerText;
  }

}
