import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalImportResultModel } from '../models/import-file-model';

@Component({
  selector: 'app-modal-import-result',
  templateUrl: './modal-import-result.component.html',
  styleUrls: ['./modal-import-result.component.scss']
})
export class ModalImportResultComponent implements OnInit {
  modalReference!: NgbModalRef;
  @ViewChild('modalImportResult') modalImportResult: ModalImportResultComponent

  @Input() dataModal: ModalImportResultModel;
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    console.log('dataModal', this.dataModal);

  }
  open(size?: 'sm' | 'md' | 'lg' | string): NgbModalRef {
    this.ngOnInit();
    if (size) {
      this.modalReference = this.modalService.open(this.modalImportResult, { size: size, centered: true, backdrop: 'static' });
    } else {
      this.modalReference = this.modalService.open(this.modalImportResult, { size: 'md', centered: true, backdrop: 'static' });
    }
    return this.modalReference;
  }

  close(bool: boolean): void {
    this.modalReference.close(bool);
  }

}
